<template>
  <div>
    <div class="pie" ref="pieRef"></div>
  </div>
</template>
<script>
export default{
    props: {
    data: {
      type: Object,
      required:true 
    },
  },
  data(){
    return{
        index:0,
    }
  },
  mounted () {
    this.initPie();
  },
  watch: {
    data:{
				handler(){
					this.initPie();
			 	},
				deep:true
			}
  },
  methods: {
    initPie(){
        let _this = this;
      if(!this.data || !this.data.radar || !this.data.radar.value || this.data.radar.value.length ===0  || !this.data.radar.name || this.data.radar.name.length === 0){
        return;
      }
      let radarData= this.data.radar;
      let myChart = this.$echarts.init(this.$refs.pieRef)
        let color = ['#FFFFFF','#31CBFE','#32A0F5','#96DBFF',  '#828B90',];
        let baseData = [];
        for (let i = 0; i < radarData.name.length; i++) {
            let name = radarData.name[i];
            let value= 0;
            if(i< radarData.value.length){
                value=radarData.value[i]
            }
            let colorIndex = i % color.length;
            baseData.push({
                value,
                name,
                itemStyle: {
                    normal: {
                        color: color[colorIndex],
                        borderColor: color[colorIndex],
                    }
                }
            },
            {
                value: 2,
                itemStyle: {
                    normal: {
                        color: 'transparent',
                        borderColor: 'transparent',
                    }
                }
            }
        );
    }

 const option = {
      title: {
        text: `{a|${this.data.totalScore.toFixed(0)}}{b|分}\n{c|${this.data.totalScoreResult}}`,
        top: '34%',
        textAlign: "center",
        left: "27%",
        textStyle: {
            rich:{
                a:{
                    color: '#fff',
                    fontSize: 52,
                    fontWeight: '400'
                },
                b:{
                    color: '#999',
                    fontSize: 22,
                },
                c:{
                    color:"#fff",
                    fontSize:22,
                }
            }
            
        }
    },
    legend:{
      show:false,
    },
    series: [
        {
             type: 'pie',
             hoverAnimation: false, 
                         silent:true,
             radius: ['65%', '67%'],
             center: ['28%', '50%'],
             tooltip: {
                 show: false
             },
             label: {
                 normal: {
                     show: false
                 }
             },
             data: baseData
         },
         {
            type: 'pie',
            color: color,
            radius: ['76%', '82%'],
            center: ['28%', '50%'],
            tooltip: {
                show: false
            },
            label: {
                show: false,
                position: 'center'
            },
            data: baseData
        },
    ]
 };
      myChart.setOption(option)
      myChart.dispatchAction({
        type: "highlight",
        dataIndex: 0
      }); //设置默认选中高亮部分
      myChart.on("mouseover", function(e) {
          if (e.dataIndex != _this.index) {
            myChart.dispatchAction({
              type: "downplay",
              dataIndex: _this.index
            });
          }
        });
        myChart.on("mouseout", function(e) {
          _this.index = e.dataIndex;
          myChart.dispatchAction({
            type: "highlight",
            dataIndex: e.dataIndex
          });
        });
    }
  }
}
</script>
<style lang='scss' scoped>
.pie{
  height: 200px;
			 		width: 330px;
}
</style>