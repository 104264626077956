<template>
  <div>
    <div class="line" ref="lineRef"></div>
  </div>
</template>
<script>
import { loopShowTooltip } from '../../util/loop-tooltip'
export default{
    props:{
        data:{
            type: Object,
            required:true
        }
    },
  data(){
    return{
      tooltipTimer:null
    }
  },
  mounted(){
    this.initLine()
  },
  watch:{
    data:{
        handler(){
            this.initLine()
        },
        deep:true
    }
  },
  destroyed () {
    this.tooltipTimer && this.tooltipTimer.clearLoop();
  },
  methods: {
    initLine() {
        if(!this.data || !this.data.xAxis || this.data.xAxis.length == 0){
            return;
        }
        let data = JSON.parse(JSON.stringify(this.data))
        let seriesData = data.series
        let myChart = this.$echarts.init(this.$refs.lineRef)
        let colors =["#00F7FF",'#F3B14C','#FF7D3D','#4B96F3']
        let legendData = [];
        let optionData = [];
        seriesData.forEach((item,index)=>{
            let colorIndex = index % colors.length
            legendData.push({
                name:item.name,
                icon:"image://"+require('../../assets/img/homeEquip/rb1'+(index+1)+'.png'),
                itemStyle:{
                    color:colors[colorIndex]
                },
                textStyle:{
                    fontSize:14,
                    color:colors[colorIndex]
                }
            })
            optionData.push({
                name: item.name,
                type: 'line',
                data: item.data,
                symbolSize: 30,
                symbol: 'image://'+require('@/assets/img/homeEquip/rb'+(index+1)+'.png'),
                smooth: false,
                showSymbol: true,
                lineStyle: {
                    color:colors[colorIndex],
                    width: 2,
                },
            })
        })
        const option = {
            legend: {
              show: true,
              top: '5%',
            //   selectedMode:false,
              itemGap: 20,
              itemWidth:30,
              itemHeight:12,
              data: legendData
            },
            tooltip: {
                trigger: 'axis',
                axisPointer:{
                    type:"line",
                    lineStyle:{
                        color:"#3371FC"
                    }
                },
                borderWidth:0,
                position: (pos,param,dom, rect, size)=>{
            var obj = {top: 60};
            let halfContent = size.viewSize[0] / 2;
            if(pos[0] <= halfContent) {
              obj['left'] = pos[0]
            } else if(pos[0] >halfContent) {
              obj['right'] = size.viewSize[0] - pos[0]
            }
            return obj;
          },
                formatter:params=>{
                    let result = '';
                    if(params.length > 0){
                        result +=`<div style="line-height:20px">${params[0].axisValue}:</div>`
                        params.forEach(item=>{
                            result += `<div style="line-height:20px">${item.seriesName}：${item.data}次</div>`
                        })
                    }
                    return result;
                },
                backgroundColor: 'rgba(113,200,255,.28)',
                textStyle: {
                    color: '#fff',
                    fontSize:15,
                    fontWeight:400,
                },
            },
            grid: {
                left:"8%",
                right:"1%",
                top: '20%',
                bottom:"7%"
            },
            xAxis: [
                {
                    type: 'category',
                    data: data.xAxis,
                    axisLine: {
                        lineStyle: {
                            width:2,
                            color: '#0887E9',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            fontSize:16,
                            color:"rgba(255,255,255,.6)",
                        },
                    },
                    splitLine: {
                        show: false,
                    },
                },
            ],
            yAxis: {
                type: 'value',
                name:"（次）",
                nameTextStyle:{
                    fontSize:16,
                    color:"rgba(255,255,255,.6)",
                    padding:[0,50,0,0]
                },
                axisTick: {
                    show: false,
                },
                minInterval:1,
                axisLine: {
                    show: true,
                    lineStyle: {
                        width:2,
                        color: 'rgba(8,135,233,.6)',
                    },
                },
                axisLabel: {
                    textStyle: {
                        fontSize:16,
                        color:"rgba(255,255,255,.6)",
                        padding:[0,5,0,0]
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dashed',
                        color: 'rgba(8,135,233,.3)',
                    },
                },
            },
            series: optionData
      };

      myChart.setOption(option)
      myChart.on('legendselectchanged',param=>{
          let flag = param.selected[param.name];
          legendData.forEach((item,index)=>{
              if(item.name == param.name){
                  let idx = flag ? index : 4 
                  item.icon = "image://"+require('../../assets/img/homeEquip/rb1'+(idx+1)+'.png')
              }
          })
          myChart.setOption(option)
      })
      this.tooltipTimer && this.tooltipTimer.clearLoop();
      this.tooltipTimer = 0;
      this.tooltipTimer = loopShowTooltip(myChart, option, {
 interval: 3000, // 轮播间隔时间
 loopSeries: true, // 是否开启轮播循环
 dataLength:data.xAxis.length
});
    }
  },
}
</script>
<style lang='scss' scoped>
.line{
  width: 800px;
	height: 300px;
}
</style>