/**
 * @description 地图部分的js
 */
import { loopShowTooltip } from '../util/loop-tooltip.js'
export default {
	data() {
		return {
			openDiaType: '', // 弹窗的打开类型
			// openDiaType: 'equip', // 弹窗的打开类型
			tabType: 'control', // tab的选中type值
			equipName: '--', // 弹窗上面的设备点名称
			diaType: '', // 弹窗层的类型
			// diaType: 'qx', // 弹窗层的类型
			// 基地信息
			jdName: '--', // 基地负责人
			jdPhone: '--', // 基地电话
			isBigImg: false, // 虫情图片是否是放大预览状态
			bigImgs: '', // 放大的图片地址
			echartsData: [], // 从地图上传过来的柱状图的tooltip数据，主要用于智脑中心
			/**
			 * 气象  16081971
			 * 墒情  16082054
			 * 苗情  1
			 * 虫情  G95799393
			 */
			equipId: '', // 选中的设备点id值  16081970
			isOnline: true, // 是否在线
			isWarn: false, // 是否有预警
			isOpenWarn: false, // 预警弹窗是否打开
			// 预警弹窗的x和y值
			warnLeft: '0',
			warnTop: '0',
			// 其它弹窗的定位值
			diaLeft: '0',
			diaTop: '0',
			warnTime: '--', // 警告弹窗的时间
			warnMsg: '--', // 预警概况信息
			warnWord: '--',
			jdTitle: '', // 模型点击弹窗上面的title
			// tab切换列表
			tabLists: [
				{
					name: '云台模式',
					type: 'control',
				},
				{
					name: '设备操作',
					type: 'hand',
				},
				{
					name: '定时设置',
					type: 'autos',
				},
				// {
				// 	name: '智能模式',
				// 	type: 'smarts',
				// },
			],
			// 云台控制设备状态的数据
			ytStatus: [
				{
					name: '注肥总量',
					num: 520,
					unit: 'kg',
				},
				{
					name: '瞬时注肥量',
					num: 220,
					unit: 'kg',
				},
				{
					name: '土壤EC值',
					num: 220,
					unit: '',
				},
				{
					name: '土壤PH值',
					num: 120,
					unit: '',
				},
				{
					name: '功耗',
					num: 520,
					unit: 'kw',
				},
				{
					name: '电压',
					num: 220,
					unit: 'v',
				},
				{
					name: '流量',
					num: 220,
					unit: 'm³/h',
				},
				{
					name: '运行次数',
					num: 120,
					unit: '次',
				},
			],
			// 气象数据
			qxDefaultList: [
				{
					title: '空气温度',
					icon: require('../assets/img/qx/icon_kw.png'),
					dushu: '',
					unit: '℃',
					type: 'WD',
				},
				{
					icon: require('../assets/img/qx/icon_kw.png'),
					dushu: '',
					title: '空气湿度',
					unit: '%RH',
					type: 'SD',
				},

				{
					icon: require('../assets/img/qx/icon_tg.png'),
					dushu: '',
					title: '太阳总辐射',
					unit: 'w/㎡',
					type: 'TYFSSUM',
				},
				{
					icon: require('../assets/img/qx/icon_jy.png'),
					dushu: '',
					title: '降雨量',
					unit: 'mm',
					type: 'JYL',
				},
				{
					icon: require('../assets/img/qx/icon_jy.png'),
					dushu: '',
					title: '日雨量累计',
					unit: 'mm',
					type: 'JYLDAY',
				},
				{
					icon: require('../assets/img/qx/icon_zf.png'),
					dushu: '',
					unit: 'mm',
					title: '蒸发',
					type: 'ZFE',
				},
				{
					icon: require('../assets/img/qx/icon_zwx.png'),
					dushu: '',
					title: '紫外线辐射量',
					unit: 'w/㎡',
					type: 'ZWX',
				},
				{
					icon: require('../assets/img/qx/icon_zwx.png'),
					dushu: '',
					title: '辐射累计',
					unit: 'MJ/㎡',
					type: 'FSLJ',
				},
				{
					icon: require('../assets/img/qx/icon_ld.png'),
					dushu: '',
					title: '露点温度',
					unit: '℃',
					type: 'LDWD',
				},
				{
					icon: require('../assets/img/qx/icon_qy.png'),
					dushu: '',
					title: '大气压力',
					unit: 'Hpa',
					type: 'DQYL',
				},
				{
					icon: require('../assets/img/qx/icon_fx.png'),
					dushu: '',
					title: '风向',
					unit: '°',
					type: 'FX',
				},
				{
					icon: require('../assets/img/qx/icon_fsu.png'),
					dushu: '',
					title: '风速',
					unit: 'm/s',
					type: 'FS',
				},
				{
					icon: require('../assets/img/qx/icon_tg.png'),
					dushu: '',
					title: '液位',
					unit: 'mm',
					type: 'YWE',
				},
			],
			qxList: [],
			timeIndex: '0', // 时间折线图的选中值
			// 气象折线图传值数据
			qxQuery: {
				startTime: '',
				endTime: '',
				dicts: '',
			},
			// 气象折线图请求参数
			qxrange: [],
			qxDefaultVal: [],
			qxChoose: '', // 气象类型折线图的选中数据
			qxChoose2: '',
			qxSelMultiple: true,
			tooltipTimerQx: null,
			tooltipTimerSq: null,
			tooltipTimerCq: null,
			// 气象下拉选
			qxParams: [],
			qxSelectMultipleNum: 3,
			// 气象选择的时间范围
			qxTimeScope: '',
			qxPickerOptions: {
				onPick: ({ maxDate, minDate }) => {
					this.qxTimeScope = minDate.getTime()
					if (maxDate) {
						this.qxTimeScope = ''
					}
				},
				disabledDate: (time) => {
					const self = this
					if (!!self.qxTimeScope) {
						const startDay = (new Date(self.qxTimeScope).getDate() - 1) * 24 * 3600 * 1000
						const endDay =
							(new Date(
								new Date(self.qxTimeScope).getFullYear(),
								new Date(self.qxTimeScope).getMonth() + 1,
								0
							).getDate() -
								new Date(self.qxTimeScope).getDate()) *
							24 *
							3600 *
							1000
						let minTime = self.qxTimeScope - startDay
						let maxTime = self.qxTimeScope + endDay
						return time.getTime() < minTime || time.getTime() > maxTime
					}
				},
			},
			// 是否显示气象折线图
			isQxLine: true,
			isSqLine: true, // 是否显示墒情折线图
			isCqLine: true, // 是否显示虫情折线图
			// 虫情防治数据
			cqControlData: [],
			// 虫情图片
			cqImgArr: [],
			// 虫情时间范围选择
			cqTimeIndex: 0,
			// 虫情选择的时间范围
			cqTimeScope: '',
			//日期选择范围在一个月内
			pickerOptions: {
				onPick: ({ maxDate, minDate }) => {
					this.cqTimeScope = minDate.getTime()

					if (maxDate) {
						this.cqTimeScope = ''
					}
				},

				disabledDate: (time) => {
					const self = this

					if (!!self.cqTimeScope) {
						const startDay = (new Date(self.cqTimeScope).getDate() - 1) * 24 * 3600 * 1000

						const endDay =
							(new Date(
								new Date(self.cqTimeScope).getFullYear(),

								new Date(self.cqTimeScope).getMonth() + 1,

								0
							).getDate() -
								new Date(self.cqTimeScope).getDate()) *
							24 *
							3600 *
							1000

						let minTime = self.cqTimeScope - startDay

						let maxTime = self.cqTimeScope + endDay

						return time.getTime() < minTime || time.getTime() > maxTime
					}
				},
			},
			// 虫情防治显示数据
			cqFzWrap: {
				agriculMeasure: '',
				biologControl: '',
				chemicalControl: '',
				id: '',
				insertTime: '',
				pestName: '',
			},
			isCqLine: false, // 虫情折线图是否显示
			controlIndex: 0,
			// 墒情数据
			trWd: [], // 土壤温度数组
			trSd: [], // 土壤湿度数组
			trCengIndex: 0, // 土壤选择的是第几层
			trEc: '', // EC数值
			trDlz: '', // 氮离子
			trLlz: '', // 磷离子
			trJlz: '', // 钾离子
			trPh: '', // ph值
			// 气象折线图传值数据
			sqQuery: {
				startTime: '',
				endTime: '',
				dicts: '',
			},
			sqrange: [],
			sqDefaultVal: [],
			sqChoose: '', // 墒情类型折线图的选中数据
			sqChoose2: '',

			// 墒情下拉选
			sqParams: [],
			// 墒情下拉框是否多选
			sqSelMultiple: true,
			// 墒情弹窗下拉多选数量
			sqSelectMultipleNum: 3,
			// 是否选中季度
			idNoSelQuarter: false, //墒情
			idNoSelQuarter1: false, //气象
			// 气象选择的时间范围
			sqTimeScope: '',
			sqPickerOptions: {
				onPick: ({ maxDate, minDate }) => {
					this.sqTimeScope = minDate.getTime()
					if (maxDate) {
						this.sqTimeScope = ''
					}
				},
				disabledDate: (time) => {
					const self = this
					if (!!self.sqTimeScope) {
						const startDay = (new Date(self.sqTimeScope).getDate() - 1) * 24 * 3600 * 1000
						const endDay =
							(new Date(
								new Date(self.sqTimeScope).getFullYear(),
								new Date(self.sqTimeScope).getMonth() + 1,
								0
							).getDate() -
								new Date(self.sqTimeScope).getDate()) *
							24 *
							3600 *
							1000
						let minTime = self.sqTimeScope - startDay
						let maxTime = self.sqTimeScope + endDay
						return time.getTime() < minTime || time.getTime() > maxTime
					}
				},
			},
			// 苗情数据
			player: null, // 视频播放控件
			screenPlayer: null, // 全屏视频控件
			accessToken: '', // 视频的token
			videoIsPlay: true, // 视频是否是播放状态
			times: '', // 实时显示的时间
			time: null, // 定时时间
			captureList: [], // 抓取的图片
			isShowMqbtn: false, // 是否显示抓图的切换按钮
			controlType: '', // 云台操控目前的类型
			deviceSerial: '', // 当前操作的序列号   G59770545
			channelNo: '1',
			channelNoP: '1',
			isOpenMsg: true, // 视频上的弹幕是否开始
			mqQuery: {
				page: 1,
				limit: 1,
				device: '',
			},
			mqTotal: 0, // 苗情过程建议的总数量
			mqShowIndex: 0,
			mqBottomInfo: {},
			mqBList: [
				{
					name: '空温(℃)',
					key: 'vacuumThermometer',
					icon: require('../assets/img/qx/icon_kw.png'),
				},
				{
					name: '空湿(%)',
					key: 'temperature',
					icon: require('../assets/img/qx/icon_ks.png'),
				},
				{
					name: '土温(℃)',
					key: 'soilTemperature',
					icon: require('../assets/img/qx/icon_tw.png'),
				},
				{
					name: '土湿(%)',
					key: 'soilMoisture',
					icon: require('../assets/img/qx/icon_ts.png'),
				},
			],
			isCanYt: true, // 云台控制是否可以进行，枪机没有云台控制

			// 本月开始时间
			warnX: '',
			warnY: '',
			mqTrId: '', // 枪机对应的土壤id
			mqQxId: '', // 枪机对应的气象id
			// 苗情过程的新增数据
			mqName: '',
			mqTrWd: '',
			mqTrSd: '',
			mqQxWd: '',
			mqqxSd: '',
			mqLastImg: '', // 新增的最后一张图片
			// 2022/04/01 视频方面的数据设置
			videoWidth: 432,
			videoHeight: 180,
			videoId: 'videoopen',
			videoRefName: 'hlsRefvideo',
			isNeedHide: true, // 全屏的时候视频是否需要隐藏，主要用作下面视频的显示隐藏
			echartsColor: {
				磷: '#1D8AB2',
				钾: '#21A859',
				氮: '#A17430',
				土温: '#E5870D',
				土湿: '#16F9E6',
				磷离子: '#D5FB43',
				钾离子: '#3BF9A4',
				氮离子: '#A17430',
			},

			// 2022-07-27 新增热力图相关弹窗数据
			equipXData: [], // x轴数据
			equipNameXdata: [],
			equipNameData: [
				// {
				// 	name: '',
				// 	faceId: ''
				// }
			], // 设备数据
			equipDiaData: {
				dan: [],
				lin: [],
				jia: [],
				tw: [],
				ts: [],
			},
			equipPage: 0,
			equipFaceId: '',
			equipFaceName: '',
			flChartDom: null,
			trChartDom: null,
			tooltipTimerFl: null,
			tooltipTimerTr: null,
		}
	},
	beforeDestroy() {},
	destroyed() {
		this.tooltipTimerQx && this.tooltipTimerQx.clearLoop()
		this.tooltipTimerSq && this.tooltipTimerSq.clearLoop()
		this.tooltipTimerCq && this.tooltipTimerCq.clearLoop()
		this.tooltipTimerFl && this.tooltipTimerFl.clearLoop()
		this.tooltipTimerTr && this.tooltipTimerTr.clearLoop()
	},
	computed: {
		// 弹窗的显示位置
		diaStyle() {
			return {
				top: this.diaTop,
				left: this.diaLeft,
			}
		},
		ctxStyle() {
			return {
				top: this.ctxTop,
				left: this.ctxLeft,
				// width: this.ctxW,
				// height: this.ctxH,
			}
		},
		ctxEStyle() {
			return {
				top: this.ctxETop,
				left: this.ctxELeft,
				// width: this.ctxW,
				// height: this.ctxH,
			}
		},
		// 公用头部弹窗上面的title
		diaTopTitle() {
			let titles = ''
			if (this.diaType == 'qx' || this.diaType == 'sq') {
				titles = '实时数据'
			} else if (this.diaType == 'cq') {
				titles = '虫情识别'
			}
			return titles
		},
		// 预警弹窗的位置
		warnStyle() {
			return {
				top: this.warnTop,
				left: this.warnLeft,
			}
		},
		// 预警信息title
		warnTitle() {
			let titles = '--'
			if (this.diaType == 'qx') {
				titles = '气象'
			} else if (this.diaType == 'sq') {
				titles = '墒情'
			} else if (this.diaType == 'cq') {
				titles = '虫情'
			} else if (this.diaType == 'mq') {
				titles = '苗情'
			} else if (this.diaType == 'sf') {
				titles = '水肥'
			}
			return titles
		},
		// 最高值
		danMax() {
			let maxs = Math.max(...this.equipDiaData.dan)
			let maxIndex = this.equipDiaData['dan'].findIndex((item) => {
				return item == maxs
			})
			let params = {
				name: this.equipNameData[maxIndex]['name'] || '待赋值',
				type: '氮离子',
				val: maxs + this.echartsData[0]['unit'],
			}
			return params
		},
		linMax() {
			let maxs = Math.max(...this.equipDiaData.lin)
			let maxIndex = this.equipDiaData['lin'].findIndex((item) => {
				return item == maxs
			})
			let params = {
				name: this.equipNameData[maxIndex]['name'] || '待赋值',
				type: '磷离子',
				val: maxs + this.echartsData[1]['unit'],
			}
			return params
		},
		jiaMax() {
			let maxs = Math.max(...this.equipDiaData.jia)
			let maxIndex = this.equipDiaData['jia'].findIndex((item) => {
				return item == maxs
			})
			let params = {
				name: this.equipNameData[maxIndex]['name'] || '待赋值',
				type: '钾离子',
				val: maxs + this.echartsData[0]['unit'],
			}
			return params
		},
		twMax() {
			let maxs = Math.max(...this.equipDiaData.tw)
			let maxIndex = this.equipDiaData['tw'].findIndex((item) => {
				return item == maxs
			})
			let params = {
				name: this.equipNameData[maxIndex]['name'] || '待赋值',
				type: '土壤温度',
				val: maxs,
			}
			return params
		},
		tsMax() {
			let maxs = Math.max(...this.equipDiaData.ts)
			let maxIndex = this.equipDiaData['ts'].findIndex((item) => {
				return item == maxs
			})
			let params = {
				name: this.equipNameData[maxIndex]['name'] || '待赋值',
				type: '土壤湿度',
				val: maxs,
			}
			return params
		},
		// 最低值
		danMin() {
			let mins = Math.min(...this.equipDiaData.dan)
			let maxIndex = this.equipDiaData['dan'].findIndex((item) => {
				return item == mins
			})
			let params = {
				name: this.equipNameData[maxIndex]['name'] || '待赋值',
				type: '氮离子',
				val: mins + this.echartsData[0]['unit'],
			}
			return params
		},
		linMin() {
			let mins = Math.min(...this.equipDiaData.lin)
			let maxIndex = this.equipDiaData['lin'].findIndex((item) => {
				return item == mins
			})
			let params = {
				name: this.equipNameData[maxIndex]['name'] || '待赋值',
				type: '磷离子',
				val: mins + this.echartsData[1]['unit'],
			}
			return params
		},
		jiaMin() {
			let mins = Math.min(...this.equipDiaData.jia)
			let maxIndex = this.equipDiaData['jia'].findIndex((item) => {
				return item == mins
			})
			let params = {
				name: this.equipNameData[maxIndex]['name'] || '待赋值',
				type: '钾离子',
				val: mins + this.echartsData[0]['unit'],
			}
			return params
		},
		twMin() {
			let mins = Math.min(...this.equipDiaData.tw)
			let maxIndex = this.equipDiaData['tw'].findIndex((item) => {
				return item == mins
			})
			let params = {
				name: this.equipNameData[maxIndex]['name'] || '待赋值',
				type: '土壤温度',
				val: mins,
			}
			return params
		},
		tsMin() {
			let mins = Math.min(...this.equipDiaData.ts)
			let maxIndex = this.equipDiaData['ts'].findIndex((item) => {
				return item == mins
			})
			let params = {
				name: this.equipNameData[maxIndex]['name'] || '待赋值',
				type: '土壤湿度',
				val: mins,
			}
			return params
		},
		danMaxCoord() {
			let maxs = Math.max(...this.equipDiaData.dan)
			let maxIndex = this.equipDiaData['dan'].findIndex((item) => {
				return item == maxs
			})
			return [maxIndex, maxs]
		},
		linMaxCoord() {
			let maxs = Math.max(...this.equipDiaData.lin)
			let maxIndex = this.equipDiaData['lin'].findIndex((item) => {
				return item == maxs
			})
			return [maxIndex, maxs]
			// return [2, 91]
		},
		jiaMaxCoord() {
			let maxs = Math.max(...this.equipDiaData.jia)
			let maxIndex = this.equipDiaData['jia'].findIndex((item) => {
				return item == maxs
			})
			return [maxIndex, maxs]
		},
		twMaxCoord() {
			let maxs = Math.max(...this.equipDiaData.tw)
			let maxIndex = this.equipDiaData['tw'].findIndex((item) => {
				return item == maxs
			})
			return [maxIndex, maxs]
		},
		tsMaxCoord() {
			let maxs = Math.max(...this.equipDiaData.ts)
			let maxIndex = this.equipDiaData['ts'].findIndex((item) => {
				return item == maxs
			})
			return [maxIndex, maxs]
		},

		danMinCoord() {
			let mins = Math.min(...this.equipDiaData.dan)
			let minIndex = this.equipDiaData['dan'].findIndex((item) => {
				return item == mins
			})
			return [minIndex, mins]
		},
		linMinCoord() {
			let mins = Math.min(...this.equipDiaData.lin)
			let minIndex = this.equipDiaData['lin'].findIndex((item) => {
				return item == mins
			})
			return [minIndex, mins]
		},
		jiaMinCoord() {
			let mins = Math.min(...this.equipDiaData.jia)
			let minIndex = this.equipDiaData['jia'].findIndex((item) => {
				return item == mins
			})
			return [minIndex, mins]
		},
		twMinCoord() {
			let mins = Math.min(...this.equipDiaData.tw)
			let minIndex = this.equipDiaData['tw'].findIndex((item) => {
				return item == mins
			})
			return [minIndex, mins]
		},
		tsMinCoord() {
			let mins = Math.min(...this.equipDiaData.ts)
			let minIndex = this.equipDiaData['ts'].findIndex((item) => {
				return item == mins
			})
			return [minIndex, mins]
		},
	},
	watch: {
		// 监听弹出层的类型，主要用于播放视频的停止
		openDiaType(val, oldVal) {
			if (val != 'mq') {
				if (this.player) {
					this.player.stop()
					this.player = null
				}
				// hls视频流销毁
				this.closeVideo()
			}
		},
	},
	mounted() {
		// 气象数据
		// this.getQxData()
		// 气象下拉选
		// this.getQxSelect()
		// // 虫情图片
		// this.getCqImg()
		// // 虫情折线图数据
		// this.getCqLineData(this.timeSlotChange('本月').time1, this.timeSlotChange('本月').time2)
		// // 虫情折线图
		// this.cqLineChart()
		// // 虫情防治
		// this.getCqControlData()
		// 墒情列表数据
		// this.getSqData()
		// 墒情进度条饼图
		// this.sqPieEcharts()
		// 墒情下拉选数据
		// this.getSqSelect()
		// 苗情下面的过程建议
		// this.getMqBottom()
		// 肥力感知
		// this.getCenterSfEcharts()
		// 土壤感知
		// this.getCenterSqEcharts('16082057')
		// 苗情/视频获取token
		// this.getToken()
	},
	methods: {
		dotStyle(name) {
			let color = ''
			color = this.echartsColor[name]
			// if (name === '磷离子' || name == '土温') {
			// 	color = 'rgb(168,212,56)'
			// } else if (name === '钾离子' || name == '土湿') {
			// 	color = 'rgb(54,215,152)'
			// } else {
			// 	color = 'rgba(56,190,211,1)'
			// }
			return {
				backgroundColor: color,
			}
		},
		// 折线图的时间选择
		changeTime(ind) {
			if (ind == '0') {
				this.idNoSelQuarter = false
				this.idNoSelQuarter1 = false
				if (this.openDiaType == 'equip' && this.diaType == 'sq') {
					this.sqSelMultiple = true
					this.sqSelectMultipleNum = 3
					this.sqChoose2 = ''
					let now = this.getNowDate()
					this.sqrange = [now, now]
					this.sqQuery.startTime = this.sqrange[0]
					this.sqQuery.endTime = this.sqrange[1]
					this.$nextTick(() => {
						this.getSqEcharts()
					})
				} else if (this.openDiaType == 'equip' && this.diaType == 'qx') {
					this.qxSelMultiple = true
					this.qxSelectMultipleNum = 3
					let now = this.getNowDate()
					this.qxrange = [now, now]
					this.qxQuery.startTime = this.qxDefaultVal[0]
					this.qxQuery.endTime = this.qxDefaultVal[1]
					this.$nextTick(() => {
						this.getQxEcharts()
					})
				}
			} else if (ind == '1') {
				this.idNoSelQuarter = false
				this.idNoSelQuarter1 = false
				if (this.openDiaType == 'equip' && this.diaType == 'sq') {
					this.sqSelMultiple = true

					this.sqSelectMultipleNum = 3
					this.sqChoose2 = ''
					let now = this.getNowDate()
					this.sqrange = [this.timeSlotChange('本月').time1, this.timeSlotChange('本月').time2]
					this.sqQuery.startTime = this.sqrange[0]
					this.sqQuery.endTime = this.sqrange[1]
					this.$nextTick(() => {
						this.getSqEcharts()
					})
				} else if (this.openDiaType == 'equip' && this.diaType == 'qx') {
					this.qxSelMultiple = true
					this.qxSelectMultipleNum = 3
					let now = this.getNowDate()
					this.qxrange = [this.timeSlotChange('本月').time1, this.timeSlotChange('本月').time2]
					this.qxQuery.startTime = this.qxrange[0]
					this.qxQuery.endTime = this.qxrange[1]
					this.$nextTick(() => {
						this.getQxEcharts()
					})
				}
			} else if (ind == '2') {
				this.idNoSelQuarter = true
				this.idNoSelQuarter1 = true
				if (this.openDiaType == 'equip' && this.diaType == 'sq') {
					this.sqSelectMultipleNum = 1
					this.sqSelMultiple = false
					// console.log(this.sqParams);
					// console.log(this.sqChoose);
					this.sqChoose2 = this.sqParams[0].itemCode
					let unit = ''
					for (let i = 0; i < this.sqParams.length; i++) {
						if (this.sqChoose2 == this.sqParams[i].itemCode) {
							unit = this.sqParams[i].itemUnitCode
						}
					}
					// console.log(unit);
					// console.log("商请弹窗");
					let now = new Date()
					let nowYear = now.getYear() // 当前年
					nowYear += nowYear < 2000 ? 1900 : 0
					this.getSqQuarterEcharts(nowYear, unit)
				} else if (this.openDiaType == 'equip' && this.diaType == 'qx') {
					this.qxSelectMultipleNum = 1
					this.qxSelMultiple = false
					this.qxChoose2 = this.qxParams[0].itemCode
					let unit = ''
					for (let i = 0; i < this.qxParams.length; i++) {
						if (this.qxChoose2 == this.qxParams[i].itemCode) {
							unit = this.qxParams[i].itemUnitCode
						}
					}
					let now = new Date()
					let nowYear = now.getYear() // 当前年
					nowYear += nowYear < 2000 ? 1900 : 0
					this.getQxQuarter(nowYear, unit)
				}
			}
			this.timeIndex = ind
		},

		// 弹窗点击关闭
		closeDia() {
			if (!this.isCanClose) return
			this.openDiaType = ''
			this.diaType = ''
			this.timeIndex = '0'
			this.diaLeft = '0px'
			this.diaTop = '0px'
			this.bigImgs = ''
			this.isBigImg = false
			// 预警关闭
			this.isOpenWarn = false
			this.warnLeft = '0'
			this.warnTop = '0'
			this.isWarn = false

			// 弹窗时间置空
			this.cqTimeScope = []

			// 画布折线图取消
			let ctxdomWarn = this.$refs.canvasewarn
			ctxdomWarn.width = 0
			ctxdomWarn.height = 0
			let ctxdomEquip = this.$refs.canvasequips
			ctxdomEquip.width = 0
			ctxdomEquip.height = 0
			// 抓图置空
			this.captureList = []
			this.isShowMqbtn = false

			if (this.player) {
				this.player.stop()
				this.player = null
			}
			// hls视频流销毁
			this.closeVideo()
		},
		closeBigImg() {
			this.bigImgs = ''
			this.isBigImg = false
			this.showVideos()
		},
		// 预警信息弹窗打开
		openWarn() {
			this.isOpenWarn = true
			// 主要用于判断设置智脑中心地图上的弹窗位置
			let jianNum = 70
			if (this.tabIndex == 2 && this.isZnMap) {
				jianNum = 55
			} else {
				jianNum = 70
			}
			this.canvasWarnLine('#F82927', this.warnX, Number(this.warnY) - jianNum, 100, 150)
		},
		// 预警弹窗关闭
		closeWarn() {
			this.isOpenWarn = false
			// this.warnLeft = '0'
			// this.warnTop = '0'
			let ctxdomWarn = this.$refs.canvasewarn
			ctxdomWarn.width = 0
			ctxdomWarn.height = 0
		},
		// 右边tab切换
		switchTab(type) {
			if (this.tabType == type) {
				return
			}
			this.tabType = type
		},
		// 获取气象数据
		getQxData() {
			this.$http.https(this.$api.latestWeatherData + '?stationCode=' + this.equipId, 'get', '', (res) => {
				if (res.meta && res.meta.success) {
					let datas = res.data.sensorVOs || []
					let qxList = []
					this.qxDefaultList.forEach((item) => {
						datas.forEach((items) => {
							if (item.type == 'FX' && items.key == 'FX') {
								qxList.push({
									title: items.name,
									icon: item.icon,
									dushu: this.fengxiang(parseFloat(items.value)),
									unit: items.unitName,
									type: item.type,
								})
								// item.dushu = this.fengxiang(parseFloat(items.value))
							} else {
								if (item.type == items.key) {
									// item.title = items.name
									// item.dushu = items.value
									// item.unit = items.unitName
									qxList.push({
										title: items.name,
										icon: item.icon,
										dushu: items.value,
										unit: items.unitName,
										type: item.type,
									})
								}
							}
						})
					})
					this.qxList = qxList
				}
			})
			// const { data: res } = await this.$http.get(this.$api.latestWeatherData + '?stationCode=' + this.equipId)
		},
		// 气象下拉选数据
		getQxSelect() {
			this.$http.https(this.$api.qxSelectItem + '?stationCode=' + this.equipId, 'get', '', (res) => {
				if (res.meta.success) {
					let datas = res.data || []
					this.qxParams = datas
					// 设置默认的折线图请求类型
					if (datas && datas.length) {
						this.isQxLine = true
						this.qxQuery.dicts = datas[0]['itemCode']
						// 时间赋值
						let now = this.getNowDate()
						this.qxChoose = [datas[0]['itemCode']]
						this.qxDefaultVal = [now, now]
						this.qxrange = [now, now]
						this.qxQuery.startTime = this.qxDefaultVal[0]
						this.qxQuery.endTime = this.qxDefaultVal[1]

						// this.qxQuery.startTime = '2021-10-12'
						// this.qxQuery.endTime = '2021-10-22'
						// 请求数据
						this.$nextTick(() => {
							this.getQxEcharts()
						})
					} else {
						this.isQxLine = false
					}
				}
			})
			// const { data: res } = await this.$http.get(this.$api.qxSelectItem + '?stationCode=' + this.equipId)
		},
		// 气象下拉选选择
		qxSelect(val) {
			this.qxChoose = val
			if (val.length) {
				// 请求数据
				this.$nextTick(() => {
					this.getQxEcharts()
				})
			} else {
				this.isQxLine = false
			}
		},
		qxSelect2() {
			this.isQxLine = true
			let unit = ''
			for (let i = 0; i < this.qxParams.length; i++) {
				if (this.qxChoose2 == this.qxParams[i].itemCode) {
					unit = this.qxParams[i].itemUnitCode
				}
			}
			let now = new Date()
			let nowYear = now.getYear() // 当前年
			nowYear += nowYear < 2000 ? 1900 : 0
			this.getQxQuarter(nowYear, unit)
		},
		// 气象折线数据获取
		getQxEcharts() {
			this.qxQuery.dicts = this.qxChoose.join(',')
			let params = this.qxQuery
			params.stationCode = this.equipId
			this.$http.https(this.$api.envirTimesDatas, 'get', this.qxQuery, (res) => {
				// 请求成功,且折线图有数据
				if (res.meta.success && res.data.series) {
					this.isQxLine = true
					var data1 = []
					var data2 = JSON.parse(JSON.stringify(res.data.series))
					var xdata = []
					res.data.series.forEach((item, index) => {
						let sdata = []
						item.data.forEach((child, idx) => {
							sdata.push(child[0])
						})
						xdata = xdata.concat(sdata)
						item.symbol = 'none'
						item.animationDuration = 3000
						item.animationDelay = 200
						if (res.data.yAxis.length <= 3) {
							item.yAxisIndex = index
						}
						delete item.yaxisIndex
					})
					// 时间排序
					xdata.sort(function (a, b) {
						return b < a ? 1 : -1
					})
					//去重
					let newxdata = xdata.reduce((pre, cur) => {
						if (!pre.includes(cur)) {
							return pre.concat(cur)
						} else {
							return pre
						}
					}, [])
					newxdata.forEach((xitem, xindex) => {
						res.data.series.forEach((emitem, emindex) => {
							let em = data2[emindex]['data']
							em[xindex] = 0
							emitem.data.forEach((child, idx) => {
								if (child[0] == xitem) {
									em[xindex] = Number(child[1])
								}
							})
						})
					})
					//series
					if (res.data.yAxis.length > 3) {
						data1 = [{ type: 'value', show: false }]
					} else {
						data1 = []
						res.data.yAxis = res.data.yAxis.reverse()
						res.data.yAxis.forEach((item, index) => {
							let aa = {
								name: '',
								type: 'value',
								show: true,
								axisLine: {
									show: false,
									lineStyle: {
										color: 'rgba(23, 157, 160, 0.2)',
										width: 2,
									},
								},
								axisLabel: { color: '#fff', show: true },
								axisTick: {
									show: false,
								},
								nameTextStyle: {
									color: '#fff',
								},
								splitLine: {
									show: true,
									lineStyle: {
										color: 'rgba(23, 157, 160, 0.2)',
									},
								},
							}
							if (index != 0) {
								aa.position = 'left'
								aa.offset = index * 40
								aa.splitLine.show = false
							}
							aa.name = item.formatter
							data1.push(aa)
						})
					}
					data2.forEach((item) => {
						if (item.name == '地温') {
							item.name = '土壤温度'
						}
						if (item.name == '体积含水量') {
							item.name = '土壤湿度'
						}
					})
					this.$nextTick(() => {
						// 绘制折线图
						this.qxEcharts(newxdata, data1, data2)
					})
				} else {
					this.isQxLine = false
				}
			})
			// const { data: res } = await this.$http.get(this.$api.envirTimesDatas, this.qxQuery)
		},
		// 气象折线图
		qxEcharts(xdata, data1, data2) {
			let myChart = this.$echarts.init(this.$refs.qxchart)
			var num = '10%'
			if (data1.length == 1) {
				num = '10%'
			}
			if (data1.length == 2) {
				num = '10%'
			}
			if (data1.length == 3) {
				num = '10%'
			}
			if (data1.length > 3) {
				num = '5%'
			}
			var option = {
				color: ['#27FCFF', '#5FD0FF', '#FFDA77', '#1A67A4', '#FFD929', '#CB98FF'],
				tooltip: {
					trigger: 'axis',
					backgroundColor: 'rgb(113,200,255, 0.28)',
					borderWidth: 0,
					axisPointer: {
						type: 'line',
					},
					textStyle: {
						fontSize: 15,
						color: '#fff',
					},
					transitionDuration: 0,
				},
				legend: {
					type: 'scroll',
					textStyle: {
						color: '#fff',
					},
					top: '3%',
					left: 'center',
					width: '70%',
					icon: 'rect',
					pageIconColor: '#01EDF6',
					pageIconInactiveColor: '#0F8088',
					pageTextStyle: {
						color: '#fff',
					},
					// 设置图例不可点击
					selectedMode: false,
				},
				grid: {
					left: num,
					right: '6%',
					bottom: '4%',
					top: '10%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						// boundaryGap: false,
						data: xdata,
						nameTextStyle: {
							fontSize: 14,
						},
						axisLine: {
							onZero: false,
							lineStyle: {
								color: 'rgba(23, 157, 160, 0.2)',
								width: 2,
							},
						},
						axisTick: {
							show: false,
						},
						axisLabel: {
							color: '#fff',
							// interval: 0,
						},
					},
				],
				yAxis: data1,
				series: data2,
			}
			myChart.clear()
			myChart.setOption(option, true)
			window.addEventListener('resize', function () {
				myChart.resize()
			})
			this.tooltipTimerQx && this.tooltipTimerQx.clearLoop()
			this.tooltipTimerQx = 0
			this.tooltipTimerQx = loopShowTooltip(myChart, option, {
				interval: 3000, // 轮播间隔时间
				loopSeries: true, // 是否开启轮播循环
				dataLength: xdata.length,
			})
		},
		// 气象季度报警柱状图数据
		getQxQuarter(year, unit) {
			this.qxQuery.dicts = this.qxChoose.join(',')
			let params = {
				year: year,
				colCode: this.qxChoose2,
				// colCode:"FS"
			}
			this.$http.https(this.$api.sqQuarterWarm, 'get', params, (res) => {
				console.log(res)
				if (res.data) {
					let average = res.data.average
					let highest = res.data.highest
					let lowest = res.data.lowest
					let facilityId = this.equipId
					let data_ = {
						facilityId: facilityId,
						year: year,
					}
					this.$http.post(this.$api.sqQuarterWarm2, data_).then((info) => {
						console.log(info)
						if (info.data) {
							if (info.data.code == 501) {
								this.isQxLine = false
							} else {
								this.isQxLine = true
								let warming = info.data.data.series[0].data
								let txtName = ''
								for (let i = 0; i < this.qxParams.length; i++) {
									if (this.qxChoose2 == this.qxParams[i].itemCode) {
										txtName = this.qxParams[i].itemName
									}
								}
								// let warming = ""
								this.qxQuarterEcharts(average, highest, lowest, warming, txtName, unit)
							}
						} else {
							this.isQxLine = false
						}
					})
				} else {
					this.isQxLine = false
				}
			})
		},
		// 气象季度报警柱状图
		qxQuarterEcharts(yData1, yData2, ydata3, yData4, txt, unit) {
			let myChart = this.$echarts.init(this.$refs.qxchart)
			let y1 = yData1
			let y2 = yData2
			let y3 = ydata3
			let y4 = yData4
			let x1 = ['第一季度', '第二季度', '第三季度', '第四季度']
			// let y1 = [10, 20, 5, 9];
			// let y2 = [20, 12, 31, 34];
			// let y3 = [1, 1, 2, 3];
			// let y4 = [10, 20, 5, 9];
			// let txt = "ss"
			// let unit = "mm"
			console.log(txt)
			let option = {
				tooltip: {
					// trigger: 'axis',
					// textStyle: {
					// 	color: "rgba(0, 0, 0, 1)"
					// },
					// backgroundColor: 'rgba(255, 255, 255, .7)',
					// borderColor: "rgba(255, 255, 255, .7)",
					// extraCssText: 'box-shadow: 0 0 20px rgba(45, 174, 169, 0.5);',
					trigger: 'axis',
					backgroundColor: 'rgb(113,200,255, 0.28)',
					borderWidth: 0,
					axisPointer: {
						type: 'line',
					},
					textStyle: {
						fontSize: 15,
						color: '#fff',
					},
					transitionDuration: 0,
					formatter: (arg) => {
						let info = ''
						let name = arg[0].name + txt
						let average = arg[0].value + unit
						let max = arg[1].value + unit
						let min = arg[2].value + unit
						let warn = arg[3].value + '次'
						// console.log(arg);
						info =
							name +
							'<br/>' +
							"平均值：<span style='color:#2daea9'>" +
							average +
							'</span><br/>' +
							"最高值：<span style='color:#ffbb29'>" +
							max +
							'</span><br/>' +
							"最低值：<span style='color:#47b8ff'>" +
							min +
							'</span><br/>' +
							"告警次数：<span style='color:#ff4c4c'>" +
							warn +
							'</span>'
						return info
					},
					rich: {
						g: {
							color: '#2daea9',
						},
						yellow: {
							color: '#ffbb29',
						},
						blue: {
							color: '#47b8ff',
						},
						red: {
							color: '#ff4c4c',
						},
					},
				},
				color: ['#27fcff', '#5fd0ff', '#ffda77', '#ff4f4f'],
				legend: {
					textStyle: {
						color: 'rgba(255, 255, 255, 1)',
					},
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						data: x1,
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: {
							color: 'rgba(255, 255, 255, 1)',
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '(' + unit + ')',
						axisLabel: {
							formatter: '{value}',
						},
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						nameTextStyle: {
							padding: [0, 20, 0, 0],
							color: 'rgba(255, 255, 255, 1)',
						},
						axisLabel: {
							color: 'rgba(255, 255, 255, 1)',
						},
						splitLine: {
							lineStyle: {
								color: 'rgba(23, 157, 160, 0.2)',
							},
						},
					},
					{
						type: 'value',
						name: '(次)',
						position: 'right',
						axisLabel: {
							formatter: '{value}',
						},
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						nameTextStyle: {
							padding: [0, 0, 0, 25],
							color: 'rgba(255, 255, 255, 1)',
						},
						splitLine: {
							show: false,
						},
						axisLabel: {
							color: 'rgba(255, 255, 255, 1)',
						},
					},
				],
				series: [
					{
						name: '平均值',
						type: 'bar',
						data: y1,
						itemStyle: {
							color: '#2daea9',
						},
						barWidth: '10%',
					},
					{
						name: '最高值',
						type: 'bar',
						data: y2,
						itemStyle: {
							color: '#ffbb29',
						},
						barWidth: '10%',
					},
					{
						name: '最低值',
						type: 'bar',
						data: y3,
						itemStyle: {
							color: '#47b8ff',
						},
						barWidth: '10%',
					},
					{
						name: '告警次数',
						type: 'line',
						yAxisIndex: 1,
						data: y4,
						itemStyle: {
							color: '#ff4c4c',
						},
					},
				],
			}
			myChart.clear()
			myChart.setOption(option)
		},
		// 气象自定义聚焦
		qxTimeZdy() {
			this.timeIndex = '3'
			this.qxSelMultiple = true
			this.qxSelectMultipleNum = 3
			this.$refs.qxpicker.focus()
		},
		// 气象自定义时间选择点击
		qxTimeScopeClick() {
			let startTime = this.formatDate(this.qxTimeScope[0])
			let endTime = this.formatDate(this.qxTimeScope[1])
			this.qxQuery.startTime = startTime
			this.qxQuery.endTime = endTime
			this.$nextTick(() => {
				this.getQxEcharts()
			})
		},
		// 墒情实时数据
		getSqData() {
			this.$http.https(this.$api.latestWeatherData + '?stationCode=' + this.equipId, 'get', '', (res) => {
				if (res.meta && res.meta.success) {
					let datas = res.data.sensorVOs || []
					let data1 = []
					let data2 = []
					datas.forEach((item) => {
						if (item.key.indexOf('TRWD') > -1) {
							data1.push(item.value)
						} else if (item.key.indexOf('TRSD') > -1) {
							data2.push(item.value)
						}
						if (item.key.indexOf('EC') > -1) {
							this.trEc = item.value
						}
						if (item.key.indexOf('PH') > -1) {
							this.trPh = item.value
						}
						if (item.key == 'DLZ') {
							this.trDlz = item.value
						} else if (item.key == 'LLZ') {
							this.trLlz = item.value
						} else if (item.key == 'JLZ') {
							this.trJlz = item.value
						}
					})

					this.trWd = data1
					this.trSd = data2
					// this.$nextTick(() => {
					// 	this.sqPieEcharts(dataEcharts)
					// })
				}
			})
		},
		// 墒情进度条饼图
		// TODO 不用了
		sqPieEcharts(datas) {
			let _this = this
			let myChart = this.$echarts.init(this.$refs.sqEchartsDan)
			let value = 80
			var data = datas
			var center = ['15', '50', '85']
			var titleArr = [],
				seriesArr = []
			let colors = [
				['#E38B0A', '#18B3D4'],
				['#18B3D4', '#ffdcc3'],
				['#18AA3E', '#ffedcc'],
				['#fd6f97', '#fed4e0'],
				['#a181fc', '#e3d9fe'],
			]
			data.forEach(function (item, index) {
				titleArr.push({
					text: item.name,
					left: index * 36 + 12 + '%',
					bottom: 0,
					textAlign: 'center',
					textStyle: {
						fontWeight: 'normal',
						fontSize: '16',
						color: '#FFFFFF',
						textAlign: 'center',
					},
				})
				seriesArr.push({
					name: item.name,
					type: 'pie',
					clockWise: false,
					radius: [38, 43],
					itemStyle: {
						normal: {
							color: colors[index][0],
							shadowColor: colors[index][0],
							shadowBlur: 0,
							label: {
								show: false,
							},
							labelLine: {
								show: false,
							},
						},
					},
					hoverAnimation: false,
					center: [center[index] + '%', '38%'],
					data: [
						{
							value: item.value,
							label: {
								normal: {
									formatter: function (params) {
										return params.value
									},
									position: 'center',
									show: true,
									textStyle: {
										fontSize: '20',
										fontWeight: 'bold',
										color: '#FFFFFF',
									},
								},
							},
							itemStyle: {
								normal: {
									color: colors[index][0],
								},
							},
						},
						{
							value: 100 - item.value,
							name: 'invisible',
							itemStyle: {
								normal: {
									color: colors[index][0],
								},
								emphasis: {
									color: colors[index][0],
								},
							},
						},
					],
				})
				seriesArr.push({
					name: 'bg',
					type: 'pie',
					clockWise: false,
					radius: [28, 30],
					itemStyle: {
						normal: {
							color: colors[index][0],
							shadowColor: colors[index][0],
							shadowBlur: 0,
							label: {
								show: false,
							},
							labelLine: {
								show: false,
							},
						},
					},
					hoverAnimation: false,
					center: [center[index] + '%', '38%'],
					data: [
						{
							value: item.value,
							itemStyle: {
								normal: {
									color: 'rgba(255,255,255,0.43)',
								},
								emphasis: {
									color: colors[index][1],
								},
							},
							label: {
								show: false,
							},
						},
						{
							value: 100 - item.value,
							name: 'invisible',
							itemStyle: {
								normal: {
									color: 'rgba(255,255,255,0.43)',
								},
								emphasis: {
									color: colors[index][1],
								},
							},
						},
					],
				})
			})
			let option = {
				title: titleArr,
				series: seriesArr,
			}
			myChart.clear()
			myChart.setOption(option, true)
		},
		// 墒情层级切换
		tapSqCeng(ind) {
			if (this.trCengIndex == ind) return
			this.trCengIndex = ind
		},
		// 墒情下拉选数据
		getSqSelect() {
			this.$http.https(this.$api.qxSelectItem + '?stationCode=' + this.equipId, 'get', '', (res) => {
				if (res.meta.success) {
					let datas = res.data || []
					let sqParams = []
					datas.forEach((item) => {
						if (item.itemCode != 'DL') {
							sqParams.push(item)
						}
					})
					this.sqParams = sqParams
					// 设置默认的折线图请求类型
					if (datas && datas.length) {
						this.isSqLine = true
						this.sqQuery.dicts = datas[0]['itemCode']
						// 时间赋值
						let now = this.getNowDate()
						this.sqChoose = [datas[0]['itemCode']]
						this.sqDefaultVal = [now, now]
						this.sqrange = [now, now]
						this.sqQuery.startTime = this.sqDefaultVal[0]
						this.sqQuery.endTime = this.sqDefaultVal[1]

						// this.sqQuery.startTime = '2021-10-12'
						// this.sqQuery.endTime = '2021-10-22'
						// 请求数据
						this.$nextTick(() => {
							this.getSqEcharts()
						})
					} else {
						this.isSqLine = false
					}
				}
			})
			// const { data: res } = await this.$http.get(this.$api.qxSelectItem + '?stationCode=' + this.equipId)
		},
		// 墒情下拉选选择
		sqSelect(val) {
			this.sqChoose = val
			if (val.length) {
				if (this.idNoSelQuarter) {
					this.isSqLine = true
					let unit = ''
					for (let i = 0; i < this.sqParams.length; i++) {
						if (this.sqChoose == this.sqParams[i].itemCode) {
							unit = this.sqParams[i].itemUnitCode
						}
					}
					let now = new Date()
					let nowYear = now.getYear() // 当前年
					nowYear += nowYear < 2000 ? 1900 : 0
					this.getSqQuarterEcharts(nowYear, unit)
				} else {
					// 请求数据
					this.$nextTick(() => {
						this.getSqEcharts()
					})
				}
			} else {
				this.isSqLine = false
			}
		},
		sqSelect2() {
			this.isSqLine = true
			let unit = ''
			for (let i = 0; i < this.sqParams.length; i++) {
				if (this.sqChoose2 == this.sqParams[i].itemCode) {
					unit = this.sqParams[i].itemUnitCode
				}
			}
			let now = new Date()
			let nowYear = now.getYear() // 当前年
			nowYear += nowYear < 2000 ? 1900 : 0
			this.getSqQuarterEcharts(nowYear, unit)
		},
		// 墒情折线数据获取
		getSqEcharts() {
			this.sqQuery.dicts = this.sqChoose.join(',')
			let params = this.sqQuery
			params.stationCode = this.equipId
			this.$http.https(this.$api.envirTimesDatas, 'get', this.sqQuery, (res) => {
				// 请求成功,且折线图有数据
				if (res.meta.success && res.data) {
					this.isSqLine = true
					var data1 = []
					var data2 = JSON.parse(JSON.stringify(res.data.series))
					var xdata = []
					res.data.series.forEach((item, index) => {
						let sdata = []
						item.data.forEach((child, idx) => {
							sdata.push(child[0])
						})
						xdata = xdata.concat(sdata)
						item.symbol = 'none'
						item.animationDuration = 3000
						item.animationDelay = 200
						if (res.data.yAxis.length <= 3) {
							item.yAxisIndex = index
						}
						delete item.yaxisIndex
					})
					// 时间排序
					xdata.sort(function (a, b) {
						return b < a ? 1 : -1
					})
					//去重
					let newxdata = xdata.reduce((pre, cur) => {
						if (!pre.includes(cur)) {
							return pre.concat(cur)
						} else {
							return pre
						}
					}, [])
					newxdata.forEach((xitem, xindex) => {
						res.data.series.forEach((emitem, emindex) => {
							let em = data2[emindex]['data']
							em[xindex] = 0
							emitem.data.forEach((child, idx) => {
								if (child[0] == xitem) {
									em[xindex] = Number(child[1])
								}
							})
						})
					})
					//series
					if (res.data.yAxis.length > 3) {
						data1 = [{ type: 'value', show: false }]
					} else {
						data1 = []
						res.data.yAxis = res.data.yAxis.reverse()
						res.data.yAxis.forEach((item, index) => {
							let aa = {
								name: '',
								type: 'value',
								show: true,
								axisLine: {
									show: false,
									lineStyle: {
										color: 'rgba(23, 157, 160, 0.2)',
										width: 2,
									},
								},
								axisLabel: { color: '#fff', show: true },
								axisTick: {
									show: false,
								},
								nameTextStyle: {
									color: '#fff',
								},
								splitLine: {
									show: true,
									lineStyle: {
										color: 'rgba(23, 157, 160, 0.2)',
									},
								},
							}
							if (index != 0) {
								aa.position = 'left'
								aa.offset = index * 40
								aa.splitLine.show = false
							}
							aa.name = item.formatter
							data1.push(aa)
						})
					}
					data2.forEach((item) => {
						if (item.name == '地温') {
							item.name = '土壤温度'
						}
						if (item.name == '体积含水量') {
							item.name = '土壤湿度'
						}
					})
					this.$nextTick(() => {
						// 绘制折线图
						this.sqEcharts(newxdata, data1, data2)
					})
				} else {
					this.isSqLine = false
				}
			})
			// const { data: res } = await this.$http.get(this.$api.envirTimesDatas, this.sqQuery)
		},
		// 墒情折线图
		sqEcharts(xdata, data1, data2) {
			let myChart = this.$echarts.init(this.$refs.sqEcharts)

			var num = '5%'
			if (data1.length == 1) {
				num = '5%'
			}
			if (data1.length == 2) {
				num = '7%'
			}
			if (data1.length == 3) {
				num = '10%'
			}
			if (data1.length > 3) {
				num = '5%'
			}
			var option = {
				color: ['#27FCFF', '#5FD0FF', '#FFDA77', '#1A67A4', '#FFD929', '#CB98FF'],
				tooltip: {
					trigger: 'axis',
					backgroundColor: 'rgb(113,200,255, 0.28)',
					borderWidth: 0,
					axisPointer: {
						type: 'line',
					},
					textStyle: {
						fontSize: 15,
						color: '#fff',
					},
					transitionDuration: 0,
				},
				legend: {
					type: 'scroll',
					textStyle: {
						color: '#fff',
					},
					top: '3%',
					left: 'center',
					width: '70%',
					icon: 'rect',
					pageIconColor: '#01EDF6',
					pageIconInactiveColor: '#0F8088',
					pageTextStyle: {
						color: '#fff',
					},
					selectedMode: false,
				},
				grid: {
					left: num,
					right: '6%',
					bottom: '4%',
					top: '15%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						// boundaryGap: false,
						data: xdata,
						nameTextStyle: {
							fontSize: 14,
						},
						axisLine: {
							onZero: false,
							lineStyle: {
								color: 'rgba(23, 157, 160, 0.2)',
								width: 2,
							},
						},
						axisTick: {
							show: false,
						},
						axisLabel: {
							color: '#fff',
							// interval: 0,
						},
					},
				],
				yAxis: data1,
				series: data2,
			}
			myChart.clear()
			myChart.setOption(option)
			window.addEventListener('resize', function () {
				myChart.resize()
			})
			this.tooltipTimerSq && this.tooltipTimerSq.clearLoop()
			this.tooltipTimerSq = 0
			this.tooltipTimerSq = loopShowTooltip(myChart, option, {
				interval: 3000, // 轮播间隔时间
				loopSeries: true, // 是否开启轮播循环
				dataLength: xdata.length,
			})
		},
		// 商情季度报警柱状图数据获取
		getSqQuarterEcharts(year, unit) {
			this.sqQuery.dicts = this.sqChoose.join(',')
			console.log(this.sqQuery.dicts)
			console.log(this.sqChoose2)
			let params = {
				year: year,
				colCode: this.sqChoose2,
			}
			this.$http.https(this.$api.sqQuarterWarm, 'get', params, (res) => {
				console.log(res)
				if (res.data) {
					let average = res.data.average
					let highest = res.data.highest
					let lowest = res.data.lowest
					let facilityId = this.equipId
					let data_ = {
						facilityId: facilityId,
						year: year,
					}
					// console.log("执行");
					this.$http.post(this.$api.sqQuarterWarm2, data_).then((info) => {
						console.log(info.data)
						if (info.data) {
							if (info.data.code == 501) {
								this.isSqLine = false
							} else {
								this.isSqLine = true
								let warming = info.data.data.series[0].data
								// let warming = ""
								let txtName = ''
								console.log(this.sqParams)
								for (let i = 0; i < this.sqParams.length; i++) {
									if (this.sqChoose2 == this.sqParams[i].itemCode) {
										txtName = this.sqParams[i].itemName
									}
								}
								this.sqQuarterEcharts(average, highest, lowest, warming, txtName, unit)
							}
						} else {
							this.isSqLine = false
						}
					})
				} else {
					this.isSqLine = false
				}
			})
		},
		// 墒情季度报警折线图
		sqQuarterEcharts(yData1, yData2, ydata3, yData4, txt, unit) {
			let myChart = this.$echarts.init(this.$refs.sqEcharts)
			let y1 = yData1
			let y2 = yData2
			let y3 = ydata3
			let y4 = yData4
			let x1 = ['第一季度', '第二季度', '第三季度', '第四季度']
			// let y1 = [10, 20, 5, 9];
			// let y2 = [20, 12, 31, 34];
			// let y3 = [1, 1, 2, 3];
			// let y4 = [10, 20, 5, 9];
			// let txt = "ss"
			// let unit = "mm"
			let option = {
				tooltip: {
					// trigger: 'axis',
					// textStyle: {
					// 	color: "rgba(0, 0, 0, 1)"
					// },
					// backgroundColor: 'rgba(255, 255, 255, .7)',
					// borderColor: "rgba(255, 255, 255, .7)",
					// extraCssText: 'box-shadow: 0 0 20px rgba(45, 174, 169, 0.5);',
					trigger: 'axis',
					backgroundColor: 'rgb(113,200,255, 0.28)',
					borderWidth: 0,
					axisPointer: {
						type: 'line',
					},
					textStyle: {
						fontSize: 15,
						color: '#fff',
					},
					transitionDuration: 0,
					formatter: (arg) => {
						let info = ''
						let name = arg[0].name + txt
						let average = arg[0].value + unit
						let max = arg[1].value + unit
						let min = arg[2].value + unit
						let warn = arg[3].value + '次'
						// console.log(arg);
						info =
							name +
							'<br/>' +
							"平均值：<span style='color:#2daea9'>" +
							average +
							'</span><br/>' +
							"最高值：<span style='color:#ffbb29'>" +
							max +
							'</span><br/>' +
							"最低值：<span style='color:#47b8ff'>" +
							min +
							'</span><br/>' +
							"告警次数：<span style='color:#ff4c4c'>" +
							warn +
							'</span>'
						return info
					},
					rich: {
						g: {
							color: '#2daea9',
						},
						yellow: {
							color: '#ffbb29',
						},
						blue: {
							color: '#47b8ff',
						},
						red: {
							color: '#ff4c4c',
						},
					},
				},
				color: ['#27fcff', '#5fd0ff', '#ffda77', '#ff4f4f'],
				legend: {
					textStyle: {
						color: 'rgba(255, 255, 255, 1)',
					},
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true,
				},
				xAxis: [
					{
						type: 'category',
						data: x1,
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						axisLabel: {
							color: 'rgba(255, 255, 255, 1)',
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '(' + unit + ')',
						axisLabel: {
							formatter: '{value}',
						},
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						nameTextStyle: {
							padding: [0, 20, 0, 0],
							color: 'rgba(255, 255, 255, 1)',
						},
						axisLabel: {
							color: 'rgba(255, 255, 255, 1)',
						},
						splitLine: {
							lineStyle: {
								color: 'rgba(23, 157, 160, 0.2)',
							},
						},
					},
					{
						type: 'value',
						name: '(次)',
						position: 'right',
						axisLabel: {
							formatter: '{value}',
						},
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						nameTextStyle: {
							padding: [0, 0, 0, 25],
							color: 'rgba(255, 255, 255, 1)',
						},
						splitLine: {
							show: false,
						},
						axisLabel: {
							color: 'rgba(255, 255, 255, 1)',
						},
					},
				],
				series: [
					{
						name: '平均值',
						type: 'bar',
						data: y1,
						itemStyle: {
							color: '#2daea9',
						},
						barWidth: '10%',
					},
					{
						name: '最高值',
						type: 'bar',
						data: y2,
						itemStyle: {
							color: '#ffbb29',
						},
						barWidth: '10%',
					},
					{
						name: '最低值',
						type: 'bar',
						data: y3,
						itemStyle: {
							color: '#47b8ff',
						},
						barWidth: '10%',
					},
					{
						name: '告警次数',
						type: 'line',
						yAxisIndex: 1,
						data: y4,
						itemStyle: {
							color: '#ff4c4c',
						},
					},
				],
			}
			myChart.clear()
			myChart.setOption(option)
		},
		// 气象自定义聚焦
		sqTimeZdy() {
			this.timeIndex = '3'
			this.sqSelectMultipleNum = 3
			this.sqSelMultiple = true
			this.idNoSelQuarter = false
			this.$refs.sqpicker.focus()
		},
		// 气象自定义时间选择点击
		sqTimeScopeClick() {
			let startTime = this.formatDate(this.sqTimeScope[0])
			let endTime = this.formatDate(this.sqTimeScope[1])
			this.sqQuery.startTime = startTime
			this.sqQuery.endTime = endTime
			this.$nextTick(() => {
				this.getSqEcharts()
			})
		},
		// 虫情图片
		getCqImg() {
			// const { data: res } = await this.$http.get(this.$api.getLastTwoDayImage)
			// this.cqImgArr = res.data.G95799399
			// console.log(res);
			let params = {
				deviceCode: this.equipId,
				startTime: this.getPredate7() + ' 00:00:01',
				endTime: this.getNowDate() + ' 23:59:59',
			}
			this.$http.https(this.$api.getLastTwoDayImage, 'get', params, (res) => {
				let datas = res.data || []
				let cqImgArr = datas || []
				if (cqImgArr && cqImgArr.length) {
					this.cqImgArr = cqImgArr
				} else {
					this.cqImgArr = []
				}
			})
		},
		// 虫情折线图数据
		getCqLineData(startTime, endTime) {
			this.$http.https(
				this.$api.insectStatistics + '?deviceCode=' + this.equipId + '&startTime=' + startTime + '&endTime=' + endTime,
				'get',
				'',
				(res) => {
					let datas = res.data
					if (datas) {
						this.isCq_line = true
						let xData = [] //时间数组
						let lenData = [] //类型数组
						let yDatas = [] //类型数量数组  （目标数据(类型（数量）)）
						let allData = [] //中转数组（时间（类型，数量））
						Object.keys(datas).forEach((item, index) => {
							xData.push(item)
							datas[item].forEach((key, val) => {
								let lenIndex = lenData.findIndex((kk) => {
									return kk == key.insectName
								})
								if (lenIndex > -1) {
								} else {
									yDatas.push({
										animationDelay: 200,
										animationDuration: 3000,
										name: key.insectName,
										smooth: true,
										symbol: 'none',
										type: 'line',
										data: [],
										time: item,
									})
									lenData.push(key.insectName)
								}
							})
						})
						xData.sort(function (a, b) {
							return b < a ? 1 : -1
						})
						xData.forEach((xitem, xindex) => {
							lenData.forEach((litem, lindex) => {
								yDatas[lindex]['data'][xindex] = 0
								datas[xitem].forEach((ditem, dindex) => {
									if (ditem.insectName == litem) {
										yDatas[lindex]['data'][xindex] = ditem.insectNumber
									}
								})
							})
						})
						if (yDatas.length) {
							this.isCqLine = true
							this.$nextTick(() => {
								this.cqLineChart(xData, yDatas)
							})
						} else {
							this.isCqLine = false
						}
					}
				}
			)
		},
		// 获取虫情防治数据
		async getCqControlData() {
			const { data: res } = await this.$http.post(this.$api.mapCqMonitor, this.params)
			if (res.data && res.data.length) {
				this.cqControlData = res.data || []
				// 赋值默认显示的数据
				this.cqFzWrap = res.data[0]
			} else {
				this.cqFzWrap = this.$options.data().cqFzWrap
			}
		},
		// 虫情点击事件
		prevcqIntros() {
			if (this.controlIndex) {
				this.controlIndex--
				this.cqFzWrap = this.cqControlData[this.controlIndex]
			} else
				return this.$message({
					message: '没有啦！',
					type: 'warning',
					showClose: true,
					center: true,
				})
		},
		nextCqIntros(len) {
			if (this.controlIndex < len - 1) {
				this.controlIndex++
				this.cqFzWrap = this.cqControlData[this.controlIndex]
			} else
				return this.$message({
					message: '最后一条啦！',
					type: 'warning',
					showClose: true,
					center: true,
				})
		},
		// 虫情折线图
		cqLineChart(x, y) {
			var myChart = this.$echarts.init(this.$refs.cqLineChart)
			let option = {
				title: {
					// text: '多线图',
					textStyle: {
						align: 'center',
						color: '#fff',
						fontSize: 20,
					},
					top: '5%',
					left: 'center',
				},
				tooltip: {
					trigger: 'axis',
					backgroundColor: 'rgb(113,200,255, 0.28)',
					borderWidth: 0,
					axisPointer: {
						type: 'line',
					},
					textStyle: {
						fontSize: 15,
						color: '#fff',
					},
					transitionDuration: 0,
				},
				grid: {
					top: '17%',
					left: '10%',
					right: '5%',
					bottom: '15%',
					// containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						axisLine: {
							show: true,
						},
						splitArea: {
							// show: true,
							color: '#f00',
							lineStyle: {
								color: '#f00',
							},
						},
						axisLabel: {
							color: '#fff',
						},
						splitLine: {
							show: false,
						},
						boundaryGap: false,
						data: x,
					},
				],

				yAxis: [
					{
						name: '单位(只)',
						nameTextStyle: {
							color: '#fff',
							fontSize: 11,
							// fontWeight: 200,
							align: 'right',
						},
						type: 'value',
						min: 0,
						// max: 140,
						splitNumber: 4,
						splitLine: {
							show: true,
							lineStyle: {
								color: 'rgba(255,255,255,0.1)',
							},
						},
						axisLine: {
							show: false,
						},
						axisLabel: {
							show: true,
							margin: 20,
							textStyle: {
								color: '#fff',
							},
						},
						axisTick: {
							show: false,
						},
					},
				],
				series: y,
			}
			myChart.clear()
			myChart.setOption(option)
			window.addEventListener('resize', function () {
				myChart.resize()
			})
			this.tooltipTimerCq && this.tooltipTimerCq.clearLoop()
			this.tooltipTimerCq = 0
			this.tooltipTimerCq = loopShowTooltip(myChart, option, {
				interval: 2000, // 轮播间隔时间
				loopSeries: true, // 是否开启轮播循环
				dataLength: x.length,
			})
		},
		// 虫情日期范围点击切换
		timeRangeClick(ind) {
			this.cqTimeIndex = ind
			if (ind == 0) {
				this.cqTimeScope = []
				this.getCqLineData(this.timeSlotChange('本月').time1, this.timeSlotChange('本月').time2)
			}
			if (ind == 1) {
				this.getCqLineData(this.timeSlotChange('本年').time1, this.timeSlotChange('本年').time2)
			}
			if (ind == 2) {
				this.getCqLineData(this.timeSlotChange('本季度').time1, this.timeSlotChange('本季度').time2)
			}
		},
		// 虫情自定义日期点击事件
		timeScopeClick() {
			this.getCqLineData(this.formatDate(this.cqTimeScope[0]), this.formatDate(this.cqTimeScope[1]))
		},
		// 日期格式化
		//  * @param 日期格式化
		formatDate(date) {
			var myyear = date.getFullYear()
			var mymonth = date.getMonth() + 1
			var myweekday = date.getDate()

			if (mymonth < 10) {
				mymonth = '0' + mymonth
			}
			if (myweekday < 10) {
				myweekday = '0' + myweekday
			}
			return myyear + '-' + mymonth + '-' + myweekday
		},
		// 获取时间
		timeSlotChange(val) {
			let startTime, endTime
			let now = new Date() //当前日期
			var nowDayOfWeek = now.getDay() //今天本周的第几天
			var nowDay = now.getDate() //当前日
			let nowMonth = now.getMonth() //当前月
			let nowYear = now.getFullYear() //当前年
			let jd = Math.ceil((nowMonth + 1) / 3)
			switch (val) {
				case '本周':
					startTime = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek)
					endTime = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek))
				case '本月':
					startTime = new Date(nowYear, nowMonth, 1)
					endTime = new Date(nowYear, nowMonth, nowDay)
					break
				case '本季度':
					startTime = new Date(nowYear, (jd - 1) * 3, 1)
					endTime = new Date(nowYear, jd * 3, 0)
					break
				case '本年':
					startTime = new Date(nowYear, 0, 1)
					endTime = new Date(nowYear, 11, 31)
					break
			}
			return {
				time1: this.formatDate(startTime),
				time2: this.formatDate(endTime),
			}
		},
		// 视频的token
		async getToken() {
			// if (this.equipId == 'J24945145') {
			// 	this.channelNoP = '1'
			// }
			// this.accessToken = 'at.8sjtom720gn356097pexpt45bc7rie7v-26kdfrpeqw-1r540go-bhp0kitfy'
			// this.accessToken = 'at.5q23djku7r274gq1bpg6xtb95599s70b-5mmp7x56dg-1q6a6lw-vt5qlzsfi'
			const { data: res } = await this.$http.post(this.$api.getToken)
			console.log(res)
			if (res.code == 0) {
				this.accessToken = res.data[Number(this.channelNoP) - 1]
				// this.mianfun('ezopen://open.ys7.com/G59770545/1.live')
			}
		},
		// 苗情下面的过程建议
		async getMqBottom() {
			this.mqQuery.page = Number(this.mqShowIndex) + 1
			this.mqQuery.device = this.equipId
			const { data: res } = await this.$http.post(this.$api.mqBottom, this.mqQuery)
			if (res.code == 0) {
				let datas = res.data || []
				this.mqTotal = res.count || 0
				if (datas && datas.length) {
					// this.mqShowIndex = 0
					this.mqBottomInfo = datas[0] || {}
				} else {
					// this.mqShowIndex = 0
					this.mqBottomInfo = {}
				}
			} else {
				this.mqBottomInfo = {}
			}
		},
		// 过程建议图片切换
		mqToPrev() {
			if (this.mqShowIndex > 0) {
				this.mqShowIndex--
				this.$nextTick(() => {
					this.getMqBottom()
				})
			} else {
				this.$message({
					message: '没有啦！',
					type: 'warning',
					showClose: true,
					center: true,
				})
			}
		},
		mqToNext() {
			if (this.mqShowIndex < this.mqTotal - 1) {
				this.mqShowIndex++
				this.$nextTick(() => {
					this.getMqBottom()
				})
			} else {
				this.$message({
					message: '最后一条啦！',
					type: 'warning',
					showClose: true,
					center: true,
				})
			}
		},
		//传给海康视频地址
		mianfun(a) {
			const that = this
			this.playUrl = a
			this.videoIsPlay = true
			console.log(this.accessToken)
			console.log(this.player)
			// 存在，直接替换
			if (this.player) {
				this.player.stop().then(() => {
					this.player.play(a)
				})
				// 视频上代表播放的图标展示播放状态
				this.videoIsPlay = true
			} else {
				this.player = new EZUIKit.EZUIKitPlayer({
					id: 'videoopen', // 视频容器ID
					accessToken: this.accessToken,
					url: a,
					autoplay: true,
					template: 'simple',
					width: 432,
					height: 180,
					handleSuccess: function () {
						// 视频上代表播放的图标展示播放状态
						this.videoIsPlay = true
					},
					// 视频播放出错的回调
					handleError: function (res) {
						console.log(res)
						if (res && res.data) {
							that.player.stop()
							that.$message(res.data.msg, 'error')
						}
						that.videoIsPlay = false
					},
				})
			}
		},
		// TODO 视频播放和暂停
		makeVideos() {
			if (this.videoIsPlay) {
				this.player.stop()
			} else {
				this.player.play()
			}
			this.videoIsPlay = !this.videoIsPlay
		},
		screenPlay() {
			if (this.screenIsPlay) {
				this.screenPlayer.stop()
			} else {
				this.screenPlayer.play()
			}
			this.screenIsPlay = !this.screenIsPlay
		},
		// 视频全屏
		toFullScreen() {
			const that = this
			if (this.isFull) return

			this.isFull = true
			// 加载全屏视频
			this.$nextTick(() => {
				this.screenPlayer = new EZUIKit.EZUIKitPlayer({
					id: 'bigscreen', // 视频容器ID
					accessToken: this.accessToken,
					url: this.playUrl,
					autoplay: true,
					template: 'simple',
					width: 5092,
					height: 1080,
					handleSuccess: function () {
						// 视频上代表播放的图标展示播放状态
						this.screenIsPlay = true
					},
					// 视频播放出错的回调
					handleError: function (res) {
						console.log(res)
						if (res && res.data) {
							that.player.stop()
							that.$message(res.data.msg, 'error')
						}
						that.screenIsPlay = false
					},
				})
			})
		},
		// 视频缩小
		// 地图点击缩小
		toSmallScreen() {
			if (!this.isFull) return
			this.isFull = false
			this.screenPlayer.stop().then(() => {
				this.screenPlayer = null
			})
		},
		// TODO  云台控制 2022/04/01 替换：暂不使用
		async controlChanges(type, isdef = true) {
			// 判断是视频是否是播放状态
			if (isdef) {
				if (!this.videoIsPlay) {
					this.$message('视频已暂停，请先点击播放', 'error')
					return
				}
			} else {
				if (!this.screenIsPlay) {
					this.$message('视频已暂停，请先点击播放', 'error')
					return
				}
			}

			// 不存在，直接开始
			if (this.controlType == '') {
				let params = {
					accessToken: this.accessToken,
					deviceSerial: this.deviceSerial,
					channelNo: this.channelNo,
					direction: type,
					speed: '1',
				}
				this.controlType = type
				const { data: res } = await this.$http.post(this.$api.ytStart, params)
			} else if (this.controlType == type) {
				// 二次点击-停止
				let params = {
					accessToken: this.accessToken,
					deviceSerial: this.deviceSerial,
					channelNo: this.channelNo,
					direction: this.controlType,
				}
				this.controlType = ''
				const { data: res } = await this.$http.post(this.$api.ytStop, params)
			} else {
				// 其它操作
				// 先调取停止，然后再操控
				let params = {
					accessToken: this.accessToken,
					deviceSerial: this.deviceSerial,
					channelNo: this.channelNo,
					direction: this.controlType,
					speed: '1',
				}
				let startParams = {
					accessToken: this.accessToken,
					deviceSerial: this.deviceSerial,
					channelNo: this.channelNo,
					direction: type,
					speed: '1',
				}
				const { data: res } = await this.$http.post(this.$api.ytStop, params)
				if (res.code == 200) {
					// 调开始
					this.controlType = type
					const { data: res } = await this.$http.post(this.$api.ytStart, startParams)
				}
			}
		},
		// TODO 抓图
		async makeCaptures() {
			// 判断是视频是否是播放状态
			if (!this.videoIsPlay) {
				this.$message('视频已暂停，请先点击播放', 'error')
				return
			}
			// 判断云台控制是否是开启状态，是的话给关闭
			if (this.controlType != '') {
				let params = {
					accessToken: this.accessToken,
					deviceSerial: this.deviceSerial,
					channelNo: this.channelNo,
					direction: this.controlType,
				}
				this.controlType = ''
				await this.$http.post(this.$api.ytStop, params)
			}
			let makeparams = {
				accessToken: this.accessToken,
				deviceSerial: this.deviceSerial,
				channelNo: this.channelNo,
			}
			const { data: res } = await this.$http.post(this.$api.capture, makeparams)
			if (res.code == 0) {
				this.$message({
					message: '图片抓取成功',
					type: 'success',
					showClose: false,
					center: true,
				})
				let datas = res.data
				if (datas) {
					this.mqLastImg = datas
					this.captureList.unshift(datas)
				}
				if (this.captureList.length > 2) {
					this.isShowMqbtn = true
				} else {
					this.isShowMqbtn = false
				}
				// 请求土壤信息，并添加过程数据
				this.getOtherData()
			} else {
				this.$message({
					message: res.msg,
					type: 'error',
					showClose: false,
					center: true,
				})
			}
			// http://222.133.14.246:15101/hnyfkj-file-server/plugins/20220124/1642991849395/1642991849395_efe6621721a849748473e02ba1ed8ea9.jpg
			// console.log(this.captureList)
		},
		// 获取枪机附近的土壤信息
		getOtherData() {
			this.$http.https(this.$api.latestWeatherData + '?stationCode=' + this.mqTrId, 'get', '', (res) => {
				if (res.meta && res.meta.success) {
					let datas = res.data.sensorVOs || []
					let data1 = []
					let data2 = []
					datas.forEach((item) => {
						if (item.key.indexOf('TRWD') > -1) {
							data1.push(item.value)
						} else if (item.key.indexOf('TRSD') > -1) {
							data2.push(item.value)
						}
					})

					this.mqTrWd = data1[0]
					this.mqTrSd = data2[0]
					console.log(this.mqTrWd, this.mqTrSd)
					this.$nextTick(() => {
						// 获取气象数据
						this.getOtherQxData()
						// // 过程新增
						// this.mqAddProgress()
					})
				}
			})
		},
		// 获取枪机附近的气象信息
		getOtherQxData() {
			this.$http.https(this.$api.latestWeatherData + '?stationCode=' + this.mqQxId, 'get', '', (res) => {
				if (res.meta && res.meta.success) {
					let datas = res.data.sensorVOs || []
					datas.forEach((item) => {
						if (item.key == 'WD') {
							this.mqQxWd = item.value
						} else if (item.key == 'SD') {
							this.mqqxSd = item.value
						}
					})
					this.$nextTick(() => {
						// 过程新增
						this.mqAddProgress()
					})
				}
			})
		},
		// 过程新增
		async mqAddProgress() {
			let params = {
				device: this.equipId,
				print: this.mqLastImg,
				place: this.mqName,
				temperature: this.mqqxSd,
				vacuumThermometer: this.mqQxWd,
				soilTemperature: this.mqTrWd,
				soilMoisture: this.mqTrSd,
				suggestions: '暂无',
				addTime: this.getNowFormatDate(),
			}
			const { data: res } = await this.$http.post(this.$api.mqSave, params)
			// 保存成功了，重新请求数据
			if (res.code == 200) {
				this.$nextTick(() => {
					this.getMqBottom()
				})
			}
		},
		// 获取基地负责人弹窗数据
		async getJdName(parm) {
			let params = {
				param: parm,
			}
			// 	{
			// 		"timestamp": "2022-01-19 15:45:00",
			// 		"status": 404,
			// 		"error": "Not Found",
			// 		"message": "",
			// 		"path": "//jxSugariPublic/jxPersonnelByType"
			// }
			const { data: res } = await this.$http.post(this.$api.jxPersonnelByType, params)
			if (res.code == 0) {
				let datas = res.data || {}
				if (datas && datas.name) {
					this.jdName = datas.name || '--'
					this.jdPhone = datas.phone || '--'
				} else {
					this.jdName = '--'
					this.jdPhone = '--'
				}
			}
		},
		// 图片放大预览
		bigImg(items) {
			console.log(items)
			this.bigImgs = items
			this.isBigImg = true
			this.hideVideos()
			// this.$refs.waterimages.setActiveItem(index)
		},
		// 风向判断
		fengxiang(value) {
			console.log(value)
			var str = ''
			if (value > 0 && value < 90) {
				str = '东北'
			}
			if (value > 90 && value < 180) {
				str = '东南'
			}
			if (value > 180 && value < 270) {
				str = '西南'
			}
			if (value > 270 && value < 360) {
				str = '西北'
			}
			if (value == 0) {
				str = '北'
			}
			if (value == 90) {
				str = '东'
			}
			if (value == 180) {
				str = '南'
			}
			if (value == 270) {
				str = '西'
			}
			console.log(str)
			return str
		},

		/*
		 * @Description: 智脑中心热力图柱状图点击弹窗中的图表绘制
		 * @Author: Huichao Wang
		 * @Date: 2022-07-26
		 */
		// 图表左右翻页
		handlePage(action) {
			let xData = this.equipXData.length
			let allPage = xData - 7
			if (action == 'left') {
				if (this.equipPage == 0) {
					return
				}
				this.equipPage--
			} else if (action == 'right') {
				if (allPage <= this.equipPage) {
					return
				}

				this.equipPage++
			}
			let equipNameXdata = JSON.parse(JSON.stringify(this.equipNameXdata))
			let xAxis = equipNameXdata.slice(this.equipPage, this.equipPage + 7)

			let equipDiaData = JSON.parse(JSON.stringify(this.equipDiaData))

			if (this.modelType == '0') {
				this.tooltipTimerFl && this.tooltipTimerFl.clearLoop()
				this.tooltipTimerFl = 0
				this.$nextTick(() => {
					let series = {
						dan: equipDiaData.dan.slice(this.equipPage, this.equipPage + 7),
						lin: equipDiaData.lin.slice(this.equipPage, this.equipPage + 7),
						jia: equipDiaData.jia.slice(this.equipPage, this.equipPage + 7),
					}
					const options = this.flChartDom.getOption()
					options.xAxis[0].data = xAxis
					options.series[0].data = series.dan
					options.series[1].data = series.lin
					options.series[2].data = series.jia

					// 氮
					let danMaxCoord = this.coords(this.danMaxCoord)
					if (danMaxCoord.isShow) {
						options.series[0].markPoint.data[0].symbolSize = [32, 30]
						options.series[0].markPoint.data[0].coord = danMaxCoord.coordEnd
						options.series[0].markPoint.data[0].label.show = danMaxCoord.isShow
					} else {
						options.series[0].markPoint.data[0].symbolSize = 0
						options.series[0].markPoint.data[0].coord = danMaxCoord.coordEnd
						options.series[0].markPoint.data[0].label.show = danMaxCoord.isShow
					}
					let danMinCoord = this.coords(this.danMinCoord)
					if (danMinCoord.isShow) {
						options.series[0].markPoint.data[1].symbolSize = [32, 30]
						options.series[0].markPoint.data[1].coord = danMinCoord.coordEnd
						options.series[0].markPoint.data[1].label.show = danMinCoord.isShow
					} else {
						options.series[0].markPoint.data[1].symbolSize = 0
						options.series[0].markPoint.data[1].coord = danMinCoord.coordEnd
						options.series[0].markPoint.data[1].label.show = danMinCoord.isShow
					}
					// 磷
					let linMaxCoord = this.coords(this.linMaxCoord)
					if (linMaxCoord.isShow) {
						options.series[1].markPoint.data[0].symbolSize = [32, 30]
						options.series[1].markPoint.data[0].coord = linMaxCoord.coordEnd
						options.series[1].markPoint.data[0].label.show = linMaxCoord.isShow
					} else {
						options.series[1].markPoint.data[0].symbolSize = 0
						options.series[1].markPoint.data[0].coord = linMaxCoord.coordEnd
						options.series[1].markPoint.data[0].label.show = linMaxCoord.isShow
					}
					let linMinCoord = this.coords(this.linMinCoord)
					if (linMinCoord.isShow) {
						options.series[1].markPoint.data[1].symbolSize = [32, 30]
						options.series[1].markPoint.data[1].coord = linMinCoord.coordEnd
						options.series[1].markPoint.data[1].label.show = linMinCoord.isShow
					} else {
						options.series[1].markPoint.data[1].symbolSize = 0
						options.series[1].markPoint.data[1].coord = linMinCoord.coordEnd
						options.series[1].markPoint.data[1].label.show = linMinCoord.isShow
					}
					// 钾
					let jiaMaxCoord = this.coords(this.jiaMaxCoord)
					if (jiaMaxCoord.isShow) {
						options.series[2].markPoint.data[0].symbolSize = [32, 30]
						options.series[2].markPoint.data[0].coord = jiaMaxCoord.coordEnd
						options.series[2].markPoint.data[0].label.show = jiaMaxCoord.isShow
					} else {
						options.series[2].markPoint.data[0].symbolSize = 0
						options.series[2].markPoint.data[0].coord = jiaMaxCoord.coordEnd
						options.series[2].markPoint.data[0].label.show = jiaMaxCoord.isShow
					}
					let jiaMinCoord = this.coords(this.jiaMinCoord)
					if (jiaMinCoord.isShow) {
						options.series[2].markPoint.data[1].symbolSize = [32, 30]
						options.series[2].markPoint.data[1].coord = jiaMinCoord.coordEnd
						options.series[2].markPoint.data[1].label.show = jiaMinCoord.isShow
					} else {
						options.series[2].markPoint.data[1].symbolSize = 0
						options.series[2].markPoint.data[1].coord = jiaMinCoord.coordEnd
						options.series[2].markPoint.data[1].label.show = jiaMinCoord.isShow
					}
					this.flChartDom.setOption(options)
					this.tooltipTimerFl = loopShowTooltip(this.flChartDom, options, {
						interval: 3000, // 轮播间隔时间
						loopSeries: true, // 是否开启轮播循环
						dataLength: xAxis.length,
					})
				})
			} else {
				this.tooltipTimerTr && this.tooltipTimerTr.clearLoop()
				this.tooltipTimerTr = 0
				this.$nextTick(() => {
					let series = {
						tw: equipDiaData.tw.slice(this.equipPage, this.equipPage + 7),
						ts: equipDiaData.ts.slice(this.equipPage, this.equipPage + 7),
					}
					const options = this.trChartDom.getOption()
					options.xAxis[0].data = xAxis
					options.series[0].data = series.tw
					options.series[1].data = series.ts

					let twMaxCoord = this.coords(this.twMaxCoord)
					if (twMaxCoord.isShow) {
						options.series[0].markPoint.data[0].symbolSize = [32, 30]
						options.series[0].markPoint.data[0].coord = twMaxCoord.coordEnd
						options.series[0].markPoint.data[0].label.show = twMaxCoord.isShow
					} else {
						options.series[0].markPoint.data[0].symbolSize = 0
						options.series[0].markPoint.data[0].coord = twMaxCoord.coordEnd
						options.series[0].markPoint.data[0].label.show = twMaxCoord.isShow
					}
					let twMinCoord = this.coords(this.twMinCoord)
					if (twMinCoord.isShow) {
						options.series[0].markPoint.data[1].symbolSize = [32, 30]
						options.series[0].markPoint.data[1].coord = twMinCoord.coordEnd
						options.series[0].markPoint.data[1].label.show = twMinCoord.isShow
					} else {
						options.series[0].markPoint.data[1].symbolSize = 0
						options.series[0].markPoint.data[1].coord = twMinCoord.coordEnd
						options.series[0].markPoint.data[1].label.show = twMinCoord.isShow
					}

					let tsMaxCoord = this.coords(this.tsMaxCoord)
					if (tsMaxCoord.isShow) {
						options.series[1].markPoint.data[0].symbolSize = [32, 30]
						options.series[1].markPoint.data[0].coord = tsMaxCoord.coordEnd
						options.series[1].markPoint.data[0].label.show = tsMaxCoord.isShow
					} else {
						options.series[1].markPoint.data[0].symbolSize = 0
						options.series[1].markPoint.data[0].coord = tsMaxCoord.coordEnd
						options.series[1].markPoint.data[0].label.show = tsMaxCoord.isShow
					}
					let tsMinCoord = this.coords(this.tsMinCoord)
					if (tsMinCoord.isShow) {
						options.series[1].markPoint.data[1].symbolSize = [32, 30]
						options.series[1].markPoint.data[1].coord = tsMinCoord.coordEnd
						options.series[1].markPoint.data[1].label.show = tsMinCoord.isShow
					} else {
						options.series[1].markPoint.data[1].symbolSize = 0
						options.series[1].markPoint.data[1].coord = tsMinCoord.coordEnd
						options.series[1].markPoint.data[1].label.show = tsMinCoord.isShow
					}
					this.trChartDom.setOption(options)
					this.tooltipTimerFl = loopShowTooltip(this.trChartDom, options, {
						interval: 3000, // 轮播间隔时间
						loopSeries: true, // 是否开启轮播循环
						dataLength: xAxis.length,
					})
				})
			}
		},
		znDiaChart(xAxis, seriesArr) {
			const _this = this
			this.flChartDom = this.$echarts.init(this.$refs.znDiachartRef)
			let danMaxCoord = this.coords(this.danMaxCoord)
			let linMaxCoord = this.coords(this.linMaxCoord)
			let jiaMaxCoord = this.coords(this.jiaMaxCoord)

			let danMinCoord = this.coords(this.danMinCoord)
			let linMinCoord = this.coords(this.linMinCoord)
			let jiaMinCoord = this.coords(this.jiaMinCoord)

			// let lengeds = ['氮离子', '磷离子', '钾离子']
			let lengeds = [
				{
					name: '氮离子',
					itemStyle: {
						color: '#FFC63C',
					},
				},
				{
					name: '磷离子',
					itemStyle: {
						color: '#DAF967',
					},
				},
				{
					name: '钾离子',
					itemStyle: {
						color: '#3AF3A1',
					},
				},
			]
			let option = {
				backgroundColor: 'transparent',
				color: ['#FFC63C', '#DAF967', '#3AF3A1'],
				legend: {
					top: 10,
					textStyle: {
						color: '#fff',
						fontSize: 14,
					},
					data: lengeds,
				},
				grid: {
					left: '2%',
					right: '3%',
					bottom: '11%',
					containLabel: true,
				},

				tooltip: {
					show: 'true',
					trigger: 'axis',
					backgroundColor: 'rgba(25, 143, 219, 0.29)', // 背景
					padding: [6, 10, 6, 11], //内边距
					borderWidth: 0,
					extraCssText: 'border-radius: 10px;', //添加阴影
					textStyle: {
						color: '#fff',
						fontSize: 14,
						fontWeight: 400,
					},
					axisPointer: {
						type: 'line',
						lineStyle: {
							color: '#3371FC',
						},
					},
					formatter: function (params) {
						let infos = '<div style="text-align:left;">'
						params.forEach((item, index) => {
							let unit = 'mg/kg'
							infos += `
                <div style="margin:6px 0;font-size:14px;">${item.marker}${item.seriesName}：${item.value}${unit}</div>
              `
							infos += '</div>'
						})
						return infos
					},
				},
				yAxis: {
					type: 'value',
					min: 0,
					minInterval: 1,
					// interval:40,
					// max: max3*1.2,
					axisLabel: {
						color: '#fff',
						textStyle: {
							fontSize: 12,
						},
					},
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: true,
						lineStyle: {
							color: 'rgba(23, 157, 160, 0.2)',
							width: 1,
						},
					},
				},
				xAxis: [
					{
						type: 'category',
						axisLabel: {
							interval: 0,
							margin: 10,
							color: '#fff',
							width: 56,
							overflow: 'break',
							lineHeight: 18,
							textStyle: {
								fontSize: 12,
								fontWeight: 400,
							},
							formatter: function (val) {
								if (val == _this.equipFaceName) {
									return '{a|' + val + '}\n{b|}'
								} else {
									return val
								}
							},
							rich: {
								a: {
									fontSize: 12,
									color: '#fff',
								},
								b: {
									backgroundColor: {
										image: '/glb/farms/icon.png',
									},
									width: 14,
									height: 10,
								},
							},
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#477AA5',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
						data: xAxis,
					},
				],
				series: [
					{
						name: '氮离子',
						type: 'bar',
						itemStyle: {
							normal: {
								show: true,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: 'rgba(255, 188, 27, 1)',
									},
									{
										offset: 1,
										color: 'rgba(255, 188, 27, 0.26)',
									},
								]),
								barBorderRadius: [5, 5, 0, 0],
								borderWidth: 0,
							},
						},
						markPoint: {
							symbol: 'pin',

							label: {
								color: '#fff',
								fontSize: 12,
							},
							data: [
								{
									type: 'max',
									symbolSize: danMaxCoord.isShow ? [32, 30] : 0,
									name: '最大值',
									coord: danMaxCoord.coordEnd,
									itemStyle: {
										color: '#2AB4FC',
									},
									label: {
										show: danMaxCoord.isShow,
										formatter: '高',
									},
									z: 1,
								},
								{
									type: 'min',
									symbolSize: danMinCoord.isShow ? [32, 30] : 0,
									coord: danMinCoord.coordEnd,
									name: '最小值',
									itemStyle: {
										color: '#FF8585',
									},
									label: {
										show: danMinCoord.isShow,
										formatter: '低',
									},
									z: 2,
								},
							],
						},
						zlevel: 2,
						barWidth: 10,
						data: seriesArr.dan,
					},
					{
						name: '磷离子',
						type: 'bar',
						barWidth: 10,
						itemStyle: {
							normal: {
								show: true,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: 'rgba(224, 255, 104, 1)',
									},
									{
										offset: 1,
										color: 'rgba(224, 255, 104, 0.38)',
									},
								]),
								barBorderRadius: [5, 5, 0, 0],
								borderWidth: 0,
							},
						},
						markPoint: {
							label: {
								color: '#fff',
								fontSize: 12,
							},
							data: [
								{
									type: 'max',
									name: '最大值',
									symbolSize: linMaxCoord.isShow ? [32, 30] : 0,
									coord: linMaxCoord.coordEnd,
									itemStyle: {
										color: '#2AB4FC',
									},
									label: {
										show: linMaxCoord.isShow,
										formatter: '高',
									},
									z: 1,
								},
								{
									type: 'min',
									name: '最小值',
									symbolSize: linMinCoord.isShow ? [32, 30] : 0,
									coord: linMinCoord.coordEnd,
									itemStyle: {
										color: '#FF8585',
									},
									label: {
										show: linMinCoord.isShow,
										formatter: '低',
									},
									z: 2,
								},
							],
						},
						zlevel: 2,
						barGap: '60%',
						data: seriesArr.lin,
					},
					{
						name: '钾离子',
						type: 'bar',
						barWidth: 10,
						itemStyle: {
							normal: {
								show: true,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: 'rgba(59, 249, 164, 1)',
									},
									{
										offset: 1,
										color: 'rgba(59, 249, 164, 0.16)',
									},
								]),
								barBorderRadius: [5, 5, 0, 0],
								borderWidth: 0,
							},
						},
						markPoint: {
							symbol: 'pin',
							label: {
								color: '#fff',
								fontSize: 12,
							},
							data: [
								{
									type: 'max',
									name: '最大值',
									symbolSize: jiaMaxCoord.isShow ? [32, 30] : 0,
									coord: jiaMaxCoord.coordEnd,
									itemStyle: {
										color: '#2AB4FC',
									},
									label: {
										show: jiaMaxCoord.isShow,
										formatter: '高',
									},
									z: 1,
								},
								{
									type: 'min',
									name: '最小值',
									symbolSize: jiaMinCoord.isShow ? [32, 30] : 0,
									coord: jiaMinCoord.coordEnd,
									itemStyle: {
										color: '#FF8585',
									},
									label: {
										show: jiaMinCoord.isShow,
										formatter: '低',
									},
									z: 2,
								},
							],
						},
						zlevel: 2,
						barGap: '60%',
						data: seriesArr.jia,
					},
				],
			}
			this.flChartDom.clear()
			this.flChartDom.setOption(option)

			this.tooltipTimerFl && this.tooltipTimerFl.clearLoop()
			this.tooltipTimerFl = 0
			this.tooltipTimerFl = loopShowTooltip(this.flChartDom, option, {
				interval: 3000, // 轮播间隔时间
				loopSeries: true, // 是否开启轮播循环
				dataLength: xAxis.length,
			})
		},
		coords(data) {
			let coordEnd = []
			let isShow = true
			if (data[0] >= this.equipPage) {
				// if (this.equipPage == 0) {
				// 	isShow = false
				// } else {
				isShow = true
				// }
				coordEnd = [data[0] - this.equipPage, data[1]]
			} else {
				// if (this.equipPage == 0) {
				// 	isShow = true
				// } else {
				// 	isShow = false
				// }
				isShow = false
				coordEnd = data
			}
			return {
				isShow: isShow,
				coordEnd: coordEnd,
			}
		},
		// 土壤感知的柱状图
		znDiaChartTr(xAxis, seriesArr) {
			const _this = this
			this.trChartDom = this.$echarts.init(this.$refs.znDiachartRefTr)
			// let lengeds = ['土壤温度', '土壤湿度']
			let twMaxCoord = this.coords(this.twMaxCoord)
			let tsMaxCoord = this.coords(this.tsMaxCoord)

			let twMinCoord = this.coords(this.twMinCoord)
			let tsMinCoord = this.coords(this.tsMinCoord)
			let lengeds = [
				{
					name: '土壤温度',
					itemStyle: {
						color: '#1ED5FD',
					},
				},
				{
					name: '土壤湿度',
					itemStyle: {
						color: '#FBBA1B',
					},
				},
			]
			let option = {
				backgroundColor: 'transparent',
				legend: {
					top: 10,
					textStyle: {
						color: '#fff',
						fontSize: 14,
					},
					data: lengeds,
				},
				grid: {
					left: '2%',
					right: '3%',
					bottom: '11%',
					containLabel: true,
				},

				tooltip: {
					show: 'true',
					trigger: 'axis',
					backgroundColor: 'rgba(25, 143, 219, 0.29)', // 背景
					padding: [6, 10, 6, 11], //内边距
					borderWidth: 0,
					extraCssText: 'border-radius: 10px;', //添加阴影
					textStyle: {
						color: '#fff',
						fontSize: 14,
						fontWeight: 400,
					},
					axisPointer: {
						type: 'line',
						lineStyle: {
							color: '#3371FC',
						},
					},
					formatter: function (params) {
						let infos = '<div style="text-align:left;">'
						params.forEach((item, index) => {
							let unit = ''
							if (index == 0) {
								unit = '℃'
							} else {
								unit = '%'
							}
							infos += `
                <div style="margin:6px 0;font-size:14px;">${item.marker}${item.seriesName}：${item.value}${unit}</div>
              `
							infos += '</div>'
						})
						return infos
					},
				},
				yAxis: [
					{
						type: 'value',
						name: '(℃)',
						nameTextStyle: {
							color: '#fff',
							align: 'right',
						},
						min: 0,
						minInterval: 1,
						interval: (Math.ceil(_this.twMax.val / 10) * 10) / 6,
						max: Math.ceil(_this.twMax.val / 10) * 10,
						axisLabel: {
							color: '#fff',
							textStyle: {
								fontSize: 12,
							},
							formatter: function (val) {
								return parseInt(val)
							},
						},
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: 'rgba(23, 157, 160, 0.2)',
								width: 1,
							},
						},
					},
					{
						type: 'value',
						name: '(%)',
						nameTextStyle: {
							color: '#fff',
							align: 'left',
						},
						position: 'right',
						min: 0,
						minInterval: 1,
						// interval: 6,
						// max: max3*1.2,
						interval: (Math.ceil(_this.tsMax.val / 10) * 10) / 6,
						max: Math.ceil(_this.tsMax.val / 10) * 10,
						axisLabel: {
							color: '#fff',
							textStyle: {
								fontSize: 12,
							},
							formatter: function (val) {
								return parseInt(val)
							},
						},
						axisLine: {
							show: false,
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: 'rgba(23, 157, 160, 0.2)',
								width: 1,
							},
						},
					},
				],
				xAxis: [
					{
						type: 'category',
						axisLabel: {
							interval: 0,
							margin: 10,
							color: '#fff',
							width: 56,
							overflow: 'break',
							lineHeight: 18,
							textStyle: {
								fontSize: 12,
								fontWeight: 400,
							},
							formatter: function (val) {
								if (val == _this.equipFaceName) {
									return '{a|' + val + '}\n{b|}'
								} else {
									return val
								}
							},
							rich: {
								a: {
									fontSize: 12,
									color: '#fff',
								},
								b: {
									backgroundColor: {
										image: '/glb/farms/icon.png',
									},
									width: 14,
									height: 10,
								},
							},
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: '#477AA5',
							},
						},
						axisTick: {
							show: false,
						},
						splitLine: {
							show: false,
						},
						data: xAxis,
					},
				],
				series: [
					{
						name: '土壤温度',
						type: 'bar',
						itemStyle: {
							normal: {
								show: true,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: 'rgba(30, 215, 255, 1)',
									},
									{
										offset: 1,
										color: 'rgba(30, 215, 255, 0.38)',
									},
								]),
								barBorderRadius: [5, 5, 0, 0],
								borderWidth: 0,
							},
						},
						markPoint: {
							symbol: 'pin',
							label: {
								color: '#fff',
								fontSize: 12,
							},
							data: [
								{
									type: 'max',
									name: '最大值',
									symbolSize: twMaxCoord.isShow ? [32, 30] : 0,
									coord: twMaxCoord.coordEnd,
									itemStyle: {
										color: '#2AB4FC',
									},
									label: {
										show: twMaxCoord.isShow,
										formatter: '高',
									},
									z: 1,
								},
								{
									type: 'min',
									name: '最小值',
									symbolSize: twMinCoord.isShow ? [32, 30] : 0,
									coord: twMinCoord.coordEnd,
									itemStyle: {
										color: '#FF8585',
									},
									label: {
										show: twMinCoord.isShow,
										formatter: '低',
									},
									z: 2,
								},
							],
						},
						zlevel: 2,
						barWidth: 10,
						data: seriesArr.tw,
					},
					{
						name: '土壤湿度',
						type: 'bar',
						barWidth: 10,
						yAxisIndex: 1,
						itemStyle: {
							normal: {
								show: true,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
									{
										offset: 0,
										color: 'rgba(255, 188, 27, 1)',
									},
									{
										offset: 1,
										color: 'rgba(255, 188, 27, 0.26)',
									},
								]),
								barBorderRadius: [5, 5, 0, 0],
								borderWidth: 0,
							},
						},
						markPoint: {
							symbol: 'pin',
							label: {
								color: '#fff',
								fontSize: 12,
							},
							data: [
								{
									type: 'max',
									name: '最大值',
									symbolSize: tsMaxCoord.isShow ? [32, 30] : 0,
									coord: tsMaxCoord.coordEnd,
									itemStyle: {
										color: '#2AB4FC',
									},
									label: {
										show: tsMaxCoord.isShow,
										formatter: '高',
									},
									z: 1,
								},
								{
									type: 'min',
									name: '最小值',
									symbolSize: tsMinCoord.isShow ? [32, 30] : 0,
									coord: tsMinCoord.coordEnd,
									itemStyle: {
										color: '#FF8585',
									},
									label: {
										show: tsMinCoord.isShow,
										formatter: '低',
									},
									z: 2,
								},
							],
						},
						zlevel: 2,
						barGap: '60%',
						data: seriesArr.ts,
					},
				],
			}
			this.trChartDom.clear()
			this.trChartDom.setOption(option)

			this.tooltipTimerTr && this.tooltipTimerTr.clearLoop()
			this.tooltipTimerTr = 0
			this.tooltipTimerTr = loopShowTooltip(this.trChartDom, option, {
				interval: 3000, // 轮播间隔时间
				loopSeries: true, // 是否开启轮播循环
				dataLength: xAxis.length,
			})
		},
	},
}
