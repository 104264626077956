<template>
	<div id="handtab">
		<div class="flex flex_a_c">
			<!-- 上面可操作图标;施肥泵 -->
			<div class="flex flex_a_c flex_ju_sb tabs-set tabs-set-jbj">
				<span class="font_14 c_white set-title">施肥泵</span>
				<div v-for="(item, index) in setList" :key="index" class="t_c tabs-setdiv" @click="changeSet(item)">
					<!-- 上面的图片 -->
					<div class="set-tab">
						<!-- 第二层间断线性背景 -->
						<img class="line-icon" :src="lineIcon(item)" alt="" />
						<!-- 第三层纯色背景 -->
						<div class="set-smallbg" :class="{ 'set-smallbg-y': bgIcon(item) }"></div>
						<!-- 第四层不同的图标 -->
						<img class="icons" :src="iconIcon(item)" alt="" />
					</div>
				</div>
				<div class="flex flex_a_c switchs">
					<div class="switch-div" @click="switchChange">启动</div>
					<div class="switch-div switch-div-active" @click="switchChange">停止</div>
				</div>
			</div>
		</div>

		<div class="flex flex_a_c tab-sets">
			<!-- 上面可操作图标;灌溉泵 -->
			<div class="flex flex_a_c flex_ju_sb tabs-set tabs-set-jbj">
				<span class="font_14 c_white set-title">灌溉泵</span>
				<div v-for="(item, index) in setList" :key="index" class="t_c tabs-setdiv" @click="changeSet(item)">
					<!-- 上面的图片 -->
					<div class="set-tab">
						<!-- 第二层间断线性背景 -->
						<img class="line-icon" :src="lineIcon(item)" alt="" />
						<!-- 第三层纯色背景 -->
						<div class="set-smallbg" :class="{ 'set-smallbg-y': bgIcon(item) }"></div>
						<!-- 第四层不同的图标 -->
						<img class="icons" :src="iconIcon(item)" alt="" />
					</div>
				</div>
				<div class="flex flex_a_c switchs">
					<div class="switch-div" @click="switchChange">启动</div>
					<div class="switch-div switch-div-active" @click="switchChange">停止</div>
				</div>
			</div>
		</div>
		<div class="flex flex_a_c tab-sets">
			<!-- 上面可操作图标;搅拌泵 -->
			<div class="flex flex_a_c flex_ju_sb tabs-set tabs-set-jbj">
				<span class="font_14 c_white set-title">搅拌泵</span>
				<div v-for="(item, index) in setList" :key="index" class="t_c tabs-setdiv" @click="changeSet(item)">
					<!-- 上面的图片 -->
					<div class="set-tab">
						<!-- 第二层间断线性背景 -->
						<img class="line-icon" :src="lineIcon(item)" alt="" />
						<!-- 第三层纯色背景 -->
						<div class="set-smallbg" :class="{ 'set-smallbg-y': bgIcon(item) }"></div>
						<!-- 第四层不同的图标 -->
						<img class="icons" :src="iconIcon(item)" alt="" />
					</div>
				</div>
				<div class="flex flex_a_c switchs">
					<div class="switch-div" @click="switchChange">启动</div>
					<div class="switch-div switch-div-active" @click="switchChange">停止</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/**
 * @description 手动模式切换模块
 * @property {Object} equipData  设备点操作详情
 * @property {Boolean} online    设备是否在线
 * @property {String|Number}  equipId  设备点id
 */

/**
 * @description 操作说明
 * 正反转状态：0正转，传参:12  1反转,传参:13   2停止,传参:11
 * 枪尾联动操作需先判断是否有这个设备
 * 水泵联动和施肥联动，需要先判断设备是否是停止运行状态
 * 关闭和开启：关闭传参1，开启传参0
 */
export default {
	data() {
		return {
			detailInfo: {},
			equipData: {},
			equipName: '',
			online: false,
			// 运行状态：zheng: 正转0   fan: 反转1   stop: 停止2
			runStatus: '0',
			/**
			 * 上面的可操作列表数据
			 */
			setList: [
				{
					iconn: require('@/assets/img/water/icon_setn1.png'),
					icony: require('@/assets/img/water/icon_sety1.png'),
					name: '水泵联',
					type: 'pumpLinked',
					val: 21,
					isStop: true, // 判断是否是停止状态
				},
				// {
				// 	iconn: require('@/assets/img/water/icon_setn2.png'),
				// 	icony: require('@/assets/img/water/icon_sety2.png'),
				// 	name: '尾枪联',
				// 	type: 'tailGunLinked',
				// 	val: 22,
				// 	isHad: true, // 判断是否有这个设备
				// },
				// {
				// 	iconn: require('@/assets/img/water/icon_setn3.png'),
				// 	icony: require('@/assets/img/water/icon_sety3.png'),
				// 	name: '施肥联',
				// 	type: 'fertilizationPumpLinked',
				// 	val: 23,
				// 	isStop: true, // 判断是否是停止状态
				// },
				// {
				// 	iconn: require('@/assets/img/water/icon_setn4.png'),
				// 	icony: require('@/assets/img/water/icon_sety4.png'),
				// 	name: '搅拌泵控',
				// 	type: 'agitatingPump',
				// 	val: 24,
				// },
				// {
				// 	iconn: require('@/assets/img/water/icon_setn5.png'),
				// 	icony: require('@/assets/img/water/icon_sety5.png'),
				// 	name: '电源开',
				// 	type: '',
				// },
			],
			// 运行速度
			speedNum: 0,
			speedWord: '--',
		}
	},
	props: {
		equipId: {
			type: [String, Number],
			default: '',
		},
		details: {
			type: Object,
			default: () => {
				return {}
			},
		},
	},
	mounted() {
		// this.getEquipStatus()
	},
	watch: {
		equipId: {
			handler(newVal) {
				// this.getEquipStatus()
			},
		},
		details: {
			handler(newVal) {
				this.equipData = newVal
				this.detailInfo = newVal
			},
		},
	},
	computed: {
		// 运行状态选中图标的定位左边距left
		runLeft() {
			let runStatus = this.detailInfo.forOrBack
			this.runStatus = runStatus
			if (runStatus == '0') {
				return '0'
			} else if (runStatus == '1') {
				return '66.6%'
			} else if (runStatus == '2') {
				return '33.3%'
			}
		},
		// 进度条的left
		proLeft() {
			return this.speed - 2 + '%'
		},
		speed() {
			let ratio = this.detailInfo.ratio
			this.speedNum = ratio || 0
			if (String(ratio)) {
				this.speedWord = `${ratio}%`
				return ratio
			} else {
				this.speedWord = '--'
				return 0
			}
		},
	},
	methods: {
		// 获取设备点信息
		async getEquipStatus() {
			const params = {
				villageId: '',
				id: this.equipId,
			}
			const { data: res } = await this.$http.post('/sf' + this.$api.getEquipStatus, this.qs.stringify(params))
			if (res.code == 0) {
				let datas = res.data
				// 赋值设备点详情数据
				this.equipData = datas.equipPresent || {}
				this.detailInfo = datas.equipPresent || {}
				this.equipName = datas.equipName
				this.online = datas.connected == '0' ? true : false
			}
		},
		// 图标点击操作
		async changeSet({ type, val, isHad, isStop, name }) {
			const _this = this
			// 判断在线状态
			if (!this.online) {
				// 离线，提示不能操作
				this.$message('设备当前是离线状态，无法操作', 'warning')
				return
			}
			// 如果是尾枪联动，判断是否有设备
			if (isHad) {
				const { data: res } = await this.$http.get('/sf' + this.$api.hasTailGun + '?equipId=' + this.equipId)
				if (res.code == 0) {
					let datas = res.data
					console.log(datas)
					if (datas == 'false') {
						this.$message('该水肥机无尾枪设备', 'warning')
						return
					}
				} else {
					this.$message(res.msg, 'warning')
					return
				}
			}
			// 水泵联动和施肥联动判断设备是否是停止状态
			if (isStop) {
				// 是停止状态，走提示且不往下进行
				if (this.runStatus == '2') {
					this.$message('设备当前是停止状态，需要先开启设备运行', 'warning')
					return
				}
			}
			// 判断单个设备的运行状态,0:目前是开启状态  1:目前是关闭状态
			let status = this.detailInfo[type]
			console.log(status, val)
			if (status == '0') {
				// 已开启，需关闭
				this.$confirm(`是否确认关闭${name}?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					customClass: 'waterMsg',
				})
					.then(() => {
						_this.setSpeed('1', val)
					})
					.catch(() => {})
			} else {
				// 已关闭，需开启
				this.$confirm(`是否确认开启${name}?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					customClass: 'waterMsg',
				})
					.then(() => {
						_this.setSpeed('0', val)
					})
					.catch(() => {})
			}
		},
		// 判断是否存在尾枪联动设备
		async hasTailGun() {
			const { data: res } = await this.$http.get('/sf' + this.$api.hasTailGun + '?equipId=' + this.equipId)
			if (res.code == 0) {
				let datas = res.data
				console.log(datas)
				if (datas == 'false') {
					this.$message('该水肥机无尾枪设备', 'warning')
					return false
				}
			} else {
				this.$message(res.msg, 'warning')
				return false
			}
			return true
		},
		// 运行状态切换事件
		changeStatus(status, val) {
			// 先判断是否在线
			if (!this.online) {
				// 离线，提示不能操作
				this.$message('设备当前是离线状态，无法操作', 'warning')
				return
			}
			if (this.runStatus == status) {
				return
			}
			this.runStatus = status
			// 请求
			this.setSpeed('', val)
		},

		// 线型图片的判断
		lineIcon({ type }) {
			// 判断是否在线
			// 在线
			if (this.detailInfo.connected == '0') {
				// 判断是否开启状态
				if (this.detailInfo[type] == '0') {
					return require('@/assets/img/water/icon_liney.png')
				}
				return require('@/assets/img/water/icon_linen.png')
			} else {
				return require('@/assets/img/water/icon_linen.png')
			}
		},
		bgIcon(type) {
			if (this.detailInfo.connected == '0') {
				// 判断是否开启状态
				if (this.detailInfo[type] == '0') {
					return true
				}
				return false
			} else {
				return false
			}
		},
		iconIcon({ iconn, icony, type }) {
			if (this.detailInfo.connected == '0') {
				// 判断是否开启状态
				if (this.detailInfo[type] == '0') {
					return icony
				}
				return iconn
			} else {
				return iconn
			}
		},
		// 进度条的加减
		speedTab(type) {
			console.log(type)
			// 首先判断设备状态
			if (!this.online) {
				// 离线，提示不能操作
				this.$message('设备当前是离线状态，无法操作', 'warning')
				return
			}
			// 设备是在线状态，进行操作
			// 加
			this.speedNum = this.speed
			if (type) {
				if (this.speedNum >= 100) {
					return
				}
				this.speedNum += 1
				console.log(this.speedNum)
			} else {
				// 减
				if (this.speedNum <= 0) {
					return
				}
				this.speedNum -= 1
				console.log(this.speedNum)
			}
			// 请求接口
			this.setSpeed(this.speedNum, 31)
		},
		// 请求设置变更接口
		async setSpeed(value, set) {
			let httpData = {
				id: this.equipId,
				set: set,
				value: value,
			}
			const { data: res } = await this.$http.post('/sf' + this.$api.setEquip, httpData)
			if (res.code == 0) {
				this.detailInfo = res.data
			} else {
				this.$message(res.msg, 'error')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
#handtab {
	margin-bottom: -12px;
	.tab-sets {
		margin-top: -12px;
	}
	// 上面可操作图标
	.tabs-set {
		width: 439px;
		height: 91px;
		background: url('../../assets/img/water/hand_bg.png') no-repeat;
		background-size: 100% 100%;
		margin-left: -13px;
		padding: 0 22px;

		position: relative;

		.set-title {
			position: absolute;
			top: 5px;
			left: 38%;
		}
	}
	.tabs-set-dcf {
		margin-top: -12px;
		padding: 0 17px;
		.icon_qie {
			width: 15px;
			height: 15px;
			cursor: pointer;
		}
	}
	.tabs-setdiv {
		cursor: pointer;
	}
	.set-tab {
		width: 45px;
		height: 45px;
		border: 2px solid rgba(141, 141, 141, 0.5);
		border-radius: 50%;
		margin-bottom: 5px;
		position: relative;
	}
	// 线
	.line-icon {
		position: absolute;
		z-index: 1;
		width: 34px;
		height: 34px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	// 纯色背景
	.set-smallbg {
		width: 19px;
		height: 19px;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;
		background-color: rgba(153, 153, 153, 0.23);
	}
	.set-smallbg-y {
		background-color: rgba(0, 186, 255, 0.23);
	}
	// 第四层不同的图标
	.icons {
		max-width: 11px;
		max-height: 11px;
		position: absolute;

		z-index: 4;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.switchs {
		width: 305px;
		height: 49px;
		background: url('../../assets/img/water/switch_bg.png') no-repeat;
		background-size: 100% 100%;
	}
	.switch-div {
		width: 50%;
		height: 49px;
		font-size: 16px;
		color: #fff;
		text-align: center;
		line-height: 49px;
		cursor: pointer;
	}
	.switch-div-active {
		background: url('../../assets/img/water/switch_h.png') no-repeat;
		background-size: 100% 100%;
		color: #50d1ff;
	}
}
</style>
