<template>
  <div>
    <div class="radar" ref="radarRef"></div>
    <div class="title">管理状态</div>
  </div>
</template>
<script>
export default{
  props: {
    data: {
      type: Array,
      required:true 
    },
  },
  data(){
    return{}
  },
  mounted(){
    this.initRadar()
  },
  watch:{
			data:{
				handler(){
					this.initRadar();
			 	},
				deep:true
			}
		},
  methods: {
    initRadar() {
      let _this= this;
      if(this.data.length == 0){
            return
        }
      let myChart = this.$echarts.init(this.$refs.radarRef)
      let data = JSON.parse(JSON.stringify(this.data));
      let indicatorData = [];
      let seriesData = []
      let total = 0;
      let numArr= [];
      data.forEach(item=>{
        item.value = +(item.value)
        numArr.push(item.value)
        total += item.value
      })
      let max = data.reduce((a,b)=>a.value > b.value ? a : b)
      data.forEach((item,index)=>{
        item.value = +(item.value)
        let per = _this.getPercentWithPrecision(numArr,index,0);
        indicatorData.push({
          text:item.name,
          percent:per,
          max: 1
        });
        seriesData.push(per/100)
      })
      const option = {
        radar: [
            {
                indicator: indicatorData,
                axisName: {
                    formatter:(value,indicator)=>{
                      let percent = indicator.percent;
                      return `${value}${percent+'%'}`
                    },
                    fontSize: '16',
                    lineHeight:10,
                    color: '#fff',
                    overflow:'break'
                },
                splitLine:{
                  lineStyle:{
                    color:"#2F49A3",
                    width:3
                  }
                },
                splitNumber: 3,
                splitArea: {
                    areaStyle: {
                        color: [
                            'rgba(47,73,163,.1)','rgba(32,42,102, .4)','rgba(83,126,249, .25)'
                        ].reverse(),
                    },
                },
                label: {
                    show: true,
                    color: '#fff',
                },
                axisLine: {
                    show: true,
                    lineStyle:{
                      color:"rgba(82,195,255,.2)"
                    }
                },
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: 'rgb(145,169,255,0.3)',
                    },
                },
                center: ['57%', '50%'],
                radius: 75,
            },
        ],
        series: [
            {
                type: 'radar',
                symbol:"none",
                lineStyle: {
                    color: '#47EAF8',
                },
                data: [
                    {
                        value: seriesData,
                        name: '',
                    },
                ],
            },
        ],
      };
      myChart.setOption(option)
    },
    getPercentWithPrecision(valueList, idx, precision) {
        if (!valueList[idx]) {
            return 0;
        }

        var sum = valueList.reduce(function(acc, val) {
            return acc + (isNaN(val) ? 0 : val);
        }, 0);
        if (sum === 0) {
            return 0;
        }

        var digits = Math.pow(10, precision);
        var votesPerQuota = valueList.map(val => {
            return ((isNaN(val) ? 0 : val) / sum) * digits * 100;
        });
        var targetSeats = digits * 100;

        var seats = votesPerQuota.map(votes => Math.floor(votes));
        var currentSum = seats.reduce(function(acc, val) {
            return acc + val;
        }, 0);

        var remainder = votesPerQuota.map((votes, idx) => votes - seats[idx]);

        // Has remainding votes.
        while (currentSum < targetSeats) {
            // Find next largest remainder.
            var max = -Infinity; //Number.NEGATIVE_INFINITY;
            var maxId = null;
            for (var i = 0, len = remainder.length; i < len; ++i) {
                if (remainder[i] > max) {
                    max = remainder[i];
                    maxId = i;
                }
            }

            // Add a vote to max remainder.
            ++seats[maxId];
            remainder[maxId] = 0;
            ++currentSum;
        }

        return seats[idx] / digits;
    },
  },
}
</script>
<style lang='scss' scoped>
.radar{
  width: 415px;
	height: 236px;
}
.title{
  position: absolute;
  top:22%;
  left:5%;
  width: 40px;
  text-align: center;
  padding: 22px 5px;
  line-height: 22px;
  background-image: url('../../assets/img/homeEquip/rml-border.png');
  background-size: 42px 136px;
  background-position: 35% 20%;
  color:#00CDFF;
  font-size: 19px;
}
</style>