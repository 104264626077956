var oWebControl = null
var initCount = 0
var pubKey = ''
/**
 * @description 属性值  类型  说明  是否必须有
 * @description 海康视频流播放js组件
 * @params videoWidth [number] 视频的宽度
 * @params videoHeight [number] 视频的高度
 * @params videoId [String] 容器的id值
 * @params isGroup [Bool] 是否是批量播放的
 * @params layout [String] 布局格式
 * @params equipId [String] 视频流编码
 * @params videoIsPlay [Bool] 是否是播放状态
 * @params controlType [String] 云台控制的代表值    false
 * @params screenIsPlay [Bool] 全屏状态下视频是否播放  false
 */
import bus from '../api/bus'
export default {
	mounted() {
		const _this = this
		// 监听resize事件，使插件窗口尺寸跟随DIV窗口变化
		window.addEventListener('resize', this.listenResize)
		document.addEventListener('scroll', this.listenScroll)
		// this.initVideo()
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.listenResize)
		document.removeEventListener('scroll', this.listenScroll)
		this.closeVideo()
	},
	methods: {
		listenScroll() {
			const _this = this
			console.log('scroll')
			if (oWebControl != null) {
				oWebControl.JS_Resize(_this.videoWidth, _this.videoHeight)
				_this.setWndCover()
			}
		},
		listenResize() {
			const _this = this
			console.log('listenResize')
			if (oWebControl != null) {
				oWebControl.JS_Resize(_this.videoWidth, _this.videoHeight)
				_this.setWndCover()
			}
		},
		// 插件启动
		initVideo() {
			const _this = this
			console.log(_this.videoId)
			oWebControl = new WebControl({
				szPluginContainer: _this.videoId, // 指定容器id
				iServicePortStart: 15900, // 指定起止端口号，建议使用该值
				iServicePortEnd: 15909,
				szClassId: '23BF3B0A-2C56-4D97-9C03-0CB103AA8F11', // 用于IE10使用ActiveX的clsid
				cbConnectSuccess: function () {
					console.log('cbConnectSuccess success')
					_this.setCallbacks()
					// 创建WebControl实例成功
					oWebControl
						.JS_StartService('window', {
							// WebControl实例创建成功后需要启动服务
							dllPath: './VideoPluginConnect.dll', // 值"./VideoPluginConnect.dll"写死
						})
						.then(
							function () {
								oWebControl.JS_CreateWnd(_this.videoId, _this.videoWidth, _this.videoHeight).then(function () {
									console.log('JS_CreateWnd success')
									//JS_CreateWnd创建视频播放窗口，宽高可设定
									_this.getPubKey() // 创建播放实例成功后初始化
								})
							},
							function () {
								// 启动插件服务失败
								console.log('JS_CreateWnd error')
							}
						)
				},
				cbConnectError: function () {
					// 创建WebControl实例失败
					oWebControl = null
					$('#' + _this.videoId).html('插件未启动，正在尝试启动，请稍候...')
					WebControl.JS_WakeUp('VideoWebPlugin://') // 程序未启动时执行error函数，采用wakeup来启动程序
					initCount++
					if (initCount < 3) {
						setTimeout(function () {
							_this.initVideo()
						}, 3000)
					} else {
						$('#' + _this.videoId).html('插件启动失败，请检查插件是否安装！')
					}
				},
				cbConnectClose: function (bNormalClose) {
					// 异常断开：bNormalClose = false
					// JS_Disconnect正常断开：bNormalClose = true
					console.log('cbConnectClose')
					setTimeout(() => {
						oWebControl = null
					}, 300)
				},
			})
		},
		init() {
			let thisLayout = this.layout ? this.layout : '1x1'
			const _this = this
			////////////////////////////////// 请自行修改以下变量值	////////////////////////////////////
			var appkey = '29531372' //综合安防管理平台提供的appkey，必填
			var secret = _this.setEncrypt('QUneUFqXsajFTKHSfdRq') //综合安防管理平台提供的secret，必填
			var ip = '222.132.93.150' //综合安防管理平台IP地址，必填
			var playMode = 0 //初始播放模式：0-预览，1-回放
			var port = 1460 //综合安防管理平台端口，若启用HTTPS协议，默认443
			var snapDir = 'D:\\SnapDir' //抓图存储路径
			var videoDir = 'D:\\VideoDir' //紧急录像或录像剪辑存储路径
			var layout = thisLayout //playMode指定模式的布局
			var enableHTTPS = 1 //是否启用HTTPS协议与综合安防管理平台交互，这里总是填1
			var encryptedFields = 'secret' //加密字段，默认加密领域为secret
			var showToolbar = 0 //是否显示工具栏，0-不显示，非0-显示
			var showSmart = 0 //是否显示智能信息（如配置移动侦测后画面上的线框），0-不显示，非0-显示
			var buttonIDs = ' ' //自定义工具条按钮
			////////////////////////////////// 请自行修改以上变量值	////////////////////////////////////

			oWebControl
				.JS_RequestInterface({
					funcName: 'init',
					argument: JSON.stringify({
						appkey: appkey, //API网关提供的appkey
						secret: secret, //API网关提供的secret
						ip: ip, //API网关IP地址
						playMode: playMode, //播放模式（决定显示预览还是回放界面）
						port: port, //端口
						snapDir: snapDir, //抓图存储路径
						videoDir: videoDir, //紧急录像或录像剪辑存储路径
						layout: layout, //布局
						enableHTTPS: enableHTTPS, //是否启用HTTPS协议
						encryptedFields: encryptedFields, //加密字段
						reconnectTimes: 2,
						showToolbar: 0, //是否显示工具栏
						showSmart: showSmart, //是否显示智能信息
						buttonIDs: buttonIDs, //自定义工具条按钮
					}),
				})
				.then(function (oData) {
					console.log('JS_RequestInterface')
					oWebControl.JS_Resize(_this.videoWidth, _this.videoHeight) // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
					setTimeout(() => {
						console.log(_this.isGroup)
						if (_this.isGroup) {
							_this.lookGroupVideo()
						} else {
							_this.lookVideo()
						}
					}, 200)
				})
				.catch((err) => {
					console.log(err)
				})
		},
		//获取公钥
		getPubKey() {
			const _this = this
			oWebControl
				.JS_RequestInterface({
					funcName: 'getRSAPubKey',
					argument: JSON.stringify({
						keyLength: 1024,
					}),
				})
				.then(function (oData) {
					console.log(oData)
					if (oData.responseMsg.data) {
						pubKey = oData.responseMsg.data
						_this.init()
					}
				})
		},
		//RSA加密
		setEncrypt(value) {
			var encrypt = new JSEncrypt()
			encrypt.setPublicKey(pubKey)
			return encrypt.encrypt(value)
		},
		// 视频预览
		lookVideo() {
			var cameraIndexCode = this.equipId //获取输入的监控点编号值，必填
			var streamMode = 1 //主子码流标识：0-主码流，1-子码流
			var transMode = 1 //传输协议：0-UDP，1-TCP
			var gpuMode = 0 //是否启用GPU硬解，0-不启用，1-启用
			var wndId = -1 //播放窗口序号（在2x2以上布局下可指定播放窗口）

			cameraIndexCode = cameraIndexCode.replace(/(^\s*)/g, '')
			cameraIndexCode = cameraIndexCode.replace(/(\s*$)/g, '')

			oWebControl
				.JS_RequestInterface({
					funcName: 'startPreview',
					argument: JSON.stringify({
						cameraIndexCode: cameraIndexCode, //监控点编号
						streamMode: streamMode, //主子码流标识
						transMode: transMode, //传输协议
						gpuMode: gpuMode, //是否开启GPU硬解
						wndId: wndId, //可指定播放窗口
					}),
				})
				.then((res) => {
					this.videoIsPlay = true
					console.log(res)
				})
				.catch((err) => {
					this.videoIsPlay = false
					console.log(err)
				})
		},
		// 消息监听
		setCallbacks() {
			const _this = this
			oWebControl.JS_SetWindowControlCallback({
				cbIntegrationCallBack: function (oData) {
					if (oData.responseMsg.type == 7) {
						console.log(oData)
						if (_this.isScreen) {
							_this.toSmallScreen()
						} else {
							_this.toFullScreen()
						}
					}
				},
			})
		},
		// 播放和暂停
		makeVideo() {
			if (this.videoIsPlay) {
				oWebControl.JS_RequestInterface({
					funcName: 'stopAllPreview',
				})
			} else {
				setTimeout(() => {
					this.lookVideo()
				}, 100)
			}
			this.videoIsPlay = !this.videoIsPlay
		},
		// 视频切换
		changeVideo() {
			if (oWebControl != null) {
				oWebControl.JS_RequestInterface({
					funcName: 'stopAllPreview',
				})
				setTimeout(() => {
					this.lookVideo()
				}, 200)
			} else {
				setTimeout(() => {
					this.initVideo()
				}, 100)
			}
		},
		//停止播放,播放组件销毁
		closeVideo() {
			if (oWebControl != null) {
				// 先进行放初始化，然后销毁窗口
				oWebControl
					.JS_RequestInterface({
						funcName: 'uninit',
					})
					.then(function (oData) {
						console.log(oData)
						// 服务断开
						if (oWebControl != null) {
							console.log('JS_HideWnd')
							oWebControl.JS_HideWnd() // 先让窗口隐藏，规避插件窗口滞后于浏览器消失问题
							oWebControl.JS_Disconnect().then(
								function () {
									console.log('JS_Disconnect')
									// 断开与插件服务连接成功
								},
								function () {
									// 断开与插件服务连接失败
								}
							)
							//  插件窗口
							oWebControl.JS_DestroyWnd().then(
								function () {
									console.log('JS_DestroyWnd')
									// oWebControl 为 WebControl 的对象
									// 销毁插件窗口成功
								},
								function () {
									// 销毁插件窗口失败
								}
							)
						}
					})
			}
		},
		// 云台控制
		async controlChange(type, isdef = true) {
			// 判断是视频是否是播放状态
			if (isdef) {
				if (!this.videoIsPlay) {
					this.$message('视频已暂停，请先点击播放', 'error')
					return
				}
			} else {
				if (!this.screenIsPlay) {
					this.$message('视频已暂停，请先点击播放', 'error')
					return
				}
			}
			if (this.controlType == '') {
				let params = {
					cameraIndexCode: this.equipId,
					action: 0,
					command: Number(type),
					speed: 20,
				}
				this.controlType = type
				const { data: res } = await this.$http.post(this.$api.ytControl, params)
			} else if (this.controlType == type) {
				// 二次点击-停止
				let params = {
					cameraIndexCode: this.equipId,
					action: 1,
					command: Number(type),
					speed: 20,
				}
				this.controlType = ''
				const { data: res } = await this.$http.post(this.$api.ytControl, params)
			} else {
				// 其它操作
				// 先调取停止，然后再操控
				let params = {
					cameraIndexCode: this.equipId,
					action: 1,
					command: Number(type),
					speed: 20,
				}
				let startParams = {
					cameraIndexCode: this.equipId,
					action: 0,
					command: Number(type),
					speed: 20,
				}
				const { data: res } = await this.$http.post(this.$api.ytControl, params)
				if (res.code == 200) {
					// 调开始
					this.controlType = type
					const { data: res } = await this.$http.post(this.$api.ytControl, startParams)
				}
			}
		},
		// 抓图
		async makeCapture() {
			// 判断是视频是否是播放状态
			if (!this.videoIsPlay) {
				this.$message('视频已暂停，请先点击播放', 'error')
				return
			}
			// 判断云台控制是否是开启状态，是的话给关闭
			if (this.controlType != '') {
				let params = {
					cameraIndexCode: this.equipId,
					action: 1,
					command: Number(this.controlType),
					speed: 20,
				}
				this.controlType = ''
				await this.$http.post(this.$api.ytControl, params)
			}
			let makeparams = {
				cameraIndexCode: this.equipId,
			}
			const { data: res } = await this.$http.post(this.$api.ytCapture + '?cameraIndexCode=' + this.equipId)
			if (res.code == '0') {
				this.$message({
					message: '图片抓取成功',
					type: 'success',
					showClose: false,
					center: true,
				})
				let datas = res.data
				if (datas) {
					this.mqLastImg = datas.picUrl
					this.captureList.unshift(datas.picUrl)
				}
				if (this.captureList.length > 2) {
					this.isShowMqbtn = true
				} else {
					this.isShowMqbtn = false
				}
				// 请求土壤信息，并添加过程数据
				this.getOtherData()
			} else {
				this.$message({
					message: res.msg,
					type: 'error',
					showClose: false,
					center: true,
				})
			}
		},
		// 屏幕计算
		setWndCover() {
			const _this = this
			var iWidth = $(window).width()
			var iHeight = $(window).height()
			var oDivRect = $('#' + _this.videoId)
				.get(0)
				.getBoundingClientRect()

			var iCoverLeft = oDivRect.left < 0 ? Math.abs(oDivRect.left) : 0
			var iCoverTop = oDivRect.top < 0 ? Math.abs(oDivRect.top) : 0
			var iCoverRight = oDivRect.right - iWidth > 0 ? Math.round(oDivRect.right - iWidth) : 0
			var iCoverBottom = oDivRect.bottom - iHeight > 0 ? Math.round(oDivRect.bottom - iHeight) : 0

			iCoverLeft = iCoverLeft > _this.videoWidth ? _this.videoWidth : iCoverLeft
			iCoverTop = iCoverTop > _this.videoHeight ? _this.videoHeight : iCoverTop
			iCoverRight = iCoverRight > _this.videoWidth ? _this.videoWidth : iCoverRight
			iCoverBottom = iCoverBottom > _this.videoHeight ? _this.videoHeight : iCoverBottom

			oWebControl.JS_RepairPartWindow(0, 0, _this.videoWidth + 1, _this.videoHeight) // 多1个像素点防止还原后边界缺失一个像素条
			if (iCoverLeft != 0) {
				oWebControl.JS_CuttingPartWindow(0, 0, iCoverLeft, _this.videoHeight)
			}
			if (iCoverTop != 0) {
				oWebControl.JS_CuttingPartWindow(0, 0, _this.videoWidth + 1, iCoverTop) // 多剪掉一个像素条，防止出现剪掉一部分窗口后出现一个像素条
			}
			if (iCoverRight != 0) {
				oWebControl.JS_CuttingPartWindow(_this.videoWidth - iCoverRight, 0, iCoverRight, _this.videoHeight)
			}
			if (iCoverBottom != 0) {
				oWebControl.JS_CuttingPartWindow(0, _this.videoHeight - iCoverBottom, _this.videoWidth, iCoverBottom)
			}
		},
		//全屏事件 -------- 不用
		toFullScreen() {
			const _this = this
			if (!this.videoIsPlay) {
				return _this.$messages.error('视频为暂停状态，请点击视频播放')
			}
			if (this.isNeedHide) {
				bus.$emit('hideVideos')
			}
			oWebControl
				.JS_RequestInterface({
					funcName: 'setFullScreen',
				})
				.then(function (oData) {
					_this.isScreen = true
					console.log(oData)
				})
		},
		// 退出全屏  -------- 不用
		toSmallScreen() {
			const _this = this
			if (this.isNeedHide) {
				bus.$emit('showVideos')
			}
			oWebControl
				.JS_RequestInterface({
					funcName: 'exitFullScreen',
				})
				.then(function (oData) {
					_this.isScreen = false
				})
		},
		hideVideos() {
			oWebControl.JS_HideWnd()
		},
		// 视频显示
		showVideos() {
			oWebControl.JS_ShowWnd()
		},
		// 批量播放事件  ---------------- start
		// 批量视频预览
		lookGroupVideo() {
			console.log('lookGroupVideo')
			var cameraIndexCode = this.equipId //获取输入的监控点编号值，必填
			var streamMode = 1 //主子码流标识：0-主码流，1-子码流
			var transMode = 1 //传输协议：0-UDP，1-TCP
			var gpuMode = 0 //是否启用GPU硬解，0-不启用，1-启用
			var wndId = -1 //播放窗口序号（在2x2以上布局下可指定播放窗口）

			cameraIndexCode = cameraIndexCode.replace(/(^\s*)/g, '')
			cameraIndexCode = cameraIndexCode.replace(/(\s*$)/g, '')

			let playList = []
			for (let i = 0; i < this.videosList.length; i++) {
				if (this.videosList[i]['isOnline'] == '0') {
					playList.push({
						cameraIndexCode: this.videosList[i]['equipId'],
						gpuMode: 0,
						streamMode: 1,
						transMode: 1,
						wndId: i + 1,
					})
				}
			}
			oWebControl.JS_RequestInterface({
				funcName: 'startMultiPreviewByCameraIndexCode',
				argument: {
					list: playList,
				},
			})
		},
		// 批量播放事件  ---------------- end
	},
}
