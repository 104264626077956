<template>
	<div>
		<div class="line2" ref="lineRef"></div>
	</div>
</template>

<script>
import { loopShowTooltip } from '../../util/loop-tooltip'

	export default {
		props: {
			data: {
				type: Object,
				required:true 
			},
		},
		data() {
			return {
				chartInstane: null,
      	tooltipTimer:null
			}
		},
		mounted() {
			this.echartLine();
		},
		watch:{
			data:{
				handler(){
					this.echartLine()
				},
				deep:true
			}
		},
		destroyed () {
    	this.tooltipTimer && this.tooltipTimer.clearLoop();
    },
		methods: {
			// 中间市场价格折线图
			echartLine() {
				const _this = this
				if(!this.data || !this.data.xAxis || this.data.xAxis.length == 0){
					return;
				}
				let myChart = this.$echarts.init(this.$refs.lineRef)
				let data = JSON.parse(JSON.stringify(this.data))
				let seriesData = data.series;
				let xData = [];
				let xLabel = data.xAxis;
				let optionData= [];
				let colors = [
					{
						color:'#7BB3F8',
						rgb:'123,179,248'
					},
					{
						color:'#B771FF',
						rgb:'183,113,255'
					},
					{
						color:'#27FCFF',
						rgb:'39,252,255'
					},
					{
						color:'#806BFF',
						rgb:'128,107,255'
					}
				]
				seriesData.forEach((item,index)=>{
					item.data.forEach(item=>+(item))
					xData.push(item.name)
					optionData.push({
						type: 'line',
						name: item.name,
						symbol: 'none', // 默认是空心圆（中间是白色的）.设置为none不显示
						// showAllSymbol: true,
						symbolSize: 10,
						smooth: true,
						label: {
							show: false,
							position: 'top',
							textStyle: {
								color: colors[index].color,
							},
						},
						itemStyle: {
							color: colors[index].color,
							
						},
						//线条
						lineStyle: {
							color: colors[index].color, // 线条颜色
							type: 'solid'
						},
						tooltip: {
							show: true,
						},
						areaStyle: {
							//区域填充样式
							normal: {
								//线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
								color: new this.$echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,

									[{
											offset: 0,
											color: 'rgba('+colors[index].rgb+',0.6)',
										},
										{
											offset: .5,
											color: 'rgba('+colors[index].rgb+',0.4)',
										},
										{
											offset: 1,
											color: 'rgba('+colors[index].rgb+',0.1)',
										},
									],
									false
								),
							},
						},
						data: item.data,
					})
				})
				var option = {
					// color: ['#00FCFF', '#7888FF'],
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgb(113,200,255, 0.28)',
						borderWidth: 0,
						axisPointer:{
                  type:"line",
                  lineStyle:{
                      color:"#3371FC"
                  }
              },
						textStyle: {
							fontSize: 15,
							color: '#fff',
						},
						position: (pos,param,dom, rect, size)=>{
            var obj = {top: 60};
            let halfContent = size.viewSize[0] / 2;
            if(pos[0] <= halfContent) {
              obj['left'] = pos[0]
            } else if(pos[0] >halfContent) {
              obj['right'] = size.viewSize[0] - pos[0]
            }
            return obj;
          },
						formatter(params) {
							// console.log(params);
							let result = '';
							if(params.length > 0){
                result +=`<div style="font-size:18px;">${params[0].axisValue}异常</div>`
								params.forEach(item=>{
									result += `<div style="font-size:16px;margin-top:5px;">${item.seriesName}：${item.value && parseInt(item.value).toFixed()}次</div>`
								})
							}
							return result;
						}
					},
					legend: {
						left:"15%",
						// selectedMode:false,
						show:true,
						data:xData,
						icon:"circle",
						left:'center',
						textStyle: {
								color: "#fff",
								fontSize: 14
						},
					},
					grid: {
						top: '18%',
						left: '8%',
						right: '2%',
						bottom: '12%',
					},
					xAxis: {
						type: 'category',
						boundaryGap: false, //一定要给x轴设置,与前面紧挨着(紧挨边缘)
						axisLine: {
							//坐标轴轴线相关设置。数学上的x轴
							show: false,
						},
						axisLabel: {
							//坐标轴刻度标签的相关设置
							textStyle: {
								fontSize: 16,
								color: '#fff',
							},
						},
						splitLine: {
							show: false,
						},
						axisTick: {
							show: false,
							inside: true,
							lineStyle: {
								color: '#192a44',
							},
						},
						data: xLabel,
					},
					yAxis: [
						{
					
							min: 0,
							name:'（次）',
							nameTextStyle:{
								color:"#fff",
								fontSize:16,
								padding:[0,40,0,0]
							},
							splitLine: {
								show: true,
								lineStyle:{
										color:"rgba(0,90,198,.2)",
										width:3 
								}
							},
							minInterval:1,
							axisLine: {
								show: false,
								lineStyle: {
									color: '#192a44',
								},
							},
							axisLabel: {
								show: true,
								color: '#fff',
								fontSize:16
							},
							axisTick: {
								show: false,
							},
						}
					],
					series: optionData
				}
				myChart.setOption(option)
				this.tooltipTimer && this.tooltipTimer.clearLoop();
				this.tooltipTimer = 0;
				this.tooltipTimer = loopShowTooltip(myChart, option, {
					interval: 3000, // 轮播间隔时间
					loopSeries: true, // 是否开启轮播循环
					dataLength:xLabel.length
				});
			},
			




		}
	}
</script>
<style lang="less" scoped>
.line2 {
height: 220px;
width: 800px;
}
</style>

