<template>
  <div>
    <div class="bar" ref="barRef"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.initBar();
  },
  methods: {
    initBar() {
      let myChart = this.$echarts.init(this.$refs.barRef);
      const option = {
        grid: {
          left: "6%",
          top: "10%",
          right: "3%",
          bottom: "17%",
        },
        xAxis: {
          type: "category",
          data: ["墒情设备", "水肥设备", "气象设备", "监控设备", "虫情设备"],
          axisLabel: {
            show: true,
            formatter: (value) => {
              return `{a|${value}}`;
            },
            rich: {
              a: {
                color: "#fff",
                fontSize: 14,
                padding: [5, 10, 5, 10],
                backgroundColor: {
                  image: require("@/assets/img/HomeBrain/lbt-tb.png"),
                },
              },
            },
            textStyle: {
              color: "#fff",
              fontSize: 14,
            },
            padding: [10, 0, 0, 0],
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: false,
            color: "#999999",
            fontSize: 16,
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        series: [
          {
            type: "pictorialBar",
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(91,252,244,1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(91,252,244,0)",
                    },
                  ],
                  global: false,
                },
              },
            },
            data: [
              {
                name: "墒情设备",
                value: 100,
              },
              {
                name: "水肥设备",
                value: 0,
              },
              {
                name: "气象设备",
                value: 20,
              },
              {
                name: "监控设备",
                value: 312,
              },
              {
                name: "虫情设备",
                value: 200,
              },
            ],
            markLine: {
              symbol: "none",
              data: [
                {
                  xAxis: "墒情设备",
                  label: {
                    show: true,
                    color: "#fff",
                    fontSize: 14,
                    formatter: "100（瓦/时）",
                  },
                  lineStyle: {
                    normal: {
                      color: "#fff",
                      width: 1,
                      type: "dotted",
                    },
                  },
                },
                {
                  xAxis: "水肥设备",
                  label: {
                    show: true,
                    color: "#fff",
                    fontSize: 14,
                    formatter: "0（瓦/时）",
                  },
                  lineStyle: {
                    normal: {
                      color: "#fff",
                      width: 1,
                      type: "dotted",
                    },
                  },
                },
                {
                  xAxis: "气象设备",
                  label: {
                    show: true,
                    color: "#fff",
                    fontSize: 14,
                    formatter: "20（瓦/时）",
                  },
                  lineStyle: {
                    normal: {
                      color: "#fff",
                      width: 1,
                      type: "dotted",
                    },
                  },
                },
                {
                  xAxis: "监控设备",
                  label: {
                    show: true,
                    color: "#fff",
                    fontSize: 14,
                    formatter: "312（瓦/时）",
                  },
                  lineStyle: {
                    normal: {
                      color: "#fff",
                      width: 1,
                      type: "dotted",
                    },
                  },
                },
                {
                  xAxis: "虫情设备",
                  label: {
                    show: true,
                    color: "#fff",
                    fontSize: 14,
                    formatter: "200（瓦/时）",
                  },
                  lineStyle: {
                    normal: {
                      color: "#fff",
                      width: 1,
                      type: "dotted",
                    },
                  },
                },
              ],
            },
            stack: "a",
            symbol:
              "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
            symbolSize: ["350%", "100%"],
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>
<style lang='scss' scoped>
.bar {
  height: 242px;
  width: 600px;
}
</style>