<template>
  <div class="mapPage">
    <div ref="mapRef" class="map"></div>
  </div>
</template>
<script>
export default{
  data(){
    return{}
  },
  mounted(){
    this.initMap()
  },
  methods: {
    async initMap() {
      let myChart = this.$echarts.init(this.$refs.mapRef)
      const {data} = await this.$http.get('/china.json')
      this.$echarts.registerMap('China', data)
      let seriesData = [
        {
          type: 'map',
          map: 'China',
          cursor:'auto',
          geoIndex: 1,
          silent:true,
          layoutCenter: ['50%', '50%'],
          layoutSize: "125%",
          selectedMode: 'single',
          roam: false,
          itemStyle: {
              normal: {
                  areaColor: '#4795a5',
                  borderColor: '#5AF5FF',
                  borderWidth: 1
              },
              emphasis: {
                  label: {
                      show: false
                  },
                  areaColor: '#00177B'
              }
          },
          data:[
            {
              name: "新疆", value: 2057.34
            }
          ]
        }, 
        {
          type: 'effectScatter',
          coordinateSystem: 'geo',
          showEffectOn: 'render',
          zlevel: 3,
          cursor:'auto',
          rippleEffect: {
            period: 15,
            scale: 4,
            brushType: 'fill'
          },
          hoverAnimation: true,
          label: {
              normal: {
                  show: true,
                  position: 'inside',
                  backgroundColor: 'rgba(244, 175, 38, 0.1)',
                  formatter: '{b}',
                  padding: [10, 12],
                  borderRadius: 0,
                  borderWidth: 1,
                  borderColor: 'rgba(0,0,0,0.1)',
                  color: '#FFFFFF',

              },
              emphasis: {
                  show: true
              }
          },
          symbolSize: "5",
          itemStyle: {
              normal: {
                  show: true,
                  color: '#1DE9B6',
                  shadowBlur: 10,
                  shadowColor: '#333'
              }
          },
          data:[
            {value: [111.670801, 40.818311],itemStyle:{color:'#f4af26'}}, 
            {value: [126.642464, 45.756967],itemStyle:{color:'#f4af26'}}, 
            {value: [125.3245, 43.886841],itemStyle:{color:'#f4af26'}},  
            {value: [114.502461, 38.045474],itemStyle:{color:'#f4af26'}},
            {value: [112.549248, 37.857014],itemStyle:{color:'#f4af26'}}, 
			{value: [108.948024, 34.263161],itemStyle:{color:'#f4af26'}},
			{value: [113.665412, 34.757975],itemStyle:{color:'#f4af26'}},
			{value: [114.298572, 30.584355],itemStyle:{color:'#f4af26'}}, 
			{value: [104.065735, 30.659462],itemStyle:{color:'#f4af26'}},
			{value: [120.153576, 30.287459],itemStyle:{color:'#f4af26'}},
			{value: [121.472644, 31.231706],itemStyle:{color:'#f4af26'}}
			
          ]
        },
      ]
      let lineData = [
        {coords: [[117.000923, 36.675807],[111.670801, 40.818311]],lineStyle:{color:'#f4af26'}}, 
        {coords: [[117.000923, 36.675807],[126.642464, 45.756967]],lineStyle:{color:'#f4af26'}}, 
		{coords: [[117.000923, 36.675807],[125.3245, 43.886841]],lineStyle:{color:'#f4af26'}},  
        {coords: [[117.000923, 36.675807],[114.502461, 38.045474]],lineStyle:{color:'#f4af26'}}, 
        {coords: [[117.000923, 36.675807],[112.549248, 37.857014]],lineStyle:{color:'#f4af26'}},
		{coords: [[117.000923, 36.675807],[108.948024, 34.263161]],lineStyle:{color:'#f4af26'}},
		{coords: [[117.000923, 36.675807],[113.665412, 34.757975]],lineStyle:{color:'#f4af26'}}, 
		{coords: [[117.000923, 36.675807],[114.298572, 30.584355]],lineStyle:{color:'#f4af26'}},  
		{coords: [[117.000923, 36.675807],[104.065735, 30.659462]],lineStyle:{color:'#f4af26'}}, 
		{coords: [[117.000923, 36.675807],[120.153576, 30.287459]],lineStyle:{color:'#f4af26'}},
		{coords: [[117.000923, 36.675807],[121.472644, 31.231706]],lineStyle:{color:'#f4af26'}}
      ]  
      lineData.forEach(info=>{
        let p1 = info.coords[0][0]
        let p2 = info.coords[1][0]
        let value = (p2-p1)/80
        seriesData.push({
          type: 'lines',
          zlevel: 2,
          cursor:'auto',
          effect: {
            show: true,
            period: 4, //箭头指向速度，值越小速度越快
            trailLength: 0.4, //特效尾迹长度[0,1]值越大，尾迹越长重
          },
          lineStyle: {
            normal: {
              color:'#f4af26',
              width: 1, //线条宽度
              opacity: 0.1, //尾迹线条透明度
              curveness: value //尾迹线条曲直度
            }
          },
          data: [info]
        })
      })
      const initOption = {
        geo: {
		 			map: 'China',
          label: {
            show:true,
            position:'insideTop',
            color: 'white',
            fontSize:10,
            formatter: `{a}`,
          },
          z:2,
          roam: false,
          silent:true,
          layoutCenter: ['50%', '50%'],
          layoutSize: "125%",
          itemStyle: {
              normal: {
                  areaColor: '#031525',
                  borderWidth: 5,
                  borderColor: '#5AF5FF',
                  shadowColor: '#5AF5FF',
                  shadowBlur: 5
              }
          }
        },
        visualMap: {
          show:false,
          min: 800,
          max: 50000,
          text: ["High", "Low"],
          left: "2%",
          bottom: "3%",
          realtime: false,
          calculable: true,
          textStyle: {
            color: "#fff",
          },
          inRange: {
            color: ["#3D808C", "#0A486D"],
          },
        },
        series: seriesData
      }
      myChart.setOption(initOption)
    }
  }
}
</script>
<style lang='scss' scoped>
.mapPage{
  width: 100%;
  height: 100%;
  cursor: default;
}
.map{
	width: 310px;
	height: 210px;
}
</style>