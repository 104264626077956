<template>
  <div>
    <div id="pie1Ref" class="pie1"></div>
  </div>
</template>
<script>
import HighCharts from 'highcharts'
export default{
	props: {
    data: {
      type: Array,
      require:true 
    },
  },
  data(){
    return{
			myChart: null
		}
  },
  mounted () {
    this.pie1();
  },
	watch:{
			data:{
				handler(){
          this.myChart = null;
					this.pie1();
			 	},
				deep:true
			}
		},
  methods: {
     pie1() {
			 if(this.data.length==0){
         return;
       }
			 let data = JSON.parse(JSON.stringify(this.data))
			 let newdata = [];
			 data.forEach(item=>{
				 newdata.push([item.name,+(item.value)])
				})
			 console.log(data);
			 let option = {
				 credits: {
					enabled: false //不显示LOGO
					},
				 chart: {
					type: 'pie',
					backgroundColor: "rgba(0, 0, 0, 0)",
					options3d: {
						enabled: true,
						alpha: 68,
						viewDistance:50,
						beta: 0, 
					}
				},
				title: {
					text: ''
				},
				legend:{
					align: 'right',
					verticalAlign: 'top',
					layout:"vertical",
					x:-80,
					y:10,
					navigation:{
						activeColor: '#fff',
						animation: true,
						arrowSize: 12,
						inactiveColor: '#333',
						style: {
							fontWeight: 'bold',
							color: '#fff',
							fontSize: '12px'
						}
					},
					itemMarginTop:8,
					itemMarginBottom:8,
					itemStyle:{
						color:"#fff",
						fontSize:16
					},
					itemHoverStyle: {
						color: "#fff", 
					},
				},
				tooltip:{
					enabled:true,
          backgroundColor:"rgba(255,255,255,0.3)",
          borderWidth:0,
          shadow:false,
          style:{
            color:"#fff",
            fontSize:16
          },
          formatter:function(){
            let percent = this.point.percentage.toFixed(0)
            return `${this.point.name}：${percent}%`;
          },
				},
				labels:{
					color:"#fff"
				},
				plotOptions: {
					pie: {
						size:"120%",
						// allowPointSelect: true,
						innerSize: '76%',
						cursor: 'pointer',
						depth: 25,
						colors:['#14AFFF','#07BE80','#CB91FF','#FF9897','#FDE367'],
						showInLegend:true,
						// states:{
						// 	inactive:{
						// 		opacity:1
						// 	}
						// },
						dataLabels: {
							enabled: true,
							formatter:function(index){
								return `<div><span style="font-size:16px;color:${this.point.color}">${this.point.name}：</span>损失${this.point.y}万元</div>`;
							},
							style: { //样式配置
									textOutline: "none", //去掉文字白边
									color: "#fff",
									fontSize: 14,
							},
						}
					}
				},
				series: [{
					data: newdata,
				}]
			 }
			 HighCharts.chart('pie1Ref', option)
			},
  },
}
</script>
<style lang='scss' scoped>
	.pie1 {
		width: 800px;
		height: 240px;
	}
</style>