<template>
	<div>
		<div ref="LineRef" class="line"></div>
	</div>
</template>
<script>
import { loopShowTooltip } from '../../util/loop-tooltip'
export default {
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			tooltipTimer: null,
		}
	},
	mounted() {
		this.initLine()
	},
	watch: {
		data: {
			handler() {
				this.initLine()
			},
			deep: true,
		},
	},
	destroyed() {
		this.tooltipTimer && this.tooltipTimer.clearLoop()
	},
	methods: {
		initLine() {
			let _this = this
			if (!this.data.xAxis || !this.data.series || this.data.series.length < 2) {
				return
			}
			let myChart = this.$echarts.init(this.$refs.LineRef)
			let colors1 = []
			let colors2 = []
			for (let i = 0; i < this.data.xAxis.length; i++) {
				colors1.push({
					type: 'linear',
					x: 0,
					x2: 1,
					y: 0,
					y2: 0,
					colorStops: [
						{
							offset: 0,
							color: '#39CEFF', // 最左边
						},
						{
							offset: 0.5,
							color: '#86eef1', // 左边的右边 颜色
						},
						{
							offset: 0.5,
							color: '#5ad6d9', // 右边的左边 颜色
						},
						{
							offset: 1,
							color: '#24C9FF',
						},
					],
				})
				colors2.push({
					type: 'linear',
					x: 0,
					x2: 1,
					y: 0,
					y2: 0,
					colorStops: [
						{
							offset: 0,
							color: '#31D5C7', // 最左边
						},
						{
							offset: 0.5,
							color: '#86eef1', // 左边的右边 颜色
						},
						{
							offset: 0.5,
							color: '#5ad6d9', // 右边的左边 颜色
						},
						{
							offset: 1,
							color: '#31D5C7',
						},
					],
				})
			}
			let data = JSON.parse(JSON.stringify(this.data))
			let xLabel = data.xAxis
			let seriesData = data.series
			let colors = ['#00DEFF', '#3BEDDE']
			let rgbs = ['0,222,255', '59,237,222']
			let leftNum = ['-63%', '58%']
			let legendData = []
			let optionData = []
			seriesData.forEach((item, index) => {
				item.value = +item.value

				legendData.push({
					name: item.name,
					itemStyle: {
						color: colors[index % 2],
					},
				})

				optionData.push(
					{
						type: 'bar',
						name: item.name,
						data: item.data,
						barWidth: '10',
						itemStyle: {
							normal: {
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: ['rgba(' + rgbs[index % 2] + ',1)'], // 0% 处的颜色
										},
										{
											offset: 1,
											color: ['rgba(' + rgbs[index % 2] + ',0)'], // 100% 处的颜色
										},
									],
									global: false, // 缺省为 false
								},
							},
						},
					},
					{
						type: 'pictorialBar',
						name: item.name,
						symbolPosition: 'end',
						data: item.data,
						symbol: 'diamond',
						symbolOffset: [leftNum[index], '-60%'],
						symbolSize: [10, 10 * 0.6],
						itemStyle: {
							normal: {
								borderWidth: 0,
								color: function (params) {
									return colors1[params.dataIndex % 6].colorStops[0].color
									// return 'red'
								},
							},
						},
					}
				)
			})
			let option = {
				grid: {
					right: '1%',
					left: '13%',
					bottom: '15%',
				},
				legend: {
					left: 'center',
					selectedMode: false,
					data: legendData,
					textStyle: {
						color: '#fff',
						fontSize: 14,
					},
				},
				tooltip: {
					backgroundColor: 'rgb(53, 53, 53, 0.51)',
					trigger: 'axis',
					borderWidth: 0,
					textStyle: {
						color: '#fff',
						fontSize: 14,
						fontWeight: 400,
					},
					axisPointer: {
						type: 'line',
						lineStyle: {
							color: '#3371FC',
						},
					},
					position: (pos, param, dom, rect, size) => {
						var obj = { top: 60 }
						let halfContent = size.viewSize[0] / 2
						if (pos[0] <= halfContent) {
							obj['left'] = pos[0]
						} else if (pos[0] > halfContent) {
							obj['right'] = size.viewSize[0] - pos[0]
						}
						return obj
					},
					extraCssText: 'z-index: 100',
					formatter: (params) => {
						let result = ''
						if (params.length > 0) {
							result += `<div style="font-size:16px">${params[0].name}：</div>`
							params.forEach((item) => {
								if (item.componentSubType == 'pictorialBar') {
									return
								}
								result += `<div style="line-height:20px;font-size:16px">${item.seriesName}：${item.value}吨</div>`
							})
						}
						return result
					},
				},
				xAxis: {
					type: 'category',
					color: '#59588D',
					data: xLabel,
					axisLabel: {
						margin: 10,
						color: '#fff',
						rotate: 30,
						textStyle: {
							fontSize: 16,
							fontWeight: 400,
						},
					},
					axisLine: {
						show: false,
						lineStyle: {
							color: '#477AA5',
						},
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: false,
					},
				},
				dataZoom: {
					start: 0,
					type: 'inside',
				},
				yAxis: {
					name: '（吨）',
					nameTextStyle: {
						color: '#fff',
						padding: [0, 30, 0, 0],
						fontSize: 16,
					},
					min: 0,
					minInterval: 1,
					// interval:40,
					// max: max3*1.2,
					axisLabel: {
						color: '#fff',
						textStyle: {
							fontSize: 16,
						},
					},
					axisLine: {
						lineStyle: {
							color: '#477AA5',
							type: 'dashed',
						},
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						show: true,
						lineStyle: {
							color: 'rgba(51,113,252,.2)',
							width: 3,
						},
					},
				},
				series: optionData,
			}
			myChart.setOption(option)
			this.tooltipTimer && this.tooltipTimer.clearLoop()
			this.tooltipTimer = 0
			this.tooltipTimer = loopShowTooltip(myChart, option, {
				interval: 3000, // 轮播间隔时间
				loopSeries: true, // 是否开启轮播循环
				dataLength: xLabel.length,
			})
		},
	},
}
</script>
<style lang="scss" scoped>
.line {
	height: 330px;
	width: 514px;
}
</style>
