<template>
  <div>
    <div ref="barRef" class="bar"></div>
  </div>
</template>
<script>
import { loopShowTooltip } from '../../util/loop-tooltip'

export default{
    props: {
    data: {
        type: Object,
        required:true
    },
  },
  data(){
    return{
      tooltipTimer:null
    }
  },
  mounted () {
    this.initBar();
  },
  watch:{
        data:{
            handler(){
                this.initBar();
            },
            deep:true
        }
    },
  destroyed () {
    this.tooltipTimer && this.tooltipTimer.clearLoop();
  },
  methods: {
    initBar(){
        const _this = this;
      if(!this.data.xAxis || !this.data.series || this.data.series.length <2){
        return
      }
      let myChart = this.$echarts.init(this.$refs.barRef)
       let seriesData = this.data.series;
      var data1 = seriesData[0].data;
      var data2 = seriesData[1].data;
      var xData = _this.data.xAxis;
      xData = xData.map(item=>item+'年')
      let optionData = [];
      let colors =['#1A81FD','#22FFC5'];
      seriesData.forEach((item,index)=>{
          optionData.push({
            name: item.name,
            data: item.data,
            type: 'bar',
            barMaxWidth: 16,
            barGap: '50%',
            itemStyle: {
                normal: {
                    color: {
                        type: 'linear',
                        x: 0,y: 1,x2: 0,y2: 0,
                        colorStops: [
                            {
                                offset: 0,
                                color: colors[index%2], // 0% 处的颜色
                            },
                            {
                                offset: 0.5,
                                color: colors[index%2], // 0% 处的颜色
                            },
                            {
                                offset: 1,
                                color: '#fff', // 100% 处的颜色
                            },
                        ],
                        global: false, // 缺省为 false
                    },
                    shadowColor:"#22FFC5",
                    shadowBlur:25,
                },
            },
          })
      })
      const option = {
        grid: {
            top: '5%',
            left: '1%',
            right:"22%",
            bottom: '15%',
        },
        legend:{
            show:true,
            // selectedMode:false,
            right:"5%",
            bottom:"25%",
            orient:"vertical",
            textStyle:{
                color:"#fff"
            }
        },
        tooltip:{
            trigger: "axis",
            backgroundColor: "rgb(113,200,255, 0.28)",
            borderWidth: 0,
            textStyle: {
            fontSize: 15,
            color: "#AED6FF",
            },
            confine:true,
            axisPointer:{
                lineStyle:{
                    color:"#3371FC"
                }
            },
            position: (pos,param,dom, rect, size)=>{
            var obj = {top: 20};
            let halfContent = size.viewSize[0] / 2;
            if(pos[0] <= halfContent) {
              obj['left'] = pos[0]
            } else if(pos[0] >halfContent) {
              obj['right'] = size.viewSize[0] - pos[0]
            }
            return obj;
          },
            formatter(params) {
                // console.log(params);
                let result = '';
                if(params.length > 0){
                    params.forEach((item,index)=>{
                        result +=`<div style="font-size:16px;margin-top:9px;color:#fff"">${item.seriesName}：${item.value}吨</div>`

                    })
                }
                return result;
            },
        },
        xAxis: [
            {
                type: 'category',
                axisLine: {
                    lineStyle: {
                        width:2,
                        color: 'rgb(255,255,255)',
                    },
                },
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                splitArea: {
                    show: false,
                },
                axisLabel: {
                    color: '#fff',
                    fontSize: 16,
                },
                data: xData,
            },
        ],
        yAxis: 
            {
                type: 'value',
                show:false,
                
            },
        series: optionData,
      };
      myChart.setOption(option)
      this.tooltipTimer && this.tooltipTimer.clearLoop();
      this.tooltipTimer = 0;
      this.tooltipTimer = loopShowTooltip(myChart, option, {
        interval: 3000, // 轮播间隔时间
        loopSeries: true, // 是否开启轮播循环
		dataLength:xData.length
      });
    }
  }
}
</script>
<style lang='scss' scoped>
.bar{
  height: 180px;
						width: 600px;
}
</style>