<template>
  <div>
    <div class="pie" id="pieRef"></div>
  </div>
</template>
<script>
import HighCharts from 'highcharts'
export default{
    props: {
    data: {
      type: Array,
      require:true 
    },
  },
  data(){
    return{
        myChart: null
    }
  },
  mounted () {
    this.initPie();
  },
  watch:{
			data:{
				handler(){
                    this.myChart = null;
					this.initPie();
			 	},
				deep:true
			}
		},
  methods: {
    initPie() {
        let _this = this;
        if(this.data.length==0){
         return;
       }
      
        let data = JSON.parse(JSON.stringify(this.data));
				let colors=['#51D7C8','#50C3D7','#DFE46D','#0351D7'];
				HighCharts.getOptions().colors = HighCharts.map(HighCharts.getOptions().colors, function (color) {
					return {
						linearGradient: { x1: 1, x2: 1, y1: 0, y2: 0},
						stops: [
							[0, '#003399'],
      				[1, '#ffffff'] // darken
						]
					};
				});
				let opData = [];
        data.forEach((item,index)=>{
          opData.push([item.name,+(item.value)])
        })
        let option = {
				 credits: {
					enabled: false //不显示LOGO
					},
				 chart: {
					type: 'pie',
					backgroundColor: "rgba(0, 0, 0, 0)",
					options3d: {
						enabled: true,
						alpha: 68,
						viewDistance:80,
						beta: 0, 
					}
				},
				title: {
					floating:true,
					text: '成品量',
					x:-80,
					y:92,
					style:{
						color:"#fff",
						fontWeight: 'bold',
						fontSize:'18px'
					}
				},
        tooltip:{
					enabled:true,
          backgroundColor:"rgba(255,255,255,0.3)",
          borderWidth:0,
          shadow:false,
          style:{
            color:"#fff",
            fontSize:'20px',
						// fontWeight:'
          },
          formatter:function(){
            // let percent = this.point.percentage.toFixed(0)
            return `${this.point.name}：${this.point.y}(吨)`;
          },
				},
				labels:{
					color:"#fff"
				},
                legend:{
					align: 'right',
					verticalAlign: 'top',
					layout:"vertical",
					x:30,
					y:10,
                    width:150,
					labelFormatter:function(){
						// return `${this.name}（${this.y}吨）`
						return `${this.name}`

					},
					navigation:{
						activeColor: '#fff',
						animation: true,
						arrowSize: 12,
						inactiveColor: '#333',
						style: {
							fontWeight: 'bold',
							color: '#fff',
							fontSize: '12px'
						}
					},
					itemMarginTop:10,
					itemMarginBottom:10,
					itemStyle:{
						color:"#fff",
						fontSize:16
					},
					itemHoverStyle: {
						color: "#fff", 
					},
				},
				plotOptions: {
					pie: {
						size:"150%",
						center:['47%','50%'],
						// allowPointSelect: true,
						innerSize: '85%',
						cursor: 'pointer',
						depth: 25,
						colors:['#47CFC1','#D6D963','#7E8BFF','#0351D7'],
						showInLegend:true,
						// states:{
						// 	inactive:{
						// 		opacity:1
						// 	}
						// },
						dataLabels: {
							enabled: true,
							distance:10,
							formatter:function(index){
                let percent = this.point.percentage.toFixed(0)
								return `${percent}%`;
							},
							style: { //样式配置
									textOutline: "none", //去掉文字白边
									color: "#fff",
									fontSize: 14,
							},
						}
					}
				},
				series: [{
					data: opData,
				}]
			 }
			 HighCharts.chart('pieRef', option,function(c) { // 图表初始化完毕后的会掉函数
			 	// console.log(c);
				// 	// 环形图圆心
				// 	var centerY = c.series[0].center[1],
				// 	titleHeight = parseInt(c.title.styles.fontSize);
				// 	// 动态设置标题位置
				// 	c.setTitle({
				// 		y:centerY + titleHeight/2
				// 	});
				})
        
    },
  },
}
</script>
<style lang='scss' scoped>
.pie{
    z-index:10;
  width: 490px;
  height: 180px;
}
</style>