<template>
	<div class="homeindex">
		<!--header-->
		<Topheader @changeMoney="changeMoney"></Topheader>
		<div class="flex flex_ju_sb home">
			<!--地图大背景-->

			<!-- <cen-map class="cesiumNcMaps"></cen-map> -->
			<!-- 左边 -->
			<div class="flex lefts">
				<div class="left animate__animated animate__fadeInLeft">
					<!--物联网设备异常-->
					<div class="c_white font_22 slide-titles flex flex_ju_l">
						<img src="../assets/img/homeAbnormal/icon4_1.png" />
						<div class="fm_pm slide-title">物联网设备异常</div>
					</div>
					<div class="LfContTop flex flex_ju_sb">
						<div class="lefttop">
							<empty v-if="deviceAbnormal.length == 0" />
							<LeftTop v-else :data="deviceAbnormal" />
						</div>
					</div>
					<!--设备异常情况变化-->
					<div class="c_white font_22 slide-titles flex flex_ju_l">
						<img src="../assets/img/homeAbnormal/icon4_2.png" />
						<div class="fm_pm slide-title">设备异常情况变化</div>
					</div>
					<div class="LfContCent flex flex_ju_sb">
						<div>
							<empty v-if="!isShowLbLine" />
							<leftMid v-else :data="deviceAbnormalityChane"></leftMid>
						</div>
					</div>
					<!--育种成效-->
					<div class="c_white font_22 slide-titles flex flex_ju_l">
						<img src="../assets/img/homeAbnormal/icon4_3.png" />
						<div class="fm_pm slide-title">安全警告占比</div>
					</div>
					<div class="LfContBottom">
						<!--放育种成效echarts图-->
						<div class="echarts">
							<empty v-if="securAlarms.length == 0" />
							<LeftBottom v-else :data="securAlarms" />
						</div>
					</div>
				</div>
				<!-- 左边资金投入数据，组件封装 -->
				<!-- <left-second></left-second> -->
			</div>
			<!-- 中间 -->
			<div class="center flex flex_ju_c" :class="isShowMoney ? 'center-show' : ''">
				<!-- <div class="CenCont flex flex_ju_sb"> -->
				<!--预警提示-->
				<!-- <div class="CenCLeft"> 
						<EarlyWarningList></EarlyWarningList>
					</div> -->
				<!--中间区域总览数据-->
				<div class="CenCCont flex flex_ju_sb">
					<div class="CenCCList">
						<p>97.5</p>
						<p class="flex flex_ju_l"><img src="../assets/img/home/icon1_10.png" />园区总面积(km²)</p>
					</div>
					<div class="CenCCList">
						<p>15</p>
						<p class="flex flex_ju_l"><img src="../assets/img/home/icon1_10.png" />入园企业(个)</p>
					</div>
					<div class="CenCCList">
						<p>50000</p>
						<p class="flex flex_ju_l"><img src="../assets/img/home/icon1_10.png" />年均繁育面积(亩)</p>
					</div>
					<div class="CenCCList">
						<p>5100</p>
						<p class="flex flex_ju_l"><img src="../assets/img/home/icon1_10.png" />带动就业人(人)</p>
					</div>
					<div class="CenCCList">
						<p>160</p>
						<p class="flex flex_ju_l"><img src="../assets/img/home/icon1_10.png" />专业技术人员(人)</p>
					</div>
				</div>
				<!--右侧数据-->
				<!-- <div class="CenCright"> -->
				<!--方位标-->
				<!-- <div class="CenCposition flex flex_ju_c color03FFEC">
							N<img src="../assets/img/home/fangwei.png" />
						</div> -->
				<!-- <div class="CenCbutlist">
							<div class="CenCbutlt" @click="tonengguan('home')">
								总览
							</div>
							<div class="CenCbutlt" @click="tonengguan('HomeEnergy')">
								能管中心
							</div>
							<div class="CenCbutlt" @click="tonengguan('HomeEquip')">
								设备资产
							</div>
							<div class="CenCbutlt CenCbutlAc">
								异常运营
							</div>
						</div>
						
						
						
						
						
					</div> -->

				<!-- </div> -->
			</div>
			<!-- 右边 -->
			<div class="flex rights">
				<!-- 右中实时数据 -->
				<div style="width: 990px"></div>
				<div class="right animate__animated animate__fadeInRight">
					<!--大豆种业-->
					<div class="c_white font_22 slide-titles flex flex_ju_l">
						<img src="../assets/img/homeAbnormal/icon4_4.png" />
						<div class="fm_pm slide-title">灾害类型占比</div>
					</div>
					<div class="RiContTop">
						<div class="Rileft">
							<empty v-if="disasterType.length == 0" />
							<rightTop v-else :data="disasterType" />
						</div>
					</div>
					<!--大豆销售网络-->
					<div class="c_white font_22 slide-titles flex flex_ju_l">
						<img src="../assets/img/homeAbnormal/icon4_5.png" />
						<div class="fm_pm slide-title">灾害情况统计</div>
					</div>
					<div class="Ribottom flex flex_ju_sb">
						<!--灾害情况统计-->
						<div class="ritbotleft">
							<empty v-if="!disasterStateList || !disasterStateList.xAxis || disasterStateList.xAxis.length === 0" />
							<rightMid2 v-else :data="disasterStateList" />
						</div>
					</div>
					<!--种子价格-->
					<div class="c_white font_22 slide-titles flex flex_ju_l">
						<img src="../assets/img/homeAbnormal/icon4_6.png" />
						<div class="fm_pm slide-title">异常事件统计</div>
					</div>
					<div class="Ritable">
						<div class="table_th flex flex_ju_sb">
							<div>类型</div>
							<div>来源</div>
							<div>异常情况</div>
							<div>时间</div>
						</div>
						<div class="table_tdcont">
							<empty v-if="anomalEventList.length === 0" />
							<div class="scroll-list" v-else>
								<vue-seamless-scroll class="seamless-warp" :class-option="classOption" :data="anomalEventList">
									<div class="table_td flex flex_ju_sb" v-for="(item, index) in anomalEventList" :key="index">
										<div>
											<a href="javascript:void(0);" :title="item.typeName | isempty">{{ item.typeName | isempty }}</a>
										</div>
										<div>
											<a href="javascript:void(0);" :title="item.source | isempty">{{ item.source | isempty }}</a>
										</div>
										<div>
											<a href="javascript:void(0);" :title="item.abnormaliti | isempty">{{
												item.abnormaliti | isempty
											}}</a>
										</div>
										<div>
											<a href="javascript:void(0);" :title="item.occurredTime | isempty">{{
												item.occurredTime | isempty
											}}</a>
										</div>
									</div>
								</vue-seamless-scroll>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--底部-->
		<!-- <Botfooter @totabclick="tabclick" :tabindex="tabindex" :tabindexsf="tabindexsf" @totabclicksf="tabclicksf">
		</Botfooter> -->
	</div>
</template>

<script>
import Topheader from '../components/header.vue'
import Botfooter from '../components/footers.vue'
import LeftTop from './AbnormalComponent/leftTop.vue'
import LeftBottom from './AbnormalComponent/LeftBottom.vue'
import rightTop from './AbnormalComponent/rightTop.vue'
import leftMid from './AbnormalComponent/leftMid.vue'
import rightMid2 from './AbnormalComponent/rightMid2.vue'
import Empty from './empty.vue'
import EarlyWarningList from '../components/EarlyWarningList.vue'
import { regionDataPlus } from 'element-china-area-data'
import api from '@/api/api'
import LeftSecond from './HomeComponent/LeftSecond.vue'
import RightSecond from './HomeComponent/RightSecond.vue'
export default {
	mixins: [],
	data() {
		return {
			classOption: {
				step: 0.7, // 数值越大速度滚动越快
				// limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
				hoverStop: true, // 是否开启鼠标悬停stop
				direction: 1, // 0向下 1向上 2向左 3向右
				// openWatch: true, // 开启数据实时监控刷新dom
				singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
				singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
				waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
			},
			securAlarms: [], // 安全警告占比
			curPage: 1,
			limit: 10,
			deviceAbnormal: [], // 物联网设备异常
			deviceAbnormalityChane: {}, // 设备异常情况变化
			isShowLbLine: true,
			anomalEventList: [], // 异常事件统计
			disasterType: [], // 灾害类型占比
			disasterStateList: {}, // 灾害情况统计
			cityname1: '',
			cityname2: '',
			cityname3: '',
			cityname1list: regionDataPlus,
			cityname2list: [],
			cityname3list: [],

			tabindexsf: 0,
			tabindex: 0,
			options: regionDataPlus,
			selectedOptions: ['370000', '370800', '370829'],
			datatime: '',
			isShowMoney: false, // 是否显示上面的金额数据
		}
	},
	components: {
		Topheader,
		LeftTop,
		LeftBottom,
		rightTop,
		Botfooter,
		leftMid,
		Empty,
		rightMid2,
		EarlyWarningList,
		LeftSecond,
		RightSecond,
	},
	mounted() {
		let year = new Date().getFullYear()
		this.getSecurityAlarm()
		this.getAnomalEventList({ page: this.curPage, limit: this.limit })
		this.getDeviceAbnormal()
		this.getDeviceAbnormalChane(year)
		this.getDisasterType()
		this.getDisState(year)
	},
	filters: {
		isempty(value) {
			if (!value) {
				return '暂无'
			} else {
				return value
			}
		},
	},
	methods: {
		// 上面的金额是否显示
		changeMoney() {
			this.isShowMoney = !this.isShowMoney
		},
		getSecurityAlarm() {
			this.$http.post(api.securityAlarm).then((res) => {
				console.log('安全警告占比', res.data.data)
				let { data } = res.data
				this.securAlarms = data
			})
		},
		getDeviceAbnormal() {
			this.$http.post(api.deviceAbnormal).then((res) => {
				console.log('物联网设备异常', res.data.data)
				let { data } = res.data
				this.deviceAbnormal = data
			})
		},
		getDeviceAbnormalChane(year) {
			this.$http.post(api.deviceAbnormalityChane, { year }).then((res) => {
				console.log('设备异常情况变化', res.data.data)
				if (res.data.code == 0) {
					let { data } = res.data
					if (!data || !data.xAxis || data.xAxis.length == 0) {
						this.isShowLbLine = false
						this.deviceAbnormalityChane = {}
					} else {
						this.deviceAbnormalityChane = data

						this.isShowLbLine = true
					}
				} else {
					this.deviceAbnormalityChane = {}
					this.isShowLbLine = false
				}
			})
		},
		getAnomalEventList(data) {
			this.$http.post(api.anomalousEventList, data).then((res) => {
				console.log('异常事件统计', res.data.data)
				let { data } = res.data
				this.anomalEventList = data
			})
		},
		getDisasterType() {
			this.$http.post(api.proportionDisasterType).then((res) => {
				console.log('灾害类型占比', res.data.data)
				let { data } = res.data
				this.disasterType = data
			})
		},
		getDisState(year) {
			this.$http.post(api.disasterStatisti, { year }).then((res) => {
				console.log('灾害情况统计', res.data.data)
				let { data } = res.data
				this.disasterStateList = data
			})
		},
		//能管中心
		tonengguan(url) {
			this.$router.push({
				name: url,
			})
		},
		tabclick(e) {
			this.$router.push({
				name: e,
			})
		},
		tabclicksf(e) {
			this.tabindexsf = e
			console.log(e)
		},

		handleChange(value) {
			console.log(value)
			this.cityname2list = []
			this.cityname2 = ''
			this.cityname3list = []
			this.cityname3 = ''
			this.cityname1list.forEach((item, index) => {
				console.log(value == item.value)
				if (value == item.value) {
					this.cityname2list = this.cityname1list[index].children
					console.log(this.cityname2list)
				}
			})
		},
		handleChange2(value) {
			console.log(value)
			this.cityname3list = []
			this.cityname3 = ''
			this.cityname2list.forEach((item, index) => {
				console.log(value == item.value)
				if (value == item.value) {
					this.cityname3list = this.cityname2list[index].children
					console.log(this.cityname3list)
				}
			})
		},
		handleChange3(value) {
			console.log(value)
			/* this.cityname2='';
			 
				this.cityname3=''; */
		},
	},
}
</script>
<style lang="less" scoped>
.home {
	height: 1080px;

	background: url('../assets/img/bgs.png') no-repeat;
	background-size: 100% 100%;

	overflow: hidden;
	margin-top: -144px;
	position: relative;

	.slide-titles {
		width: 805px;
		background: url('../assets/img/bg_title.png') no-repeat;
		background-size: 100% 100%;

		.slide-title {
			height: 66px;
			line-height: 66px;
			font-size: 22px;
			font-family: Source Han Sans CN;
			font-weight: bold;
			color: #ffffff;
		}

		img {
			width: 50px;
			height: 50px;
			margin-top: 8px;
		}
	}
	.lefts {
		width: 1840px;
	}
	.left {
		padding: 77px 0 0 50px;
		width: 888px;
		background: url('../assets/img/home/bg_left.png') no-repeat;
		background-size: 100% 100%;
		overflow: hidden;
		// position: fixed;
		// top: 63px;
		position: relative;
		z-index: 10;
		animation-duration: 2s;
		animation-timing-function: linear;
		.LfContTop {
			padding: 10px 15px;
			.lefttop {
				width: 780px;
				height: 220px;
				position: relative;
			}
		}

		.LfContCent {
			padding: 10px 0;
			div {
				width: 800px;
				height: 220px;
			}
		}
		.LfContBottom {
			margin: 10px 0 0 0;
			.LfContBotCont {
				width: 808px;
				height: 77px;
				background: rgba(6, 18, 66, 0.4);
				p {
					width: 804px;
					height: 45px;
					padding: 10px 20px;
					line-height: 30px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #ffffff;
					span {
						color: #2ebdff;
					}
				}
			}
			.echarts {
				height: 220px;
				width: 100%;
				margin-top: 10px;
			}
		}
	}

	// 中间
	.center {
		// background-color: rgba(255, 255, 255, 0.2);
		// height: 1080px;
		// width: 3300px;
		position: relative;
		z-index: 5;
		flex: 1;
		height: 136px;
		// top: 120px;
		top: -50px;
		opacity: 0;
		transition: all 1s;
		// .CenCont{
		// 	padding: 120px 70px;

		// background-color: rgba(255, 255, 255, 0.5);
		&.center-show {
			top: 120px;
			opacity: 1;
		}
		.CenCLeft {
			width: 600px;
			position: absolute;
			top: 0;
			left: 70px;
		}
		.CenCCont {
			height: 136px;
			width: 1122px;
			background: url('../assets/img/home/img1_9.png') no-repeat;
			background-size: 100% 100%;
			padding: 0 90px;
			margin-top: 20px;
			.CenCCList {
				padding: 22px 0;
				p:nth-child(1) {
					font-size: 50px;
					font-family: Bahnschrift;
					font-weight: 400;
					color: #ffffff;
					margin-left: 20px;
				}
				p:nth-child(2) {
					font-size: 20px;
					font-family: Adobe Heiti Std;
					font-weight: normal;
					color: #ffffff;
					line-height: 34px;
				}
			}
		}
		.CenCright {
			width: 590px;
			height: 136px;
			position: absolute;
			top: 148px;
			right: 70px;
			.CenCposition {
				height: 136px;
				line-height: 70px;
				width: 590px;
				img {
					height: 70px;
					width: 70px;
					margin-left: 4px;
				}
			}

			.CenCbutlist {
				// margin-top:190px ;
				width: 590px;
				.CenCbutlt {
					margin: 40px 0 0 275px;
					height: 85px;
					line-height: 85px;
					width: 220px;
					text-align: center;
					background: url('../assets/img/home/butbg.png') no-repeat;
					font-size: 24px;
					font-family: HuXiaoBo-NanShen;
					font-weight: 400;
					color: #ffffff;
					cursor: pointer;
				}
				.CenCbutlAc {
					background: url('../assets/img/home/butbg_ac.png') no-repeat;
					color: #0fe6ff;
				}
				.CenCbutlt:hover {
					transition: 0.3s;
					background: url('../assets/img/home/butbg_ac.png') no-repeat;
					color: #0fe6ff;
				}
			}
		}

		// }
	}

	// 右边
	.right {
		padding: 77px 50px 0 0;
		width: 858px;
		background: url('../assets/img/home/bg_right.png') no-repeat;
		background-size: 100% 100%;
		overflow: hidden;
		// position: fixed;
		// top: 63px;
		position: relative;
		z-index: 10;
		animation-duration: 2s;
		animation-timing-function: linear;
		.RiContTop {
			margin: 10px 0 0 0;
			.Rileft {
				height: 248px;
				width: 800px;
			}
		}
		.Ritable {
			margin-top: 10px;
			.table_th {
				div {
					background: url('../assets/img/home/taber_th.png') no-repeat;
					background-size: 100% 100%;
					font-size: 15px;
					font-weight: bold;
					color: #ffffff;
					line-height: 21px;
					padding: 5px 10px;
					text-align: center;
				}
				div:nth-child(1) {
					width: 180px;
				}
				div:nth-child(2) {
					width: 190px;
				}
				div:nth-child(3) {
					width: 260px;
				}
				div:nth-child(4) {
					width: 150px;
				}
			}
			.table_tdcont {
				height: 260px;
				// overflow-y: auto;
				overflow: hidden;
				position: relative;
				.table_td {
					margin: 10px 0;
					div {
						height: 27px;
						line-height: 27px;
						background: rgba(2, 31, 70, 0.5);
						font-size: 15px;
						font-weight: bold;
						color: #ffffff;
						text-align: center;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						a {
							cursor: initial;
						}
					}
					div:nth-child(1) {
						width: 180px;
					}
					div:nth-child(2) {
						width: 190px;
					}
					div:nth-child(3) {
						width: 260px;
					}
					div:nth-child(4) {
						width: 150px;
					}
				}
			}

			.table_tdcont > .scroll-list {
				// position: absolute;
				// top: 0;
				// left: 0;
				// width: 100%;
				// animation: scroll 10s linear infinite normal;
				&:hover {
					animation-play-state: paused;
					-webkit-animation-play-state: paused;
				}
			}

			.table_tdcont > .scroll-list > .table_td {
				/* 滚动的项目需要有具体的高度 */
				height: 30px;
			}
			@keyframes scroll {
				100% {
					/* 需要滚动内容的总高度 */
					top: -600px;
				}
			}

			.table_tdcont::-webkit-scrollbar {
				width: 5px;
			}

			.table_tdcont::-webkit-scrollbar-track {
				background-color: #10445b;

				-webkit-border-radius: 2em;
				-moz-border-radius: 2em;
				border-radius: 2em;
			}

			.table_tdcont::-webkit-scrollbar-thumb {
				background-color: #01756b;
				-webkit-border-radius: 2em;
				-moz-border-radius: 2em;
				border-radius: 2em;
			}
		}
		.Ribottom {
			position: relative;
			.ritbotleft {
				width: 800px;
				height: 210px;
			}
		}
	}
	//公共颜色
	.bcolor0090FF {
		color: #0090ff;
		background: #0090ff;
	}

	.bcolor2EBDFF {
		color: #2ebdff;
		background: #2ebdff;
	}

	.bcolor03FFEC {
		color: #03ffec;
		background: #03ffec;
	}

	.bcolorFDFF2E {
		color: #fdff2e;
		background: #fdff2e;
	}

	.color0090FF {
		color: #0090ff;
	}

	.color2EBDFF {
		color: #2ebdff;
	}

	.color03FFEC {
		color: #03ffec;
	}
	.colorFDFF2E {
		color: #fdff2e;
	}
}
</style>
