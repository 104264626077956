<template>
  <div>
    <div class="line2" ref="lineRef"></div>
  </div>
</template>

<script>
import { loopShowTooltip } from '../../util/loop-tooltip'

export default {
  props: {
    data: {
        type: Object,
        required:true
    },
  },
  data() {
    return {
      chartInstane: null,
      tooltipTimer:null
    };
  },
  mounted() {
    this.echartLine();
  },
  watch:{
			data:{
				handler(){
					this.echartLine();
			 	},
				deep:true
			}
		},
  destroyed () {
    this.tooltipTimer && this.tooltipTimer.clearLoop();
  },
  methods: {
    // 中间市场价格折线图
    echartLine() {
      const _this = this;
      if(!this.data.xAxis || !this.data.series || this.data.series.length <2){
        return
      }
      let myChart = this.$echarts.init(this.$refs.lineRef);
      let xLabel = _this.data.xAxis;
      let seriesData = this.data.series;
      let yData = seriesData[0].data;
      let zData = seriesData[1].data;
      let legendData = [];
      let colors = ["#00AEFF","#0FFE7A"];
      let optionData = [];
      let rgbs = ['11,190,232','58,255,221'];
      let unitArr = [
        {
          name:"作物产量",
          unit:"吨"
        },
        {
          name:"种植面积",
          unit:"亩"
        }
      ]
      seriesData.forEach((item,index)=>{
        legendData.push({
          name:item.name,
          itemStyle:{
            color:colors[index%2],
            borderWidth:0
          }
        })
        optionData.push({
          type: "line",
          name: item.name,
          data: item.data,
          symbol: "none", 
          smooth: true,
          lineStyle: {
            color: colors[index%2], // 线条颜色
            type: "solid",
          },
          areaStyle: {
            //区域填充样式
            normal: {
              color: new this.$echarts.graphic.LinearGradient(0,0,0,1,
              [
                {
                      offset: 0,
                      color: "rgba("+rgbs[index%2]+",0.6)",
                    },
                    {
                      offset: 0.5,
                      color: "rgba("+rgbs[index%2]+",0.3)",
                    },
                    {
                      offset: 1,
                      color: "rgba("+rgbs[index%2]+",0.1)",
                    },
              ],false)
            }
          }
        })
      })
   
      var option = {
        // color: ["#00FCFF", "#7888FF"],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgb(113,200,255, 0.28)",
          borderWidth: 0,
          textStyle: {
            fontSize: 15,
            color: "#AED6FF",
          },
          axisPointer:{
            lineStyle:{
              color:"#3371FC"
            }
          },
          position: (pos,param,dom, rect, size)=>{
            var obj = {top: 20};
            let halfContent = size.viewSize[0] / 2;
            if(pos[0] <= halfContent) {
              obj['left'] = pos[0]
            } else if(pos[0] >halfContent) {
              obj['right'] = size.viewSize[0] - pos[0]
            }
            return obj;
          },
          formatter(params) {
            let result = '';
            if(params.length > 0){
              params.forEach((item,index)=>{
                let name = item.seriesName
                let [unitObj] = unitArr.filter(unit=>unit.name == name)
                result +=`<div style="font-size:16px;margin-top:9px;color:#fff"">${name}：${item.value}${unitObj.unit}</div>`

              })
            }
            return result;
          },
        },
        legend:{
          // selectedMode:false,
          show:true,
          icon:"circle",
          right:"2%",
          top:"20%",
          orient:"vetical",
          itemGap:20,
          textStyle:{
            color:"#fff",
            fontSize:16
          },
          data:legendData
        },
        grid: {
          top: "5%",
          left: "4%",
          right: "20%",
          bottom: "16%",
        },
        xAxis: {
          type: "category",
          boundaryGap: false, //一定要给x轴设置,与前面紧挨着(紧挨边缘)
          axisLine: {
            //坐标轴轴线相关设置。数学上的x轴
            show: false,
          },
          axisLabel: {
            formatter:'{value}年',
            //坐标轴刻度标签的相关设置
            textStyle: {
              fontSize: 14,
              color: "#fff",
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
            inside: true,
            lineStyle: {
              color: "#192a44",
            },
          },
          data: xLabel,
        },
        yAxis: [
          {
            axisLabel: {
              show: false,
              color: "#fff",
              fontSize: 16,
            },
            splitLine:{
              show: true,
                        lineStyle:{
                            color:"rgba(0,90,198,.2)",
                            width:3 
                        }
            }
          },
          {
            axisLabel: {
              show: false,
              color: "#fff",
              fontSize: 16,
            },
            splitLine:{
              show: false,
                        lineStyle:{
                            color:"rgba(0,90,198,.2)",
                            width:3 
                        }
            },
          }
        ],
        series: optionData
      };
      myChart.setOption(option);
      this.tooltipTimer && this.tooltipTimer.clearLoop();
      this.tooltipTimer = 0;
      this.tooltipTimer = loopShowTooltip(myChart, option, {
        interval: 3000, // 轮播间隔时间
        loopSeries: true, // 是否开启轮播循环
			  dataLength:xLabel.length
      });
    },
  },
};
</script>
<style lang="less" scoped>
.line2 {
  height: 160px;
  width: 780px;
}
</style>
