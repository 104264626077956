<template>
	<div class="video-full">
		<div class="dbmbmc" @dblclick="toSmallScreen"></div>
		<div id="bigscreen"></div>
		<div class="flex flex_a_c flex_ju_sb full-control">
			<!-- 播放暂停 -->
			<div class="videobtns">
				<i v-if="screenIsPlay" class="el-icon-video-pause" @click="screenPlay"></i>
				<i v-else class="el-icon-video-play" @click="screenPlay"></i>
			</div>
			<!-- 缩小按钮 -->
			<span title="全屏" style="vertical-align: top" @click="toSmallScreen">
				<svg
					id="fullScreen"
					t="1578020167938"
					class="icon"
					viewBox="0 0 1024 1024"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					p-id="5035"
					width="16"
					height="16"
				>
					<path
						d="M348.373333 625.706667l-128 128-64 64v-128a33.28 33.28 0 0 0-35.413333-35.413334A33.493333 33.493333 0 0 0 85.333333 689.706667v213.333333A33.706667 33.706667 0 0 0 120.96 938.666667h213.333333a35.626667 35.626667 0 0 0 0-71.04h-128l64-64 128-128a35.2 35.2 0 0 0-49.92-49.92zM206.293333 156.373333h128a33.28 33.28 0 0 0 35.413334-35.413333A33.493333 33.493333 0 0 0 334.293333 85.333333H113.706667c-7.04 0-14.08 7.04-21.333334 14.293334a26.026667 26.026667 0 0 0-7.04 21.333333v213.333333a33.493333 33.493333 0 0 0 35.626667 35.413334 33.28 33.28 0 0 0 35.413333-35.413334v-128l192 192a35.2 35.2 0 0 0 49.92-49.92zM903.04 85.333333h-213.333333a33.493333 33.493333 0 0 0-35.413334 35.626667 33.28 33.28 0 0 0 35.413334 35.413333h128l-64 64-128 128a35.2 35.2 0 0 0 49.92 49.92l128-128 64-64v128a35.626667 35.626667 0 0 0 71.04 0v-213.333333A33.706667 33.706667 0 0 0 903.04 85.333333zM903.04 654.293333a33.28 33.28 0 0 0-35.413333 35.413334v128l-64-64-128-128a35.2 35.2 0 0 0-49.92 49.92l128 128 64 64h-128a35.626667 35.626667 0 0 0 0 71.04h213.333333A33.706667 33.706667 0 0 0 938.666667 903.04v-213.333333a33.493333 33.493333 0 0 0-35.626667-35.413334z"
						p-id="5036"
						fill="#ffffff"
					></path>
				</svg>
			</span>
		</div>
	</div>
</template>

<script>
import EZUIKit from 'ezuikit-js'
export default {
	data() {
		return {
			screenPlayer: null, // 全屏视频控件
			screenIsPlay: true, // 视频全屏模式下是否播放
		}
	},
	methods: {
		//全屏 视频播放和暂停
		screenPlay() {
			if (this.screenIsPlay) {
				this.screenPlayer.stop()
			} else {
				this.screenPlayer.play()
			}
			this.screenIsPlay = !this.screenIsPlay
		},
		// 地图点击缩小
		toSmallScreen() {
			this.screenPlayer.stop().then(() => {
				this.screenPlayer = null
			})
			this.$emit('smallScreen')
		},
		mianFullfun(equipId, tokens) {
			const that = this
			this.screenIsPlay = true
			let a = 'ezopen://open.ys7.com/' + equipId + '/1.live'
			// console.log(this.accessToken)
			// console.log(this.player)
			// 存在，直接替换
			if (this.screenPlayer) {
				this.screenPlayer.stop().then(() => {
					this.screenPlayer.play(a)
				})
				// 视频上代表播放的图标展示播放状态
				this.screenIsPlay = true
			} else {
				this.$nextTick(() => {
					this.screenPlayer = new EZUIKit.EZUIKitPlayer({
						id: 'bigscreen', // 视频容器ID
						accessToken: tokens,
						url: a,
						autoplay: true,
						template: 'simple',
						width: 5092,
						height: 1080,
						handleSuccess: function () {
							// 视频上代表播放的图标展示播放状态
							this.screenIsPlay = true
						},
						// 视频播放出错的回调
						handleError: function (res) {
							console.log(res)
							if (res && res.data) {
								that.player.stop()
								that.$message(res.data.msg, 'error')
							}
							that.screenIsPlay = false
						},
					})
				})
			}
		},
	},
}
</script>

<style lang="less" scoped>
// 视频全屏样式
.video-full {
	.dbmbmc {
		position: absolute;
		width: 5092px;
		height: 1080px;
		top: 0;
		left: 0;
		z-index: 250;
	}

	position: absolute;
	width: 5092px;
	height: 1080px;
	top: 0;
	left: -915px;
	z-index: 250;
	#bigscreen {
		width: 5092px;
		height: 1080px;
	}
	.full-control {
		position: absolute;
		width: 100%;
		height: 48px;
		bottom: 0;
		left: 0;
		background-color: #333;
		padding: 0 20px;
		z-index: 251;
	}
	.screen-closebtn {
		width: 40px;
		height: 40px;
		cursor: pointer;
	}
	.videobtns {
		cursor: pointer;
		color: #fff;
		font-size: 30px;
	}
}
</style>
