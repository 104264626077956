<template>
  <div>
    <div class="bar" ref="barRef"></div>
  </div>
</template>
<script>
export default{
  props: {
    data: {
      type: Object,
      required:true 
    },
  },
  mounted () {
    this.initBar();
  },
  watch:{
			data:{
				handler(){
					this.initBar();
			 	},
				deep:true
			}
		},
  methods: {
    initBar() {
      let myChart = this.$echarts.init(this.$refs.barRef);
      let seriesData1 = [];
      let seriesData2 = [];
      let seriesData3 = [];
      let colors = ["#2EBDFF","#03FFEC","#FDFF2E"];
      let symbols = [require('@/assets/img/home/img1_5.png'),require('@/assets/img/home/img1_6.png'),require('@/assets/img/home/img1_7.png')]
      let valueData = this.data.valueData || [];
      if(valueData.length == 0){
        return;
      }
      let max = valueData.reduce((a,b)=>b>a ? b : a)
      valueData.forEach((item,index)=>{
        let newIndex = index % colors.length;
        // let max = Math.max(valueData);
        seriesData1.push({
          value:item,
          itemStyle:{
            color:colors[newIndex]
          }
        })
        seriesData2.push(1.5*max)
        seriesData3.push({
          value:item,
          symbol:'image://'+symbols[newIndex]
        })
      })
      console.log(seriesData1);
      const option = {
        color:["#2EBDFF","#03FFEC","#FDFF2E"],
        grid: {
            top: '2%',
            left: '0%',
            right:"2%",
            bottom:"2%",
        },
        xAxis: {
            show: false,
            type: 'value'
        },
        yAxis: {
            type: 'category',
            inverse: true,
            axisLabel: {
                show: false,
                textStyle: {
                    color: '#444444'
                },
            },
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            data: this.data.category || []
        },
        series: [
            {
                name: '完成率',
                type: 'bar',
                zlevel: 1,
                cursor:'auto',
                silent:false,
                label: {
                    normal: {
                        show: true,
                        position: [0, '-24px'],
                        formatter:  '{x|{b}} {y|{c}万元}',
                        rich:{
                          x:{
                            color: '#fff',
                            fontWeight:800,
                            fontSize: 16
                          },
                          y:{
                            fontSize: 14,
                            fontWeight:800,
                            color:'inherit'
                          }
                        }
                    }
                },
                itemStyle: {
                    normal: {
                        barBorderRadius: 30,
                        // color: '#14d88e',
                    },
                },
                barWidth: 12,
                data: seriesData1,
            },
            {
                name: '背景',
                type: 'bar',
                cursor:'auto',
                barWidth: 12,
                barGap: '-100%',
                data: seriesData2,
                itemStyle: {
                    normal: {
                        color: 'rgba(0,144,255,.3)',
                        barBorderRadius: 30,
                    }
                },
            },
           {
              name: 'XXX',
              cursor:'auto',
              type: 'pictorialBar',
              symbolSize: [42, 43],
              symbolOffset: [20, 0],
              symbolPosition: 'end',
              z: 12,
              itemStyle: {
                  normal: {
                      color: '#14b1eb'
                  }
              },
              data: seriesData3
            }
        ]
      };
      myChart.setOption(option)
    }
  },
}
</script>
<style lang='scss' scoped>
.bar{
  width: 580px;
  height: 198px;
}
</style>