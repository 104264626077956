// 接口统一管理页面
const api = {
	// 园区总览-总览
	overView: '/apiy/jxSugariPublic/overview', // 产业园介绍 - 资金投入 - 大豆种业
	breedingResult: '/apiy/jxSugariPublic/breedingResult', // 育种成效
	seedPriceList: '/apiy/jxSugariPublic/seedPriceList', // 种子价格
	earlywarningList: '/apiy/jxSugariPublic/alertLogList', //预警日志

	// 第一屏改版
	getCultureInfo: '/apiy/apiV2/getCultureInfo', // 文化长廊列表
	getEquipInfoList: '/apiy/apiV2/getEquipInfoList', // 物联网设备数
	getCulturePictureList: '/apiy/apiV2/getCulturePictureList', // 获取文化长廊的图片数据
	getMajorProjectList: '/apiy/apiV2/getMajorProjectList', // 重点项目数据获取
	getFacListState: '/apiy/jxFacility/getFacListState', // 获取右侧视频监控列表

	// 园区总览-能管中心
	energyConsumption: '/apiy/jxSugariPublic/energyConsumption', // 核心区域能耗情况
	// energyConsumption: '/apiy/jxSugariPublic/parkConsumption', // 核心区域能耗情况
	parkConsumption: '/apiy/jxSugariPublic/parkConsumption', // 园区用水、电、气总耗
	debitNote: '/apiy/jxSugariPublic/debitNote', // 缴费通知
	debitNoteFindOne: '/apiy/jxSugariPublic/debitNoteFindOne', // 头部四个数字
	// 设备资产
	equipmentSituation: '/apiy/jxSugariPublic/equipmentSituation', // 物联网设备情况
	companyListAll: '/apiy/jxSugariPublic/companyListAll', // 企业设备占有情况 -- 企业列表
	equipmentAmountOne: '/apiy/jxSugariPublic/equipmentAmountOne', // 设备金额分布 固定资产和物联网设备扇形图
	equipmentAmountTwo: '/apiy/jxSugariPublic/equipmentAmountTwo', // 设备金额分布 固定资产扇形图
	equipmentUtilization: '/apiy/jxSugariPublic/equipmentUtilization', // 设备利用情况
	faultStatistic: '/apiy/jxSugariPublic/faultStatistic', // 设备状态统计 - 故障统计
	supervisorState: '/apiy/jxSugariPublic/supervisorState', // 设备状态统计 - 管理状态
	//  旧的  possessionEquipment: '/apiy/jxSugariPublic/possessionEquipment', // 企业设备占有情况 -- 设备名称和设备数量
	//新的
	possessionEquipment: '/apiy/jxSugariPublic/possessionEquipmentNew', // 企业设备占有情况 -- 设备名称和设备数量
	deviceAbnormality: '/apiy/jxSugariPublic/deviceAbnormality', // 物联网设备报警分析
	// 异常运营
	securityAlarm: '/apiy/jxSugariPublic/securityAlarm', // 安全警告占比
	deviceAbnormal: '/apiy/jxSugariPublic/deviceAbnormal', // 物联网设备异常
	deviceAbnormalityChane: '/apiy/jxSugariPublic/deviceAbnormalityChane', // 设备异常情况变化
	anomalousEventList: '/apiy/jxSugariPublic/anomalousEventList', // 异常事件统计
	proportionDisasterType: '/apiy/jxSugariPublic/proportionDisasterType', // 灾害类型占比
	disasterStatisti: '/apiy/jxSugariPublic/disasterStatisti', // 灾害情况统计
	// 监测监管-总览
	seedReproductionStatisti: '/apiy/jxSugariPublic/seedReproductionStatisti', // 繁种情况分析
	deviceListAll: '/apiy/jxSugariPublic/deviceListAll', // 物联网设备
	monitoringEarlyWarningEcharts: '/apiy/jxSugariPublic/monitoringEarlyWarningEcharts', // 监测预警 左边图
	monitoringEarlyWarning: '/apiy/jxSugariPublic/monitoringEarlyWarning', // 监测预警 右边图
	productionAnalys: '/apiy/jxSugariPublic/productionAnalys', // 产量分析
	yieldEvaluation: '/apiy/jxSugariPublic/yieldEvaluation', // 产量评价占比
	// 生产
	companyInfoList: '/apiy/jxSugariPublic/companyInfoList', // 企业介绍 - 种业生产 - 实时监控
	acreageCropYield: '/apiy/jxSugariPublic/acreageCropYield', // 种植面积和作物产量 条形图
	productionProcessingOne: '/apiy/jxSugariPublic/productionProcessingOne', // 生产加工 柱状图
	productionProcessingTwo: '/apiy/jxSugariPublic/productionProcessingTwo', // 生产加工 扇形图
	saleFeedbackOne: '/apiy/jxSugariPublic/saleFeedbackOne', // 销售与反馈 柱状图
	saleFeedbackTwo: '/apiy/jxSugariPublic/saleFeedbackTwo', // 销售与反馈 地图
	// 智脑中心
	aggregateAnaly: '/apiy/jxSugariPublic/aggregateAnaly', // 总体分析，头部四个数据
	coreAreaHydropower: '/apiy/jxSugariPublic/coreAreaHydropower', // 核心区域水电统计
	variousVarieti: '/apiy/jxSugariPublic/variousVarieti', // 各品种面积
	yieldVariousVarietie: '/apiy/jxSugariPublic/yieldVariousVarietie', // 各品种产量
	annualChange: '/apiy/jxSugariPublic/annualChange', // 年度变化情况
	wisdomIndustry: '/apiy/jxSugariPublic/wisdomIndustry', // 智慧产业

	// 地图
	mapCqMonitor: '/apiy/jxSugariPublic/pestControlList', // 虫情监测--虫防治
	jxPersonnelByType: '/apiy/jxSugariPublic/jxPersonnelByType', // 核心区域负责人弹窗数据获取

	// 设备信息
	jxFacilityListAll: '/apiy/jxSugariPublic/jxFacilityListAll', // 设备信息
	// 物联网-弹窗接口
	latestWeatherData: '/api/v1/envMonitorStation/latestWeatherData', // 气象/土壤列表数据
	qxSelectItem: '/api/v1/envMonitorStation/items', // 气象/墒情 折线图左侧的下拉选
	envirTimesDatas: '/api/v1/envMonitorStation/envirTimesDatas', // 气象/墒情折线图
	getLastTwoDayImage: '/api/insect/se/getDeviceCodeSomeTimeImage', // 虫情图片
	insectStatistics: '/api/insect/se/insectStatistics', // 虫情统计-折线图
	mqSave: '/apiy/jxProcessSuggestions/save', // 苗情过程新增
	mqBottom: '/apiy/jxProcessSuggestions/selectList', // 苗情下面的过程建议
	sqQuarterWarm: '/api/jx/ds/yearQuarterStatistic', //墒情季度报警
	sqQuarterWarm2: '/apiy/jxThresholdWarn/getThWarmByQuarter', //墒情季度报警次数接口,

	// 视频
	getToken: '/apiy/ptz/getAccessToken', // 获取萤石云的token

	// 智脑中心
	getItemsByHour: '/api/v1/weatherStation/getItemsByHour', //
	// 水肥和云台控制
	// 水肥
	setEquipment: '/apiy/equipmentInfo/setEquipment', // 自动模式添加
	updateEquipment: '/apiy/equipmentInfo/updateEquipment', // 自动模式修改
	editEquipment: '/apiy/equipmentInfo/editEquipment', // 自动模式回显
	clearEquipSet: '/apiy/equipmentInfo/clearEquipSet', // 自动模式清除

	// 云台控制
	capture: '/apiy/ptz/capture', // 设备抓图
	ytStart: '/apiy/ptz/start', // 开始
	ytStop: '/apiy/ptz/stop', // 停止

	// 云台控制  2022/04/01 调整为hls拉流m3u8播放方式
	getVideoUrl: '/videos/hnyfkj/api/v1/getVideoUrl', // 获取视频流
	ytControl: '/videos/hnyfkj/api/v1/ptzControlling', // 云台控制
	ytCapture: '/videos/hnyfkj/api/v1/getCapture', // 云台设备抓图

	// 智脑中心  2022/01/26改版调整
	businessData: '/apiy/jxSugariPublic/businessData', // 业务数据采集条数
	businessMonitoring: '/apiy/jxSugariPublic/businessMonitoring', // 业务系统监测
	// 数据采集
	allData: '/api/jx/ds/ds6', // 气象，虫情、墒情一起返回本月的数据值
	monthAllData: '/api/jx/ds/ds62', // 气象，虫情、墒情一起返回总条数
	cqTypes: '/api/jx/ds/dict3', // 虫情类型集
	// 处理记录
	qxRecord: '/api/jx/ds/ds51', // 气象处理记录
	sqRecord: '/api/jx/ds/ds52', // 墒情处理记录
	cqRecord: '/api/jx/ds/ds8', // 虫情处理记录
	dataAcquisition: '/apiy/jxSugariPublic/dataAcquisition', // 业务数据记录

	// 本月情况
	qxMonth: '/api/jx/ds/ds11', // 气象
	sqMonth: '/api/jx/ds/ds12', // 墒情
	cqMonth: '/api/jx/ds/ds13', // 虫情
	// 历史同期
	qxHis: '/api/jx/ds/ds31', // 气象
	sqHis: '/api/jx/ds/ds32', // 墒情
	cqHis: '/api/jx/ds/ds33', // 虫情
	// 下拉选
	qxItems: '/api/jx/ds/dict1', // 气象采集项下拉选
	sqItems: '/api/jx/ds/dict2', // 墒情采集项下拉选
	// 监测监管新版
	soybeanSowing: '/apiy/jxSugariPublic/soybeanSowingPage', // 全国大豆播种面积
	jcCompanyListAll: '/apiy/jxSugariPublic/companyListAll', //企业列表
	soybeanNetworkSale: '/apiy/jxSugariPublic/soybeanNetworkSale', //大豆网络销量地图
	salesStatistics: '/apiy/jxSugariPublic/salesStatistics', // 各品种销量与产量统计
}
export default api
