<template>
  <div class="page">
    <!-- <div ref="barRef" class="bar"></div> -->
    <img :src="rtl" alt="">
    <div :class="['item','item'+(index+1)]" v-for="(item,index) in data" :key="index">{{item.name}}<p><span>{{item.value}}</span>次</p></div>
  </div>
</template>
<script>
export default{
    props: {
        data: {
            type: Array,
            required:true 
        },
    },
  data(){
    return{
        rtl:""
    }
  },
  mounted () {
      this.rtl = require("../../assets/img/homeMon/rtl.png")
    // this.initBar();
  },
  methods: {
    initBar() {
        if(!this.data || this.data.length == 0){
            return;
        }
      let myChart = this.$echarts.init(this.$refs.barRef)
      let data = JSON.parse(JSON.stringify(this.data))
      let yList = [];
      let xData = [];
      data.forEach(item=>{
          xData.push(item.name)
          yList.push(item.value)
      })
      console.log(xData);
      let color = ['#CC1CAA', '#8D67FF', '#00FFFF', '#48DE13', '#FFC516', '#DC3E14', '#8E16F8'];
      let colors = []
      for(let i = 0;i<4;i++){
          colors.push({
              type: 'linear',
              x: 0,
              x2: 1,
              y: 0,
              y2: 0,
              colorStops: [
              {
                  offset: 0,
                  color: '#73fcff' // 最左边
              }, {
                  offset: 0.5,
                  color: '#86eef1' // 左边的右边 颜色
              }, {
                  offset: 0.5,
                  color: '#5ad6d9' // 右边的左边 颜色
              }, {
                  offset: 1,
                  color: '#3dc8ca'
              }]
          })
      }
      const option = {
          /**区域位置*/
            grid: {
                left: '10%',
                right: '10%',
                top: '15%',
                bottom: '10%',
              },
          //X轴
          xAxis: {
            show:true,
            axisLine:{
                show:false
            },
            axisTick:{
                show:false
            },
            axisLabel:{
                color:"#fff",
            },
            data: xData,
            type: 'category',
          },
          yAxis: {
            show: true,
            axisLabel:{
                color:"#fff"
            },
            axisLine:{
                show:false
            },
            splitLine:{
                show:false
            }
          },
          series: [
              {
                  type: 'bar',
                  barWidth: 20,
                  itemStyle: {
                      normal: {
                          color: function(params) {
                              return colors[params.dataIndex % 7];
                          }
                      }
                  },
                  label: {
                      show: true,
                      position: ['50%',-25],
                      color: '#ffffff',
                      fontSize: 14,
                      formatter:'{c}次',
                      fontStyle: 'bold',
                      align: 'center',
                  },
                  data: yList
              },

              {
                  z: 3,
                  type: 'pictorialBar',
                  symbolPosition: 'end',
                  data: yList,
                  symbol: 'diamond',
                  symbolOffset: [0, '-50%'],
                  symbolSize: [20, 20 * 0.5],
                  itemStyle: {
                      normal: {
                          borderWidth: 0,
                          color: function(params) {
                              return colors[params.dataIndex % 7].colorStops[0].color;
                          },

                      }
                  },
              },
          ],
      }
      myChart.setOption(option)
    }
  },
}
</script>
<style lang='scss' scoped>
.page{
    width: 350px;
    height: 220px;
    position: relative;
    .item{
        position: absolute;
        width:50px;
        color:#fff;
        font-size: 16px;
        text-align: center;
        line-height: 20px;
        span{
            color: #01EAFF;
            font-size: 20px;
        }
    }
    .item1{
        left:42px;
        bottom:22px
    }
    .item2{
        left:108px;
        bottom:5px
    }
    .item3{
        right:118px;
        bottom:15px
    }
    .item4{
        width: 80px;
        right:35px;
        bottom:12px
    }
}
.bar{
    width: 350px;
    height: 220px;
}
</style>