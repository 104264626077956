<template>
  <div>
    <div class="pie" id="pieRef"></div>
  </div>
</template>
<script>
import HighCharts from 'highcharts'
export default{
    props: {
    data: {
      type: Array,
      require:true 
    },
  },
  data(){
    return{  myChart: null}
  },
  mounted () {
    this.initPie();
  },
  watch:{
			data:{
				handler(){
          this.myChart = null;
					this.initPie();
			 	},
				deep:true
			}
		},
  methods: {
    initPie() {
      let _this = this;
      if(this.data.length==0){
         return;
       }
       let data = JSON.parse(JSON.stringify(this.data));
        let opData = [];
        data.forEach((item,index)=>{
          opData.push([item.name,+(item.value)])
        })
        
        let option = {
				 credits: {
					enabled: false //不显示LOGO
					},
				 chart: {
					type: 'pie',
					backgroundColor: "rgba(0, 0, 0, 0)",
					options3d: {
						enabled: true,
						alpha: 68,
						viewDistance:80,
						beta: 0, 
					}
				},
				title: {
					text: ''
				},
        tooltip:{
					enabled:true,
          backgroundColor:"rgba(255,255,255,0.3)",
          borderWidth:0,
          shadow:false,
          style:{
            color:"#fff",
            fontSize:16
          },
          formatter:function(){
            let percent = this.point.percentage.toFixed(0)
            return `${this.point.name}：${percent}%`;
          },
				},
				legend:{
					align: 'right',
					verticalAlign: 'top',
					floating: true,
					x:70,
					y:70,
					width:380,
					navigation:{
						activeColor: '#fff',
						animation: true,
						arrowSize: 12,
						inactiveColor: '#333',
						style: {
							fontWeight: 'bold',
							color: '#fff',
							fontSize: '18px'
						}
					},
					itemWidth:180,
					itemMarginTop:20,
					itemMarginBottom:20,
					itemStyle:{
						color:"#fff",
						fontSize:16
					},
					itemHoverStyle: {
						color: "#fff", 
					},
				},
				labels:{
					color:"#fff"
				},
				plotOptions: {
					pie: {
            size:"120%",
						center:['30%','50%'],
						// allowPointSelect: true,
						innerSize: '80%',
						cursor: 'pointer',
						depth: 25,
						colors:['#47CFC1','#D6D963','#7E8BFF','#0351D7'],
						showInLegend:true,
						// states:{
						// 	inactive:{
						// 		opacity:1
						// 	}
						// },
						dataLabels: {
							enabled: true,
							formatter:function(index){
                let percent = this.point.percentage.toFixed(0)
								return `${percent}%`;
							},
							style: { //样式配置
									textOutline: "none", //去掉文字白边
									color: "#fff",
									fontSize: 14,
							},
						}
					}
				},
				series: [{
					data: opData,
				}]
			 }
			 HighCharts.chart('pieRef', option)
    },
  },
    
}
</script>
<style lang='scss' scoped>
.pie{
  z-index:10;
  width: 800px;
  height: 250px;
}
</style>