<template>
	<div class="homeMon">
		<!--header-->
		<Topheader></Topheader>
		<div class="flex flex_ju_sb home" :class="tabindexsf == 0 ? 'homebg' : 'homebgsf'">
			<!--地图大背景-->
			<!-- <cen-map class="cesiumNcMaps"></cen-map> -->
			<!-- 左边 -->
			<div class="left">
				<!--繁种情况分析-->
				<div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/homeMon/icon5_1.png" />
					<div class="fm_pm slide-title">繁种情况分析</div>
				</div>
				<div class="LfContTop flex flex_ju_sb">
					<!--图表占位-->
					<div class="eachimg">
						<empty v-if="seedProductList.length == 0" />
						<leftTop v-else :data="seedProductList" />
					</div>
					<div class="LfContTopRig">
						<empty v-if="seedErWeiArr.length == 0" />
						<el-carousel class="swipelist" arrow="always">
							<el-carousel-item v-for="(seedArr, index) in seedErWeiArr" :key="index">
								<div class="flex swipelist">
									<div
										:class="['swipelistbg', item.name || item.value ? '' : 'noBackground']"
										v-for="(item, idx) in seedArr"
										:key="idx"
									>
										<p v-if="item.name">{{ item.name }}<span class="normal1">种植面积</span></p>
										<p v-if="item.value" class="flex flex_ju_sb flex_a_end">
											<span class="normal2">{{ item.value }}<span class="normal3">亩</span></span>
											<span class="normal4"
												>同比去年
												<span v-if="item.basis < 0" class="normal6">↓{{ Math.abs(item.basis) }}%</span>
												<span v-else class="normal5">↑{{ item.basis }}%</span>
											</span>
										</p>
									</div>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
				</div>
				<!--物联网设备-->
				<div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/homeMon/icon5_2.png" />
					<div class="fm_pm slide-title">物联网设备</div>
				</div>
				<div class="LfContCent flex flex_ju_sb">
					<empty v-if="deviceList.length == 0" />
					<div v-else v-for="device in deviceList" :key="device.name">
						<div class="LCCleft">
							<p>
								总数：<span>{{ device.total }}</span>
							</p>
							<p>
								在线：<span>{{ device.online }}</span>
							</p>
							<p>
								离线：<span>{{ device.offline }}</span>
							</p>
						</div>
						<div class="LCClefttit">{{ device.name }}</div>
					</div>
				</div>
				<!--产量分析与评价-->
				<div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/homeMon/icon5_3.png" />
					<div class="fm_pm slide-title">产量分析与评价</div>
				</div>
				<div class="LfContBottom flex flex_ju_sb">
					<div class="LfContBotleft">
						<div class="LfContBotlefttit"></div>
						<!--图表占位-->
						<div class="LfContBeachimg">
							<empty v-if="!productionAnalys || !productionAnalys.xAxis || productionAnalys.xAxis.length == 0" />
							<lefBotLeft v-else :data="productionAnalys" />
						</div>
					</div>
					<div class="LfContBotleft">
						<div class="LfContBotlefttit2"></div>
						<!--图表占位-->
						<div class="LfContBeachimg">
							<empty v-if="yieldEvaluation.length == 0" />
							<lefBotRight v-else :data="yieldEvaluation" />
						</div>
					</div>
				</div>
			</div>
			<!-- 中间 -->
			<div class="center">
				<div class="CenCont flex flex_ju_sb">
					<!--预警提示-->
					<div class="CenCLeft">
						<EarlyWarningList></EarlyWarningList>
					</div>

					<!--右侧数据-->
					<div class="CenCright">
						<!--方位标-->
						<!-- <div class="CenCposition flex flex_ju_c color03FFEC">
							N<img src="../assets/img/home/fangwei.png" />
						</div> -->
						<div class="CenCbutlist">
							<div class="CenCbutlt CenCbutlAc">总览</div>
							<div class="CenCbutlt" @click="tonengguan('HomeProduction')">生产</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 右边 -->
			<div class="right">
				<!--监测预警-->
				<div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/homeMon/icon5_4.png" />
					<div class="fm_pm slide-title">监测预警</div>
				</div>
				<div class="RiContTop flex flex_ju_sb">
					<!--图表占位-->
					<div class="RiContTopleft">
						<empty v-if="earlyWarningOne.length == 0" />
						<rightTopLeft v-else :data="earlyWarningOne" />
					</div>

					<div class="RiContTopright">
						<div class="flex search-box absde">
							<el-date-picker
								style="width: 218px; height: 30px"
								v-model="datatime1"
								type="date"
								value-format="yyyy-MM-dd"
								placeholder="选择日期"
							>
							</el-date-picker>
							<img class="soimg" @click="searchMonitor" src="../assets/img/homeMon/so.png" />
						</div>
						<!--图表占位-->
						<div class="zhanwei">
							<empty v-if="!earlyWarningTwo || !earlyWarningTwo.xAxis || earlyWarningTwo.xAxis.length == 0" />
							<rightTopRight v-else :data="earlyWarningTwo" />
						</div>
					</div>
				</div>
				<!--大豆销售网络-->
				<div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/homeMon/icon5_5.png" />
					<div class="fm_pm slide-title">大豆销售网络</div>
				</div>
				<div class="Ribottom flex flex_ju_sb">
					<!--辐射地图-->
					<div class="ritbotleft">
						<rigBotLeft />
					</div>
					<!--饼状图-->
					<div class="ritbotright">
						<rigBotRight />
					</div>
					<div class="ritbotcont-left">占黄淮海流域大豆种子交易量</div>
					<div class="ritbotcont">良种畅销安徽、河南、 江苏及长江中下游11 个省份</div>
				</div>
				<!--大豆价格信息-->
				<div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/homeMon/icon5_6.png" />
					<div class="fm_pm slide-title">大豆价格信息</div>
				</div>
				<div class="Ricity flex flex_ju_sb">
					<div class="flex flex_ju_sb absde1">
						<div class="RiCtTit">区域</div>
						<el-select v-model="cityname1" @change="handleChange" placeholder="请选择" style="width: 120px">
							<el-option v-for="item in cityname1list" :key="item.value" :label="item.label" :value="item.label">
							</el-option>
						</el-select>
						<el-select v-model="cityname2" @change="handleChange2" placeholder="请选择" style="width: 120px">
							<el-option v-for="item in cityname2list" :key="item.value" :label="item.label" :value="item.label">
							</el-option>
						</el-select>
						<el-select v-model="cityname3" @change="handleChange3" placeholder="请选择" style="width: 120px">
							<el-option v-for="item in cityname3list" :key="item.value" :label="item.label" :value="item.label">
							</el-option>
						</el-select>
					</div>
					<div class="flex flex_ju_sb absde">
						<div class="RiCtTit">日期</div>
						<el-date-picker
							style="width: 280px"
							v-model="datatime"
							type="daterange"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
						>
						</el-date-picker>
					</div>
					<el-button class="RictBtn" @click="getPriceList">查询</el-button>
				</div>
				<div class="Ritable absde">
					<div class="table_th flex flex_ju_sb">
						<div>观察点</div>
						<div>品种</div>
						<div>价格(元)</div>
						<div>规格</div>
						<div>公司</div>
						<div>上报时间</div>
					</div>
					<div class="table_tdcont">
						<empty v-if="priceList.length === 0" />

						<div v-else>
							<vue-seamless-scroll class="seamless-warp" :class-option="classOption" :data="priceList">
								<div class="table_td flex flex_ju_sb" v-for="(item, index) in priceList" :key="index">
									<div>
										<a href="javascript:void(0);" :title="item.area | isempty">{{ item.area | isempty }}</a>
									</div>
									<div>
										<a href="javascript:void(0);" :title="item.type | isempty">{{ item.type | isempty }}</a>
									</div>
									<div>
										<a href="javascript:void(0);" :title="item.price | isempty">{{ item.price | isempty }}</a>
									</div>
									<div>
										<a href="javascript:void(0);" :title="item.specification | isempty">{{
											item.specification | isempty
										}}</a>
									</div>
									<div>
										<a href="javascript:void(0);" :title="item.companyName | isempty">{{
											item.companyName | isempty
										}}</a>
									</div>
									<div>
										<a href="javascript:void(0);" :title="item.insertTime | isempty">{{ item.insertTime | isempty }}</a>
									</div>
								</div>
							</vue-seamless-scroll>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--底部-->
		<!-- <Botfooter @totabclick="tabclick" :tabindex="tabindex" :tabindexsf="tabindexsf" @totabclicksf="tabclicksf">
		</Botfooter> -->
	</div>
</template>

<script>
import Topheader from '../components/header.vue'
import leftTop from './MonComponent/leftTop.vue'
import lefBotLeft from './MonComponent/lefBotLeft.vue'
import lefBotRight from './MonComponent/lefBotRight.vue'
import rightTopLeft from './MonComponent/rightTopLeft'
import rightTopRight from './MonComponent/rightTopRight'
import rigBotLeft from './MonComponent/rigBotLeft.vue'
import rigBotRight from './MonComponent/rigBotRight.vue'
import Botfooter from '../components/footers.vue'
import Empty from './empty.vue'
import EarlyWarningList from '../components/EarlyWarningList.vue'
import { regionDataPlus } from 'element-china-area-data'
import api from '@/api/api'
export default {
	mixins: [],
	data() {
		return {
			classOption: {
				step: 0.7, // 数值越大速度滚动越快
				// limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
				hoverStop: true, // 是否开启鼠标悬停stop
				direction: 1, // 0向下 1向上 2向左 3向右
				// openWatch: true, // 开启数据实时监控刷新dom
				singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
				singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
				waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
			},
			seedProductList: [], // 繁种情况分析
			seedErWeiArr: [], // 轮播数据
			deviceList: [], // 物联网设备
			earlyWarningOne: [], // 监测预警 左边图
			earlyWarningTwo: {}, // 监测预警 右边图
			productionAnalys: {}, // 产量分析
			yieldEvaluation: [], // 产量评价占比
			priceList: [],
			cityname1: '全部',
			cityname2: '全部',
			cityname3: '全部',
			cityname1list: regionDataPlus,
			cityname2list: [],
			cityname3list: [],
			tabindexsf: 0,
			tabindex: 1,
			options: regionDataPlus,
			selectedOptions: ['全部', '全部', '全部'],
			datatime: '',
			page: 1,
			limit: 10,
			datatime1: '',
			datalist: [
				{
					name: '山东济宁市嘉祥县',
					type: '祥丰4号',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/8/13',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆2号',
					mpney: 72,
					size: '6公斤/袋',
					company: '嘉祥祥丰种业有限公司',
					time: '2021/8/13',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '苏豆12',
					mpney: 120,
					size: '10公斤/袋',
					company: '东海县农业科学研究所',
					time: '2021/8/13',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆43',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/8/13',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆43',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/8/9',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '苏豆12',
					mpney: 120,
					size: '10公斤/袋',
					company: '东海县农业科学研究所',
					time: '2021/8/9',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆2号',
					mpney: 72,
					size: '6公斤/袋',
					company: '嘉祥祥丰种业有限公司',
					time: '2021/8/9',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '祥丰4号',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/8/9',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '祥丰4号',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/8/2',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆2号',
					mpney: 72,
					size: '6公斤/袋',
					company: '嘉祥祥丰种业有限公司',
					time: '2021/8/2',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '苏豆12',
					mpney: 120,
					size: '10公斤/袋',
					company: '东海县农业科学研究所',
					time: '2021/8/2',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆43',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/8/2',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆43',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/7/30',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '苏豆12',
					mpney: 120,
					size: '10公斤/袋',
					company: '东海县农业科学研究所',
					time: '2021/7/30',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆2号',
					mpney: 72,
					size: '6公斤/袋',
					company: '嘉祥祥丰种业有限公司',
					time: '2021/7/30',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '祥丰4号',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/7/30',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '祥丰4号',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/7/27',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆2号',
					mpney: 72,
					size: '6公斤/袋',
					company: '嘉祥祥丰种业有限公司',
					time: '2021/7/27',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '苏豆12',
					mpney: 120,
					size: '10公斤/袋',
					company: '东海县农业科学研究所',
					time: '2021/7/27',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆43',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/7/27',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆43',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/7/20',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '苏豆12',
					mpney: 120,
					size: '10公斤/袋',
					company: '东海县农业科学研究所',
					time: '2021/7/20',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆2号',
					mpney: 72,
					size: '6公斤/袋',
					company: '嘉祥祥丰种业有限公司',
					time: '2021/7/20',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '祥丰4号',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/7/20',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '祥丰4号',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/7/16',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆2号',
					mpney: 72,
					size: '6公斤/袋',
					company: '嘉祥祥丰种业有限公司',
					time: '2021/7/16',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '苏豆12',
					mpney: 120,
					size: '10公斤/袋',
					company: '东海县农业科学研究所',
					time: '2021/7/16',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆43',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/7/16',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆43',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/7/12',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '苏豆12',
					mpney: 120,
					size: '10公斤/袋',
					company: '东海县农业科学研究所',
					time: '2021/7/12',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆2号',
					mpney: 72,
					size: '6公斤/袋',
					company: '嘉祥祥丰种业有限公司',
					time: '2021/7/12',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '祥丰4号',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/7/12',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆43',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/7/9',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '苏豆12',
					mpney: 120,
					size: '10公斤/袋',
					company: '东海县农业科学研究所',
					time: '2021/7/9',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆2号',
					mpney: 72,
					size: '6公斤/袋',
					company: '嘉祥祥丰种业有限公司',
					time: '2021/7/9',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '祥丰4号',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/7/9',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '祥丰4号',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/6/20',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆2号',
					mpney: 72,
					size: '6公斤/袋',
					company: '嘉祥祥丰种业有限公司',
					time: '2021/6/20',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '苏豆12',
					mpney: 120,
					size: '10公斤/袋',
					company: '东海县农业科学研究所',
					time: '2021/6/20',
				},
				{
					name: '山东济宁市嘉祥县',
					type: '嘉豆43',
					mpney: 120,
					size: '10公斤/袋',
					company: '山东祥丰种业有限责任公司',
					time: '2021/6/20',
				},
			],
		}
	},
	components: {
		Topheader,
		leftTop,
		lefBotLeft,
		lefBotRight,
		rightTopLeft,
		rightTopRight,
		rigBotLeft,
		rigBotRight,
		Botfooter,
		Empty,
		EarlyWarningList,
	},
	created() {
		let now = new Date()
		let year = now.getFullYear()
		let month = now.getMonth() + 1
		let day = now.getDate()
		this.datatime1 = year + '-' + (month > 9 ? month : '0' + month) + '-' + (day > 9 ? day : '0' + day)
		// console.log(this.provinceAndCityData)
		let month2 = new Date().getMonth()
		let lastMonth = 0
		let lastYear = year
		if (month2 === 0) {
			lastMonth = 11
			lastYear = year - 1
		}
		this.datatime = [new Date(lastYear, lastMonth, day), new Date(year, month, day)]
		this.cityname1list = this.options
		this.cityname2list = []
		this.cityname3list = []

		this.getSeedProductList(year)
		this.getDeviceList()
		this.getEarlyWarningOne(year)
		this.getEarlyWarningTwo()
		this.getProductAnalys()
		this.getYieldEvaluation()
		this.getPriceList()
	},
	filters: {
		isempty(value) {
			if (!value) {
				return '暂无'
			} else {
				return value
			}
		},
	},
	methods: {
		getSeedProductList(year) {
			this.$http.post(api.seedReproductionStatisti, { year }).then((res) => {
				console.log('繁种情况分析', res.data.data)
				let { data, count } = res.data
				let arrNum = Math.ceil(count / 6)
				let newArr = []
				data.forEach((item) => {
					item.value = +item.value
					item.basis = parseInt(item.basis)
				})
				for (let i = 0; i < arrNum; i++) {
					let arr1 = []
					if (i < arrNum - 1) {
						arr1 = data.slice(i * 6, (i + 1) * 6)
					} else {
						arr1 = data.slice(i * 6, data.length)
						if (arr1.length == 1) {
							arr1.push({})
						}
					}
					newArr.push(arr1)
				}
				this.seedErWeiArr = newArr
				this.seedProductList = data
			})
		},
		getDeviceList() {
			this.$http.post(api.deviceListAll).then((res) => {
				console.log('物联网设备', res.data.data)
				let { data } = res.data
				this.deviceList = data
			})
		},
		getEarlyWarningOne(year) {
			this.$http.post(api.monitoringEarlyWarningEcharts, { year }).then((res) => {
				console.log('监测预警 左边图', res.data.data)
				let { data } = res.data
				this.earlyWarningOne = data
			})
		},
		getEarlyWarningTwo() {
			this.$http.post(api.monitoringEarlyWarning, { param: this.datatime1 }).then((res) => {
				console.log('监测预警 右边图', res.data.data)
				let { data } = res.data
				data.date = this.datatime1
				this.earlyWarningTwo = data
			})
		},
		getProductAnalys() {
			this.$http.post(api.productionAnalys).then((res) => {
				console.log('产量分析', res.data.data)
				let { data } = res.data
				this.productionAnalys = data
			})
		},
		getYieldEvaluation() {
			this.$http.post(api.yieldEvaluation).then((res) => {
				console.log('产量评价占比', res.data.data)
				let { data } = res.data
				this.yieldEvaluation = data
			})
		},
		getCityName(name) {
			if (name.indexOf('省') != -1) {
				name = name.substring(0, name.length - 1)
			} else if (name.indexOf('自治区') != -1) {
				name = name.substring(0, name.length - 3)
			} else if (name.indexOf('特别行政区') != -1) {
				name = name.substring(0, name.length - 5)
			} else if (name === '全部') {
				name = ''
			}
			return name
		},
		getPriceList() {
			let timeArr = this.getTimeFromDataTime(this.datatime)
			if (this.cityname2 == '全部') {
				this.cityname2 = ''
			}
			if (this.cityname3 == '全部') {
				this.cityname3 = ''
			}
			let area = this.getCityName(this.cityname1) + this.cityname2 + this.cityname3
			let data = {
				page: this.page,
				limit: this.limit,
				stratTime: timeArr[0],
				endTime: timeArr[1],
				area,
				//this.cityname1+this.cityname2+this.cityname3
			}
			this.$http.post(api.seedPriceList, data).then((res) => {
				console.log('种子价格', res.data.data)
				let { data } = res.data

				this.priceList = data
			})
		},
		getTimeFromDataTime(dataTime) {
			let timeArr = []
			if (dataTime && dataTime.length > 0) {
				var start = new Date(dataTime[dataTime.length - 2])
				var end = new Date(dataTime[dataTime.length - 1])
				start =
					start.getFullYear() +
					'-' +
					(start.getMonth() < 9 ? '0' + (start.getMonth() + 1) : start.getMonth() + 1) +
					'-' +
					(start.getDate() < 9 ? '0' + start.getDate() : start.getDate())
				// start.getHours() +
				// ":" +
				// start.getMinutes() +
				// ":" +
				// start.getSeconds();
				end =
					end.getFullYear() +
					'-' +
					(end.getMonth() < 9 ? '0' + (end.getMonth() + 1) : end.getMonth() + 1) +
					'-' +
					(end.getDate() < 9 ? '0' + end.getDate() : end.getDate())
				// " " +
				// end.getHours() +
				// ":" +
				// end.getMinutes() +
				// ":" +
				// end.getSeconds();
				timeArr = [start, end]
			} else {
				timeArr = ['', '']
			}
			return timeArr
		},
		tonengguan(url) {
			this.$router.push({
				name: url,
			})
		},
		searchMonitor() {
			this.getEarlyWarningTwo()
		},
		tabclick(e) {
			this.$router.push({
				name: e,
			})
		},
		tabclicksf(e) {
			this.tabindexsf = e
			console.log(e)
		},
		handleChange(value) {
			console.log(value)
		},

		handleChange(name) {
			this.cityname2list = []
			this.cityname2 = ''
			this.cityname3list = []
			this.cityname3 = ''
			this.cityname1list.forEach((item, index) => {
				// console.log(value==item.value)
				if (name == item.label) {
					this.cityname2list = this.cityname1list[index].children
					// console.log(this.cityname2list)
				}
			})
		},
		handleChange2(name) {
			this.cityname3list = []
			this.cityname3 = ''
			this.cityname2list.forEach((item, index) => {
				if (name == item.label) {
					this.cityname3list = this.cityname2list[index].children
				}
			})
		},
		handleChange3(value) {
			console.log(value)
			/* this.cityname2='';
				   
				  	this.cityname3=''; */
		},
	},
}
</script>
<style lang="less" scoped>
.homebg {
	background: url('../assets/img/bg2.png') no-repeat;
}
.homebgsf {
	background: url('../assets/img/sbg2.png') no-repeat;
}
.home {
	height: 1080px;
	background-size: 100% 100%;

	overflow: hidden;
	margin-top: -144px;
	position: relative;

	.slide-titles {
		width: 805px;
		background: url('../assets/img/bg_title.png') no-repeat;
		background-size: 100% 100%;

		.slide-title {
			height: 66px;
			line-height: 66px;
			font-size: 22px;
			font-family: Source Han Sans CN;
			font-weight: bold;
			color: #ffffff;
		}

		img {
			width: 50px;
			height: 50px;
			margin-top: 8px;
		}
	}

	.left {
		padding: 77px 0 0 50px;
		width: 858px;
		background: url('../assets/img/home/bg_left.png') no-repeat;
		background-size: 100% 100%;
		overflow: hidden;
		// position: fixed;
		// top: 63px;
		position: relative;
		z-index: 10;
		.LfContTop {
			padding-top: 10px;
			.eachimg {
				height: 280px;
				width: 300px;
				margin-bottom: 10px;
				// background: rgba(197, 188, 61, 0.2);
			}
			.LfContTopRig {
				height: 286px;
				width: 500px;
				overflow: hidden;
				.swipelist {
					width: 500px;
					height: 286px;
					overflow: hidden;
					text-align: center;
					flex-wrap: wrap;
					justify-content: center;
					& /deep/ .el-carousel__arrow {
						height: 26px;
						width: 26px;
					}
					//  & /deep/ .el-carousel__indicators--horizontal{
					// 	 bottom:-1px !important;
					//  }
					.swipelistbg {
						height: 76px;
						width: 200px;
						background: url('../assets/img/homeMon/tb.png') no-repeat;
						background-size: 100% 100%;
						margin: 0 5px 20px 5px;
						padding: 5px 10px 0 20px;
						text-align: left;
						&.noBackground {
							background: none;
						}
						p {
							font-size: 16px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #42d0ff;
							padding: 5px;
						}
						.normal1 {
							font-size: 12px;
							margin-left: 10px;
						}
						.normal2 {
							font-size: 24px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #feffff;
						}
						.normal3 {
							font-size: 15px;
							color: #feffff;
						}
						.normal4 {
							font-size: 12px;
							color: #42d0ff;
							padding-bottom: 2px;
						}
						.normal5,
						.normal6 {
							font-size: 12px;
							color: #06fd52;
							padding-left: 5px;
						}
						.normal6 {
							color: #fd2306;
						}
						// p:nth-child(1){
						// 	font-size: 16px;
						// 	font-family: Microsoft YaHei;
						// 	font-weight: 400;
						// 	color: #42D0FF;
						// 	span{
						// 		font-size: 12px;
						// 	}
						// }
						// p:nth-child(2){
						// 	font-size: 24px;
						// 	font-family: Microsoft YaHei;
						// 	font-weight: 400;
						// 	color: #FEFFFF;
						// 	span:nth-child(1){
						// 		font-size: 12px;
						// 		color: #42D0FF;
						// 		padding-left:5px ;
						// 	}
						// 	span:nth-child(2){
						// 		font-size: 12px;
						// 		color: #06FD52;
						// 		padding-left:5px ;
						// 	}
						// }
					}
				}
			}

			/* div {

					padding-left: 26px;
					font-size: 17px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #FFFFFF;
					line-height: 30px;
					padding: 10px;
				} */
		}

		.LfContCent {
			padding: 10px 0;
			height: 196px;
			.LCCleft {
				width: 140px;
				height: 140px;
				background: url('../assets/img/homeMon/wulianwbiank.png') no-repeat;
				background-size: 100% 100%;
				text-align: center;
				padding: 33px 0;

				p {
					padding: 5px 0;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
				}

				p:nth-child(1) {
					span {
						color: #fe651a;
					}
				}
			}
			.LCClefttit {
				text-align: center;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
				padding: 10px 0;
			}
		}
		.LfContBottom {
			margin: 10px 0 0 0;
			.LfContBotleft {
				margin: 10px 0 0 0;

				.LfContBotlefttit {
					width: 390px;
					height: 31px;
					background: url('../assets/img/homeMon/clfx.png') no-repeat;
					background-size: 100% 100%;
				}
				.LfContBotlefttit2 {
					width: 390px;
					height: 31px;
					background: url('../assets/img/homeMon/clpgzb.png') no-repeat;
					background-size: 100% 100%;
				}

				.LfContBeachimg {
					height: 242px;
					width: 390px;
					// background: rgba(197, 188, 61, 0.2);
				}
			}
		}
	}

	// 中间
	.center {
		// background-color: rgba(255, 255, 255, 0.2);
		// height: 1080px;
		// width: 3300px;
		position: relative;
		z-index: 4;
		width: 3300px;
		height: 136px;
		top: 120px;
		.CenCont {
			// padding: 120px 70px;

			// background-color: rgba(255, 255, 255, 0.5);
			.CenCLeft {
				width: 600px;
				position: absolute;
				top: 0;
				left: 70px;
			}
			.CenCright {
				width: 500px;
				height: 136px;
				// padding-top: 250px;
				position: absolute;
				top: 148px;
				right: 70px;
				.CenCposition {
					height: 136px;
					line-height: 70px;
					width: 500px;
					img {
						height: 70px;
						width: 70px;
						margin-left: 4px;
					}
				}

				.CenCbutlist {
					margin-top: 90px;
					width: 590px;
					.CenCbutlt {
						margin: 40px 0 0 190px;
						height: 85px;
						line-height: 85px;
						width: 220px;
						text-align: center;
						background: url('../assets/img/home/butbg.png') no-repeat;
						font-size: 24px;
						font-family: HuXiaoBo-NanShen;
						font-weight: 400;
						color: #ffffff;
						cursor: pointer;
					}
					.CenCbutlAc {
						background: url('../assets/img/home/butbg_ac.png') no-repeat;
						color: #0fe6ff;
					}
					.CenCbutlt:hover {
						transition: 0.3s;
						background: url('../assets/img/home/butbg_ac.png') no-repeat;
						color: #0fe6ff;
					}
				}
			}
		}
	}

	// 右边
	.right {
		padding: 77px 50px 0 0;
		width: 858px;
		background: url('../assets/img/home/bg_right.png') no-repeat;
		background-size: 100% 100%;
		overflow: hidden;
		// position: fixed;
		position: relative;
		// top: 63px;
		z-index: 10;
		.RiContTop {
			margin: 10px 0;
			.RiContTopleft {
				width: 350px;
				height: 220px;
				// background: rgba(197, 188, 61, 0.2);
			}
			.RiContTopright {
				width: 430px;
				.search-box {
					justify-content: flex-end;
				}
				& /deep/ .el-picker-panel {
					background: rgba(2, 31, 70, 0.9) !important;
					color: #ffffff !important;
					box-shadow: inset 0px 0px 16px 4px rgb(19, 147, 166) !important;
					border: none !important;
				}
				.soimg {
					height: 25px;
					width: 25px;
					margin: 10px 15px;
					cursor: pointer;
				}
				.zhanwei {
					width: 430px;
					height: 175px;
					// background: rgba(197, 188, 61, 0.2);
				}
			}
		}
		.Ricity {
			padding: 10px 0;
			.RiCtTit {
				height: 47px;
				line-height: 47px;
				font-weight: bold;
				font-size: 16px;
				color: #24c4f5;
				padding-right: 10px;
			}
			.RictBtn {
				background: transparent;
				border: none;
				height: 47px !important;
				line-height: 28px !important;
				background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIcAAAAvCAYAAADJqNmJAAAAAXNSR0IArs4c6QAAA/tJREFUeF7tnN1OG0cUx39nsbFjQkM+GpK8QdSryuUB8gjpRZ+hb5C8QhQpUapWCle8RB4CJXARqVetxBVpCISP1AQbG++JZnZNCcUJNllmVpyxEBbSeM7+/789Z8zOjGDNFBiigJgypsAwBQwOY2OoAgaHwWFwGAOjK2CZY3TNLkyPL8PxWm/S42fgLkrdq5KgKF2EA5Q+Qnph1CrbhaaIfykVoJr/ZFchbCO8pM4LfpDuSZc2HI5FnWWCh0Aj/7AO8BFwv/eBHtAHtGyaXah4xd/OGRxKzfspTOV/c5D8RZOniPzvJh8Ox5L+ivJjLuQHhH8RdklpIx6Qns8errlcYi0uBVzGcC0lIWECmES55OFQLgNXDm/8hAWasnj8Ar4Ex7O8lDgwtoEMENgj9XC40tI3MOJi4rNosqKSlRXJ4ciyxnfADHDNA5OwSFMWTg/HK53PM8QGsIWyTUKLlF0SOn7ekRgcEaMxmFu4BFDlgEnETxGmfNYQrgLXEW6S8idz8vuocGwirAPvgR2fPZSPCO0cjqysWItXgay8VOkx6ctIkpcU5RrC9wizpKyMBseyPkf5B+UdCRukbPmy0mfXw1Fjn46fkGbNFRhrcSgwkc83XDTufUqFCjWUBinTCDMM4IDb3ueRMseS/ga8AdZQNkjY8pnDwaHs0aNLPYeja2DEQcWxKFwhqSL0qFClRkqD/iEcWUlxcKRsjgbHsj5BWc3hWEdyOKq0aNFmli6r9NkwMKIEYxCUKxxQYYa6zxwDOODGIRzupm/KH6efcyzpY2AV4e2RzLFDj10maNOiyz2xOUfUZLh/M6iwTIUOdSo0qB7JHCmzCLd9RfhJnp8ejmV9hPIGYY2UdV9WhA+HcKywzy/y35wjdpEuanwDOC5R44AGPaZJuIpynTQvKw6OOZkfHQ6OZI4BHH32WKVrcJSAuJPgyCakg6+xd3zm+CZwdGgBbYOjBGC4EA2OkhgVIkyDI4TqJRnT4CiJUSHCNDhCqF6SMQ2OkhgVIkyDI4TqJRnT4CiJUSHCNDhCqF6SMQ2OkhgVIkyDI4TqJRnT4CiJUSHCPFc43CN7e/AWwubxxiwUDntkP54psfQq5JG9LfaJxd6zxVHQYh9bJng2W+LoXdAyQVtgHIe940dR4AJj25owvi1hexa+NSHb8WabmsLafPbRC9nU9Pl2yE2UHdsOeXavzv0TBnB80+2QS3p8I/UO4teP2kbqc3d4zAEL20htRzCM6Ugk3Qo9guGl3kJ4YIe3RGL2uGF8/fCWv2nyZLTDW1ww7nSfCvft2KdxnQncr7BjnwJflw0fXgE7TTC8B9FGYHBEa034wAyO8B5EG4HBEa014QP7BC94EV1wV5wwAAAAAElFTkSuQmCC)
					no-repeat !important;
				background-size: 100% 100% !important;
				border: 0px solid #24c4f5 !important;
				color: #24c4f5 !important;
				font-weight: bold;
				font-size: 16px;
				color: #24c4f5;
			}
		}
		.Ritable {
			.table_th {
				div {
					background: url('../assets/img/home/taber_th.png') no-repeat;
					background-size: 100% 100%;
					font-size: 15px;
					font-weight: bold;
					color: #ffffff;
					line-height: 21px;
					padding: 5px 10px;

					text-align: center;
				}
				div:nth-child(1) {
					width: 150px;
				}
				div:nth-child(2) {
					width: 117px;
				}
				div:nth-child(3) {
					width: 100px;
				}
				div:nth-child(4) {
					width: 100px;
				}
				div:nth-child(5) {
					width: 180px;
				}
				div:nth-child(6) {
					width: 130px;
				}
			}
			.table_tdcont {
				height: 190px;
				// overflow-y: auto;
				overflow: hidden;
				position: relative;
				.table_td {
					margin: 10px 0;
					div {
						height: 27px;
						line-height: 27px;
						background: rgba(2, 31, 70, 0.5);
						font-size: 15px;
						font-weight: bold;
						color: #ffffff;
						text-align: center;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						a {
							cursor: initial;
						}
					}
					div:nth-child(1) {
						width: 150px;
					}
					div:nth-child(2) {
						width: 117px;
					}
					div:nth-child(3) {
						width: 100px;
					}
					div:nth-child(4) {
						width: 100px;
					}
					div:nth-child(5) {
						width: 180px;
					}
					div:nth-child(6) {
						width: 130px;
					}
				}
			}

			.table_tdcont > .scroll-list {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				animation: scroll 10s linear infinite normal;
				&:hover {
					animation-play-state: paused;
					-webkit-animation-play-state: paused;
				}
			}

			.table_tdcont > .scroll-list > .table_td {
				/* 滚动的项目需要有具体的高度 */
				height: 30px;
			}
			@keyframes scroll {
				100% {
					/* 需要滚动内容的总高度 */
					top: -600px;
				}
			}

			.table_tdcont::-webkit-scrollbar {
				width: 5px;
			}

			.table_tdcont::-webkit-scrollbar-track {
				background-color: #10445b;

				-webkit-border-radius: 2em;
				-moz-border-radius: 2em;
				border-radius: 2em;
			}

			.table_tdcont::-webkit-scrollbar-thumb {
				background-color: #01756b;
				-webkit-border-radius: 2em;
				-moz-border-radius: 2em;
				border-radius: 2em;
			}
		}
		.Ribottom {
			position: relative;
			.ritbotleft {
				width: 310px;
				height: 250px;
				// background: rgba(0, 255, 255, 0.4);
			}
			.ritbotright {
				width: 500px;
				height: 250px;
				// background: rgba(96, 243, 25, 0.2);
			}
			.ritbotcont-left {
				width: 260px;
				height: 96px;
				position: absolute;
				top: 0;
				right: 240px;
				font-size: 16px;
				font-weight: bold;
				color: #fff;
				padding: 10px 20px;
				line-height: 24px;
			}
			.ritbotcont {
				width: 213px;
				height: 96px;
				position: absolute;
				top: 0;
				right: 0px;
				background: url('../assets/img/home/yuan.png') no-repeat;
				background-size: 100% 100%;
				font-size: 17px;
				font-weight: bold;
				color: #80d9fe;
				padding: 10px 20px;
				line-height: 24px;
			}
		}
	}
	//公共颜色
	.bcolor0090FF {
		color: #0090ff;
		background: #0090ff;
	}

	.bcolor2EBDFF {
		color: #2ebdff;
		background: #2ebdff;
	}

	.bcolor03FFEC {
		color: #03ffec;
		background: #03ffec;
	}

	.bcolorFDFF2E {
		color: #fdff2e;
		background: #fdff2e;
	}

	.color0090FF {
		color: #0090ff;
	}

	.color2EBDFF {
		color: #2ebdff;
	}

	.color03FFEC {
		color: #03ffec;
	}
	.colorFDFF2E {
		color: #fdff2e;
	}
}
</style>
