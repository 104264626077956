<template>
  <div class="piePage">
    <div ref="pieRef" class="pie"></div>
    <div class="pie-title">
      <p class="title-count">{{curData.value}}<span>亩</span></p>
      <p class="title-name">{{curData.name}}面积</p>
    </div>
  </div>
</template>
<script>
import {loopShowTooltip} from '../../util/loop-tooltip'
export default{
  props: {
    data: {
      type: Array,
      required: true 
    },
  },
  data(){
    return{
      index:'',
      curData:{},
      tooltipTimer:null,
      myChart: null,
    }
  },
  mounted(){
    this.initPie()
  },
  destroyed () {
    this.tooltipTimer && this.tooltipTimer.clearLoop();
  },
  watch:{
			data:{
				handler(){
          this.myChart = null;
					this.initPie();
			 	},
				deep:true
			}
		},
  methods: {
    initPie(){
      let _this = this;
			if(this.data.length===0){
         return;
       }
       this.myChart = this.$echarts.getInstanceByDom(this.$refs.pieRef)
       if(this.myChart == null){
         	this.myChart = this.$echarts.init(this.$refs.pieRef);
       }
      // let myChart = this.$echarts.init(this.$refs.pieRef)
      let propData = JSON.parse(JSON.stringify(this.data))
      let optionData = [];
       let legendData = [];
      propData.forEach(item=>{
         item.value = parseFloat(item.value).toFixed(0)
         if(item.value == 0){
        return
         }
          legendData.push(item.name)
        optionData.push({
          value:item.value,
          name:item.name
        },
        {
          value: 8,
          name: '',
          itemStyle: {
              normal: {
                color: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgba(0, 0, 0, 0)',
                borderWidth: 0
              }
          }
        })
      })
      this.curData = propData[0]
      const option={
        tooltip: {
          show: true,
          backgroundColor:"transparent",
          borderWidth:0,
          textStyle:{
            color:"#fff"
          },
          formatter:param=>{
            _this.curData = {
              name: param.name,
              value:param.value
            }
            return ''
            // return `${param.name}：${param.value}亩`
          }
        },
        legend:{
          show:true,
          type:"scroll",
          // selectedMode:false,
          right: "1%",
          top:"17%",
          pageTextStyle:{
            color:"#fff"
          },
          orient: "vertical",
          icon:"circle",
          itemGap:20,
          formatter:name=>{
            if(name.length >5){
              name = name.slice(0,5) + '...'
            }
            return name
          },
          tooltip:{
            show:true,
            backgroundColor:"rgba(0,0,0,0.3)",
            textStyle:{
              color:"#fff"
            },
            formatter:param=>{
              [_this.curData] = propData.filter(item=>item.name == param.name)
            return `${param.name}`
            }
          },
          textStyle:{
            color:"#fff",
          },
          data:legendData,
        },
        color:['#FF973A ','#3EB7CC','#FF5150','#06AAFC','#0C90FA','#29C0FF'],
        series: [
          {
            type: "pie",
            radius: ["60%", "70%"],
            center: ['34%', '50%'],
            label:{
              show:false
            },
            labelLine:{
              show:false
            },
            data: optionData,
          },
          {
            type: 'pie',
            radius: ['45%', '50%'],
            center:['34%', '50%'],
            label: {
                show: false
            },
            data:this.Pie()
          }
        ]
      }
      this.myChart.setOption(option)
      this.tooltipTimer && this.tooltipTimer.clearLoop();
      this.tooltipTimer = 0;
      this.tooltipTimer = loopShowTooltip(this.myChart, option, {
        interval: 3000, // 轮播间隔时间
        loopSeries: true // 是否开启轮播循环
      });
    },
    Pie(){
      let dataArr = [];
      for (var i = 0; i < 150; i++) {
          if (i % 2 === 0) {
              dataArr.push({
                  name: '',
                  value: 5,
                  itemStyle: {
                      normal: {
                          color: "rgba(92,184,255,.3)",
                          borderWidth: 0,
                          borderColor: "rgba(0,0,0,0)"
                      }
                  }
              })
          } else {
              dataArr.push({
                  name: '',
                  value: 5,
                  itemStyle: {
                      normal: {
                          color: "rgba(0,0,0,0)",
                          borderWidth: 0,
                          borderColor: "rgba(0,0,0,0)"
                      }
                  }
              })
          }
      }
      return dataArr
    }
  }
}
</script>
<style lang='scss' scoped>
.piePage{
  width: 100%;
  height: 100%;
  position: relative;
  .pie{
    height: 280px;
    width: 300px;
  }
  .pie-title{
    position: absolute;
    top:38%;
    left:11%;
    width: 140px;
    text-align: center;
    color:#fff;
    .title-count{
      font-size: 36px;
      font-weight: 400;
      span{
        color: #C4C9CA;
        font-size: 16px
      }
    }
    .title-name{
      color:#C4C9CA;
      font-size:16px;
    }
  }
}

</style>