<template>
  <div>
    <div class="wave" ref="waveRef"></div>
  </div>
</template>
<script>
import 'echarts-liquidfill'
export default{
  props:{
    colors:{
      type:Array,
      default: ()=>["#078FF7","#2BDAFF"]
    }
  },
  mounted () {
    this.initChart();
  },
  methods: {
    initChart() {
      const chart = this.$echarts.init(this.$refs.waveRef)
      chart.setOption({
        series: [{
          type: 'liquidFill',
          z:2,
          radius: '80%',
          center: ['50%', '50%'],
          label:{
            show:false
          },
          color: [{
            type: 'linear',
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            colorStops: [{
                offset: 0,
                color: [this.colors[0]], // 0% 处的颜色
            }, {
                offset: 1,
                color: [this.colors[1]], // 100% 处的颜色
            }],
            global: false // 缺省为 false
          }],
          backgroundStyle: {
              borderWidth: 1,
              color: 'rgb(255,255,255,0.1)'
          },
          outline: {
              show: false
          },
          data:[0.60,0.55]
        },
        {
            "type": "pie",
            z:2,
            "center": ["50%", "50%"],
            "radius": ["95%", "100%"],
            "hoverAnimation": false,
            "data": [
                {
                  "name": "",
                  "value": 500,
                  itemStyle: {
                      color: '#5886f0'
                  },  
                },
            ]
        },
        {
            "type": "pie",
            z:3,
             cursor:'auto',
            "center": ["50%", "50%"],
            "radius": ["0%", "100%"],
            "hoverAnimation": false,
            "data": [
                {
                  "name": "",
                  "value": 500,
                  itemStyle: {
                      color: '#5886f0',
                      opacity:0
                  },  
                },
            ]
        }
        ]
      })
    }
  },
}
</script>
<style lang='scss' scoped>
.wave{
  height: 72px;
	width: 72px;
}
</style>