<template>
  <div>
    <div class="bar" ref="barRef"></div>
  </div>
</template>
<script>
import { loopShowTooltip } from '../../util/loop-tooltip'

export default{
    props: {
        data: {
            type: Object,
            require:true
        },
    },
  data(){
    return{
      tooltipTimer:null
    }
  },
  mounted () {
    this.initBar();
  },
  watch:{
        data:{
            handler(){
                this.initBar();
            },
            deep:true
        }
    },
  destroyed () {
    this.tooltipTimer && this.tooltipTimer.clearLoop();
  },
  methods: {
    initBar(){
        if(!this.data.xAxis || this.data.series.length <2){
            return;
        }
        let data = JSON.parse(JSON.stringify(this.data));
        let seriesData = data.series
        seriesData[0].data =seriesData[0].data.map(item => item = +(item)) 
        seriesData[1].data = seriesData[1].data.map(item => item = +(item)) 
        let max1 = Math.ceil(Math.max(...seriesData[0].data) / 100)  
        let max2 = Math.ceil(Math.max(...seriesData[1].data) / 100)
        if(max1 == 0) {max1 =1}
        if(max2 == 0) {max2 = 1}
        max1 = max1 *100
        max2 = max2*100
      let myChart = this.$echarts.init(this.$refs.barRef)
      const option = {
        color:["#00A8FF","#00A8FF"],
        grid: {
            top: "20%",
            left:"6%",
            right:"5%",
            bottom: "16%"//也可设置left和right设置距离来控制图表的大小
        },
        legend: {
            selectedMode:false,
            // data: ["经济损失", "数量统计"],
            top: "3%",
            textStyle: {
                color: "#ffffff",
                fontSize:16
            }
        },
        tooltip:{
            show:true,
            trigger:"axis",
            backgroundColor:"rgba(113,200,255,.28)",
            borderWidth:0,
            textStyle:{
                color:"#fff",
                fontSize:16,
                fontWeight:400
            },
            axisPointer:{
                  type:"line",
                  lineStyle:{
                      color:"#3371FC"
                  }
              },
              position: (pos,param,dom, rect, size)=>{
            var obj = {top: 60};
            let halfContent = size.viewSize[0] / 2;
            if(pos[0] <= halfContent) {
              obj['left'] = pos[0]
            } else if(pos[0] >halfContent) {
              obj['right'] = size.viewSize[0] - pos[0]
            }
            return obj;
          },
            formatter: (params) => {
                return `<div style="font-size:16px">${params[0].name}</div>
                        <div style="line-height:20px;font-size:16px">${params[0].seriesName}：${params[0].value}万元</div>
                        <div style="font-size:16px">${params[3].seriesName}：${params[3].value}次</div>`
            }
        },
        xAxis: {
            data: data.xAxis,
            axisLine: {
                show: false, //隐藏X轴轴线
            },
            axisTick: {
                show: false //隐藏X轴刻度
            },
            splitLine:{
                show:false,
                lineStyle:{
                    color:"rgba(255,255,255,.05)"
                }
            },
            axisLabel: {
                show: true,
                padding:[5,0,0,0],
                textStyle: {
                    color: "#fff", //X轴文字颜色
                    fontSize:14
                }
            },  
        },
        yAxis: [
            {
                type: "value",
                name: "（万元）",
                nameTextStyle: {
                    color: "#fff",
                    fontSize:14,
                    padding:[0,50,0,0]
                },
                splitLine: {
                    show: true,
                    lineStyle:{
                        color:"rgba(0,90,198,.2)",
                        width:3 
                    }
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false 
                },
                minInterval:1,
                interval:max1/5,
				max:max1,
                // max:max1*1.5,
                // interval:250,
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: "#fff",
                        fontSize:16
                    }
                },
                
            },
            {
                type: "value",
                name: "（次）",
                nameTextStyle: {
                    color: "#fff",
                    fontSize:14,
                    padding:[0,0,0,40]
                },
                // max:max2*1.5,
                // interval:25,
                minInterval:1,
                interval:max2/5,
				max:max2,
												// max:arr[1].max,
                position: "right",
                splitLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: "#fff",
                        fontSize:16
                    }
                }
            },
      
        ],
        series: [
             {
                name: data.series[0].name,
                type: "bar",
                barWidth: 25,
                itemStyle: {
                    normal: {
                      "color": {
                          "type": "linear",
                          "x": 0,
                          "y": 0,
                          "x2": 0,
                          "y2": 1,
                          "colorStops": [
                              {
                                  "offset": 0,
                                  "color": "#00D8FF"
                              },
                              {
                                  "offset": 0.5,
                                  "color": "#00D8FF"
                              },
                              {
                                  "offset": 1,
                                  "color": "#00A8FF"
                              }
                          ],
                          "global": false
                      }
                    }
                },
                data: data.series[0].data
            },
            {
                z: 1,
                name: data.series[0].name,
                type: 'pictorialBar',
                symbolPosition: 'start',
                data: data.series[0].data,
                symbol: 'circle',
                symbolOffset: [0, '5'],
                symbolSize: [25, 10],
                silent: true,
            },
            {
                z: 3,
                name: data.series[0].name,
                type: 'pictorialBar',
                symbolPosition: 'end',
                data: data.series[0].data,
                symbol: 'circle',
                symbolOffset: [0, '-50%'],
                symbolSize: [25, 10],
                silent: true,
            },
            {
                name: data.series[1].name,
                type: "line",
                yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
                smooth: false, //平滑曲线显示
                showAllSymbol: true, //显示所有图形。
                symbol: "circle", //标记的图形为实心圆
                symbolSize: 4, //标记的大小
                itemStyle: {
                    //折线拐点标志的样式
                    color: "#121E43",
                    borderColor:"#00D8FF",
                    borderWidth: 2,
                },
                lineStyle: {
                    color: "#00D8FF"
                },
                // areaStyle:{
                //     color: "rgba(0,216,255, 0.2)"
                // },
                data: data.series[1].data
            }
        ]
      };
      myChart.setOption(option)
       this.tooltipTimer && this.tooltipTimer.clearLoop();
      this.tooltipTimer = 0;
    //   this.tooltipTimer = this.$hoverLoop(myChart,option,12,3000)
    	this.tooltipTimer = loopShowTooltip(myChart, option, {
            interval: 3000, // 轮播间隔时间
            loopSeries: true, // 是否开启轮播循环
			dataLength:data.xAxis.length
        });
    }
  }
}
</script>
<style lang='scss' scoped>
.bar{
  height: 210px;
  width: 800px;
}
</style>