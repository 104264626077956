<template>
	<div class="flex maps" :style="{ left: mapLeft }">
		<left-second v-if="!isZnMap"></left-second>
		<!-- 地图 -->
		<div class="cesiumNcMapdiv" :style="{ width: mapDivWidth }">
			<div id="cesiumNcMap" :style="{ width: mapWidth }"></div>
		</div>

		<right-second v-if="!isZnMap"></right-second>
		<!-- 基地介绍和预警弹层连接线 -->
		<canvas ref="canvasewarn" class="canvas-line" :style="ctxStyle" width="0" height="0"></canvas>
		<!-- 设备点弹层连接线 -->
		<canvas ref="canvasequips" class="canvas-line" :style="ctxEStyle" width="0" height="0"></canvas>
		<!-- 设备点弹窗 -->
		<div class="mapdialog equipdialog" :style="diaStyle" v-if="openDiaType == 'equip'">
			<!-- 头部 -->
			<div class="flex flex_a_c flex_ju_sb equip-titles">
				<span class="font_20 hansan bold c_white">{{ equipName || '--' }}</span>
				<div>
					<img
						class="cursor icon_close icon_warns"
						v-if="isWarn"
						src="../assets/img/map/warns.png"
						alt=""
						@click="openWarn"
					/>
					<img class="cursor icon_close" src="../assets/img/map/icon_close.png" alt="" @click="closeDia" />
				</div>
			</div>
			<!-- 在离线状态与工作数值 -->
			<div class="flex flex_a_c dia-status">
				<div class="flex flex_col flex_a_c flex_ju_c status-bg">
					<span class="status_online">{{ isOnline ? '在线' : '离线' }}</span>
					<span class="words">状态</span>
				</div>
				<div class="flex flex_a_c flex_ju_sb status-numers">
					<div class="status-div">
						<div class="flex flex_a_c">
							<span class="dian"></span>
							<span class="font_14 status-word">工作湿度(%)</span>
						</div>
						<div class="font_20 bold status-num">
							{{ diaType == 'qx' ? '68' : '80' }}
						</div>
					</div>
					<div class="status-div">
						<div class="flex flex_a_c">
							<span class="dian"></span>
							<span class="font_14 status-word">工作电压(V)</span>
						</div>
						<div class="font_20 bold status-num">12</div>
					</div>
					<div class="status-div">
						<div class="flex flex_a_c">
							<span class="dian"></span>
							<span class="font_14 status-word">功耗(w)</span>
						</div>
						<div class="font_20 bold status-num">
							{{ diaType == 'qx' ? '10' : '100' }}
						</div>
					</div>
				</div>
			</div>
			<!-- 弹窗名称的title -->
			<div class="dia-titles">
				<img class="lines" src="../assets/img/map/lines.png" alt="" />
				<span class="bold font_18">{{ diaTopTitle }}</span>
			</div>
			<!-- 下面的具体内容，需要区分设备 -->
			<div class="dia-wraps">
				<!-- 虫情 -->
				<div class="dia-cq" v-if="diaType == 'cq'">
					<!-- 轮播图片 -->
					<div class="cq-swipers" v-if="cqImgArr.length">
						<div class="swiper-button-prev" id="btn_p"></div>
						<div class="swiper-button-next" id="btn_n"></div>
						<swiper ref="mySwiper2" class="" :options="swiperOptions2">
							<swiper-slide class="parent" v-for="(item, index) in cqImgArr" :key="index">
								<div class="cqImg">
									<span class="bold num-time">{{ item.photoTime.slice(0, 11) }}</span>
									<img :src="item.blastPicture" :data-item="item.blastPicture" alt="" />
									<div class="font_14 c_white ellipsis num-div">识别虫种：{{ item.num || 0 }}</div>
								</div>
							</swiper-slide>
						</swiper>
					</div>
					<img v-else style="height: 91px; display: block; margin: 17px auto 0" src="../assets/img/none.png" alt="" />
					<!-- 折线图 -->
					<div class="cq-echarts">
						<!-- 时间选择 -->
						<div class="flex flex_a_c flex_ju_end echarts-times">
							<div class="ech-time" :class="{ 'ech-time-active': cqTimeIndex == 0 }" @click="timeRangeClick(0)">
								本月
							</div>
							<!-- <div
                class="ech-time"
                :class="{ 'ech-time-active': cqTimeIndex == 1 }"
                @click="timeRangeClick(1)"
              >
                本年
              </div>
              <div
                class="ech-time"
                :class="{ 'ech-time-active': cqTimeIndex == 2 }"
                @click="timeRangeClick(2)"
              >
                季度
              </div> -->
							<el-date-picker
								class="cqpicker"
								v-model="cqTimeScope"
								:picker-options="pickerOptions"
								type="daterange"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="timeScopeClick()"
							>
							</el-date-picker>
						</div>
						<!-- 折线图 -->
						<div id="cqecharts">
							<div ref="cqLineChart" v-if="isCqLine"></div>
							<img v-else style="width: 40%; display: block; margin: 10px auto 0" src="../assets/img/none.png" alt="" />
						</div>
					</div>
					<!-- 防治 -->
					<div class="cq-cure">
						<div class="t_c line-titles">
							<img class="img_fg" src="../assets/img/map/lines.png" alt="" />
							<img class="arrow cursor" src="../assets/img/map/cq-arrow-left.png" alt="" @click="prevcqIntros()" />
							<span class="bold font_18">{{ cqFzWrap['pestName'] || '--' }}防治</span>
							<img
								class="arrow cursor"
								src="../assets/img/map/cq-arrow-right.png"
								alt=""
								@click="nextCqIntros(cqControlData.length)"
							/>
						</div>
						<!-- 下面的措施说明 -->
						<div class="cq-intros">
							<!-- 农业措施 -->
							<div>
								<div class="flex flex_a_c font_14 c_white intros-wrap">
									<div class="title">农业措施</div>
									<div class="intros intro-wei">
										<div class="intro-after">
											{{ cqFzWrap.agriculMeasure || '--' }}
										</div>
									</div>
								</div>
								<div class="flex flex_a_c font_14 c_white intros-wrap">
									<div class="title">生物防治</div>
									<div class="intros intro-wei">
										<div class="intro-after">
											{{ cqFzWrap.biologControl || '--' }}
										</div>
									</div>
								</div>
								<div class="flex flex_a_c font_14 c_white intros-wrap">
									<div class="title">药剂防治</div>
									<div class="intros">
										<div class="intro-after">
											{{ cqFzWrap.chemicalControl || '--' }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- 墒情 -->
				<div class="dia-sq" v-if="diaType == 'sq'">
					<!-- 实时数据内容 -->
					<div class="flex sq-mains">
						<!-- 左边的层数 -->
						<div class="sq-left">
							<div class="t_c sq-cengs" :class="{ 'sq-cengs-active': trCengIndex == 0 }" @click="tapSqCeng(0)">
								<span>第一层</span>
							</div>
							<div class="t_c sq-cengs" :class="{ 'sq-cengs-active': trCengIndex == 1 }" @click="tapSqCeng(1)">
								<span>第二层</span>
							</div>
							<div class="t_c sq-cengs" :class="{ 'sq-cengs-active': trCengIndex == 2 }" @click="tapSqCeng(2)">
								<span>第三层</span>
							</div>
						</div>
						<!-- 右边的数据 -->
						<div class="sq-right">
							<!-- 上面的数值 -->
							<div class="sq-numbers">
								<div class="flex">
									<div class="sq-num-div sq-num-div-aa" style="width: 53%">
										<div class="flex flex_a_c">
											<img class="icon_sq" src="../assets/img/map/icon_sq.png" alt="" />
											<span class="font_16 c_white sq_word">土温</span>
											<span class="sq_number">{{ trWd[trCengIndex] || '0' }}</span>
											<span class="sq_unit hansan">℃</span>
										</div>
									</div>
									<div class="sq-num-div" style="width: 47%">
										<div class="flex flex_a_c">
											<img class="icon_sq icon_sq_tw" src="../assets/img/map/icon_tw.png" alt="" />
											<span class="font_16 c_white sq_word">土湿</span>
											<span class="sq_number">{{ trSd[trCengIndex] || '0' }}</span>
											<span class="sq_unit hansan">%</span>
										</div>
									</div>
								</div>
								<div class="flex sq-num-two">
									<div class="sq-num-div sq-num-div-aa" style="width: 53%">
										<div class="flex flex_a_c">
											<img class="icon_sq" src="../assets/img/map/icon_tec.png" alt="" />
											<span class="font_16 c_white sq_word">EC</span>
											<span class="sq_number">{{ trEc || '0' }}</span>
											<span class="sq_unit hansan">uS/CM</span>
										</div>
									</div>
									<div class="sq-num-div sq-num-ph" style="width: 47%">
										<div class="flex flex_a_c">
											<img class="icon_sq icon_sq_ph" src="../assets/img/map/icon_ph.png" alt="" />
											<span class="font_16 c_white sq_word">PH</span>
											<span class="sq_number">{{ trPh || '0' }}</span>
										</div>
									</div>
								</div>
							</div>
							<!-- 下面的进度条 -->
							<div class="flex flex_ju_sb sq-progress">
								<div class="sq-proecharts">
									<div class="c_white flex flex_col flex_a_c flex_ju_c sq-yuan sq-yuan-d">
										<span class="font_20">{{ trDlz || '--' }}</span>
										<span class="font_16">mg/kg</span>
									</div>
									<span class="font_20 c_white">氮离子</span>
								</div>
								<div class="sq-proecharts">
									<div class="c_white flex flex_col flex_a_c flex_ju_c sq-yuan sq-yuan-l">
										<span class="font_20">{{ trLlz || '--' }}</span>
										<span class="font_16">mg/kg</span>
									</div>
									<span class="font_20 c_white">磷离子</span>
								</div>
								<div class="sq-proecharts">
									<div class="c_white flex flex_col flex_a_c flex_ju_c sq-yuan sq-yuan-j">
										<span class="font_20">{{ trJlz || '--' }}</span>
										<span class="font_16">mg/kg</span>
									</div>
									<span class="font_20 c_white">钾离子</span>
								</div>
								<!-- <div class="sq-proecharts" ref="sqEchartsLi"></div>
								<div class="sq-proecharts" ref="sqEchartsJia"></div> -->
							</div>
						</div>
					</div>
					<div class="line-titles sq-linetitles">
						<img class="img_fg" src="../assets/img/map/lines.png" alt="" />
						<span class="bold font_18">数据统计</span>
					</div>
					<!-- 折线图 -->
					<div class="qx-echarts">
						<div class="flex flex_a_c flex_ju_sb qx-timeall">
							<!-- 左边的下拉选 -->
							<div class="qx-selects" v-show="sqSelMultiple">
								<el-select
									v-model="sqChoose"
									placeholder="请选择"
									popper-class="qx_select_box"
									:multiple="sqSelMultiple"
									:multiple-limit="sqSelectMultipleNum"
									collapse-tags
									@change="sqSelect"
								>
									<el-option
										v-for="item in sqParams"
										:label="item.itemName"
										:value="item.itemCode"
										:key="item.itemCode"
									>
									</el-option>
								</el-select>
							</div>
							<div class="qx-selects" v-show="!sqSelMultiple">
								<el-select
									v-model="sqChoose2"
									placeholder="请选择"
									popper-class="qx_select_box"
									:multiple="false"
									collapse-tags
									@change="sqSelect2"
								>
									<el-option
										v-for="item in sqParams"
										:label="item.itemName"
										:value="item.itemCode"
										:key="item.itemCode"
									>
									</el-option>
								</el-select>
							</div>
							<!-- 右边时间 -->
							<div class="font_14 qx-times">
								<span
									class="qx-time"
									:class="{ 'qx-time-active': timeIndex == '0' }"
									@click="changeTime('0')"
									style="margin-right: 0"
									>本日</span
								>
								<span
									class="qx-time"
									:class="{ 'qx-time-active': timeIndex == '1' }"
									@click="changeTime('1')"
									style="margin-right: 0"
									>本月</span
								>
								<span
									class="qx-time"
									:class="{ 'qx-time-active': timeIndex == '2' }"
									@click="changeTime('2')"
									style="margin-right: 0"
									>季度</span
								>
								<span
									class="qx-time qx-zdy"
									:class="{ 'qx-time-active': timeIndex == '3' }"
									@click="sqTimeZdy"
									style="margin-right: 0"
									>自定义</span
								>
								<el-date-picker
									class="qxpicker"
									ref="sqpicker"
									v-model="sqTimeScope"
									:picker-options="sqPickerOptions"
									type="daterange"
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									@change="sqTimeScopeClick()"
								>
								</el-date-picker>
							</div>
						</div>
						<div v-if="isSqLine" id="sq-echart" ref="sqEcharts"></div>
						<img v-else style="width: 50%; display: block; margin: 50px auto 0" src="../assets/img/none.png" alt="" />
					</div>
				</div>
				<!-- 气象 -->
				<div class="dia-qx" v-if="diaType == 'qx'">
					<!-- 实时数据 -->
					<div class="flex flex_wrap qx-mains" v-if="qxList.length">
						<div v-for="(item, index) in qxList" :key="index" class="flex flex_a_c qx-divs">
							<img :src="item.icon" alt="" />
							<div class="flex flex_col">
								<span class="font_20 c_white bold mq_num">{{ item.dushu || 0 }}</span>
								<span class="font_14 qx_word">{{ item.title }}({{ item.unit }})</span>
							</div>
						</div>
					</div>
					<div class="qx-mains" v-else>
						<img style="width: 50%; display: block; margin: 60px auto 0" src="../assets/img/none.png" alt="" />
					</div>

					<div class="line-titles qx-linetitles">
						<img class="img_fg" src="../assets/img/map/lines.png" alt="" />
						<span class="bold font_18">数据统计</span>
					</div>
					<!-- 折线图 -->
					<div class="qx-echarts">
						<div class="flex flex_a_c flex_ju_sb qx-timeall">
							<!-- 左边的下拉选 -->
							<div class="qx-selects" v-show="qxSelMultiple">
								<el-select
									v-model="qxChoose"
									placeholder="请选择"
									popper-class="qx_select_box"
									multiple
									:multiple-limit="qxSelectMultipleNum"
									collapse-tags
									@change="qxSelect"
								>
									<el-option
										v-for="item in qxParams"
										:label="item.itemName"
										:value="item.itemCode"
										:key="item.itemCode"
									>
									</el-option>
								</el-select>
							</div>
							<div class="qx-selects" v-show="!qxSelMultiple">
								<el-select
									v-model="qxChoose2"
									placeholder="请选择"
									popper-class="qx_select_box"
									collapse-tags
									@change="qxSelect2"
								>
									<el-option
										v-for="item in qxParams"
										:label="item.itemName"
										:value="item.itemCode"
										:key="item.itemCode"
									>
									</el-option>
								</el-select>
							</div>
							<!-- 右边时间 -->
							<div class="font_14 qx-times">
								<span
									class="qx-time"
									:class="{ 'qx-time-active': timeIndex == '0' }"
									@click="changeTime('0')"
									style="margin-right: 0"
									>本日</span
								>
								<span
									class="qx-time"
									:class="{ 'qx-time-active': timeIndex == '1' }"
									@click="changeTime('1')"
									style="margin-right: 0"
									>本月</span
								>
								<span
									class="qx-time"
									:class="{ 'qx-time-active': timeIndex == '2' }"
									@click="changeTime('2')"
									style="margin-right: 0"
									>季度</span
								>
								<span
									class="qx-time qx-zdy"
									:class="{ 'qx-time-active': timeIndex == '3' }"
									@click="qxTimeZdy"
									style="margin-right: 0"
									>自定义</span
								>
								<el-date-picker
									class="qxpicker"
									ref="qxpicker"
									v-model="qxTimeScope"
									:picker-options="qxPickerOptions"
									type="daterange"
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									@change="qxTimeScopeClick()"
								>
								</el-date-picker>
								<!-- <span class="qx-time">自定义</span> -->
							</div>
						</div>
						<div id="qx-echart" v-if="isQxLine" ref="qxchart"></div>
						<img v-else style="width: 50%; display: block; margin: 50px auto 0" src="../assets/img/none.png" alt="" />
					</div>
				</div>
			</div>
		</div>
		<!-- 苗情弹窗 -->
		<div class="mapdialog mqdialog" :style="diaStyle" v-else-if="openDiaType == 'mq'">
			<div class="flex flex_a_c flex_ju_sb equip-titles">
				<span class="font_20 hansan bold c_white">视频监控</span>
				<img class="cursor icon_close" src="../assets/img/map/icon_close.png" alt="" @click="closeDia" />
			</div>
			<!-- 下面的内容 -->
			<div class="yt-wraps">
				<!-- 播放的视频 -->
				<div class="yt-videos">
					<video id="videoopen" ref="hlsRefvideo" muted></video>
					<!-- <div id="videoopen" ref="hlsRefvideo"></div> -->
					<!-- <canvas id="videoopen" ref="hlsRefvideo"></canvas> -->
					<!-- 底部操作 -->
					<div class="flex flex_a_c flex_ju_sb video-footer">
						<!-- 播放暂停 -->
						<div class="videobtns">
							<i v-if="videoIsPlay" class="el-icon-video-pause" @click="makeVideo"></i>
							<i v-else class="el-icon-video-play" @click="makeVideo"></i>
						</div>
						<div class="flex flex_a_c">
							<!-- 截图 -->
							<div @click="makeCapture">
								<img class="cursor icon_pai" src="../assets/img/pai.png" alt="" />
							</div>
							<!-- 全屏 -->
							<!-- <div class="videofull" @click="toFullScreen">
								<img class="icon_full" src="../assets/img/icon_full.png" alt="" />
							</div> -->
						</div>
					</div>
				</div>
				<!-- 云台按钮 -->
				<div class="yt-tabs tabs_wrap">
					<div class="wraps">
						<!-- 云台模式 -->
						<div class="flex flex_a_c control">
							<!-- 左边控制 -->
							<div class="controls" v-if="isCanYt">
								<!-- 上面的云台控制 -->
								<div class="control-wrap">
									<div
										class="control-btn btn-top"
										:class="{
											'controlbtn-active': controlType == '2',
										}"
										@click="controlChange('2')"
									></div>
									<div
										class="control-btn btn-right"
										:class="{
											'controlbtn-active': controlType == '1',
										}"
										@click="controlChange('1')"
									></div>
									<div
										class="control-btn btn-bottom"
										:class="{
											'controlbtn-active': controlType == '3',
										}"
										@click="controlChange('3')"
									></div>
									<div
										class="control-btn btn-left"
										:class="{
											'controlbtn-active': controlType == '0',
										}"
										@click="controlChange('0')"
									></div>
									<div
										class="control-cbtn btn-add"
										:class="{
											'controlbtn-active': controlType == '4',
										}"
										@click="controlChange('4')"
									></div>
									<div
										class="control-cbtn btn-jian"
										:class="{
											'controlbtn-active': controlType == '5',
										}"
										@click="controlChange('5')"
									></div>
								</div>
							</div>
							<div class="controls controls-no" v-else>
								<div class="control-wrap">
									<div class="control-btn btn-top"></div>
									<div class="control-btn btn-right"></div>
									<div class="control-btn btn-bottom"></div>
									<div class="control-btn btn-left"></div>
									<div class="control-cbtn btn-add"></div>
									<div class="control-cbtn btn-jian"></div>
								</div>
							</div>
							<!-- 右边截图 -->
							<!-- 多一个父级，主要用于ie浏览器进度条的显示 -->
							<div class="control-imgs" v-if="captureList.length">
								<div class="mq-swipers">
									<div class="swiper-button-prev" id="mqbtn-p" v-show="isShowMqbtn"></div>
									<div class="swiper-button-next" id="mqbtn-n" v-show="isShowMqbtn"></div>
									<swiper ref="mqSwiper" class="control-imgwrap" :options="mqswiperOptions">
										<swiper-slide class="parent" v-for="(item, index) in captureList" :key="index">
											<div class="mqImg">
												<img :src="item" :data-item="item" alt="" />
											</div>
										</swiper-slide>
									</swiper>
								</div>
							</div>
							<div class="emptys" v-else>
								<img class="emptys-img" src="../assets/img/none.png" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 过程建议 -->
			<div class="mq-status">
				<div class="t_c titles">
					<img class="titles_bg" src="../assets/img/map/lines.png" alt="" />
					<span class="font_18 bold titles-word">抓拍记录</span>
				</div>

				<!-- 下面的内容 -->
				<div class="mq-wraps" v-if="mqBottomInfo && mqBottomInfo.id">
					<div class="flex flex_a_c flex_ju_sb mq-contents">
						<div class="mq-vlcs">
							<!-- 图片 -->
							<img class="mq-showing" :src="mqBottomInfo.print" alt="" @click="bigImg(mqBottomInfo.print)" />
							<!-- 左右切换按钮 -->
							<img class="mq-btn mq-btn-p" src="../assets/img/map/btn_p.png" alt="" @click="mqToPrev" />
							<img class="mq-btn mq-btn-n" src="../assets/img/map/btn_n.png" alt="" @click="mqToNext" />
						</div>
						<div class="mq-mains">
							<div class="flex flex_a_c mq_divs" v-for="(item, index) in mqBList" :key="index">
								<img class="mq_icon" :src="item.icon" alt="" />
								<div class="flex flex_col">
									<span class="font_20 c_white bold mq_num">{{ mqBottomInfo[item.key] || '--' }}</span>
									<span class="font_14 mq_word">{{ item.name }}</span>
								</div>
							</div>
						</div>
					</div>
					<!-- <div class="mq-intros">
						<div class="mq-intro">建议：{{ mqBottomInfo.suggestions || '暂无' }}。</div>
					</div> -->
				</div>
				<div class="flex flex_ju_c mq-wraps" v-else>
					<img style="width: 60%" src="../assets/img/none.png" alt="" />
				</div>
			</div>
		</div>
		<!-- 水肥弹窗 -->
		<div class="mapdialog sfdialog" :style="diaStyle" v-else-if="openDiaType == 'sf'">
			<div class="flex flex_a_c flex_ju_sb equip-titles">
				<span class="font_20 hansan bold c_white">XXXX水肥监测</span>
				<img class="cursor icon_close" src="../assets/img/map/icon_close.png" alt="" @click="closeDia" />
			</div>
			<!-- 下面的内容 -->
			<div class="yt-wraps">
				<!-- 播放的视频 -->
				<div class="yt-videos"></div>
				<!-- tab切换 -->
				<div class="yt-tabs tabs_wrap">
					<!-- 上面的切换tab -->
					<div class="flex flex_ju_c tabs">
						<div
							class="font_18 tabdivs"
							:class="{ 'tabdiv-active': tabType == item.type }"
							v-for="(item, index) in tabLists"
							:key="index"
							@click="switchTab(item.type)"
						>
							{{ item.name }}
						</div>
					</div>
					<div class="wraps">
						<!-- 云台模式 -->
						<div class="flex control" v-if="tabType == 'control'">
							<!-- 左边控制 -->
							<div class="controls">
								<!-- 上面的云台控制 -->
								<div class="control-wrap">
									<div
										class="control-btn btn-top"
										:class="{
											'controlbtn-active': controlType == '0',
										}"
									></div>
									<div
										class="control-btn btn-right"
										:class="{
											'controlbtn-active': controlType == '3',
										}"
									></div>
									<div
										class="control-btn btn-bottom"
										:class="{
											'controlbtn-active': controlType == '1',
										}"
									></div>
									<div
										class="control-btn btn-left"
										:class="{
											'controlbtn-active': controlType == '2',
										}"
									></div>
									<div
										class="control-cbtn btn-add"
										:class="{
											'controlbtn-active': controlType == '8',
										}"
									></div>
									<div
										class="control-cbtn btn-jian"
										:class="{
											'controlbtn-active': controlType == '9',
										}"
									></div>
								</div>
								<!-- 下面的截图 -->
								<div class="control-jie"></div>
							</div>
							<!-- 右边截图 -->
							<!-- 多一个父级，主要用于ie浏览器进度条的显示 -->
							<div class="control-imgs" v-if="captureList.length">
								<div class="control-imgwrap">
									<img v-for="(item, index) in captureList" :key="index" :src="item" alt="" />
								</div>
							</div>
							<div class="emptys" v-else>
								<img class="emptys-img" src="../assets/img/none.png" alt="" />
							</div>
						</div>
						<!-- 设备操作 -->
						<div class="hand" v-else-if="tabType == 'hand'">
							<hand-tab :equip-id="equipId"></hand-tab>
						</div>
						<!-- 定时设置 -->
						<div class="autos" v-else-if="tabType == 'autos'">
							<auto-tab :equip-id="equipId"></auto-tab>
						</div>
						<!-- 智能模式 -->
						<div class="smarts" v-else-if="tabType == 'smarts'">
							<smarts-tab :equip-id="equipId"></smarts-tab>
						</div>
					</div>
				</div>
				<!-- 运行情况 -->
				<div class="yt-status">
					<div class="titles">
						<img class="titles_bg" src="../assets/img/yt/fg.png" alt="" />
						<span class="font_18 c_white titles-word">运行情况</span>
					</div>
					<div class="yt-equips">
						<!-- 左边 -->
						<!-- <div class="lefts">
							<div v-for="(item, index) in ytStatus.slice(0, 4)" :key="index" class="status-div">
								<div class="flex flex_a_c">
									<span class="dian"></span>
									<span class="font_14 status-word">{{ item.name }}</span>
								</div>
								<div class="status-num">
									<span class="font_20 bold">{{ item.num }}</span>
									<span class="font_16">{{ item.unit }}</span>
								</div>
							</div>
						</div> -->
						<div class="flex flex_a_c flex_ju_c center-times">更新时间：2021-12-10 14:05:00</div>
						<!-- 中间运行中 -->
						<div class="flex flex_a_c flex_ju_sb centers">
							<div class="font_18 c_white flex flex_col flex_a_c flex_ju_c hansan center-bg">
								<span>状态:</span>
								<span style="margin-top: 6px">运行中</span>
							</div>
							<!-- 右边的数据 -->
							<div class="flex flex_ju_sb sf-cr">
								<div class="flex flex_col flex_a_c sf-cdiv">
									<img class="sf-cicon" src="../assets/img/yt/icon_b1.png" alt="" />
									<span>A肥流速</span>
									<span>10升/分钟</span>
								</div>
								<div class="flex flex_col flex_a_c sf-cdiv">
									<img class="sf-cicon" src="../assets/img/yt/icon_b2.png" alt="" />
									<span>A肥积累流量</span>
									<span>152升</span>
								</div>
								<div class="flex flex_col flex_a_c sf-cdiv">
									<img class="sf-cicon" src="../assets/img/yt/icon_b3.png" alt="" />
									<span>施肥总量</span>
									<span>152升</span>
								</div>
							</div>
						</div>
						<!-- 右边 -->
						<!-- <div class="rights">
							<div v-for="(item, index) in ytStatus.slice(4, 8)" :key="index" class="status-div">
								<div class="flex flex_a_c">
									<span class="dian"></span>
									<span class="font_14 status-word">{{ item.name }}</span>
								</div>
								<div class="status-num">
									<span class="font_20 bold">{{ item.num }}</span>
									<span class="font_16">{{ item.unit }}</span>
								</div>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<!-- 基地介绍弹窗 -->
		<div class="mapdialog jddialog" :style="diaStyle" v-else-if="openDiaType == 'jd'">
			<div class="flex flex_a_c flex_ju_sb jd-title">
				<div class="flex flex_a_c">
					<img class="icon_diatitle" src="../assets/img/map/icon_diatitle.png" alt="" />
					<span class="c_white font_20 hansan">{{ jdTitle }}核心区</span>
				</div>
				<!-- 右边的叉号 -->
				<span class="icon_cha cursor" @click="closeDia">×</span>
			</div>
			<div class="font_18 c_white jd-cont">
				<div>负责人：{{ jdName }}</div>
				<div>值班电话：{{ jdPhone }}</div>
			</div>
		</div>
		<!-- 智脑中心的时间线 -->
		<!-- <div class="times-wrap">
			<div class="left_">
				<img src="../assets/img/map/swiper_icon1.png" alt="" />
			</div>
			<div class="right_">
				<swiper ref="mySwiper2" class="" :options="swiperOptions2">
					<swiper-slide class="parent" v-for="(item, index) in hours" :key="index">
						<div class="slidebox">{{ item }}时</div>
					</swiper-slide>
				</swiper>
			</div>
		</div> -->
		<!-- 列表弹窗 -->
		<div class="mapdialog lbdialog" :style="diaStyle" v-else-if="openDiaType == 'lb'">
			<div class="flex flex_a_c flex_ju_sb equip-titles">
				<span class="font_20 hansan bold c_white">监控列表</span>
				<img class="cursor icon_close" src="../assets/img/map/icon_close.png" alt="" @click="closeDia" />
			</div>
			<ul>
				<li class="lbLi" v-for="(item, index) in lists" :key="index" @click="changeList(item)">
					{{ item.name }}
				</li>
			</ul>
		</div>
		<!-- 图表元素：智脑中心使用 -->
		<!-- 2022/07/26 注释不用 -->
		<!-- <div class="echartleng" :style="diaStyle" v-else-if="openDiaType == 'chart'">
			<div v-for="(item, index) in echartsData" :key="index" class="flex flex_a_c chart-li">
				<div class="dot" :style="dotStyle(item.name)"></div>
				<span class="font_16 c_white">{{ item.name }}: {{ item.value }}{{ item.unit }}</span>
			</div>
		</div> -->
		<!-- 2022/07/26 智脑中心 添加弹窗 -->
		<div class="echart-wrap" v-else-if="openDiaType == 'chart'">
			<!-- 上面的title和关闭按钮 -->
			<div class="flex flex_a_c flex_ju_sb equip-titles">
				<span class="font_20 hansan bold c_white">{{ modelType == '0' ? '肥力' : '土壤' }}感知对比</span>
				<img class="cursor icon_close" src="../assets/img/map/icon_close.png" alt="" @click="closeDia" />
			</div>
			<!-- 第二层实时数据和对比结果 -->
			<div class="flex flex_ju_c flex_a_c chart-second">
				<!-- 左侧实时数据 -->
				<div class="chart-item chart-data">
					<div class="flex flex_ju_c flex_a_c titles">
						<img src="../assets/img/chart/icon_line.png" class="icon_line" alt="" />
						<span>{{ equipFaceName }}</span>
						<img src="../assets/img/chart/icon_line.png" class="icon_line" alt="" />
					</div>
					<!-- 肥力 -->
					<div class="flex flex_ju_c data-wrap" v-if="modelType == '0'">
						<div class="data-items">
							<div class="flex flex_col flex_a_c flex_ju_c data-item item-dan">
								<div class="item-nums">{{ echartsData[0]['value'] }}</div>
								<div class="item-unit">{{ echartsData[0]['unit'] }}</div>
							</div>
							<div class="t_c item-word">{{ echartsData[0]['name'] }}</div>
						</div>
						<div class="data-items">
							<div class="flex flex_col flex_a_c flex_ju_c data-item item-lin">
								<div class="item-nums">{{ echartsData[1]['value'] }}</div>
								<div class="item-unit">{{ echartsData[1]['unit'] }}</div>
							</div>
							<div class="t_c item-word">{{ echartsData[1]['name'] }}</div>
						</div>
						<div class="data-items">
							<div class="flex flex_col flex_a_c flex_ju_c data-item item-jia">
								<div class="item-nums">{{ echartsData[2]['value'] }}</div>
								<div class="item-unit">{{ echartsData[2]['unit'] }}</div>
							</div>
							<div class="t_c item-word">{{ echartsData[2]['name'] }}</div>
						</div>
					</div>
					<!-- 土壤 -->
					<div class="flex flex_ju_c data-wrap data-wrap-tr" v-if="modelType == '1'">
						<div class="data-items">
							<div class="flex flex_a_c flex_ju_c data-item item-wd">
								<div class="item-nums">{{ echartsData[0]['value'] }}</div>
								<div class="item-unit">{{ echartsData[0]['unit'] }}</div>
							</div>
							<div class="t_c item-word">土壤温度</div>
						</div>
						<div class="data-items">
							<div class="flex flex_a_c flex_ju_c data-item item-sd">
								<div class="item-nums">{{ echartsData[1]['value'] }}</div>
								<div class="item-unit">{{ echartsData[1]['unit'] }}</div>
							</div>
							<div class="t_c item-word">土壤湿度</div>
						</div>
					</div>
				</div>
				<!-- 右侧对比结果 -->
				<div class="chart-item chart-bj">
					<div class="flex flex_ju_c flex_a_c titles">
						<img src="../assets/img/chart/icon_line.png" class="icon_line" alt="" />
						<span>对比结果</span>
						<img src="../assets/img/chart/icon_line.png" class="icon_line" alt="" />
					</div>
					<div class="bj-wrap" :class="{ 'bj-wrap-tr': modelType == '1' }">
						<!-- 最高 -->
						<div class="flex flex_a_c flex_ju_c bj-maxs">
							<div class="flex flex_col flex_a_c flex_ju_c bj-words">最高</div>
							<div class="flex flex_col imgs">
								<img src="../assets/img/chart/line_t.png" alt="" />
								<img v-if="modelType == '0'" src="../assets/img/chart/line_c.png" alt="" />
								<img src="../assets/img/chart/line_b.png" alt="" />
							</div>
							<div v-if="modelType == '0'">
								<div class="bj-item">{{ danMax.name }}：氮离子为{{ danMax.val }}</div>
								<div class="bj-item">{{ linMax.name }}：磷离子为{{ linMax.val }}</div>
								<div class="bj-item">{{ jiaMax.name }}：钾离子为{{ jiaMax.val }}</div>
							</div>
							<div v-else-if="modelType == '1'">
								<div class="bj-item">{{ twMax.name }}：土壤温度为{{ twMax.val }}℃</div>
								<div class="bj-item">{{ tsMax.name }}：土壤湿度为{{ tsMax.val }}%</div>
							</div>
						</div>
						<!-- 最低 -->
						<div class="flex flex_a_c flex_ju_c bj-mins">
							<div class="flex flex_col flex_a_c flex_ju_c bj-words">最低</div>
							<div class="flex flex_col imgs">
								<img src="../assets/img/chart/line_t.png" alt="" />
								<img v-if="modelType == '0'" src="../assets/img/chart/line_c.png" alt="" />
								<img src="../assets/img/chart/line_b.png" alt="" />
							</div>
							<div v-if="modelType == '0'">
								<div class="bj-item">{{ danMin.name }}：氮离子为{{ danMin.val }}</div>
								<div class="bj-item">{{ linMin.name }}：磷离子为{{ linMin.val }}</div>
								<div class="bj-item">{{ jiaMin.name }}：钾离子为{{ jiaMin.val }}</div>
							</div>
							<div v-else-if="modelType == '1'">
								<div class="bj-item">{{ twMin.name }}：土壤温度为{{ twMin.val }}℃</div>
								<div class="bj-item">{{ tsMin.name }}：土壤湿度为{{ tsMin.val }}%</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 第三层柱状图 -->
			<div class="chart-charts" ref="znDiachartRef" v-if="modelType == '0'"></div>
			<div class="chart-charts" ref="znDiachartRefTr" v-else-if="modelType == '1'"></div>
			<!-- 左右俩个切换按钮 -->
			<div class="icon_jian jian_left" @click="handlePage('left')"></div>
			<div class="icon_jian jian_right" @click="handlePage('right')"></div>
		</div>

		<!-- 预警信息弹窗 -->
		<div class="mapdialog warndialog" :style="warnStyle" v-if="isOpenWarn">
			<div class="flex flex_a_c flex_ju_sb jd-title">
				<div class="flex flex_a_c">
					<img class="icon_diatitle" src="../assets/img/map/icon_warn.png" alt="" />
					<span class="c_white font_20 hansan">预警信息</span>
				</div>
				<!-- 右边的叉号 -->
				<span class="icon_cha cursor" @click="closeWarn">×</span>
			</div>
			<div class="font_18 c_white jd-cont">
				<div class="hansan font_18 bold warn-infotitle">
					{{ warnWord || '' }}
				</div>
				<div class="warn-infos">
					<div class="warn-times">时间：{{ warnTime || '--' }}</div>
					<div>概述：{{ warnMsg || '--' }}</div>
				</div>
			</div>
		</div>
		<!-- VR链接 -->
		<div class="vr" v-if="!isZnMap" :style="{ right: vrRight }" @click="openVr('vr')"></div>
		<!-- 新增管网导览按钮 -->
		<div class="gwdl vr" v-if="!isZnMap" :style="{ right: vrRight }" @click="openVr('gwdl')"></div>
		<!-- 蒙层 -->
		<div class="dialog-mb" v-if="openDiaType != ''" @click="closeDia"></div>
		<!-- 图片放大预览 -->
		<div class="bigimg" v-if="isBigImg">
			<img style="width: 100%; height: 100%" :src="bigImgs" alt="" />
			<img class="cursor icon_close" src="../assets/img/map/icon_close.png" alt="" @click="closeBigImg" />
		</div>
		<!-- 土壤感知和肥力感知 -->
		<div class="chart-tabs" v-if="isZnMap">
			<div class="chart-btn" :class="{ 'chart-active': modelType === '0' }" @click="changeModel('0')">肥力感知</div>
			<div class="chart-btn" :class="{ 'chart-active': modelType === '1' }" @click="changeModel('1')">土壤感知</div>
		</div>
	</div>
</template>
<script>
import EZUIKit from 'ezuikit-js'
// 地图部分的js
import bus from '../api/bus.js'
import map from '../api/map.js'
import dialog from '../api/dialog.js'
import video from '../api/videos.js'
// 手动模式
import HandTab from '@/components/water/handTab1'
// 自动模式
import AutoTab from '@/components/water/autoTab'
// 智能模式
import SmartsTab from '@/components/water/smartsTab.vue'

import ncJson from '../assets/json/equip1.json'
import sfJson from '../assets/json/equipsf(1).json'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import LeftSecond from './HomeComponent/LeftSecond.vue'
import RightSecond from './HomeComponent/RightSecond.vue'
var vm
export default {
	mixins: [dialog, map, video],
	components: {
		HandTab,
		AutoTab,
		SmartsTab,
		Swiper,
		SwiperSlide,
		LeftSecond,
		RightSecond,
	},
	data() {
		return {
			sfIsLoad: false, // 圣丰是否已经加载了
			pageIndex: 0, // 所属区域  0 农场 1 圣丰,默认都是农场
			tabIndex: 0, // 下面三个主要页面的切换
			years: 0, // 肥力感知 土壤感知  0 肥力 1 土壤
			videoRefName: 'hlsRefvideo',
			params: {
				page: 1,
				limit: 50,
			},
			lists: [
				// {
				// 	name: '圣丰大豆科技馆2楼西楼梯口',
				// 	id: 'G59770484',
				// },
				// {
				// 	name: '圣丰大豆科技馆1楼西门入口',
				// 	id: 'G59770697',
				// },
				// {
				// 	name: '圣丰大豆科技馆1楼东门口',
				// 	id: 'G59770555',
				// },
				// {
				// 	name: '圣丰大豆科技馆2楼东楼梯口',
				// 	id: 'G59770545',
				// },
				{
					name: '圣丰大豆科技馆2楼西楼梯口',
					id: '18d1ecbba42c4e688e5bb42a0e15f189',
				},
				{
					name: '圣丰大豆科技馆1楼西门入口',
					id: 'b8d1d7cabc214c32a92dc88e3bdab505',
				},
				{
					name: '圣丰大豆科技馆1楼东门口',
					id: 'd6e137aec48c4e79ad123ac47c4f0b7e',
				},
				{
					name: '圣丰大豆科技馆2楼东楼梯口',
					id: '853b699e277c40009fe4e4366bcb4898',
				},
			],

			equips: [],
			swiperOptions2: {
				// slidesPerView: 5,
				spaceBetween: 15,
				loop: true,
				observer: true,
				observeParents: true,
				autoplay: false,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				slidesPerView: 3,
				grid: {
					fill: 'column',
					rows: 1,
				},
				on: {
					click: function (e) {
						// // 获取属性绑定的函数名
						var items = e.target.dataset.item

						vm.isBigImg = true
						vm.bigImgs = items
						console.log(items)
					},
				},
				// autoplay: {
				//   delay: 3000,
				//   disableOnInteraction: false
				// }
			},
			// 苗情抓拍图片轮播
			mqswiperOptions: {
				// slidesPerView: 5,
				spaceBetween: 9,
				loop: false,
				observer: true,
				observeParents: true,
				autoplay: false,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				slidesPerView: 2,
				grid: {
					fill: 'column',
					rows: 1,
				},
				on: {
					click: function (e) {
						// // 获取属性绑定的函数名
						var items = e.target.dataset.item
						vm.isBigImg = true
						vm.bigImgs = items
						vm.hideVideos()
					},
				},
			},
			// 时间线的小时数据
			hours: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
			isCanClose: true, // 弹窗是否可以关闭，主要用于视频播放，限制视频未创建时不能进行关闭操作
		}
	},
	beforeCreate() {
		const key = 'xxl_sso_sessionid'
		const val = '05bdaf20f1914471812218615388f0df_667a1a4fed3f4aa08fa9e031ebf836a5'
		// document.cookie('xxl_sso_sessionid', '05bdaf20f1914471812218615388f0df_667a1a4fed3f4aa08fa9e031ebf836a5	')
		document.cookie = key + '=' + val
	},
	created() {},
	computed: {
		isZnMap() {
			console.log(this.$store.state.isZnMap)
			return this.$store.state.isZnMap
		},
		// 地图的左侧距离，用作区分智脑中心时，地图左侧的距离
		mapLeft() {
			if (this.isZnMap) {
				return '1335px'
			} else {
				return '915px'
			}
		},
		// 地图外边背景框的大小
		mapDivWidth() {
			if (this.isZnMap) {
				return '2800px'
			} else {
				return '1400px'
			}
		},
		// 地图宽度
		mapWidth() {
			if (this.isZnMap) {
				return '2760px'
			} else {
				return '1360px'
			}
		},
		vrRight() {
			if (this.isZnMap) {
				return '160px'
			} else {
				return '1037px'
			}
		},
	},
	mounted() {
		let paths = this.$route.path
		if (paths == '/home' || paths == '/HomeEnergy' || paths == '/HomeEquip' || paths == '/HomeAbnormal') {
			this.tabIndex = 0
		} else if (paths == '/HomeMon' || paths == '/HomeProduction') {
			this.tabIndex = 1
		} else if (paths == '/HomeBrain') {
			this.tabIndex = 2
		}
		// 赋值显示的是农场还是圣丰
		this.pageIndex = 0
		this.$nextTick(() => {
			if (this.tabIndex == 0 || (this.tabIndex == 2 && this.$store.state.isZnMap)) {
				this.mapInit()
				if (this.tabIndex == 2 && this.$store.state.isZnMap) {
					setTimeout(() => {
						this.changeModel('0')
					}, 1000)
				}
				// 获取设备信息
				this.getEquipData()
				// if (this.tabIndex == 2 && this.isZnMap) {
				// 	this.changeModel('0')
				// }
			}
		})

		// 监听农场和圣丰的切换
		bus.$on('totabclicksf', (e) => {
			// 赋值显示的是农场还是圣丰
			this.pageIndex = e
			// 弹窗关闭
			this.openDiaType = ''
			this.diaType = ''
			this.isBigImg = false
			this.bigImgs = ''
			this.isOpenWarn = false

			// 画布折线图取消
			let ctxdomWarn = this.$refs.canvasewarn
			ctxdomWarn.width = 0
			ctxdomWarn.height = 0
			let ctxdomEquip = this.$refs.canvasequips
			ctxdomEquip.width = 0
			ctxdomEquip.height = 0

			// 视频播放
			if (this.player) {
				this.player.stop()
				this.player = null
			}
			// 重新设置视角
			this.setView(e)
		})
		// 监听切换到智脑中心的热力图页面
		bus.$on('changeZnMap', (e) => {
			this.modelType = '0'
			this.centerType = '0'
			if (e) {
				const entitys = cesiumViewer.entities._entities._array
				let length = entitys.length
				// 倒叙遍历防止实体减少之后entitys[f]不存在
				for (let f = length - 1; f >= 0; f--) {
					if (
						entitys[f]._name &&
						(entitys[f]._name === 'echarts' ||
							entitys[f]._name === 'heatpolygon' ||
							entitys[f]._name === 'heatrectangle' ||
							entitys[f]._name === 'equipname')
					) {
						cesiumViewer.entities.remove(entitys[f])
					}
				}
				// 绘制图表
				this.$nextTick(() => {
					this.makeTrEcharts()
				})
			}
		})
		// 监听智脑中心感知的切换
		// bus.$on('brainIndex', (val) => {
		// 	this.centerType = val
		// 	// 请求不同的数接口
		// 	// 清除之前的实体
		// 	const entitys = cesiumViewer.entities._entities._array
		// 	let length = entitys.length
		// 	// 倒叙遍历防止实体减少之后entitys[f]不存在
		// 	for (let f = length - 1; f >= 0; f--) {
		// 		if (entitys[f]._name && entitys[f]._name === 'echarts') {
		// 			cesiumViewer.entities.remove(entitys[f])
		// 		}
		// 	}

		// 	this.$nextTick(() => {
		// 		this.makeTrEcharts()
		// 	})
		// })
		vm = this
	},

	destroyed() {
		// 销毁监听事件
		bus.$off('totabclicksf')
		bus.$off('changeZnMap')
		// bus.$off('brainIndex')
	},
	methods: {
		// 智脑中心感知的切换 肥力和土壤
		changeModel(type) {
			this.modelType = type
			// 发布全局切换事件，用于地图页面切换后数据请求变化
			// bus.$emit('brainIndex', type)
			this.centerType = type
			// 请求不同的数接口
			// 清除之前的实体
			const entitys = cesiumViewer.entities._entities._array
			let length = entitys.length
			// 倒叙遍历防止实体减少之后entitys[f]不存在
			for (let f = length - 1; f >= 0; f--) {
				if (
					entitys[f]._name &&
					(entitys[f]._name === 'echarts' ||
						entitys[f]._name === 'heatpolygon' ||
						entitys[f]._name === 'heatrectangle' ||
						entitys[f]._name === 'equipname')
				) {
					cesiumViewer.entities.remove(entitys[f])
				}
			}

			this.$nextTick(() => {
				this.makeTrEcharts()
			})
		},
		// 获取设备信息
		async getEquipData() {
			let params = {
				param: this.pageIndex,
				year: this.years,
			}
			let equips = ncJson
			console.log(this.pageIndex,'pageIndexpageIndex');
			
			if (this.pageIndex == 1) {
				equips = sfJson
			} else {
				equips = ncJson
			}

			const { data: res } = await this.$http.post(this.$api.jxFacilityListAll, params)
			console.log('设备信息', res)
			if (res.code == 0) {
				let datas = res.data || {}
				equips.forEach((item) => {
					datas.forEach((items) => {
						if (item.properties.facility_id && item.properties.facility_id == items.facility_id) {
							item.properties.id = items.id
							item.properties.send_msg = items.send_msg || ''
							item.properties.is_online = items.is_online || '1'
							item.properties.warnColor = items.warnColor || []
							item.properties.is_normal = items.is_normal || '0'
							item.properties.times = items.insert_time || ''
							if(item.properties.name == '土壤'){
								item.properties.equipname = item.properties.ding_name
								console.log(item.properties.equipname,'item.properties.equipname')
								console.log(item.properties,'item.properties.equipname')
							}else{
								if (item.properties.is_dingname) {
								item.properties.equipname = item.properties.ding_name
							} else {
								item.properties.equipname = items.facility_name || ''
							}
							}

							if (items.column_name && items.column_name != '') {
								item.properties.column_name = items.column_name || ''
							} else {
								item.properties.column_name = ''
							}
							// 视频播放的通道号
							item.properties.channel_num = items.channel_num || ''
						}
					})
				})
			}

			this.$nextTick(() => {
				// 添加设备点
				console.log(equips,'equips');
				// return
				this.addEquips(equips)
			})
		},
		// 重新设置视角
		setView(ind) {
			// 农场，农场默认已经加载过了，直接转换视角
			if (ind == 0) {
				// TODO 主要用于智脑中心
				const entitys = cesiumViewer.entities._entities._array
				let length = entitys.length
				for (let f = length - 1; f >= 0; f--) {
					if (
						entitys[f]._name &&
						(entitys[f]._name === 'echarts' ||
							entitys[f]._name === 'heatpolygon' ||
							entitys[f]._name === 'heatrectangle' ||
							entitys[f]._name === 'equipname')
					) {
						cesiumViewer.entities.remove(entitys[f])
					}
				}
				// 智脑中心
				let saleZoom = 1830.0
				let latNum = 35.57735282986337
				let pitchNum = -45
				// 判断是否是智脑中心
				if (this.tabIndex == 2 && this.isZnMap) {
					// 设置缩放比和视角角度
					saleZoom = 1900.0
					pitchNum = -30
					latNum = 35.56505282986337
				}

				cesiumViewer.camera.setView({
					// fromDegrees()方法，将经纬度和高程转换为世界坐标
					destination: Cesium.Cartesian3.fromDegrees(116.26953363418579, latNum, saleZoom),
					orientation: {
						// 指向
						heading: Cesium.Math.toRadians(0),
						// 视角
						pitch: Cesium.Math.toRadians(pitchNum),
						// roll: 0.0,
					},
				})

				if (this.tabIndex == 2 && this.isZnMap) {
					this.$nextTick(() => {
						this.makeTrEcharts()
					})
				}
			} else {
				// 判断圣丰是否已经加载
				// 已经加载过，直接转化视角
				if (this.sfIsLoad) {
					// TODO 主要用于智脑中心
					const entitys = cesiumViewer.entities._entities._array
					let length = entitys.length
					for (let f = length - 1; f >= 0; f--) {
						if (
							entitys[f]._name &&
							(entitys[f]._name === 'echarts' ||
								entitys[f]._name === 'heatpolygon' ||
								entitys[f]._name === 'heatrectangle' ||
								entitys[f]._name === 'equipname')
						) {
							cesiumViewer.entities.remove(entitys[f])
						}
					}

					let saleZoom = 700.0
					let latNum = 35.42906429359502
					let pitchNum = -50
					if (this.tabIndex == 2 && this.$store.state.isZnMap) {
						saleZoom = 1000.0
						pitchNum = -45
						latNum = 35.42536429359502
						this.$nextTick(() => {
							this.makeTrEcharts()
						})
					}
					cesiumViewer.camera.setView({
						// fromDegrees()方法，将经纬度和高程转换为世界坐标
						destination: Cesium.Cartesian3.fromDegrees(116.36564254760744, latNum, saleZoom),
						orientation: {
							// 指向
							heading: Cesium.Math.toRadians(0),
							// 视角
							pitch: Cesium.Math.toRadians(pitchNum),
							// roll: 0.0,
						},
					})
				} else {
					// 没有加载过，加载圣丰的模型地图等数据
					this.makeSf()
					this.pageIndex = 1
					this.years = this.centerType
					// 请求设备点
					this.getEquipData()
					setTimeout(() => {
						this.sfIsLoad = true
					}, 2000)

					const entitys = cesiumViewer.entities._entities._array
					let length = entitys.length
					for (let f = length - 1; f >= 0; f--) {
						if (
							entitys[f]._name &&
							(entitys[f]._name === 'echarts' ||
								entitys[f]._name === 'heatpolygon' ||
								entitys[f]._name === 'heatrectangle' ||
								entitys[f]._name === 'equipname')
						) {
							cesiumViewer.entities.remove(entitys[f])
						}
					}

					if (this.tabIndex == 2 && this.$store.state.isZnMap) {
						setTimeout(() => {
							this.makeTrEcharts()
						}, 1000)
					}
				}
			}
		},
		// 点击打开iframe
		openVr(type) {
			// console.log(1111)
			switch (type) {
				case 'vr':
					// console.log(type)
					bus.$emit('openVR', {
						param: {
							type,
							VRsrc: 'https://720yun.com/t/55vkz7i75pl?scene_id=81683298#scene_id=81683298',
						},
					})
					break
				case 'gwdl':
					// console.log(type)
					bus.$emit('openVR', {
						param: {
							type,
						},
					})
					break
			}
		},
	},
}
</script>
<style lang="less">
@import '@/assets/css/map.less';
.cqpicker.el-range-editor.el-input__inner {
	width: 220px;
	background-color: transparent;
	border-color: #07a3ee;
	height: 30px;
	line-height: 30px;
	.el-date-editor .el-range__icon {
		line-height: 30px !important;
	}
}
.el-date-editor .el-range__icon {
	line-height: 24px !important;
}
.el-date-editor .el-range-separator {
	line-height: 24px !important;
	padding: 0 !important;
	margin: 0 5px !important;
}
.qxpicker.el-range-editor.el-input__inner {
	position: absolute;
	z-index: 2;
	opacity: 0;
	right: 15px;
	top: 0px;
	width: 61px;
	background-color: transparent;
	border-color: #07a3ee;
	height: 35px;
	line-height: 35px;
	cursor: pointer;
	.el-date-editor .el-range__icon {
		line-height: 35px !important;
	}
}
.el-date-table td.disabled div {
	background-color: rgba(94, 100, 104, 0.5) !important;
}
.qx-selects .el-input__inner {
	color: white;
}
.qx_select_box .el-select-dropdown__item.selected {
	background-color: #15acd7 !important;
	color: #fff !important;
}
</style>
