<template>
  <div>
    <div class="treemap" ref="treeRef"></div>
  </div>
</template>
<script>
export default{
  props: {
    data: {
      type: Array,
      required:true 
    },
  },
  mounted () {
    this.initTreeMap();
  },
  watch:{
			data:{
				handler(){
					this.initTreeMap();
			 	},
				deep:true
			}
		},
  methods: {
    initTreeMap() {
      let _this = this;
      if(!this.data || this.data.length === 0){
        return;
      }
      let myChart = this.$echarts.init(this.$refs.treeRef)
      var color = ['#4B96F3', '#10C6A6', '#F2AF4B', '#FF4D4F', '#AA7AF1'];
      var colorList = [];
      for (var i = 0; i < this.data.length; i++) {
          if (i <= 4) {
              colorList[i] = color[i];
          } else {
              colorList[i] = color[i % 5];
          }
      }

      var resdata = [];
      for (var i = 0; i < this.data.length; i++) {
          resdata.push({
              value: _this.data[i].value,
              name: _this.data[i].name,
              itemStyle: {
                  color: colorList[i],
              },
          });
      }

      const option = {
        legend: {
            show: true,
            // data: dataname,
            // selectedMode: 'single',
            itemGap: 5,
            borderRadius: 5,
            symbolSize: 'auto',
        },
        tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}',
        },
        series: [
            {
                type: 'treemap',
                width: '100%',
                height: '100%',
                silent:true,
                breadcrumb: {
                    show: false,
                },
                // roam:false,
                label: {
                    normal: {
                        show: true,
                        textStyle: {
                            color: '#fff',
                            fontSize: 16,
                        },
                        overflow:"breakAll",
                        formatter: function (params) {
                            return params.data.name+'：'+params.data.value+'次'
                        }
                    },
                },
                data: resdata,
            },
        ],
      };
      myChart.setOption(option)
    }
  },
}
</script>
<style lang='scss' scoped>
.treemap{
  height: 226px;
	width: 808px; 
}
</style>