<template>
	<div class="screen-footers">
		<div class="screen-header">
			<!-- 圣丰和农场的切换 -->
			<div class="yuanqulist" :style="{ right: yuanquLeft }" v-if="tabindex == 0 || (tabindex == 2 && this.isZnMap)">
				<div
					:class="tabindexsf == index ? 'Ycactive' : ''"
					v-for="(item, index) in tablistsf"
					:key="index"
					@click="tabclksf(index)"
				>
					{{ item }}
				</div>
			</div>

			<div class="flex flex_ju_sb tabberlist">
				<div class="tabber-div" :class="{ activelist: tabindex == 3 }" @click="tabclk(3)">
					<span class="names">3D可视化</span>
				</div>
				<div
					class="tabber-div"
					@mouseenter="mouseOver(index)"
					@mouseleave="mouseLeave(index)"
					:class="{ activelist: tabindex == index }"
					v-for="(item, index) in tablist"
					:key="index"
					@click="tabclk(index)"
				>
					<span class="names">{{ item.name }}</span>
					<!-- 底部菜单 -->
					<div class="footmenus" v-if="item.menus && index == 0" :style="footHoverCss">
						<div
							class="font_18 c_white menus"
							v-for="(items, indexs) in item.menus"
							:key="indexs"
							:class="['menus' + (indexs + 1), paths.indexOf(items.paths) > -1 ? 'menus-active' : '']"
							@click.stop="tonengguan(items, index)"
						>
							{{ items.name }}
						</div>
						<img class="hover-icon" src="@/assets/img/homeMon/hover-icon.png" alt="" />
					</div>
					<div class="footmenus" v-else-if="item.menus && index == 2" :style="footHoverCss">
						<div
							class="font_18 c_white menus"
							v-for="(items, indexs) in item.menus"
							:key="indexs"
							:class="['menus2', tabindex == 2 && isZnMap ? 'menus-active' : '']"
							@click.stop="tonengguan(items, index)"
						>
							{{ items.name }}
						</div>
						<img class="hover-icon" src="@/assets/img/homeMon/hover-icon.png" alt="" />
					</div>
				</div>
			</div>

			<!-- <img class="header-img" src="../assets/img/footer.png" alt="" />  -->
		</div>
	</div>
</template>

<script>
import bus from '../api/bus.js'
import { mapState, mapMutations } from 'vuex'
export default {
	name: 'footers',

	data() {
		return {
			tabindex: 0,
			tabindexsf: 0,
			// tablist: ['园区总览', '监测监管', '智脑中心'],
			tablistsf: ['农场', '圣丰'],
			tablist: [
				// {
				// 	name: '3D可视化',
				// },
				{
					name: '园区总览',
					menus: [
						{
							name: '能管中心',
							paths: 'HomeEnergy',
						},
						{
							name: '设备资产',
							paths: 'HomeEquip',
						},
						{
							name: '异常运营',
							paths: 'HomeAbnormal',
						},
					],
				},
				{
					name: '监测监管',
				},
				{
					name: '智脑中心',
					menus: [
						{
							name: '热力图',
							paths: 'HomeBrain',
							iscen: true,
						},
					],
				},
			],
			footHoverCss: {
				width: '0px',
				height: '0px',
				top: '10px',
				left: '32%',
			},
		}
	},
	mounted() {
		console.log(this.$route.path)
		let thisPaths = this.$route.path
		if (thisPaths == '' || thisPaths == '/' || thisPaths == '/home') {
			this.tabindexsf = 0
			this.tabindex = 0
		} else if (thisPaths == '/HomeBrain') {
			this.tabindex = 2
		} else if (thisPaths == '/HomeProduction' || thisPaths == '/HomeMon') {
			this.tabindex = 1
		} else if (thisPaths == '/visual') {
			this.tabindex = 3
		} else {
			this.tabindex = 0
		}
	},
	watch: {
		$route: 'getPaths',
	},
	created() {},
	computed: {
		...mapState(['isZnMap']),
		paths() {
			console.log(this.$route.path)
			return this.$route.path
		},
		yuanquLeft() {
			if (this.isZnMap) {
				return '890px'
			} else {
				return '1715px'
			}
		},
	},
	methods: {
		...mapMutations(['setZnMap']),
		getPaths() {
			let thisPaths = this.$route.path
			if (thisPaths == '' || thisPaths == '/' || thisPaths == '/home') {
				this.tabindex = 0
			} else if (thisPaths == '/HomeBrain') {
				this.tabindex = 2
			} else if (thisPaths == '/HomeProduction' || thisPaths == '/HomeMon') {
				this.tabindex = 1
			} else if (thisPaths == '/visual') {
				this.tabindex = 3
			} else {
				this.tabindex = 0
			}
		},
		tabclk(index) {
			let oldTabindex = this.tabindex
			this.tabindex = index
			this.setZnMap(false)
			switch (index) {
				case 0:
					if (oldTabindex != 0) {
						this.tabindexsf = 0
					}
					// this.$emit('startTime')

					this.$router.push({
						name: 'home',
					})
					break
				case 1:
					bus.$emit('clearTime')
					// this.$emit('totabclick', 'HomeMon')
					this.$router.push({
						name: 'HomeProduction',
					})
					// this.$emit('totabclick', 'HomeProduction')
					break
				case 2:
					bus.$emit('clearTime')
					// this.$emit('totabclick', 'HomeBrain')
					bus.$emit('changeZnMap', false)
					this.$router.push({
						name: 'HomeBrain',
					})

					break
				case 3:
					bus.$emit('clearTime')
					  window.open(
					    "http://124.223.46.38:8089",
					    "_blank"
					  );
					// this.$emit('totabclick', 'HomeBrain')
					// this.$router.push({
					// 	name: 'visual',
					// })
					// window.location.href =
					//   "http://124.223.46.38:8089",
					//   "_blank";

					break
				default:
					break
			}
		},
		// 农场和圣丰的切换
		tabclksf(index) {
			this.tabindexsf = index
			// this.$emit('totabclicksf', index)
			bus.$emit('totabclicksf', index)
		},
		tonengguan({ paths, iscen }, index) {
			// 防止的是智脑中心二次点击
			if (this.tabindex == index && index == 2 && this.isZnMap) {
				return
			}
			if (index == 2) {
				this.tabindexsf = 0
				this.setZnMap(true)
				bus.$emit('changeZnMap', false)
			} else {
				this.setZnMap(false)
				if (index == 0 && this.tabindex != index) {
					this.tabindexsf = 0
				}
			}
			this.tabindex = index
			if (this.paths.indexOf(paths) > -1) {
				return
			}
			this.$router.push({
				name: paths,
			})
		},
		// 鼠标移入事件
		mouseOver(index) {
			if (!this.tablist[index]['menus']) {
				return
			}
			this.$nextTick(() => {
				this.footHoverCss = {
					width: '337px',
					height: '140px',
					top: '-128px',
					left: '-30%',
				}
			})
		},
		mouseLeave(index) {
			if (!this.tablist[index]['menus']) {
				return
			}
			setTimeout(() => {
				this.footHoverCss = {
					width: '0px',
					height: '0px',
					top: '10px',
					left: '32%',
				}
			}, 300)
		},
	},
}
</script>

<style lang="less" scoped>
.screen-footers {
	width: 5092px;
	height: 135px;
	// position: fixed;
	// top: 0;
	// left: 0;
	text-align: center;
	position: absolute;
	z-index: 9;
	bottom: 0;
	left: 0;
	.screen-header {
		width: 4639px;
		height: 135px;
		margin: 0 auto;
		background: url('../assets/img/footer.png') no-repeat;
		background-size: 100% 100%;
		position: relative;
		z-index: 4;

		.yuanqulist {
			width: 85px;
			position: absolute;
			top: -153px;
			right: 1715px;
			div {
				width: 85px;
				height: 42px;
				line-height: 42px;
				background: url('../assets/img/homeMon/btnbg_n.png') no-repeat;
				background-size: 100% 100%;
				font-size: 20px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #4daac2;
				cursor: pointer;
				margin-top: 20px;
				transition: all 0.3s;
			}

			.Ycactive {
				background: url('../assets/img/homeMon/btnbg_h.png') no-repeat;
				background-size: 100% 100%;
				color: #3cfffd;
			}
			div:hover {
				background: url('../assets/img/homeMon/btnbg_h.png') no-repeat;
				background-size: 100% 100%;
				color: #3cfffd;
			}
		}

		.tabberlist {
			width: 800px;
			margin: 0 auto;
			padding: 34px 0 0 0;

			.tabber-div {
				width: 200px;
				height: 45px;
				line-height: 45px;

				font-size: 26px;
				font-weight: bold;
				color: #999999;
				cursor: pointer;
				position: relative;
			}

			.activelist {
				.names {
					text-shadow: 0px 0px 8px #fff, 0px 0px 42px #0090ff, 0px 0px 72px #0090ff, 0px 0px 150px #2ebdff;
					color: #ffffff;
				}
			}
			.footmenus {
				// width: 333px;
				// height: 130px;
				// top: -133px;
				// position: absolute;
				// left: -29%;
				overflow: hidden;
				// background-color: #fff;
				position: absolute;
				// width: 20px;
				// height: 1px;
				// top: 0px;

				// left: 35%;
				.menus {
					width: 97px;
					height: 97px;
					position: absolute;
					background: url('../assets/img/homeMon/menu_bgn.png') no-repeat;
					background-size: 100% 100%;
					padding: 26px 28px 0;
					line-height: 1.3;
					font-weight: lighter;
					transition: all 0.4s;
					z-index: 3;
					opacity: 0;
					&:hover {
						background: url('../assets/img/homeMon/menu_bgh.png') no-repeat;
						background-size: 100% 100%;
					}
				}
				.menus-active {
					background: url('../assets/img/homeMon/menu_bgh.png') no-repeat;
					background-size: 100% 100%;
				}
				.menus1 {
					left: 113px;
					bottom: -100px;
				}
				.menus2 {
					left: 113px;
					bottom: -100px;
				}
				.menus3 {
					right: 123px;
					bottom: -100px;
				}
				.hover-icon {
					width: 35px;
					height: 21px;
					position: absolute;
					bottom: 10px;
					left: 42.5%;
					opacity: 0;
				}
			}
			.tabber-div:hover {
				.names {
					transition: 0.3s;
					text-shadow: 0px 0px 8px #fff, 0px 0px 42px #0090ff, 0px 0px 72px #0090ff, 0px 0px 150px #2ebdff;
					color: #ffffff;
				}
				.menus1 {
					left: 0;
					bottom: 13px;
					opacity: 1;
				}
				.menus2 {
					left: 113px;
					bottom: 38px;
					opacity: 1;
				}
				.menus3 {
					right: 12px;
					bottom: 13px;
					opacity: 1;
				}
				.hover-icon {
					opacity: 1;
				}
			}
		}
	}
}
</style>
