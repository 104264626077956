<template>
  <div>
    <div class="line2" ref="lineRef"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chartInstane: null,
    };
  },
  mounted() {
    this.echartLine();
  },
  methods: {
    // 中间市场价格折线图
    echartLine() {
      const _this = this;
      let myChart = this.$echarts.init(this.$refs.lineRef);
      let xLabel = [
        "2015年",
        "2016年",
        "2017年",
        "2018年",
        "2019年",
        "2020年",
        "2021年",
      ];
      let yData = [75, 100, 110, 125, 180, 175, 180, 200];
      var option = {
        title: {
          text: "产量（公斤/亩)",
          textStyle: {
            color: "#fff",
            fontSize: 16,
            fontWeight: 200,
          },
        },
        color: ["#00FCFF", "#7888FF"],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgb(53, 53, 53, 0.51)",
          borderWidth: 0,
          textStyle: {
            fontSize: 15,
            color: "#AED6FF",
          },
          formatter(params) {
            return `<div style="font-size:18px;">${params[0].axisValue}</div>
											<div style="font-size:16px;margin-top:9px;">产量:${params[0].value}公斤/亩</div>`;
          },
        },
        legend: {
          show: false,
        },
        grid: {
          top: "18%",
          left: "4%",
          right: "0%",
          bottom: "16%",
        },
        xAxis: {
          type: "category",
          boundaryGap: false, //一定要给x轴设置,与前面紧挨着(紧挨边缘)
          axisLine: {
            //坐标轴轴线相关设置。数学上的x轴
            show: false,  	//是否显示坐标轴轴线。
          },
          axisLabel: {
            //坐标轴刻度标签的相关设置
            textStyle: {
              fontSize: 14,
              color: "rgba(150, 204, 251, 0.7)",
            },
          },
		  //是否显示分隔线。默认数值轴显示，类目轴不显示
          splitLine: {
            show: false,
          },
		  //是否显示坐标轴刻度。
          axisTick: {
            show: true,
            inside: true,//坐标轴刻度是否朝内，默认朝外。
            lineStyle: {
              color: "#192a44",
            },
          },
          data: xLabel,
        },
        yAxis: {
          min: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#192a44",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#192a44",
            },
          },
          axisLabel: {
            show: true,
            color: "rgba(150, 204, 251, 0.7)",
          },
          axisTick: {
            show: true,
          },
        },
        series: [
          {
            type: "line",
            symbol: "none", // 默认是空心圆（中间是白色的）.设置为none不显示
            // showAllSymbol: true,
			//标记的大小
            symbolSize: 10,
            smooth: true,
            label: {
              show: true, //是否显示标签。
              position: "top", //标签的位置。
              textStyle: {
                color: "#00AEFF",
              },
            },
			//折线拐点标志的样式。
            itemStyle: {
              color: "#00AEFF",
              borderColor: "#fff",
              borderWidth: 2,
            },
            //线条
            lineStyle: {
              color: "#00AEFF", // 线条颜色
              type: "solid",
            },
            tooltip: {
              show: true,
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,

                  [
                    {
                      offset: 0,
                      color: "rgba(0, 174, 255, 1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0, 174, 255, 0.02)",
                    },
                  ],
                  false
                ),
              },
            },
            data: yData,
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped>
.line2 {
  width: 850px;
  height: 230px;
}
</style>
