<template>
  <div class="homeindex">
    <!--header-->
    <Topheader @changeMoney="changeMoney"></Topheader>
    <div class="flex flex_ju_sb home">
      <!-- 左边 -->
      <div class="flex lefts">
        <div class="left animate__animated animate__fadeInLeft">
          <!--物联网设备用电情况-->
          <div class="c_white font_22 slide-titles flex flex_ju_l">
            <img src="../assets/img/homeEnergy/icon2_1.png" />
            <div class="fm_pm slide-title">物联网设备用电情况</div>
          </div>
          <div class="LfContTop flex flex_ju_sb">
            <leftTop />
          </div>
          <div class="oil">
            <img
              src="../assets/img/homeEnergy/b1.png"
              alt=""
              style="width: 30px; height: 30px"
            />
            <span>本月总耗电：</span>
            <span>455.04</span>
            <span>度</span>
          </div>
          <!--核心区域能耗情况-->
          <div class="c_white font_22 slide-titles flex flex_ju_l">
            <img src="../assets/img/homeEnergy/icon2_2.png" />
            <div class="fm_pm slide-title">核心区域能耗情况</div>
          </div>
          <div>
            <empty
              v-if="
                !energyConsum ||
                !energyConsum.xAxis ||
                energyConsum.xAxis.length == 0
              "
            />
            <leftMiddle v-else :data="energyConsum" />
          </div>
          <!--缴费通知-->
          <div class="c_white font_22 slide-titles flex flex_ju_l">
            <img src="../assets/img/homeEnergy/icon2_3.png" />
            <div class="fm_pm slide-title">缴费通知</div>
          </div>
          <div class="LfContBottom">
            <!--放育种成效echarts图-->
            <div class="Ritable">
              <div class="table_th flex flex_ju_sb">
                <div>序号</div>
                <div>用户姓名</div>
                <div>水费(元)</div>
                <div>电费(元)</div>
                <div>燃气费(元)</div>
              </div>
              <div class="table_tdcont">
                <empty v-if="NoteList.length === 0" />

                <div v-else class="scroll-list">
                  {{ NoteList.length }}
                  <vue-seamless-scroll
                    class="seamless-warp"
                    :class-option="classOption"
                    :data="NoteList"
                  >
                    <div
                      class="table_td flex flex_ju_sb"
                      v-for="(item, index) in NoteList"
                      :key="index"
                    >
                      <div>{{ index + 1 }}</div>
                      <div>
                        <a
                          href="javascript:void(0);"
                          :title="item.companyName | isempty"
                          >{{ item.companyName | isempty }}</a
                        >
                      </div>
                      <div
                        :class="item.waterFee === '未交费' ? 'text-red' : ''"
                      >
                        <a
                          href="javascript:void(0);"
                          :title="item.waterFee | isempty"
                          >{{ item.waterFee | isempty }}</a
                        >
                      </div>
                      <div :class="item.elecFee === '未交费' ? 'text-red' : ''">
                        <a
                          href="javascript:void(0);"
                          :title="item.elecFee | isempty"
                          >{{ item.elecFee | isempty }}</a
                        >
                      </div>
                      <div :class="item.gasFee === '未交费' ? 'text-red' : ''">
                        <a
                          href="javascript:void(0);"
                          :title="item.gasFee | isempty"
                          >{{ item.gasFee | isempty }}</a
                        >
                      </div>
                    </div>
                  </vue-seamless-scroll>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 左边资金投入数据，组件封装 -->
        <!-- <left-second></left-second> -->
      </div>
      <!-- 中间 -->
      <div
        class="center flex flex_ju_c"
        :class="isShowMoney ? 'center-show' : ''"
      >
        <!-- <div class="CenCont flex flex_ju_sb"> -->
        <!--预警提示-->
        <!-- <div class="CenCLeft">
					<EarlyWarningList></EarlyWarningList>
				</div> -->
        <!--中间区域总览数据-->
        <div class="CenCCont flex flex_ju_sb">
          <div class="CenCCList">
            <p>{{ NoteFindOne.charging }}</p>
            <p class="flex flex_ju_l">
              <img src="../assets/img/home/icon1_10.png" />累计充电桩(个)
            </p>
          </div>
          <div class="CenCCList">
            <p>{{ NoteFindOne.waterConsum }}</p>
            <p class="flex flex_ju_l">
              <img src="../assets/img/home/icon1_10.png" />本月用水(吨)
            </p>
          </div>
          <div class="CenCCList">
            <p>{{ NoteFindOne.elecConsum }}</p>
            <p class="flex flex_ju_l">
              <img src="../assets/img/home/icon1_10.png" />本月用电(Kw·h)
            </p>
          </div>
          <div class="CenCCList">
            <p>{{ NoteFindOne.gasConsum }}</p>
            <p class="flex flex_ju_l">
              <img src="../assets/img/home/icon1_10.png" />本月燃气(Nm³）
            </p>
          </div>
        </div>
        <!--右侧数据-->
        <!-- <div class="CenCright"> -->
        <!--方位标-->
        <!-- <div class="CenCposition flex flex_ju_c color03FFEC">
							N<img src="../assets/img/home/fangwei.png" />
						</div> -->
        <!-- <div class="CenCbutlist">
						<div class="CenCbutlt" @click="tonengguan('home')">总览</div>
						<div class="CenCbutlt CenCbutlAc">能管中心</div>
						<div class="CenCbutlt" @click="tonengguan('HomeEquip')">设备资产</div>
						<div class="CenCbutlt" @click="tonengguan('HomeAbnormal')">异常运营</div>
					</div>
				</div> -->
        <!-- </div> -->
      </div>
      <!-- 右边 -->
      <!-- 右边 -->
      <div class="flex rights">
        <!-- 右中实时数据 -->
        <div style="width: 990px"></div>
        <!-- <right-second></right-second> -->
        <div class="right animate__animated animate__fadeInRight">
          <!--大豆种业-->
          <div class="c_white font_22 slide-titles flex flex_ju_l">
            <img src="../assets/img/homeEnergy/icon2_4.png" />
            <div class="fm_pm slide-title">园区用水总耗</div>
          </div>
          <div class="RiContTop">
            <empty
              v-if="
                !parkConsump ||
                !parkConsump.xAxis ||
                parkConsump.xAxis.length == 0
              "
            />
            <rightTop_ v-else :data="parkConsump" />
          </div>
          <!--种子价格-->
          <div class="c_white font_22 slide-titles flex flex_ju_l">
            <img src="../assets/img/homeEnergy/icon2_5.png" />
            <div class="fm_pm slide-title">园区用电总耗</div>
          </div>
          <div class="Ricity flex flex_ju_sb">
            <empty
              v-if="
                !parkConsump ||
                !parkConsump.xAxis ||
                parkConsump.xAxis.length == 0
              "
            />
            <rightMiddle v-else :data="parkConsump" />
          </div>

          <!--大豆销售网络-->
          <div class="c_white font_22 slide-titles flex flex_ju_l">
            <img src="../assets/img/homeEnergy/icon2_6.png" />
            <div class="fm_pm slide-title">园区燃气总耗</div>
          </div>
          <div class="Ribottom flex flex_ju_sb">
            <empty
              v-if="
                !parkConsump ||
                !parkConsump.xAxis ||
                parkConsump.xAxis.length == 0
              "
            />
            <rightBottom v-else :data="parkConsump" />
          </div>
        </div>
      </div>
    </div>
    <!--底部-->
    <!-- <Botfooter @totabclick="tabclick" :tabindex="tabindex" :tabindexsf="tabindexsf" @totabclicksf="tabclicksf">
		</Botfooter> -->
  </div>
</template>

<script>
import Topheader from "../components/header.vue";
import Botfooter from "../components/footers.vue";
import { regionDataPlus } from "element-china-area-data";
import leftTop from "./EnergyComponent/leftTop.vue";
import leftMiddle from "./EnergyComponent/leftMiddle.vue";
import rightTop_ from "./EnergyComponent/rightTop_.vue";
import rightMiddle from "./EnergyComponent/rightMiddle.vue";
import rightBottom from "./EnergyComponent/rightBottom.vue";
import Empty from "./empty.vue";
import EarlyWarningList from "../components/EarlyWarningList.vue";
import LeftSecond from "./HomeComponent/LeftSecond.vue";
import RightSecond from "./HomeComponent/RightSecond.vue";
import api from "@/api/api";
export default {
  mixins: [],
  data() {
    return {
      classOption: {
        step: 0.7, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
      year: "",
      month: "",
      energyConsum: {}, // 核心区域能耗
      parkConsump: {}, // 园区用水、电、气总耗
      NoteList: [], // 缴费通知
      curPage: 1,
      limit: 10,
      NoteFindOne: {}, // 头部数字
      cityname1: "",
      cityname2: "",
      cityname3: "",
      cityname1list: regionDataPlus,
      cityname2list: [],
      cityname3list: [],
      //预警
      warningList: [
        "预计今天夜里12点到凌晨三点将有七级大风，请注意防范",
        "某某某区域，光缆断裂，请相关工作人员及时处理",
        "预警内容展示预警内容展示预警内容展示预警内容展示预警内容展示",
      ],

      tabindexsf: 0,
      tabindex: 0,
      options: regionDataPlus,
      selectedOptions: ["370000", "370800", "370829"],
      datatime: "",
      isShowMoney: false, // 是否显示上面的金额数据
    };
  },
  components: {
    Topheader,
    Botfooter,
    leftTop,
    leftMiddle,
    rightTop_,
    rightMiddle,
    rightBottom,
    Empty,
    EarlyWarningList,
    LeftSecond,
    RightSecond,
  },
  mounted() {
    this.year = new Date().getFullYear();
    let month = new Date().getMonth() + 1;
    this.month = this.year + "-" + (month < 10 ? "0" + month : month);
    this.getNoteFind(this.month);
    this.getEneryConsum(this.year);
    this.getParkConsump(this.year);
    this.getNoteList({ page: this.curPage, limit: this.limit });
  },
  filters: {
    isempty(value) {
      if (!value) {
        return "暂无";
      } else {
        return value;
      }
    },
  },
  methods: {
    // 上面的金额是否显示
    changeMoney() {
      this.isShowMoney = !this.isShowMoney;
    },
    getNoteFind(month) {
      this.$http
        .post(api.debitNoteFindOne, {
          month,
        })
        .then((res) => {
          console.log("头部四个字", res.data.data);
          let { data } = res.data;
          this.NoteFindOne = data;
        });
    },
    getEneryConsum(year) {
      this.$http
        .post(api.energyConsumption, {
          year,
        })
        .then((res) => {
          console.log("核心区域能耗", res.data.data);
          let { data } = res.data;
          this.energyConsum = data;
        });
    },
    getNoteList(pageData) {
      this.$http.post(api.debitNote, pageData).then((res) => {
        console.log("缴费通知", res.data.data);
        let { data } = res.data;
        this.NoteList = data;
      });
    },
    getParkConsump(year) {
      this.$http
        .post(api.parkConsumption, {
          year,
        })
        .then((res) => {
          console.log("园区用水、电、气能耗", res.data.data);
          let { data } = res.data;
          this.parkConsump = data;
        });
    },
    //跳转
    tonengguan(url) {
      this.$router.push({
        name: url,
      });
    },
    tabclick(e) {
      this.$router.push({
        name: e,
      });
    },
    tabclicksf(e) {
      this.tabindexsf = e;
      console.log(e);
    },

    handleChange(value) {
      console.log(value);
      this.cityname2list = [];
      this.cityname2 = "";
      this.cityname3list = [];
      this.cityname3 = "";
      this.cityname1list.forEach((item, index) => {
        console.log(value == item.value);
        if (value == item.value) {
          this.cityname2list = this.cityname1list[index].children;
          console.log(this.cityname2list);
        }
      });
    },
    handleChange2(value) {
      console.log(value);
      this.cityname3list = [];
      this.cityname3 = "";
      this.cityname2list.forEach((item, index) => {
        console.log(value == item.value);
        if (value == item.value) {
          this.cityname3list = this.cityname2list[index].children;
          console.log(this.cityname3list);
        }
      });
    },
    handleChange3(value) {
      console.log(value);
      /* this.cityname2='';
			 
				this.cityname3=''; */
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  height: 1080px;

  background: url("../assets/img/bgs.png") no-repeat;
  background-size: 100% 100%;

  overflow: hidden;
  margin-top: -144px;
  position: relative;

  .slide-titles {
    width: 805px;
    background: url("../assets/img/bg_title.png") no-repeat;
    background-size: 100% 100%;

    .slide-title {
      height: 66px;
      line-height: 66px;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
    }

    img {
      width: 50px;
      height: 50px;
      margin-top: 8px;
    }
  }
  .lefts {
    width: 1840px;
  }
  .left {
    padding: 77px 0 0 50px;
    width: 888px;
    background: url("../assets/img/home/bg_left.png") no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    // position: fixed;
    // top: 63px;
    z-index: 10;
    position: relative;
    animation-duration: 2s;
    animation-timing-function: linear;
    .LfContTop {
      padding: 10px 15px;

      img {
        width: 210px;
        height: 232px;
      }

      div {
        height: 230px;
        padding-left: 26px;
        font-size: 17px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        line-height: 30px;
        padding: 10px;
        overflow: auto;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 7;
      }
      div::-webkit-scrollbar {
        width: 5px;
      }

      div::-webkit-scrollbar-track {
        background-color: #10445b;

        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }

      div::-webkit-scrollbar-thumb {
        background-color: #01756b;
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }
    }
    .oil {
      position: absolute;
      top: 17%;
      right: 8%;
      display: flex;
      align-items: center;

      span {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
      }
      span:nth-child(2) {
        margin-left: 10px;
      }
      span:nth-child(3) {
        color: #1ac1ff;
        font-size: 22px;
        margin: 0 5px;
      }
    }
    .LfContCent {
      padding: 10px 0;

      .LCCleft {
        width: 198px;
        height: 198px;
        background: url("../assets/img/home/img1_4.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        padding: 46px;

        p {
          padding: 5px 0;
        }

        p:nth-child(1) {
          font-size: 27px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #2ebdff;
        }

        p:nth-child(2) {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2cb5f5;
        }

        p:nth-child(3) {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
        }
      }

      .LCCright {
        width: 600px;
        padding: 10px 0 10px 20px;

        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          padding: 12px 0;

          span {
            height: 15px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            padding-left: 10px;
          }
        }

        .lccrBg {
          position: relative;
          height: 12px;
          margin: 0 0 14px 0;

          .lccrbgTop1 {
            height: 12px;
            border-radius: 6px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            opacity: 0.36;
            width: 100%;
          }

          .lccrbgTop2 {
            position: absolute;
            top: 0;
            left: 0;
            width: 502px;
            height: 12px;
            border-radius: 6px;
            z-index: 2;

            img {
              position: absolute;
              top: -16px;
              right: -17px;
            }
          }
        }
      }
    }
    .LfContBottom {
      margin: 10px 0 0 0;
      .Ritable {
        width: 805px;
        .table_th {
          div {
            background: url("../assets/img/home/taber_th.png") no-repeat;
            background-size: 100% 100%;
            font-size: 15px;
            font-weight: bold;
            color: #ffffff;
            line-height: 21px;
            padding: 5px 10px;
            width: 19%;
            text-align: center;
          }
        }
        .table_tdcont {
          height: 250px;
          // overflow-y: auto;
          overflow: hidden;
          position: relative;
          .table_td {
            margin: 10px 0;
            div {
              height: 27px;
              line-height: 27px;
              background: rgba(2, 31, 70, 0.5);
              font-size: 15px;
              font-weight: bold;
              color: #ffffff;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 19%;
            }
            a {
              cursor: initial;
            }
            .text-red {
              color: red;
            }
          }
        }

        .table_tdcont > .scroll-list {
          //        position: absolute;
          //        top: 0;
          //        left: 0;
          //        width: 100%;

          // animation: scroll 10s linear infinite normal;
          &:hover {
            // animation-play-state:paused;
            // -webkit-animation-play-state:paused;
          }
        }

        .table_tdcont > .scroll-list > .table_td {
          /* 滚动的项目需要有具体的高度 */
          // height: 30px;
        }
        @keyframes scroll {
          100% {
            /* 需要滚动内容的总高度 */
            top: -300px;
          }
        }

        .table_tdcont::-webkit-scrollbar {
          width: 5px;
        }

        .table_tdcont::-webkit-scrollbar-track {
          background-color: #10445b;

          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
        }

        .table_tdcont::-webkit-scrollbar-thumb {
          background-color: #01756b;
          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
        }
      }
      .Ribottom {
        position: relative;
        .ritbotleft {
          width: 310px;
          height: 210px;
        }
        .ritbotright {
          width: 480px;
          height: 210px;
        }
        .ritbotcont {
          width: 213px;
          height: 96px;
          position: absolute;
          top: 0;
          right: 0px;
          background: url("../assets/img/home/yuan.png") no-repeat;
          background-size: 100% 100%;
          font-size: 17px;
          font-weight: bold;
          color: #80d9fe;
          padding: 10px 20px;
          line-height: 24px;
        }
      }
    }
  }

  // 中间
  .center {
    // background-color: rgba(255, 255, 255, 0.2);
    // height: 1080px;
    // width: 3300px;
    height: 136px;
    flex: 1;
    position: relative;
    z-index: 5;
    top: -50px;
    opacity: 0;
    transition: all 1s;
    // .CenCont {
    //   padding: 120px 70px;

    // background-color: rgba(255, 255, 255, 0.5);
    &.center-show {
      top: 120px;
      opacity: 1;
    }
    .CenCLeft {
      width: 600px;
      position: absolute;
      top: 0;
      left: 70px;
    }
    .CenCCont {
      height: 136px;
      width: 1122px;
      background: url("../assets/img/home/img1_9.png") no-repeat;
      background-size: 100% 100%;
      padding: 0 90px;
      margin-top: 20px;
      .CenCCList {
        padding: 22px 0;
        p:nth-child(1) {
          font-size: 50px;
          font-family: Bahnschrift;
          font-weight: 400;
          color: #ffffff;
          margin-left: 20px;
        }
        p:nth-child(2) {
          font-size: 20px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #ffffff;
          line-height: 34px;
        }
      }
    }
    .CenCright {
      width: 590px;
      height: 136px;
      position: absolute;
      top: 148px;
      right: 70px;
      .CenCposition {
        height: 136px;
        line-height: 70px;
        width: 590px;
        img {
          height: 70px;
          width: 70px;
          margin-left: 4px;
        }
      }

      .CenCbutlist {
        // margin-top: 190px;
        width: 590px;
        .CenCbutlt {
          margin: 40px 0 0 275px;
          height: 85px;
          line-height: 85px;
          width: 220px;
          text-align: center;
          background: url("../assets/img/home/butbg.png") no-repeat;
          font-size: 24px;
          font-family: HuXiaoBo-NanShen;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
        .CenCbutlAc {
          background: url("../assets/img/home/butbg_ac.png") no-repeat;
          color: #0fe6ff;
        }
        .CenCbutlt:hover {
          transition: 0.3s;
          background: url("../assets/img/home/butbg_ac.png") no-repeat;
          color: #0fe6ff;
        }
      }
    }
    // }
  }

  // 右边
  .right {
    padding: 77px 50px 0 0;
    width: 858px;
    background: url("../assets/img/home/bg_right.png") no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    // position: fixed;
    // top: 63px;
    position: relative;
    z-index: 10;
    animation-duration: 2s;
    animation-timing-function: linear;
    .RiContTop {
      margin: -15px 0 0 0;
      .RiContBotCont {
        width: 808px;
        height: 98px;
        background: rgba(6, 18, 66, 0.4);
        border: 1px solid #005992;
        p {
          width: 804px;
          height: 97px;
          padding: 4px 20px;
          line-height: 30px;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          span {
            color: #2ebdff;
          }
          overflow: auto;
        }

        p::-webkit-scrollbar {
          width: 5px;
        }

        p::-webkit-scrollbar-track {
          background-color: #10445b;

          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
        }

        p::-webkit-scrollbar-thumb {
          background-color: #01756b;
          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
        }
      }
      .RiContBotContBot {
        padding: 10px 0;

        .Rileft {
          .lefttop {
            margin-bottom: 10px;
            .eachZw {
              height: 72px;
              width: 72px;
            }
            .LfTpConten {
              padding: 5px 10px;
              p {
                font-weight: bold;
                padding: 5px 0;
              }
              span {
                font-weight: bold;
                color: #ffffff;
              }
              p:nth-child(1) {
                font-size: 16px;
                color: #ffffff;
                span {
                  font-size: 12px;
                }
              }
              p:nth-child(2) {
                font-size: 26px;
                span {
                  font-size: 16px;
                  padding-left: 10px;
                }
              }
            }
          }
        }
        .Ricenter {
          position: relative;
          width: 170px;

          .RicenYuan {
            position: absolute;
            height: 80px;
            width: 80px;
            background: url("../assets/img/home/icon_dzimg.png") no-repeat;
            background-size: 100% 100%;
            line-height: 80px;
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            text-align: center;
          }
          .wz1 {
            top: 0;
            right: 0;
            width: 94px;
            height: 94px;
            line-height: 94px;
          }
          .wz2 {
            bottom: 20px;
            left: 10px;
            width: 89px;
            height: 89px;
            line-height: 89px;
          }
          .wz3 {
            bottom: 0px;
            left: 96px;
          }
          .wz4 {
            top: 0;
            left: 0;
          }
          .wz5 {
            top: 20px;
            left: 96px;
            width: 70px;
            height: 70px;
            line-height: 70px;
          }
          .wz6 {
            top: 100px;
            left: 17px;
            width: 55px;
            height: 55px;
            line-height: 55px;
          }
          .wz7 {
            top: 100px;
            right: 17px;
            width: 64px;
            height: 64px;
            line-height: 64px;
          }
          .wz8 {
            top: 100px;
            left: 100px;
          }
        }
      }
    }
    .Ricity {
      margin-top: -15px !important;
      .RiCtTit {
        height: 47px;
        line-height: 47px;
        font-weight: bold;
        font-size: 16px;
        color: #24c4f5;
        padding-right: 10px;
      }
      .city1 {
        height: 47px;
        width: 135px;
        line-height: 47px;
        text-align: center;
        background: url("../assets/img/home/input.png") no-repeat;
        background-size: 100% 100%;
        .citytit {
          height: 47px;
          line-height: 47px;
          cursor: pointer;
          font-size: 16px;
          font-family: Source Han Sans CN;
          // font-weight: bold;
          color: #24c4f5;
          position: relative;
          padding-left: 20px;
          img {
            width: 11px;
            height: 9px;
            position: absolute;
            right: 16px;
            top: 20px;
          }
        }
      }
    }
    .Ritable {
      .table_th {
        div {
          background: url("../assets/img/home/taber_th.png") no-repeat;
          background-size: 100% 100%;
          font-size: 15px;
          font-weight: bold;
          color: #ffffff;
          line-height: 21px;
          padding: 5px 10px;

          text-align: center;
        }
        div:nth-child(1) {
          width: 150px;
        }
        div:nth-child(2) {
          width: 117px;
        }
        div:nth-child(3) {
          width: 100px;
        }
        div:nth-child(4) {
          width: 100px;
        }
        div:nth-child(5) {
          width: 180px;
        }
        div:nth-child(6) {
          width: 130px;
        }
      }
      .table_tdcont {
        height: 200px;
        // overflow-y: auto;
        overflow: hidden;
        position: relative;
        .table_td {
          margin: 10px 0;
          div {
            height: 27px;
            line-height: 27px;
            background: rgba(0, 0, 0, 0.1);
            font-size: 15px;
            font-weight: bold;
            color: #ffffff;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          div:nth-child(1) {
            width: 150px;
          }
          div:nth-child(2) {
            width: 117px;
          }
          div:nth-child(3) {
            width: 100px;
          }
          div:nth-child(4) {
            width: 100px;
          }
          div:nth-child(5) {
            width: 180px;
          }
          div:nth-child(6) {
            width: 130px;
          }
        }
      }

      .table_tdcont > .scroll-list {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        animation: scroll 10s linear infinite normal;
      }

      .table_tdcont > .scroll-list > .table_td {
        /* 滚动的项目需要有具体的高度 */
        height: 30px;
      }
      @keyframes scroll {
        100% {
          /* 需要滚动内容的总高度 */
          top: -600px;
        }
      }

      .table_tdcont::-webkit-scrollbar {
        width: 5px;
      }

      .table_tdcont::-webkit-scrollbar-track {
        background-color: #10445b;

        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }

      .table_tdcont::-webkit-scrollbar-thumb {
        background-color: #01756b;
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }
    }
    .Ribottom {
      margin-top: -15px !important;
      position: relative;
      .ritbotleft {
        width: 310px;
        height: 210px;
      }
      .ritbotright {
        width: 480px;
        height: 210px;
      }
      .ritbotcont {
        width: 213px;
        height: 96px;
        position: absolute;
        top: 0;
        right: 0px;
        background: url("../assets/img/home/yuan.png") no-repeat;
        background-size: 100% 100%;
        font-size: 17px;
        font-weight: bold;
        color: #80d9fe;
        padding: 10px 20px;
        line-height: 24px;
      }
    }
  }
  //公共颜色
  .bcolor0090FF {
    color: #0090ff;
    background: #0090ff;
  }

  .bcolor2EBDFF {
    color: #2ebdff;
    background: #2ebdff;
  }

  .bcolor03FFEC {
    color: #03ffec;
    background: #03ffec;
  }

  .bcolorFDFF2E {
    color: #fdff2e;
    background: #fdff2e;
  }

  .color0090FF {
    color: #0090ff;
  }

  .color2EBDFF {
    color: #2ebdff;
  }

  .color03FFEC {
    color: #03ffec;
  }
  .colorFDFF2E {
    color: #fdff2e;
  }
}
</style>
