<template>
	<div>
		<div class="pie" id="pieRef"></div>
	</div>
</template>
<script>
import HighCharts from 'highcharts'
export default {
	data() {
		return {}
	},
	mounted() {
		this.initPie()
	},
	methods: {
		initPie() {
			let colors = ['#08aa9c', '#d6d963', '#0452d7', '#8994F1']
			// 传入数据生成 option
			let data = [
				{
					name: '气象监测',
					value: 30,
					y: 30,
				},
				{
					name: '墒情监测',
					value: 25,
					y: 25,
				},
				{
					name: '水肥设备',
					value: 15,
					y: 15,
				},
				{
					name: '虫情监测',
					value: 30,
					y: 30,
				},
			]
			let option = {
				credits: {
					enabled: false, //不显示LOGO
				},
				chart: {
					type: 'pie',
					backgroundColor: 'rgba(0, 0, 0, 0)',
					options3d: {
						enabled: true,
						alpha: 68,
						viewDistance: 80,
						beta: 0,
					},
				},
				title: {
					text: '',
				},
				tooltip: {
					enabled: true,
					backgroundColor: 'rgba(255,255,255,0.3)',
					borderWidth: 0,
					shadow: false,
					style: {
						color: '#fff',
						fontSize: 16,
					},
					formatter: function () {
						let percent = this.point.percentage.toFixed(0)
						return `${this.point.name}：${percent}%`
					},
				},
				legend: {
					align: 'right',
					verticalAlign: 'center',
					floating: true,
					x: 62,
					y: 70,
					width: 300,
					navigation: {
						activeColor: '#fff',
						animation: true,
						arrowSize: 12,
						inactiveColor: '#333',
						style: {
							fontWeight: 'bold',
							color: '#fff',
							fontSize: '12px',
						},
					},
					itemMarginTop: 0,
					itemMarginBottom: 40,
					symbolPadding: 20,
					itemStyle: {
						color: '#fff',
						fontSize: 16,
					},
					itemHoverStyle: {
						color: '#fff',
					},
				},
				labels: {
					color: '#fff',
				},
				plotOptions: {
					pie: {
						size: '150%',
						center: ['30%', '50%'],
						// allowPointSelect: true,
						innerSize: '85%',
						cursor: 'pointer',
						depth: 25,
						colors: ['#08aa9c', '#d6d963', '#0452d7', '#8994F1'],
						showInLegend: true,
						// states:{
						// 	inactive:{
						// 		opacity:1
						// 	}
						// },
						dataLabels: {
							enabled: true,
							formatter: function (index) {
								let percent = this.point.percentage.toFixed(0)
								return `${percent}%`
							},
							style: {
								//样式配置
								textOutline: 'none', //去掉文字白边
								color: '#fff',
								fontSize: 14,
							},
						},
					},
				},
				series: [
					{
						data: data,
					},
				],
			}
			HighCharts.chart('pieRef', option)
		},
	},
}
</script>
<style lang="scss" scoped>
.pie {
	width: 750px;
	height: 200px;
}
</style>
