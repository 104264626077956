<template>
	<div class="content">
		<!--m3u8视频-->
		<div class="videojs showvideo" id="videoall">
			<!-- 视频容器 -->
			<video ref="videoPlayer" id="videoopen" muted></video>
			<!-- 下面的按钮 -->
			<div class="videom3btns flex flex_ju_sb">
				<div class="vidjsleft">
					<i
						v-if="videoIsPlay"
						class="el-icon-video-pause"
						@click="makeVideo"
					></i>
					<i v-else class="el-icon-video-play" @click="makeVideo"></i>
				</div>
				<div class="contros">
					<!-- <span v-if="jingyinnum" title="关闭声音" @click="yinliangFun" style="vertical-align: top">
						<svg
							t="1590414410633"
							class="icon"
							viewBox="0 0 1024 1024"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							p-id="20545"
							width="16"
							height="16"
						>
							<path
								d="M840.533333 98.133333c-17.066667-17.066667-42.666667-17.066667-59.733333 0-17.066667 17.066667-17.066667 42.666667 0 59.733334C883.2 256 938.666667 392.533333 938.666667 533.333333c0 140.8-55.466667 277.333333-157.866667 375.466667-17.066667 17.066667-17.066667 42.666667 0 59.733333 8.533333 8.533333 21.333333 12.8 29.866667 12.8 8.533333 0 21.333333-4.266667 29.866666-12.8 115.2-110.933333 183.466667-268.8 183.466667-435.2 0-166.4-68.266667-324.266667-183.466667-435.2zM571.733333 12.8c-17.066667-8.533333-34.133333-4.266667-46.933333 8.533333L281.6 256H42.666667c-25.6 0-42.666667 17.066667-42.666667 42.666667v426.666666c0 25.6 17.066667 42.666667 42.666667 42.666667h238.933333l243.2 234.666667c8.533333 8.533333 17.066667 12.8 29.866667 12.8 4.266667 0 12.8 0 17.066666-4.266667 17.066667-8.533333 25.6-21.333333 25.6-38.4V51.2c0-17.066667-8.533333-34.133333-25.6-38.4zM512 870.4l-183.466667-179.2c-8.533333-4.266667-17.066667-8.533333-29.866666-8.533333H85.333333V341.333333h213.333334c12.8 0 21.333333-4.266667 29.866666-12.8L512 153.6v716.8z"
								p-id="20546"
								fill="#ffffff"
							></path>
							<path
								d="M759.466667 349.866667c-12.8-21.333333-38.4-25.6-59.733334-8.533334-21.333333 12.8-25.6 38.4-8.533333 59.733334 21.333333 29.866667 34.133333 76.8 34.133333 123.733333 0 46.933333-12.8 93.866667-34.133333 123.733333-12.8 21.333333-8.533333 46.933333 8.533333 59.733334 8.533333 4.266667 17.066667 8.533333 25.6 8.533333 12.8 0 25.6-4.266667 34.133334-17.066667 34.133333-46.933333 51.2-106.666667 51.2-174.933333 0-68.266667-17.066667-128-51.2-174.933333z"
								p-id="20547"
								fill="#ffffff"
							></path>
						</svg>
					</span>
					<span v-else @click="yinliangFun" id="ezuikit-close-sound" title="打开声音" style="vertical-align: top">
						<svg
							t="1590476263239"
							class="icon"
							viewBox="0 0 1178 1024"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							p-id="2273"
							width="16"
							height="16"
						>
							<path
								d="M665.6 51.2 665.6 51.2c-10.24-30.72-40.96-51.2-71.68-51.2-5.12 0-15.36 0-20.48 5.12l0 0L358.4 153.6 51.2 209.92l0 0C20.48 220.16 0 250.88 0 281.6 0 286.72 0 291.84 0 307.2l0 0 0 409.6 0 0c0 15.36 0 20.48 0 25.6 0 30.72 20.48 61.44 51.2 71.68l0 0L358.4 870.4l97.28 71.68 107.52 76.8 0 0c5.12 5.12 15.36 5.12 25.6 5.12 40.96 0 76.8-35.84 76.8-76.8 0-10.24 0-10.24 0-25.6l0 0L665.6 51.2zM563.2 870.4l-153.6-102.4-307.2-51.2L102.4 307.2l307.2-51.2 153.6-102.4L563.2 870.4z"
								p-id="2274"
								fill="#FF0000"
							></path>
							<path
								d="M1049.6 537.6l112.64-112.64c20.48-20.48 20.48-56.32 0-76.8-20.48-20.48-56.32-20.48-76.8 0L972.8 460.8l-112.64-112.64c0 0 0 0 0 0-20.48-20.48-56.32-20.48-76.8 0 0 0 0 0 0 0-20.48 20.48-20.48 56.32 0 76.8l112.64 112.64-112.64 112.64c-20.48 20.48-20.48 56.32 0 76.8 20.48 20.48 56.32 20.48 76.8 0L972.8 614.4l112.64 112.64c20.48 20.48 56.32 20.48 76.8 0s20.48-56.32 0-76.8L1049.6 537.6z"
								p-id="2275"
								fill="#FF0000"
							></path>
						</svg>
					</span> -->
					<!-- <span title="全屏" style="vertical-align: top" @click="toFullScreen">
						<svg
							id="fullScreen"
							t="1578020167938"
							class="icon"
							viewBox="0 0 1024 1024"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							p-id="5035"
							width="16"
							height="16"
						>
							<path
								d="M348.373333 625.706667l-128 128-64 64v-128a33.28 33.28 0 0 0-35.413333-35.413334A33.493333 33.493333 0 0 0 85.333333 689.706667v213.333333A33.706667 33.706667 0 0 0 120.96 938.666667h213.333333a35.626667 35.626667 0 0 0 0-71.04h-128l64-64 128-128a35.2 35.2 0 0 0-49.92-49.92zM206.293333 156.373333h128a33.28 33.28 0 0 0 35.413334-35.413333A33.493333 33.493333 0 0 0 334.293333 85.333333H113.706667c-7.04 0-14.08 7.04-21.333334 14.293334a26.026667 26.026667 0 0 0-7.04 21.333333v213.333333a33.493333 33.493333 0 0 0 35.626667 35.413334 33.28 33.28 0 0 0 35.413333-35.413334v-128l192 192a35.2 35.2 0 0 0 49.92-49.92zM903.04 85.333333h-213.333333a33.493333 33.493333 0 0 0-35.413334 35.626667 33.28 33.28 0 0 0 35.413334 35.413333h128l-64 64-128 128a35.2 35.2 0 0 0 49.92 49.92l128-128 64-64v128a35.626667 35.626667 0 0 0 71.04 0v-213.333333A33.706667 33.706667 0 0 0 903.04 85.333333zM903.04 654.293333a33.28 33.28 0 0 0-35.413333 35.413334v128l-64-64-128-128a35.2 35.2 0 0 0-49.92 49.92l128 128 64 64h-128a35.626667 35.626667 0 0 0 0 71.04h213.333333A33.706667 33.706667 0 0 0 938.666667 903.04v-213.333333a33.493333 33.493333 0 0 0-35.626667-35.413334z"
								p-id="5036"
								fill="#ffffff"
							></path>
						</svg>
					</span> -->
				</div>
			</div>
		</div>
		<div class="zhanweibg" :class="showvideoplay == 2 ? 'showvideo' : 'hidevideo'">
			<img src="../../assets/img/noplay.png" />
			<div>抱歉，该视频暂不可播放，请切换其他视频或稍后重试。</div>
		</div>
	</div>
</template>

<script>
import video from '../../api/videos'

export default {
	mixins: [video],
	props: {
		videoWidth: { type: Number },
		videoHeight: { type: Number },
	},
	components: {},
	data() {
		return {
			showvideoplay: 0, // 控制使用的播放器  0-萤石;1-videojs
			//萤石变量
			jingyinnum: true,

			videoIsPlay: true, // 视频是否是播放状态
			videoUrl: '',
			videoPlayer: null,
			videoId: 'videoopen', // 指定容器id
			equipId: '', // 设备/视频流id
			isGroup: false,
			isNeedHide: false, // 全屏的时候视频是否需要隐藏，主要用作下面视频的显示隐藏
		}
	},
	created() {},
	computed: {},
	mounted() {
		// 萤石接入
		// this.getToken()
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.listenResize)
		document.removeEventListener('scroll', this.listenScroll)
		this.closeVideo()
	},
	methods: {
		/**
		 * @description 父级调用播放并传入参数
		 * @params type 0:视频是切换 否则是新创建的
		 */
		handelVideoUrl(data, type) {
			let urlVal = data.value
			// let urlVal = '17bb2c8c8c8b45cabe351b9d90ab0d4e'
			this.equipId = urlVal
			if (type == 0) {
				// 视频切换预览
				this.$nextTick(() => {
					this.changeVideo()
				})
			} else {
				// 视频创建视频初始化
				this.$nextTick(() => {
					this.initVideo()
				})
			}
		},
		//静音
		yinliangFun(val) {
			// if (this.jingyinnum) {
			// 	this.videoPlayer.volume(0)
			// } else {
			// 	this.videoPlayer.volume(1)
			// }
			// this.jingyinnum = !this.jingyinnum
		},
	},
}
</script>
<style lang="scss" scoped>
.showvideo {
	display: block !important;
}
.hidevideo {
	display: none !important;
}
.zhanweibg {
	height: 330px;
	width: 1100px;
	background: #000;
	text-align: center;
	padding: 110px 0;
	img {
		margin: 0 auto;
	}
	div {
		text-align: center;
		height: 16px;
		font-size: 16px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #ffffff;
		padding-top: 16px;
	}
}
.videojs {
	position: relative;
	.videom3btns {
		width: 100%;
		height: 48px;
		background: #3d3d3d;
		user-select: none;
		position: absolute;
		top: 284px;
		text-align: left;
		color: #fff;
		font-size: 30px;
		.vidjsleft {
			width: 60px;
			cursor: pointer;
			text-align: center;
			padding: 9px 0;
		}
		.contros {
			text-align: center;
			width: 80px;
			cursor: pointer;
			padding: 0px;
			span {
				margin: 0 10px;
			}
		}
	}
}

#videoopen {
	width: 100%;
	height: 280px;
	cursor: pointer;
}
.video-js {
	cursor: pointer;
}

// 内容
.content {
	.videoBox {
		position: relative;
		overflow: hidden;
		.videobtns {
			position: absolute;
			z-index: 200;
			// background-color: red;
			bottom: 8px;
			left: 30px;
			cursor: pointer;
			color: #fff;
			font-size: 30px;
		}
		.videobtns2 {
			position: absolute;
			z-index: 200;
			background: #3d3d3d;
			// background-color: red;
			bottom: 0;
			right: 0;
			cursor: pointer;
			color: #fff;
			font-size: 30px;
			width: 40px;
			text-align: center;
			height: 47px;
			svg {
				margin-bottom: 3px;
			}
		}
		// 防止双击全屏的蒙层
		.dbmb {
			position: absolute;
			z-index: 50;
			width: 932px;
			height: 558px;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0);
		}
	}
}

// 视频全屏样式
.video-full {
	position: absolute;
	width: 2560px;
	height: calc(100vh + 90px);
	top: -90px;
	left: 0;
	z-index: 250;
	#bigscreen {
		width: 2560px;
		height: 1122;
	}
	.full-control {
		position: absolute;
		z-index: 100;
		width: 100%;
		height: 48px;
		bottom: 0;
		left: 0;
		background-color: #333;
		padding: 0 20px;
	}
	.screen-closebtn {
		width: 40px;
		height: 40px;
		cursor: pointer;
	}
	.videobtns {
		cursor: pointer;
		color: #fff;
		font-size: 30px;
	}
}

.el-select-dropdown.waterlog {
	width: 150px !important;
	min-width: 0 !important;
	background-color: #024242 !important;
	border: none !important;
	box-shadow: 0px 0px 10px 5px rgba(13, 174, 164, 1) inset !important;
	padding: 10px 0 !important;
}
.waterlog .el-select-dropdown__item {
	color: #fff !important;
}
.waterlog .el-select-dropdown__item.hover,
.waterlog .el-select-dropdown__item:hover {
	background-color: rgba(52, 200, 255, 0.3) !important;
	color: #fff !important;
}
.waterlog .el-select-dropdown__item.selected {
	color: #2bf7ff !important;
}
.el-popper[x-placement^='bottom'] .popper__arrow::after {
	border-bottom-color: #2bf7ff !important;
}
.el-popper[x-placement^='bottom'] .popper__arrow {
	border-bottom-color: #2bf7ff !important;
}

// 时间选择样式
.el-picker-panel {
	background-color: #024242 !important;
	border: none !important;
	box-shadow: 0px 0px 10px 5px rgba(13, 174, 164, 1) inset !important;
	color: #fff !important;
}
.el-picker-panel__icon-btn {
	color: #fff !important;
}
.el-date-table th {
	color: #fff !important;
}
.el-date-table td.in-range div {
	background-color: rgba(52, 200, 255, 0.3);
}
.el-date-table td.in-range div {
	background-color: rgba(52, 200, 255, 0.3) !important;
}
.el-date-table td.start-date span,
.el-date-table td.end-date span {
	background-color: rgba(13, 174, 164, 1) !important;
}
.el-time-panel {
	left: -23px !important;
}
.el-date-table td.today span {
	color: #0daea4 !important;
	&:hover {
		color: #0daea4 !important;
	}
}
</style>
