<template>
	<div class="visual">
		<iframe src="http://124.223.46.38:8089/" frameborder="0"></iframe>
	</div>
</template>

<script>
// 3D可视化页面
export default {}
</script>

<style lang="scss">
.visual {
	width: 100%;
	height: 100%;
	iframe {
		width: 100%;
		height: 100%;
	}
}
</style>
