<template>
	<div>
		<div class="line2" ref="lineRef"></div>
	</div>
</template>

<script>
import { loopShowTooltip } from '../../util/loop-tooltip'

export default {
	props: {
		data: {
			type: Object,
			requied: true,
		},
	},
	data() {
		return {
			chartInstane: null,
			tooltipTimer: null,
		}
	},
	mounted() {
		this.echartLine()
	},
	watch: {
		data: {
			handler() {
				this.tooltipTimer && this.tooltipTimer.clearLoop()
				this.echartLine()
			},
			deep: true,
		},
	},
	destroyed() {
		this.tooltipTimer && this.tooltipTimer.clearLoop()
	},
	methods: {
		// 中间市场价格折线图
		echartLine() {
			const _this = this
			if (!this.data || !this.data.xAxis || !this.data.series || this.data.series.length < 3) {
				return
			}
			let data = JSON.parse(JSON.stringify(this.data))
			let seriesData = data.series.slice(0, 3)
			let myChart = this.$echarts.getInstanceByDom(this.$refs.lineRef)
			if (myChart == null) {
				myChart = this.$echarts.init(this.$refs.lineRef)
			}
			let xLabel = data.xAxis
			let yData = seriesData[0].data
			let zData = seriesData[1].data
			let dData = seriesData[2].data
			let colors = ['#71C8FF', '#27FCFF', '#FFD554']
			let rgbs = ['113,200,255', '39,252,255', '255,213,84']
			let unitArr = [
				{ name: '产品数量', unit: '个' },
				{ name: '溯源码生成数', unit: '个' },
				{ name: '溯源码扫码数', unit: '次' },
			]
			let SelectedData = {},
				LengData = [],
				YaData = [],
				SeriesData = [],
				DataInfo = [],
				option = {}

			seriesData.forEach((item, index) => {
				SelectedData[item.name] = true
				LengData.push(item.name)
				DataInfo.push({
					type: 'line',
					name: item.name,
					symbol: 'none',
					symbolSize: 10,
					yAxisIndex: item.name !== '溯源码扫码数' ? 0 : 1,
					smooth: true,
					//线条
					lineStyle: {
						color: colors[index], // 线条颜色
						type: 'solid',
					},
					areaStyle: {
						//区域填充样式
						normal: {
							//线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
							color: new this.$echarts.graphic.LinearGradient(
								0,
								0,
								0,
								1,
								[
									{
										offset: 0,
										color: 'rgba(' + rgbs[index] + ', 0.6)',
									},
									{
										offset: 0.5,
										color: 'rgba(' + rgbs[index] + ', 0.4)',
									},
									{
										offset: 1,
										color: 'rgba(' + rgbs[index] + ', 0.2)',
									},
								],
								false
							),
						},
					},
					data: item.data,
				})
			})
			let max1 = yData.reduce((a, b) => (+a > +b ? a : b))
			let max2 = zData.reduce((a, b) => (+a > +b ? a : b))
			let max3 = dData.reduce((a, b) => (+a > +b ? a : b))
			let max4 = [max1, max2].reduce((a, b) => (a > b ? a : b))

			let arr = [max4, max3]
			arr = arr.map((item) => {
				if (item == 0) {
					item = 1
				}
				let num0 = Math.ceil(item / 100) * 100
				let num = num0 / 5
				return {
					interval: Math.ceil(num),
					max: num0,
				}
			})

			function Init(sel, dataInfo) {
				SelectedData = sel || {}
				;(YaData = []), (SeriesData = [])
				let Datas = JSON.parse(JSON.stringify(dataInfo))
				for (let n = 0, l = LengData.length; n < l; n++) {
					let name = LengData[n]
					let [unitObj] = unitArr.filter((unit) => unit.name == name)
					let ymax = name == '溯源码扫码数' ? arr[1] : arr[0]
					// 如果该图例状态为false时，则跳过 不push
					// let flag = sel[name] &&
					if (sel[name]) {
						YaData.push({
							type: 'value',
							name: LengData[n],
							min: 0,
							minInterval: 1,
							max: ymax.max,
							splitNumber: 5,
							interval: ymax.interval,
							position: name == '溯源码扫码数' ? 'right' : 'left',
							name: `(${unitObj.unit})`,
							nameTextStyle: {
								color: '#fff',
								fontSize: 16,
								padding: name == '溯源码扫码数' ? [0, 0, 0, 50] : [0, 50, 0, 0],
							},
							splitLine: {
								show: true,
								lineStyle: {
									color: 'rgba(0,90,198,.2)',
									width: 3,
								},
							},
							axisLabel: {
								show: true,
								color: '#fff',
								fontSize: 16,
							},
							axisTick: {
								show: false,
							},
						})
					} else {
						Datas[n].data = []
					}
					Datas[n].yAxisIndex = name == '溯源码扫码数' ? (YaData.length > 0 ? YaData.length - 1 : 0) : 0
					SeriesData.push(Datas[n])
				}
				if (YaData.length == 0) {
					YaData = [{ type: 'value' }]
				}
				option = {
					color: colors,
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgb(113,200,255, 0.28)',
						borderWidth: 0,
						textStyle: {
							fontSize: 15,
							color: '#AED6FF',
						},
						axisPointer: {
							type: 'line',
							lineStyle: {
								color: '#3371FC',
							},
						},
						position: (pos, param, dom, rect, size) => {
							var obj = { top: 60 }
							let halfContent = size.viewSize[0] / 2
							if (pos[0] <= halfContent) {
								obj['left'] = pos[0]
							} else if (pos[0] > halfContent) {
								obj['right'] = size.viewSize[0] - pos[0]
							}
							return obj
						},
						formatter(params) {
							let result = ''
							if (params.length > 0) {
								params.forEach((item) => {
									let name = item.seriesName
									let [unitObj] = unitArr.filter((unit) => unit.name == name)
									if (name.indexOf('溯源码') != -1) {
										name = name.slice(3)
									} else {
										name = name.slice(0, 3)
									}
									result += `<div>${name}：${item.value}${unitObj.unit}</div>`
								})
							}
							return result
						},
					},
					grid: {
						top: '18%',
						left: '10%',
						right: '8%',
						bottom: '16%',
					},
					legend: {
						data: LengData,
						selected: SelectedData,
						show: true,
						icon: 'circle',
						top: '1%',
						textStyle: {
							color: '#fff',
							fontSize: 16,
						},
						itemStyle: {
							borderWidth: 0,
						},
					},
					xAxis: [
						{
							type: 'category',
							boundaryGap: false, //一定要给x轴设置,与前面紧挨着(紧挨边缘)
							axisLine: {
								//坐标轴轴线相关设置。数学上的x轴
								show: false,
							},
							axisLabel: {
								align: 'center',
								interval: 0,
								//坐标轴刻度标签的相关设置
								textStyle: {
									fontSize: 16,
									color: '#fff',
								},
							},
							splitLine: {
								show: false,
							},
							axisTick: {
								show: true,
								inside: true,
								lineStyle: {
									color: '#192a44',
								},
							},
							data: xLabel,
						},
					],
					yAxis: YaData,
					series: SeriesData,
				}
			}
			Init(SelectedData, DataInfo)
			if (option && typeof option === 'object') {
				myChart.setOption(option, true)
				this.tooltipTimer && this.tooltipTimer.clearLoop()
				this.tooltipTimer = 0
				this.tooltipTimer = loopShowTooltip(myChart, option, {
					interval: 3000, // 轮播间隔时间
					loopSeries: true, // 是否开启轮播循环
					dataLength: xLabel.length,
				})
			} else {
				// this.tooltipTimer && this.tooltipTimer.clearLoop();
				this.tooltipTimer = 0
			}
			/* 选中图例 */
			myChart.on('legendselectchanged', function (params) {
				// 得到当前的图例显示隐藏状态分别有哪些
				SelectedData = params.selected
				let serIndex = 0
				Object.values(SelectedData).forEach((item, index) => {
					if (item) {
						serIndex = index
					}
				})
				Init(SelectedData, DataInfo)
				console.log(option)
				if (option && typeof option === 'object') {
					myChart.setOption(option, true)
					_this.tooltipTimer && _this.tooltipTimer.clearLoop()
					_this.tooltipTimer = 0
					_this.tooltipTimer = loopShowTooltip(myChart, option, {
						interval: 3000, // 轮播间隔时间
						loopSeries: true, // 是否开启轮播循环
						dataLength: xLabel.length,
						seriesIndex: serIndex,
						dataIndex: -1,
					})
				}
			})
		},
	},
}
</script>
<style lang="less" scoped>
.line2 {
	width: 610px;
	height: 190px;
}
</style>
