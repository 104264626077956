<template>
  <div>
    <div ref="LineRef" class="line"></div>
  </div>
</template>
<script>
import {loopShowTooltip} from '../../util/loop-tooltip'
export default{
    props:{
        // date:String,
        data:{
            type:Object,
            required:true
        }
    },
  data(){
    return{
      tooltipTimer:null
    }
  },
  mounted () {
    this.initLine()
  },
  watch:{
      data:{
          handler(){
              this.initLine()
          },
          deep:true
      }
  },
  destroyed () {
    this.tooltipTimer && this.tooltipTimer.clearLoop();
  },
  methods: {
    initLine() {
        if(!this.data || !this.data.xAxis || this.data.xAxis.length == 0){
            return;
        }
        let data = JSON.parse(JSON.stringify(this.data))
        let myChart = this.$echarts.init(this.$refs.LineRef)
        let xLabel= data.xAxis
        let seriesData = data.series
        let maxArr = [];
        seriesData.forEach(item=>{
            let max = item.data.reduce((a,b)=>a>b ? a : b)
            maxArr.push(max)
        })
        let maxF = maxArr.reduce((a,b)=>a>b ? a : b)
        const grid = {
            left:"12%",
            top:"23%",
            bottom:"30%",
            right:"0%"
        }
        // legend
        const legend = {
            selectedMode:false,
            orient: "horizontal",
            itemGap: 15,
            textStyle:{
                color:"#fff",
                fontSize:14
            }
        }
        // tooltip
        const tooltip = {
            show:true,
            backgroundColor: 'rgb(53, 53, 53, 0.51)',
            trigger:"axis",
            borderWidth:0,
            textStyle:{
                color:"#fff",
                fontSize:14,
                fontWeight:400
            },
            axisPointer:{
                type:"line",
                lineStyle:{
                    color:"#3371FC"
                }
            },
            position: (pos,param,dom, rect, size)=>{
            var obj = {top: 40};
            let halfContent = size.viewSize[0] / 2;
            if(pos[0] <= halfContent) {
              obj['left'] = pos[0]
            } else if(pos[0] >halfContent) {
              obj['right'] = size.viewSize[0] - pos[0]
            }
            return obj;
          },
            formatter: (params) => {
                let result = '';
                let dates = data.date.split('-');
                let newDate = dates[1]+'月'+dates[2]+'日'
                if(params.length > 0){
                    result +=`<div style="font-size:15px">${newDate}${params[0].name}</div>`
                    params.forEach(item=>{
                        if(!item.seriesName){
                            return
                        }
                        result += `<div style="line-height:20px;font-size:15px">${item.seriesName}：${item.value}次</div>`
                    })
                }
                return result;
            },
        }
        // xAxis
        const xAxis = { 
            axisTick: { show: false },
            axisLine: { lineStyle: { color: 'rgba(255,255,255, .2)' } },
            axisLabel: { textStyle: { fontSize: 14, color: 'rgba(255,255,255, .5)'  }, },
            data: xLabel
        }

        // yAxis
        const yAxis = { 
            name:"（次）",
            nameTextStyle:{
                color:"rgba(255,255,255,.5)",
                padding:[0,35,0,0]
            },
            minInterval:1,
            // max:maxF*1.2,
            // interval:
            axisTick: { show: false },
            axisLine: { show: false, },
            splitLine: { lineStyle: { color: 'rgba(255,255,255, .05)' } },
            axisLabel: { 
                textStyle: { 
                    fontSize: 14, 
                    color: 'rgba(255,255,255,.5)' } 
                }
        }

        // series
        const series = [
            {
                // z: 1,
                name: '',
                type: 'pictorialBar',
                symbolPosition: 'end',
                data: seriesData[0].data,
                symbol : 'diamond',
                symbolOffset: ['-75%', '-50%'],
                symbolSize: [15, 5],
                itemStyle: {
                    borderColor: '#62ACFF',
                    color: '#62ACFF'
                },
            },
            {
                // z: 1,
                type: 'bar',
                name: seriesData[0].name,
                barWidth: 15,
                barGap: '-50%',
                data: seriesData[0].data,
                itemStyle: {
                    color: {
                        type: 'linear',
                        x: 0, x2: 1, y: 0, y2: 0,
                        colorStops: [
                            { offset: 0, color: 'rgba(56,150,255, .7)' }, 
                            { offset: 0.5, color: 'rgba(56,150,255, .7)' }, 
                            { offset: 0.5, color: 'rgba(56,150,255, .3)' }, 
                            { offset: 1, color: 'rgba(56,150,255, .3)' }
                        ]
                    }
                },
            },
            {
                // z: 2,
                name: '',
                type: 'pictorialBar',
                symbolPosition: 'end',
                data: seriesData[1].data,
                symbol : 'diamond',
                symbolOffset: ['-25%', '-50%'],
                symbolSize: [15, 5],
                itemStyle: {
                    borderColor: '#32ffee',
                    color: '#32ffee'
                },
            },
            {
                // z: 2,
                type: 'bar',
                name: seriesData[1].name,
                barWidth: 15,
                data: seriesData[1].data,
                itemStyle: {
                    color: {
                        type: 'linear',
                        x: 0, x2: 1, y: 0, y2: 0,
                        colorStops: [
                            { offset: 0, color: 'rgba(57,248,255, .7)' }, 
                            { offset: 0.5, color: 'rgba(57,248,255, .7)' }, 
                            { offset: 0.5, color: 'rgba(57,248,255, .3)' }, 
                            { offset: 1, color: 'rgba(57,248,255, .3)' }
                        ]
                    }
                },
            }, 
            {
                // z: 3,
                name: '',
                type: 'pictorialBar',
                symbolPosition: 'end',
                data: seriesData[2].data,
                symbol : 'diamond',
                symbolOffset: ['25%', '-50%'],
                symbolSize: [15, 5],
                itemStyle: {
                    borderColor: '#12905C',
                    color: '#12905C'
                },
            },
            {
                // z: 3,
                type: 'bar',
                name: seriesData[2].name,
                barWidth: 15,
                data: seriesData[2].data,
                itemStyle: {
                    color: {
                        type: 'linear',
                        x: 0, x2: 1, y: 0, y2: 0,
                        colorStops: [
                            { offset: 0, color: 'rgba(28,229,146, .7)' }, 
                            { offset: 0.5, color: 'rgba(28,229,146, .7)' }, 
                            { offset: 0.5, color: 'rgba(28,229,146, .3)' }, 
                            { offset: 1, color: 'rgba(28,229,146, .3)' }
                        ]
                    }
                },
            },
            {
                // z: 4,
                name: '',
                type: 'pictorialBar',
                symbolPosition: 'end',
                data: seriesData[3].data,
                symbol : 'diamond',
                symbolOffset: ['70%', '-50%'],
                symbolSize: [15, 5],
                itemStyle: {
                    borderColor: '#ffd11a',
                    color: '#ffd11a'
                },
            },
            {
                // z: 4,
                type: 'bar',
                name: seriesData[3].name,
                barWidth: 15,
                data: seriesData[3].data,
                itemStyle: {
                    color: {
                        type: 'linear',
                        x: 0, x2: 1, y: 0, y2: 0,
                        colorStops: [
                            { offset: 0, color: 'rgba(226,186,65, .7)' }, 
                            { offset: 0.5, color: 'rgba(226,186,65, .7)' }, 
                            { offset: 0.5, color: 'rgba(226,186,65, .3)' }, 
                            { offset: 1, color: 'rgba(226,186,65, .3)' }
                        ]
                    }
                },
            },

        ]
        const option = { grid,legend,silent:true,tooltip,xAxis, yAxis, series}
        myChart.setOption(option)
        this.tooltipTimer && this.tooltipTimer.clearLoop();
        this.tooltipTimer = 0;
        this.tooltipTimer = loopShowTooltip(myChart, option, {
            interval: 2000, // 轮播间隔时间
            loopSeries: true, // 是否开启轮播循环
			dataLength:xLabel.length
        });
    }
  },
}
</script>
<style lang='scss' scoped>
.line{
  width: 430px;
	height: 220px;
}
</style>