<template>
	<div id="app">
		<cen-map class="cesiumNcMaps" v-if="isShowMap"></cen-map>
		<!-- <Botfooter @totabclick="tabclick" :tabindex="tabindex" :tabindexsf="tabindexsf" @totabclicksf="tabclicksf">
		</Botfooter> -->
		<Botfooter> </Botfooter>
		<router-view />
	</div>
</template>
<script>
import Botfooter from '@/components/footers.vue'
import { mapState } from 'vuex'
export default {
	components: {
		Botfooter,
	},
	data() {
		return {}
	},
	computed: {
		...mapState(['isZnMap']),
		// 判断是不是智脑中心页面，智脑中心中间是单独的页面
		isShowMap() {
			let paths = this.$route.path
			if (
				(paths == '/HomeBrain' && !this.isZnMap) ||
				paths == '/line' ||
				paths == '/HomeProduction' ||
				paths == '/visual'
			) {
				return false
			} else {
				return true
			}
		},
	},
	mounted() {
		window.sessionStorage.setItem('jxpaths', '/')
		// document.addEventListener('visibilitychange', function () {
		// 	console.log(document.visibilityState)
		// 	if (document.visibilityState == 'hidden') {
		// 		console.log('隐藏')
		// 	} else if (document.visibilityState == 'visible') {
		// 		console.log('显示')
		// 	}
		// })
		// window.onblur = function () {
		// 	console.log('onblur')
		// }
		// window.onfocus = function () {
		// 	console.log('onfocus ')
		// }
	},
	beforeDestroy() {
		// console.log('beforeDestroy')
	},
}
</script>

<style lang="scss">
@import './assets/fonts/font.css';
@import './assets/css/global.scss';
html ::-webkit-scrollbar-track-piece {
	background-color: transparent !important;
}
html ::-webkit-scrollbar {
	width: 0px !important;
	height: 6px !important;
}
html ::-webkit-scrollbar-track {
	background-color: #10445b !important;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius: 2em;
}

html ::-webkit-scrollbar-thumb {
	background-color: #01756b !important;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius: 2em;
}

#app {
	width: 5092px;
	height: 1080px;
	position: relative;
	// overflow-x: auto;
}
.cesiumNcMaps {
	// position: absolute;
	// width: 5092px;
	// height: 1080px;
	// top: 0;
	// left: 0;
	// z-index: 3;
}
//图片放大
.vue-directive-image-previewer {
	z-index: 99;
}
//首页修改element组件

.absde {
	.el-input__inner {
		height: 47px !important;
		background: url('assets/img/home/input2.png') no-repeat !important;
		background-size: 100% 100% !important;
		// background-color: rgba(0,0,0,0)!important;
		border: 0px solid #24c4f5 !important;
		// box-shadow:inset 0px 0px 12px #24c4f5 !important;
		color: #24c4f5 !important;
	}
	.el-date-editor .el-range__icon {
		font-size: 14px !important;
		margin-left: 0 !important;
		color: #24c4e8 !important;
		float: right !important;
		line-height: 44px !important;
	}
	.el-date-editor .el-range-separator {
		line-height: 41px !important;
	}
	.el-input__icon {
		margin-top: 4px;
	}
}
.absde88 {
	.el-input__inner {
		height: 47px !important;
		background: url('assets/img/home/input2.png') no-repeat !important;
		background-size: 100% 100% !important;
		// background-color: rgba(0,0,0,0)!important;
		border: 0px solid #24c4f5 !important;
		// box-shadow:inset 0px 0px 12px #24c4f5 !important;
		color: #24c4f5 !important;
	}
	.el-date-editor .el-range__icon {
		font-size: 14px !important;
		margin-left: 0 !important;
		color: #24c4e8 !important;
		float: right !important;
		line-height: 36px !important;
	}
	.el-date-editor .el-range-separator {
		line-height: 41px !important;
	}
	.el-input__icon {
		margin-top: 4px;
	}
}
.el-picker-panel__icon-btn {
	color: #ffffff !important;
}
.el-date-picker__header-label {
	color: #ffffff !important;
}
.absde1 {
	.el-input__inner {
		height: 47px !important;
		background: url('assets/img/home/input.png') no-repeat !important;
		background-size: 100% 100% !important;
		// background-color: rgba(0,0,0,0)!important;
		border: 0px solid #24c4f5 !important;
		// box-shadow:inset 0px 0px 12px #24c4f5 !important;
		color: #24c4f5 !important;
	}

	.el-picker-panel__icon-btn {
		color: #ffffff !important;
	}
}
.el-select-dropdown__item {
	color: #24c4f5 !important;
	font-size: 14px !important;
	font-family: Source Han Sans CN !important;
	font-weight: 400 !important;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
	background: #15acd7 !important;
	color: #ffffff !important;
}
.el-popper[x-placement^='top'] .popper__arrow::after {
	bottom: 0 !important;
	border-top-color: #15acd7 !important;
}
.el-popper[x-placement^='top'] .popper__arrow {
	border-top-color: #15acd7 !important;
}
.el-popper[x-placement^='bottom'] .popper__arrow::after {
	top: 0 !important;
	border-bottom-color: #15acd7 !important;
}
.el-popper[x-placement^='bottom'] .popper__arrow {
	border-bottom-color: #15acd7 !important;
}
.el-select-dropdown__empty,
.el-select .el-input .el-select__caret {
	color: #24c4f5 !important;
}
.el-input__prefix,
.el-input__suffix {
	color: #24c4f5 !important;
}
.el-select-dropdown {
	background: rgba(2, 31, 70, 0.9) !important;
	border: 1px solid #15acd7 !important;
	width: 110px !important;
}
.el-picker-panel {
	background: rgba(2, 31, 70, 0.9) !important;
	color: #ffffff !important;
	box-shadow: inset 0px 0px 16px 4px rgb(19, 147, 166) !important;
	border: none !important;
}
.el-range-input {
	background-color: rgba(0, 0, 0, 0) !important;
	color: #24c4f5 !important;
}
.el-date-editor .el-range-separator {
	color: #24c4f5 !important;
}
.el-date-range-picker__content.is-left {
	border-right: 1px solid rgba(36, 196, 245, 0.6) !important;
}
.el-date-table th {
	border-bottom: 1px solid rgba(36, 196, 245, 0.6) !important;
}
.el-date-table td.today span {
	color: #24c4f5 !important;
}
.el-date-table td.in-range div,
.el-date-table td.in-range div:hover,
.el-date-table.is-week-mode .el-date-table__row.current div,
.el-date-table.is-week-mode .el-date-table__row:hover div {
	background-color: rgba(29, 164, 206, 0.8) !important;
}
.el-date-table td.end-date span,
.el-date-table td.start-date span {
	background-color: #24c4f5 !important;
	color: #ffffff !important;
}
.el-date-table th {
	color: #ffffff !important;
}
//监测监管修改element组件

.el-carousel__arrow {
	color: #01c4ec !important;
	background-color: rgba(0, 197, 239, 0.11) !important;
	font-size: 16px !important;
	font-weight: bold !important;
}
.el-carousel__arrow--right {
	right: 0px !important;
}
.el-carousel__arrow--left {
	left: 0px !important;
}
.el-carousel__indicators--horizontal {
	bottom: -10px !important;
}
.el-carousel__button {
	width: 8px !important;
	height: 8px !important;
	background: rgba(1, 196, 237, 0.8) !important;
	opacity: 0.8 !important;
	border-radius: 50% !important;
}
.el-message {
	min-width: 100px !important;
}
// 修改竖向滚动条样式
body::-webkit-scrollbar {
	width: 0px;
	height: 6px;
}
body::-webkit-scrollbar-track {
	background-color: #10445b;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius: 2em;
}

body::-webkit-scrollbar-thumb {
	background-color: #01756b;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius: 2em;
}
</style>
