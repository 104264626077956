<template>
  <div>
    <div class="radar" ref="radarRef"></div>
  </div>
</template>
<script>
export default{
  props: {
    data: {
      type: Object,
      required:true 
    },
  },
  data(){
    return{}
  },
  mounted () {
    this.initRadar();
  },
  watch: {
    data:{
				handler(){
					this.initRadar();
			 	},
				deep:true
			}
  },
  methods: {
    initRadar(){
      if(!this.data || !this.data.value || this.data.value.length ===0  || !this.data.name || this.data.name.length === 0){
        return;
      }
      let indicData =[];
      let max = this.data.value.reduce((a,b)=>a>b ? a : b)
      this.data.name.forEach((item,index)=>{
        indicData.push({
          name:item,
          max:20,
          axisLabel: { 
            show:index===0 ? true : false
          }
        })
      })
      let myChart = this.$echarts.init(this.$refs.radarRef)
      const option = {
        radar: {
          center:["45%","58%"],
          radius:"70%",
          indicator: indicData,
          splitLine:{
            lineStyle:{
              color:'#2E5489'
            }
          },
          splitArea:{
            show:false
          },
          axisName:{
            fontSize:16,
            color:"#fff"
          },
          axisLine:{
            show:false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#999",
            },
          },
        },
        series: [
          {
            type: 'radar',
            color:['#9EC219'],
            areaStyle: {
              color: "rgba(158,194,25,.5)"
            },
            symbol:'none',
            lineStyle:{
              width:1
            },
            label:{
              color:"#fff"
            },
            data: [
              {
                value: this.data.value,
              },
            ]
          }
        ]
      };
      myChart.setOption(option)
    }
  }
}
</script>
<style lang='scss' scoped>
.radar{
  height: 200px;
			 		width: 330px;
}
</style>