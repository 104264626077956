<template>
	<div id="autotab">
		<!-- 表单 -->
		<div class="forms">
			<el-form ref="dsForm" :model="dsRuleForm" :rules="dsRules" label-position="left" hide-required-asterisk>
				<div class="flex flex_a_c">
					<el-form-item class="form-item3" label="施肥定时*：" prop="startTime">
						<el-input v-model="dsRuleForm.startTime"></el-input>
						<span>分钟</span>
					</el-form-item>
					<el-form-item class="form-item3" label="施肥定量*：" prop="wateringTime">
						<el-input v-model="dsRuleForm.wateringTime"></el-input>
						<span>升</span>
					</el-form-item>
				</div>
				<div class="flex flex_a_c">
					<el-form-item class="form-item3" label="灌溉定时*：" prop="startTime">
						<el-input v-model="dsRuleForm.startTime"></el-input>
						<span>分钟</span>
					</el-form-item>
					<el-form-item class="form-item3" label="搅拌定时*：" prop="wateringTime">
						<el-input v-model="dsRuleForm.wateringTime"></el-input>
						<span>分钟</span>
					</el-form-item>
				</div>

				<el-form-item class="form-btns-item elitem4">
					<div class="flex flex_ju_sa form-btns">
						<div class="btn submit" @click="submitZd('dsForm', '定时轮灌', dsRuleForm)">立即提交</div>
						<div class="btn cancel" @click="noupdata">取消</div>
						<div class="btn clear" @click="clearAll">清除数据</div>
					</div>
				</el-form-item>
			</el-form>
		</div>
		<!-- <div class="forms">
			<el-form ref="dsForm" :model="dsRuleForm" :rules="dsRules" label-position="left" hide-required-asterisk>
				<div class="flex flex_a_c">
					<el-form-item label="日期选择:" prop="weekDay" class="time-select">
						<el-select popper-class="autoselect" v-model="dsRuleForm.weekDay">
							<el-option label="周一" value="1"></el-option>
							<el-option label="周二" value="2"></el-option>
							<el-option label="周三" value="3"></el-option>
							<el-option label="周四" value="4"></el-option>
							<el-option label="周五" value="5"></el-option>
							<el-option label="周六" value="6"></el-option>
							<el-option label="周日" value="7"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item class="form-switch1" label="启动吸肥泵*:" prop="">
						<el-switch v-model="dsRuleForm.isFertilizer"></el-switch>
					</el-form-item>
				</div>
				<div class="flex flex_a_c">
					<el-form-item class="form-item3" label="灌溉开始时间*:" prop="startTime">
						<el-input v-model="dsRuleForm.startTime"></el-input>
						<span>时</span>
						<el-input v-model="dsRuleForm.Time2"></el-input>
						<span>分</span>
					</el-form-item>
					<el-form-item class="form-switch2" label="土壤传感器启用*:">
						<el-switch v-model="dsRuleForm.isSoil"></el-switch>
					</el-form-item>
				</div>
				<div class="flex">
					<div class="form-item4">
						<el-form-item label="每区灌溉时长*：" prop="wateringTime">
							<el-input v-model="dsRuleForm.wateringTime"></el-input>
							<span>分钟</span>
						</el-form-item>
						<el-form-item label="每区注肥时长*：" prop="fertilizerTime">
							<el-input v-model="dsRuleForm.fertilizerTime"></el-input>
							<span>分钟</span>
						</el-form-item>
						<el-form-item label="共有灌溉区数*：" prop="wateringNum">
							<el-input v-model="dsRuleForm.wateringNum"></el-input>
						</el-form-item>
						<el-form-item label="肥前水冲洗*：" prop="wateringBefore">
							<el-input v-model="dsRuleForm.wateringBefore"></el-input>
							<span>分钟</span>
						</el-form-item>
					</div>
					<div class="form-item5">
						<div class="flex flex_a_c">
							<div class="font_14 c_white">设置土温*</div>
							<img class="img_sxk" src="../../assets/img/water/sxk.png" alt />
							<div>
								<el-form-item label="上限:" prop="soilTempMax">
									<el-input v-model="dsRuleForm.soilTempMax"></el-input>
									<span>℃</span>
								</el-form-item>
								<el-form-item label="下限:" prop="soilTempMin">
									<el-input v-model="dsRuleForm.soilTempMin"></el-input>
									<span>℃</span>
								</el-form-item>
							</div>
						</div>
						<div class="flex flex_a_c">
							<div class="font_14 c_white">设置土湿*</div>
							<img class="img_sxk" src="../../assets/img/water/sxk.png" alt />
							<div>
								<el-form-item label="上限:" prop="soilMoistureMax">
									<el-input v-model="dsRuleForm.soilMoistureMax"></el-input>
									<span>RH%</span>
								</el-form-item>
								<el-form-item label="下限:" prop="soilMoistureMin">
									<el-input v-model="dsRuleForm.soilMoistureMin"></el-input>
									<span>RH%</span>
								</el-form-item>
							</div>
						</div>
					</div>
				</div>

				<el-form-item class="form-btns-item elitem4">
					<div class="flex flex_ju_sa form-btns">
						<div class="btn submit" @click="submitZd('dsForm', '定时轮灌', dsRuleForm)">立即提交</div>
						<div class="btn cancel" @click="noupdata">取消</div>
						<div class="btn clear" @click="clearAll">清除数据</div>
					</div>
				</el-form-item>
			</el-form>
		</div> -->
	</div>
</template>
<script>
// 自动模式切换模块
export default {
	data() {
		// 数字校验
		const validateValueMin = (rule, value, callback) => {
			console.log(value + 'jiaoyan')
			let testReg = /^\d+\.?\d{0,2}$/
			if (Number(value) <= 0 || Number(value) > 100 || !testReg.test(Number(value))) {
				callback(new Error('请输入大于0小于100的数字，最多保留两位小数')) //小数点后两位
			} else {
				callback()
			}
		}
		return {
			modeType: '0',
			tabList: [
				{
					name: '定时模式',
					modeType: '0',
				},
				{
					name: '定量模式',
					modeType: '1',
				},
				{
					name: '自动灌溉',
					modeType: '3',
				},
			],
			otherForm: {
				modeType: '0',
				equipId: '',
			},
			dsTimeVal: '', // 定时模式的时间
			// 自动模式表单数据
			dsRuleForm: {
				weekDay: '', // 日期选择
				isFertilizer: false, // 吸肥泵
				startTime: '', // 灌溉开始时间 小时
				// Time2: '', // 灌溉开始时间 分
				isSoil: false, // 土壤传感器
				wateringTime: '', // 灌溉时长
				fertilizerTime: '', // 注肥时长
				wateringNum: '', // 灌溉区数
				wateringBefore: '', // 肥前水
				soilTempMax: '', // 土温最大值
				soilTempMin: '', // 土温最小值
				soilMoistureMax: '', // 图湿最大值
				soilMoistureMin: '', // 图湿最小值
			},
			// 自定义表单的规则校验
			dsRules: {
				weekDay: [
					// 日期选择
					{ required: true, message: '请选择日期', trigger: 'change' },
				],
				isFertilizer: [], // 吸肥泵
				isSoil: [], // 土壤传感器
				startTime: [{ required: true, message: '请填写灌溉开始时间', trigger: 'blur' }], // 灌溉开始时间 小时
				wateringTime: [{ required: true, message: '请填写灌溉时长', trigger: 'blur' }], // 灌溉时长
				fertilizerTime: [{ required: true, message: '请填写注肥时长', trigger: 'blur' }], // 注肥时长
				wateringNum: [{ required: true, message: '请填写灌溉区数', trigger: 'blur' }], // 灌溉区数
				wateringBefore: [{ required: true, message: '请填写肥前水冲洗的时长', trigger: 'blur' }], // 肥前水
				soilTempMax: [{ required: true, message: '请填写土温最大值', trigger: 'blur' }], // 土温最大值
				soilTempMin: [{ required: true, message: '请填写土温最小值', trigger: 'blur' }], // 土温最小值
				soilMoistureMax: [{ required: true, message: '请填写土湿最大值', trigger: 'blur' }], // 图湿最大值
				soilMoistureMin: [{ required: true, message: '请填写土湿最小值', trigger: 'blur' }], // 图湿最小值
			},
			dlRules: {
				startDate: [
					{
						required: true,
						message: '请选择日期范围',
					},
				],
				isFertilizer1: [
					{
						required: true,
						message: '请选择时间范围',
					},
				],
			},
			dlDataTime: [], // 日期范围
			dlTimeVal: '', // 时间范围
			// 定量模式
			dlRuleForm: {
				isFertilizer1: '',
				endstartTime: '',
				startDate: '',
				endDate: '',
			},
			// 自动灌溉
			zdForm: {
				humidityValueMin: '', // 湿度最大值
				speedValue: '', // 运行速度
			},

			// 自动的校验
			zdRules: {
				humidityValueMin: [
					{
						type: 'string',
						required: true,
						trigger: 'change',
						message: '湿度最小值不能为空',
					},
					{
						validator: validateValueMin,
						trigger: 'change',
					},
				],
				speedValue: [
					{
						type: 'string',
						required: true,
						message: '请输入运行速度',
						trigger: 'change',
					},
					{
						validator: validateValueMin,
						trigger: 'change',
					},
				],
			},
		}
	},
	props: ['equipId'],
	watch: {
		equipId: {
			handler(newVal) {
				// 获取数据
				// this.getData()
			},
		},
	},
	mounted() {
		// 获取数据
		const that = this
		// this.getData()
		// window.layui.use('laydate', function () {
		//   let laydate = window.layui.laydate
		//   //定时下的时间选择
		//   laydate.render({
		//     elem: '#dsTimeVal',
		//     type: 'time',
		//     range: '至',
		//     theme: 'lay-time',
		//     done: function (val) {
		//       that.dsTimeVal = val
		//       // 03:02:03 至 23:59:59
		//       if (val && val.length > 0) {
		//         let times = val.split(' 至 ')
		//         that.dsRuleForm.isFertilizer = times[0]
		//         that.dsRuleForm.endTime = times[1]
		//       } else {
		//         that.dsRuleForm.isFertilizer = ''
		//         that.dsRuleForm.endTime = ''
		//       }
		//     },
		//   })
		//   // 定量下的时间选择
		//   laydate.render({
		//     elem: '#dlTimeVal',
		//     type: 'time',
		//     range: '至',
		//     theme: 'lay-time',
		//     done: function (val) {
		//       that.dlTimeVal = val
		//       // 03:02:03 至 23:59:59
		//       if (val && val.length > 0) {
		//         let times = val.split(' 至 ')
		//         that.dlRuleForm.isFertilizer1 = times[0]
		//         that.dlRuleForm.endstartTime = times[1]
		//       } else {
		//         that.dlRuleForm.isFertilizer1 = ''
		//         that.dlRuleForm.endstartTime = ''
		//       }
		//     },
		//   })
		// })
	},
	computed: {
		year() {
			var date = new Date()
			return date.getFullYear()
		},
		month() {
			var date = new Date()
			return date.getMonth() + 1
		},
		day() {
			var date = new Date()
			return date.getDate()
		},
	},
	methods: {
		// 数据回显
		async getData() {
			const params = {
				equipId: this.equipId,
			}
			console.log(params)
			const { data: res } = await this.$http.post('sf' + this.$api.editEquipment, params)
			if (res.code == 0) {
				this.dsRuleForm = res.data
				// let datas = res.data
				// let types = datas.modeType
				// // 默认显示的是哪个
				// if (types == '0' || types == '1' || types == '3') {
				//   this.modeType = types
				// } else {
				//   this.modeType = '0'
				// }
				// this.$set(this.otherForm, 'modeType', this.modeType)
				// this.$set(this.otherForm, 'equipId', this.equipId)
				// console.log(this.otherForm)
				// // 赋值数据
				// let regular = datas.regular
				// let ration = datas.ration
				// let autos = datas.auto
				// // 定时模式
				// if (regular) {
				//   this.dsTimeVal = regular.remark1.replace('-', '至')

				//   for (const item in this.dsRuleForm) {
				//     this.$set(this.dsRuleForm, item, regular[item])
				//   }
				// } else {
				//   this.dsTimeVal = null
				//   Object.assign(this.$data.dsRuleForm, this.$options.data().dsRuleForm)
				// }
				// // 定量模式
				// if (ration) {
				//   let dataVals = []

				//   this.dlTimeVal = `${ration.isFertilizer} 至 ${ration.endTime}`

				//   dataVals[0] = new Date(ration.startDate)
				//   dataVals[1] = new Date(ration.endDate)
				//   this.dlDataTime = dataVals

				//   for (const item in this.dlRuleForm) {
				//     this.$set(this.dlRuleForm, item, ration[item])
				//   }
				//   this.$set(this.dlRuleForm, 'isFertilizer1', ration['isFertilizer'])
				//   this.$set(this.dlRuleForm, 'endstartTime', ration['endTime'])
				// } else {
				//   this.dlDataTime = []
				//   this.dlTimeVal = null
				//   Object.assign(this.$data.dlRuleForm, this.$options.data().dlRuleForm)
				// }
				// // 自动赋值
				// if (autos) {
				//   for (const item in this.zdForm) {
				//     console.log(item)
				//     this.$set(this.zdForm, item, autos[item])
				//   }
				// } else {
				//   Object.assign(this.$data.zdForm, this.$options.data().zdForm)
				// }
			}
		},
		// 模式切换
		changeTab(index) {
			if (this.modeType == index) {
				return
			}
			this.$set(this.otherForm, 'modeType', index)
			this.modeType = index
		},
		// 自动模式定义验证,以及对表单数据的处理
		submitZd(formName, name, forms) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					let params = Object.assign({}, forms, this.otherForm)
					for (let key in params) {
						if (params[key] === false) {
							params[key] = 0
						} else if (params[key] === true) {
							params[key] = 1
						} else if (params[key].indexOf('周') != -1) {
							params[key] = params[key].replace('周', '')
						} else if (key.indexOf('wateringTime') != -1) {
							params[key] = parseInt(params[key])
						} else if (key.indexOf('fertilizerTime') != -1) {
							params[key] = parseInt(params[key])
						} else if (key.indexOf('wateringNum') != -1) {
							params[key] = parseInt(params[key])
						} else if (key.indexOf('wateringBefore') != -1) {
							params[key] = parseInt(params[key])
						}
					}
					console.log(params)
					// 提交
					// 弹框确认
					this.$confirm(`确定要提交数据吗?`, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						customClass: 'waterMsg',
					})
						.then(() => {
							this.makeSubmit(params)
						})
						.catch(() => {})
				} else {
					return false
				}
			})
		},
		// 向后台提交数据
		async makeSubmit(params) {
			console.log(params)
			// const { data: res } = await this.$http.post('/sf' + this.$api.setEquipment, params)
			// if (res.code == 200) {
			// 	this.$message(res.msg, 'success')
			// } else {
			// 	this.$message(res.msg, 'warning')
			// }
			// 重新请求数据
			// this.getData()
		},
		// 清除全部数据
		clearAll() {
			const _this = this
			this.$confirm('一键清除会清除所有选项卡内的数据，确定要清除吗', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				customClass: 'waterMsg',
			})
				.then(() => {
					_this.makeClear()
				})
				.catch(() => {})
		},
		// 请求清除接口
		async makeClear() {
			const params = {
				equipId: this.equipId,
			}
			console.log(params)
			const { data: res } = await this.$http.post('/sf' + this.$api.clearEquipSet, this.qs.stringify(params))
			if (res.code == 200) {
				// 成功以后重新请求设置页面的数据
				this.$message(res.msg, 'success')
				this.getData()
			} else {
				this.$message(res.msg, 'error')
			}
		},
		// 定时模式时间选择
		dsTimeChange(val) {
			if (val && val.length > 0) {
				this.dsRuleForm.isFertilizer = val[0]
				this.dsRuleForm.endTime = val[1]
			} else {
				this.dsRuleForm.isFertilizer = ''
				this.dsRuleForm.endTime = ''
			}
		},
		// 定量模式日期选择
		dlDataChange(val) {
			if (val && val.length > 0) {
				this.dlRuleForm.startDate = val[0]
				this.dlRuleForm.endDate = val[1]
			} else {
				this.dlRuleForm.startDate = ''
				this.dlRuleForm.endDate = ''
			}
		},
		dlTimeChange(val) {
			if (val && val.length > 0) {
				this.dlRuleForm.isFertilizer1 = val[0]
				this.dlRuleForm.endstartTime = val[1]
			} else {
				this.dlRuleForm.isFertilizer1 = ''
				this.dlRuleForm.endstartTime = ''
			}
		},
		nowTime() {
			var date = new Date()
			var year = date.getFullYear()
			var month = date.getMonth() + 1
			var strDate = date.getDate()

			var currentdate = `${year}-${month}-${strDate}`
			return currentdate
		},
		// 取消按钮
		noupdata() {
			const _this = this
			this.$confirm('确定取消更改吗', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				customClass: 'waterMsg',
			})
				.then(() => {
					this.dsRuleForm = {
						weekDay: '', // 日期选择
						isFertilizer: false, // 吸肥泵
						startTime: '', // 灌溉开始时间 小时
						Time2: '', // 灌溉开始时间 分
						isSoil: false, // 土壤传感器
						wateringTime: '', // 灌溉时长
						fertilizerTime: '', // 注肥时长
						wateringNum: '', // 灌溉区数
						wateringBefore: '', // 肥前水
						soilTempMax: '', // 土温最大值
						soilTempMin: '', // 土温最小值
						soilMoistureMax: '', // 图湿最大值
						soilMoistureMin: '', // 图湿最小值
					}
				})
				.catch(() => {})
		},
	},
}
</script>
<style lang="scss">
#autotab {
	width: 430px;
	// margin-right: -9px;
	margin-bottom: 37px;
	// 表单
	.forms {
		margin: 28px auto 0;
		.el-form-item__error {
			color: #f56c6c;
			font-size: 14px;
			line-height: 1;
			padding-top: 8px;
			position: absolute;
			top: 69%;
			left: 0;
		}
		// .time-inp {
		// 	width: 100%;
		// 	background-color: transparent;
		// 	border-width: 0;
		// 	padding: 0 11px;
		// 	height: 32px;
		// 	line-height: 32px;
		// 	color: #2bf7ff;
		// 	background-image: url('../../assets/img/water/auto_iconinpb.png');
		// 	background-size: 100% 100%;
		// 	// 去除输入框上的自清除叉号
		// 	&::-ms-clear {
		// 		display: none;
		// 	}
		// 	&:focus {
		// 		background-image: url('../../assets/img/water/auto_iconinpf.png');
		// 	}
		// }

		.el-form-item {
			margin-bottom: 32px;
			.el-form-item__label {
				color: #feffff;
				letter-spacing: 0.05rem;
				padding: 0;
				font-size: 18px;
				line-height: 42px;
			}
			.el-form-item__content {
				line-height: 42px;
				.el-input__inner {
					background-color: transparent;
					border-width: 0;
					width: 60px;
					padding: 0 11px;
					height: 42px;
					line-height: 42px;
					color: #2bf7ff;
					background-image: url('../../assets/img/water/sjk.png');
					background-size: 100% 100%;
					// &:focus {
					// 	background-image: url('../../assets/img/water/auto_iconinpf.png');
					// }
				}
			}
		}
		// 下拉选
		.time-select {
			margin-bottom: 5px;
			.el-form-item__label {
				line-height: 35px;
			}
			.el-form-item__content {
				width: 265px;
				line-height: 35px;
				.el-input__inner {
					background-color: transparent;
					border-width: 0;
					padding: 0 11px;
					width: 188px;
					height: 35px;
					line-height: 35px;
					color: #2bf7ff;
					background-image: url('../../assets/img/water/auto_iconinpf.png');
					background-size: 100% 100%;
				}
			}
		}
		// switch
		.form-switch1 {
			.el-form-item__label {
				line-height: 35px;
			}
			.el-form-item__content {
				width: 150px;
				line-height: 35px;
			}
		}
		.form-item3 {
			.el-form-item__content {
				width: 218px;
				.el-input {
					width: auto;
				}
				span {
					font-size: 16px;
					color: #feffff;
					line-height: 42px;
				}
			}
		}
		.form-item4 {
			.el-form-item__content {
				width: 240px;
				.el-input {
					width: auto;
				}
				span {
					font-size: 12px;
					color: #feffff;
				}
			}
		}
		.form-switch2 {
			.el-form-item__content {
				width: 180px;
			}
		}
		.form-item5 {
			.el-form-item__content {
				width: 114px;
				.el-input {
					width: auto;
				}
				span {
					font-size: 12px;
					color: #feffff;
				}
			}
			.img_sxk {
				width: 11px;
				height: 37px;
				margin-right: 2px;
			}
		}
		// 按钮
		.form-btns-item {
			color: #feffff;
			font-size: 16px;
			padding-top: 6px;
			margin-bottom: 0;
			.el-form-item__content {
				margin-left: 0 !important;
				width: 100%;
			}
			.btn {
				width: 90px;
				height: 36px;
				text-align: center;
				line-height: 36px;
				border-radius: 4px;
				cursor: pointer;
				font-size: 16px;
			}
			.submit {
				box-shadow: 0px 0px 10px 7px rgba(12, 123, 158, 1) inset;
			}
			.cancel {
				box-shadow: 0px 0px 10px 5px rgba(12, 123, 158, 1) inset;
			}
			.clear {
				box-shadow: 0px 0px 10px 6px rgba(160, 100, 15, 1) inset;
			}
		}
	}
}
</style>
<style lang="scss">
// .el-date-range-picker {
// 	width: 540px !important;
// }
// .el-time-range-picker {
// 	.el-time-range-picker__body {
// 		border-color: rgba(52, 200, 255, 0.3);
// 	}
// 	.el-time-spinner__item {
// 		color: #fff;
// 		&:hover {
// 			color: #0daea4;
// 			background-color: rgba(52, 200, 255, 0.3) !important;
// 		}
// 	}
// 	.el-time-panel__content::before {
// 		border-top-color: rgba(52, 200, 255, 0.3);
// 		border-bottom-color: rgba(52, 200, 255, 0.3);
// 	}
// 	.el-time-spinner__item.active:not(.disabled) {
// 		color: #0daea4;
// 	}
// 	.el-time-panel__footer {
// 		border-color: rgba(52, 200, 255, 0.3);
// 	}
// 	.el-time-panel__btn {
// 		color: #d3d3d3;
// 	}
// 	.el-time-panel__btn.confirm {
// 		color: #0daea4;
// 	}
// }
</style>
<style>
/* .el-form-item__error { */
/* top: 32px !important;
} */
</style>
