<template>
  <div class="homeindex" :class="{ 'modal-active': isInit }">
    <!--header-->
    <Topheader @changeMoney="changeMoney"></Topheader>
    <div class="flex flex_ju_sb home">
      <!-- 左边 -->
      <div class="flex lefts">
        <div class="left animate__animated animate__fadeInLeft">
          <!--产业园介绍-->
          <div class="c_white font_22 slide-titles flex flex_ju_l">
            <img src="../assets/img/home/icon1_1.png" />
            <div class="fm_pm slide-title">产业园介绍</div>
          </div>
          <div class="LfContTop">
            <empty v-if="!overViewData || !overViewData.parkDetail" />
            <el-carousel
              v-else-if="overViewData && overViewData.parkPicList"
              indicator-position="none"
            >
              <el-carousel-item
                v-for="pic in overViewData.parkPicList"
                :key="pic"
              >
                <img v-image-preview :src="pic" />
              </el-carousel-item>
            </el-carousel>
            <div
              class="park-detail"
              v-if="overViewData && overViewData.parkDetail"
            >
              {{ overViewData.parkDetail }}
            </div>
          </div>
          <!--文化长廊-->
          <div class="c_white font_22 slide-titles flex flex_ju_l">
            <img src="../assets/img/home/icon1_2.png" />
            <div class="fm_pm slide-title">文化长廊</div>
          </div>
          <!-- <div class="flex lfcon-center">

					</div> -->
          <rig-mid-right-two
            ref="cultureDiv"
            v-if="culturesList.length"
            @openDialog="openDialog"
            :lists="culturesList"
          ></rig-mid-right-two>
          <div v-else class="t_c" style="height: 231px">
            <img
              style="height: 80%; margin-top: 2%"
              src="../assets/img/none.png"
              alt=""
            />
          </div>

          <!-- <div class="LfContCent flex flex_ju_sb">
						<empty v-if="!overViewData || !overViewData.fundSum" />
						<div v-else class="LCCleft">
							<p>{{ overViewData.fundSum }}</p>
							<p>万元</p>
							<p>产业园总投资</p>
						</div>
						<div class="LCCright">
							<empty v-if="!capitalData || !capitalData.category" />
							<LeftMidRight v-else :data="capitalData" />
						</div>
					</div> -->
          <!--育种成效-->
          <div class="c_white font_22 slide-titles flex flex_ju_l">
            <img src="../assets/img/home/icon1_3.png" />
            <div class="fm_pm slide-title">育种成效</div>
          </div>
          <div class="LfContBottom">
            <div
              class="LfContBotCont"
              v-if="overViewData && overViewData.seedResource"
            >
              <p>
                拥有豆科种质资源<span>{{ overViewData.seedResource }}万</span
                >多份，审定品种<span>{{ overViewData.auditVariety }}</span
                >个，植物新品种权和发明专利<span>{{
                  overViewData.inventionPatent
                }}</span
                >多项，国家科技进步奖等成果奖<span>{{
                  overViewData.nationalaWards
                }}</span
                >项
              </p>
            </div>
            <!--放育种成效echarts图-->
            <div class="echarts">
              <empty v-if="!breedResult || breedResult.length == 0" />
              <LeftBottom v-else :data="breedResult" />
            </div>
          </div>
        </div>
        <!-- 左边资金投入数据，组件封装 -->
        <!-- <left-second></left-second> -->
      </div>
      <!-- 中间预留地图 -->
      <!-- <div class="centers"></div> -->
      <!-- 中间:地图位置留空 -->
      <div
        class="center flex flex_ju_c"
        :class="isShowMoney ? 'center-show' : ''"
      >
        <!--预警提示-->
        <!-- <div class="CenCLeft">
					<EarlyWarningList></EarlyWarningList>
				</div> -->
        <!--中间区域总览数据-->
        <div class="CenCCont flex flex_ju_sb">
          <div class="CenCCList">
            <p>{{ overViewData.totalArea }}</p>
            <p class="flex flex_ju_l">
              <img src="../assets/img/home/icon1_10.png" />园区总面积(km²)
            </p>
          </div>
          <div class="CenCCList">
            <p>{{ overViewData.countCompany }}</p>
            <p class="flex flex_ju_l">
              <img src="../assets/img/home/icon1_10.png" />入园企业(个)
            </p>
          </div>
          <div class="CenCCList">
            <p>{{ overViewData.breedYear }}</p>
            <p class="flex flex_ju_l">
              <img src="../assets/img/home/icon1_10.png" />年均繁育面积(亩)
            </p>
          </div>
          <div class="CenCCList">
            <p>{{ overViewData.jobMember }}</p>
            <p class="flex flex_ju_l">
              <img src="../assets/img/home/icon1_10.png" />带动就业人(人)
            </p>
          </div>
          <div class="CenCCList">
            <p>{{ overViewData.techMember }}</p>
            <p class="flex flex_ju_l">
              <img src="../assets/img/home/icon1_10.png" />专业技术人员(人)
            </p>
          </div>
        </div>
        <!--右侧数据-->
        <!-- <div class="CenCright"> -->
        <!--方位标-->
        <!-- <div class="CenCbutlist">
						<div class="CenCbutlt CenCbutlAc">总览</div>
						<div class="CenCbutlt" @click="tonengguan('HomeEnergy')">能管中心</div>
						<div class="CenCbutlt" @click="tonengguan('HomeEquip')">设备资产</div>
						<div class="CenCbutlt" @click="tonengguan('HomeAbnormal')">异常运营</div>
					</div>
				</div> -->
      </div>
      <!-- 右边 -->
      <div class="flex rights">
        <!-- 右中实时数据 -->
        <div style="width: 990px"></div>
        <!-- <right-second></right-second> -->
        <div class="right animate__animated animate__fadeInRight">
          <!--大豆种业-->
          <div class="c_white font_22 slide-titles flex flex_ju_l">
            <img src="../assets/img/home/icon1_4.png" />
            <div class="fm_pm slide-title">大豆种业</div>
          </div>
          <div class="RiContTop">
            <div class="RiContBotCont" v-if="overViewData.basicProfile">
              <p v-html="overViewData.basicProfile"></p>
            </div>
            <div class="RiContBotContBot flex flex_ju_sb">
              <div class="Rileft">
                <div class="lefttop flex flex_ju_sb">
                  <!--图表 占位-->
                  <div class="eachZw">
                    <WaveChart :colors="['#EA6C32', '#F4EC6D']" />
                  </div>
                  <div class="LfTpConten">
                    <p>外地客商<span>（累计吸引）</span></p>
                    <p class="colorFDFF2E">
                      {{ overViewData.handNum }}<span>人</span>
                    </p>
                  </div>
                </div>
                <div class="lefttop flex flex_ju_sb">
                  <!--图表 占位-->
                  <div class="eachZw">
                    <WaveChart />
                  </div>
                  <div class="LfTpConten">
                    <p>观摩活动<span>（累计观摩）</span></p>
                    <p class="color2EBDFF">
                      {{ overViewData.nextNum }}<span>次</span>
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="Ricenter"
                v-if="overViewData && overViewData.typeNameList"
              >
                <div class="RicenYuan wz1" v-if="overViewData.typeNameList[0]">
                  {{ overViewData.typeNameList[0] }}
                </div>
                <div class="RicenYuan wz2" v-if="overViewData.typeNameList[1]">
                  {{ overViewData.typeNameList[1] }}
                </div>
                <!-- <div class="RicenYuan wz3" v-if="overViewData.typeNameList[2]">
                  {{ overViewData.typeNameList[2] }}
                </div> -->
              </div>
              <div class="">
                <!-- <img src="../assets/img/home/icon_dz.png" /> -->
                <img src="../assets/img/home/icon_dz2.png" />
              </div>
              <div
                class="Ricenter"
                v-if="overViewData && overViewData.typeNameList"
              >
                <div class="RicenYuan wz3" v-if="overViewData.typeNameList[2]">
                  {{ overViewData.typeNameList[2] }}
                </div>
                <div class="RicenYuan wz4" v-if="overViewData.typeNameList[3]">
                  {{ overViewData.typeNameList[3] }}
                </div>
                <div class="RicenYuan wz5" v-if="overViewData.typeNameList[4]">
                  {{ overViewData.typeNameList[4] }}
                </div>
                <div class="RicenYuan wz6" v-if="overViewData.typeNameList[5]">
                  {{ overViewData.typeNameList[5] }}
                </div>
                <div class="RicenYuan wz7" v-if="overViewData.typeNameList[6]">
                  {{ overViewData.typeNameList[6] }}
                </div>
              </div>
            </div>
          </div>
          <!--种子价格-->
          <div class="c_white font_22 slide-titles flex flex_ju_l">
            <img src="../assets/img/home/yuzhongtb.png" />
            <div class="fm_pm slide-title">种子价格</div>
          </div>
          <div class="Ricity flex flex_ju_sb">
            <div class="flex flex_ju_sb absde1">
              <div class="RiCtTit">区域</div>
              <el-select
                v-model="cityname1"
                @change="handleChange"
                placeholder="请选择"
                style="width: 120px"
              >
                <el-option
                  v-for="item in cityname1list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="cityname2"
                @change="handleChange2"
                placeholder="请选择"
                style="width: 120px"
              >
                <el-option
                  v-for="item in cityname2list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
              <el-select
                v-model="cityname3"
                @change="handleChange3"
                placeholder="请选择"
                style="width: 120px"
              >
                <el-option
                  v-for="item in cityname3list"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
              <!-- <el-cascader
									style="width: 350px;"
								      size="large"
								      :options="options"
								      v-model="selectedOptions"
								      @change="handleChange">
								    </el-cascader> -->
            </div>
            <div class="flex flex_ju_sb absde88">
              <div class="RiCtTit">日期</div>
              <el-date-picker
                style="width: 280px"
                v-model="datatime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
            <el-button class="RictBtn" @click="getPriceList">查询</el-button>
          </div>
          <div class="Ritable">
            <div class="table_th flex flex_ju_sb">
              <div>观察点</div>
              <div>品种</div>
              <div>价格(元)</div>
              <div>规格</div>
              <div>公司</div>
              <div>上报时间</div>
            </div>
            <div class="table_tdcont">
              <empty v-if="priceList.length === 0" />

              <div v-else>
                <vue-seamless-scroll
                  class="seamless-warp"
                  :class-option="classOption"
                  :data="priceList"
                >
                  <div
                    class="table_td flex flex_ju_sb"
                    v-for="(item, index) in priceList"
                    :key="index"
                  >
                    <div>
                      <a href="javascript:void(0);" :title="item.area | isempty"
                        >{{ item.area | isempty }}
                      </a>
                    </div>
                    <div>
                      <a
                        href="javascript:void(0);"
                        :title="item.type | isempty"
                        >{{ item.type | isempty }}</a
                      >
                    </div>
                    <div>
                      <a
                        href="javascript:void(0);"
                        :title="item.price | isempty"
                        >{{ item.price | isempty }}</a
                      >
                    </div>
                    <div>
                      <a
                        href="javascript:void(0);"
                        :title="item.specification | isempty"
                        >{{ item.specification | isempty }}</a
                      >
                    </div>
                    <div>
                      <a
                        href="javascript:void(0);"
                        :title="item.companyName | isempty"
                        >{{ item.companyName | isempty }}</a
                      >
                    </div>
                    <div>
                      <a
                        href="javascript:void(0);"
                        :title="item.insertTime | isempty"
                        >{{ item.insertTime | isempty }}</a
                      >
                    </div>
                  </div>
                </vue-seamless-scroll>
              </div>
            </div>
          </div>

          <!--大豆销售网络-->
          <!-- <div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/home/icon1_6.png" />
					<div class="fm_pm slide-title">大豆销售网络</div>
				</div>
				<div class="Ribottom flex flex_ju_sb"> -->
          <!--辐射地图-->
          <!-- <div class="ritbotleft">
						<rigBotLeft />
					</div> -->
          <!--饼状图-->
          <!-- <div class="ritbotright">
						<rigBotRight />
					</div>
					<div class="ritbotcont-left">占黄淮海流域大豆种子交易量</div>
					<div class="ritbotcont">良种畅销安徽、河南、 江苏及长江中下游11 个省份</div>
				</div> -->
        </div>
      </div>
    </div>
    <!--底部-->
    <!-- <Botfooter @totabclick="tabclick" :tabindex="tabindex" :tabindexsf="tabindexsf" @totabclicksf="tabclicksf">
		</Botfooter> -->
    <!-- 文化长廊的弹窗 -->
    <div
      class="dialog-mb"
      v-if="isOpenDialog || isShowMb"
      @click="closeDialog"
    ></div>
    <div
      class="dialog-culture modal_container"
      :class="isOpenDialog ? 'modal_show' : 'modal_out'"
    >
      <img
        class="icon_close"
        src="../assets/img/homeMon/icon_close.png"
        alt=""
        @click="closeDialog"
      />
      <template v-if="dialogType === 'left'">
        <div class="flex">
          <!-- 左边缩略图 -->
          <div class="swiper-left" v-show="cultureImgList.length">
            <div class="swiper-name">{{ cultureImgName || "--" }}</div>
            <div thumbsSlider="" class="swiper swiper-thumbs" id="swiperthums">
              <div class="swiper-wrapper swiper-wraps">
                <div
                  class="swiper-slide swiper-slides"
                  v-for="(item, i) of cultureImgList"
                  :key="i"
                >
                  <div class="swiper-borders">
                    <img class="swipers-imgs" :src="item" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <!-- 下面的数量 -->
            <div class="swiper-nums t_c">
              {{ swiperActive }}/{{ cultureImgList.length }}
            </div>
          </div>
          <div class="swiper-left" v-show="!cultureImgList.length">
            <div class="swiper-name">{{ cultureImgName || "--" }}</div>
          </div>
          <!-- 右边轮播 -->
          <div class="swiper-imgs" v-show="cultureImgList.length">
            <div class="swiper swiperbig" id="swiperbigs">
              <div class="swiper-wrapper swiper-wraps">
                <div
                  class="swiper-slide swiper-slides"
                  v-for="(item, i) of cultureImgList"
                  :key="i"
                >
                  <div class="swiper-borders">
                    <img class="swipers-imgs" :src="item" alt="" />
                  </div>
                </div>
              </div>
              <div
                class="arrow-02 swiper-button-next"
                v-show="this.cultureImgList.length > 1"
              ></div>
              <div
                class="arrow swiper-button-prev"
                v-show="this.cultureImgList.length > 1"
              ></div>
            </div>
          </div>
          <div class="swiper-imgs" v-show="!cultureImgList.length">
            <img class="swiper-none" src="../assets/img/none.png" alt="" />
          </div>
        </div>
      </template>
      <template v-else-if="dialogType === 'VR'">
        <div class="dialog-VR">
          <iframe :src="dialogSrc" frameborder="0"></iframe>
        </div>
      </template>
      <template v-else-if="dialogType === 'gwdl'">
        <div class="dialog-VR">
          <!-- 我是内容 -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Topheader from "../components/header.vue";
import Botfooter from "../components/footers.vue";
import LeftMidRight from "@/views/HomeComponent/LeftMidRight";
import LeftBottom from "./HomeComponent/LeftBottom.vue";
import WaveChart from "./HomeComponent/waveChart.vue";
import rigBotLeft from "./HomeComponent/rigBotLeft.vue";
import rigBotRight from "./HomeComponent/rigBotRight.vue";
import Empty from "./empty.vue";
import EarlyWarningList from "../components/EarlyWarningList.vue";
import { regionDataPlus } from "element-china-area-data";
import api from "@/api/api";
import LeftSecond from "./HomeComponent/LeftSecond.vue";
import RightSecond from "./HomeComponent/RightSecond.vue";
import RigMidRightTwo from "./HomeComponent/LeftMidRight2.vue";
import bus from "../api/bus.js";
var swiperSmall, swiper2;
export default {
  data() {
    const self = this;
    return {
      classOption: {
        step: 0.7, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
      overViewData: "", // 产业园介绍、资金投入
      capitalData: {}, // 资金投入组件数据,
      breedResult: [], //育种成效
      priceList: [], // 种子价格
      page: 1,
      limit: 10,
      stratTime: "",
      endTime: "",
      cityname1: "全部",
      cityname2: "全部",
      cityname3: "全部",
      cityname1list: regionDataPlus,
      cityname2list: [],
      cityname3list: [],

      tabindexsf: 0,
      tabindex: 0,
      options: regionDataPlus,
      selectedOptions: ["全部", "全部", "全部"],
      datatime: "",
      isShowMb: false,
      isOpenDialog: false, // 是否显示文化长廊弹窗
      isShowMoney: false, // 是否显示上面的金额数据
      culturesList: [], // 文化长廊
      cultureImgList: [], // 文化长廊弹窗图片数据
      cultureImgName: "", // 文化长廊弹窗名称
      swiperActive: 1, // 文化长廊图片显示的索引值
      isInit: false, // 页面是否初始化过了，主要用作页面刚进入时，弹窗会闪动的问题
      dialogType: "left", // 文化长廊是否是地图 VR 点击的
      dialogSrc: "", // 点图VR的地址
    };
  },
  components: {
    Topheader,
    LeftMidRight,
    LeftBottom,
    WaveChart,
    rigBotLeft,
    rigBotRight,
    Botfooter,
    Empty,
    EarlyWarningList,
    LeftSecond,
    RightSecond,
    RigMidRightTwo,
  },
  beforeDestroy() {
    if (swiper2) {
      swiper2.destroy();
      swiper2 = null;
    }
    if (swiperSmall) {
      swiperSmall.destroy();
      swiperSmall = null;
    }
    bus.$off("openVR");
  },
  mounted() {
    console.log(this.options);
    // this.cityname1='山东省'
    // this.cityname2='济宁市'
    // this.cityname3='嘉祥县'
    this.cityname1list = this.options;
    this.cityname2list = [];
    this.cityname3list = [];

    let year = new Date().getFullYear();
    let month = new Date().getMonth();
    let day = new Date().getDate();
    let lastMonth = 0;
    let lastYear = year;
    if (month === 0) {
      lastMonth = 11;
      lastYear = year - 1;
    }
    this.datatime = [
      new Date(lastYear, lastMonth, day),
      new Date(year, month, day),
    ];
    // 获取页面呈现全部数据
    this.getOverview();
    // 获取文化长廊
    this.getCulturesList();
    this.getBreedingResult(year);
    this.getPriceList();
    bus.$on("openVR", this.openDialog);
  },
  filters: {
    isempty(value) {
      if (!value) {
        return "暂无";
      } else {
        return value;
      }
    },
  },
  methods: {
    // 上面的金额是否显示
    changeMoney() {
      this.isShowMoney = !this.isShowMoney;
    },
    //跳转
    tonengguan(url) {
      this.$router.push({
        name: url,
      });
    },
    tabclick(e) {
      this.$router.push({
        name: e,
      });
    },
    tabclicksf(e) {
      this.tabindexsf = e;
      console.log(e);
    },
    // 获取页面呈现全部数据
    getOverview() {
      this.$http.post(api.overView).then((res) => {
        console.log("资金投入", res.data.data);
        let { data } = res.data;
        if (data && data.parkPic) {
          data.parkPicList = data.parkPic.split(",");
        }
        if (data && data.typeName) {
          data.typeNameList = data.typeName.split(",");
        }
        if (data && data.basicProfile) {
          data.basicProfile = data.basicProfile.replace(
            /\d+/g,
            '<a style="color:#2EBDFF">$&</a>'
          );
        }
        this.capitalData = {
          category: ["地方配套", "省级资金", "社会资金"],
          valueData: [data.partFunding, data.provincialFunds, data.socialFunds],
        };
        let capitalDatas = [
          {
            name: "配套资金",
            val: data.partFunding,
          },
          {
            name: "省级资金",
            val: data.provincialFunds,
          },
          {
            name: "社会资金",
            val: data.socialFunds,
          },
        ];
        this.overViewData = data;
        bus.$emit("zongNum", data.fundSum || "--");
        bus.$emit("capitalData", capitalDatas || {});
      });
    },
    // 文化长廊
    async getCulturesList() {
      const { data: res } = await this.$http.post(this.$api.getCultureInfo);
      if (res.code == 0) {
        let datas = res.data || [];
        this.culturesList = datas || [];
      } else {
        this.culturesList = [];
      }
    },
    // 文化长廊切换监听
    handleClickSlide() {
      const i = this.swiper.activeIndex;
      console.log(i);
    },
    getBreedingResult(year) {
      this.$http.post(api.breedingResult, { year }).then((res) => {
        console.log("育种成效", res.data.data);
        let { data } = res.data;
        let breedingResultArr = [];
        data.forEach((item) => {
          // if (item.name - 0 >= 2017) {
          breedingResultArr.push(item);
          console.log(breedingResultArr);
          // }
        });
        // this.breedResult = breedingResultArr;
        // 假数据
        let jiaData = [
          {
            name: "2011",
            value: "162.64",
          },
          {
            name: "2012",
            value: "147.27",
          },
          {
            name: "2013",
            value: "157.36",
          },
          {
            name: "2014",
            value: "160.02",
          },
          {
            name: "2015",
            value: "171.18",
          },
          {
            name: "2016",
            value: "219.07",
          },
          {
            name: "2017",
            value: "194.93",
          },
          {
            name: "2018",
            value: "171.26",
          },
          {
            name: "2019",
            value: "190.08",
          },
          {
            name: "2020",
            value: "193.39",
          },
          {
            name: "2021",
            value: "213.6",
          },
        ];

        this.breedResult = jiaData;
      });
    },
    getCityName(name) {
      if (name.indexOf("省") != -1) {
        name = name.substring(0, name.length - 1);
      } else if (name.indexOf("自治区") != -1) {
        name = name.substring(0, name.length - 3);
      } else if (name.indexOf("特别行政区") != -1) {
        name = name.substring(0, name.length - 5);
      } else if (name === "全部") {
        name = "";
      }
      return name;
    },
    getPriceList() {
      let timeArr = this.getTimeFromDataTime(this.datatime);
      if (this.cityname2 == "全部") {
        this.cityname2 = "";
      }
      if (this.cityname3 == "全部") {
        this.cityname3 = "";
      }
      console.log(this.cityname1);
      let area;

      // 宁夏改夏宁
      if (this.cityname1 == "宁夏回族自治区") {
        area = "宁夏" + this.cityname2 + this.cityname3;
      } else {
        area =
          this.getCityName(this.cityname1) + this.cityname2 + this.cityname3;
      }

      let data = {
        page: this.page,
        limit: this.limit,
        startTime: timeArr[0],
        endTime: timeArr[1],
        area,
        //this.cityname1+this.cityname2+this.cityname3
      };
      if (data.area.includes("北京市市辖区")) {
        data.area = data.area.replace("市", "");
      }

      this.$http.post(api.seedPriceList, data).then((res) => {
        console.log("种子价格", res.data.data);
        let { data } = res.data;

        this.priceList = data;
      });
    },
    getTimeFromDataTime(dataTime) {
      let timeArr = [];
      if (dataTime && dataTime.length > 0) {
        var start = new Date(dataTime[dataTime.length - 2]);
        var end = new Date(dataTime[dataTime.length - 1]);
        start =
          start.getFullYear() +
          "-" +
          (start.getMonth() < 9
            ? "0" + (start.getMonth() + 1)
            : start.getMonth() + 1) +
          "-" +
          (start.getDate() < 9 ? "0" + start.getDate() : start.getDate());
        // start.getHours() +
        // ":" +
        // start.getMinutes() +
        // ":" +
        // start.getSeconds();
        end =
          end.getFullYear() +
          "-" +
          (end.getMonth() < 9
            ? "0" + (end.getMonth() + 1)
            : end.getMonth() + 1) +
          "-" +
          (end.getDate() < 9 ? "0" + end.getDate() : end.getDate());
        // " " +
        // end.getHours() +
        // ":" +
        // end.getMinutes() +
        // ":" +
        // end.getSeconds();
        timeArr = [start, end];
      } else {
        timeArr = ["", ""];
      }
      return timeArr;
    },
    handleChange(name) {
      this.cityname2list = [];
      this.cityname2 = "";
      this.cityname3list = [];
      this.cityname3 = "";
      this.cityname1list.forEach((item, index) => {
        console.log(item.label);
        if (name == item.label) {
          this.cityname2list = this.cityname1list[index].children;
          // 	console.log(this.cityname2list)
        }
      });
    },
    handleChange2(name) {
      this.cityname3list = [];
      this.cityname3 = "";
      this.cityname2list.forEach((item, index) => {
        if (name == item.label) {
          this.cityname3list = this.cityname2list[index].children;
          // console.log(this.cityname3list)
        }
      });
    },
    handleChange3(name) {
      console.log(name);
      /* this.cityname2='';
			 
				this.cityname3=''; */
    },
    // 点击打开文化长廊弹窗
    async openDialog({ cultureName, id, param }) {
      // console.log(cultureName, id);
      console.log(cultureName, id);
      // console.log(VRsrc)
      // 触发右侧页面切换切换暂停事件
      bus.$emit("toggleChart", { action: "toggleChart" });
      // 如果是点击地图上的按钮
      if (param) {
        const { type } = param;
        switch (type) {
          case "vr":
            this.dialogType = "VR";
            this.dialogSrc = param.VRsrc;
            break;
          case "gwdl":
            this.dialogType = "gwdl";
            break;
        }
        this.isOpenDialog = true;
        this.isShowMb = true;
        setTimeout(() => {
          this.isInit = true;
        }, 500);
        return;
      }
      // 这里说明点击的是左侧的文化长廊
      this.dialogType = "left";
      this.cultureImgList = [];
      this.swiperActive = 1;
      setTimeout(() => {
        this.isInit = true;
      }, 500);
      this.cultureImgName = cultureName;
      // 请求接口数据
      let params = {
        id: id,
      };
      const { data: res } = await this.$http.get(
        this.$api.getCulturePictureList,
        params
      );
      if (res.code == 0) {
        let datas = res.data || [];
        let ifFreeMode = true;
        if (datas.length && datas.length > 4) {
          ifFreeMode = true;
        } else {
          ifFreeMode = false;
        }

        this.cultureImgList = datas || [];
        // 赋值轮播默认的索引值
        this.swiperActive = 1;
        // swiperSmall = null
        // swiper2 = null
        this.$nextTick(() => {
          if (!swiperSmall || !swiper2) {
            this.swiperInIt(ifFreeMode);
            this.swiperBigInit();
          } else {
            swiperSmall.update();
            swiper2.update();
            swiperSmall.slideTo(0, 0, false);
            swiper2.slideTo(0, 0, false);
          }
        });
      } else {
        this.cultureImgList = [];
        this.swiperActive = 1;
      }
      this.isOpenDialog = true;
      this.isShowMb = true;
    },
    swiperInIt(ifFreeMode) {
      swiperSmall = new Swiper("#swiperthums", {
        direction: "vertical",
        // loop: false,
        spaceBetween: 20,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
        mousewheel: true,
        // observer: true,
        // observeSlideChildren: true,
      });
    },
    swiperBigInit() {
      const _this = this;
      swiper2 = new Swiper("#swiperbigs", {
        // loop: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        // slidesPerView: 1,
        // observer: true,
        // observeSlideChildren: true,
        thumbs: {
          swiper: swiperSmall,
        },
        on: {
          slideChangeTransitionEnd: function (e) {
            let thisActiveIndex = this.realIndex + 1;
            _this.swiperActive = thisActiveIndex;
            if (thisActiveIndex == _this.cultureImgList.length) {
              _this.$message({
                message: "已经是最后一张了",
                type: "warning",
                showClose: true,
                center: true,
              });
            }
            if (thisActiveIndex == 1) {
              _this.$message({
                message: "已经是第一张了",
                type: "warning",
                showClose: true,
                center: true,
              });
            }
          },
        },
      });
    },
    // 关闭弹窗
    closeDialog() {
      bus.$emit("toggleChart", { action: "closeChart" });
      if (this.dialogSrc) this.dialogSrc = "";
      this.isOpenDialog = false;
      setTimeout(() => {
        this.$refs.cultureDiv.chooseId = "";
      }, 10);
      setTimeout(() => {
        // this.cultureImgList = []
        // this.swiperActive = 1
        this.isShowMb = false;
      }, 800);
    },
  },
};
</script>
<style lang="less" scoped>
@import "../assets/css/modal.less";
.homeindex {
  height: 1080px;
  overflow: hidden;
}
.home {
  height: 1080px;

  background: url("../assets/img/bgs.png") no-repeat;
  background-size: 100% 100%;

  overflow: hidden;
  margin-top: -144px;
  position: relative;

  .slide-titles {
    width: 805px;
    background: url("../assets/img/bg_title.png") no-repeat;
    background-size: 100% 100%;

    .slide-title {
      height: 66px;
      line-height: 66px;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
    }

    img {
      width: 50px;
      height: 50px;
      margin-top: 8px;
    }
  }
  .lefts {
    width: 1840px;
  }
  .left {
    padding: 77px 0 0 50px;
    width: 888px;
    background: url("../assets/img/home/bg_left.png") no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    // position: fixed;
    // top: 63px;
    position: relative;
    z-index: 10;
    animation-duration: 2s;
    animation-timing-function: linear;

    .LfContTop {
      padding: 10px 15px;
      overflow: hidden;
      .el-carousel {
        float: left;
        width: 210px;
      }
      & /deep/ .el-carousel__container {
        height: 225px;
      }
      & /deep/ .el-carousel__item {
        img {
          width: 100%;
          height: 220px;
          cursor: pointer;
        }
      }

      div.park-detail {
        float: right;
        width: 72%;
        height: 215px;
        padding-left: 26px;
        font-size: 17px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        line-height: 30px;
        padding: 0 10px 10px 10px;
        overflow: auto;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 7;
      }
      div.park-detail::-webkit-scrollbar {
        width: 5px;
      }

      div.park-detail::-webkit-scrollbar-track {
        background-color: #10445b;

        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }

      div.park-detail::-webkit-scrollbar-thumb {
        background-color: #01756b;
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }
    }

    .LfContCent {
      padding-top: 10px;

      .LCCleft {
        width: 198px;
        height: 198px;
        background: url("../assets/img/home/img1_4.png") no-repeat;
        background-size: 100% 100%;
        text-align: center;
        padding: 46px;

        p {
          padding: 5px 0;
        }

        p:nth-child(1) {
          font-size: 27px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #2ebdff;
        }

        p:nth-child(2) {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #2cb5f5;
        }

        p:nth-child(3) {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
        }
      }

      .LCCright {
        width: 600px;
        padding: 10px 0 10px 20px;

        // p {
        // 	font-size: 16px;
        // 	font-family: Microsoft YaHei;
        // 	font-weight: bold;
        // 	color: #FFFFFF;
        // 	padding: 12px 0;

        // 	span {
        // 		height: 15px;
        // 		font-size: 16px;
        // 		font-family: Microsoft YaHei;
        // 		font-weight: bold;
        // 		padding-left: 10px
        // 	}
        // }

        // .lccrBg {
        // 	position: relative;
        // 	height: 12px;
        // 	margin: 0 0 14px 0;

        // 	.lccrbgTop1 {
        // 		height: 12px;
        // 		border-radius: 6px;
        // 		position: absolute;
        // 		top: 0;
        // 		left: 0;
        // 		z-index: 1;
        // 		opacity: 0.36;
        // 		width: 100%;
        //  }

        // 	.lccrbgTop2 {
        // 		position: absolute;
        // 		top: 0;
        // 		left: 0;
        // 		width: 502px;
        // 		height: 12px;
        // 		border-radius: 6px;
        // 		z-index: 2;

        // 		img {
        // 			position: absolute;
        // 			top: -16px;
        // 			right: -17px;
        // 	 }
        // 	}
        // }
      }
    }
    .LfContBottom {
      margin: 10px 0 0 0;
      .LfContBotCont {
        width: 808px;
        height: 77px;
        background: rgba(6, 18, 66, 0.4);
        p {
          width: 804px;
          height: 45px;
          padding: 10px 20px;
          line-height: 30px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          span {
            color: #2ebdff;
          }
        }
      }
      .echarts {
        height: 220px;
        width: 100%;
        margin-top: 10px;
      }
    }
  }
  .centers {
    flex: 1;
    position: relative;
    height: 1px;
    z-index: 20;
    top: 120px;
    background-color: rgba(255, 255, 255, 0.5);
  }
  // 中间
  .center {
    // background-color: rgba(255, 255, 255, 0.2);
    // height: 1080px;
    // width: 3300px;
    position: relative;
    z-index: 5;
    flex: 1;
    height: 136px;
    // top: 120px;
    top: -50px;
    opacity: 0;
    transition: all 1s;
    // .CenCont{
    // 	padding: 120px 70px;

    // background-color: rgba(255, 255, 255, 0.5);
    &.center-show {
      top: 120px;
      opacity: 1;
    }
    .CenCLeft {
      width: 600px;
      position: absolute;
      top: 0;
      left: 70px;
    }
    .CenCCont {
      height: 136px;
      width: 1122px;
      background: url("../assets/img/home/img1_9.png") no-repeat;
      background-size: 100% 100%;
      padding: 0 90px;
      margin-top: 20px;
      .CenCCList {
        padding: 22px 0;
        p:nth-child(1) {
          font-size: 50px;
          font-family: Bahnschrift;
          font-weight: 400;
          color: #ffffff;
          margin-left: 20px;
        }
        p:nth-child(2) {
          font-size: 20px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #ffffff;
          line-height: 34px;
        }
      }
    }
    .CenCright {
      width: 590px;
      height: 136px;
      position: absolute;
      top: 148px;
      right: 70px;
      .CenCposition {
        height: 136px;
        line-height: 70px;
        width: 590px;
        img {
          height: 70px;
          width: 70px;
          margin-left: 4px;
        }
      }

      .CenCbutlist {
        // margin-top:190px ;
        width: 590px;
        .CenCbutlt {
          margin: 40px 0 0 275px;
          height: 85px;
          line-height: 85px;
          width: 220px;
          text-align: center;
          background: url("../assets/img/home/butbg.png") no-repeat;
          font-size: 24px;
          font-family: HuXiaoBo-NanShen;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
        }
        .CenCbutlAc {
          background: url("../assets/img/home/butbg_ac.png") no-repeat;
          color: #0fe6ff;
        }
        .CenCbutlt:hover {
          transition: 0.3s;
          background: url("../assets/img/home/butbg_ac.png") no-repeat;
          color: #0fe6ff;
        }
      }
    }

    // }
  }
  // 右边
  .right {
    padding: 77px 50px 0 0;
    width: 858px;
    background: url("../assets/img/home/bg_right.png") no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    // position: fixed;
    // top: 63px;
    position: relative;
    z-index: 10;
    animation-duration: 2s;
    animation-timing-function: linear;
    .RiContTop {
      margin: 10px 0 0 0;
      .RiContBotCont {
        width: 808px;
        height: 98px;
        background: rgba(6, 18, 66, 0.4);
        border: 1px solid #005992;
        p {
          width: 804px;
          height: 95px;
          padding: 2px 20px;
          line-height: 30px;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
          span {
            color: #2ebdff;
          }
          overflow: auto;
        }

        p::-webkit-scrollbar {
          width: 5px;
        }

        p::-webkit-scrollbar-track {
          background-color: #10445b;

          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
        }

        p::-webkit-scrollbar-thumb {
          background-color: #01756b;
          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
        }
      }
      .RiContBotContBot {
        padding: 10px 0;

        .Rileft {
          .lefttop {
            margin-bottom: 10px;
            .eachZw {
              height: 72px;
              width: 72px;
            }
            .LfTpConten {
              padding: 5px 10px;
              p {
                font-weight: bold;
                padding: 5px 0;
              }
              span {
                font-weight: bold;
                color: #ffffff;
              }
              p:nth-child(1) {
                font-size: 16px;
                color: #ffffff;
                span {
                  font-size: 12px;
                }
              }
              p:nth-child(2) {
                font-size: 26px;
                span {
                  font-size: 16px;
                  padding-left: 10px;
                }
              }
            }
          }
        }
        .Ricenter {
          position: relative;
          width: 170px;

          .RicenYuan {
            position: absolute;
            height: 80px;
            width: 80px;
            background: url("../assets/img/home/icon_dzimg.png") no-repeat;
            background-size: 100% 100%;
            line-height: 80px;
            font-size: 14px;
            font-weight: bold;
            color: #ffffff;
            text-align: center;
          }
          .wz1 {
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 15px;
            bottom: 0;
            right: 0;
            width: 94px;
            height: 94px;
            line-height: 1.5;
          }
          .wz2 {
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 15px;
            top: 20px;
            left: 10px;
            width: 89px;
            height: 89px;
            line-height: 1.5;
          }
          .wz3 {
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 15px;
            line-height: 1.5;
            bottom: 0px;
            left: 30px;
            width: 89px;
            height: 89px;
          }
          .wz4 {
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 15px;
            top: 0;
            left: 0;
            width: 89px;
            height: 89px;
            line-height: 1.5;
          }
          .wz5 {
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 15px;
            top: 20px;
            left: 96px;
            width: 89px;
            height: 89px;
            line-height: 1.5;
          }
          .wz6 {
            top: 100px;
            left: 5px;
            width: 80px;
            height: 80px;
            line-height: 1.5;
          }
          .wz7 {
            top: 100px;
            right: 17px;
            width: 64px;
            height: 64px;
            line-height: 64px;
          }
          .wz8 {
            top: 100px;
            left: 100px;
          }
        }
      }
    }
    .Ricity {
      padding: 10px 0;
      .RiCtTit {
        height: 47px;
        line-height: 47px;
        font-weight: bold;
        font-size: 16px;
        color: #24c4f5;
        padding-right: 10px;
      }
      .RictBtn {
        background: transparent;
        border: none;
        height: 47px !important;
        line-height: 28px !important;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIcAAAAvCAYAAADJqNmJAAAAAXNSR0IArs4c6QAAA/tJREFUeF7tnN1OG0cUx39nsbFjQkM+GpK8QdSryuUB8gjpRZ+hb5C8QhQpUapWCle8RB4CJXARqVetxBVpCISP1AQbG++JZnZNCcUJNllmVpyxEBbSeM7+/789Z8zOjGDNFBiigJgypsAwBQwOY2OoAgaHwWFwGAOjK2CZY3TNLkyPL8PxWm/S42fgLkrdq5KgKF2EA5Q+Qnph1CrbhaaIfykVoJr/ZFchbCO8pM4LfpDuSZc2HI5FnWWCh0Aj/7AO8BFwv/eBHtAHtGyaXah4xd/OGRxKzfspTOV/c5D8RZOniPzvJh8Ox5L+ivJjLuQHhH8RdklpIx6Qns8errlcYi0uBVzGcC0lIWECmES55OFQLgNXDm/8hAWasnj8Ar4Ex7O8lDgwtoEMENgj9XC40tI3MOJi4rNosqKSlRXJ4ciyxnfADHDNA5OwSFMWTg/HK53PM8QGsIWyTUKLlF0SOn7ekRgcEaMxmFu4BFDlgEnETxGmfNYQrgLXEW6S8idz8vuocGwirAPvgR2fPZSPCO0cjqysWItXgay8VOkx6ctIkpcU5RrC9wizpKyMBseyPkf5B+UdCRukbPmy0mfXw1Fjn46fkGbNFRhrcSgwkc83XDTufUqFCjWUBinTCDMM4IDb3ueRMseS/ga8AdZQNkjY8pnDwaHs0aNLPYeja2DEQcWxKFwhqSL0qFClRkqD/iEcWUlxcKRsjgbHsj5BWc3hWEdyOKq0aNFmli6r9NkwMKIEYxCUKxxQYYa6zxwDOODGIRzupm/KH6efcyzpY2AV4e2RzLFDj10maNOiyz2xOUfUZLh/M6iwTIUOdSo0qB7JHCmzCLd9RfhJnp8ejmV9hPIGYY2UdV9WhA+HcKywzy/y35wjdpEuanwDOC5R44AGPaZJuIpynTQvKw6OOZkfHQ6OZI4BHH32WKVrcJSAuJPgyCakg6+xd3zm+CZwdGgBbYOjBGC4EA2OkhgVIkyDI4TqJRnT4CiJUSHCNDhCqF6SMQ2OkhgVIkyDI4TqJRnT4CiJUSHCNDhCqF6SMQ2OkhgVIkyDI4TqJRnT4CiJUSHCPFc43CN7e/AWwubxxiwUDntkP54psfQq5JG9LfaJxd6zxVHQYh9bJng2W+LoXdAyQVtgHIe940dR4AJj25owvi1hexa+NSHb8WabmsLafPbRC9nU9Pl2yE2UHdsOeXavzv0TBnB80+2QS3p8I/UO4teP2kbqc3d4zAEL20htRzCM6Ugk3Qo9guGl3kJ4YIe3RGL2uGF8/fCWv2nyZLTDW1ww7nSfCvft2KdxnQncr7BjnwJflw0fXgE7TTC8B9FGYHBEa034wAyO8B5EG4HBEa014QP7BC94EV1wV5wwAAAAAElFTkSuQmCC)
          no-repeat !important;
        background-size: 100% 100% !important;
        border: 0px solid #24c4f5 !important;
        color: #24c4f5 !important;
        font-weight: bold;
        font-size: 16px;
        color: #24c4f5;
      }
      .city1 {
        height: 47px;
        width: 135px;
        line-height: 47px;
        text-align: center;
        background: url("../assets/img/home/input.png") no-repeat;
        background-size: 100% 100%;
        .citytit {
          height: 47px;
          line-height: 47px;
          cursor: pointer;
          font-size: 16px;
          font-family: Source Han Sans CN;
          // font-weight: bold;
          color: #24c4f5;
          position: relative;
          padding-left: 20px;
          img {
            width: 11px;
            height: 9px;
            position: absolute;
            right: 16px;
            top: 20px;
          }
        }
      }
    }
    .Ritable {
      .table_th {
        div {
          background: url("../assets/img/home/taber_th.png") no-repeat;
          background-size: 100% 100%;
          font-size: 15px;
          font-weight: bold;
          color: #ffffff;
          line-height: 21px;
          padding: 5px 10px;

          text-align: center;
        }
        div:nth-child(1) {
          width: 150px;
        }
        div:nth-child(2) {
          width: 117px;
        }
        div:nth-child(3) {
          width: 100px;
        }
        div:nth-child(4) {
          width: 100px;
        }
        div:nth-child(5) {
          width: 180px;
        }
        div:nth-child(6) {
          width: 130px;
        }
      }
      .table_tdcont {
        height: 450px;
        // overflow-y: auto;
        overflow: hidden;
        position: relative;
        .table_td {
          margin: 10px 0;
          div {
            height: 27px;
            line-height: 27px;
            background: rgba(2, 31, 70, 0.5);
            font-size: 15px;
            font-weight: bold;
            color: #ffffff;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            a {
              cursor: initial;
            }
          }
          div:nth-child(1) {
            width: 150px;
          }
          div:nth-child(2) {
            width: 117px;
          }
          div:nth-child(3) {
            width: 100px;
          }
          div:nth-child(4) {
            width: 100px;
          }
          div:nth-child(5) {
            width: 180px;
          }
          div:nth-child(6) {
            width: 130px;
          }
        }
      }

      .table_tdcont > .scroll-list {
        /* position: absolute;
				    top: 0;
				    left: 0;
				    width: 100%;
				    animation: scroll 20s linear infinite normal;
					&:hover{
					        animation-play-state:paused;
					        -webkit-animation-play-state:paused;
					    } */
      }

      .table_tdcont > .scroll-list > .table_td {
        /* 滚动的项目需要有具体的高度 */
        height: 30px;
      }
      @keyframes scroll {
        100% {
          /* 需要滚动内容的总高度 */
          top: -200px;
        }
      }

      .table_tdcont::-webkit-scrollbar {
        width: 5px;
      }

      .table_tdcont::-webkit-scrollbar-track {
        background-color: #10445b;

        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }

      .table_tdcont::-webkit-scrollbar-thumb {
        background-color: #01756b;
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
      }
    }
    .Ribottom {
      position: relative;
      .ritbotleft {
        width: 310px;
        height: 210px;
      }
      .ritbotright {
        width: 500px;
        height: 210px;
      }
      .ritbotcont-left {
        width: 260px;
        height: 96px;
        position: absolute;
        top: 0;
        right: 240px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        padding: 10px 20px;
        line-height: 24px;
      }
      .ritbotcont {
        width: 213px;
        height: 96px;
        position: absolute;
        top: 0;
        right: 0px;
        background: url("../assets/img/home/yuan.png") no-repeat;
        background-size: 100% 100%;
        font-size: 17px;
        font-weight: bold;
        color: #80d9fe;
        padding: 10px 20px;
        line-height: 24px;
      }
    }
  }
  //公共颜色
  .bcolor0090FF {
    color: #0090ff;
    background: #0090ff;
  }

  .bcolor2EBDFF {
    color: #2ebdff;
    background: #2ebdff;
  }

  .bcolor03FFEC {
    color: #03ffec;
    background: #03ffec;
  }

  .bcolorFDFF2E {
    color: #fdff2e;
    background: #fdff2e;
  }

  .color0090FF {
    color: #0090ff;
  }

  .color2EBDFF {
    color: #2ebdff;
  }

  .color03FFEC {
    color: #03ffec;
  }
  .colorFDFF2E {
    color: #fdff2e;
  }
}
.dialog-mb {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 18;
}
.modal_container {
  position: relative;
  .icon_close {
    position: absolute;
    width: 86px;
    height: 86px;
    top: -24px;
    right: -21px;
    cursor: pointer;
  }
  // 左边缩略图
  .swiper-left {
    .swiper-name {
      width: 242px;
      height: 65px;
      background: url("../assets/img/home/bg_swipername.png") no-repeat;
      background-size: 100% 100%;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #d2e0ff;
      font-size: 24px;
      text-align: center;
      line-height: 65px;
    }
    .swiper-thumbs {
      margin-top: 25px;
      width: 242px;
      height: 616px;
      .swiper-wraps {
        width: 241px;
        height: 616px;
      }
      .swiper-slides {
        width: 241px;
        height: 140px;
      }
      .swiper-borders {
        width: 241px;
        height: 140px;
        text-align: center;
        border: 2px solid transparent;
        background-color: #07132b;
      }
      .swipers-imgs {
        width: 232px;
        height: 130px;
        margin-top: 3px;
      }
    }
    .swiper-nums {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #d2e0ff;
      margin-top: 60px;
    }
    .swiper-slide-thumb-active {
      .swiper-borders {
        border: 2px solid #327cfc;
      }
    }
  }
  // 右边展示
  .swiper-imgs {
    width: 1140px;
    height: 777px;
    margin-left: 25px;
    position: relative;
    .swiper-wraps {
      width: 1140px;
      height: 777px;
    }
    .swiper-slides {
      width: 1140px;
      height: 777px;
    }
    .swiper-borders {
      width: 1140px;
      height: 777px;
    }
    .swipers-imgs {
      width: 1138px;
      height: 775px;
    }
    .swiper-slide-active {
      .swiper-borders {
        border: 1px solid #327cfc;
      }
    }
    .swiper-button-prev::before {
      content: "";
    }
    .swiper-button-prev::after {
      content: "";
    }
    .swiper-button-next::after {
      content: "";
    }
    .arrow {
      position: absolute;
      left: 15px;
      top: 50%;
      width: 57px;
      height: 80px;
      background: url("../assets/img/map/btn_p.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      z-index: 15;
      transform: translateY(-50%);
      &:focus {
        outline: none;
      }
    }
    .arrow-02 {
      position: absolute;
      right: 15px;
      top: 50%;
      width: 57px;
      height: 80px;
      background: url("../assets/img/map/btn_n.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      z-index: 15;
      transform: translateY(-50%);
      &:focus {
        outline: none;
      }
    }
    .swiper-none {
      display: block;
      margin: 150px auto 0;
    }
  }

  .dialog-VR {
    // width: 100%;
    width: 1400px;
    height: 777px;
    iframe {
      width: 100%;
      height: 100%;
    }
    // background: red;
  }
}
</style>
