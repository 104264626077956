import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		isZnMap: false, // 是否显示智脑中心的map
	},
	mutations: {
		setZnMap(state, bool) {
			state.isZnMap = bool
		},
	},
})
