<template>
  <div>
    <div class="pie" ref="pieRef"></div>
    <div class="title">故障统计</div>
  </div>
</template>
<script>
export default{
    props: {
    data: {
      type: Array,
      required:true 
    },
  },
  data(){
    return{}
  },
  watch:{
			data:{
				handler(){
					this.initPie();
			 	},
				deep:true
			}
		},
  mounted(){
    this.initPie();
  },
  methods: {
    initPie() {
        let _this = this;
        if(this.data.length == 0){
            return
        }
      let myChart = this.$echarts.init(this.$refs.pieRef)
      let data = this.data;
       let total = 0;
      let numArr= [];
      data.forEach(item=>{
        item.value = +(item.value)
        numArr.push(item.value)
        total += item.value
      })
      let max = data.reduce((a,b)=>{
          return b.value>a.value ? b : a
      })
    //   let sumValue = max.value * 1.5;
      let optionData = getData(data)

      
      function getData(data) {
          var res = {
              series: [],
              yAxis: []
          };
          for (let i = 0; i < data.length; i++) {
              let newVal = 0.2;
              let newRa = 100;
              if(i==2){
                  newVal = 0.3;
                  newRa = 10;
              } else if(i==3){
                  newVal = 0.4;
                  newRa = 10;
              }
              else if(i==4){
                  newVal = 0.5;
                  newRa = 10;
              }
              res.series.push(
                  {
                  name: '学历',
                  type: 'pie',
                  clockWise: true,
                  z: 2,
                  hoverAnimation: false,
                  radius: [85 - i * 14 + '%', 80 - i * 14 + '%'],
                  center: ["56%", "50%"],
                  label: {
                      show: true,
                      formatter: param=>{
                          if(!param.name){
                              return;
                          }
                        let percent = _this.getPercentWithPrecision(numArr,param.componentIndex/2,2)
                          return percent+ '%'
                      },
                      color: '#fff',
                      fontSize: 13,
                      rotate:-50,
                      padding:[25,0,0,15],
                      position: 'inside'
                  },
                  labelLine: {
                      show: false
                  },
                  data: [
                    {
                        value: data[i].value,
                        name: data[i].name,
                    },
                    {
                        name:'',
                        value:newVal,
                        label:{
                            show:false
                        },
                        labelLine:{
                            show:false
                        },
                        itemStyle:{
                            color:'#4CD7FE',
                            borderRadius:newRa,
                            borderColor:"#4CD7FE",
                            shadowBlur:10,
                            shadowColor:"rgba(76,215,254,1)",
                            borderWidth:5,
                        },
                    },
                   {
                      value: total - data[i].value,
                      name: '',
                      itemStyle: {
                          color: "rgba(0,0,0,0)",
                          borderWidth: 0
                      },
                      tooltip: {
                          show: false
                      },
                      label: {
                          show: false
                      },
                      hoverAnimation: false
                  }]
              });
              res.series.push({
                  name: '背景线',
                  type: 'pie',
                  silent: true,
                  z: 1,
                  clockWise: true,
                  hoverAnimation: false,
                  radius: [83 - i * 14 + '%', 81 - i * 14 + '%'],
                  center: ["56%", "50%"],
                  label: {
                      show: false
                  },
                  itemStyle: {
                      label: {
                          show: false,
                      },
                      labelLine: {
                          show: false
                      },
                      borderWidth: 5,
                  },
                  data: [{
                      value: 100,
                      itemStyle: {
                          color: "#287699",
                          borderWidth: 0
                      },
                      tooltip: {
                          show: false
                      },
                      hoverAnimation: false
                  }]
              });
              res.yAxis.push(data[i].name);
          }
          console.log(res.series);
          res.series.push({
            name:'',
            type: 'pie',
            silent: true,
            z: 1,
            clockWise: true,
            hoverAnimation: false,
            radius: ['83%', '88%'],
            center: ["56%", "50%"],
            data: [
                {
                value: 100,
                itemStyle: {
                    color: "rgba(43,163,235,.3)",
                    borderWidth: 0
                },
                tooltip: {
                    show: false
                },
                hoverAnimation: false
            }]
          },
          {
            name:'',
            type: 'pie',
            silent: true,
            z: 1,
            clockWise: true,
            hoverAnimation: false,
            radius: ['88%', '93%'],
            center: ["56%", "50%"],
            data: [
                {
                value: 100,
                itemStyle: {
                    color: "rgba(43,163,235,.2)",
                    borderWidth: 0
                },
                tooltip: {
                    show: false
                },
                hoverAnimation: false
            }]
          })
          return res;
      }
      const option = {
          color: [{
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 1,
              colorStops: [{
                  offset: 0,
                  color: 'rgba(43,163,235,1)'
              }, {
                  offset: 1,
                  color: 'rgba(1,232,254,1)'
              }],
              global: false
          }],
          grid: {
              top: '6%',
              bottom:"58%",
              left:"45%",
              containLabel: true
          },
          yAxis: [{
              type: 'category',
              inverse: true,
              z: 3,
              axisLine: {
                  show: false
              },
              axisTick: {
                  show: false
              },
              axisLabel: {
                  interval: 0,
                  inside: false,
                  align:'right',
                  textStyle: {
                      color: "#00CDFF",
                      fontSize: 14,
                      fontWeight:'bold'
                  },
                  show: true
              },
              data: optionData.yAxis
          }],
          xAxis: [{
              show: false
          }],
          series: optionData.series
      };
      myChart.setOption(option)
    },
    getPercentWithPrecision(valueList, idx, precision) {
        if (!valueList[idx]) {
            return 0;
        }

        var sum = valueList.reduce(function(acc, val) {
            return acc + (isNaN(val) ? 0 : val);
        }, 0);
        if (sum === 0) {
            return 0;
        }

        var digits = Math.pow(10, precision);
        var votesPerQuota = valueList.map(val => {
            return ((isNaN(val) ? 0 : val) / sum) * digits * 100;
        });
        var targetSeats = digits * 100;

        var seats = votesPerQuota.map(votes => Math.floor(votes));
        var currentSum = seats.reduce(function(acc, val) {
            return acc + val;
        }, 0);

        var remainder = votesPerQuota.map((votes, idx) => votes - seats[idx]);

        // Has remainding votes.
        while (currentSum < targetSeats) {
            // Find next largest remainder.
            var max = -Infinity; //Number.NEGATIVE_INFINITY;
            var maxId = null;
            for (var i = 0, len = remainder.length; i < len; ++i) {
                if (remainder[i] > max) {
                    max = remainder[i];
                    maxId = i;
                }
            }

            // Add a vote to max remainder.
            ++seats[maxId];
            remainder[maxId] = 0;
            ++currentSum;
        }

        return seats[idx] / digits;
    },
  },

}
</script>
<style lang='scss' scoped>
.pie{
    width: 415px;
	height: 236px;
}
.title{
  position: absolute;
  top:22%;
  left:4%;
  width: 40px;
  text-align: center;
  padding: 22px 5px;
  line-height: 22px;
  background-image: url('../../assets/img/homeEquip/rml-border.png');
  background-size: 42px 136px;
  background-position: 35% 20%;
  color:#00CDFF;
  font-size: 19px;
}
</style>