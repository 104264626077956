<template>
  <div>
    <div ref="LineRef" class="line"></div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required:true 
    },
  },
  mounted() {
    this.initLine();
  },
  watch:{
			data:{
				handler(){
					this.initLine();
			 	},
				deep:true
			}
		},
  methods: {
    initLine() {
      let _this = this;
      if(!this.data.xAxis || !this.data.series || this.data.series.length == 0){
        return
      }
      let myChart = this.$echarts.init(this.$refs.LineRef);
      let colors1 = [];
      let colors2 = [];
      for (let i = 0; i < 12; i++) {
        colors1.push({
          type: "linear",
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#ece43d", // 最左边
            },
            {
              offset: 0.5,
              color: "#ece43d", // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: "#ece43d", // 右边的左边 颜色
            },
            {
              offset: 1,
              color: "#ece43d",
            },
          ],
        });
        colors2.push({
          type: "linear",
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            {
              offset: 0,
              color: "#31D5C7", // 最左边
            },
            {
              offset: 0.5,
              color: "#86eef1", // 左边的右边 颜色
            },
            {
              offset: 0.5,
              color: "#5ad6d9", // 右边的左边 颜色
            },
            {
              offset: 1,
              color: "#31D5C7",
            },
          ],
        });
      }
      let seriesData = this.data.series;
      let max = seriesData[2].data.reduce((a,b)=>parseInt(b)>parseInt(a) ? b : a)
      const option = {
        grid: {
          right: "1%",
          left: "5%",
          bottom: "15%",
        },
        //提示框
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgb(113,200,255, 0.28)",
          borderWidth: 0,
          textStyle: {
            fontSize: 15,
            color: "#fff",
          },
          formatter: "{b}用气量: {c}Nm³",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        xAxis: {
          type: "category",
          color: "#59588D",
          data: _this.data.xAxis,
          axisLabel: {
            margin: 10,
            color: "#fff",
            textStyle: {
              fontSize: 16,
              fontWeight: 400,
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#477AA5",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          name: "（Nm³）",
          nameTextStyle: {
            color: "#fff",
            padding: [0, 35, 0, 0],
            fontSize: 16,
          },
          min: 0,
          minInterval:1,
          // interval: 40,
          // max: max*1.2,
          axisLabel: {
            color: "#fff",
            textStyle: {
              fontSize: 16,
            },
          },
          axisLine: {
            lineStyle: {
              color: "#477AA5",
              type: "dashed",
            },
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(0,90,198,.2)",
              width: 3,
            },
          },
        },
        series: [
          {
            type: "bar",
            data: seriesData[2].data,
            barWidth: "16",
            label:{
              show:true,
              position:'top',
              textStyle:{
                color:"#ECE13D",
                fontSize:15
              }
            },
            itemStyle: {
              normal: {
                // color:params=>colors1[params.dataIndex],
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,  
                      color: ["rgba(235, 218, 61, 1)"], // 0% 处的颜色
                    },
                    {
                      offset: 1,
                     color: ["rgba(235, 218, 61, 0)"], // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          },
          {
            z: 3,
            type: "pictorialBar",
            symbolPosition: "end",
            data: seriesData[2].data,
            symbol: "diamond",
            symbolOffset: ["-3%", "-50%"],
             symbolSize: [16, 16 * 0.5],
            itemStyle: {
              normal: {
                borderWidth: 0,
                color: function (params) {
                  return colors1[params.dataIndex % 6].colorStops[0].color;
                },
              },
            },
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.line {
  height: 285px;
  width: 826px;
}
</style>
