import Vue from 'vue'
// 2021
Vue.prototype.getNowYear = function () {
	var date = new Date()
	var year = date.getFullYear()
	var currentdate = year + ''
	return currentdate
}

// 2021-07
Vue.prototype.getNowYearMonth = function () {
	var date = new Date()
	var seperator1 = '-'
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}

	var currentdate = year + seperator1 + month
	return currentdate
}

// 2021-07-15
Vue.prototype.getNowDate = function () {
	var date = new Date()
	var seperator1 = '-'
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	var strDate = date.getDate()

	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}

	var currentdate = year + seperator1 + month + seperator1 + strDate
	return currentdate
}
// 2021-07-15 10:48
Vue.prototype.getNowFormatDateS = function () {
	var date = new Date()
	var seperator1 = '-'
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	var strDate = date.getDate()
	var h = date.getHours()
	var m = date.getMinutes()
	var s = date.getSeconds()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	if (h >= 0 && h <= 9) {
		h = '0' + h
	}
	if (m >= 0 && m <= 9) {
		m = '0' + m
	}
	if (s >= 0 && s <= 9) {
		s = '0' + s
	}
	var currentdate = year + seperator1 + month + seperator1 + strDate + ' ' + h + ':' + m
	return currentdate
}
// 2021-07-15 10:00:00
Vue.prototype.getNowFormatDates = function () {
	var date = new Date()
	var seperator1 = '-'
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	var strDate = date.getDate()
	var h = date.getHours()
	var m = date.getMinutes()
	var s = date.getSeconds()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	if (h >= 0 && h <= 9) {
		h = '0' + h
	}
	if (m >= 0 && m <= 9) {
		m = '0' + m
	}
	if (s >= 0 && s <= 9) {
		s = '0' + s
	}
	var currentdate = year + seperator1 + month + seperator1 + strDate + ' ' + h + ':00:00'
	return currentdate
}

// 2021-07-15 10:48:50
Vue.prototype.getNowFormatDate = function () {
	var date = new Date()
	var seperator1 = '-'
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	var strDate = date.getDate()
	var h = date.getHours()
	var m = date.getMinutes()
	var s = date.getSeconds()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	if (h >= 0 && h <= 9) {
		h = '0' + h
	}
	if (m >= 0 && m <= 9) {
		m = '0' + m
	}
	if (s >= 0 && s <= 9) {
		s = '0' + s
	}
	var currentdate = year + seperator1 + month + seperator1 + strDate + ' ' + h + ':' + m + ':' + s
	return currentdate
}
//没有间隔符号的年月日
Vue.prototype.getmyNowDate = function () {
	var date = new Date()
	var year = date.getFullYear()
	var month = date.getMonth() + 1
	var strDate = date.getDate()
	var h = date.getHours()
	var m = date.getMinutes()
	var s = date.getSeconds()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	if (h >= 0 && h <= 9) {
		h = '0' + h
	}
	if (m >= 0 && m <= 9) {
		m = '0' + m
	}
	if (s >= 0 && s <= 9) {
		s = '0' + s
	}
	console.log(year)
	console.log(month)
	console.log(strDate)
	year = year + ''
	month = month + ''
	var currentdate = year + month + strDate
	return currentdate
}
//获取前一天
Vue.prototype.getPredate = function () {
	var day1 = new Date()
	day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000)
	var year = day1.getFullYear()
	var month = day1.getMonth() + 1
	var strDate = day1.getDate()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	year = year + ''
	month = month + ''
	var s1 = year + month + strDate
	return s1
}
// 获取昨天的当前时间   2021-07-15 10:00:00
Vue.prototype.getPredateH = function () {
	var day1 = new Date()
	var seperator1 = '-'
	day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000)
	var year = day1.getFullYear()
	var month = day1.getMonth() + 1
	var strDate = day1.getDate()
	var h = day1.getHours()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	if (h >= 0 && h <= 9) {
		h = '0' + h
	}
	var s1 = year + seperator1 + month + seperator1 + strDate + ' ' + h + ':00:00'
	return s1
}

//获取前一周
Vue.prototype.getPredate7 = function () {
	var day1 = new Date()
	day1.setTime(day1.getTime() - 6 * 24 * 60 * 60 * 1000)
	var month = day1.getMonth() + 1
	var strDate = day1.getDate()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	var s1 = day1.getFullYear() + '-' + month + '-' + strDate
	return s1
}

//获取前一月
Vue.prototype.getPredate8 = function () {
	var day1 = new Date()
	day1.setTime(day1.getTime() - 29 * 24 * 60 * 60 * 1000)
	var month = day1.getMonth() + 1
	var strDate = day1.getDate()
	if (month >= 1 && month <= 9) {
		month = '0' + month
	}
	if (strDate >= 0 && strDate <= 9) {
		strDate = '0' + strDate
	}
	var s1 = day1.getFullYear() + '-' + month + '-' + strDate
	return s1
}
// 颜色转rgba

Vue.prototype.convertHexToRGB = function (color, opa) {
	if (color.length === 4) {
		let extendedColor = '#'
		for (let i = 1; i < color.length; i++) {
			extendedColor += color.charAt(i) + color.charAt(i)
		}
		color = extendedColor
	}
	const values = {
		r: parseInt(color.substr(1, 2), 16),
		g: parseInt(color.substr(3, 2), 16),
		b: parseInt(color.substr(5, 2), 16),
	}
	return `rgba(${values.r}, ${values.g}, ${values.b}, ${opa})`
}
