<template>
  <div class="empty-box">
    <img src="../assets/img/none.png" alt="">
  </div>
</template>
<script>
export default{
  data(){
    return{}
  }
}
</script>
<style lang='scss' scoped>
.empty-box{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  img{
    display: block;
    width: 256px;
    margin:0 auto;
    height: 173px;
  }
}
</style>