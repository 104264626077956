<template>
  <div class="info-list">
      <div class="info-box" v-for="info in newList" :key="info.title">
          <div class="info-count">{{info.count}}个</div>
          <div class="info-bar" :style="{backgroundImage:'url('+info.img1+')'}">
              <div class="bar-cont" :style="{backgroundImage:'url('+info.img2+')'}"></div>
          </div>
          <div class="info-title">{{info.title}}</div>
      </div>
    <!-- <div class="bar" ref="barRef"></div> -->
  </div>
</template>
<script>
export default{
    props: {
        data: {
            type: Array,
            required: true 
        },
    },
  data(){
    return{
        infoList:[
            {
                img1:require('@/assets/img/homeEquip/lt-bg.png'),
                img2:require('@/assets/img/homeEquip/bar1.png'),

            },
            {
                img1:require('@/assets/img/homeEquip/lt-bg.png'),
                img2:require('@/assets/img/homeEquip/bar2.png'),
            },
            {
                img1:require('@/assets/img/homeEquip/lt-bg.png'),
                img2:require('@/assets/img/homeEquip/bar3.png'),
            },
            {
                img1:require('@/assets/img/homeEquip/lt-bg.png'),
                img2:require('@/assets/img/homeEquip/bar4.png'),
            },
            {
                img1:require('@/assets/img/homeEquip/lt-bg.png'),
                img2:require('@/assets/img/homeEquip/bar5.png'),
            }
        ],
        newList:[]
    }
  },
  mounted () {
    this.initData()
  },
  watch:{
			data:{
				handler(){
					this.initData();
			 	},
				deep:true
			}
		},
  methods: {
      initData(){
          let _this = this;
        if(!this.data || this.data.length == 0){
            return;
        }
        let arr = JSON.parse(JSON.stringify(this.data))
        let newArr = arr.map((item,index)=>({
            count:item.value,
            img1:_this.infoList[index].img1,
            img2:_this.infoList[index].img2,
            title:item.name
        }));
                console.log(newArr);
        this.newList = newArr;

      },
    initBar() {
      let myChart = this.$echarts.init(this.$refs.barRef)
      var data = [];
for (let i = 0; i < 5; ++i) {
    data.push(Math.round(Math.random() * 100));
}
var path = 'path://M134.76,106.63A41.76,41.76,0,1,1,93,64.87,41.76,41.76,0,0,1,134.76,106.63Z';
 const option = {
    tooltip: {
        show: false,
        trigger: 'item',
    },
    grid: {
        left: '1%',
        right: '1%',
        bottom: '12%',
    },
    xAxis: {
        show: true,
        axisLine:{
          show:false
        },
        axisTick:{
          show:false
        },
        axisLabel:{
          show:true,
          color:'#5DB4DD',
          fontSize:14
        },
        data: ['气象监测', '水肥一体化设备', '墒情监测设备', '监控设备', '虫情检测设备'],
    },
    yAxis: {
        show: false,
    },
    series: [
        {
            name: '',
            type: 'pictorialBar',
            symbol: path,
            symbolOffset: [0, 0],
            z: 2,
            label:{
              show:true,
              position:"top",
              fontSize:14,
              color:"#4C97FF"
            },
            itemStyle: {
                opacity: 1,
                shadowBlur: 2,
                shadowColor: 'rgba(0, 0, 0, .3)',
                shadowOffsetY: 1,
                shadowOffsetX: 0,
                color: params=> {
                    var colorList = [
                        new this.$echarts.graphic.RadialGradient(0.4, 0.3, 1.1, [
                            {
                                offset: 0,
                                color: '#fef8a7',
                            },
                            {
                                offset: 0.3,
                                color: '#fabd41',
                            },
                            {
                                offset: 0.5,
                                color: '#eb710f',
                            },
                            {
                                offset: 1,
                                color: '#fed174',
                            },
                        ]),
                        new this.$echarts.graphic.RadialGradient(0.4, 0.3, 1.1, [
                            {
                                offset: 0,
                                color: '#feecc2',
                            },
                            {
                                offset: 0.3,
                                color: '#fd945d',
                            },
                            {
                                offset: 0.5,
                                color: '#e8491e',
                            },
                            {
                                offset: 1,
                                color: '#fd8d51',
                            },
                        ]),
                        new this.$echarts.graphic.RadialGradient(0.4, 0.3, 1.1, [
                            {
                                offset: 0,
                                color: '#fafee6',
                            },
                            {
                                offset: 0.3,
                                color: '#b0d67b',
                            },
                            {
                                offset: 0.5,
                                color: '#70a239',
                            },
                            {
                                offset: 1,
                                color: '#a7db5c',
                            },
                        ]),
                        new this.$echarts.graphic.RadialGradient(0.4, 0.3, 1.1, [
                            {
                                offset: 0,
                                color: '#fed6ee',
                            },
                            {
                                offset: 0.3,
                                color: '#e94b87',
                            },
                            {
                                offset: 0.5,
                                color: '#cf1d4d',
                            },
                            {
                                offset: 1,
                                color: '#f76495',
                            },
                        ]),
                        new this.$echarts.graphic.RadialGradient(0.4, 0.3, 1.1, [
                            {
                                offset: 0,
                                color: '#fefdf9',
                            },
                            {
                                offset: 0.3,
                                color: '#f9daab',
                            },
                            {
                                offset: 0.5,
                                color: '#deb386',
                            },
                            {
                                offset: 1,
                                color: '#fae8e0',
                            },
                        ]),
                        new this.$echarts.graphic.RadialGradient(0.4, 0.3, 1.1, [
                            {
                                offset: 0,
                                color: '#c3a6c4',
                            },
                            {
                                offset: 0.3,
                                color: '#8a6d89',
                            },
                            {
                                offset: 0.5,
                                color: '#523a51',
                            },
                            {
                                offset: 1,
                                color: '#746074',
                            },
                        ]),
                    ];
                    return colorList[params.dataIndex];
                },
            },
            symbolPosition: 'end',
            data: data,
        },
    ],
};
    var data = option.series[0].data;
    for (var i = 0; i < data.length; ++i) {
        if (Math.random() > 10) {
            data[i] += Math.round(Math.random() * 200);
        }
        else {
            data[i] += Math.round(Math.random() * 100);
        }
    }
    myChart.setOption(option);
    }
  },
}
</script>
<style lang='scss' scoped>
.info-list{
    width: 100%;
    display: flex;
    .info-box{
        flex: 1;
        text-align: center;
        .info-count{
            font-size: 31px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #FDBF46;
            line-height: 26px;

            background: linear-gradient(0deg, #1BBCFF 0%, #FFFFFF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; 
        }
        .info-bar{
            width: 155px;
            height: 118px;
            padding: 20px 0 ;
            background-size: 160px 120px;
            background-position: 0 2px;
            .bar-cont{
                width: 46px;
                height: 40px;
                margin: 0 auto;
            }
        }
        .info-title{
            margin-top: 10px;
            color:#6AD3FF;
            font-weight: 400;
            font-size:16px
        }
    }
}
// .bar{
//   	width:780px;
// 	height: 180px;
// }
</style>