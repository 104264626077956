<template>
  <div class="page">
    <div ref="mapRef" class="map"></div>
    <div class="bg"></div>
    <!-- <div class="tooltip">
      <div class="title">四川省</div>
      <div class="text">销售额<span class="text-num">5000万元</span></div>
      <div class="text">总销量<span class="text-num">5000万吨</span></div>
    </div> -->
  </div>
</template>
<script>
export default{
  props: {
    data: {
        type: Array,
        required:true
    },
  },
  data(){
    return{
      // saleData:[],
      toolImg: require("../../assets/img/homeProduction/rbl-title.png"),
      myChart:null,
      tooltipTimer:null
    }
  },
  mounted(){
    this.initMap()
  },
  watch:{
			data:{
				handler(){
					// this.pie1();
			 	},
				deep:true
			}
		},
    destroyed () {
    this.tooltipTimer && this.tooltipTimer.clearLoop();
  },
  methods: {
	  getStrFromNumber(params){
	  	var param = params.data ? params.data.saleWeight.toFixed(2) : 0  ;
	  	var newStr=''
	  	if(param>10000){
	  		newStr='w吨'
	  	}else{
	  		newStr='吨'
	  	}
	  	return newStr
	  },
	  getNumberFromWeight(params){
	  	var newVal=0;
      if(params.data && params.data.saleWeight >= 0){
        let param = JSON.parse(JSON.stringify(params.data.saleWeight))
        if(param > 10000){
          newVal = param/10000
          newVal = newVal.toFixed(2)
        } else {
          newVal = param.toFixed(2)
        }
      }
      return newVal;
    },
    async initMap() {
      let _this = this;
      this.myChart = this.$echarts.init(this.$refs.mapRef)
      const {data} = await this.$http.get('/china.json')
      this.$echarts.registerMap('China', data)
      let mapAll = this.getMapData(data)
      let mapAllLength = Object.keys(mapAll).length;
      let mapData = JSON.parse(JSON.stringify(this.data))
      let scaData = [];
      let lineData =[];
      mapData.forEach(map=>{
        map.name = _this.getMapName(map.name)
        map.saleAcount = +(map.saleAcount)
        map.value =  map.saleAcount
        map.saleWeight = +(map.saleWeight)

        let fromCoord = [117.000923, 36.675807]
        let toCoord = mapAll[map.name]
        scaData.push({
          value:toCoord,
          itemStyle:{color:'#f4af26'}
        })
        lineData.push({
          coords:[fromCoord,toCoord],
          lineStyle:{color:'#f4af26'}
        })

      })
      console.log(lineData);
      let seriesData = [
        {
          type: 'map',
          map: 'China',
          // geoIndex: 1,
          // silent:true,
          layoutCenter: ['50%', '52%'],
          layoutSize: "110%",
          // selectedMode: false,
          roam: false,
          label:{
            color:"#fff"
          },
          select:{
            label:{
            color:"#fff"
          },
            itemStyle:{
                color:"#fff",
                areaColor: '#00177B',
                borderColor: '#5AF5FF',
                borderWidth: 1
            }
          },
          itemStyle: {
              normal: {
                color:"#fff",
                areaColor: '#4795a5',
                borderColor: '#5AF5FF',
                borderWidth: 1
              },
              emphasis: {
                  label: {
                      show: true,
                      color:"#fff"
                  },
                  areaColor: '#00177B'
              }
          },
          data:mapData
        }, 
        {
          type: 'effectScatter',
          coordinateSystem: 'geo',
          showEffectOn: 'render',
          zlevel: 2,
          rippleEffect: {
            period: 15,
            scale: 4,
            brushType: 'fill'
          },
          silent:true,
          hoverAnimation: true,
          label: {
              normal: {
                  show: false,
                  position: 'inside',
                  backgroundColor: 'rgba(244, 175, 38, 0.1)',
                  formatter: '{b}',
                  padding: [8, 12],
                  borderWidth: 20,
                  color: 'rgba(244, 175, 38, 1)',

              },
              emphasis: {
                  show: true
              }
          },
          symbolSize: "5",
          itemStyle: {
              normal: {
                  show: true,
                  color: '#1DE9B6',
                  shadowBlur: 10,
                  shadowColor: 'rgba(244, 175, 38, 1)'
              }
          },
          data:scaData
        },
      ]
      lineData.forEach(info=>{
        let p1 = info.coords[0][0]
        let p2 = info.coords[1][0]
        let value = (p2-p1)/80
        seriesData.push({
          type: 'lines',
          zlevel: 2,
          silent:true,
          effect: {
            show: true,
            period: 4, //箭头指向速度，值越小速度越快
            trailLength: 0.4, //特效尾迹长度[0,1]值越大，尾迹越长重
          },
          lineStyle: {
            normal: {
              color:'#FFA758',
              width: 1, //线条宽度
              opacity: 0.1, //尾迹线条透明度
              curveness: value //尾迹线条曲直度
            }
          },
          data: [info]
        })
      })
      let initOption = {
        tooltip: {
          trigger: "item",
          position:'top',
          backgroundColor: "transparent",
          borderWidth: 0,
          borderColor:"transparent",
          padding: 0,
          textStyle:{
            color:"#fff"
          },
          formatter: function(params) {
            return `<div style = "background:url( ${_this.toolImg}) no-repeat center center;width:200px;height:85px;">
              <div style="text-align:center;line-height: 22px;
              font-size: 18px;font-weight: bold;padding-top:10px">${params.name}</div>
              <div style="line-height: 25px;text-align:center;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #969696;">销售额
                <span style="padding-left: 2px;
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  color: #FF8D3A;">${params.data ? params.data.saleAcount.toFixed(2) : 0}万元
                  </span>
              </div>
              <div style="line-height: 25px;text-align:center;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #969696;">总销量
                <span style="padding-left: 2px;
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  color: #FF8D3A;">${_this.getNumberFromWeight(params)}${_this.getStrFromNumber(params)}</span>
              </div>
            </div> `;
          }
        },
        geo: {
		 			map: 'China',
          label: {
            show:true,
            position:'insideTop',
            color: 'white',
            fontSize:13,
            formatter: `{a}`,
          },
          z:2,
          roam: false,
          silent:true,
          layoutCenter: ['50%', '52%'],
          layoutSize: "110%",
          itemStyle: {
              normal: {
                  areaColor: '#f00',
                  borderWidth: 5,
                  borderColor: '#5AF5FF',
                  shadowColor: '#5AF5FF',
                  shadowBlur: 5
              }
          },
        },
        visualMap: {
          show:true,
          seriesIndex:0,
          calculable: false,
          text: ["高", "低"],
          left: "5%",
          bottom: "2%",
          realtime: true,
          itemWidth:10,
          itemHeight:70,
          textStyle: {
            color: "#fff",
          },
            color: ["#086EC9","#42D0FF"],
        },
        series: seriesData
      }
      this.myChart.setOption(initOption)
      this.tooltipTimer && this.tooltipTimer.clearLoop();
      this.tooltipTimer = 0;
      this.tooltipTimer = this.hoverLoop(this.myChart,initOption,mapAllLength,3000)
    },
    getMapData(data){
      let obj = {};
      data.features.forEach(item=>{
        let prop = item.properties;
        obj[prop.name] = prop.cp;
      })
      return obj;
    },
    getMapName(name){
      if(name.indexOf('省') != -1 || name.indexOf('市') != -1){
        name = name.slice(0,name.length -1)
      } else if(name.indexOf('自治区')!= -1){
        name = name.slice(0,name.length-3)
      }else if(name.indexOf('特别行政区')!= -1){
        name = name.slice(0,name.length-5)
      }
      return name;
    },
    hoverLoop(myChart, option, num, time){
      var defaultData = { // 设置默认值
        time: 3000,
        num: 6
      };
      if (!time) {
        time = defaultData.time;
      }
      if (!num) {
        num = defaultData.num;
      }

      var count = 0;
      var timeTicket = 0;
      // 清除定时器
      function clearLoop() {
        if (timeTicket) {
          clearInterval(timeTicket);
          timeTicket = 0;
        }
        myChart.off('mousemove', stopAutoShow);
        myChart.getZr().off('mousemove', zRenderMouseMove);
        // myChart.getZr().off('globalout', zRenderGlobalOut);
      }

      // 关闭轮播
      function stopAutoShow() {
        if (timeTicket) {
          clearInterval(timeTicket);
          timeTicket = 0;
        }
      }

      function zRenderMouseMove(param) {
        if (param.event) {
          // 阻止canvas上的鼠标移动事件冒泡
          param.event.cancelBubble = true;
        }
        stopAutoShow();
      }


      timeTicket && clearInterval(timeTicket);
      timeTicket = setInterval(function() {
        myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0 // serieIndex的索引值   可以触发多个
        });
        myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: count
        });
        myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: count
        });
        count++;
        if (count >= num) {
          count = 0;
        }
      }, time);
      myChart.on('mouseover', function(params) {
        stopAutoShow();
        myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0
        });
        if(params.componentType != 'geo'){
          myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: params.dataIndex
        });
        }
        
        myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: params.dataIndex
        });
      });

      myChart.on('mouseout', function(e) {
         myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0 // serieIndex的索引值   可以触发多个
          });
        timeTicket && clearInterval(timeTicket);
        timeTicket = setInterval(function() {
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0 // serieIndex的索引值   可以触发多个
          });
          myChart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: count
          });
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: count
          });
          count++;
          if (count >= 14) {
            count = 0;
          }
        }, 3000);
      });
      
      return {
        clearLoop: clearLoop
      };
    }
  }
}
</script>
<style lang='scss' scoped>
.page{
  width: 580px;
  height: 460px;
}
.map{
  z-index:20;
  position: absolute;
  top: 0px;
  left:30px;
	width: 520px;
  margin:0 auto;
  height: 460px;
}
.bg{
  z-index:1;
  position: absolute;
  top: 60px;
  left:0px;
  width: 580px;
  height: 280px;
  background-image: url("../../assets/img/homeProduction/rbl-bg.png");
  background-size: 580px 280px;
  background-position: 0px 15px;
}
.tooltip{
  z-index:30;
  position: absolute;
  top: 50px;
  left:180px;
  color:#fff;
  text-align: center;
  width: 190px;
  height: 90px;
  padding:10px;
  background-image: url("../../assets/img/homeProduction/rbl-title.png");
  background-size: 190px 90px;
  .title{
    line-height: 22px;
    font-size: 18px;
font-family: Source Han Sans CN;
font-weight: bold;
  }
  .text{
      line-height: 25px;
    font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #969696;
.text-num{
  padding-left: 5px;
  font-size: 18px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #FF8D3A;
}
  }
}
</style>