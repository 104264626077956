<template>
	<div class="homeindex">
		<!--header-->
		<Topheader></Topheader>
		<div class="flex flex_ju_sb home">
			<!--地图大背景-->
			<!-- <cen-map class="cesiumNcMaps"></cen-map> -->
			<!-- 左边 -->
			<div class="left">
				<!--企业介绍-->
				<div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/homeProduction/one.png" />
					<div class="fm_pm slide-title">企业介绍</div>
				</div>
				<div class="LfContTop flex flex_ju_sb">
					<div class="title" v-if="companyList && companyList.length > 0 && curCompany">
						<div class="title1">{{ curCompany.companyName }}</div>
						<div v-show="companyList && companyList.length > 1" class="flex">
							<div class="title2 title-top" @click="tabChangeCompanyPerv" v-if="curCompanyIndex > 0">
								<span>上一个</span>
							</div>
							<div class="title2" @click="tabChangeCompany" v-if="curCompanyIndex < companyList.length">
								<span>下一个</span>
							</div>
						</div>
					</div>
					<empty v-if="!curCompany || !curCompany.comPicList || curCompany.comPicList.length == 0" />
					<div v-else class="content">
						<el-carousel indicator-position="none">
							<el-carousel-item v-for="pic in curCompany.comPicList" :key="pic">
								<img v-image-preview :src="pic" />
							</el-carousel-item>
						</el-carousel>
						<div class="info">{{ curCompany.comDetail }}</div>
					</div>
				</div>
				<!--资金投入-->
				<div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/homeProduction/four.png" />
					<div class="fm_pm slide-title">种业生产</div>
				</div>
				<!-- 种业情况 -->
				<div class="situation">
					<div class="situation-top">
						<div class="header">
							<img src="../assets/img/homeProduction/p6.png" alt="" />
						</div>
						<div class="con">
							<div class="Rileft">
								<div class="lefttop flex">
									<!--图表 占位-->
									<div class="eachZw">
										<WaveChart :colors="['#EA6C32', '#F4EC6D']" />
									</div>
									<div class="LfTpConten">
										<p>企业育种总数</p>
										<p class="colorFDFF2E">{{ curCompany.breedingEffectCount }}<span>个</span></p>
									</div>
								</div>
								<div class="lefttop flex">
									<!--图表 占位-->
									<div class="eachZw">
										<WaveChart />
									</div>
									<div class="LfTpConten">
										<p>国家审批通过</p>
										<p class="color2EBDFF">{{ curCompany.examineCount }}<span>个</span></p>
									</div>
								</div>
							</div>
							<div class="center">
								<div class="top">
									<img v-if="varietyList.length == 0" src="../assets/img/none.png" alt="" />
									<el-carousel
										v-else
										indicator-position="none"
										arrow="always"
										:autoplay="false"
										@change="changeCompany"
									>
										<el-carousel-item v-for="(item, index) in varietyList" :key="index">
											{{ item.varietyName }}
										</el-carousel-item>
									</el-carousel>
									<!-- <img
                    src="../assets/img/homeProduction/six.png"
                    alt=""
                    class="one"
                  />
                  <div class="two">
                    <span>{{curVariety.varietyName}}</span>
                  </div>
                  <img
                    src="../assets/img/homeProduction/seven.png"
                    alt=""
                    class="three"
                  /> -->
								</div>
								<div class="bottom">
									<img
										style="position: absolute; overflow: hidden; left: 100px"
										v-if="examineList.length == 0"
										src="../assets/img/none.png"
										alt=""
									/>
									<p
										v-else
										:class="infoIndex === exam.examine_num ? 'active' : ''"
										@click="setInfo(exam, index)"
										v-for="(exam, index) in examineList"
										:key="exam.examine_num"
									>
										审定编号：{{ exam.examine_num }}
									</p>
								</div>
							</div>
							<div class="r">
								<div class="r1-img" v-if="!curExamine || !curExamine.examine_character">
									<img src="../assets/img/none.png" alt="" />
								</div>
								<div class="r1" v-else>
									<span style="cursor: pointer">{{ curExamine.examine_character }}</span>
								</div>
								<img
									class="r4"
									v-if="!curVariety.picList || curVariety.picList.length == 0"
									src="../assets/img/none.png"
									alt=""
								/>
								<img v-else v-for="pic in curVariety.picList" :key="pic" :src="pic" alt="" class="r2" />
							</div>
						</div>
					</div>
					<div class="situation-bottom flex flex_ju_l">
						<div class="bottom-left">
							<empty v-if="!prodProcess || !prodProcess.xAxis || prodProcess.xAxis.length == 0" />
							<leftMiddleLeft v-else :data="prodProcess" :key="curCompany.companyName" />
						</div>
						<div class="bottom-right">
							<empty v-if="lmrArr.length == 0" />
							<leftMiddleRight v-else :data="lmrArr" />
						</div>
					</div>
				</div>
				<!--生产加工-->
				<div class="product">
					<div class="header">
						<img src="../assets/img/homeProduction/tu6.png" alt="" />
					</div>
					<div class="product-bottom flex flex_ju_l">
						<div class="product-left">
							<empty v-if="!prodProcessOne || !prodProcessOne.xAxis || prodProcessOne.xAxis.length == 0" />
							<leftBottomLeft v-else :data="prodProcessOne" :key="curCompany.companyName" />
						</div>
						<div class="product-right">
							<empty v-if="prodProcessTwo.length == 0" />
							<leftBottomRight v-else :data="prodProcessTwo" />
						</div>
					</div>
				</div>
			</div>
			<!-- 中间 -->
			<div class="center">
				<second-center></second-center>
				<!-- <router-link to="/HomeProduction/sec"><h1>走你！</h1></router-link> -->
				<!-- <router-view></router-view> -->
				<!-- <div class="CenCont flex flex_ju_sb"> -->
				<!--预警提示-->
				<!-- <div class="CenCLeft">
						<EarlyWarningList></EarlyWarningList>
					</div> -->

				<!--右侧数据-->
				<!-- <div class="CenCright">
						<div class="CenCbutlist">
							<div class="CenCbutlt" @click="tonengguan('HomeMon')">总览</div>
							<div class="CenCbutlt CenCbutlAc">生产</div>
						</div>
					</div> -->
				<!-- </div> -->
			</div>
			<!-- 右边 -->
			<div class="right">
				<!--大豆种业-->
				<div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/homeProduction/pu2.png" />
					<div class="fm_pm slide-title">实时监控</div>
				</div>
				<div class="RiContTop">
					<empty v-if="!curMonitor || !curMonitor.name || !curMonitor.value" />

					<div v-else-if="curMonitor && curMonitor.name">
						<div class="header">
							<div class="head-left">{{ curMonitor.name }}</div>
							<div class="right" @click="changeMonitor">
								<span>下一个</span>
							</div>
						</div>
						<div class="img-wrap">
							<hls-video
								ref="hlsVideoPlayer"
								:videoWidth="hlsWidth"
								:videoHeight="hlsHeight"
								@toFullScreen="toFullScreen"
								@videojsplay="videojsplay"
								class="video-player vjs-custom-skin"
								:channel="curMonitor.dataOne"
							></hls-video>
						</div>
					</div>

					<!-- empty -->
				</div>
				<!-- 销量与反馈 -->
				<div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/homeProduction/tu1.png" />
					<div class="fm_pm slide-title">销量与反馈</div>
				</div>
				<div class="saleFeedBack">
					<div class="ritbotleft">
						<empty v-if="saleFeedTwo.length == 0" />
						<rigBotLeft v-else :data="saleFeedTwo" />
					</div>
					<div class="rightbottomRight">
						<empty v-if="saleFeedOne.length == 0" />
						<rightBottomRight v-else :data="saleFeedOne" />
					</div>
				</div>
			</div>
		</div>
		<!--底部-->
		<!-- <Botfooter @totabclick="tabclick" :tabindex="tabindex" :tabindexsf="tabindexsf" @totabclicksf="tabclicksf">
		</Botfooter> -->

		<!-- 视频全屏控制 -->
		<div class="video-full" v-if="isFull">
			<div class="dbmbmc"></div>
			<div id="bigscreen"></div>
			<div class="flex flex_a_c flex_ju_sb full-control">
				<!-- 播放暂停 -->
				<div class="videobtns">
					<i v-if="screenIsPlay" class="el-icon-video-pause" @click="screenPlay"></i>
					<i v-else class="el-icon-video-play" @click="screenPlay"></i>
				</div>
				<!-- 缩小按钮 -->
				<span title="全屏" style="vertical-align: top" @click="toSmallScreen">
					<svg
						id="fullScreen"
						t="1578020167938"
						class="icon"
						viewBox="0 0 1024 1024"
						version="1.1"
						xmlns="http://www.w3.org/2000/svg"
						p-id="5035"
						width="16"
						height="16"
					>
						<path
							d="M348.373333 625.706667l-128 128-64 64v-128a33.28 33.28 0 0 0-35.413333-35.413334A33.493333 33.493333 0 0 0 85.333333 689.706667v213.333333A33.706667 33.706667 0 0 0 120.96 938.666667h213.333333a35.626667 35.626667 0 0 0 0-71.04h-128l64-64 128-128a35.2 35.2 0 0 0-49.92-49.92zM206.293333 156.373333h128a33.28 33.28 0 0 0 35.413334-35.413333A33.493333 33.493333 0 0 0 334.293333 85.333333H113.706667c-7.04 0-14.08 7.04-21.333334 14.293334a26.026667 26.026667 0 0 0-7.04 21.333333v213.333333a33.493333 33.493333 0 0 0 35.626667 35.413334 33.28 33.28 0 0 0 35.413333-35.413334v-128l192 192a35.2 35.2 0 0 0 49.92-49.92zM903.04 85.333333h-213.333333a33.493333 33.493333 0 0 0-35.413334 35.626667 33.28 33.28 0 0 0 35.413334 35.413333h128l-64 64-128 128a35.2 35.2 0 0 0 49.92 49.92l128-128 64-64v128a35.626667 35.626667 0 0 0 71.04 0v-213.333333A33.706667 33.706667 0 0 0 903.04 85.333333zM903.04 654.293333a33.28 33.28 0 0 0-35.413333 35.413334v128l-64-64-128-128a35.2 35.2 0 0 0-49.92 49.92l128 128 64 64h-128a35.626667 35.626667 0 0 0 0 71.04h213.333333A33.706667 33.706667 0 0 0 938.666667 903.04v-213.333333a33.493333 33.493333 0 0 0-35.626667-35.413334z"
							p-id="5036"
							fill="#ffffff"
						></path>
					</svg>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import EarlyWarningList from '../components/EarlyWarningList.vue'
import HlsVideo from './video/rtsp.vue'
// import HlsVideo from './video/HlsVideo.vue'
import Topheader from '../components/header.vue'
import Botfooter from '../components/footers.vue'
import LeftBottom from './ProductionComponent/LeftBottom.vue'
import WaveChart from './ProductionComponent/waveChart.vue'
import rigBotLeft from './ProductionComponent/rigBotLeft.vue'
import rigBotRight from './ProductionComponent/rigBotRight.vue'
import { regionDataPlus } from 'element-china-area-data'
import leftMiddleLeft from './ProductionComponent/leftMiddleLeft.vue'
import leftMiddleRight from './ProductionComponent/leftMidRight.vue'
import leftBottomLeft from './ProductionComponent/leftBottomLeft.vue'
import leftBottomRight from '@/views/ProductionComponent/leftBottomRight'
import rightBottomRight from './ProductionComponent/rightBottomRight.vue'
import Empty from './empty.vue'
import api from '@/api/api'
import EZUIKit from 'ezuikit-js'
import SecondCenter from '../components/secondCenter.vue'
export default {
	mixins: [],
	data() {
		return {
			isFull: false, // 视频是否是全屏状态
			screenPlayer: null, // 全屏视频控件
			screenIsPlay: true, // 视频全屏模式下是否播放

			palym3u8: false, //videojs 判断上一个视频是否成功播放
			hlsHeight: 280,
			hlsWidth: 1100,

			year: 0,
			companyList: [], // 公司信息列表
			curCompany: {}, // 当前公司信息
			curCompanyIndex: 0, // 当前公司信息下标
			lmrArr: [], // 种植户、合作社
			varietyList: [], // 品种审定情况
			curVariety: {}, // 当前审定品种,
			examineList: [], // 品种审定信息
			curExamine: {}, // 当前品种信息
			monitorList: [], // 监控列表
			curMonitor: {}, //当前监控信息
			curMonitorIndex: 0, // 当前监控下标
			prodProcess: {}, // 种植面积和作物产量
			prodProcessOne: {}, // 生产加工柱状图
			prodProcessTwo: [], // 生产加工扇形图
			saleFeedOne: [], // 销售与反馈柱状图
			saleFeedTwo: [], // 销售与反馈地图
			earlyWarnNums: 4,
			cityname1: '',
			cityname2: '',
			cityname3: '',
			cityname1list: regionDataPlus,
			cityname2list: [],
			cityname3list: [],

			tabindexsf: 0,
			tabindex: 1,
			options: regionDataPlus,
			selectedOptions: ['370000', '370800', '370829'],
			datatime: '',
			infoIndex: '',
		}
	},
	components: {
		HlsVideo,
		Topheader,
		LeftBottom,
		WaveChart,
		rigBotLeft,
		rigBotRight,
		Botfooter,
		leftMiddleLeft,
		leftMiddleRight,
		leftBottomLeft,
		leftBottomRight,
		rightBottomRight,
		Empty,
		EarlyWarningList,
		SecondCenter,
	},
	mounted() {
		this.year = new Date().getFullYear()
		this.getCompanyList()
		// this.getCropYield(this.year)
		// this.getProdProcess()
		// this.getSaleFeedBack()
	},
	methods: {
		//全屏 视频播放和暂停
		makeVideo() {
			if (this.videoIsPlay) {
				this.player.stop()
			} else {
				this.player.play()
			}
			this.videoIsPlay = !this.videoIsPlay
		},
		screenPlay() {
			if (this.screenIsPlay) {
				this.screenPlayer.stop()
			} else {
				this.screenPlayer.play()
			}
			this.screenIsPlay = !this.screenIsPlay
		},
		// 视频全屏
		toFullScreen(e) {
			console.log(e)

			const that = this
			if (this.isFull) return

			this.isFull = true
			// 加载全屏视频
			this.$nextTick(() => {
				this.screenPlayer = new EZUIKit.EZUIKitPlayer({
					id: 'bigscreen', // 视频容器ID
					accessToken: e.token,
					url: e.url,
					autoplay: true,
					template: 'simple',
					width: 5092,
					height: 1080,
					handleSuccess: function () {
						// 视频上代表播放的图标展示播放状态
						this.screenIsPlay = true
					},
					// 视频播放出错的回调
					handleError: function (res) {
						console.log(res)
						if (res && res.data) {
							that.player.stop()
							that.$message(res.data.msg, 'error')
						}
						that.screenIsPlay = false
					},
				})
			})
		},
		// 视频缩小
		// 地图点击缩小
		toSmallScreen() {
			if (!this.isFull) return
			this.isFull = false
			this.screenPlayer.stop().then(() => {
				this.screenPlayer = null
			})
		},

		getCompanyList() {
			var that = this

			this.$http.post(api.companyInfoList).then((res) => {
				console.log('企业介绍 - 种业生产', res.data.data)
				let { data } = res.data
				if (data && data.length > 0) {
					let dataF = data[0]
					if (dataF && dataF.comPic) {
						dataF.comPicList = dataF.comPic.split(',')
					}
					if (dataF.cooperativeCount >= 0 && dataF.growerCount >= 0) {
						this.lmrArr = [
							{
								name: '合作社',
								count: dataF.cooperativeCount,
								countAll: dataF.cooperativeCountAll,
							},
							{
								name: '种植户',
								count: dataF.growerCount,
								countAll: dataF.growerCountAll,
							},
						]
					}
					if (dataF.varietyList && dataF.varietyList.length > 0) {
						let dataV = dataF.varietyList[0]
						if (dataV.varietyPic) {
							dataV.picList = dataV.varietyPic.split(',')
						}
						if (dataV.examineList && dataV.examineList.length > 0) {
							this.curExamine = dataV.examineList[0]
							this.infoIndex = dataV.examineList[0].examine_num
							this.examineList = dataV.examineList
						}
						this.curVariety = dataV
						this.varietyList = dataF.varietyList
					}

					if (dataF.monitorList && dataF.monitorList.length > 0) {
						let dataM = dataF.monitorList[0]
						this.monitorList = dataF.monitorList
						console.log(dataF.monitorList)
						this.curMonitor = dataM
						this.curMonitorIndex = 0
						// 播放视频
						setTimeout(() => {
							console.log('视频地址', dataM)
							that.$refs.hlsVideoPlayer.handelVideoUrl(dataM)
						}, 1500)
					}
					this.curCompany = dataF
					this.curCompanyIndex = 0

					this.getCropYield(this.year, dataF.id)
					this.getProdProcess(this.year, dataF.id)
					this.getSaleFeedBack(this.year, dataF.id)
				}
				this.companyList = data
			})
		},
		getCropYield(year, id) {
			this.$http.post(api.acreageCropYield, { year, param: id }).then((res) => {
				console.log('种植面积和作物产量', res.data.data)
				let { data } = res.data
				this.prodProcess = data
			})
		},
		getProdProcess(year, id) {
			this.$http.post(api.productionProcessingOne, { year, param: id }).then((res) => {
				console.log('生产加工柱状图', res.data.data)
				let { data } = res.data
				this.prodProcessOne = data
			})
			this.$http.post(api.productionProcessingTwo, { year, param: id }).then((res) => {
				console.log('生产加工扇形图', res.data.data)
				let { data } = res.data
				this.prodProcessTwo = data
			})
		},
		getSaleFeedBack(year, id) {
			this.$http.post(api.saleFeedbackOne, { year, param: id }).then((res) => {
				console.log('销售与反馈柱状图', res.data.data)
				let { data } = res.data
				this.saleFeedOne = data
			})
			this.$http.post(api.saleFeedbackTwo, { year, param: id }).then((res) => {
				console.log('销售与反馈地图', res.data.data)
				let { data } = res.data
				this.saleFeedTwo = data
			})
		},
		setInfo(data, index) {
			this.infoIndex = data.examine_num
			this.curExamine = this.examineList[index]
		},
		//跳转
		tonengguan(url) {
			this.$router.push({
				name: url,
			})
		},
		tabclick(e) {
			this.$router.push({
				name: e,
			})
		},
		tabclicksf(e) {
			this.tabindexsf = e
			console.log(e)
		},
		changeMonitor() {
			console.log(this.monitorList)
			if (this.monitorList.length > 1) {
				this.curMonitor = {}
				let newIndex = this.curMonitorIndex + 1
				if (newIndex == this.monitorList.length) {
					newIndex = 0
				}
				let dataM = this.monitorList[newIndex]
				this.curMonitor = dataM
				this.curMonitorIndex = newIndex
				console.log(dataM)
				if (dataM.value) {
					this.videoBoxClick(dataM.value, dataM)
				}
			} else {
				this.$message({
					message: '暂无更多视频',
					type: 'warning',
				})
			}
		},
		changeMonitor2() {
			console.log(this.monitorList.length)
			if (this.monitorList.length > 1) {
				let newIndex = this.curMonitorIndex + 1
				if (newIndex == this.monitorList.length) {
					newIndex = 0
				}
				let dataM = this.monitorList[newIndex]
				this.curMonitor = dataM
				this.curMonitorIndex = newIndex
				console.log(dataM.value)
				if (dataM.value) {
					this.videoBoxClick(dataM.value, dataM)
				}
			}
		},
		// 企业切换上一个
		tabChangeCompanyPerv() {
			var that = this
			if (this.companyList == 1) {
				this.$message({
					message: '暂无更多信息',
					type: 'warning',
				})
				return
			}
			if (this.curCompanyIndex == 0) {
				this.$message({
					message: '当前为第一个企业',
					type: 'warning',
				})
				return
			}
			this.curMonitor = {}
			this.monitorList = []
			this.lmrArr = []
			this.curVariety = {}
			this.varietyList = []
			this.curMonitorIndex = 0
			let newIndex = this.curCompanyIndex - 1
			// if (newIndex == this.companyList.length) {
			// 	newIndex = 0
			// }
			let dataF = this.companyList[newIndex]
			if (dataF && dataF.comPic) {
				dataF.comPicList = dataF.comPic.split(',')
			}
			if (dataF.cooperativeCount >= 0 && dataF.growerCount >= 0) {
				this.lmrArr = [
					{
						name: '合作社',
						count: dataF.cooperativeCount,
						countAll: dataF.cooperativeCountAll,
					},
					{
						name: '种植户',
						count: dataF.growerCount,
						countAll: dataF.growerCountAll,
					},
				]
			}
			if (dataF.varietyList && dataF.varietyList.length > 0) {
				let dataV = dataF.varietyList[0]
				if (dataV.varietyPic) {
					dataV.picList = dataV.varietyPic.split(',')
				}
				if (dataV.examineList && dataV.examineList.length > 0) {
					this.curExamine = dataV.examineList[0]
					this.infoIndex = dataV.examineList[0].examine_num
					this.examineList = dataV.examineList
				} else {
					this.curExamine = {}
					this.infoIndex = ''
					this.examineList = []
				}
				this.curVariety = dataV
				this.varietyList = dataF.varietyList
			} else {
				this.curExamine = {}
				this.infoIndex = ''
				this.examineList = []
			}
			if (dataF.monitorList && dataF.monitorList.length > 0) {
				let dataM = dataF.monitorList[0]
				this.monitorList = dataF.monitorList
				this.curMonitor = dataM
				this.curMonitorIndex = 0

				// this.changeMonitor2()
				// 播放视频
				setTimeout(() => {
					console.log('视频地址2', dataM)
					that.$refs.hlsVideoPlayer.handelVideoUrl(dataM, 0)
				}, 1500)
			} else {
				this.monitorList = []
				this.curMonitor = {}
				this.curMonitorIndex = 0
			}
			this.curCompany = dataF
			this.curCompanyIndex = newIndex
			console.log(this.curCompanyIndex)
			this.getCropYield(this.year, dataF.id)
			this.getProdProcess(this.year, dataF.id)
			this.getSaleFeedBack(this.year, dataF.id)
		},
		// 企业切换下一个
		tabChangeCompany() {
			var that = this
			if (this.companyList == 1) {
				this.$message({
					message: '暂无更多信息',
					type: 'warning',
				})
				return
			}
			this.curMonitor = {}
			this.monitorList = []
			this.lmrArr = []
			this.curVariety = {}
			this.varietyList = []
			this.curMonitorIndex = 0
			let newIndex = this.curCompanyIndex + 1
			if (newIndex == this.companyList.length) {
				newIndex = 0
			}
			let dataF = this.companyList[newIndex]
			if (dataF && dataF.comPic) {
				dataF.comPicList = dataF.comPic.split(',')
			}
			if (dataF.cooperativeCount >= 0 && dataF.growerCount >= 0) {
				this.lmrArr = [
					{
						name: '合作社',
						count: dataF.cooperativeCount,
						countAll: dataF.cooperativeCountAll,
					},
					{
						name: '种植户',
						count: dataF.growerCount,
						countAll: dataF.growerCountAll,
					},
				]
			}
			if (dataF.varietyList && dataF.varietyList.length > 0) {
				let dataV = dataF.varietyList[0]
				if (dataV.varietyPic) {
					dataV.picList = dataV.varietyPic.split(',')
				}
				if (dataV.examineList && dataV.examineList.length > 0) {
					this.curExamine = dataV.examineList[0]
					this.infoIndex = dataV.examineList[0].examine_num
					this.examineList = dataV.examineList
				} else {
					this.curExamine = {}
					this.infoIndex = ''
					this.examineList = []
				}
				this.curVariety = dataV
				this.varietyList = dataF.varietyList
			} else {
				this.curExamine = {}
				this.infoIndex = ''
				this.examineList = []
			}
			if (dataF.monitorList && dataF.monitorList.length > 0) {
				let dataM = dataF.monitorList[0]
				this.monitorList = dataF.monitorList
				this.curMonitor = dataM
				this.curMonitorIndex = 0

				// this.changeMonitor2()
				// 播放视频
				setTimeout(() => {
					console.log('视频地址2', dataM)
					that.$refs.hlsVideoPlayer.handelVideoUrl(dataM, 0)
				}, 1500)
			} else {
				this.monitorList = []
				this.curMonitor = {}
				this.curMonitorIndex = 0
			}
			this.curCompany = dataF
			this.curCompanyIndex = newIndex
			this.getCropYield(this.year, dataF.id)
			this.getProdProcess(this.year, dataF.id)
			this.getSaleFeedBack(this.year, dataF.id)
		},
		videojsplay(data) {
			this.palym3u8 = data
			console.log(data, '能否播放')
		},

		//切换视频
		videoBoxClick(item, data) {
			this.$refs.hlsVideoPlayer.handelVideoUrl(data, 0)
		},

		changeCompany(e) {
			if (e >= this.varietyList.length) {
				return
			}
			this.curVariety = {}
			this.curExamine = {}
			this.infoIndex = ''
			this.examineList = []
			let dataV = this.varietyList[e]
			if (dataV.varietyPic) {
				dataV.picList = dataV.varietyPic.split(',')
			}
			if (dataV.examineList && dataV.examineList.length > 0) {
				this.curExamine = dataV.examineList[0]
				this.infoIndex = dataV.examineList[0].examine_num
				this.examineList = dataV.examineList
			}
			this.curVariety = dataV
		},
		handleChange(value) {
			console.log(value)
			this.cityname2list = []
			this.cityname2 = ''
			this.cityname3list = []
			this.cityname3 = ''
			this.cityname1list.forEach((item, index) => {
				console.log(value == item.value)
				if (value == item.value) {
					this.cityname2list = this.cityname1list[index].children
					console.log(this.cityname2list)
				}
			})
		},
		handleChange2(value) {
			console.log(value)
			this.cityname3list = []
			this.cityname3 = ''
			this.cityname2list.forEach((item, index) => {
				console.log(value == item.value)
				if (value == item.value) {
					this.cityname3list = this.cityname2list[index].children
					console.log(this.cityname3list)
				}
			})
		},
		handleChange3(value) {
			console.log(value)
			/* this.cityname2='';
			 
				this.cityname3=''; */
		},
	},
}
</script>
<style lang="less" scoped>
// 视频全屏样式
.video-full {
	.dbmbmc {
		position: absolute;
		width: 5092px;
		height: 1080px;
		top: 0;
		left: 0;
		z-index: 250;
	}

	position: absolute;
	width: 5092px;
	height: 1080px;
	top: 0;
	left: 0;
	z-index: 250;
	#bigscreen {
		width: 5092px;
		height: 1080px;
	}
	.full-control {
		position: absolute;
		width: 100%;
		height: 48px;
		bottom: 0;
		left: 0;
		background-color: #333;
		padding: 0 20px;
		z-index: 251;
	}
	.screen-closebtn {
		width: 40px;
		height: 40px;
		cursor: pointer;
	}
	.videobtns {
		cursor: pointer;
		color: #fff;
		font-size: 30px;
	}
}

.homebg {
	background: url('../assets/img/bg.png') no-repeat;
}
.homebgsf {
	background: url('../assets/img/sbg.png') no-repeat;
}

.home {
	height: 1080px;
	background: url('../assets/img/bgs.png') no-repeat;
	background-size: 100% 100%;

	overflow: hidden;
	margin-top: -144px;
	position: relative;

	.slide-titles {
		width: 1099px;
		background: url('../assets/img/bg_title.png') no-repeat;
		background-size: 100% 100%;

		.slide-title {
			height: 66px;
			line-height: 66px;
			font-size: 22px;
			font-family: Source Han Sans CN;
			font-weight: bold;
			color: #ffffff;
		}

		img {
			width: 50px;
			height: 50px;
			margin-top: 8px;
		}
	}

	.left {
		padding: 77px 0 0 50px;
		width: 1149px;
		background: url('../assets/img/home/bg_left.png') no-repeat;
		background-size: 100% 100%;
		// position: fixed;
		// top: 63px;
		position: relative;
		z-index: 10;
		.LfContTop {
			background: url('../assets/img/homeProduction/p3.png') no-repeat;
			background-size: 100% 100%;
			padding: 22px;
			display: flex;
			flex-direction: column;
			height: 293px;
			width: 1099px;
			.title {
				display: flex;
				justify-content: space-between;
				.title1 {
					font-size: 26px;
					line-height: 30px;
					font-weight: bold;
					color: #ffffff;
					font-family: Microsoft YaHei;
					text-shadow: 0px 0px 20px rgba(88, 170, 231, 0.4);
					background-image: -webkit-gradient(
						linear,
						left bottom,
						left top,
						color-stop(0.01, rgb(88, 170, 231)),
						color-stop(1, rgb(255, 255, 255))
					);

					/*必需加前缀 -webkit- 才支持这个text值 */

					-webkit-background-clip: text;

					/*text-fill-color会覆盖color所定义的字体颜色： */

					-webkit-text-fill-color: transparent;
				}
				.title2 {
					cursor: pointer;
					font-size: 16px;
					font-weight: bold;
					font-family: Source Han Sans CN;
					color: #ffffff;
					line-height: 40px;
					background: url('../assets/img/homeProduction/two.png') no-repeat;
					background-size: 100% 100%;
					width: 82px;
					height: 34px;
					text-align: center;
					line-height: 34px;
					span {
						// background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.01,rgb(88,170,231)), color-stop(1,rgb(255, 255, 255)));
						background-image: linear-gradient(180deg, #ffffff, #5e99cd);
						background-clip: text;
						-webkit-background-clip: text;
						color: transparent;
						/*必需加前缀 -webkit- 才支持这个text值 */

						-webkit-background-clip: text;

						/*text-fill-color会覆盖color所定义的字体颜色： */

						-webkit-text-fill-color: transparent;
					}
				}
				.title-top {
					margin-right: 20px;
				}
			}
			.content {
				display: flex;
				padding-top: 30px;
				overflow: hidden;
				.el-carousel {
					float: left;
					width: 244px;
				}
				& /deep/ .el-carousel__container {
					height: 179px;
				}
				& /deep/ .el-carousel__item {
					img {
						width: 244px;
						height: 179px;
						cursor: pointer;
					}
				}
				// .content-img {
				//   display: block;
				//   width: 244px;
				//   height: 179px;
				// }
				.info {
					width: 72%;
					height: 180px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					color: #ffffff;
					font-weight: bold;
					margin-left: 20px;
					line-height: 28px;
					overflow: auto;
					&::-webkit-scrollbar {
						width: 5px;
					}
					&::-webkit-scrollbar-track {
						background-color: #10445b;

						-webkit-border-radius: 2em;
						-moz-border-radius: 2em;
						border-radius: 2em;
					}
					&::-webkit-scrollbar-thumb {
						background-color: #01756b;
						-webkit-border-radius: 2em;
						-moz-border-radius: 2em;
						border-radius: 2em;
					}
				}
			}
		}
		.situation {
			width: 1099px;
			// height: 330px;
			.situation-top {
				height: 200px;
				.header {
					width: 841px;
					margin: 0 auto;
					height: 33px;
					img {
						width: 841px;
						height: 33px;
					}
				}
				.con {
					display: flex;
					height: 177px;
					.Rileft {
						height: 177px;
						width: 20%;
						.lefttop {
							margin-bottom: 10px;
							.eachZw {
								height: 72px;
								width: 72px;
							}
							.LfTpConten {
								margin-left: 20px;
								padding: 5px 10px;
								p {
									font-weight: bold;
									padding: 5px 0;
								}
								span {
									font-weight: bold;
									color: #ffffff;
								}
								p:nth-child(1) {
									font-size: 16px;
									color: #ffffff;
									span {
										font-size: 12px;
									}
								}
								p:nth-child(2) {
									font-size: 26px;
									span {
										font-size: 16px;
										padding-left: 10px;
									}
								}
							}
						}
					}
					.center {
						width: 25%;
						height: 177px;
						top: 0;
						.top {
							// margin-left: 15%;

							display: flex;
							align-items: center;
							& img {
								// width: 100px;
								margin: 0 auto;
								height: 45px;
							}
							.el-carousel {
								width: 170px;
								margin: 0 auto;
								height: 45px;
								text-align: center;
							}
							& /deep/ .el-carousel__container {
								height: 45px;
							}
							& /deep/ .el-carousel__arrow {
								width: 13px;
								height: 13px;
								i {
									display: none;
								}
							}
							& /deep/ .el-carousel__arrow--left {
								z-index: 5;
								background: url('../assets/img/homeProduction/six.png') no-repeat;
								background-size: 100% 100%;
							}
							& /deep/ .el-carousel__arrow--right {
								z-index: 5;
								background: url('../assets/img/homeProduction/seven.png') no-repeat;
								background-size: 100% 100%;
							}
							& /deep/ .el-carousel__item {
								background: url('../assets/img/homeProduction/eight.png') no-repeat;
								background-size: 100% 100%;
								width: 135px;
								height: 44px;
								left: 15px;
								font-size: 16px;
								font-family: Source Han Sans CN;
								font-weight: bold;
								color: #feffff;
								line-height: 44px;
								text-align: center;
							}
							.one {
								cursor: pointer;
								width: 13px;
								height: 13px;
							}
							.two {
								background: url('../assets/img/homeProduction/eight.png') no-repeat;
								background-size: 100% 100%;
								width: 135px;
								height: 44px;
								font-size: 16px;
								font-family: Source Han Sans CN;
								font-weight: bold;
								color: #feffff;
								line-height: 44px;
								text-align: center;
							}
							.three {
								cursor: pointer;
								width: 13px;
								height: 13px;
							}
						}
						.bottom {
							background-image: url('../assets/img/homeProduction/bg_2.png');
							background-size: 230px 76px;
							margin: 0 auto;
							margin-top: 30px;
							padding: 10px 10px;
							width: 230px;
							height: 76px;
							text-align: left;
							overflow-y: scroll;
							&::-webkit-scrollbar {
								width: 0px;
							}
							& img {
								// width: 210px;
								margin: 0 auto;
								height: 50px;
							}
							p {
								font-size: 16px;
								font-family: Source Han Sans CN;
								font-weight: bold;
								padding: 5px 0;
								color: #feffff;
								cursor: pointer;
							}
							p.active {
								color: #02d5f4;
							}
						}
					}
					.r {
						width: 55%;
						height: 177px;
						display: flex;
						text-align: center;
						.r1-img {
							padding: 8px;
							height: 152px;
							width: 410px;
							text-align: center;
							background-image: url('../assets/img/homeProduction/img6_3.png');
							background-size: 100% 100%;
							font-size: 16px;
							line-height: 28px;
							font-family: Source Han Sans CN;
							font-weight: bold;
							color: #ffffff;
							& > img {
								height: 134px;
								// width: 376px;
							}
						}
						.r1 {
							padding: 8px;
							height: 152px;
							width: 410px;
							text-align: left;
							overflow-y: scroll;

							&::-webkit-scrollbar {
								width: 0px;
							}
							background-image: url('../assets/img/homeProduction/img6_3.png');
							background-size: 100% 100%;
							font-size: 16px;
							line-height: 28px;
							font-family: Source Han Sans CN;
							font-weight: bold;
							color: #ffffff;
						}
						.r2 {
							width: 93px;
							height: 152px;
							margin: 0 6px;
						}
						.r3 {
							width: 88px;
							height: 152px;
						}
						.r4 {
							width: 198px;
							height: 146px;
						}
					}
				}
			}
			.situation-bottom {
				width: 1099px;
				height: 160px;
				.bottom-left {
					width: 780px;
					height: 100%;
				}
				.bottom-right {
					width: 310px;
					height: 100%;
				}
			}
		}
		.product {
			width: 1099px;
			.header {
				width: 841px;
				margin: 0 auto;
				height: 33px;
				img {
					width: 841px;
					height: 33px;
				}
			}
			.product-left {
				width: 600px;
				height: 180px;
			}
			.product-right {
				width: 490px;
				height: 180px;
			}
		}

		.LfContCent {
			padding: 10px 0;

			.LCCleft {
				width: 198px;
				height: 198px;
				background: url('../assets/img/home/img1_4.png') no-repeat;
				background-size: 100% 100%;
				text-align: center;
				padding: 46px;

				p {
					padding: 5px 0;
				}

				p:nth-child(1) {
					font-size: 27px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #2ebdff;
				}

				p:nth-child(2) {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #2cb5f5;
				}

				p:nth-child(3) {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #ffffff;
				}
			}

			.LCCright {
				width: 600px;
				padding: 10px 0 10px 20px;

				p {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #ffffff;
					padding: 12px 0;

					span {
						height: 15px;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						padding-left: 10px;
					}
				}

				.lccrBg {
					position: relative;
					height: 12px;
					margin: 0 0 14px 0;

					.lccrbgTop1 {
						height: 12px;
						border-radius: 6px;
						position: absolute;
						top: 0;
						left: 0;
						z-index: 1;
						opacity: 0.36;
						width: 100%;
					}

					.lccrbgTop2 {
						position: absolute;
						top: 0;
						left: 0;
						width: 502px;
						height: 12px;
						border-radius: 6px;
						z-index: 2;

						img {
							position: absolute;
							top: -16px;
							right: -17px;
						}
					}
				}
			}
		}
		.LfContBottom {
			margin: 10px 0 0 0;
			.LfContBotCont {
				width: 808px;
				height: 77px;
				background: rgba(6, 18, 66, 0.4);
				p {
					width: 804px;
					height: 45px;
					padding: 10px 20px;
					line-height: 30px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #ffffff;
					span {
						color: #2ebdff;
					}
				}
			}
			.echarts {
				height: 220px;
				width: 100%;
				margin-top: 10px;
			}
		}
	}

	// 中间
	.center {
		// background-color: rgba(255, 255, 255, 0.2);
		// height: 1080px;
		// width: 3300px;
		position: relative;
		z-index: 4;
		flex: 1;
		height: 30px;
		top: 120px;
		.CenCont {
			// padding: 120px 70px;
			// overflow: hidden;
			position: relative;
			// background-color: rgba(255, 255, 255, 0.5);
			.CenCLeft {
				width: 600px;
				// margin-top: -60px;
				position: absolute;
				top: 0;
				left: 100px;
			}
			.CenCCont {
				height: 136px;
				width: 1122px;
				background: url('../assets/img/home/img1_9.png') no-repeat;
				background-size: 100% 100%;
				padding: 0 90px;
				margin-top: 20px;
				.CenCCList {
					padding: 22px 0;
					p:nth-child(1) {
						font-size: 50px;
						font-family: Bahnschrift;
						font-weight: 400;
						color: #ffffff;
						margin-left: 20px;
					}
					p:nth-child(2) {
						font-size: 20px;
						font-family: Adobe Heiti Std;
						font-weight: normal;
						color: #ffffff;
						line-height: 34px;
					}
				}
			}
			.CenCright {
				position: absolute;
				right: 20px;
				z-index: 20;
				width: 590px;
				height: 136px;
				top: 200px;
				.CenCposition {
					height: 136px;
					line-height: 70px;
					width: 590px;
					img {
						height: 70px;
						width: 70px;
						margin-left: 4px;
					}
				}

				.CenCbutlist {
					// margin-top: 340px;
					width: 590px;
					.CenCbutlt {
						margin: 40px 0 0 275px;
						height: 85px;
						line-height: 85px;
						width: 220px;
						text-align: center;
						background: url('../assets/img/home/butbg.png') no-repeat;
						font-size: 24px;
						font-family: HuXiaoBo-NanShen;
						font-weight: 400;
						cursor: pointer;

						color: #ffffff;
					}
					.CenCbutlAc {
						background: url('../assets/img/home/butbg_ac.png') no-repeat;
						color: #0fe6ff;
					}
					.CenCbutlt:hover {
						transition: 0.3s;
						background: url('../assets/img/home/butbg_ac.png') no-repeat;
						color: #0fe6ff;
					}
				}
			}
		}
	}

	// 右边
	.right {
		padding: 77px 50px 0 0;
		//width: 858px;
		background: url('../assets/img/home/bg_right.png') no-repeat;
		background-size: 100% 100%;
		overflow: hidden;
		// position: fixed;
		// top: 63px;
		position: relative;
		z-index: 10;
		.RiContTop {
			width: 1099px;
			height: 399px;
			.header {
				height: 72px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				background: url('../assets/img/jkbk.png') no-repeat;
				background-size: 100% 72px;
				padding: 0 20px;

				.head-left {
					width: 336px;
					padding: 0;
					font-size: 26px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #ffffff;
					text-shadow: 0px 0px 20px rgba(88, 170, 231, 0.75);
					background-image: -webkit-gradient(
						linear,
						left bottom,
						left top,
						color-stop(0.01, rgb(88, 170, 231)),
						color-stop(1, rgb(255, 255, 255))
					);

					/*必需加前缀 -webkit- 才支持这个text值 */

					-webkit-background-clip: text;

					/*text-fill-color会覆盖color所定义的字体颜色： */

					-webkit-text-fill-color: transparent;
				}
				.right {
					width: 85px;
					cursor: pointer;
					padding: 0;
					font-size: 16px;
					font-family: Source Han Sans CN;
					// line-height: 40px;
					background: url('../assets/img/homeProduction/two.png') no-repeat;
					height: 34px;
					text-align: center;
					line-height: 34px;
					span {
						// background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.01,rgb(88,170,231)), color-stop(1,rgb(255, 255, 255)));
						background-image: linear-gradient(180deg, #ffffff, #5e99cd);
						background-clip: text;
						-webkit-background-clip: text;
						color: transparent;

						/*必需加前缀 -webkit- 才支持这个text值 */

						-webkit-background-clip: text;

						/*text-fill-color会覆盖color所定义的字体颜色： */

						-webkit-text-fill-color: transparent;
					}
				}
			}
			.img-wrap {
				height: 332px;
				width: 1100px;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
		.saleFeedBack {
			height: 460px;
			display: flex;
			.ritbotleft {
				position: relative;
				width: 580px;
				height: 460px;
			}
			.rightbottomRight {
				margin-left: 40px !important;
				width: 400px;
				height: 460px;
			}
		}
		.Ricity {
			padding: 10px 0;
			.RiCtTit {
				height: 47px;
				line-height: 47px;
				font-weight: bold;
				font-size: 16px;
				color: #24c4f5;
				padding-right: 10px;
			}
			.city1 {
				height: 47px;
				width: 135px;
				line-height: 47px;
				text-align: center;
				background: url('../assets/img/home/input.png') no-repeat;
				background-size: 100% 100%;
				.citytit {
					height: 47px;
					line-height: 47px;
					cursor: pointer;
					font-size: 16px;
					font-family: Source Han Sans CN;
					// font-weight: bold;
					color: #24c4f5;
					position: relative;
					padding-left: 20px;
					img {
						width: 11px;
						height: 9px;
						position: absolute;
						right: 16px;
						top: 20px;
					}
				}
			}
		}
		.Ritable {
			.table_th {
				div {
					background: url('../assets/img/home/taber_th.png') no-repeat;
					background-size: 100% 100%;
					font-size: 15px;
					font-weight: bold;
					color: #ffffff;
					line-height: 21px;
					padding: 5px 10px;

					text-align: center;
				}
				div:nth-child(1) {
					width: 150px;
				}
				div:nth-child(2) {
					width: 117px;
				}
				div:nth-child(3) {
					width: 100px;
				}
				div:nth-child(4) {
					width: 100px;
				}
				div:nth-child(5) {
					width: 180px;
				}
				div:nth-child(6) {
					width: 130px;
				}
			}
			.table_tdcont {
				height: 200px;
				// overflow-y: auto;
				overflow: hidden;
				position: relative;
				.table_td {
					margin: 10px 0;
					div {
						height: 27px;
						line-height: 27px;
						background: rgba(0, 0, 0, 0.1);
						font-size: 15px;
						font-weight: bold;
						color: #ffffff;
						text-align: center;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					div:nth-child(1) {
						width: 150px;
					}
					div:nth-child(2) {
						width: 117px;
					}
					div:nth-child(3) {
						width: 100px;
					}
					div:nth-child(4) {
						width: 100px;
					}
					div:nth-child(5) {
						width: 180px;
					}
					div:nth-child(6) {
						width: 130px;
					}
				}
			}

			.table_tdcont > .scroll-list {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				animation: scroll 10s linear infinite normal;
			}

			.table_tdcont > .scroll-list > .table_td {
				/* 滚动的项目需要有具体的高度 */
				height: 30px;
			}
			@keyframes scroll {
				100% {
					/* 需要滚动内容的总高度 */
					top: -600px;
				}
			}

			.table_tdcont::-webkit-scrollbar {
				width: 5px;
			}

			.table_tdcont::-webkit-scrollbar-track {
				background-color: #10445b;

				-webkit-border-radius: 2em;
				-moz-border-radius: 2em;
				border-radius: 2em;
			}

			.table_tdcont::-webkit-scrollbar-thumb {
				background-color: #01756b;
				-webkit-border-radius: 2em;
				-moz-border-radius: 2em;
				border-radius: 2em;
			}
		}
		.Ribottom {
			position: relative;
			.ritbotleft {
				width: 310px;
				height: 210px;
			}
			.ritbotright {
				width: 480px;
				height: 210px;
			}
			.ritbotcont {
				width: 213px;
				height: 96px;
				position: absolute;
				top: 0;
				right: 0px;
				background: url('../assets/img/home/yuan.png') no-repeat;
				background-size: 100% 100%;
				font-size: 17px;
				font-weight: bold;
				color: #80d9fe;
				padding: 10px 20px;
				line-height: 24px;
			}
		}
	}
	//公共颜色
	.bcolor0090FF {
		color: #0090ff;
		background: #0090ff;
	}

	.bcolor2EBDFF {
		color: #2ebdff;
		background: #2ebdff;
	}

	.bcolor03FFEC {
		color: #03ffec;
		background: #03ffec;
	}

	.bcolorFDFF2E {
		color: #fdff2e;
		background: #fdff2e;
	}

	.color0090FF {
		color: #0090ff;
	}

	.color2EBDFF {
		color: #2ebdff;
	}

	.color03FFEC {
		color: #03ffec;
	}
	.colorFDFF2E {
		color: #fdff2e;
	}
}
html ::-webkit-scrollbar-track-piece {
	background-color: transparent !important;
}
html ::-webkit-scrollbar {
	width: 0px !important;
	height: 6px !important;
}
html ::-webkit-scrollbar-track {
	background-color: #10445b !important;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius: 2em;
}

html ::-webkit-scrollbar-thumb {
	background-color: #01756b !important;
	-webkit-border-radius: 2em;
	-moz-border-radius: 2em;
	border-radius: 2em;
}
</style>
