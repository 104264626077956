<template>
	<div class="flex table_tdcont">
		<!-- <img class="cursor warnIcon" @click="changeShow" src="../assets/img/home/icon1_8.png" /> -->
		<div
			class="loadingmb"
			v-loading="isLoading"
			element-loading-text="拼命加载中"
			element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(0, 0, 0, 0)"
			custom-class="loadingmb"
		>
      <template v-if="!noData">
        <div class="listcont" >
          <vue-seamless-scroll
            class="seamless-warp"
            :class-option="classOption"
            :data="earlywarningList"
          >
            <div
              class="CenCLeftlist flex flex_ju_l"
              v-for="(item, index) in earlywarningList"
              :key="index"
            >
              <img src="../assets/img/home/icon1_8.png" />
              <div class="Yjcont">
                <p>
                  <span>{{ item.paramTypeName }}</span
                  >{{ item.addTime }}
                </p>
  
                <el-tooltip
                  popper-class="warn-tooltip"
                  :content="item.sendMsg"
                >
                  <p style="width: 398px" class="ellipsis">
                    {{ item.sendMsg }}
                  </p>
                </el-tooltip>
              </div>
            </div>
          </vue-seamless-scroll>
        </div>
      </template>
      <template v-else>
        <div class="no-data t_c">
          <img src="../assets/img/home/no-warningInfo.png" alt="">
          <p class="font_20 t_c hansan">暂无预警信息</p>
        </div>
      </template>
		</div>
	</div>
</template>

<script>
import api from '@/api/api'
export default {
	name: 'EarlyWarningList',
	// props: ['priceList'],
	data() {
		return {
			earlywarningList: [],
			typeList: ['气象站', '土壤墒情', '虫情监测', '水肥一体化', '苗情'],
			classOption: {
				step: 0.7, // 数值越大速度滚动越快
				limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
				hoverStop: true, // 是否开启鼠标悬停stop
				direction: 1, // 0向下 1向上 2向左 3向右
				// openWatch: true, // 开启数据实时监控刷新dom
				singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
				singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
				waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
			},
			isLoading: true,
      noData: false  // 是否有数据
		}
	},
	mounted() {
		this.getEarlyWarningList()
	},
	created() {},
	watch: {},
	methods: {
		// 滚动列表显隐切换
		changeShow() {
			this.isShow = !this.isShow
			if (this.isShow) {
				this.getEarlyWarningList()
			}
		},
		getEarlyWarningList() {
			let data = {
				page: 1,
				limit: 50,
			}
			this.$http.post(api.earlywarningList, data).then((res) => {
				console.log('预警信息', res.data.data)
				let { data } = res.data || []
				this.isLoading = false
				data.forEach((item) => {
					if (item && item.paramType) {
						item.paramType = +item.paramType
						item.paramTypeName = this.typeList[item.paramType]
					}
				})
				this.earlywarningList = data
        // 判断数据是否为空
        if (this.earlywarningList.length) {
          this.noData = false
        } else {
           this.noData = true
        }
			})
		},
		tabclk(index) {
			//   this.$emit('totabclick','home')
		},
		tabclksf(index) {
			// this.gettabindexsf=index;
			this.$emit('totabclicksf', index)
		},
	},
}
</script>

<style lang="less" scoped>
.table_tdcont {
	height: 265px;
	overflow: hidden;
	position: relative;
	z-index: 6;
	.loadingmb {
		width: 500px;
		font-size: 30px;
		color: #ffffff;
	}
	.warnIcon {
		height: 30px;
		width: 30px;
		margin-right: 20px;
		margin-top: 10px;
	}
	.listcont {
		.CenCLeftlist {
			width: 500px;
			height: 70px;
			background: url('../assets/img/homeMon/warn_bg.png') no-repeat;
			background-size: 100% 100%;
			margin-bottom: 16px;

			img {
				height: 30px;
				width: 30px;
				margin: 20px;
			}

			.Yjcont {
				padding: 10px 0;

				p {
					padding: 5px 0;
				}

				p:nth-child(1) {
					font-size: 14px;
					font-weight: 500;
					color: #ffffff;

					span {
						font-size: 16px;
						font-weight: bold;
						color: #ff1f1f;
						padding-right: 10px;
					}
					-webkit-backface-visibility: hidden;
					-moz-backface-visibility: hidden;
					-ms-backface-visibility: hidden;
					backface-visibility: hidden;
					-webkit-perspective: 1000;
					-moz-perspective: 1000;
					-ms-perspective: 1000;
					perspective: 1000;
					/* Other transform properties here */
				}

				p:nth-child(2) {
					font-size: 14px;
					font-weight: 500;
					color: #ffffff;
					max-width: 400px;
				}
			}
		}
	}
  .no-data {
    padding-top: 20px;
    p {
      margin-top: 20px;
    }
  }
}
</style>
<style lang="less">
.warn-tooltip {
	width: 400px;
	line-height: 1.5;
}
.loadingmb {
	width: 500px;
	font-size: 20px;
	color: #ffffff;
	.el-loading-spinner .el-loading-text {
		color: #ffffff;
		font-size: 14px;
	}
	.el-loading-spinner i {
		color: #ffffff;
		font-size: 35px;
	}
}
</style>
