<template>
	<div>
		<div class="pie" id="pie1Ref"></div>
	</div>
</template>
<script>
import HighCharts from 'highcharts'
export default {
	props: {
		data: {
			type: Array,
			require: true,
		},
	},
	data() {
		return {
			myChart: null,
		}
	},
	mounted() {
		this.initPie()
	},
	watch: {
		data: {
			handler() {
				this.myChart = null
				this.initPie()
			},
			deep: true,
		},
	},
	methods: {
		initPie() {
			if (this.data.length == 0) {
				return
			}

			let colors = ['#DFE46D', '#51D7C8', '#50C3D7']
			let data = JSON.parse(JSON.stringify(this.data))
			let opData = []
			data.forEach((item, index) => {
				opData.push([item.name, +item.value])
			})
			let option = {
				credits: {
					enabled: false, //不显示LOGO
				},
				chart: {
					type: 'pie',
					backgroundColor: 'rgba(0, 0, 0, 0)',
					options3d: {
						enabled: true,
						alpha: 68,
						viewDistance: 80,
						beta: 0,
					},
				},
				title: {
					text: '',
				},
				tooltip: {
					enabled: true,
					backgroundColor: 'rgba(255,255,255,0.3)',
					borderWidth: 0,
					shadow: false,
					style: {
						color: '#fff',
						fontSize: 16,
					},
					formatter: function () {
						let percent = this.point.percentage.toFixed(0)
						return `${this.point.name}：${percent}%`
					},
				},
				labels: {
					color: '#fff',
				},
				plotOptions: {
					pie: {
						size: '120%',
						center: ['50%', '60%'],
						// allowPointSelect: true,
						innerSize: '85%',
						cursor: 'pointer',
						depth: 25,
						colors: ['#47CFC1', '#D6D963', '#7E8BFF', '#0351D7'],
						// showInLegend:true,
						// states:{
						// 	inactive:{
						// 		opacity:1
						// 	}
						// },
						dataLabels: {
							enabled: true,
							distance: 5,
							formatter: function (index) {
								let percent = this.point.percentage.toFixed(0)
								return `${percent}%`
							},
							style: {
								//样式配置
								textOutline: 'none', //去掉文字白边
								color: '#fff',
								fontSize: 14,
							},
						},
					},
				},
				series: [
					{
						data: opData,
					},
				],
			}
			HighCharts.chart('pie1Ref', option)
		},
	},
}
</script>
<style lang="scss" scoped>
.pie {
	z-index: 10;
	width: 390px;
	height: 236px;
}
</style>
