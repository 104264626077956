<template>
  <div class="screen-container">
    <header class="screen-header">
      <!-- <img class="header-img" src="../assets/img/header.png" alt="" /> -->
      <img class="header-img" src="../assets/img/header2.png" alt="" />
      <img
        class="icon_sanjiao"
        src="../assets/img/icon_sanjiao.png"
        alt=""
        @click="showHideMoney"
      />
    </header>
  </div>
</template>

<script>
export default {
  methods: {
    showHideMoney() {
      this.$emit("changeMoney");
    },
  },
};
</script>

<style lang="less" scoped>
.screen-container {
  height: 144px;
  // position: fixed;
  // top: 0;
  // left: 0;
  position: relative;
  z-index: 5;
  .screen-header {
    width: 5090px;
    height: 144px;
    position: relative;
    .header-img {
      width: 100%;
    }
    .icon_sanjiao {
      position: absolute;
      // bottom: 0;
      bottom: -12px;
      left: 50%;
      // transform: translateX(-50%);
      transform: translateX(-40%);
      z-index: 8;
      cursor: pointer;
    }
  }
}
</style>
