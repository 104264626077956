<template>
  <div class="c_white rightsecond">
    <!-- 实时气象与核心区域预警/过程建议 -->
    <div class="right-top">
      <div class="c_white font_22 slide-titles flex flex_a_c flex_ju_sb">
        <div class="flex">
          <img class="icon_logo" src="../../assets/img/home/icon3_1.png" />
          <div class="fm_pm slide-title">
            实时气象与{{ isShowWarn ? "核心区域预警" : "农事建议" }}
          </div>
        </div>
        <img
          class="icon_switch cursor"
          src="../../assets/img/home/icon_switch.png"
          alt=""
          @click="switchWarn"
        />
      </div>
      <!-- 数值 -->
      <div class="flex rt-wrap">
        <!-- 左边天气 -->
        <div class="rt-div t_c rt-tianqi">
          <img
            src="../../assets/img/homeMon/title_qx.png"
            class="rtleft-title"
            alt=""
          />
          <div class="weather">
            <!-- <iframe
							width="450"
							scrolling="no"
							height="260"
							frameborder="0"
							allowtransparency="true"
							src="https://i.tianqi.com/?c=code&a=getcode&id=95&icon=1&color=%FFFFFFFF"
						></iframe> -->
            <iframe
              width="450"
              scrolling="no"
              height="260"
              frameborder="0"
              allowtransparency="true"
              src="https://i.tianqi.com/?c=code&a=getcode&id=95&py=jiaxiang&icon=1&color=%FFFFFFFF"
            ></iframe>
          </div>
        </div>
        <!-- 右边预警信息 -->
        <div class="rt-div rt-warning" v-if="isShowWarn">
          <img
            src="../../assets/img/homeMon/title_warn.png"
            class="rtleft-title-warn"
            alt=""
          />
          <early-warning-list></early-warning-list>
        </div>
        <!-- 过程建议 -->
        <div class="rt-div tr-jianyi" v-else>
          <img
            src="../../assets/img/homeMon/title_nsjy.png"
            class="rtleft-title-warn"
            alt=""
          />
          <div class="flex nsjy-wrap">
            <!-- <div class="nsjy-icon"></div> -->
            <img
              src="../../assets/img/homeMon/icon_nsjy.png"
              class="nsjy-icon"
              alt=""
            />
            <!-- <div class="swiper">
							<div class="swiper-wrapper">
								<div class="swiper-slide swiper-slide-active"> -->
            <div class="nsjy-word">
              <div class="words-all">
                <!-- <div class="nsjy-title">由于近期气温逐步升高，针对大豆种植采取的措施</div>
							<div class="nsjy-time">建议时间：2022年3月至4月</div>
							<div class="nsjy-con">
								这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊这里是建议啊
							</div> -->
                <h4 class="t_c nsjy-title">
                  两次降水过程有效增墒 利于北方夏播和出苗
                </h4>
                <h4 class="t_c nsjy-title">
                  未来十天夏播区大部水热适宜 利于作物幼苗生长
                </h4>
                <!-- <div class="nsjy-time word-div c_white">
                  建议时间：2022年3月至15月
                </div>
                <div class="word-div">
                  <span class="bold c_white">摘要：</span>
                  <span
                    >预计未来一周，大部春播区气温偏高、墒情适宜，利于南方早稻等播种育秧以及西北地区春小麦播种出苗；17日和22日华南大部有降水过程，春播作业受到影响，部分地区春耕春播气象条件等级为不适宜。</span
                  >
                </div> -->
                <div class="word-words">
                  <div class="bold c_white duan-title">
                    一、当前夏播进展和发育进程
                  </div>
                  <div class="word-div">
                    当前夏播已经接近尾声。河北、河南、江苏等夏大豆处于出苗期；河北、山东、山西南部、河南、陕西、新疆南部夏玉米处于出苗至三叶期，西南地区夏玉米处于七叶至拔节期。
                  </div>
                </div>
                <div class="word-words">
                  <div class="bold c_white duan-title">
                    二、近期降水过程对夏播区的影响
                  </div>
                  <div class="word-div">
                    北方夏播区分别于6月22-23日和25-27日出现两次较大范围明显降水过程。6月22日以来，西北地区东部、华北大部、黄淮、江淮、江汉等夏播区累计降水量有10～50毫米，山东北部、河南东南部、湖北大部、安徽大部等地有50～100毫米，山东中部和南部、江苏北部和湖北东北部等地部分地区达100～250毫米；降水利于增加农田土壤水分，有效改善土壤墒情，缓解前期旱情。土壤墒情监测显示，28日土壤缺墒范围较22日明显缩小，程度减轻（图1）；河北、山西、山东、河南、湖北、江苏、陕西28日08时10厘米缺墒站点比例分别较降雨前下降27、20、34、38、9、29、11个百分点。目前，夏播区大部土壤墒情适宜，利于夏玉米、夏大豆等作物出苗和幼苗生长；仅陕西中北部、山西大部、河南西北部等地墒情仍偏差。
                  </div>
                  <!-- <div class="word-div">
                    3月17日白天，春播区大部水热条件良好，利于春播顺利开展及春播作物出苗生长，春耕春播气象条件等级为适宜。华南东部和北部部分地区有小到中雨，对早稻、春玉米播种略有影响，春播气象条件等级为次适宜；福建西北部有5毫米以上降水，春耕春播气象条件等级为不适宜。
                  </div>
                  <div class="word-div">
                    3月18-19日白天，春播区大部天气晴好，墒情适宜，水热条件利于春耕作业和旱地作物播种、早稻播种育秧及幼苗生长，春耕春播气象条件等级为适宜。
                  </div>
                  <div class="word-div">
                    3月20-21日白天，华南北部、西南地区东部春播区有5毫米以下降水，对大田作业略有影响，春播气象条件等级为次适宜；其余春播区气象条件利于春播，春耕春播气象条件等级为适宜。
                  </div>
                  <div class="word-div">
                    3月22日白天，华南春播区大部有降水，局地中到大雨，并伴随有雷暴大风，春播气象条件等级为次适宜到不适宜；其余春播区春耕春播气象条件等级为适宜。
                  </div> -->
                </div>
                <div class="word-words">
                  <div class="bold c_white duan-title">
                    三、未来10天夏播区天气展望和影响预估
                  </div>
                  <div class="word-div">
                    预计未来10天，夏播区大部水热适宜，利于夏播作物幼苗生长。华北、黄淮、江淮等地无大范围持续性高温，多降雨天气，累计降雨量有25～60毫米，其中华北累计降雨量较常年同期明显偏多。29-30日，京津冀、山东、河南、湖北、苏皖等地有小到中雨，部分地区大雨，局地有暴雨，并伴有短时强降水、雷暴大风等强对流天气。降雨将进一步改善上述地区土壤墒情和增加库塘蓄水，河南、山西等地土壤缺墒有望缓解，利于夏玉米、大豆等作物出苗和生长发育；但部分地区可能出现短时强降水，低洼田块可能出现渍涝，不利作物生长。未来10天，西北地区多高温天气，降水偏少，陕西等地部分地区土壤缺墒持续。
                  </div>
                  <!-- <div class="word-div">
                    西南春播区注意利用好降水天气，做好农田蓄水工作，确保春播用水充足。
                  </div>
                  <div class="word-div">
                    西北春播区要密切气温回暖情况，土壤蒸发较大时，注意播后适时镇压保墒。
                  </div> -->
                </div>
                <div class="word-words">
                  <div class="bold c_white duan-title">四、农业生产建议</div>
                  <div class="word-div">
                    夏播区要利用降水有利时机做好夏种扫尾工作，加强夏玉米、大豆等作物的苗期管理，注意查苗补苗，对缺苗断垄地块趁墒补种补栽，确保苗齐苗壮。
                  </div>
                  <div class="word-div">
                    陕西等土壤墒情偏差地区要积极开展抗旱工作，合理调配水源、及时灌溉，确保夏播作物正常出苗和生长。
                  </div>
                  <div class="word-div">
                    降水偏多地区需及时疏通沟渠，防止湿渍害，并做好大风、冰雹等灾害性天气的防范工作，避免或减轻灾害损失。
                  </div>
                </div>
              </div>
            </div>
            <!-- </div>
							</div>
						</div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 产量分析与评价/视频监控  -->
    <div class="right-bottom">
      <!-- 标题 -->
      <div class="c_white font_22 slide-titles flex flex_a_c flex_ju_sb">
        <div class="flex">
          <img
            class="icon_logo"
            v-if="isShowVideo"
            src="../../assets/img/home/icon3_2.png"
          />
          <img
            class="icon_logo"
            v-else
            src="../../assets/img/home/icon3_1.png"
          />
          <div class="fm_pm slide-title">
            {{ isShowVideo ? "视频监控" : "产量分析与评价" }}
          </div>
        </div>
        <img
          class="icon_switch cursor"
          src="../../assets/img/home/icon_switch.png"
          alt=""
          @click="switchChart"
        />
      </div>
      <div class="rb-wrap">
        <!-- 视频监控 -->
        <div v-if="isShowVideo">
          <div class="flex flex_wrap rb-videos" v-if="videosList.length">
            <!-- <div class="videodiv" id="videodiv"></div> -->
            <div
              class="videodiv"
              v-for="(item, index) in videosList"
              :key="index"
            >
              <div v-if="item.isOnline == '0'">
                <!-- 视频 -->
                <div class="videoopen" :id="'videoopen' + index"></div>
                <!-- 遮罩蒙层，主要用于双击事件 -->
                <!-- <div class="video-mb" @dblclick="toFullScreen(item, index)"></div> -->
                <!-- 底部的播放和全屏操作 -->
                <div class="flex flex_a_c flex_ju_sb video-footers">
                  <!-- 播放和暂停 -->
                  <div class="video-plays">
                    <i
                      class="video-playicon el-icon-video-pause"
                      v-if="item.videoIsPlay"
                      @click="makeVideo(item, index)"
                    ></i>
                    <i
                      class="video-playicon el-icon-video-play"
                      v-else
                      @click="makeVideo(item, index)"
                    ></i>
                  </div>
                  <!-- 全屏 -->
                  <!-- <div class="videofull">
									<img
										class="cursor icon_full"
										src="../../assets/img/icon_full.png"
										alt=""
										@click="toFullScreen(index)"
									/>
								</div> -->
                </div>
              </div>
              <div
                v-else
                class="flex flex_col flex_a_c flex_ju_c video-outline"
              >
                <img src="../../assets/img/noplay.png" alt="" />
                <div class="outline-word">设备已离线</div>
              </div>
            </div>
          </div>
          <div v-else class="rb-videos video-none">
            <img src="../../assets/img/none.png" alt="" />
          </div>
        </div>
        <!-- 产量分析与评价  -->
        <div v-else class="flex rb-charts">
          <div class="LfContBotleft">
            <div class="LfContBotlefttit"></div>
            <!--图表占位-->
            <div class="LfContBeachimg">
              <empty
                v-if="
                  !productionAnalys ||
                  !productionAnalys.xAxis ||
                  productionAnalys.xAxis.length == 0
                "
              />
              <lefBotLeft v-else :data="productionAnalys" />
            </div>
          </div>
          <div class="LfContBotleft">
            <div class="LfContBotlefttit2"></div>
            <!--图表占位-->
            <div class="LfContBeachimg">
              <empty v-if="yieldEvaluation.length == 0" />
              <lefBotRight v-else :data="yieldEvaluation" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <full-screen
      v-if="isFull"
      ref="fullScreen"
      @smallScreen="smallScreen"
    ></full-screen>
  </div>
</template>

<script>
/**
 * @description 左边中间的组件：资金投入和物联网设备数据
 */
import EarlyWarningList from "@/components/EarlyWarningList.vue";
import Empty from "../empty.vue";
import lefBotLeft from "../MonComponent/lefBotLeft.vue";
import lefBotRight from "../MonComponent/lefBotRight.vue";
import EZUIKit from "ezuikit-js";
import FullScreen from "../../components/fullScreen.vue";
import bus from "../../api/bus";
import video from "../../api/rtsp-for";
var oneTime = null;
var twoTime = null;
export default {
  mixins: [video],
  components: {
    EarlyWarningList,
    Empty,
    lefBotLeft,
    lefBotRight,
    FullScreen,
  },
  data() {
    return {
      isShowWarn: true, // 是否显示预警信息
      isShowVideo: false, // 显示的是否是视频监控
      productionAnalys: {}, // 产量分析
      yieldEvaluation: [], // 产量评价占比
      accessTokenOne: "",
      accessTokenTwo: "",
      // 右侧视频播放列表
      // videosList: [
      // 	{
      // 		name: '',
      // 		equipId: 'J16116988',
      // 		channel: '1',
      // 		isOnline: '1',
      // 		videoIsPlay: true, // 视频是否是播放状态
      // 	},
      // 	{
      // 		name: '',
      // 		equipId: 'J16116996',
      // 		channel: '1',
      // 		isOnline: '1',
      // 		videoIsPlay: true, // 视频是否是播放状态
      // 	},
      // 	{
      // 		name: '',
      // 		equipId: 'J16116986',
      // 		channel: '2',
      // 		isOnline: '1',
      // 		videoIsPlay: true, // 视频是否是播放状态
      // 	},
      // 	{
      // 		name: '',
      // 		equipId: 'J16116992',
      // 		channel: '2',
      // 		isOnline: '0',
      // 		videoIsPlay: true, // 视频是否是播放状态
      // 	},
      // ],
      videosList: [],
      players: {
        player0: null,
        player1: null,
        player2: null,
        player3: null,
      },
      isFull: false,
      layout: "1x1", // 视频播放布局
      videoId: "videoopen",
      videoWidth: 448,
      videoHeight: 170,
      equipId: "",
      isGroup: false,
      clickTime: 4, // 视频和图表点击后中间的间隔
      clickTimer: null,
    };
  },
  mounted() {
    console.log("mounted");
    // 产量分析
    this.getProductAnalys();
    // 产量评价占比
    this.getYieldEvaluation();
    // 获取视频监控列表
    // this.getVideoList()
    // 获取token
    // this.getToken()
    // 右侧两个切换
    this.timeOneIntel();
    this.timeTwoIntel();
    // 点击文化长廊弹窗切换为图表
    bus.$on("toggleChart", (param) => {
      if (this.clickTimer) {
        clearInterval(this.clickTimer);
        this.clickTime = 4;
      }
      // console.log('我被触发了')
      const { action } = param;
      if (action === "toggleChart") {
        this.handlerToggleChart();
      } else if (action === "closeChart") {
        // console.log('我被关闭；额')
        this.clickTime = 4;
        setTimeout(() => {
          this.timeTwoIntel();
        }, 10);
      }
    });
    // 监听页面切换切换暂停事件
    bus.$on("clearTime", () => {
      if (oneTime) {
        clearInterval(oneTime);
      }
      if (twoTime) {
        clearInterval(twoTime);
      }
      if (this.isShowVideo) {
        window.removeEventListener("resize", this.listenResize);
        document.removeEventListener("scroll", this.listenScroll);
        for (let i in this.videosList) {
          this.closeVideo(i);
        }
      }
    });
  },
  // 页面销毁，自动切换暂停
  beforeDestroy() {
    console.log("beforeDestroy");
    bus.$off("clearTime");
    bus.$off("startTime");
    bus.$off("toggleChart");
    if (oneTime) {
      clearInterval(oneTime);
    }
    if (twoTime) {
      clearInterval(twoTime);
    }
    if (this.clickTimer) {
      clearInterval(this.clickTimer);
      this.clickTime = 4;
    }
    window.removeEventListener("resize", this.listenResize);
    document.removeEventListener("scroll", this.listenScroll);
    if (this.isShowVideo) {
      for (let i in this.videosList) {
        this.closeVideo(i);
      }
    }
  },
  methods: {
    getProductAnalys() {
      this.$http.post(this.$api.productionAnalys).then((res) => {
        let { data } = res.data;
        this.productionAnalys = data;
      });
    },
    getYieldEvaluation() {
      this.$http.post(this.$api.yieldEvaluation).then((res) => {
        let { data } = res.data;
        this.yieldEvaluation = data;
      });
    },
    // 获取视频监控列表
    async getVideoList() {
      const { data: res } = await this.$http.post(this.$api.getFacListState);
      this.videosList = [];
      // let ids = [
      // 	'17bb2c8c8c8b45cabe351b9d90ab0d4e',
      // 	'04c3639009a04cbcb488a2dad1e46036',
      // 	'08eda05e7c414ec0bb78face4d4cee30',
      // 	'459e0faf09864ca0a50e2717d6807df9',
      // ]
      // const res = {
      // 	code: 0,
      // 	data: [
      // 		{
      // 			channelNum: 'fff',
      // 			isOnline: '0',
      // 		},
      // 		{
      // 			channelNum: 'fff',
      // 			isOnline: '0',
      // 		},
      // 		{
      // 			channelNum: 'fff',
      // 			isOnline: '0',
      // 		},
      // 		{
      // 			channelNum: 'fff',
      // 			isOnline: '1',
      // 		},
      // 		{
      // 			channelNum: 'fff',
      // 			isOnline: '1',
      // 		},
      // 	],
      // }
      if (res.code == 0) {
        let datas = res.data || [];
        if (datas && datas.length) {
          let videosList = [];
          datas.forEach((item, index) => {
            // if (item.isOnline == '0') {
            videosList.push({
              name: "",
              equipId: item.facilityId,
              // equipId: ids[index],
              channel: item.channelNum,
              isOnline: item.isOnline,
              videoIsPlay: item.isOnline == "0" ? true : false, // 视频是否是播放状态
            });
            // }
          });
          this.videosList = videosList.slice(0, 4);
          this.$nextTick(() => {
            for (let i in this.videosList) {
              if (this.videosList[i]["isOnline"] == "0") {
                this.initVideo(i);
              }
            }
          });
          console.log(this.videosList);
          // this.$nextTick(() => {
          // 	if (this.accessTokenOne == '' || this.accessTokenTwo == '') {
          // 		// 获取token
          // 		this.getToken()
          // 	} else {
          // 		setTimeout(() => {
          // 			for (let i = 0; i < this.videosList.length; i++) {
          // 				this.mianfun(this.videosList[i], i)
          // 			}
          // 		}, 200)
          // 	}
          // })
        } else {
          this.videosList = [];
        }
      } else {
        this.videosList = [];
      }
    },
    // 切换预警和过程建议
    switchWarn() {
      this.isShowWarn = !this.isShowWarn;
      clearInterval(oneTime);
      setTimeout(() => {
        this.timeOneIntel();
      }, 10);
    },
    // 图表和视频的切换
    switchChart(isClear = true) {
      // 当前是视频页
      if (this.clickTime < 3) {
        return this.$messages.error("点击太频繁了");
      }
      if (this.clickTimer) {
        clearInterval(this.clickTimer);
        this.clickTime = 0;
      }
      // let thisShowVideo = !this.isShowVideo
      this.isShowVideo = !this.isShowVideo;
      if (this.isShowVideo) {
        this.clickTime = 0;
        this.getVideoList();
        this.makeListen();
        // if (this.videosList.length) {
        // 	if (this.accessTokenOne == '' || this.accessTokenTwo == '') {
        // 		// 获取token
        // 		this.getToken()
        // 	} else {
        // 		setTimeout(() => {
        // 			for (let i = 0; i < this.videosList.length; i++) {
        // 				this.mianfun(this.videosList[i], i)
        // 			}
        // 		}, 200)
        // 	}
        // } else {
        // 	this.getVideoList()
        // }
        setTimeout(() => {
          this.clickTimer = setInterval(() => {
            this.clickTime += 1;
          }, 1000);
        }, 10);
      } else {
        console.log("makeclosevideo");
        window.removeEventListener("resize", this.listenResize);
        document.removeEventListener("scroll", this.listenScroll);
        this.$nextTick(() => {
          console.log("makeclosevideo2");

          for (let i in this.videosList) {
            this.closeVideo(i);
          }
        });
        setTimeout(() => {
          this.clickTime = 4;
        }, 500);
        // for (let i = 0; i < this.videosList.length; i++) {
        // 	if (this.players['player' + i]) {
        // 		this.players['player' + i].stop()
        // 		this.players['player' + i] = null
        // 	} else {
        // 		this.players['player' + i] = null
        // 	}
        // }
      }

      if (isClear) {
        clearInterval(twoTime);
        setTimeout(() => {
          this.timeTwoIntel();
        }, 10);
      }
    },
    // 视频的token
    async getToken() {
      // if (this.equipId == 'J24945145') {
      // 	this.channelNoP = '1'
      // }
      // this.accessToken = 'at.8sjtom720gn356097pexpt45bc7rie7v-26kdfrpeqw-1r540go-bhp0kitfy'
      // this.accessToken = 'at.5q23djku7r274gq1bpg6xtb95599s70b-5mmp7x56dg-1q6a6lw-vt5qlzsfi'
      const { data: res } = await this.$http.post(this.$api.getToken);
      if (res.code == 0) {
        this.accessTokenOne = res.data[0];
        this.accessTokenTwo = res.data[1];
        // this.accessToken = res.data[Number(this.channelNoP) - 1]
        // this.mianfun('ezopen://open.ys7.com/G59770545/1.live')
        setTimeout(() => {
          for (let i = 0; i < this.videosList.length; i++) {
            this.mianfun(this.videosList[i], i);
          }
        }, 200);
      }
    },
    mianfun(items, index) {
      // 不在线，不做播放
      if (items.isOnline == "1") return;
      console.log("地址替换mmmmmmmmmmmmmmmmmmmmmmm");
      const that = this;
      this.videosList[index].videoIsPlay = true;
      let a = "ezopen://open.ys7.com/" + items.equipId + "/1.live";
      // 存在，直接替换
      if (this.players["player" + index]) {
        this.players["player" + index].stop().then(() => {
          this.players["player" + index].play(a);
        });
        // 视频上代表播放的图标展示播放状态
        this.videosList[index].videoIsPlay = true;
      } else {
        this.players["player" + index] = new EZUIKit.EZUIKitPlayer({
          id: "videoopen" + index, // 视频容器ID
          accessToken:
            items.channel == "1" ? this.accessTokenOne : this.accessTokenTwo,
          url: a,
          autoplay: true,
          template: "simple",
          width: 448,
          height: 175,
          handleSuccess: function () {
            console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
            // 视频上代表播放的图标展示播放状态
            that.videosList[index].videoIsPlay = true;
          },
          // 视频播放出错的回调
          handleError: function (res) {
            if (res && res.data) {
              if (that.players["player" + index]) {
                that.players["player" + index].stop();
                that.players["player" + index] = null;
              } else {
                that.players["player" + index] = null;
              }

              that.$messages.error(res.data.msg);
            }
            that.videosList[index].videoIsPlay = false;
          },
        });
      }
    },
    makeVideos({ videoIsPlay }, index) {
      if (videoIsPlay) {
        this.players["player" + index].stop();
      } else {
        this.players["player" + index].play();
      }
      this.videosList[index]["videoIsPlay"] =
        !this.videosList[index]["videoIsPlay"];
    },
    // toFullScreen({ equipId, channel }) {
    // 	this.isFull = true
    // 	let tokens = channel == '1' ? this.accessTokenOne : this.accessTokenTwo
    // 	// 清除视频的定时切换

    // 	this.$nextTick(() => {
    // 		this.$refs.fullScreen.mianFullfun(equipId, tokens)
    // 		clearInterval(twoTime)
    // 	})
    // },
    // smallScreen() {
    // 	this.isFull = false
    // 	setTimeout(() => {
    // 		this.timeTwoIntel()
    // 	}, 100)
    // },
    clearTime() {
      clearInterval(twoTime);
    },
    // 自动切换设置
    timeOneIntel() {
      oneTime = setInterval(() => {
        this.isShowWarn = !this.isShowWarn;
      }, 60 * 1000 * 5);
    },
    timeTwoIntel() {
      // twoTime = setInterval(() => {
      // 	this.switchChart(false)
      // }, 60 * 1000 * 5)
    },
    stopTimeTwo() {
      clearInterval(twoTime);
    },
    handlerToggleChart() {
      if (twoTime) {
        clearInterval(twoTime);
      }
      if (this.isShowVideo) {
        window.removeEventListener("resize", this.listenResize);
        document.removeEventListener("scroll", this.listenScroll);
        for (let i in this.videosList) {
          this.closeVideo(i);
        }
      }
      setTimeout(() => {
        this.isShowVideo = false;
      }, 500);
    },
  },
};
</script>

<style lang="less" scoped>
.rightsecond {
  // position: relative;
  z-index: 12;
  padding-top: 77px;
  width: 950px;
  margin-left: 20px;
  // titles
  .slide-titles {
    width: 950px;
    background: url("../../assets/img/bg_title.png") no-repeat;
    background-size: 100% 100%;

    .slide-title {
      height: 66px;
      line-height: 66px;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
    }

    .icon_logo {
      width: 40px;
      height: 40px;
      margin-top: 13px;
      margin-right: 12px;
      margin-left: 4px;
    }
    .icon_switch {
      width: 38px;
      height: 39px;
      margin-right: 9px;
    }
  }
  // 实时气象与核心区域预警
  .rt-wrap {
    margin-top: 16px;
    padding-bottom: 20px;
    // 实时气象
    .rt-div {
      width: 50%;
    }
    .rtleft-title {
      width: 391px;
      height: 32px;
    }
    .rtleft-title-warn {
      height: 32px;
      display: block;
      margin: 0 auto 5px;
    }
    // 实时气象
    .weather {
      width: 450px;
      height: 265px;
      background-color: rgba(2, 31, 70, 0.28);
      margin: 5px auto 0;
    }
    // 农事建议
    .nsjy-wrap {
      .nsjy-icon {
        width: 36px;
        height: 35px;
        margin-right: 10px;
      }
      .nsjy-word {
        width: 450px;
        height: 265px;
        background-color: rgba(2, 31, 70, 0.28);
        padding: 10px 20px;
        color: #e0d9d9;
        letter-spacing: 1px;
        line-height: 1.5;
        overflow-y: hidden;

        .words-all {
          animation: myMove 40s linear infinite; // 重点，定义动画
          animation-fill-mode: forwards;
        }
      }
      /*文字无缝滚动*/
      @keyframes myMove {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-1100px);
        }
      }

      .duan-title {
        margin: 15px 0;
      }
      .word-div {
        text-indent: 2em;
        line-height: 1.5;
      }
      .nsjy-title {
        color: #3ccbf6;
      }
      .nsjy-time {
        margin: 15px 0;
      }
      .nsjy-con {
        color: #fff;
        line-height: 1.5;
        letter-spacing: 1px;
      }
    }
  }
  // 产量分析与评价
  .rb-wrap {
    .LfContBotleft {
      width: 50%;
      margin-top: 23px;
    }
    .LfContBotlefttit {
      width: 390px;
      height: 31px;
      background: url("../../assets/img/homeMon/clfx.png") no-repeat;
      background-size: 100% 100%;
      margin: auto;
    }
    .LfContBotlefttit2 {
      width: 390px;
      height: 31px;
      background: url("../../assets/img/homeMon/clpgzb.png") no-repeat;
      background-size: 100% 100%;
      margin: auto;
    }
    // 视频监控
    .rb-videos {
      width: 920px;
      margin: 3px auto 0;
      .videodiv {
        // // width: 453px;
        // // height: 180px;
        // width: 910px;
        // height: 365px;
        // background: #033e6f;
        // border: 2px solid #25577e;
        // // position: relative;
        // margin-top: 10px;
        // // &:nth-child(2n) {
        // // 	margin-left: 13px;
        // // }
        width: 453px;
        height: 200px;
        background: #033e6f;
        border: 2px solid #25577e;
        // position: relative;
        margin-top: 10px;
        &:nth-child(2n) {
          margin-left: 13px;
        }
      }
      .videoopen {
        width: 100%;
        height: 170px;
      }
      .video-mb {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      .video-footers {
        width: 100%;
        height: 25px;
        // position: absolute;
        background: rgba(0, 0, 0, 0.42);
        border-radius: 12px;
        padding: 0 13px;

        .video-playicon {
          font-size: 18px;
          cursor: pointer;
        }
      }
      .videofull {
        width: 20px;
        height: 20px;
        // position: absolute;
        // top: 10px;
        // right: 15px;
        padding: 3px 0 0 3px;
        .icon_full {
          width: 14px;
          height: 14px;
        }
      }
      // 离线设备
      .video-outline {
        width: 100%;
        height: 100%;
        background-color: #000;
        img {
          width: 40px;
        }
        .outline-word {
          font-size: 16px;
          margin-top: 15px;
        }
      }
    }
    .video-none {
      text-align: center;
      img {
        width: 40%;
        margin-top: 30px;
      }
    }
  }
}
</style>
