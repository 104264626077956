import axios from 'axios'
import { Message } from 'element-ui'
let axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
	(config) => {
		return config
	},
	(err) => {
		return Promise.reject(err)
	}
)

axiosInstance.interceptors.response.use(
	(reponse) => {
		return reponse
	},
	(error) => {
		if (error.response) {
			switch (error.response.status) {
				case 401:
					return Promise.reject('token失效，请重新登录')
				case 404:
					return Promise.reject('网络错误，请检查网络连接或联系维护人员')
				case 504:
					return Promise.reject('连接服务器失败')
				default:
					return Promise.reject('')
			}
		}
	}
)
function get(url, params) {
	return axiosInstance.get(url, {
		params,
	})
}
function post(url, data) {
	return axiosInstance.post(url, data)
}

function https(url, type, requestContent, successCallback, isCen = false, isAsync = true, isJson = true, failCallback) {
	if (type == null) {
		type = 'POST'
	}
	function invocationFailure(error) {
		// showToast('无法连接至服务器,请稍后再试')
		// Message({
		// 	message: '无法连接至服务器,请稍后再试',
		// 	customClass: 'pagemessage',
		// 	type: 'error',
		// })
		if (failCallback && typeof failCallback == 'function') {
			failCallback()
		}
	}
	// var path = 'http://nywlw.hnyfkj.com' // 正式
	var path = 'https://nywlw.hnyfkj.com' // 正式
	// var path = 'http://hnw.vaiwan.com'   // 本地
	// var path = 'https://ibms.hnyfkj.com' // 测试
	$.ajax({
		type: type,
		url: path + url,
		data: requestContent,
		async: isAsync,
		success: successCallback,
		processData: isJson, // 使用formData传参很重要的配置
		contentType: isJson ? 'application/json; charset=utf-8' : false,
		dataType: 'json',
		error: function (jqXHR) {
			invocationFailure(jqXHR)
		},
	})
}
function https_(
	url,
	type,
	requestContent,
	successCallback,
	isCen = false,
	isAsync = true,
	isJson = true,
	failCallback
) {
	if (type == null) {
		type = 'POST'
	}
	function invocationFailure(error) {
		// showToast('无法连接至服务器,请稍后再试')
		// Message({
		// 	message: '无法连接至服务器,请稍后再试',
		// 	customClass: 'pagemessage',
		// 	type: 'error',
		// })
		if (failCallback && typeof failCallback == 'function') {
			failCallback()
		}
	}
	// var path = 'http://nywlw.hnyfkj.com' // 正式
	var path = 'https://nywlw.hnyfkj.com' // 正式
	// var path = 'http://hnw.vaiwan.com'   // 本地
	// var path = 'https://ibms.hnyfkj.com' // 测试
	$.ajax({
		type: type,
		url: path + url,
		data: requestContent,
		async: isAsync,
		success: successCallback,
		processData: isJson, // 使用formData传参很重要的配置
		contentType: isJson ? 'application/json; charset=utf-8' : false,
		dataType: 'json',
		error: function (jqXHR) {
			invocationFailure(jqXHR)
		},
	})
}
export { post, get, https, https_ }
