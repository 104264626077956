import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomeMon from '../views/HomeMon.vue'
import HomeBrain from '../views/HomeBrainc.vue'
import HomeEnergy from '../views/HomeEnergy.vue'
import HomeEquip from '../views/HomeEquip.vue'
import HomeAbnormal from '../views/HomeAbnormal.vue'
import HomeProduction from '../views/HomeProduction.vue'
// 文化长廊
import Visual from '../views/ThreeDVisual.vue'

// 添加
// import second from '../components/secondCenter.vue'

import Map from '../views/Map.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/home',
	},
	{
		path: '/home',
		name: 'home',
		component: Home,
	},
	{
		path: '/HomeMon',
		name: 'HomeMon',
		component: HomeMon,
	},
	{
		path: '/HomeBrain',
		name: 'HomeBrain',
		component: HomeBrain,
	},
	{
		path: '/HomeEnergy',
		name: 'HomeEnergy',
		component: HomeEnergy,
	},
	{
		path: '/HomeEquip',
		name: 'HomeEquip',
		component: HomeEquip,
	},
	{
		path: '/HomeAbnormal',
		name: 'HomeAbnormal',
		component: HomeAbnormal,
	},
	{
		path: '/HomeProduction',
		name: 'HomeProduction',
		component: HomeProduction,
		// children: [
		//   {
		//     path: 'sec',
		//     component: second
		//   }
		// ]
	},
	{
		path: '/map',
		component: Map,
	},
	{
		path: '/visual',
		name: 'visual',
		component: Visual,
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
