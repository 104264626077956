<template>
  <div class="piePage">
    <div ref="pieRef" class="pie"></div>
    <div class="pie-title">
      <p class="title-count">{{curData.value}}<span>亩</span></p>
      <p class="title-name">{{curData.name}}面积</p>
    </div>
  </div>
</template>
<script>
export default{
  props: {
    data: {
      type: Array,
      required: true 
    },
  },
  data(){
    return{
      index:'',
      curData:{},
      tooltipTimer:null,

      myChart: null,
    }
  },
  mounted(){
    this.initPie()
  },
    destroyed () {
    this.tooltipTimer && this.tooltipTimer.clearLoop();
  },
  watch:{
			data:{
				handler(){
          this.myChart = null;
					this.initPie();
			 	},
				deep:true
			}
		},
  methods: {
    initPie(){
      let _this = this;
			if(this.data.length===0){
         return;
       }
      this.myChart = this.$echarts.getInstanceByDom(this.$refs.pieRef)
       if(this.myChart == null){
         	this.myChart = this.$echarts.init(this.$refs.pieRef);
       }
      let propData = JSON.parse(JSON.stringify(this.data))
      let optionData = [];
      let legendData = [];
       propData.forEach(item=>{
         item.value = parseFloat(item.value).toFixed(0)
         legendData.push(item.name)
        optionData.push({
          value:item.value,
          name:item.name
        },
        {
          value: 8,
          name: '',
          itemStyle: {
              normal: {
                color: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgba(0, 0, 0, 0)',
                borderWidth: 0
              }
          }
        })
      })
      this.curData = propData[0]
      
      const option={
        tooltip: {
          show: true,
          backgroundColor:"transparent",
          borderWidth:0,
          textStyle:{
            color:"#fff"
          },
          formatter:param=>{
            _this.curData = {
              name: param.name,
              value:param.value
            }
            return ''
            // return `${param.name}：${param.value}亩`
          }
        },
        legend:{
          type:"scroll",
          show:true,
          pageTextStyle:{
            color:"#fff"
          },
          // selectedMode:false,
          right: "5%",
          top:"5%",
          orient: "vertical",
          icon:"circle",
          itemGap:15,
          formatter:name=>{
            if(name.length >5){
              name = name.slice(0,5) + '...'
            }
            return name
          },
          tooltip:{
            show:true,
            backgroundColor:"rgba(0,0,0,0.3)",
            textStyle:{
              color:"#fff"
            },
            formatter:param=>{
              [_this.curData] = propData.filter(item=>item.name == param.name)
            return `${param.name}`
          }
          },
          textStyle:{
            fontSize:14,
            color:"#fff"
          },
          data:legendData,
        },
        color:['#FF973A ','#3EB7CC','#FF5150','#06AAFC','#0C90FA','#29C0FF'],
        series: [
          {
            type: "pie",
            radius: [80, 90],
            center: ['37%', '50%'],
            label:{
              show:false
            },
            labelLine:{
              show:false
            },
            data: optionData,
          },
          {
            type: 'pie',
            radius: ['65', '70'],
            center:['37%', '50%'],
            label: {
                show: false
            },
            data:this.Pie()
          }
        ]
      }
      this.myChart.setOption(option)
       this.tooltipTimer && this.tooltipTimer.clearLoop();
      this.tooltipTimer = 0;
      this.tooltipTimer = this.loopShowTooltip(this.myChart, option, {
        interval: 3000, // 轮播间隔时间
        loopSeries: true // 是否开启轮播循环
      });
    },
    loopShowTooltip(chart, chartOption, options) {
      let defaultOptions = {
        interval: 2000,
        loopSeries: false,
        seriesIndex: 0,
        updateData: null,
      };

      if (!chart || !chartOption) {
        return;
      }

      let dataIndex = 0; // 数据索引，初始化为-1，是为了判断是否是第一次执行
      let seriesIndex = 0; // 系列索引
      let timeTicket = 0;
      let seriesLen = chartOption.series.length; // 系列个数
      let dataLen = 0; // 某个系列数据个数
      let chartType; // 系列类型
      let first = true;
      let lastShowSeriesIndex = 0;
      let lastShowDataIndex = 0;

      if (seriesLen === 0) {
        return;
      }
      if (options) {
        options.interval = options.interval || defaultOptions.interval;
        options.loopSeries = options.loopSeries || defaultOptions.loopSeries;
        options.seriesIndex = options.seriesIndex || defaultOptions.seriesIndex;
        options.updateData = options.updateData || defaultOptions.updateData;
      } else {
        options = defaultOptions;
      }

      // 如果设置的seriesIndex无效，则默认为0
      if (options.seriesIndex < 0 || options.seriesIndex >= seriesLen) {
        seriesIndex = 0;
      } else {
        seriesIndex = options.seriesIndex;
      }

      /**
       * 清除定时器
       */
      function clearLoop() {
        if (timeTicket) {
          clearInterval(timeTicket);
          timeTicket = 0;
        }

        chart.off('mousemove', stopAutoShow);
        zRender.off('mousemove', zRenderMouseMove);
        zRender.off('globalout', zRenderGlobalOut);
      }

    /**
     * 取消高亮
     */
    function cancelHighlight() {
      /**
       * 如果dataIndex为0表示上次系列完成显示，如果是循环系列，且系列索引为0则上次是seriesLen-1，否则为seriesIndex-1；
       * 如果不是循环系列，则就是当前系列；
       * 如果dataIndex>0则就是当前系列。
       */
      let tempSeriesIndex =
        dataIndex === 0
          ? options.loopSeries
            ? seriesIndex === 0
              ? seriesLen - 1
              : seriesIndex - 1
            : seriesIndex
          : seriesIndex;
      let tempType = chartOption.series[tempSeriesIndex].type;

      if (tempType === 'pie' || tempType === 'radar' || tempType === 'map') {
        chart.dispatchAction({
          type: 'downplay',
          seriesIndex: lastShowSeriesIndex,
          dataIndex: lastShowDataIndex,
        }); // wait 系列序号为0且循环系列，则要判断上次的系列类型是否是pie、radar
      }
    }

    /**
     * 自动轮播tooltip
     */
    function autoShowTip() {
      let invalidSeries = 0;
      let invalidData = 0;
      function showTip() {
        // chart不在页面中时，销毁定时器
        let dom = chart.getDom();
        if (document !== dom && !document.documentElement.contains(dom)) {
          clearLoop();
          return;
        }

        // 判断是否更新数据
        if (
          dataIndex === 0 &&
          !first &&
          typeof options.updateData === 'function'
        ) {
          options.updateData();
          chart.setOption(chartOption);
        }

        let series = chartOption.series;
        let currSeries = series[seriesIndex];
        if (
          !series ||
          series.length === 0 ||
          !currSeries ||
          !currSeries.type ||
          !currSeries.data ||
          !currSeries.data.length
        ) {
          return;
        }

        chartType = currSeries.type; // 系列类型
        dataLen = currSeries.data.length; // 某个系列的数据个数

        let tipParams = {
          seriesIndex: seriesIndex,
        };
        switch (chartType) {
          case 'pie':
            // 处理饼图中数据为0或系列名为空的不显示tooltip
            if (
              !currSeries.data[dataIndex].name ||
              currSeries.data[dataIndex].name === '空' ||
              !currSeries.data[dataIndex].value
            ) {
              invalidData += 1;
              dataIndex = (dataIndex + 1) % dataLen;
              if (options.loopSeries && dataIndex === 0) {
                // 数据索引归0表示当前系列数据已经循环完
                // 无效数据个数个总数据个数相等，则该系列无效
                if (invalidData === dataLen) {
                  invalidSeries += 1;
                }

                // 新系列，重置无效数据个数
                invalidData = 0;

                // 系列循环递增1
                seriesIndex = (seriesIndex + 1) % seriesLen;
                // 系列数循环至起始值时重置无效系列数
                if (seriesIndex === options.seriesIndex) {
                  if (seriesLen !== invalidSeries) {
                    // 下一次系列轮回，重置无效系列数
                    invalidSeries = 0;
                    showTip();
                  } else {
                    // 下一次系列轮回，重置无效系列数
                    invalidSeries = 0;
                    clearLoop();
                  }
                } else {
                  showTip();
                }
              } else if (!options.loopSeries && dataIndex === 0) {
                if (dataLen !== invalidData) {
                  invalidData = 0;
                  showTip();
                } else {
                  invalidData = 0;
                  clearLoop();
                }
              } else {
                showTip();
              }

              return;
            }
          // eslint-disable-next-line no-fallthrough
          case 'map':
          case 'chord':
            tipParams.name = currSeries.data[dataIndex].name;
            break;
          case 'radar': // 雷达图
            tipParams.seriesIndex = seriesIndex;
            // tipParams.dataIndex = dataIndex;
            break;
          case 'lines': // 线图地图上的lines忽略
            dataIndex = 0;
            seriesIndex = (seriesIndex + 1) % seriesLen;
            invalidSeries++; // 记录无效系列数，如果无效系列数和系列总数相等则取消循环显示
            if (seriesLen !== invalidSeries) {
              showTip();
            } else {
              clearLoop();
            }
            return;
          default:
            tipParams.dataIndex = dataIndex;
            break;
        }

        if (chartType === 'pie' || chartType === 'radar' || chartType === 'map') {
          if (!first) {
            cancelHighlight();
          }

          // 高亮当前图形
          chart.dispatchAction({
            type: 'highlight',
            seriesIndex: seriesIndex,
            dataIndex: dataIndex,
          });
        }

        // 显示 tooltip
        tipParams.type = 'showTip';
        chart.dispatchAction(tipParams);

        lastShowSeriesIndex = seriesIndex;
        lastShowDataIndex = dataIndex;

        dataIndex = (dataIndex + 1) % dataLen;
        if (options.loopSeries && dataIndex === 0) {
          // 数据索引归0表示当前系列数据已经循环完
          invalidData = 0;
          seriesIndex = (seriesIndex + 1) % seriesLen;
          if (seriesIndex === options.seriesIndex) {
            invalidSeries = 0;
          }
        }

        first = false;
      }

      showTip();
      timeTicket = setInterval(showTip, options.interval);
    }

    // 关闭轮播
    function stopAutoShow() {
      if (timeTicket) {
        clearInterval(timeTicket);
        timeTicket = 0;

        if (chartType === 'pie' || chartType === 'radar' || chartType === 'map') {
          cancelHighlight();
        }
      }
    }

    let zRender = chart.getZr();

    function zRenderMouseMove(param) {
      if (param.event) {
        // 阻止canvas上的鼠标移动事件冒泡
        // param.event.cancelBubble = true;
      }

      stopAutoShow();
    }

    // 离开echarts图时恢复自动轮播
    function zRenderGlobalOut() {
      if (!timeTicket) {
        autoShowTip();
      }
    }

    // 鼠标在echarts图上时停止轮播
    chart.on('mousemove', stopAutoShow);
    zRender.on('mousemove', zRenderMouseMove);
    zRender.on('globalout', zRenderGlobalOut);

    autoShowTip();

    return {
      clearLoop: clearLoop,
    };
  },
    Pie(){
      let dataArr = [];
      for (var i = 0; i < 150; i++) {
          if (i % 2 === 0) {
              dataArr.push({
                  name: '',
                  value: 5,
                  curor:'auto',
                  itemStyle: {
                      normal: {
                          color: "rgba(92,184,255,.3)",
                          borderWidth: 0,
                          borderColor: "rgba(0,0,0,0)"
                      }
                  }
              })
          } else {
              dataArr.push({
                  name: '',
                  value: 5,
                  curor:'auto',
                  itemStyle: {
                      normal: {
                          color: "rgba(0,0,0,0)",
                          borderWidth: 0,
                          borderColor: "rgba(0,0,0,0)"
                      }
                  }
              })
          }
      }
      return dataArr
    }
  }
}
</script>
<style lang='scss' scoped>
.piePage{
  width: 100%;
  height: 100%;
  position: relative;
  .pie{
  height: 200px;
              width: 390px;
  }
  .pie-title{
    position: absolute;
    top:38%;
    left:19%;
    width: 140px;
    text-align: center;
    color:#fff;
    .title-count{
      font-size: 36px;
      font-weight: 400;
      span{
        color: #C4C9CA;
        font-size: 16px
      }
    }
    .title-name{
      color:#C4C9CA;
      font-size:16px;
    }
  }
}
</style>