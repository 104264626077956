<template>
	<div class="homeindex">
		<!--header-->
		<Topheader></Topheader>
		<div class="flex flex_ju_sb home">
			<!--地图大背景-->
			<!-- <map-center class="cesiumNcMaps" :modelType="modelType"></map-center> -->

			<!-- 左边 -->
			<div class="left">
				<!--总体分析-->
				<div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/HomeBrain/icon3_1.png" />
					<div class="fm_pm slide-title">总体分析</div>
				</div>
				<div class="LfContTop flex flex_ju_l">
					<!--图表占位-->
					<div class="eachimg" style="width: 300px">
						<empty v-if="!aggreAnaly || !aggreAnaly.totalScoreResult" />
						<lefTopLeft v-else :data="aggreAnaly" />
					</div>
					<!--图表占位-->
					<div class="eachimg" style="width: 300px; margin-right: 70px">
						<empty v-if="!aggreAnaly || !aggreAnaly.totalScoreResult || !aggreAnaly.radar" />
						<LeftTopRight v-else :data="aggreAnaly.radar" />
					</div>
					<empty style="width: 534px" v-if="!messageList || messageList.length == 0" />
					<div class="LfContTopRig" v-else>
						<div class="flex flex_ju_l LfContTopRiglist" v-for="(msg, index) in messageList" :key="index">
							<img :src="msg.img" />
							<div>{{ msg.name }}：{{ msg.value }}</div>
						</div>
						<!-- <div class="flex flex_ju_l LfContTopRiglist" >
							<img src="../assets/img/HomeBrain/ju1.png">
							<div>
								物联网覆盖率：设备建设低于15分。请注意物联网设备情况
							</div>
						</div>
						<div class="flex flex_ju_l LfContTopRiglist" >
							<img src="../assets/img/HomeBrain/ju2.png">
							<div>
								生产安全：指数良好请继续保持
							</div>
						</div>
						<div class="flex flex_ju_l LfContTopRiglist" >
							<img src="../assets/img/HomeBrain/ju2.png">
							<div>
								产品安全：指数良好请继续保持
							</div>
						</div> -->
					</div>
				</div>
				<div class="LfContTopcopnt flex flex_ju_l">
					<div class="LfContToplist flex flex_ju_l" style="margin-bottom: 20px">
						<div class="LfContToplistlf">
							<img src="../assets/img/HomeBrain/y1.png" />
							<p>园区安全</p>
						</div>
						<div class="xianji">
							<img style="margin-top: 20px" src="../assets/img/HomeBrain/xian4.png" />
						</div>
						<div class="LfContToplistri">
							<div class="flex flex_ju_sb">
								<p>事故次数</p>
								<p>{{ aggreAnaly.accident }}次</p>
							</div>
							<div class="flex flex_ju_sb">
								<p>整改次数</p>
								<p>{{ aggreAnaly.rectification }}次</p>
							</div>
							<div class="flex flex_ju_sb">
								<p>演练次数</p>
								<p>{{ aggreAnaly.drill }}次</p>
							</div>
							<div class="flex flex_ju_sb">
								<p>培训次数</p>
								<p>{{ aggreAnaly.train }}次</p>
							</div>
						</div>
					</div>
					<div class="LfContToplist flex flex_ju_sb" style="margin-bottom: 20px">
						<div class="LfContToplistlf">
							<img src="../assets/img/HomeBrain/y2.png" />
							<p>生产安全</p>
						</div>
						<div class="xianji">
							<img src="../assets/img/HomeBrain/xian2.png" style="margin-top: 33px" />
						</div>
						<div class="LfContToplistri">
							<div class="flex flex_ju_sb" style="margin-top: 15px; margin-bottom: 26px">
								<p>产品自检率</p>
								<p>{{ aggreAnaly.selfCheck }}%</p>
							</div>
							<div class="flex flex_ju_sb">
								<p>抽检合格率</p>
								<p>{{ aggreAnaly.spotCheck }}%</p>
							</div>
						</div>
					</div>
					<div class="LfContToplist flex flex_ju_sb" style="margin-bottom: 20px">
						<div class="LfContToplistlf">
							<img src="../assets/img/HomeBrain/y3.png" />
							<p>产品安全</p>
						</div>
						<div class="xianji">
							<img src="../assets/img/HomeBrain/xian2.png" style="margin-top: 33px" />
						</div>
						<div class="LfContToplistri">
							<div class="flex flex_ju_sb" style="margin-top: 15px; margin-bottom: 26px">
								<p>溯源产品率</p>
								<p>{{ aggreAnaly.traceProduct }}%</p>
							</div>
							<div class="flex flex_ju_sb">
								<p>溯源扫码率</p>
								<p>{{ aggreAnaly.traceSweep }}%</p>
							</div>
						</div>
					</div>
					<div class="LfContToplist flex flex_ju_sb" style="margin-bottom: 20px">
						<div class="LfContToplistlf">
							<img src="../assets/img/HomeBrain/y4.png" />
							<p>产品推广</p>
						</div>
						<div class="xianji">
							<img src="../assets/img/HomeBrain/xian3.png" style="margin-top: 22px" />
						</div>
						<div class="LfContToplistri">
							<div class="flex flex_ju_sb" style="margin-bottom: 20px">
								<p>种子供种量</p>
								<p>{{ aggreAnaly.seedSupply }}亿斤</p>
							</div>
							<div class="flex flex_ju_sb" style="margin-bottom: 20px">
								<p>推广面积</p>
								<p>{{ aggreAnaly.promotionArea }}万亩</p>
							</div>
							<div class="flex flex_ju_sb">
								<p>供种市场占比</p>
								<p>{{ aggreAnaly.marketShare }}%</p>
							</div>
						</div>
					</div>
					<div class="LfContToplist flex flex_ju_sb">
						<div class="LfContToplistlf">
							<img src="../assets/img/HomeBrain/y5.png" />
							<p>物联网设备</p>
						</div>
						<div class="xianji">
							<img src="../assets/img/HomeBrain/xian2.png" style="margin-top: 26px" />
						</div>
						<div class="LfContToplistri">
							<div class="flex flex_ju_sb" style="margin-top: 15px; margin-bottom: 26px">
								<p>育种基地面积</p>
								<p>{{ aggreAnaly.breedBase }}亩</p>
							</div>
							<div class="flex flex_ju_sb">
								<p>设备覆盖面积</p>
								<p>{{ aggreAnaly.equipCoverage }}亩</p>
							</div>
						</div>
					</div>
				</div>

				<!--智慧园区-->
				<div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/HomeBrain/icon3_2.png" />
					<div class="fm_pm slide-title">智慧园区</div>
				</div>
				<div class="LfContBottom flex flex_ju_sb">
					<div class="LfContBotleft">
						<div class="LfContBotlefttit"></div>
						<!--图表占位-->
						<div class="LfContBeachimg">
							<LeftBottomLeft />
						</div>
					</div>
					<div class="LfContBotleft">
						<div class="LfContBotlefttit2"></div>
						<!--图表占位-->
						<div class="LfContBeachimg">
							<empty v-if="!coreArea || !coreArea.xAxis || coreArea.xAxis.length == 0" />
							<LeftBottomRight v-else :data="coreArea" />
						</div>
					</div>
				</div>
			</div>
			<!-- 中间 -->
			<div class="center" v-if="!isZnMap">
				<zhinao-center></zhinao-center>
			</div>
			<!-- <div class="center">
				<zhinao-center></zhinao-center>
			</div> -->
			<!-- 右边 -->
			<div class="right">
				<!--智慧育种-->
				<div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/HomeBrain/icon3_3.png" />
					<div class="fm_pm slide-title">智慧育种</div>
				</div>
				<div class="RiContTop flex flex_ju_sb">
					<div class="LfContBotleft">
						<div class="LfContBotlefttit" style="margin: 10px auto"></div>
						<!--图表占位-->
						<div class="LfContBeachimg">
							<empty v-if="variousList.length == 0" />
							<rightTopLeft v-else :data="variousList" />
						</div>
					</div>
					<div class="LfContBotleft">
						<div class="LfContBotlefttit2" style="margin: 10px auto"></div>
						<!--图表占位-->
						<div class="LfContBeachimg">
							<empty v-if="!yieldVariousList || !yieldVariousList.xAxis || yieldVariousList.xAxis.length == 0" />
							<rightTopRight v-else :data="yieldVariousList" />
						</div>
					</div>
				</div>
				<div class="LfContBottom2">
					<div class="LfContBotleft">
						<div class="LfContBotlefttit" style="margin: 0 auto"></div>
						<!--图表占位-->
						<div class="LfContBeachimg">
							<empty v-if="!annualChangeList || !annualChangeList.xAxis || annualChangeList.xAxis.length == 0" />
							<rightMid v-else :data="annualChangeList" />
						</div>
					</div>
				</div>

				<!--智慧产业-->
				<div class="c_white font_22 slide-titles flex flex_ju_l">
					<img src="../assets/img/HomeBrain/icon3_2.png" />
					<div class="fm_pm slide-title">智慧产业</div>
				</div>
				<div class="Ribottom flex flex_ju_sb">
					<!--产业-->
					<div class="ritbotleft">
						<div class="CenCbutlist">
							<div
								:class="['CenCbutlt', EquipType == item.copmanyName ? 'CenCbutlAc' : '']"
								@click="changeEuip(item)"
								v-for="(item, index) in wisIndustList"
								:key="index"
							>
								{{ item.copmanyName }}
							</div>
						</div>
					</div>
					<!--饼状图-->
					<div class="ritbotright">
						<div class="flex flex_ju_sb">
							<div class="ritbotrightlist">
								<p>繁种面积</p>
								<p>{{ nowCompany.area }}<span>亩</span></p>
								<img src="../assets/img/HomeBrain/p1.png" />
							</div>
							<div class="ritbotrightlist">
								<p>总产量</p>
								<p>{{ nowCompany.totalOutput }}<span>kg</span></p>
								<img src="../assets/img/HomeBrain/p2.png" />
							</div>
							<div class="ritbotrightlist">
								<p>总产值</p>
								<p>{{ nowCompany.grossOutput }}<span>万元</span></p>
								<img src="../assets/img/HomeBrain/p3.png" />
							</div>
							<div class="ritbotrightlist">
								<p>产品档案</p>
								<p>{{ nowCompany.proudCount }}<span>个</span></p>
								<img src="../assets/img/HomeBrain/p4.png" />
							</div>
							<div class="ritbotrightlist">
								<p>自检率</p>
								<p>{{ nowCompany.selfCheckRatio }}<span>%</span></p>
								<img src="../assets/img/HomeBrain/p5.png" />
							</div>
							<div class="ritbotrightlist">
								<p>抽检率</p>
								<p>{{ nowCompany.samplingRate }}<span>%</span></p>
								<img src="../assets/img/HomeBrain/p6.png" />
							</div>
						</div>
						<div class="LfContBotimg">
							<empty
								v-if="
									!nowCompany ||
									!nowCompany.echarts ||
									!nowCompany.echarts.xAxis ||
									nowCompany.echarts.xAxis.length == 0
								"
							/>
							<rightBottom ref="rigBtm" v-else :data="nowCompany.echarts" :key="nowCompany.copmanyName" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--底部-->
		<!-- <Botfooter @totabclick="tabclick" :tabindex="tabindex" :tabindexsf="tabindexsf" @totabclicksf="tabclicksf">
		</Botfooter> -->
	</div>
</template>

<script>
import Topheader from '../components/header.vue'
import lefTopLeft from './BrainComponent/lefTopLeft.vue'
import LeftTopRight from './BrainComponent/lefTopRight.vue'
import LeftBottomLeft from './BrainComponent/leftBottomLeft.vue'
import LeftBottomRight from './BrainComponent/leftBottomRight.vue'
import rightTopLeft from './BrainComponent/rightTopLeft.vue'
import rightTopRight from './BrainComponent/rightTopRight.vue'
import rightMid from './BrainComponent/rightMid.vue'
import rightBottom from './BrainComponent/rightBottom.vue'
import Botfooter from '../components/footers.vue'
import { regionData } from 'element-china-area-data'
import Empty from './empty.vue'
import api from '@/api/api'
import bus from '@/api/bus'
import ZhinaoCenter from '../components/zhinaoCenter.vue'
export default {
	mixins: [],
	data() {
		return {
			wisIndustList: [], // 智慧产业列表
			messageList: [],
			nowCompany: {}, // 当前公司信息
			annualChangeList: {}, // 年度变化情况,
			variousList: [], // 各品种面积,
			yieldVariousList: {}, // 各品种产量
			coreArea: {}, // 核心区域水电统计,
			aggreAnaly: {
				radar: {},
			}, // 总体分析
			headFour: {},
			tabindexsf: 0,
			tabindex: 2,
			options: regionData,
			selectedOptions: ['370000', '370800', '370829'],
			datatime: '',
			EquipType: '圣丰',

			modelType: '0',
		}
	},
	components: {
		Topheader,
		lefTopLeft,
		LeftTopRight,
		LeftBottomLeft,
		LeftBottomRight,
		rightTopLeft,
		rightTopRight,
		rightMid,
		rightBottom,
		Botfooter,
		Empty,
		ZhinaoCenter,
	},
	computed: {
		isZnMap() {
			console.log(this.$store.state.isZnMap)
			return this.$store.state.isZnMap
		},
	},

	created() {
		// this.nowCompany = this.companyList[0]
		// console.log(this.provinceAndCityData)
		let year = new Date().getFullYear()
		this.getAggregateAnaly()
		this.getAnnualChange(year)
		this.getCoreAreaHy(year)
		this.getVarious(year)
		this.getYieldVarious(year)
		this.getWisdomIndustry(year)
	},
	methods: {
		getAggregateAnaly() {
			this.$http.post(api.aggregateAnaly).then((res) => {
				console.log('总体分析', res.data.data)
				let { data } = res.data
				if (data && data.headFour) {
					this.headFour = data.headFour
				}
				if (data && data.message) {
					let messageList = data.message
					messageList.forEach((item) => {
						item.img = require('../assets/img/HomeBrain/ju' + (item.stats == 1 ? '2' : '1') + '.png')
					})
					this.messageList = messageList
				}
				this.aggreAnaly = data
			})
		},
		getCoreAreaHy(year) {
			this.$http.post(api.coreAreaHydropower, { year }).then((res) => {
				console.log('核心区域水电统计', res.data.data)
				let { data } = res.data
				this.coreArea = data
			})
		},
		getVarious(year) {
			this.$http.post(api.variousVarieti, { year }).then((res) => {
				console.log('各品种面积', res.data.data)
				let { data } = res.data
				this.variousList = data
			})
		},
		getYieldVarious(year) {
			this.$http.post(api.yieldVariousVarietie, { year }).then((res) => {
				console.log('各品种产量', res.data.data)
				let { data } = res.data
				this.yieldVariousList = data
			})
		},
		getAnnualChange(year) {
			this.$http.post(api.annualChange, { year }).then((res) => {
				console.log('年度变化情况', res.data.data)
				let { data } = res.data
				this.annualChangeList = data
			})
		},
		getWisdomIndustry(year) {
			this.$http.post(api.wisdomIndustry, { year }).then((res) => {
				console.log('智慧产业', res.data.data)
				let { data } = res.data
				if (data && data.length != 0) {
					this.nowCompany = data[0]
					this.EquipType = data[0].copmanyName
				}
				this.wisIndustList = data
			})
		},
		changeModel(type) {
			this.modelType = type
			// 发布全局切换事件，用于地图页面切换后数据请求变化
			bus.$emit('brainIndex', type)
		},
		changeEuip(data) {
			this.nowCompany = {}
			;[this.nowCompany] = this.wisIndustList.filter((company) => company.copmanyName === data.copmanyName)
			this.EquipType = data.copmanyName
		},
		tabclick(e) {
			this.$router.push({
				name: e,
			})
		},
		tabclicksf(e) {
			this.tabindexsf = e
			console.log(e)
		},

		handleChange(value) {
			console.log(value)
		},
	},
}
</script>
<style lang="less" scoped>
.homebg {
	background: url('../assets/img/bg3.png') no-repeat;
}
.homebgsf {
	background: url('../assets/img/sbg3.png') no-repeat;
}
.home {
	height: 1080px;
	// background-color: rgba(3, 11, 27, 0.83);
	background: url('../assets/img/brains/allbg.gif');

	overflow: hidden;
	margin-top: -144px;
	position: relative;

	.slide-titles {
		width: 1210px;
		background: url('../assets/img/bg_title.png') no-repeat;
		background-size: 100% 100%;

		.slide-title {
			height: 66px;
			line-height: 66px;
			font-size: 22px;
			font-family: Source Han Sans CN;
			font-weight: bold;
			color: #ffffff;
		}

		img {
			width: 50px;
			height: 50px;
			margin-top: 8px;
		}
	}

	.left {
		padding: 77px 0 0 50px;
		width: 1260px;
		background: url('../assets/img/home/bg_left.png') no-repeat;
		background-size: 100% 100%;
		overflow: hidden;
		// position: fixed;
		// top: 63px;
		position: relative;
		z-index: 10;
		.LfContTop {
			padding: 10px 0px;
			.eachimg {
				height: 200px;
				width: 330px;
				// background: rgba(197, 188, 61, 0.2);
				margin-right: 10px;
			}
			.LfContTopRig {
				height: 200px;
				width: 534px;
				padding: 16px 20px;
				background: url('../assets/img/HomeBrain/zongtfxbk.png') no-repeat;
				background-size: 100% 100%;
				.LfContTopRiglist {
					img {
						height: 40px;
						width: 40px;
					}
					div {
						height: 40px;
						line-height: 40px;
						width: 490px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-size: 16px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #ffffff;
					}
				}
			}

			/* div {
			 
			 		padding-left: 26px;
			 		font-size: 17px;
			 		font-family: Source Han Sans CN;
			 		font-weight: bold;
			 		color: #FFFFFF;
			 		line-height: 30px;
			 		padding: 10px;
			 	} */
		}
		.LfContTopcopnt {
			margin: 10px 0 0 0;
			padding: 20px 0 0 0;
			border-top: 1px dashed #36a3f6;
			flex-wrap: wrap;
			.LfContToplist {
				.LfContToplistlf {
					margin: 0 auto;
					text-align: center;
					width: 100px;
					img {
						margin-top: 10px;
					}
					p {
						font-size: 16px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #ffffff;
						text-align: center;
					}
				}
				.xianji {
					position: relative;
					margin: 0 5px 0 0;
					img {
					}
				}
				.LfContToplistri {
					margin: 0 20px 0 0;
					div {
						width: 220px;
						height: 26px;
						line-height: 26px;
						background: rgba(0, 145, 255, 0.1);
						padding: 0 20px;
						margin: 5px 0;
						p {
							line-height: 26px;
							font-size: 16px;
							font-family: Source Han Sans CN;
							font-weight: 400;
						}
						p:nth-child(1) {
							color: #ffffff;
						}
						p:nth-child(2) {
							color: #00c6ff;
						}
					}
				}
			}
		}
		.LfContBottom {
			margin: 10px 0 0 0;
			.LfContBotleft {
				.LfContBotlefttit {
					margin: 10px auto;
					width: 390px;
					height: 31px;
					background: url('../assets/img/HomeBrain/sbhd.png') no-repeat;
					background-size: 100% 100%;
				}
				.LfContBotlefttit2 {
					margin: 10px auto;
					width: 475px;
					height: 32px;
					background: url('../assets/img/HomeBrain/hxqysd.png') no-repeat;
					background-size: 100% 100%;
				}

				.LfContBeachimg {
					height: 230px;
					width: 600px;
					// background: rgba(197, 188, 61, 0.2);
				}
			}
		}
	}

	// 中间
	.center {
		// background-color: rgba(255, 255, 255, 0.2);
		// height: 1080px;
		// width: 2775px;
		position: relative;
		z-index: 7;
		width: 2690px;
		// height: 136px;
		top: 67px;
		.CenCont {
			// padding: 120px 70px;
			.CenCLeft {
				width: 600px;
				position: absolute;
				top: 0;
				left: 70px;
			}
			.CenCCont {
				height: 136px;
				width: 1122px;
				background: url('../assets/img/home/img1_9.png') no-repeat;
				background-size: 100% 100%;
				padding: 0 150px;
				margin-left: -400px;
				margin-top: 20px;
				.CenCCList {
					padding: 22px 0;
					p:nth-child(1) {
						font-size: 50px;
						font-family: Bahnschrift;
						font-weight: 400;
						color: #ffffff;
						margin-left: 20px;
					}
					p:nth-child(2) {
						font-size: 20px;
						font-family: Adobe Heiti Std;
						font-weight: normal;
						color: #ffffff;
						line-height: 34px;
					}
				}
			}
			.CenCright {
				width: 590px;
				height: 136px;
				position: absolute;
				top: 240px;
				right: 70px;
				.CenCposition {
					height: 136px;
					line-height: 70px;
					width: 590px;
					img {
						height: 70px;
						width: 70px;
						margin-left: 4px;
					}
				}

				.CenCbutlist {
					// margin-top:360px ;
					width: 590px;
					.CenCbutlt {
						margin: 40px 0 0 315px;
						height: 85px;
						line-height: 85px;
						width: 220px;
						text-align: center;
						background: url('../assets/img/home/butbg.png') no-repeat;
						font-size: 24px;
						font-family: HuXiaoBo-NanShen;
						font-weight: 400;
						color: #ffffff;
						cursor: pointer;
					}
					.CenCbutlAc {
						background: url('../assets/img/home/butbg_ac.png') no-repeat;
						color: #0fe6ff;
					}
					.CenCbutlt:hover {
						transition: 0.3s;
						background: url('../assets/img/home/butbg_ac.png') no-repeat;
						color: #0fe6ff;
					}
				}
			}
		}
	}

	// 右边
	.right {
		padding: 77px 50px 0 0;
		width: 858px;
		background: url('../assets/img/home/bg_right.png') no-repeat;
		background-size: 100% 100%;
		overflow: hidden;
		// position: fixed;
		// top: 63px;
		position: relative;
		z-index: 10;
		.RiContTop {
			// margin: 10px 0 0 0 ;
			.LfContBotleft {
				margin-bottom: 10px;

				.LfContBotlefttit {
					margin: 10px auto;
					width: 390px;
					height: 31px;
					background: url('../assets/img/HomeBrain/pzmjbs.png') no-repeat;
					background-size: 100% 100%;
				}
				.LfContBotlefttit2 {
					margin: 10px auto;
					width: 400px;
					height: 31px;
					background: url('../assets/img/HomeBrain/clbs.png') no-repeat;
					background-size: 100% 100%;
				}

				.LfContBeachimg {
					height: 200px;
					width: 390px;
					// background: rgba(197, 188, 61, 0.2);
				}
			}
		}
		.LfContBottom2 {
			.LfContBotlefttit {
				margin: 20px auto;
				width: 800px;
				height: 31px;
				background: url('../assets/img/HomeBrain/niandu.png') no-repeat;
				background-size: 100% 100%;
			}

			.LfContBeachimg {
				height: 220px;
				width: 800px;
				// background: rgba(197, 188, 61, 0.2);
			}
		}
		.Ribottom {
			position: relative;
			z-index: 10;
			.ritbotleft {
				width: 190px;
				height: 100%;
				// background: rgba(0, 255, 255, 0.4);
				.CenCbutlist {
					margin-top: 40px;
					// width: 590px;
					height: 270px;
					overflow-y: scroll;
					&::-webkit-scrollbar {
						width: 0;
					}
					.CenCbutlt {
						// margin:10px auto ;
						// height: 70px;
						// line-height: 70px;
						padding: 25px 20px;
						width: 190px;
						cursor: pointer;
						text-align: center;
						background: url('../assets/img/HomeBrain/bqwxz.png') no-repeat;
						background-size: 100% 100%;
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
					}
					.CenCbutlAc {
						background: url('../assets/img/HomeBrain/bqxz.png') no-repeat;
						background-size: 100% 100%;
						color: #22c0fa;
					}
					.CenCbutlt:hover {
						transition: 0.3s;
						background: url('../assets/img/HomeBrain/bqxz.png') no-repeat;
						background-size: 100% 100%;
						color: #22c0fa;
					}
				}
			}
			.ritbotright {
				width: 610px;
				height: 280px;

				.ritbotrightlist {
					width: 100px;
					text-align: center;
					padding: 26px 0 15px 0;
					p {
						padding: 4px 0;
					}

					p:nth-child(1) {
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: bold;
						color: #ffffff;
					}
					p:nth-child(2) {
						font-size: 15px;
						font-family: Source Han Sans CN;
						font-weight: bold;
						color: #17c0ff;
						span {
							color: #ffffff;
						}
					}
					span {
						padding-left: 3px;
						font-size: 12px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #ffffff;
					}
					img {
						margin: 0 auto;
					}
				}
				.LfContBotimg {
					margin: 0px 0 0 0;
					width: 610px;
					// background: rgba(96, 243, 25, 0.2);
					height: 180px;
				}
			}
		}
	}
	//公共颜色
	.bcolor0090FF {
		color: #0090ff;
		background: #0090ff;
	}

	.bcolor2EBDFF {
		color: #2ebdff;
		background: #2ebdff;
	}

	.bcolor03FFEC {
		color: #03ffec;
		background: #03ffec;
	}

	.bcolorFDFF2E {
		color: #fdff2e;
		background: #fdff2e;
	}

	.color0090FF {
		color: #0090ff;
	}

	.color2EBDFF {
		color: #2ebdff;
	}

	.color03FFEC {
		color: #03ffec;
	}
	.colorFDFF2E {
		color: #fdff2e;
	}
}
</style>
