<template>
	<div class="homeindex">
		<!--header-->
		<Topheader @changeMoney="changeMoney"></Topheader>
		<div class="flex flex_ju_sb home">
			<!--地图大背景-->

			<!-- <cen-map class="cesiumNcMaps"></cen-map> -->
			<!-- 左边 -->
			<div class="flex lefts">
				<div class="left animate__animated animate__fadeInLeft">
					<!--物联网设备用电情况-->
					<div class="c_white font_22 slide-titles flex flex_ju_l">
						<img src="../assets/img/homeEquip/icon3_1.png" />
						<div class="fm_pm slide-title">物联网设备投入情况</div>
					</div>
					<div class="LfContTop flex flex_ju_sb">
						<div class="leleft">
							<empty v-if="!equipSituation || equipSituation.length == 0" />
							<leftTop v-else :data="equipSituation" />
						</div>
					</div>
					<!--资金投入-->
					<div class="c_white font_22 slide-titles flex flex_ju_l">
						<img src="../assets/img/homeEquip/icon3_2.png" />
						<div class="fm_pm slide-title">企业设备占有情况</div>
					</div>
					<div class="LfContCent flex flex_ju_sb">
						<!--产业-->
						<div class="ritbotleft">
							<div class="CenCbutlist" v-if="companyList.length > 0">
								<div
									:class="['CenCbutlt', EquipType === item.value ? 'CenCbutlAc' : '']"
									@click="changeEuip(item)"
									v-for="item in companyList"
									:key="item.value"
								>
									{{ item.name }}
								</div>
							</div>
						</div>
						<div class="ritbotright">
							<empty v-if="companyInfoList.length == 0" />
							<leftMid v-else :data="companyInfoList" />
						</div>
					</div>
					<!--育种成效-->
					<div class="c_white font_22 slide-titles flex flex_ju_l">
						<img src="../assets/img/homeEquip/icon3_3.png" />
						<div class="fm_pm slide-title">设备金额分布</div>
					</div>
					<div class="LfContBottom flex flex_ju_sb">
						<!--放育种成效echarts图-->
						<div class="lftbotleft">
							<empty v-if="equipOne.length == 0" />
							<leftBotLeft v-else :data="equipOne" />
						</div>
						<div class="lftbotright">
							<empty v-if="equiptwo.length == 0" />
							<LeftBotRight v-else :data="equiptwo" />
						</div>
					</div>
				</div>
				<!-- 左边资金投入数据，组件封装 -->
				<!-- <left-second></left-second> -->
			</div>
			<!-- 中间 -->
			<div class="center flex flex_ju_c" :class="isShowMoney ? 'center-show' : ''">
				<!-- <div class="CenCont flex flex_ju_sb"> -->
				<!--预警提示-->
				<!-- <div class="CenCLeft"> 
						<EarlyWarningList></EarlyWarningList>
					</div> -->
				<!--中间区域总览数据-->
				<div class="CenCCont flex flex_ju_sb">
					<div class="CenCCList">
						<p>97.5</p>
						<p class="flex flex_ju_l"><img src="../assets/img/home/icon1_10.png" />园区总面积(km²)</p>
					</div>
					<div class="CenCCList">
						<p>15</p>
						<p class="flex flex_ju_l"><img src="../assets/img/home/icon1_10.png" />入园企业(个)</p>
					</div>
					<div class="CenCCList">
						<p>50000</p>
						<p class="flex flex_ju_l"><img src="../assets/img/home/icon1_10.png" />年均繁育面积(亩)</p>
					</div>
					<div class="CenCCList">
						<p>5100</p>
						<p class="flex flex_ju_l"><img src="../assets/img/home/icon1_10.png" />带动就业人(人)</p>
					</div>
					<div class="CenCCList">
						<p>160</p>
						<p class="flex flex_ju_l"><img src="../assets/img/home/icon1_10.png" />专业技术人员(人)</p>
					</div>
				</div>
				<!--右侧数据-->
				<!-- <div class="CenCright"> -->
				<!--方位标-->
				<!-- <div class="CenCposition flex flex_ju_c color03FFEC">
							N<img src="../assets/img/home/fangwei.png" />
						</div> -->
				<!-- <div class="CenCbutlist">
							<div class="CenCbutlt" @click="tonengguan('home')">
								总览
							</div>
							<div class="CenCbutlt" @click="tonengguan('HomeEnergy')">
								能管中心
							</div>
							<div class="CenCbutlt CenCbutlAc">
								设备资产
							</div>
							<div class="CenCbutlt" @click="tonengguan('HomeAbnormal')">
								异常运营
							</div>
						</div> 
					</div> -->

				<!-- </div> -->
			</div>
			<!-- 右边 -->
			<div class="flex rights">
				<!-- 右中实时数据 -->
				<div style="width: 990px"></div>
				<div class="right animate__animated animate__fadeInRight">
					<!--大豆种业-->
					<div class="c_white font_22 slide-titles flex flex_ju_l">
						<img src="../assets/img/homeEquip/icon3_4.png" />
						<div class="fm_pm slide-title">资产设施利用情况</div>
					</div>
					<div class="RiContTop flex flex_ju_sb">
						<div class="Rileft">
							<empty v-if="equipUtil.length == 0" />
							<rightTop v-else :data="equipUtil" />
						</div>
					</div>
					<!--种子价格-->
					<div class="c_white font_22 slide-titles flex flex_ju_l">
						<img src="../assets/img/homeEquip/icon3_5.png" />
						<div class="fm_pm slide-title">资产设施状态统计</div>
					</div>
					<div class="RiMid flex flex_ju_sb">
						<!--辐射地图-->
						<div class="ritmidleft">
							<empty v-if="faultSate.length == 0" />
							<rigMidLeft v-else :data="faultSate" />
						</div>
						<!--饼状图-->
						<div class="ritmidright">
							<empty v-if="superState.length == 0" />
							<rigMidRight v-else :data="superState" />
						</div>
					</div>

					<!--大豆销售网络-->
					<div class="c_white font_22 slide-titles flex flex_ju_l">
						<img src="../assets/img/homeEquip/icon3_6.png" />
						<div class="fm_pm slide-title">物联网设备报警分析</div>
					</div>
					<div class="Ribottom flex flex_ju_sb">
						<!--辐射地图-->
						<div class="ritbotleft">
							<empty v-if="!isRightBLine" />
							<rightBottom v-else :data="deviceAbnor" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--底部-->
		<!-- <Botfooter @totabclick="tabclick" :tabindex="tabindex" :tabindexsf="tabindexsf" @totabclicksf="tabclicksf">
		</Botfooter> -->
	</div>
</template>

<script>
import Topheader from '../components/header.vue'
import Botfooter from '../components/footers.vue'
import leftTop from './EquipComponent/leftTop.vue'
import leftMid from './EquipComponent/leftMid.vue'
import leftBotLeft from './EquipComponent/leftBotLeft.vue'
import LeftBotRight from './EquipComponent/leftBotRight.vue'
import rightTop from './EquipComponent/rightTop.vue'
import rigMidLeft from './EquipComponent/rigMidLeft.vue'
import rigMidRight from './EquipComponent/rigMidRight.vue'
import rightBottom from './EquipComponent/rightBottom.vue'
import Empty from './empty.vue'
import EarlyWarningList from '../components/EarlyWarningList.vue'
import { regionDataPlus } from 'element-china-area-data'
import api from '@/api/api'
import LeftSecond from './HomeComponent/LeftSecond.vue'
import RightSecond from './HomeComponent/RightSecond.vue'
export default {
	mixins: [],
	data() {
		return {
			equipSituation: [], // 物联网设备情况
			companyList: [], // 企业设备占有情况 -- 企业列表
			companyInfoList: [], // 企业信息
			equipOne: [], // 固定资产和物联网设备扇形图
			equiptwo: [], //  固定资产扇形图
			equipUtil: [], // 设备利用情况
			faultSate: [], //  设备状态统计 - 故障统计
			superState: [], //  // 设备状态统计 - 管理状态
			deviceAbnor: {}, // 物联网设备报警分析
			isRightBLine: true,
			cityname1: '',
			cityname2: '',
			cityname3: '',
			cityname1list: regionDataPlus,
			cityname2list: [],
			cityname3list: [],
			EquipType: '',
			tabindexsf: 0,
			tabindex: 0,
			options: regionDataPlus,
			selectedOptions: ['370000', '370800', '370829'],
			datatime: '',
			isShowMoney: false, // 是否显示上面的金额数据
		}
	},
	components: {
		Topheader,
		rightTop,
		leftTop,
		leftMid,
		leftBotLeft,
		LeftBotRight,
		rigMidLeft,
		rigMidRight,
		rightBottom,
		Botfooter,
		Empty,
		EarlyWarningList,
		LeftSecond,
		RightSecond,
	},
	mounted() {
		let year = new Date().getFullYear()
		this.getEquipSiauation()
		this.getCompanyList()
		this.getEquipMore()
		this.getEquipUtil(year)
		this.getEquipSate(year)
		this.getDeviceAbnor(year)
	},
	methods: {
		// 上面的金额是否显示
		changeMoney() {
			this.isShowMoney = !this.isShowMoney
		},
		getEquipSiauation() {
			this.$http.post(api.equipmentSituation).then((res) => {
				console.log('物联网设备情况', res.data.data)
				let { data } = res.data
				this.equipSituation = data
			})
		},
		getCompanyList() {
			this.$http.post(api.companyListAll).then((res) => {
				console.log('企业列表', res.data.data)
				let { data } = res.data
				if (data.length != 0) {
					this.EquipType = data[0].value
					this.getCompanyInfo(data[0].value)
				}
				this.companyList = data
			})
		},
		getCompanyInfo(id) {
			this.$http.post(api.possessionEquipment, { param: id }).then((res) => {
				console.log('企业情况', res.data.data)
				let { data } = res.data
				this.companyInfoList = data
			})
		},
		getEquipMore() {
			this.$http.post(api.equipmentAmountOne).then((res) => {
				console.log('固定资产和物联网设备', res.data.data)
				let { data } = res.data
				this.equipOne = data
			})
			this.$http.post(api.equipmentAmountTwo).then((res) => {
				console.log('固定资产扇形图', res.data.data)
				let { data } = res.data
				this.equiptwo = data
			})
		},
		getEquipUtil(year) {
			this.$http.post(api.equipmentUtilization, { year }).then((res) => {
				console.log('设备利用情况', res.data.data)
				let { data } = res.data
				this.equipUtil = data
			})
		},
		getEquipSate(year) {
			this.$http.post(api.faultStatistic, { year }).then((res) => {
				console.log('故障统计', res.data.data)
				let { data } = res.data
				this.faultSate = data
			})
			this.$http.post(api.supervisorState).then((res) => {
				console.log('管理状态', res.data.data)
				let { data } = res.data
				this.superState = data
			})
		},
		getDeviceAbnor(year) {
			this.$http.post(api.deviceAbnormality, { year }).then((res) => {
				console.log('物联网设备报警分析', res.data.data)
				if (res.data.code == 0) {
					let { data } = res.data

					if (!data || !data.xAxis || data.xAxis.length == 0) {
						this.isRightBLine = false
						this.deviceAbnor = {}
					} else {
						this.deviceAbnor = data

						this.isRightBLine = true
					}
				} else {
					console.log('ttttttttt')
					this.deviceAbnor = {}
					this.isRightBLine = false
				}
			})
		},

		changeEuip(data) {
			this.EquipType = data.value
			this.getCompanyInfo(data.value)
		},
		//跳转
		tonengguan(url) {
			this.$router.push({
				name: url,
			})
		},
		tabclick(e) {
			this.$router.push({
				name: e,
			})
		},
		tabclicksf(e) {
			this.tabindexsf = e
			console.log(e)
		},

		handleChange(value) {
			console.log(value)
			this.cityname2list = []
			this.cityname2 = ''
			this.cityname3list = []
			this.cityname3 = ''
			this.cityname1list.forEach((item, index) => {
				console.log(value == item.value)
				if (value == item.value) {
					this.cityname2list = this.cityname1list[index].children
					console.log(this.cityname2list)
				}
			})
		},
		handleChange2(value) {
			console.log(value)
			this.cityname3list = []
			this.cityname3 = ''
			this.cityname2list.forEach((item, index) => {
				console.log(value == item.value)
				if (value == item.value) {
					this.cityname3list = this.cityname2list[index].children
					console.log(this.cityname3list)
				}
			})
		},
		handleChange3(value) {
			console.log(value)
			/* this.cityname2='';
			 
				this.cityname3=''; */
		},
	},
}
</script>
<style lang="less" scoped>
.home {
	height: 1080px;

	background: url('../assets/img/bgs.png') no-repeat;
	background-size: 100% 100%;

	overflow: hidden;
	margin-top: -144px;
	position: relative;
	.slide-titles {
		width: 805px;
		background: url('../assets/img/bg_title.png') no-repeat;
		background-size: 100% 100%;

		.slide-title {
			height: 66px;
			line-height: 66px;
			font-size: 22px;
			font-family: Source Han Sans CN;
			font-weight: bold;
			color: #ffffff;
		}

		img {
			width: 50px;
			height: 50px;
			margin-top: 8px;
		}
	}

	.lefts {
		width: 1840px;
	}
	.left {
		padding: 77px 0 0 50px;
		width: 888px;
		background: url('../assets/img/home/bg_left.png') no-repeat;
		background-size: 100% 100%;
		overflow: hidden;
		// position: fixed;
		// 			top: 63px;
		// z-index:10;
		position: relative;
		z-index: 10;
		animation-duration: 2s;
		animation-timing-function: linear;
		.LfContTop {
			padding: 10px 15px;
			.leleft {
				width: 780px;
				height: 180px;
			}
		}

		.LfContCent {
			padding: 10px 15px;
			height: 320px;
			position: relative;
			.ritbotleft {
				width: 190px;
				height: 100%;
				// background: rgba(0, 255, 255, 0.4);
				.CenCbutlist {
					padding: 10px 0;
					height: 96%;
					overflow-y: scroll;
					&::-webkit-scrollbar {
						width: 0;
					}
					// width: 590px;
					cursor: pointer;
					.CenCbutlt {
						// margin:10px auto ;
						// height: 70px;
						// line-height: 70px;
						padding: 25px 20px;
						width: 170px;
						text-align: center;
						background: url('../assets/img/HomeBrain/bqwxz.png') no-repeat;
						background-size: 100% 100%;
						font-size: 18px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
					}
					.CenCbutlAc {
						background: url('../assets/img/HomeBrain/bqxz.png') no-repeat;
						background-size: 100% 100%;
						color: #22c0fa;
					}
					.CenCbutlt:hover {
						transition: 0.3s;
						background: url('../assets/img/HomeBrain/bqxz.png') no-repeat;
						background-size: 100% 100%;
						color: #22c0fa;
					}
				}
			}
			.ritbotright {
				width: 580px;
				height: 300px;
				// background-image: url('../assets/img/homeEquip/rm.png');
				background-size: 580px 300px;
			}
		}
		.LfContBottom {
			margin: 10px 0 0 0;
			.lftbotleft {
				width: 420px;
				height: 236px;
			}
			.lftbotright {
				width: 390px;
				height: 236px;
			}
		}
	}

	// 中间
	.center {
		// background-color: rgba(255, 255, 255, 0.2);
		// height: 1080px;
		// width: 3300px;
		position: relative;
		flex: 1;
		height: 136px;
		z-index: 5;
		top: -50px;
		opacity: 0;
		transition: all 1s;
		// .CenCont{
		// 	padding: 120px 70px;

		// background-color: rgba(255, 255, 255, 0.5);
		&.center-show {
			top: 120px;
			opacity: 1;
		}
		.CenCLeft {
			width: 600px;
			position: absolute;
			top: 0;
			left: 70px;
		}
		.CenCCont {
			height: 136px;
			width: 1122px;
			background: url('../assets/img/home/img1_9.png') no-repeat;
			background-size: 100% 100%;
			padding: 0 90px;
			margin-top: 20px;
			.CenCCList {
				padding: 22px 0;
				p:nth-child(1) {
					font-size: 50px;
					font-family: Bahnschrift;
					font-weight: 400;
					color: #ffffff;
					margin-left: 20px;
				}
				p:nth-child(2) {
					font-size: 20px;
					font-family: Adobe Heiti Std;
					font-weight: normal;
					color: #ffffff;
					line-height: 34px;
				}
			}
		}
		.CenCright {
			width: 590px;
			height: 136px;
			position: absolute;
			top: 148px;
			right: 70px;
			.CenCposition {
				height: 136px;
				line-height: 70px;
				width: 590px;
				img {
					height: 70px;
					width: 70px;
					margin-left: 4px;
				}
			}

			.CenCbutlist {
				// margin-top:190px ;
				width: 590px;
				.CenCbutlt {
					margin: 40px 0 0 275px;
					height: 85px;
					line-height: 85px;
					width: 220px;
					text-align: center;
					background: url('../assets/img/home/butbg.png') no-repeat;
					font-size: 24px;
					font-family: HuXiaoBo-NanShen;
					font-weight: 400;
					color: #ffffff;
					cursor: pointer;
				}
				.CenCbutlAc {
					background: url('../assets/img/home/butbg_ac.png') no-repeat;
					color: #0fe6ff;
				}
				.CenCbutlt:hover {
					transition: 0.3s;
					background: url('../assets/img/home/butbg_ac.png') no-repeat;
					color: #0fe6ff;
				}
			}
		}

		// }
	}

	// 右边
	.right {
		padding: 77px 50px 0 0;
		width: 858px;
		background: url('../assets/img/home/bg_right.png') no-repeat;
		background-size: 100% 100%;
		overflow: hidden;
		// position: fixed;
		// top: 63px;
		position: relative;
		z-index: 10;
		animation-duration: 2s;
		animation-timing-function: linear;
		.RiContTop {
			margin: 10px 0;
			.Rileft {
				height: 226px;
				width: 808px;
			}
		}
		.RiMid {
			.ritmidleft {
				width: 415px;
				height: 236px;
				position: relative;
			}
			.ritmidright {
				width: 415px;
				height: 236px;
				position: relative;
			}
		}
		.Ribottom {
			position: relative;
			.ritbotleft {
				width: 800px;
				height: 300px;
			}
		}
	}
	//公共颜色
	.bcolor0090FF {
		color: #0090ff;
		background: #0090ff;
	}

	.bcolor2EBDFF {
		color: #2ebdff;
		background: #2ebdff;
	}

	.bcolor03FFEC {
		color: #03ffec;
		background: #03ffec;
	}

	.bcolorFDFF2E {
		color: #fdff2e;
		background: #fdff2e;
	}

	.color0090FF {
		color: #0090ff;
	}

	.color2EBDFF {
		color: #2ebdff;
	}

	.color03FFEC {
		color: #03ffec;
	}
	.colorFDFF2E {
		color: #fdff2e;
	}
}
</style>
