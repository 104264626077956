<template>
  <div>
    <div ref="barRef" class="bar"></div>
  </div>
</template>
<script>
export default{
    props: {
			data: {
				type: Object,
				required:true 
			},
		},
  data(){
    return{}
  },
  mounted () {
    this.initBar();
  },
  watch:{
			data:{
				handler(){
					this.initBar();
			 	},
				deep:true
			}
		},
  methods: {
    initBar(){
        let _this = this;
        if(!this.data || !this.data.xAxis || !this.data.series || this.data.series.length == 0){
					return;
				}
                let data = JSON.parse(JSON.stringify(this.data))
      let myChart = this.$echarts.init(this.$refs.barRef)
      var data1 = data.series;
var xData = data.xAxis;
const option = {
    grid: {
        borderWidth: 0,
        top: '15%',
        left: '8%',
        right:"1%",
        bottom: '25%',
        textStyle: {
            color: '#fff',
        },
    },

    xAxis: [
        {
            type: 'category',
            axisLine: {
                lineStyle: {
                    width:2,
                    color: 'rgb(255,255,255)',
                },
            },
            splitLine: {
                show: false,
            },
            axisTick: {
                show: false,
            },
            splitArea: {
                show: false,
            },
            axisLabel: {
                color: '#fff',
                fontSize: 16,
                rotate:25
            },
            data: xData,
        },
    ],
    dataZoom:[
        {
            show:true,
            type:"inside",
        }
    ],
    yAxis: 
        {
            type: 'value',
            show:false,
            
        },
    series: [
        {
            name: '周转量',
            type: 'bar',
            // "stack": "总量",
            barMaxWidth: 16,
            barGap: '10%',
            label:{
                show:true,
                color:"#fff",
                position:"top",
                fontSize:14,
                formatter:'{c}吨'
            },
            itemStyle: {
                normal: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 1,
                        x2: 0,
                        y2: 0,
                        colorStops: [
                            {
                                offset: 0,
                                color: '#22FFC5', // 0% 处的颜色
                            },
                            {
                                offset: 0.5,
                                color: '#22FFC5', // 0% 处的颜色
                            },
                            {
                                offset: 1,
                                color: '#fff', // 100% 处的颜色
                            },
                        ],
                        global: false, // 缺省为 false
                    },
                    shadowColor:"#22FFC5",
                    shadowBlur:25,
                },
            },
            data: data1,
        },
    ],
};
myChart.setOption(option)

    }
  }
}
</script>
<style lang='scss' scoped>
.bar{
  height: 200px;
						width: 390px;
}
</style>