/**
 * @description 地图部分的js
 */
import 'echarts-gl'
export default {
	data() {
		return {
			// 模型
			modals: [
				{
					name: '华亚农业',
					path: '/glb/huaya(1).glb',
					icon: '/glb/farms/label_hy1.png',
					lon: 116.27255916595459,
					lat: 35.60459105788122,
					scale: 0.001,
					headings: 90,
					width: 100,
					lons: 116.272,
					lats: 35.604,
				},
				{
					name: '基地服务中心',
					type: 'modal',
					path: '/glb/jidi.glb',
					icon: '/glb/farms/label_jd.png',
					lon: 116.2722,
					lat: 35.5938,
					scale: 0.0025,
					headings: 90,
					width: 140,
					lons: 116.2685,
					lats: 35.595,
				},
				{
					name: '种子加工仓储基地',
					path: '/glb/sanchangHM2.glb',
					icon: '/glb/farms/label_zz.png',
					lon: 116.27251625061035,
					lat: 35.607923221974985,
					scale: 0.0015,
					headings: 90,
					width: 150,
					lons: 116.272,
					lats: 35.6074,
				},
			],
			// 圣丰模型
			sfModals: {
				name: '圣丰',
				path: '/glb/shengfeng(2).glb',
				// lon: 116.36512756347656,
				// lat: 35.43409965413408,
				lon: 116.364925,
				lat: 35.4341,
				scale: 0.0015,
				headings: 97,
			},
			centerType: '0', // 肥力和土壤的切换
			ctxTop: '',
			ctxLeft: '', // 弹窗画布的位置
			ctxETop: '',
			ctxELeft: '', // 弹窗画布的位置
			ctxW: '',
			ctxH: '',
			// 肥力还是土壤
			modelType: '0',
		}
	},
	beforeDestroy() {
		if (window.cesiumViewer) {
			// 移除所有的源
			window.cesiumViewer.dataSources.removeAll()
			window.cesiumViewer.entities.removeAll()
		}
	},
	watch: {
		// 监听路由变化，主要用于地图视角切换与数据加载
		$route: {
			handler(val, oldval) {
				this.getPath(val, oldval)
			},
			// 深度观察监听
			deep: true,
		},
	},
	methods: {
		// 监听路由变化，主要用于地图视角切换与数据加载
		getPath(val, oldval) {
			// 如果
			let paths = val.path
			let oldPath = oldval.path
			if (paths == '/home' || paths == '/HomeEnergy' || paths == '/HomeEquip' || paths == '/HomeAbnormal') {
				this.tabIndex = 0
			} else if (paths == '/HomeMon' || paths == '/HomeProduction') {
				this.tabIndex = 1
			} else if (paths == '/HomeBrain') {
				this.tabIndex = 2
			}

			// 赋值显示的是农场还是圣丰
			// 弹窗关闭
			this.openDiaType = ''
			this.diaType = ''
			this.isBigImg = false
			this.bigImgs = ''
			this.isOpenWarn = false
			// 视频播放
			if (this.player) {
				this.player.stop()
				this.player = null
			}
			if (
				this.tabIndex == 0 &&
				(oldPath == '/home' ||
					oldPath == '' ||
					oldPath == '/' ||
					oldPath == '/HomeEnergy' ||
					oldPath == '/HomeEquip' ||
					oldPath == '/HomeAbnormal')
			) {
				return
			} else {
				this.pageIndex = 0
				// 重新设置视角,转到农场页面
				this.setView(0)
			}
		},
		// 地图初始化
		mapInit() {
			console.log('mapInit')
			// 判断如果存在，移除已经添加的图层
			if (window.cesiumViewer) {
				// 移除所有的源
				window.cesiumViewer.dataSources.removeAll()
				window.cesiumViewer.entities.removeAll()
			}
			// 地图
			let viewer = new Cesium.Viewer('cesiumNcMap', {
				animation: false, // 是否显示动画控件
				shouldAnimate: true,
				homeButton: false, // 是否显示home键
				timeline: false, // 是否显示时间线控件
				fullscreenButton: false, // 是否显示全屏按钮
				geocoder: false, //是否显示地名查找控件
				baseLayerPicker: false, // 是否显示图层选择控件
				navigationHelpButton: false, // 是否显示帮助信息控件
				infoBox: false, // 是否显示点击要素之后显示的信息
				sceneModePicker: false, //是否显示投影方式控件  三维/二维
				selectionIndicator: false, //是否显示指示器组件
				// scene3DOnly: false, //如果设置为true，则所有几何图形以3D模式绘制以节约GPU资源
				scene3DOnly: true, //如果设置为true，则所有几何图形以3D模式绘制以节约GPU资源
				maximumScreenSpaceError: 22220,
				sceneMode: Cesium.SceneMode.COLUMBUS_VIEW,
				requestRenderMode: true, // 启用请求渲染模式
				selectionIndicator: false, //鼠标选中点击效果
				// 加载地图
				// 卫星影像底图
				imageryProvider: new Cesium.WebMapTileServiceImageryProvider({
					// url: 'http://t0.tianditu.com/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=a4ddb992887eb6a615650ee583080e59',
					url: 'https://t0.tianditu.gov.cn/img_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=img&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default&format=tiles&tk=bf21fa76bf837b6d3ce7fcaca24c87af',
					layer: 'tdtBasicLayer',
					style: 'default',
					format: 'image/jpeg',
					// tileMatrixSetID: 'GoogleMapsCompatible',
					// show: false,
					// mininumLevel: 0,
					// maximumLevel: 16,
				}),
			})
			// 加载路网
			viewer.imageryLayers.addImageryProvider(
				new Cesium.WebMapTileServiceImageryProvider({
					// url: 'http://t0.tianditu.com/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=f8474e70fe3d6a22725172f8af67d274',
					url: 'https://t0.tianditu.gov.cn/cia_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cia&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=bf21fa76bf837b6d3ce7fcaca24c87af',
					layer: 'tdtAnnoLayer',
					style: 'default',
					format: 'image/jpeg',
					// mininumLevel: 0,
					// maximumLevel: 16,
				})
			)
			// 这里指的是地图和现实当中的比例，不是地图的缩放等级
			viewer.scene.screenSpaceCameraController.minimumZoomDistance = 650
			viewer.scene.screenSpaceCameraController.maximumZoomDistance = 4000
			viewer.scene.globe.enableLighting = false
			viewer.shadows = true

			window.cesiumViewer = viewer

			// 调整视角
			// cesiumViewer.camera.setView({
			// 	// fromDegrees()方法，将经纬度和高程转换为世界坐标
			// 	destination: Cesium.Cartesian3.fromDegrees(116.26899719238281, 35.53250561941886, 4200.0),
			// 	orientation: {
			// 		// 指向
			// 		heading: Cesium.Math.toRadians(0),
			// 		// 视角
			// 		pitch: Cesium.Math.toRadians(-30),
			// 		// roll: 0.0,
			// 	},
			// })

			// 地图点击事件
			this.mapHandlerClick()
			// 加载模型
			for (const item of this.modals) {
				this.makeNcModel(item)
			}

			// 绘制农场地块
			this.mapDiKuai()
		},
		// 绘制地图上的柱状图图表
		makeEcharts(polyCenter, xData2, data1, allData, facilityId, names) {
			let _this = this
			let colors = this.echartsColor
			let option = {
				backgroundColor: 'transparent',
				tooltip: {
					trigger: 'item',
					show: false,
				},
				grid: {
					left: 5,
					right: 5,
					bottom: 5,
					top: '10%',
				},
				xAxis: {
					data: xData2,
					axisTick: {
						show: false,
					},
					axisLine: {
						show: false,
					},
					axisLabel: {
						show: false,
						interval: 0,
						textStyle: {
							color: '#fff',
							fontSize: 20,
						},
						margin: 0, //刻度标签与轴线之间的距离。
					},
				},
				yAxis: {
					splitLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						show: false,
					},
					axisLabel: {
						show: false,
						textStyle: {
							color: '#fff',
							fontSize: 20,
						},
					},
				},
				series: [
					//下半截柱状图
					{
						name: '2020',
						type: 'bar',
						barWidth: 15,
						z: 11,
						// barGap: '-100%',
						itemStyle: {
							//lenged文本
							opacity: 1,
							color: function (params) {
								var a = params.name
								return new echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,
									[
										{
											offset: 0,
											color: _this.convertHexToRGB(colors[a], 1), // 0% 处的颜色
										},
										{
											offset: 0.9,
											color: _this.convertHexToRGB(colors[a], 0.1), // 100% 处的颜色
										},
										{
											offset: 1,
											color: _this.convertHexToRGB(colors[a], 0), // 100% 处的颜色
										},
									],
									false
								)
								// if (a === '磷离子' || a == '土温') {
								// 	return new echarts.graphic.LinearGradient(
								// 		0,
								// 		0,
								// 		0,
								// 		1,
								// 		[
								// 			{
								// 				offset: 0,
								// 				color: 'rgb(168,212,56)', // 0% 处的颜色
								// 			},
								// 			{
								// 				offset: 0.9,
								// 				color: 'rgba(168,212,56,0)', // 100% 处的颜色
								// 			},
								// 			{
								// 				offset: 1,
								// 				color: 'rgba(168,212,56,0)', // 100% 处的颜色
								// 			},
								// 		],
								// 		false
								// 	)
								// } else if (a === '钾离子' || a == '土湿') {
								// 	return new echarts.graphic.LinearGradient(
								// 		0,
								// 		0,
								// 		0,
								// 		1,
								// 		[
								// 			{
								// 				offset: 0,
								// 				color: 'rgb(54,215,152)', // 0% 处的颜色
								// 			},
								// 			{
								// 				offset: 0.9,
								// 				color: 'rgba(54,215,152,0)', // 100% 处的颜色
								// 			},
								// 			{
								// 				offset: 1,
								// 				color: 'rgba(46,218,148,0)', // 100% 处的颜色
								// 			},
								// 		],
								// 		false
								// 	)
								// } else if (a === '氮离子') {
								// 	return new echarts.graphic.LinearGradient(
								// 		0,
								// 		0,
								// 		0,
								// 		1,
								// 		[
								// 			{
								// 				offset: 0,
								// 				color: 'rgba(56,190,211,1)', // 0% 处的颜色
								// 			},
								// 			{
								// 				offset: 0.9,
								// 				color: 'rgba(56,190,211,0)', // 100% 处的颜色
								// 			},
								// 			{
								// 				offset: 1,
								// 				color: 'rgba(46,183,204,0)', // 100% 处的颜色
								// 			},
								// 		],
								// 		false
								// 	)
								// }
							},
						},

						data: data1,
					},
					// 上面的头
					{
						name: '',
						type: 'pictorialBar',
						symbolSize: [15, 8],
						symbolOffset: [0, -5],
						z: 12,
						itemStyle: {
							opacity: 1,
							color: function (params) {
								var a = params.name
								return colors[a]
								// if (a === '磷' || a == '土温') {
								// 	return '#D5FB43'
								// } else if (a === '钾' || a == '土湿') {
								// 	return '#3BF9A4'
								// } else if (a === '氮') {
								// 	return '#4AE6F4'
								// }
							},
						},
						symbolPosition: 'end',
						data: data1,
					},
				],
			}
			this.drawPie(option, polyCenter, allData, facilityId, names)
			// viewer.camera.flyTo({
			// 	destination: Cesium.Cartesian3.fromDegrees(116.19777, 39.03883, 10000000.0),
			// })
		},
		drawPie(option, polyCenter, allData, facilityId, names) {
			let _this = this
			let canvasDom = document.createElement('canvas')
			canvasDom.width = 100
			canvasDom.height = 200
			let myChart = echarts.init(canvasDom)
			myChart.setOption(option)
			myChart.on('finished', () => {
				cesiumViewer.entities.add({
					position: polyCenter,
					name: 'echarts',
					type: 'echarts',
					facilityId: facilityId,
					truename: names,
					diadata: JSON.stringify(allData),
					// disableDepthTestDistance: 2000,
					billboard: {
						// 图像地址，URI或Canvas的属性
						image: myChart.getDataURL(),
						// 设置颜色和透明度
						color: Cesium.Color.WHITE.withAlpha(1),
						// 高度（以像素为单位）
						height: 200,
						// 宽度（以像素为单位）
						width: 100,
						// 逆时针旋转
						rotation: 0,
						// 大小是否以米为单位
						sizeInMeters: false,
						// 相对于坐标的垂直位置(偏移)
						verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
						// 相对于坐标的水平位置(偏移)
						horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
						// 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
						pixelOffset: new Cesium.Cartesian2(-30, 0),
						// 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
						scale: 1.0,
						// 是否显示
						show: true,
						zIndex: 5,
					},
				})
				myChart.dispose()
				myChart = null
				canvasDom = null
			})
		},

		// 地图鼠标左键点击事件
		mapHandlerClick() {
			let _this = this
			let handler = new Cesium.ScreenSpaceEventHandler(cesiumViewer.scene.canvas)
			handler.setInputAction(function (click) {
				let pick = cesiumViewer.scene.pick(click.position)
				if (pick && pick.id) {
					let items = cesiumViewer.entities.getById(pick.id.id)
					// 点击的类型
					let types = items.type
					let name = items.name
					// 点击的左边点
					let c = new Cesium.Cartesian2(click.position.x, click.position.y)
					// 点击的是模型
					if (types == 'model' || types == 'icon') {
						if (name == '基地服务中心' || name == '圣丰') {
							let modelifFan = true
							// 主要用于判断设置智脑中心地图上的弹窗位置
							if (_this.tabIndex == 2 && _this.isZnMap) {
								if (Number(c.x) > 1390) {
									_this.diaLeft = c.x - 445 + 'px'
									modelifFan = true
								} else {
									_this.diaLeft = c.x + 100 + 'px'
									modelifFan = false
								}
								_this.canvasWarnLine('#91640D', c.x, c.y, 100, 80, modelifFan)
							} else {
								if (Number(c.x) > 690) {
									_this.diaLeft = c.x + 491 + 'px'
									modelifFan = true
								} else {
									_this.diaLeft = c.x + 1039 + 'px'
									modelifFan = false
								}
								_this.canvasWarnLine('#91640D', c.x + 939, c.y, 100, 80, modelifFan)
							}
							_this.diaTop = c.y + 'px'
							_this.openDiaType = 'jd'
						}
						if (name == '基地服务中心') {
							_this.jdTitle = '农场'
							_this.getJdName('0')
						} else if (name == '圣丰') {
							_this.jdTitle = '圣丰'
							_this.getJdName('1')
						}
					} else if (types == 'points') {
						let online = items.online
						console.log(online,items,'online');
						
						if (online == '1') {
							_this.$message({
								message: '当前设备为离线状态',
								customClass: 'pagemessage',
							})
							return
						}
						// 点击的是设备点
						let type = items.types
						let name = items.name
						let equipName = items.equipname
						// 设备点
						let equipId = items.facilityId
						// 赋值设备点id
						_this.equipId = equipId
						// 主要用于预警信息
						let normal = items.normal
						let id = items.ids
						let times = items.times
						let warnMsg = items.sendmsg
						let warnWord = items.warnword
						let xx = 0
						_this.equipName = equipName
						// 判断x的坐标点，设置位置
						let thisx = Number(c.x)
						let hs = Math.abs(Number(c.y))
						console.log(c)
						// let cx = Number(c.x)
						let isf = false
						// if (thisx < 1490) {
						// 	xx = thisx + 200
						// 	cx = Number(c.x)
						// 	isf = false
						// }
						// if (1490 < thisx && thisx < 2300) {
						// 	xx = thisx - 662
						// 	cx = cx - 200
						// 	isf = true
						// }
						// if (2300 < thisx && thisx < 3150) {
						// 	xx = thisx + 200
						// 	cx = Number(c.x)
						// 	isf = false
						// }
						// if (thisx > 3150) {
						// 	xx = thisx - 662
						// 	cx = cx - 200
						// 	isf = true
						// }
						// 主要用于判断设置智脑中心地图上的弹窗位置
						if (_this.tabIndex == 2 && _this.isZnMap) {
							if (thisx > 1390) {
								xx = thisx - 640
								isf = true
							} else {
								xx = thisx + 200
								isf = false
							}
							// 绘制连接线
							_this.canvasEquipLine('#0CC3C6', thisx + 23, 200, hs, isf)
						} else {
							if (thisx > 690) {
								xx = thisx + 260
								isf = true
							} else {
								xx = thisx + 1119
								isf = false
							}
							// 绘制连接线
							_this.canvasEquipLine('#0CC3C6', thisx + 939, 200, hs, isf)
						}

						_this.diaLeft = xx + 'px'
						_this.diaTop = '70px'

						if (type == 'cq' || type == 'sq' || type == 'qx') {
							_this.openDiaType = 'equip'
							_this.diaType = type
						} else {
							_this.openDiaType = type
							_this.diaType = type
						}

						// 点击的是虫情
						if (type == 'cq') {
							_this.$nextTick(() => {
								// 虫情图片
								_this.getCqImg()
								// 虫情折线图
								_this.getCqLineData(_this.timeSlotChange('本月').time1, _this.timeSlotChange('本月').time2)
								// 虫情防治
								_this.getCqControlData()
							})
						} else if (type == 'qx') {
							_this.$nextTick(() => {
								// 气象列表数据
								_this.getQxData()
								// 气象下拉选数据
								_this.getQxSelect()
							})
						} else if (type == 'mq') {
							if (name == '枪机') {
								_this.isCanYt = false
							} else {
								_this.isCanYt = true
							}
							let trId = items.trid
							_this.mqTrId = trId
							let qxId = items.qxid
							_this.mqQxId = qxId
							_this.mqName = items.mqname
							_this.channelNoP = items.channel
							// 点击的是苗情
							_this.$nextTick(() => {
								// 苗情建议
								_this.getMqBottom()
								// 2022/03/31  获取hls拉流
								_this.initVideo()
								// 获取token
								// _this.getToken()

								// // 视频赋值播放
								// setTimeout(() => {
								// 	_this.deviceSerial = equipId
								// 	_this.mianfun('ezopen://open.ys7.com/' + equipId + '/1.live')
								// }, 1000)
							})
						} else if (type == 'sq') {
							// 墒情列表数据
							_this.getSqData()
							// 墒情进度条饼图
							// _this.sqPieEcharts()
							// 墒情下拉选
							_this.getSqSelect()
						}
						// 是否在线的赋值
						_this.isOnline = online == '0' ? true : false
						// 是否预警的赋值
						if (online == '0') {
							if (normal == '1') {
								_this.isWarn = true
								// _this.openDiaType = 'warn'
								_this.warnWord = warnWord
								_this.warnTime = times
								_this.warnMsg = warnMsg
								// 赋值预警弹窗的位置
								// 主要用于判断设置智脑中心地图上的弹窗位置
								if (_this.tabIndex == 2 && _this.isZnMap) {
									_this.warnLeft = c.x - 425 + 'px'
									_this.warnTop = c.y - 70 + 'px'
									_this.warnX = c.x + 20
								} else {
									_this.warnLeft = c.x + 491 + 'px'
									_this.warnTop = c.y - 70 + 'px'
									_this.warnX = c.x + 939
								}

								_this.warnY = c.y
							} else {
								_this.isWarn = false
								_this.warnLeft = '0'
								_this.warnTop = '0'
							}
						}
					} else if (types == 'onlineicon') {
						// 点击的是在离线状态
						let online = items.online
						let normal = items.normal
						let id = items.ids
						let times = items.times
						let warnMsg = items.sendmsg
						let warnWord = items.warnword
						if (online == '1') {
							_this.$message({
								message: '当前设备为离线状态',
								customClass: 'pagemessage',
							})
						} else {
							if (normal == '1') {
								// 异常，弹出提醒层
								_this.diaLeft = c.x + 1119 + 'px'
								_this.diaTop = c.y + 'px'
								_this.openDiaType = 'warn'
								_this.warnWord = warnWord
								_this.warnTime = times
								_this.warnMsg = warnMsg
							}
						}
					} else if (types == 'iconlist') {
						// 点击的是圣丰的设备标记点
						// 主要用于判断设置智脑中心地图上的弹窗位置
						if (_this.tabIndex == 2 && _this.isZnMap) {
							_this.diaLeft = c.x + 120 + 'px'
						} else {
							_this.diaLeft = c.x + 1039 + 'px'
						}
						_this.diaTop = c.y + 'px'
						_this.openDiaType = 'lb'
					} else if (types == 'echarts') {
						_this.openDiaType = 'chart'
						// 点击的是柱状图：弹出窗口
						_this.diaLeft = c.x + 50 + 'px'
						_this.diaTop = c.y - 10 + 'px'
						let allData = JSON.parse(items.diadata)
						_this.equipFaceId = items.facilityId
						_this.echartsData = allData
						_this.equipFaceName = items.truename
						// console.log(_this.equipDiaData)

						// 2022-07-27  新增弹窗事件处理
						_this.makeNewDia()
					}
				}
			}, Cesium.ScreenSpaceEventType.LEFT_CLICK)
		},
		// canvas绘制线条-预警和基地信息
		canvasWarnLine(color, x, y, w = 100, h = 80, isFan = true) {
			if (isFan) {
				this.ctxLeft = x - w + 'px'
			} else {
				this.ctxLeft = x + 'px'
			}

			this.ctxTop = y + 50 + 'px'
			let ctxdom = this.$refs.canvasewarn
			let ctx = this.$refs.canvasewarn.getContext('2d')
			ctxdom.width = w
			ctxdom.height = h
			ctx.beginPath()
			if (isFan) {
				ctx.moveTo(0, 0)
				ctx.lineTo(50, 0)
				ctx.lineTo(w, h)
			} else {
				ctx.moveTo(0, h)
				ctx.lineTo(50, 0)
				ctx.lineTo(w, 0)
			}

			ctx.lineWidth = 2
			ctx.strokeStyle = color
			ctx.stroke()
		},
		// canvas绘制线条-设备点弹层
		canvasEquipLine(color, x, w, h, isf) {
			if (isf) {
				this.ctxELeft = x - w + 'px'
			} else {
				this.ctxELeft = x + 'px'
			}

			this.ctxETop = '145px'
			let ctxdom = this.$refs.canvasequips
			let ctx = this.$refs.canvasequips.getContext('2d')
			ctxdom.width = w
			ctxdom.height = h
			ctx.beginPath()
			if (isf) {
				ctx.moveTo(0, 0)
				ctx.lineTo(100, 0)
				ctx.lineTo(w, h)
			} else {
				ctx.moveTo(0, h)
				ctx.lineTo(100, 0)
				ctx.lineTo(w, 0)
			}

			ctx.lineWidth = 2
			ctx.strokeStyle = color
			ctx.stroke()
		},
		// 加载模型
		makeNcModel(item) {
			let { name, lon, lat, path, icon, scale, headings, width, lons, lats } = item
			var position = Cesium.Cartesian3.fromDegrees(lon, lat, 0)
			let positions = Cesium.Cartesian3.fromDegrees(lons, lats, 15)
			var heading = Cesium.Math.toRadians(headings)
			var pitch = 0
			var roll = 0
			var hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll)
			var orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr)
			// 模型
			cesiumViewer.entities.add({
				position: position,
				orientation: orientation,
				name: name,
				type: 'model',
				model: {
					uri: path,
					//不管缩放如何，模型的最小最小像素大小。
					minimumPixelSize: 10,
					//模型的最大比例尺大小。 minimumPixelSize的上限。
					maximumScale: 20000,
					scale: scale, // 模型缩放，设置大小
				},
			})
			// 文字
			cesiumViewer.entities.add({
				type: 'label',
				position: positions,
				// disableDepthTestDistance: 2000,
				billboard: {
					// 图像地址，URI或Canvas的属性
					image: icon,
					// 设置颜色和透明度
					color: Cesium.Color.WHITE.withAlpha(1),
					// 高度（以像素为单位）
					height: 40,
					// 宽度（以像素为单位）
					width: width,
					// 逆时针旋转
					rotation: 0,
					// 大小是否以米为单位
					sizeInMeters: false,
					// 相对于坐标的垂直位置(偏移)
					verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
					// 相对于坐标的水平位置(偏移)
					horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
					// 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
					pixelOffset: new Cesium.Cartesian2(0, -10),
					// 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
					scale: 1.0,
					// 是否显示
					show: true,
				},
			})
			// 基地上面的图标
			if (name == '基地服务中心') {
				cesiumViewer.entities.add({
					position: Cesium.Cartesian3.fromDegrees(116.2685, 35.595, 65),
					type: 'icon',
					name: name,
					billboard: {
						// 图像地址，URI或Canvas的属性
						image: '/glb/farms/js.png',
						// 设置颜色和透明度
						color: Cesium.Color.WHITE.withAlpha(1),
						// 高度（以像素为单位）
						height: 120,
						// 宽度（以像素为单位）
						width: 65,
						// 逆时针旋转
						rotation: 0,
						// 大小是否以米为单位
						sizeInMeters: false,
						// 相对于坐标的垂直位置(偏移)
						verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
						// 相对于坐标的水平位置(偏移)
						horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
						// 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
						pixelOffset: new Cesium.Cartesian2(40, 0),
						// 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
						scale: 1.0,
						// 是否显示
						show: true,
					},
				})
			}
			// cesiumViewer.trackedEntity = entity
		},
		// 加载农场地块
		mapDiKuai() {
			let _this = this
			var features = require('@/assets/json/ncdk.json')
			let promise = Cesium.GeoJsonDataSource.load(features)
			promise.then((dataSource) => {
				cesiumViewer.dataSources.add(dataSource)
				var entities = dataSource.entities.values
				// // 填充每一个地块的颜色和文本
				for (var i = 0; i < entities.length; i++) {
					var entity = entities[i]
					let dkImg = '/glb/farms/nc.png'
					let text = entity.name
					let owner = entity.owner
					// 获取地块名称
					// dkImg = '/glb/farms/color1.png'
					// 得到每块多边形的坐标集合
					var polyPositions = entity.polygon.hierarchy.getValue(Cesium.JulianDate.now()).positions
					// 根据坐标集合构造BoundingSphere获取中心点坐标
					var polyCenter = Cesium.BoundingSphere.fromPoints(polyPositions).center
					// 将中心点拉回到地球表面
					polyCenter = Cesium.Ellipsoid.WGS84.scaleToGeodeticSurface(polyCenter)
					cesiumViewer.entities.add({
						position: polyCenter,
						name: 'ncdk',
						// 边界
						polygon: {
							hierarchy: entity.polygon.hierarchy,
							outlineWidth: 2,
							fill: true,
							outline: true,
							outlineColor: Cesium.Color.fromCssColorString('#5BF6F3'),
							stRotation: Cesium.Math.toRadians(0), // 旋转角度
							extrudedHeight: 1,
							material: new Cesium.ImageMaterialProperty({
								image: dkImg,
								color: Cesium.Color.green,
								repeat: new Cesium.Cartesian2(1, 1), // 重复的设置
							}),
							eyeOffset: new Cesium.Cartesian3(0, 0, -2),
						},
					})
					// 地块文字
					cesiumViewer.entities.add({
						position: polyCenter,
						name: 'label',
						billboard: {
							// 图像地址，URI或Canvas的属性
							image: '/glb/farms/di_bg' + text + '.png',
							// 设置颜色和透明度
							color: Cesium.Color.WHITE.withAlpha(1),
							// 高度（以像素为单位）
							height: 40,
							// 宽度（以像素为单位）
							width: 150,
							// 逆时针旋转
							rotation: 0,
							// 大小是否以米为单位
							sizeInMeters: false,
							// 相对于坐标的垂直位置(偏移)
							verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
							// 相对于坐标的水平位置(偏移)
							horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
							// 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
							pixelOffset: new Cesium.Cartesian2(-75, -10),
							// 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
							scale: 1.0,
							// 是否显示
							show: true,
						},
					})
				}

				// if (callBack) {
				// 	callBack(dataSource)
				// }
			})
			// 智脑中心
			// 主要用于判断设置智脑中心地图上的视图和缩放
			let saleZoom = 1830.0
			let latNum = 35.57735282986337
			let pitchNum = -45
			// 判断是否是智脑中心
			if (this.tabIndex == 2 && this.isZnMap) {
				// 设置缩放比和视角角度
				saleZoom = 1900.0
				pitchNum = -30
				latNum = 35.56505282986337
			}
			// cesiumViewer.zoomTo(promise)
			// 调整视角
			cesiumViewer.camera.setView({
				// fromDegrees()方法，将经纬度和高程转换为世界坐标
				destination: Cesium.Cartesian3.fromDegrees(116.26953363418579, latNum, saleZoom),
				orientation: {
					// 指向
					heading: Cesium.Math.toRadians(0),
					// 视角
					pitch: Cesium.Math.toRadians(pitchNum),
					// roll: 0.0,
				},
			})
		},
		// 添加设备点
		addEquips(equips) {
			var features = equips
			console.log(features,'======================================')
			let _this = this
			for (var i = 0; i < features.length; i++) {
				var entity = features[i]

				let names = features[i].properties.name
				// 名称
				let equipName = features[i].properties.equipname
				// id值
				let facilityId = features[i].properties.facility_id || ''
				let ids = features[i].properties.id || ''
				// 是否有预警
				let is_normal = features[i].properties.is_normal || '0'
				// 是否在线
				let is_online = features[i].properties.is_online == '0' ? '0' : '1'
				// 预警信息
				let sendMsg = entity.properties.send_msg
				// 类型
				let types = ''
				let statusNum = ''
				// 图片地址
				let path = '',
					width = '',
					height = ''
				// 枪机对应的土壤id
				let trId = entity.properties.tr_id || ''
				// 枪机对应的气象id
				let qxId = entity.properties.qx_id || ''
				let mqname = entity.properties.facility_name || ''
				// 通道号
				let channel = entity.properties.channel_num || ''

				switch (names) {
					case '虫情':
						statusNum = '1'
						width = 45
						height = 90
						types = 'cq'
						break
					case '球机':
						statusNum = '2'
						width = 45
						height = 150
						types = 'mq'
						break
					case '气象':
						statusNum = '3'
						width = 45
						height = 120
						types = 'qx'
						break
					case '水肥':
						statusNum = '4'
						width = 45
						height = 90
						types = 'sf'
						break
					case '土壤':
						statusNum = '5'
						width = 47
						height = 68
						types = 'sq'
						break
					case '枪机':
						statusNum = '6'
						width = 35
						height = 88
						types = 'mq'
						break
					default:
						width = 38
						height = 100
						break
				}
				// 离线
				if (is_online == '1') {
					// 枪机的离线
					if (statusNum == '6') {
						path = '/glb/farms/qjL.png'
					} else {
						path = '/glb/farms/di' + statusNum + 'L.png'
					}
				} else {
					// 在线正常
					if (is_normal == '0') {
						if (statusNum == '6') {
							path = '/glb/farms/qjZ.png'
						} else {
							path = '/glb/farms/di' + statusNum + 'Z.png'
						}
					} else {
						// 在线预警
						if (statusNum == '6') {
							path = '/glb/farms/qjY.png'
						} else {
							path = '/glb/farms/di' + statusNum + 'Y.png'
						}
					}
				}
				// 添加设备
				cesiumViewer.entities.add({
					type: 'points',
					name: names,
					equipname: equipName,
					types: types,
					online: is_online == '0' ? '0' : '1',
					normal: is_normal || '0',
					facilityId: facilityId,
					times: features[i].properties.times || '',
					sendmsg: sendMsg || '',
					warnword: features[i].properties.column_name || '',
					trid: trId || '',
					qxid: qxId || '',
					mqname: mqname || '',
					channel: channel || '',
					position: Cesium.Cartesian3.fromDegrees(entity.geometry.coordinates[0], entity.geometry.coordinates[1], 5),
					// disableDepthTestDistance: 2000,
					billboard: {
						// 图像地址，URI或Canvas的属性
						image: path,
						// 设置颜色和透明度
						color: Cesium.Color.WHITE.withAlpha(1),
						// 高度（以像素为单位）
						height: height,
						// 宽度（以像素为单位）
						width: width,
						// 逆时针旋转
						rotation: 0,
						// 大小是否以米为单位
						sizeInMeters: false,
						// 相对于坐标的垂直位置(偏移)
						verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
						// 相对于坐标的水平位置(偏移)
						horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
						// 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
						pixelOffset: new Cesium.Cartesian2(-32, 0),
						// 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
						scale: 1.0,
						// 是否显示
						show: true,
					},
				})

				// 添加设备名称
				// 2022/08/16 添加 -- 转 addEquipName,09/06逻辑切换删除
				// 2022/08/16 添加  ---- end
				// 智脑中心，需要加载图表
				// TODO 不用
				// if (_this.tabIndex == 2) {
				// 	if (names == '土壤') {
				// 		let points = entity.properties.points
				// 		// 给设备旁边添加图表
				// 		if (_this.centerType == '0') {
				// 			_this.getCenterSfEcharts(facilityId, points)
				// 			// _this.getCenterSqEcharts(facilityId, entity.geometry.coordinates)
				// 		} else if (_this.centerType == '1') {
				// 			_this.getCenterSqEcharts(facilityId, points)
				// 		}
				// 	}
				// }
			}
		},

		// 圣丰地图数据的添加
		makeSf() {
			// 加载圣丰的地块
			this.makeSfDiKuai()
			// 加载模型
			this.makeSfModel()
		},
		// 圣丰地块
		makeSfDiKuai() {
			let _this = this
			var features = require('@/assets/json/sf.json')
			let promise = Cesium.GeoJsonDataSource.load(features)
			promise.then((dataSource) => {
				cesiumViewer.dataSources.add(dataSource)
				var entities = dataSource.entities.values
				// // 填充每一个地块的颜色和文本
				for (var i = 0; i < entities.length; i++) {
					var entity = entities[i]
					let dkImg = '/glb/farms/sf.png'
					let text = entity.name
					// 获取地块名称
					// dkImg = '/glb/farms/color1.png'
					// 得到每块多边形的坐标集合
					var polyPositions = entity.polygon.hierarchy.getValue(Cesium.JulianDate.now()).positions
					// 根据坐标集合构造BoundingSphere获取中心点坐标
					var polyCenter = Cesium.BoundingSphere.fromPoints(polyPositions).center
					// 将中心点拉回到地球表面
					polyCenter = Cesium.Ellipsoid.WGS84.scaleToGeodeticSurface(polyCenter)
					cesiumViewer.entities.add({
						position: polyCenter,
						name: 'sfdk',
						// 边界
						polygon: {
							hierarchy: entity.polygon.hierarchy,
							outlineWidth: 2,
							fill: true,
							outline: true,
							outlineColor: Cesium.Color.fromCssColorString('#5BF6F3'),
							stRotation: Cesium.Math.toRadians(0),
							extrudedHeight: 1,
							material: new Cesium.ImageMaterialProperty({
								image: dkImg,
								color: Cesium.Color.green,
								repeat: new Cesium.Cartesian2(1, 1),
							}),
							eyeOffset: new Cesium.Cartesian3(0, 0, -2),
						},
					})
				}

				// if (callBack) {
				// 	callBack(dataSource)
				// }
			})
			// 主要用于判断设置智脑中心地图上的视图和缩放
			let saleZoom = 700.0
			let latNum = 35.42906429359502
			let pitchNum = -50
			if (this.tabIndex == 2 && this.isZnMap) {
				saleZoom = 1000.0
				pitchNum = -45
				latNum = 35.42536429359502
			}
			cesiumViewer.camera.setView({
				// fromDegrees()方法，将经纬度和高程转换为世界坐标
				destination: Cesium.Cartesian3.fromDegrees(116.36564254760744, latNum, saleZoom),
				orientation: {
					// 指向
					heading: Cesium.Math.toRadians(0),
					// 视角
					pitch: Cesium.Math.toRadians(pitchNum),
					// roll: 0.0,
				},
			})
		},
		// 圣丰模型
		makeSfModel() {
			let { name, lon, lat, path, scale, headings } = this.sfModals
			var position = Cesium.Cartesian3.fromDegrees(lon, lat, 0)
			var heading = Cesium.Math.toRadians(headings)
			var pitch = 0
			var roll = 0
			var hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll)
			var orientation = Cesium.Transforms.headingPitchRollQuaternion(position, hpr)
			// 模型
			cesiumViewer.entities.add({
				position: position,
				orientation: orientation,
				name: name,
				type: 'model',
				model: {
					uri: path,
					//不管缩放如何，模型的最小最小像素大小。
					minimumPixelSize: 10,
					//模型的最大比例尺大小。 minimumPixelSize的上限。
					maximumScale: 20000,
					scale: scale, // 模型缩放，设置大小
				},
			})

			// 基地上面的图标
			cesiumViewer.entities.add({
				position: Cesium.Cartesian3.fromDegrees(116.36314406991005, 35.43286379076165, 80),
				type: 'icon',
				name: name,
				billboard: {
					// 图像地址，URI或Canvas的属性
					image: '/glb/farms/js.png',
					// 设置颜色和透明度
					color: Cesium.Color.WHITE.withAlpha(1),
					// 高度（以像素为单位）
					height: 120,
					// 宽度（以像素为单位）
					width: 65,
					// 逆时针旋转
					rotation: 0,
					// 大小是否以米为单位
					sizeInMeters: false,
					// 相对于坐标的垂直位置(偏移)
					verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
					// 相对于坐标的水平位置(偏移)
					horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
					// 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
					pixelOffset: new Cesium.Cartesian2(40, 0),
					// 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
					scale: 1.0,
					// 是否显示
					show: true,
				},
			})
			// 添加一个监测摄像头图标
			cesiumViewer.entities.add({
				position: Cesium.Cartesian3.fromDegrees(116.36243462562561, 35.432814618086574, 60),
				type: 'iconlist',
				billboard: {
					// 图像地址，URI或Canvas的属性
					image: '/glb/farms/qj.png',
					// 设置颜色和透明度
					color: Cesium.Color.WHITE.withAlpha(1),
					// 高度（以像素为单位）
					height: 100,
					// 宽度（以像素为单位）
					width: 55,
					// 逆时针旋转
					rotation: 0,
					// 大小是否以米为单位
					sizeInMeters: false,
					// 相对于坐标的垂直位置(偏移)
					verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
					// 相对于坐标的水平位置(偏移)
					horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
					// 该属性指定标签在屏幕空间中距此标签原点的像素偏移量
					pixelOffset: new Cesium.Cartesian2(40, 0),
					// 应用于图像的统一比例。比例大于会1.0放大标签，而比例小于会1.0缩小标签。
					scale: 1.0,
					// 是否显示
					show: true,
				},
			})
		},
		// 循环请求每个设备点的数据
		makeTrEcharts() {
			let _this = this
			let features
			this.equipXData = []
			this.equipNameXdata = []
			this.equipNameData = []
			Object.keys(this.equipDiaData).forEach((key, val) => {
				this.equipDiaData[key] = []
			})
			let ncJsons = require('../assets/json/equip1.json')
			let sfJsons = require('../assets/json/equipsf(1).json')
			// 农场
			console.log('pageIndex',pageIndex);
			
			if (this.pageIndex == '0') {
				features = ncJsons
			} else if (this.pageIndex == '1') {
				// 圣丰
				features = sfJsons
			}
			for (var i = 0; i < features.length; i++) {
				var entity = features[i]
				let facilityId = features[i].properties.facility_id || ''
				if (facilityId && facilityId != '' && entity.properties.name == '土壤') {
					let points = entity.properties.points
					let heatPoints = entity.properties.heatPoints || []
					let isOnline = entity.properties.is_online || '1'

					let equipName = entity.properties.equipname
					/*
					 * @description: 只有智脑中心添加设备点名称
					 * @date: 2022-09-06
					 * @auth: whc
					 */
					_this.addEquipName(entity)

					// 绘制热力图
					if (heatPoints && heatPoints.length) {
						let heatNums = entity.properties.heatNums || 100
						_this.makeHeatMap(heatPoints, heatNums)
					}
					if (isOnline) {
						// 设备添加存储 2022-07-27
						this.equipXData.push(facilityId)
						this.equipNameXdata.push(equipName)
						this.equipNameData.push({
							name: equipName,
							faceId: facilityId,
						})
						Object.keys(this.equipDiaData).forEach((key, val) => {
							this.equipDiaData[key].push('-')
						})

						// 给设备旁边添加图表
						if (_this.centerType == '0' && isOnline == '0') {
							_this.getCenterSfEcharts(facilityId, points, equipName)
						} else if (_this.centerType == '1' && isOnline == '0') {
							_this.getCenterSqEcharts(facilityId, points, equipName)
						}
					}
				}
			}
		},
		/*
		 * @Description: 智脑中心添加设备点名称
		 * @Author: Huichao Wang
		 * @Date: 2022-09-06
		 */
		addEquipName(entity) {
			console.log('???????????????????????');
			
			let names = entity.properties.name
			// 名称
			let equipName = entity.properties.equipname
			// 设备名称相关属性
			// 2022/08/16 添加
			// 设备名称的颜色
			let equipColor = ''
			// 设备名称的背景
			let equipBg = ''
			let equipY = -60
			let bgLeft = equipName.length * 8.1
			let labelWidth = equipName.length * 8.8
			let bgWidth = equipName.length * 14
			let is_online = entity.properties.is_online == '0' ? '0' : '1'

			switch (names) {
				case '虫情':
					equipY = -63
					break
				case '球机':
					equipY = -126
					break
				case '气象':
					equipY = -98
					break
				case '水肥':
					equipY = -65
					break
				case '土壤':
					equipY = -45
					break
				case '枪机':
					equipY = -62
					break
				default:
					break
			}
			// 离线
			if (is_online == '1') {
				equipColor = '#fff'
				equipBg = '/glb/farms/nameun.png'
			} else {
				equipColor = '#0efeec'
				equipBg = '/glb/farms/nameon.png'
			}

			cesiumViewer.entities.add({
				position: Cesium.Cartesian3.fromDegrees(entity.geometry.coordinates[0], entity.geometry.coordinates[1], 5),
				type: 'equipname',
				name: 'equipname',
				label: {
					text: equipName,
					font: '12px Source Han Sans CN',

					outlineWidth: 0,
					verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向以底部来计算标签的位置
					pixelOffset: new Cesium.Cartesian2(labelWidth, equipY - 5), // 偏移量
					showBackground: false,
					backgroundColor: Cesium.Color.fromCssColorString('rgba(255,255,255,0)'),
					style: Cesium.LabelStyle.FILL, //边框 FILL不要轮廓 , OUTLINE只要轮廓,FILL_AND_OUTLINE轮廓加填充
					fillColor: Cesium.Color.fromCssColorString(equipColor),
				},
				billboard: {
					//图标
					image: equipBg,
					width: bgWidth,
					height: 21,
					pixelOffset: new Cesium.Cartesian2(bgLeft, equipY - 10), //偏移量
				},
			})
		},
		// 智脑中心请求不同的接口
		// 肥力感知
		getCenterSfEcharts(facilityId, position, names, isReq = true) {
			let paths = this.$api.latestWeatherData
			let polyCenter = Cesium.Cartesian3.fromDegrees(position[0], position[1], 35)
			let times
			if (isReq) {
				times = this.getNowFormatDates()
			} else {
				times = this.getPredateH()
			}
			this.$http.https(paths + '?stationCode=' + facilityId, 'get', '', (res) => {
				if (res.meta && res.meta.success) {
					let datas = res.data.sensorVOs || []
					if (datas && datas.length) {
						let trXData = []
						let trYData = []
						let allData = [{}, {}, {}]
						datas.forEach((items) => {
							let params = {
								name: items.name,
								value: items.value,
								unit: items.unitName,
							}
							if (items.key == 'DLZ') {
								trXData[0] = items.name
								trYData[0] = items.value

								allData[0] = params
							} else if (items.key == 'LLZ') {
								trXData[1] = items.name
								trYData[1] = items.value

								allData[1] = params
							} else if (items.key == 'JLZ') {
								trXData[2] = items.name
								trYData[2] = items.value

								allData[2] = params
							}
							this.equipXData.forEach((item, index) => {
								if (item == facilityId) {
									if (items.key == 'DLZ') {
										this.$set(this.equipDiaData.dan, index, items.value)
										this.$set(this.equipNameData[index], 'dan', items.value)
									} else if (items.key == 'LLZ') {
										this.$set(this.equipDiaData.lin, index, items.value)
										this.$set(this.equipNameData[index], 'lin', items.value)
									} else if (items.key == 'JLZ') {
										this.$set(this.equipDiaData.jia, index, items.value)
										this.$set(this.equipNameData[index], 'jia', items.value)
									}
								}
							})
						})
						// 地块上的图表
						this.makeEcharts(polyCenter, trXData, trYData, allData, facilityId, names)
					} else {
						if (isReq) {
							this.getCenterSfEcharts(facilityId, position, names, false)
						}
					}
				}
			})
		},
		// 土壤感知
		getCenterSqEcharts(facilityId, position, names, isReq = true) {
			console.log(position)
			// 16082057
			let paths = this.$api.latestWeatherData
			let polyCenter = Cesium.Cartesian3.fromDegrees(position[0], position[1], 35)
			let times
			if (isReq) {
				times = this.getNowFormatDates()
			} else {
				times = this.getPredateH()
			}
			this.$http.https(paths + '?stationCode=' + facilityId, 'get', '', (res) => {
				if (res.meta && res.meta.success) {
					let datas = res.data.sensorVOs || []
					if (datas && datas.length) {
						let trXData = ['土温', '土湿']
						let trYData = [0, 0]
						let allData = [{}, {}]
						datas.forEach((items) => {
							if (items.key == 'TRWDA') {
								trYData[0] = items.value
								allData[0] = {
									name: '土温',
									value: items.value,
									unit: items.unitName,
								}
							} else if (items.key == 'TRSDA') {
								trYData[1] = items.value
								allData[1] = {
									name: '土湿',
									value: items.value,
									unit: items.unitName,
								}
							}
							this.equipXData.forEach((item, index) => {
								if (item == facilityId) {
									if (items.key == 'TRWDA') {
										this.$set(this.equipDiaData.tw, index, items.value)
										this.$set(this.equipNameData[index], 'tw', items.value)
									} else if (items.key == 'TRSDA') {
										this.$set(this.equipDiaData.ts, index, items.value)
										this.$set(this.equipNameData[index], 'ts', items.value)
									}
								}
							})
						})
						// 地块上的图表
						this.makeEcharts(polyCenter, trXData, trYData, allData, facilityId, names)
					} else {
						if (isReq) {
							this.getCenterSqEcharts(facilityId, position, names, false)
						}
					}
				}
			})
		},
		// 列表点击，切换播放地址
		changeList({ id }) {
			this.equipId = id
			this.channelNoP = '2'
			this.openDiaType = 'mq'
			this.diaTop = '180px'
			this.isCanYt = false
			this.mqTrId = '16082054'
			this.mqQxId = '16081970'
			this.initVideo()
			this.getMqBottom()
			// setTimeout(() => {
			// 	// 赋值通道号
			// 	this.deviceSerial = id
			// 	this.mianfun('ezopen://open.ys7.com/' + id + '/1.live')
			// }, 1000)
		},
		// 绘制热力图
		makeHeatMap(heatPoints, heatNums) {
			let gradient = {}
			// 肥力感知
			if (this.centerType == '0') {
				gradient = {
					0.1: 'rgba(73,113,64,0.3)',
					0.3: '#3af6a1',
					0.6: '#60afff',
					0.8: '#6FF4E9',
					1: '#fffc00',
				}
			} else {
				// 土壤感知
				gradient = {
					0.1: 'rgba(73,113,64,0.3)',
					0.3: 'rgba(209,160,24,0.7)',
					// 0.6: '#fe6034',
					0.6: 'rgba(254,96,52,0.8)',
					1: '#4783ff',
				}
			}
			let features = heatPoints
			let points = []
			let bpoint = []
			let lonMin = 0
			let lonMax = 0
			let latMax = 0
			let latMin = 0
			let lats = []
			let lons = []
			for (const coord of features) {
				lons.push(coord[0])
				lats.push(coord[1])
				points.push(coord[0], coord[1])
				bpoint.push(new Cesium.Cartographic(coord[0], coord[1], 0))
			}
			lonMax = Math.max.apply(null, lons) + 0.0008
			lonMin = Math.min.apply(null, lons) - 0.0008
			latMax = Math.max.apply(null, lats) + 0.0008
			latMin = Math.min.apply(null, lats) - 0.0008
			let bounds = new Cesium.Rectangle.fromCartographicArray(bpoint)
			// 初始化CesiumHeatmap
			let heatMap = CesiumHeatmap.create(cesiumViewer, bounds, {
				// heatmap相应参数
				backgroundColor: 'rgba(0,0,0,0)',
				gradient: gradient,
				radius: 80,
				maxOpacity: 0.7,
				minOpacity: 0.7,
				blur: 0.8,
			})
			// 添加数据 最小值，最大值，数据集
			heatMap.setWGS84Data(60, 100, this.getHeatData(lonMax, lonMin, latMax, latMin, heatNums))
			// heatmap生成的图片
			let imgs = heatMap._heatmap._renderer.canvas
			// 添加热力图贴图图层
			cesiumViewer.entities.add({
				name: 'heatpolygon',
				polygon: {
					height: 5, // 定义高度用作遮盖地块默认的种植物图片
					// 绘制区域
					hierarchy: Cesium.Cartesian3.fromDegreesArray(points),
					// 材质：贴图处理
					material: new Cesium.ImageMaterialProperty({
						image: imgs,
						transparent: true, // 透明度处理
					}),
				},
			})
		},
		getHeatData(lonMax, lonMin, latMax, latMin, length) {
			var data = []
			for (var i = 0; i < length; i++) {
				var x = Math.random() * (lonMax - lonMin) + lonMin
				var y = Math.random() * (latMax - latMin) + latMin
				var value = Math.random() * (100 - 60) + 60
				data.push({ x: x, y: y, value: value })
			}
			return data
		},

		// 2022-07-27  新增弹窗事件处理
		makeNewDia() {
			this.equipPage = 0
			// 数据重新排序
			// 名称排序
			let oldNameData = JSON.parse(JSON.stringify(this.equipNameData))
			oldNameData.sort((a, b) => (Number(a.name.slice(7)) > Number(b.name.slice(7)) ? 1 : -1))
			oldNameData.forEach((item, index) => {
				this.$set(this.equipXData, index, item.faceId)
				this.$set(this.equipNameXdata, index, item.name)
				if (this.modelType == '0') {
					this.$set(this.equipDiaData['dan'], index, item.dan || 0)
					this.$set(this.equipDiaData['lin'], index, item.lin || 0)
					this.$set(this.equipDiaData['jia'], index, item.jia || 0)
				} else {
					this.$set(this.equipDiaData['tw'], index, item.tw || 0)
					this.$set(this.equipDiaData['ts'], index, item.ts || 0)
				}
			})
			this.equipNameData = oldNameData

			let equipNameXdata = JSON.parse(JSON.stringify(this.equipNameXdata))
			let equipDiaData = JSON.parse(JSON.stringify(this.equipDiaData))
			let xdata = equipNameXdata.slice(0, 7)

			// 绘制柱状图
			if (this.modelType == '0') {
				this.$nextTick(() => {
					let series = {
						dan: equipDiaData.dan.slice(0, 7),
						lin: equipDiaData.lin.slice(0, 7),
						jia: equipDiaData.jia.slice(0, 7),
					}
					this.znDiaChart(xdata, series)
				})
			} else {
				this.$nextTick(() => {
					let series = {
						tw: equipDiaData.tw.slice(0, 7),
						ts: equipDiaData.ts.slice(0, 7),
					}
					this.znDiaChartTr(xdata, series)
				})
			}
		},
	},
}
