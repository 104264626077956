<template>
  <div>
    <div class="bar" ref="barRef"></div>
  </div>
</template>
<script>
import { loopShowTooltip } from '../../util/loop-tooltip'
export default{
    props: {
			data: {
				type: Object,
				required:true 
			},
		},
  data(){
    return{
      tooltipTimer:null
    }
  },
  mounted () {
    this.initBar();
  },
  watch:{
			data:{
				handler(){
					this.initBar();
			 	},
				deep:true
			}
		},
  destroyed () {
    this.tooltipTimer && this.tooltipTimer.clearLoop();
  },
  methods: {
    initBar(){
        const _this = this
        if(!this.data || !this.data.xAxis || !this.data.series || this.data.series.length <2){
            return;
        }
        let data = JSON.parse(JSON.stringify(this.data))
        let seriesData = data.series.slice(0,2);
        let myChart = this.$echarts.init(this.$refs.barRef)
        let xLabel = data.xAxis
        
        seriesData[0].data.forEach(item => item = +(item)) 
        seriesData[1].data.forEach(item => item = +(item)) 
        let max1 = Math.ceil(Math.max(...seriesData[0].data) / 100)  
        let max2 = Math.ceil(Math.max(...seriesData[1].data) / 100)
        if(max1 == 0) {max1 =1}
        if(max2 == 0) {max2 = 1}
        max1 = max1 *100
        max2 = max2*100
        const option = {
        color:["#00A8FF","#00A8FF"],
        // grid: {
        //     top: "15%",
        //     bottom: "12%"//也可设置left和right设置距离来控制图表的大小
        // },
        legend: {
            selectedMode:false,
            // data: ["电", "水"],
            top: "0%",
            textStyle: {
                color: "#ffffff"
            }
        },
        tooltip:{
            trigger: 'axis',
            backgroundColor: 'rgb(113,200,255, 0.28)',
            borderWidth: 0,
            axisPointer:{
                type:"line",
                lineStyle:{
                    color:"#3371FC"
                }
            },
            textStyle: {
                fontSize: 14,
                color: '#fff',
            },
            position: (pos,param,dom, rect, size)=>{
            var obj = {top: 60};
            let halfContent = size.viewSize[0] / 2;
            if(pos[0] <= halfContent) {
              obj['left'] = pos[0]
            } else if(pos[0] >halfContent) {
              obj['right'] = size.viewSize[0] - pos[0]
            }
            return obj;
          },
            formatter(params) {
                // console.log(params);
                return `<div style="font-size:18px;">${params[0].name}年：</div>
                        <div style="font-size:18px;">用水量：${params[0].value}吨</div>
                        <div style="font-size:18px;">用电量：${params[3].value}千瓦/时</div>`
            }
        },
        xAxis: {
            data: xLabel,
            axisLine: {
                show: true, //隐藏X轴轴线
   
            },
            axisTick: {
                show: false //隐藏X轴刻度
            },
            splitLine:{
                show:true,
                lineStyle:{
                    color:"rgba(255,255,255,.05)"
                }
            },
            axisLabel: {
                show: true,
                padding:[5,0,0,0],
                textStyle: {
                    color: "#fff", //X轴文字颜色
                    fontSize:14
                }
            },  
        },
        yAxis: [
            {
                type: "value",
                name: "（吨）",
                nameTextStyle: {
                    color: "#fff",
                    fontSize:14,
                    padding:[0,40,0,0]
                },
                splitLine: {
                    show: true,
                    lineStyle:{
                        color:"rgba(255,255,255,.05)"
                    }
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: true,
                    
                },
                minInterval:1,
                interval:max1/5,
												max:max1,

                axisLabel: {
                    show: true,
                    textStyle: {
                        color: "#fff",
                        fontSize:14
                    }
                },
                
            },
            {
                type: "value",
                name: "（千瓦/时）",
                nameTextStyle: {
                    color: "#fff",
                    fontSize:14,
                    padding:[0,0,0,40]
                },
                minInterval:1,
                interval:max2/5,
												max:max2,
                position: "right",
                splitLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                axisLine: {
                    show: true
                },
                axisLabel: {
                    show: true,
                    textStyle: {
                        color: "#fff",
                        fontSize:14
                    }
                }
            },
        ],
        series: [
             {
                name: seriesData[0].name,
                type: "bar",
                barWidth: 25,
                itemStyle: {
                    normal: {
                      "color": {
                          "type": "linear",
                          "x": 0,
                          "y": 0,
                          "x2": 0,
                          "y2": 1,
                          "colorStops": [
                              {
                                  "offset": 0,
                                  "color": "#00D8FF"
                              },
                              {
                                  "offset": 0.5,
                                  "color": "#00D8FF"
                              },
                              {
                                  "offset": 1,
                                  "color": "#00A8FF"
                              }
                          ],
                          "global": false
                      }
                    }
                },
                data: seriesData[0].data
            },
            {
                z: 1,
                name: seriesData[0].name,
                type: 'pictorialBar',
                symbolPosition: 'start',
                data: seriesData[0].data,
                symbol: 'circle',
                symbolOffset: [0, '50%'],
                symbolSize: [25, 10],
                silent: true,
            },
            {
                z: 3,
                name: seriesData[0].name,
                type: 'pictorialBar',
                symbolPosition: 'end',
                data: seriesData[0].data,
                symbol: 'circle',
                symbolOffset: [0, '-50%'],
                symbolSize: [25, 10],
                silent: true,
            },
            {
                name: seriesData[1].name,
                type: "line",
                yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
                smooth: false, //平滑曲线显示
                showAllSymbol: true, //显示所有图形。
                symbol: "circle", //标记的图形为实心圆
                symbolSize: 8, //标记的大小
                itemStyle: {
                    //折线拐点标志的样式
                    color: "#121E43",
                    borderColor:"#00D8FF",
                    borderWidth: 2,
                },
                lineStyle: {
                    color: "#00D8FF"
                },
                areaStyle:{
                    color: "rgba(0,216,255, 0.2)"
                },
                data: seriesData[1].data
            }
        ]
      };
      myChart.setOption(option)
      this.tooltipTimer && this.tooltipTimer.clearLoop();
      this.tooltipTimer = 0;
      this.tooltipTimer = loopShowTooltip(myChart, option, {
        interval: 3000, // 轮播间隔时间
        loopSeries: true, // 是否开启轮播循环
		    dataLength:xLabel.length
      });
    },
  }
}
</script>
<style lang='scss' scoped>
.bar{
  height: 282px;
    width: 600px;
}
</style>