<template>
  <div class="table_tdcont">
	  <div class="scroll-list">
		  <vue-seamless-scroll :class-option="classOption" :data="infoList"> 
		  <div class="seamless-warp">
		  	<div class="table_td " v-for="(item,index) in infoList" :key="index">
		  		<div :class="setclass(index,item)"> {{item}} </div>  
		  	</div>
		  </div>
			 
		  </vue-seamless-scroll>
	  </div>

  </div>
</template>
<script>
export default{
	 props: {
    data: {
      type: Array,
      required:true 
    },
  },
  data(){
    return{
        infoList:[],
		classOption: {
			step: .7, // 数值越大速度滚动越快
			// limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
			hoverStop: true, // 是否开启鼠标悬停stop
			direction: 1, // 0向下 1向上 2向左 3向右
			// openWatch: true, // 开启数据实时监控刷新dom
			singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
			singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
			waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
		},
    }
  },
	watch:{
			data:{
				handler(newVal){
					console.log(newVal)
					this.infoList = newVal;
			 	},
				deep:true,
				immediate:true
			}
		},
  mounted () {
    
  },
  methods: {
		initData(){},
	    setclass(index,item){
				if(item.length<10){
					return 'table_tdac3'
				}else if(item.length>15){
					return 'table_tdac1'
				}else{
					return 'table_tdac2'
				}
				
				
	    },
  },
}
</script>
<style lang='scss' scoped>
	 
	.table_tdcont{ 
		
		height: 300px;
		overflow: hidden;
		position: relative;
		 
		 .table_tdac1{
			 background: url('../../assets/img/wenbenkd.png') no-repeat;
			 background-size: 100% 100%;  
		 }
		 .table_tdac2{
		 			 background: url('../../assets/img/wenbenklc.png') no-repeat;
		 			 background-size: 100% 100%;  
		 }
		 .table_tdac3{
		 			 background: url('../../assets/img/wenbenkh.png') no-repeat;
		 			 background-size: 100% 100%;  
		 }
		.table_td{
			  div{
				  text-align: center;
				  min-width: 105px;
				  height: 70px; 
				   
				  width: auto;
				  line-height: 58px;
				  padding: 0 16px;
				  margin: 0 5px;
				  color: #fff;
			  }
				
		 
			
		}
		
		
		
	}
	
						  
	.seamless-warp {
		 display: flex;
		flex-wrap: wrap; 
	}
	.table_tdcont > .scroll-list:hover{
		  /* animation: none; */
	}					
	.table_tdcont > .scroll-list > .table_td { 
	    /* 滚动的项目需要有具体的高度 */
	  /* height: 70px; */
	}  				
	@keyframes scroll {
	    100% {
	        /* 需要滚动内容的总高度 */
	        top: -400px;
	    }
	} 
	
	
	
	
	
	/* 
	
.info-list{
    width: 100%;
    display: flex;
	    flex-wrap: wrap;
		height: 300px;
		overflow: hidden;
		position: relative;
    .info-box{ 
        text-align: center;
		min-width: 105px;
		height: 70px; 
        background: url('../../assets/img/wenbenklc.png') no-repeat;
		background-size: 100% 100%; 
		width: auto;
		line-height: 58px;
		padding: 0 16px;
		margin: 0 5px;
		color: #fff;
    }
	
	
	
	
} */
</style>