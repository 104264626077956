<template>
	<div class="c_white leftsecond">
		<!-- 资金投入-->
		<div class="zijin">
			<div class="c_white font_22 slide-titles flex flex_ju_l">
				<img src="../../assets/img/home/icon1_2.png" />
				<div class="fm_pm slide-title">资金投入</div>
			</div>
			<!-- 数值 -->
			<div class="flex zj-wrap">
				<!-- 左边数值 -->
				<div class="lefts">
					<div class="flex flex_col flex_a_c flex_ju_c left_allnum">
						<div class="font_20">产业园</div>
						<div class="font_20 zj_unit">总投资（万元)</div>
						<div class="zj_nums">{{ fundSum || '--' }}</div>
					</div>
					<div class="zj_other" v-for="(item, index) in capitalData" :key="index">
						<span class="zj_word">{{ item.name }}（万元）</span>
						<span class="zj_money">{{ item.val }}</span>
					</div>
				</div>
				<!-- 右边表格 -->
				<div class="rights">
					<div class="tables">
						<div class="flex table-header">
							<div class="head-div head-name">重点项目</div>
							<div class="head-div head-div-two">投资（万元）</div>
							<div class="flex flex_col flex_ju_c head-div-more">
								<div class="t_c">资金来源（万元）</div>
								<div class="flex flex_ju_sb" style="margin-top: 13px">
									<div>配套资金</div>
									<div>省级资金</div>
									<div>社会资金</div>
								</div>
							</div>
							<div class="flex flex_col flex_a_c flex_ju_c head-time">
								<div>实施进度</div>
								<div style="margin-top: 13px">2019-2021</div>
							</div>
						</div>
						<div class="table-body">
							<div class="flex body-list" v-for="(item, index) in marjorList" :key="index">
								<div class="body-div body-name" v-html="htmlNames(item.projectName)"></div>
								<div class="body-two">{{ item.invest || '--' }}</div>
								<div class="body-small">{{ item.supporting || '--' }}</div>
								<div class="body-small">{{ item.provincial || '--' }}</div>
								<div class="body-small">{{ item.society || '--' }}</div>
								<div class="body-div">{{ item.solution || '--' }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 物联网设备 -->
		<div class="wlwnums">
			<div class="c_white font_22 slide-titles flex flex_ju_l">
				<img class="icon_title" src="../../assets/img/home/icon2_2.png" />
				<div class="fm_pm slide-title">物联网设备</div>
			</div>
			<div class="wlw-wrap">
				<!-- 中间的总数量 -->
				<div class="wlw-div wlw_center">
					<div class="wlw-title">物联网设备</div>
					<div class="flex flex_ju_sb wlwnum-wrap">
						<div>
							<div class="font_18">总数</div>
							<div class="wlwnum-number">{{ totalDevice.total }}</div>
						</div>
						<div>
							<div class="font_18">在线</div>
							<div class="wlwnum-number">{{ totalDevice.online }}</div>
						</div>
						<div>
							<div class="font_18">离线</div>
							<div class="wlwnum-number">{{ totalDevice.offline }}</div>
						</div>
					</div>
				</div>
				<!-- 单个设备的数量 -->
				<div class="flex flex_ju_sb wlw_equips">
					<div class="flex flex_a_c wlw-div" v-for="(device, index) in deviceList" :key="index">
						<div class="wlw-title">{{ device.name }}</div>
						<div class="flex flex_col flex_ju_sb" style="height: 100%">
							<div class="flex flex_a_c">
								<img class="icon_dian" src="../../assets/img/homeMon/icon_dian.png" alt="" />
								<span>总数</span><span class="num-number">{{ device.total }}</span>
							</div>
							<div class="flex flex_a_c">
								<img class="icon_dian" src="../../assets/img/homeMon/icon_dian.png" alt="" />
								<span>在线</span><span class="num-number">{{ device.online }}</span>
							</div>
							<div class="flex flex_a_c">
								<img class="icon_dian" src="../../assets/img/homeMon/icon_dian.png" alt="" />
								<span>离线</span><span class="num-number">{{ device.offline }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @description 左边中间的组件：资金投入和物联网设备数据
 */
export default {
	data() {
		return {
			fundSum: '--', // 产业园总资产
			capitalData: [
				{
					name: '配套资金',
					val: '--',
				},
				{
					name: '省级资金',
					val: '--',
				},
				{
					name: '社会资金',
					val: '--',
				},
			], // 资金投入组件数据,
			deviceList: [], // 物联网设备
			totalDevice: {
				equipType: '5',
				name: '物联网设备',
				offline: '--',
				online: '--',
				total: '--',
			},
			otherDevice: {
				equipType: '',
				name: '',
				offline: '',
				online: '',
				total: '',
			},
			// 右侧重点项目
			marjorList: [],
		}
	},
	mounted() {
		this.getOverview()
		// 物联网设备数据获取
		this.getDeviceList()
		// 重点项目获取
		this.getMarjor()
	},
	methods: {
		// 产业园资产信息
		async getOverview() {
			const { data: res } = await this.$http.post(this.$api.overView)
			if (res.code == 0) {
				let data = res.data || {}
				let capitalDatas = [
					{
						name: '配套资金',
						val: data.partFunding,
					},
					{
						name: '省级资金',
						val: data.provincialFunds,
					},
					{
						name: '社会资金',
						val: data.socialFunds,
					},
				]
				this.capitalData = capitalDatas || this.$options.data().capitalData
				this.fundSum = data.fundSum || '--'
			} else {
				this.capitalData = this.$options.data().capitalData
				this.fundSum = '--'
			}
		},
		getDeviceList() {
			this.$http.post(this.$api.getEquipInfoList).then((res) => {
				let { data } = res.data
				let allDatas = []
				data.forEach((item) => {
					if (item.equipType == 5) {
						this.totalDevice = item
					} else {
						allDatas.push(item)
					}
				})
				this.deviceList = allDatas
				this.deviceList.splice(1, 0, this.otherDevice)
			})
		},
		// 重点项目获取
		async getMarjor() {
			const { data: res } = await this.$http.post(this.$api.getMajorProjectList)
			if (res.code == 0) {
				let data = res.data || []
				this.marjorList = data || []
			} else {
				this.marjorList = []
			}
		},
		// 调整重点项目名称显示
		htmlNames(names) {
			let thisName = names
			if (thisName.indexOf('产业融合发展推进工程') > -1 || thisName.indexOf('公共服务能力增强工程') > -1) {
				let arr = thisName.split('')
				arr.splice(4, 0, '</br>')
				thisName = arr.join('') //ab-cdef
			}
			return thisName
		},
	},
}
</script>

<style lang="less" scoped>
@bgs: 100% 100%;
.leftsecond {
	position: relative;
	z-index: 10;
	padding-top: 77px;
	width: 899px;
	margin-right: 20px;
	// titles
	.slide-titles {
		width: 899px;
		background: url('../../assets/img/bg_title.png') no-repeat;
		background-size: 100% 100%;

		.slide-title {
			height: 66px;
			line-height: 66px;
			font-size: 22px;
			font-family: Source Han Sans CN;
			font-weight: bold;
			color: #ffffff;
		}
		.icon_title {
			width: 32px;
			height: 32px;
			margin: 15px 12px 0 8px;
		}

		img {
			width: 50px;
			height: 50px;
			margin-top: 8px;
		}
	}
	// 资金投入
	.zj-wrap {
		// height: 380px;
		margin-top: 20px;
		padding-bottom: 20px;
		.lefts {
			.left_allnum {
				width: 243px;
				height: 243px;
				background: url('../../assets/img/homeMon/zong_bg.png') no-repeat;
				background-size: @bgs;
				font-family: 'Source Han Sans CN';
				.zj_unit {
					margin-top: 13px;
				}
				.zj_nums {
					font-family: 'YouSheBiaoTiHei';
					font-size: 30px;
					margin-top: 23px;
				}
			}
			.zj_other {
				margin-top: 22px;
				display: flex;
				align-items: center;
				.zj_word {
					font-family: 'Source Han Sans CN';
					font-size: 20px;
					font-weight: bold;
				}
				.zj_money {
					font-size: 26px;
					font-family: 'YouSheBiaoTiHei';
				}
			}
		}
		.rights {
			margin-left: 40px;
		}
		.tables {
			width: 609px;
			.table-header {
				width: 609px;
				height: 69px;
				background: #021f46;
				div {
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #ffffff;
				}
				.head-div {
					width: 22%;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.head-name {
					width: 25%;
				}
				.head-div-two {
					width: 20%;
				}
				.head-div-more {
					width: 34%;
				}
				.head-time {
					width: 22%;
				}
			}
			.table-body {
				width: 609px;
				height: 298px;
				overflow-y: scroll;
				.body-list {
					width: 609px;
					height: 70px;
					background-color: rgba(2, 31, 70, 0.28);
					margin-top: 4px;
					font-size: 16px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					color: #ffffff;
					border-left: 1px solid #25577e;
				}
				.body-div {
					width: 22%;
					height: 70px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.body-two {
					width: 19%;
					height: 70px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.body-small {
					width: 11.3%;
					height: 70px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.body-name {
					width: 25%;
					padding: 0 25px;
					line-height: 1.5;
					text-align: center;
				}
			}
		}
	}
	// 物联网设备
	.wlw-wrap {
		position: relative;
		.wlw-div {
			margin-top: 23px;
			width: 286px;
			height: 139px;
			background: url('../../assets/img/homeMon/wlw_bg.png') no-repeat;
			background-size: 100% 100%;
		}
		.wlw-title {
			font-size: 24px;
			font-family: 'YouSheBiaoTiHei';
		}
		.wlw_center {
			width: 280px;
			height: 140px;
			background: url('../../assets/img/homeMon/wlwnum_bg.png') no-repeat;
			background-size: 100% 100%;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			padding: 20px 30px 0;
			text-align: center;
			.wlwnum-wrap {
				margin-top: 20px;
			}
			.wlwnum-number {
				font-size: 20px;
				margin-top: 14px;
				font-family: 'YouSheBiaoTiHei';
			}
		}
		.wlw_equips {
			flex-wrap: wrap;
			.wlw-div {
				&:nth-child(2) {
					opacity: 0;
				}
				.wlw-title {
					width: 50px;
					margin-right: 20px;
				}
				padding: 7px 5px 7px 100px;
			}
			.wlw_dian {
				width: 33px;
				height: 33px;
				margin-right: 4px;
			}
			.num-number {
				font-family: 'YouSheBiaoTiHei';
				margin-left: 12px;
				font-size: 20px;
			}
		}
	}
}
</style>
