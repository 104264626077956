<template>
	<div>
		<div class="pie" ref="pieRef"></div>
		<div :class="['chart-title', 'chart' + (index + 1)]" v-for="(item, index) in data" :key="index">
			{{ item.name }}
		</div>
		<!-- <div class="chart-title chart2">墒情站</div>
    <div class="chart-title chart3">苗情站</div>
    <div class="chart-title chart4">虫情站</div> -->
	</div>
</template>
<script>
export default {
	props: {
		data: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {}
	},
	mounted() {
		this.initPie()
	},
	watch: {
		data: {
			handler() {
				this.initPie()
			},
			deep: true,
		},
	},
	methods: {
		initPie() {
			let myChart = this.$echarts.init(this.$refs.pieRef)
			let seriesData = []
			let data = JSON.parse(JSON.stringify(this.data))
			let sumValue = data.reduce((a, b) => {
				return a + b.value
			}, 0)
			data.forEach((item, index) => {
				let value = 0
				if (item.value > 0) {
					value = item.value / 100
				}
				seriesData.push(
					{
						name: '背景圈',
						type: 'gauge',
						radius: '70%',
						center: [12 + index * 25 + '%', '50%'],
						startAngle: 220,
						endAngle: -40,
						axisLine: {
							// 坐标轴线
							lineStyle: {
								// 属性lineStyle控制线条样式
								width: 22,
								color: [
									[
										1,
										new this.$echarts.graphic.LinearGradient(1, 0, 0, 1, [
											{
												offset: 0,
												color: 'rgba(255,255,255,.3)',
											},
											{
												offset: 1,
												color: 'rgba(255,255,255,.2)',
											},
										]),
									],
								],
							},
						},
						splitLine: {
							//分隔线样式
							show: false,
						},
						axisLabel: {
							//刻度标签
							show: false,
						},
						pointer: {
							show: false,
						},
						axisTick: {
							//刻度样式
							show: false,
						},
					},
					{
						name: '',
						type: 'gauge',
						radius: '70%',
						center: [12 + index * 25 + '%', '50%'],
						startAngle: 220,
						endAngle: -45,
						min: 0,
						max: 100,
						axisLine: {
							lineStyle: {
								width: 22,
								color: [
									[
										value,
										new this.$echarts.graphic.LinearGradient(0, 1, 1, 0, [
											{
												offset: 0,
												color: '#019690',
											},
											{
												offset: 1,
												color: '#33FF9B',
											},
										]),
									],
									[1, 'rgba(255,255,255,.0)'],
								],
							},
						},
						axisTick: {
							show: true,
							distance: -37,
							lineStyle: {
								width: 2,
								color: '#82FCF6',
							},
						},
						splitLine: {
							show: false,
						},
						axisLabel: {
							show: 0,
						},
						pointer: {
							show: false,
						},
						detail: {
							show: true,
							offsetCenter: [0, 0],
							textStyle: {
								fontSize: 30,
								color: '#ffffff',
							},
							formatter: function () {
								value = value * 100
								return value.toFixed(0) + '%'
							},
						},
						data: [
							{
								value: item.value,
							},
						],
					}
				)
			})
			const option = {
				series: seriesData,
			}
			myChart.setOption(option)
		},
	},
}
</script>
<style lang="scss" scoped>
.pie {
	width: 780px;
	height: 220px;
}
.chart-title {
	position: absolute;
	bottom: 5px;
	background-image: url('../../assets/img/homeAbnormal/lt-title.png');
	background-size: 98px 36px;
	color: #fff;
	font-size: 16px;
	padding: 10px 25px;
}
.chart1 {
	left: 37px;
}
.chart2 {
	left: 235px;
}
.chart3 {
	right: 248px;
}
.chart4 {
	right: 52px;
}
</style>
