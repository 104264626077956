import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './api/dateTools.js'
// ajax封装
import * as http from './api/request'
// 统一接口管理
import api from './api/api'
import * as echarts from 'echarts'

import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

import ElementUI from 'element-ui'
import {
	Message,
	Dialog,
	Tooltip,
	InfiniteScroll,
	Form,
	FormItem,
	Input,
	InputNumber,
	Switch,
	Select,
	Option,
	DatePicker,
	Image,
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { hoverLoop } from '@/util/loop.js'
import VueVideoPlayer from 'vue-video-player'
import 'videojs-flash'
import 'videojs-contrib-hls'
import 'videojs-flvjs-es6'
import 'video.js/dist/video-js.css'

window.videojs = VueVideoPlayer.videojs
require('vue-video-player/src/custom-theme.css')
require('video.js/dist/video-js.min.css')
require('video.js/dist/lang/zh-CN.js')
Vue.use(VueVideoPlayer)

import highcharts from 'highcharts'
import VueHighCharts from 'vue-highcharts'
import highcharts3d from 'highcharts/highcharts-3d'
highcharts3d(highcharts)

import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer)

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

Vue.use(ElementUI)
Vue.use(Dialog)
Vue.use(Tooltip)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Option)
Vue.use(InfiniteScroll)
Vue.use(DatePicker)
Vue.use(Image)

const showMessage = Symbol('showMessage')

class DonMessage {
	success(options, single = false) {
		this[showMessage]('success', options, single)
	}
	warning(options, single = false) {
		this[showMessage]('warning', options, single)
	}
	info(options, single = false) {
		this[showMessage]('info', options, single)
	}
	error(options, single = true) {
		this[showMessage]('error', options, single)
	}

	[showMessage](type, options, single) {
		if (single) {
			// 判断是否已存在Message
			if (document.getElementsByClassName('el-message--error').length === 0) {
				Message[type](options)
			}
		} else {
			Message[type](options)
		}
	}
}
Vue.prototype.$messages = new DonMessage()

Vue.prototype.$message = Message
Vue.prototype.$hoverLoop = hoverLoop

import CenMap from '@/views/Map.vue'
Vue.component('cen-map', CenMap)

Vue.prototype.$http = http
Vue.prototype.$echarts = echarts
Vue.prototype.$api = api
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
