<template>
  <div class="flex flex_a_c zncenter">
    <!-- 左边的圆球 -->
    <div class="flex flex_a_c lefts">
      <div class="left-yuans">
        <div class="yuans" v-for="(item, index) in leftDatas" :key="index">
          <div
            class="yuan-zhuan flex flex_col flex_a_c flex_ju_c c_white"
            :class="'yuan-zhuan' + (index + 1)"
          >
            <!-- 虫情虫子类型定位 -->
            <!-- <div class="yuan-cq" v-if="index == 1">
							<div class="yuan-cqspan ellipsis" :class="'yuan-cqspan' + indexs" v-for="indexs in 5" :key="indexs">
								{{ cqLeftTypes && cqLeftTypes[indexs - 1] ? cqLeftTypes[indexs - 1] : '暂无数据' }}
							</div>
						</div> -->
            <div class="yuan-title">{{ item.name }}</div>
            <span class="yuan-nums">本月采集：{{ item.num }}条</span>
          </div>
        </div>
      </div>
      <!-- 线条和箭头 -->
      <!-- <img class="yuan-jian yuano-jo" src="../assets/img/brains/line1.gif" alt="" />
			<img class="yuan-jian yuano-jt" src="../assets/img/brains/line2.gif" alt="" />
			<img class="yuan-jian yuano-je" src="../assets/img/brains/line3.gif" alt="" />
			<img class="yuan-jian yuano-jf" src="../assets/img/brains/line4.gif" alt="" /> -->
      <div class="xuxian">
        <img
          class="icon_jian icon_jian1"
          src="../assets/img/brains/icon_jian.png"
          alt=""
        />
        <img
          class="icon_jian icon_jian2"
          src="../assets/img/brains/icon_jian.png"
          alt=""
        />
        <img
          class="icon_jian icon_jian3"
          src="../assets/img/brains/icon_jian.png"
          alt=""
        />
        <img
          class="icon_jian icon_jian4"
          src="../assets/img/brains/icon_jian.png"
          alt=""
        />
      </div>
    </div>
    <!-- 中间的数值和图片 -->
    <div class="centers">
      <div class="flex flex_a_c flex_ju_c titles">
        <span class="title-span"
          >{{ Number(this.nowMonth) }}月累计数据采集</span
        >
        <div class="flex flex_a_c">
          <div
            class="t_c num-span"
            v-for="(item, index) in counts"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <!-- 下面的图片 -->
      <div class="c-imgs">
        <img
          class="left-jian-one left-jian"
          src="../assets/img/brains/left_jian.png"
          alt=""
        />
        <img
          class="left-jian-teo left-jian"
          src="../assets/img/brains/left_jian.png"
          alt=""
        />
      </div>
      <!-- 下面的时间 -->
      <div class="flex flex_a_c flex_ju_c times">
        <div class="c_white times-year">
          <el-select
            popper-class="yearselect"
            class="censelect"
            v-model="yearChoose"
            @change="yearChange"
          >
            <el-option
              v-for="(items, indexs) in options"
              :key="indexs"
              :value="items.value"
              :label="items.label"
            ></el-option>
          </el-select>
        </div>
        <div class="flex times-month">
          <div
            class="font_14 c_white month-span"
            v-for="index in 12"
            :key="index"
            @click="chooseMonth(index)"
          >
            <img
              v-if="monthChoose == String(index)"
              class="t_yuan"
              src="../assets/img/brains/t_yuan.png"
              alt=""
            />
            <div
              class="month-spans"
              :class="{ 'month-span-active': monthChoose == String(index) }"
            >
              {{ index }}月
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 右边列表部分 -->
    <div class="flex flex_a_c nav">
      <!-- 处理记录 -->
      <div class="deal">
        <div class="flex flex_a_c flex_ju_sb">
          <div class="flex flex_a_c">
            <img src="../assets/img/HomeBrain/zu2415.png" alt class="zu" />
            <span class="record-title">处理记录</span>
          </div>
          <div class="flex flex_a_c">
            <span
              class="tap1"
              :class="{ 'tap-active': tableTab == item.val }"
              v-for="(item, index) in tableTabsData"
              :key="index"
              @click="changeRecord(item)"
              >{{ item.name }}</span
            >
          </div>
        </div>

        <div class="font_14 c_white flex table-tab">
          <div class="table-span table-types">设备名称</div>
          <div class="table-span table-nums">参数</div>
          <div class="table-span table-times">时间</div>
        </div>
        <!-- 虫情选中 -->
        <div
          class="c_white table-list tablece-list"
          v-if="tableTab == '2' && tableData.length"
        >
          <div class="flex table-li" v-if="cqChoose.deviceCode">
            <div class="table-span table-types">{{ cqChoose.deviceName }}</div>
            <div class="table-span table-nums">图片</div>
            <div class="table-span table-times">{{ cqChoose.photoTime }}</div>
          </div>
          <div class="table-li table_n" v-else>点击下列图片查看详细信息</div>
        </div>
        <!-- table滚动 -->
        <div class="max" @click="handleSectionClick($event)">
          <vue-seamless
            :data="tableData"
            class="seamtable"
            :style="{ height: scrollHeight }"
            :class-option="seamlessOption"
            v-if="tableData.length"
          >
            <ul class="c_white table-list">
              <li
                v-for="(item, index) in tableData"
                :key="index"
                class="flex table-li"
              >
                <!-- <div class="table-span table-types" v-if="tableTab == '2'">{{ cqCodeName(item.deviceCode) }}</div> -->
                <div class="table-span table-types">
                  {{ item.name || item.facName || item.deviceName }}
                </div>
                <div
                  v-if="tableTab == '0' || tableTab == '1'"
                  class="table-span table-nums"
                >
                  {{ item.acquisitionItemName }}: {{ item.acquisitionData
                  }}{{ item.acquisitionItemUnitCode }}
                </div>
                <div
                  v-else-if="tableTab == '2'"
                  class="table-span table-nums cursor"
                  :data-val="JSON.stringify(item)"
                >
                  图片
                </div>
                <div v-else class="table-span table-nums">
                  {{ item.value }}
                </div>
                <div class="table-span table-times">
                  {{ item.datetime || item.acquisitionTime || item.photoTime }}
                </div>
              </li>
            </ul>
          </vue-seamless>
          <img
            v-else
            src="../assets/img/none.png"
            style="width: 80%; margin: 70px 10% 0"
            alt=""
          />
        </div>
      </div>
      <!-- 箭头 -->
      <img class="right-jian" src="../assets/img/brains/left_jian.png" alt="" />
      <!-- 监测数据 -->
      <div class="advice" v-if="!isCqImg">
        <!-- 气象监测 -->
        <div class="shang">
          <div>
            <img src="../assets/img/HomeBrain/zu2415.png" alt class="zu" />
            <span>气象监测</span>
            <span class="tap" :class="{ tap0: qxThis }" @click="qxTabs(1)"
              >本月情况</span
            >
            <span class="tap1" :class="{ tap0: !qxThis }" @click="qxTabs(0)"
              >历史同期</span
            >
            <!-- 本月 -->
            <div class="yue" v-if="qxThis">
              <!-- 左1 -->
              <yue-modal :datas="qxJcData" tabindex="0"></yue-modal>
            </div>
            <!-- 历史 -->
            <div v-else style="position: relative; z-index: 12">
              <!-- 下拉框 -->
              <div class="selects">
                <div class="kuang1">
                  <el-select
                    popper-class="yearselect"
                    v-model="qxYear"
                    placeholder="请选择"
                    @change="qxSelectYear"
                  >
                    <el-option
                      v-for="item in lineOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
                <div class="kuang2">
                  <el-select
                    popper-class="yearselect"
                    v-model="qxItemActive"
                    placeholder="请选择"
                    @change="qxItemSelect"
                  >
                    <el-option
                      v-for="item in qxItem"
                      :key="item.itemCode"
                      :label="item.itemName"
                      :value="item.itemCode"
                    ></el-option>
                  </el-select>
                </div>
              </div>

              <!-- 折线图 -->
              <div class="echarts-wrap" v-if="isQxLine">
                <div class="echarts" ref="qxecharts"></div>
              </div>
              <div class="flex flex_a_c echarts-wrap" v-else>
                <img class="emptydata" src="../assets/img/none.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <!-- 墒情监测 -->
        <div class="shang">
          <img src="../assets/img/HomeBrain/zu2415.png" alt class="zu" />
          <span>墒情监测</span>
          <span class="tap" :class="{ tap0: sqThis }" @click="sqTabs(1)"
            >本月情况</span
          >
          <span class="tap1" :class="{ tap0: !sqThis }" @click="sqTabs(0)"
            >历史同期</span
          >
          <!-- 本月 -->
          <div class="yue" v-if="sqThis">
            <!-- 左1 -->
            <yue-modal :datas="sqJcData" tabindex="1"></yue-modal>
          </div>
          <!-- 历史 -->
          <div v-else style="position: relative; z-index: 12">
            <!-- 下拉框 -->
            <div class="selects">
              <div class="kuang1">
                <el-select
                  popper-class="yearselect"
                  v-model="sqYear"
                  placeholder="请选择"
                  size="mini"
                  @change="sqSelectYear"
                >
                  <el-option
                    v-for="item in lineOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="kuang2">
                <el-select
                  popper-class="yearselect"
                  v-model="sqItemActive"
                  placeholder="请选择"
                  size="mini"
                  @change="sqItemSelect"
                >
                  <el-option
                    v-for="item in sqItem"
                    :key="item.itemCode"
                    :label="item.itemName"
                    :value="item.itemCode"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <!-- 折线图 -->
            <div class="echarts-wrap" v-if="isSqLine">
              <div class="echarts" ref="sqecharts"></div>
            </div>
            <div class="flex flex_a_c echarts-wrap" v-else>
              <img class="emptydata" src="../assets/img/none.png" alt="" />
            </div>
          </div>
        </div>
        <!-- 虫情监测 -->
        <div class="shang">
          <img src="../assets/img/HomeBrain/zu2415.png" alt class="zu" />
          <span>虫情监测</span>
          <span class="tap" :class="{ tap0: cqThis }" @click="cqTabs(1)"
            >本月情况</span
          >
          <span class="tap1" :class="{ tap0: !cqThis }" @click="cqTabs(0)"
            >历史同期</span
          >
          <!-- 本月 -->
          <div class="yue" v-if="cqThis">
            <!-- 左1 -->
            <yue-modal :datas="cqJcData" tabindex="2"></yue-modal>
          </div>
          <!-- 历史 -->
          <div v-else style="position: relative; z-index: 12">
            <!-- 下拉框 -->
            <div class="selects">
              <div class="kuang1">
                <el-select
                  popper-class="yearselect"
                  v-model="cqYear"
                  placeholder="请选择"
                  @change="cqSelectYear"
                >
                  <el-option
                    v-for="(item, index) in lineOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <!-- 折线图 -->
            <div class="echarts-wrap" v-if="isCqLine">
              <div class="echarts" ref="cqecharts"></div>
            </div>
            <div class="flex flex_a_c echarts-wrap" v-else>
              <img class="emptydata" src="../assets/img/none.png" alt="" />
            </div>
          </div>
        </div>
        <!-- 业务系统监测 -->
        <div class="shang">
          <!-- 标题及图标 -->
          <div class="biao1">
            <img src="../assets/img/HomeBrain/zu2415.png" alt class="zu" />
            <span>业务系统监测</span>
          </div>
          <!-- 左1 -->
          <div>
            <yue-modal
              :datas="ywJcData"
              tabindex="3"
              :jianyi="ywJianyi"
            ></yue-modal>
          </div>
        </div>
      </div>
      <!-- 虫情图片 -->
      <div class="flex flex_a_c cqimgs-all" v-else>
        <div class="cqimgs">
          <div class="flex flex_ju_sb flex_a_c">
            <div class="flex flex_a_c cq-titles">
              <img src="../assets/img/HomeBrain/zu2415.png" alt class="zu" />
              <span class="titles">虫情图片信息</span>
            </div>
            <div class="t_c cursor btn_back" @click="cqBack">返回</div>
          </div>
          <!-- 下面的图片 -->
          <ul class="cqimg-wrap" v-if="cqImgs.length">
            <li class="cqimg-li" v-for="(item, index) in cqImgs" :key="index">
              <img class="cq-img" v-image-preview :src="item" />
              <!-- <div class="ellipsis cqli-name">识别虫种：{{ item.name || '--' }}</div> -->
            </li>
          </ul>
          <img v-else class="cqimg-empty" src="../assets/img/none.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @description 智脑中心中间数据组件
 */
// 本月情况组件模块
import YueModal from "./yueModal.vue";
// 表格滚动插件
import vueSeamless from "vue-seamless-scroll";
import { loopShowTooltip } from "../util/loop-tooltip";
export default {
  components: {
    YueModal,
    vueSeamless,
  },
  data() {
    return {
      // orgId: 'f5bf0fb4-45bb-4bd4-8af5-4dbbc621ceb7',  // 本地
      orgId: "1476457105094451202", // 线上-正式
      // orgId: '1504029290314928130', // 线上-测试
      yearChoose: "", // 选中的年份时间
      monthChoose: "1", // 选中的月份时间
      thisTime: "", // 选中的时间拼接
      // 当前的年份和月份，用作比较
      nowYear: "",
      nowMonth: "",
      ywAllData: 0,
      monAllData: 0,
      // 左边数据
      leftDatas: [
        {
          name: "气象数据采集",
          num: "0",
          count: 0,
        },
        {
          name: "虫情数据采集",
          num: "0",
          count: 0,
        },
        {
          name: "墒情数据采集",
          num: "0",
          count: 0,
        },
        {
          name: "业务数据采集",
          num: "0",
          count: 0,
        },
      ],
      // 左边虫情数据采集虫子的类型
      cqLeftTypes: [],
      // 处理记录的列表数据
      tableData: [],
      tableTab: "0", // 处理记录的选中类型
      // 处理记录上面的选择项
      tableTabsData: [
        {
          name: "气象数据",
          val: "0",
        },
        {
          name: "墒情数据",
          val: "1",
        },
        {
          name: "虫情数据",
          val: "2",
        },
        {
          name: "业务数据",
          val: "3",
        },
      ],
      seamlessOption: {
        step: 0.4,
        limitMoveNum: 13,
      },
      // 显示的是否是虫情图片弹窗
      isCqImg: false,
      cqChoose: {}, // 虫情选中的数据
      // 虫情弹窗显示的图片
      cqImgs: [],
      options: "",
      lineOptions: [],
      // 气象
      qxYear: 0,
      qxUnit: "", // 折线图上面的单位
      qxItemActive: "",
      qxName: "",
      // 气象下拉选数据
      qxItem: [],
      // 墒情
      sqYear: "",
      sqItemActive: "",
      sqUnit: "",
      sqName: "",
      sqItem: [],
      // 虫情
      cqYear: "",

      // 显示与隐藏
      // 气象
      qxThis: true, // 气象显示隐藏
      sqThis: true, // 墒情显示隐藏
      cqThis: true, // 虫情显示隐藏

      qxJcData: [], // 气象监测数据
      qxJianyi: "暂无", // 气象建议
      sqJcData: [], // 墒情监测数据
      sqJianyi: "暂无", // 墒情建议
      cqJcData: [], // 虫情监测数据
      cqJianyi: "暂无", // 虫情建议

      ywJcData: [], // 业务监测数据
      ywJianyi: "暂无", // 业务建议

      qxEchartTime: null,
      sqEchartTime: null,
      cqEchartTime: null,

      isQxLine: true,
      isSqLine: true,
      isCqLine: true,
    };
  },
  created() {},
  mounted() {
    // 获取当前年份，主要用于时间下拉选赋值
    let thisYear = this.getNowYear();

    // 上一年
    let prevYear = Number(this.getNowYear()) - 1;
    let options = [];
    this.lineOptions = [];
    for (let i = 0; i < 3; i++) {
      options.push({
        value: thisYear - i + "年",
        label: thisYear - i + "年",
      });
    }
    this.options = options;
    this.yearChoose = thisYear + "年";
    for (let i = 0; i < 3; i++) {
      // if (prevYear - i > 2019) {
      this.lineOptions.push({
        value: prevYear - i,
        label: prevYear - i + "年",
      });
      // }
    }
    // 获取时间，并赋值拆分
    let thisTime = this.getNowYearMonth();
    this.thisTime = thisTime;
    let spls = thisTime.split("-");
    this.monthChoose = String(Number(spls[1]));

    this.nowYear = spls[0];
    this.nowMonth = spls[1];

    // 赋值折线图上面的时间选择
    // this.$nextTick(() => {
    this.qxYear = prevYear;
    this.sqYear = prevYear;
    this.cqYear = prevYear;
    // })

    // 获取气象\虫情\墒情数据采集
    this.getAllData();
    // 获取气象\虫情\墒情数据总采集
    this.getMonAllData();
    // 获取虫情类型的数据采集
    // this.getCqTypes()

    // 获取气象监测数据
    this.getQxJc();
    // 获取墒情监测数据
    this.getSqJc();
    // 获取虫情监测数据
    this.getCqJc();
    // 获取业务数据采集
    // 全部
    this.getYwData();
    // 本月
    this.getYwData(this.thisTime);

    // 获取气象数据记录类型
    this.getQxRecord();

    // // 获取气象监测数据
    // this.getQxJc()
    // // 获取墒情监测数据
    // this.getSqJc()
    // // 获取虫情监测数据
    // this.getCqJc()

    // 获取业务系统监测数据
    this.getYwJc();
  },
  destroyed() {
    this.qxEchartTime && this.qxEchartTime.clearLoop();
    this.sqEchartTime && this.sqEchartTime.clearLoop();
    this.cqEchartTime && this.cqEchartTime.clearLoop();
  },
  computed: {
    // 累计数据采集
    counts() {
      var result = this.leftDatas.reduce((total, num) => {
        return Number(total) + Number(num.num);
      }, 0);
      // let total = Number(this.ywAllData) + Number(this.monAllData)
      return String(result);
    },
    // 处理记录的滚动高度，主要用于区分虫情数据
    scrollHeight() {
      let h = "650px";
      if (this.tableTab == "2") {
        h = "560px";
        this.seamlessOption.limitMoveNum = 12;
      } else {
        h = "650px";
        this.seamlessOption.limitMoveNum = 13;
      }
      return h;
    },
  },
  methods: {
    // 年份时间选择
    yearChange(val) {
      let vals = val.replace("年", "");
      if (vals == this.nowYear) {
        if (parseInt(this.nowMonth) < parseInt(this.monthChoose)) {
          this.$message({
            message: "选择时间超限啦！",
            type: "warning",
            showClose: true,
            center: true,
          });
          return;
        }
      }
      let thisMonth = this.monthChoose;
      if (thisMonth < 10) {
        thisMonth = `0${thisMonth}`;
      }
      this.thisTime = `${vals}-${thisMonth}`;
      this.makeInit();
    },
    // 月份时间选择
    chooseMonth(ind) {
      console.log(this.nowMonth, ind);
      if (this.monthChoose == ind) return;
      // 判断选中的时间是否超出了现在
      if (this.yearChoose.replace("年", "") == this.nowYear) {
        if (parseInt(this.nowMonth) < ind) {
          this.$message({
            message: "选择时间超限啦！",
            type: "warning",
            showClose: true,
            center: true,
          });
          return;
        }
      }

      this.monthChoose = ind;
      let thisMonth = ind;
      if (ind < 10) {
        thisMonth = `0${ind}`;
      }
      this.thisTime = `${this.yearChoose.replace("年", "")}-${thisMonth}`;

      // 折线图上面的时间赋值
      // this.qxYear = `${this.qxYear}-${thisMonth}`
      // this.sqYear = `${this.sqYear}-${thisMonth}`
      // this.cqYear = `${this.cqYear}-${thisMonth}`

      this.makeInit();
    },

    // 月份选择以后，数据重新请求
    makeInit() {
      // 最左侧采集数据
      // 气象、虫情、墒情一起的本月采集数据
      this.getAllData();
      // 虫情虫子类型数据
      // this.getCqTypes()
      // 业务数据
      this.getYwData(this.thisTime);
      this.getYwJc();

      // 请求处理记录数据
      if (this.tableTab == "0") {
        this.getQxRecord();
      } else if (this.tableTab == "1") {
        this.getSqRecord();
      } else if (this.tableTab == "2") {
        this.getCqRecord();
      } else if (this.tableTab == "3") {
        // 请求业务记录
        this.getYwRecord();
      }
      // 右侧数据
      // 气象折线图显示
      if (!this.qxThis) {
        this.$nextTick(() => {
          this.getQxChartData();
        });
      } else {
        this.getQxJc();
      }
      // 墒情折线图显示
      if (!this.sqThis) {
        this.$nextTick(() => {
          this.getSqChartData();
        });
      } else {
        this.getSqJc();
      }
      // 虫情折线图显示
      if (!this.cqThis) {
        this.$nextTick(() => {
          this.getCqChartData();
        });
      } else {
        this.getCqJc();
      }
    },
    // 气象年份选择
    qxSelectYear() {
      // 请求气象折线图数据
      this.$nextTick(() => {
        this.getQxChartData();
      });
    },
    // 气象下拉选选择
    qxItemSelect(val) {
      let qxIndex = this.qxItem.findIndex((item) => {
        return item.itemCode == val;
      });
      this.qxUnit = this.qxItem[qxIndex]["itemUnitCode"];
      this.qxName = this.qxItem[qxIndex]["itemName"];
      console.log(this.qxUnit);
      this.$nextTick(() => {
        this.getQxChartData();
      });
    },
    // 墒情年份选择
    sqSelectYear() {
      // 请求气象折线图数据
      this.$nextTick(() => {
        this.getSqChartData();
      });
    },
    // 墒情下拉选选择
    sqItemSelect(val) {
      let sqIndex = this.sqItem.findIndex((item) => {
        return item.itemCode == val;
      });
      this.sqUnit = this.sqItem[sqIndex]["itemUnitCode"];
      this.sqName = this.sqItem[sqIndex]["itemName"];
      // 赋值单位
      this.$nextTick(() => {
        this.getSqChartData();
      });
    },
    // 虫情年份选择
    cqSelectYear(val) {
      this.cqYear = val;
      // 请求气象折线图数据
      this.$nextTick(() => {
        this.getCqChartData();
      });
    },
    // 获取气象\虫情\墒情数据采集
    getAllData() {
      let params = {
        ym: this.thisTime,
        orgId: this.orgId,
      };
      this.$http.https(this.$api.allData, "get", params, (res) => {
        console.log(res,'=======>>>>>>>>>>');
        if (res.meta && res.meta.success) {
          let datas = res.data || [];
          datas.forEach((item, index) => {
            if (item.name.indexOf("气象") > -1) {
              this.leftDatas[0]["num"] = item.num;
            } else if (item.name.indexOf("土壤") > -1) {
              this.leftDatas[2]["num"] = item.num;
            } else if (item.name.indexOf("虫情") > -1) {
              // this.leftDatas[1]['num'] = item.num
              // TODO 死数据，判断月份
              if (this.thisTime == "2022-07") {
                this.leftDatas[1]["num"] = "47";
              } else if (this.thisTime == "2022-06") {
                this.leftDatas[1]["num"] = "165";
              } else if (this.thisTime == "2022-05") {
                this.leftDatas[1]["num"] = "182";
              } else if (this.thisTime == "2022-04") {
                this.leftDatas[1]["num"] = "170";
              } else {
                this.leftDatas[1]["num"] = 0;
              }
              // if (item.num > 0) {
              // 	this.getCqTypes()
              // } else {
              // 	this.cqLeftTypes = []
              // }
              // this.leftDatas[1]['num'] = 0
              // this.cqLeftTypes = []
            }
          });
          console.log(this.leftDatas,'====')
        } else {
          this.leftDatas[0]["num"] = 0;
          this.leftDatas[2]["num"] = 0;
          this.leftDatas[1]["num"] = 0;
          this.cqLeftTypes = [];
        }
      });
    },
    // 获取气象\虫情\墒情数据总采集
    getMonAllData() {
      let params = {
        orgId: this.orgId,
      };
      this.$http.https(this.$api.monthAllData, "get", params, (res) => {
        if (res.meta && res.meta.success) {
          this.monAllData = res.data || 0;
        } else {
          this.monAllData = 0;
        }
      });
    },
    // 获取虫情类型的数据采集
    getCqTypes() {
      this.$http.https(this.$api.cqTypes, "get", "", (res) => {
        if (res.meta && res.meta.success) {
          let datas = res.data || [];
          this.cqLeftTypes = datas.slice(0, 5) || [];
        } else {
          this.cqLeftTypes = [];
        }
      });
    },
    // 获取业务数据采集
    async getYwData(times) {
      let params = {
        year: times,
      };
      const { data: res } = await this.$http.post(
        this.$api.businessData,
        params
      );
      if (res.code == 0) {
        if (times && times != "") {
          this.leftDatas[3]["num"] = res.data;
        } else {
          this.ywAllData = res.data;
        }
      }
    },

    // 处理记录tab点击切换事件
    changeRecord({ val }) {
      if (this.isCqImg) {
        this.isCqImg = false;
        this.cqImgs = [];
        this.cqChoose = {};
        // this.qxThis = true
        // this.sqThis = true
        // this.cqThis = true
        if (!this.qxThis) {
          // 请求折线图数据
          this.$nextTick(() => {
            this.getQxChartData();
          });
        } else {
          this.getQxJc();
        }

        if (!this.sqThis) {
          // 请求折线图数据
          this.$nextTick(() => {
            this.getSqChartData();
          });
        } else {
          this.getSqJc();
        }

        if (!this.cqThis) {
          // 请求折线图数据
          this.$nextTick(() => {
            this.getCqChartData();
          });
        } else {
          this.getCqJc();
        }
      }

      if (this.tableTab == val) return;
      this.tableTab = val;
      // 请求对应的数据
      if (val == 0) {
        // 请求气象数据
        this.getQxRecord();
      } else if (val == 1) {
        // 请求墒情数据
        this.getSqRecord();
      } else if (val == 2) {
        // 请求虫情数据
        this.getCqRecord();
      } else if (val == 3) {
        // 请求业务数据
        this.getYwRecord();
      }
    },
    // 获取气象记录数据
    getQxRecord() {
      let params = {
        ym: this.thisTime,
        pageNum: 1,
        pageSize: 200,
      };
      this.$http.https(this.$api.qxRecord, "get", params, (res) => {
        if (res.meta && res.meta.success) {
          let datas = res.data.records || [];
          this.tableData = datas || [];
        } else {
          this.tableData = [];
        }
      });
    },
    // 请求墒情记录数据
    getSqRecord() {
      let params = {
        ym: this.thisTime,
        pageNum: 1,
        pageSize: 200,
      };
      this.$http.https(this.$api.sqRecord, "get", params, (res) => {
        if (res.meta && res.meta.success) {
          let datas = res.data.records || [];
          this.tableData = datas || [];
        } else {
          this.tableData = [];
        }
      });
    },
    // 请求虫情记录数据
    getCqRecord() {
      let params = {
        ym: this.thisTime,
        orgId: this.orgId,
        pageNum: 1,
        pageSize: 200,
      };
      this.$http.https(this.$api.cqRecord, "get", params, (res) => {
        if (res.meta && res.meta.success) {
          let datas = res.data.records || [];
          this.tableData = datas || [];
        } else {
          this.tableData = [];
        }
      });
    },
    // 虫情图片文字点击显示图片-代理点击配置
    handleSectionClick(e) {
      let val = e.target.dataset.val;
      if (val && val != "") {
        // 请求或者获取对应的图片
        this.cqChoose = JSON.parse(val);
        this.isCqImg = true;
        this.cqImgs = this.cqChoose.blastPictures;
      }
    },
    // 虫情弹窗点击返回
    cqBack() {
      this.isCqImg = false;
      this.cqImgs = [];
      this.cqChoose = {};
      if (!this.qxThis) {
        // 请求折线图数据
        this.$nextTick(() => {
          this.getQxChartData();
        });
      } else {
        this.getQxJc();
      }

      if (!this.sqThis) {
        // 请求折线图数据
        this.$nextTick(() => {
          this.getSqChartData();
        });
      } else {
        this.getSqJc();
      }

      if (!this.cqThis) {
        // 请求折线图数据
        this.$nextTick(() => {
          this.getCqChartData();
        });
      } else {
        this.getCqJc();
      }
      // TODO
      // 判断折线图是否显示，重新请求折线图数据
    },
    // 获取业务数据记录
    async getYwRecord() {
      let params = {
        year: this.thisTime,
        page: 1,
        limit: 200,
      };
      const { data: res } = await this.$http.post(
        this.$api.dataAcquisition,
        params
      );

      if (res.code == 0) {
        let datas = res.data || [];
        if (datas && datas.length) {
          this.tableData = datas || [];
        } else {
          this.tableData = [];
        }
      } else {
        this.tableData = [];
      }
    },
    // 获取气象监测数据
    getQxJc() {
      let params = {
        ym: this.thisTime,
      };
      this.$http.https(this.$api.qxMonth, "get", params, (res) => {
        if (res.meta && res.meta.success) {
          let datas = res.data || [];
          if (datas && datas.length) {
            this.qxJcData = datas || [];
          } else {
            this.qxJcData = [];
          }
          // this.ywJianyi = res.data.name || '暂无'
        } else {
          this.qxJcData = [];
        }
      });
    },
    // 获取墒情监测数据
    getSqJc() {
      let params = {
        ym: this.thisTime,
      };
      this.$http.https(this.$api.sqMonth, "get", params, (res) => {
        if (res.meta && res.meta.success) {
          let datas = res.data || [];
          if (datas && datas.length) {
            this.sqJcData = datas || [];
          } else {
            this.sqJcData = [];
          }
          // this.ywJianyi = res.data.name || '暂无'
        } else {
          this.sqJcData = [];
        }
      });
    },
    // 获取虫情监测数据
    getCqJc() {
      let params = {
        ym: this.thisTime,
        orgId: this.orgId,
      };
      // this.$http.https(this.$api.cqMonth, 'get', params, (res) => {
      // 	if (res.meta && res.meta.success) {
      // 		let datas = res.data || []
      // 		if (datas && datas.length) {
      // 			this.cqJcData = datas || []
      // 		} else {
      // 			this.cqJcData = []
      // 		}
      // 		// this.ywJianyi = res.data.name || '暂无'
      // 	} else {
      // this.cqJcData = [
      // 	{
      // 		last: '0',
      // 		name: '昆虫种类数量',
      // 		unit: '种',
      // 		value: '0',
      // 	},
      // ]
      let vals = 0;
      let lase = 0;
      if (this.thisTime == "2022-07") {
        vals = "47";
        lase = "165";
      } else if (this.thisTime == "2022-06") {
        vals = "165";
        lase = "182";
      } else if (this.thisTime == "2022-05") {
        vals = "182";
        lase = "170";
      } else if (this.thisTime == "2022-04") {
        vals = "170";
        lase = "0";
      } else {
        vals = 110;
        lase = 120;
      }
      this.cqJcData = [
        {
          last: lase,
          name: "虫情采集数量",
          unit: "条",
          value: vals,
        },
      ];
      // }
      // })
    },
    // 获取业务系统监测数据
    async getYwJc() {
      let params = {
        year: this.thisTime,
        param: "0",
      };
      const { data: res } = await this.$http.post(
        this.$api.businessMonitoring,
        params
      );
      if (res.code == 0) {
        let datas = res.data.data || [];
        if (datas && datas.length) {
          this.ywJcData = datas || [];
        } else {
          this.ywJcData = [];
        }
        this.ywJianyi = res.data.name || "暂无";
      } else {
        this.ywJcData = [];
        // this.ywJianyi = '暂无'
      }
    },
    // 气象显示与隐藏
    qxTabs(isThis) {
      if (this.qxThis == isThis) return;
      this.qxThis = isThis;
      if (!this.qxThis) {
        // 判断下拉选是否有值
        if (this.qxItem.length) {
          // 请求折线图数据
          this.$nextTick(() => {
            this.getQxChartData();
          });
        } else {
          // 请求下拉选数据
          this.getQxItems();
        }
      } else {
        this.getQxJc();
      }
    },
    // 获取气象下拉选数据
    getQxItems() {
      this.$http.https(this.$api.qxItems, "get", "", (res) => {
        if (res.meta && res.meta.success) {
          let datas = res.data || [];
          if (datas && datas.length) {
            let qxItem = [];
            datas.forEach((item) => {
              let baseDataUnit = item.baseDataColumnDes.baseDataUnit;
              qxItem.push({
                itemCode: item.acqItem,
                itemName: item.baseDataColumnDes.colName,
                itemUnitCode: baseDataUnit.unitCode,
              });
            });
            this.qxItem = qxItem;
            this.qxName = qxItem[0]["itemName"];
            this.qxItemActive = qxItem[0]["itemCode"];
            this.qxUnit = qxItem[0]["itemUnitCode"];
            this.$nextTick(() => {
              this.getQxChartData();
            });
          } else {
            this.qxItem = [];
          }
        } else {
        }
      });
    },
    // 气象折线图数据获取
    getQxChartData() {
      let _this = this;
      let params = {
        ym: this.thisTime,
        acquisitionItem: this.qxItemActive,
        yyyy: this.qxYear,
      };
      this.$http.https(
        this.$api.qxHis,
        "get",
        params,
        (res) => {
          if (res.meta && res.meta.success) {
            this.isQxLine = true;
            let datas = res.data || {};
            let xAxis = datas.xAxis || [];
            let series = datas.series || [];
            this.$nextTick(() => {
              this.getQxEcharts(xAxis, series);
            });
          } else {
            this.isQxLine = false;
          }
        },
        false,
        true,
        true,
        () => {
          this.isQxLine = false;
        }
      );
    },
    // 气象折线图
    getQxEcharts(xAxis, seriesAll) {
      let myChart = this.$echarts.init(this.$refs.qxecharts);
      let xData = xAxis;
      let options = this.makeOptions(
        xData,
        seriesAll,
        this.qxUnit,
        this.qxName,
        true
      );
      myChart.clear();
      myChart.setOption(options);
      this.qxEchartTime && this.qxEchartTime.clearLoop();
      this.qxEchartTime = 0;
      this.qxEchartTime = loopShowTooltip(myChart, options, {
        interval: 3000, // 轮播间隔时间
        loopSeries: true, // 是否开启轮播循环
        dataLength: xAxis.length,
      });
    },
    // 墒情切换
    sqTabs(isThis) {
      if (this.sqThis == isThis) return;
      this.sqThis = isThis;
      if (!this.sqThis) {
        // 判断下拉选是否有值
        if (this.sqItem.length) {
          // 请求折线图数据
          this.$nextTick(() => {
            this.getSqChartData();
          });
        } else {
          // 请求下拉选数据
          this.getSqItems();
        }
      } else {
        this.getSqJc();
      }
    },
    // 获取墒情下拉选数据
    getSqItems() {
      this.$http.https(this.$api.sqItems, "get", "", (res) => {
        if (res.meta && res.meta.success) {
          let datas = res.data || [];
          if (datas && datas.length) {
            let sqItem = [];
            datas.forEach((item) => {
              let baseDataUnit = item.baseDataColumnDes.baseDataUnit;
              sqItem.push({
                itemCode: item.acqItem,
                itemName: item.baseDataColumnDes.colName,
                itemUnitCode: baseDataUnit.unitCode,
              });
            });
            this.sqItem = sqItem;
            this.sqName = sqItem[0]["itemName"];
            this.sqItemActive = sqItem[0]["itemCode"];
            this.sqUnit = sqItem[0]["itemUnitCode"];
            this.$nextTick(() => {
              this.getSqChartData();
            });
          } else {
            this.sqItem = [];
          }
        } else {
        }
      });
    },
    getSqChartData() {
      let _this = this;
      let params = {
        ym: this.thisTime,
        acquisitionItem: this.sqItemActive,
        yyyy: this.sqYear,
      };
      this.$http.https(
        this.$api.sqHis,
        "get",
        params,
        (res) => {
          if (res.meta && res.meta.success) {
            this.isSqLine = true;
            let datas = res.data || {};
            let xAxis = datas.xAxis || [];
            let series = datas.series || [];
            this.$nextTick(() => {
              this.getSqEcharts(xAxis, series);
            });
          } else {
            this.isSqLine = false;
          }
        },
        false,
        true,
        true,
        () => {
          this.isSqLine = false;
        }
      );
    },
    // 墒情折线图
    getSqEcharts(xAxis, series) {
      let myChart = this.$echarts.init(this.$refs.sqecharts);
      let xData = xAxis;
      let options = this.makeOptions(
        xData,
        series,
        this.sqUnit,
        this.sqName,
        true
      );
      myChart.clear();
      myChart.setOption(options);
      this.sqEchartTime && this.sqEchartTime.clearLoop();
      this.sqEchartTime = 0;
      this.sqEchartTime = loopShowTooltip(myChart, options, {
        interval: 3000, // 轮播间隔时间
        loopSeries: true, // 是否开启轮播循环
        dataLength: xAxis.length,
      });
    },
    // 虫情切换
    cqTabs(isThis) {
      console.log(isThis);
      if (this.cqThis == isThis) return;
      this.cqThis = isThis;
      this.cqYear = this.cqYear;
      if (!this.cqThis) {
        // 请求折线图数据
        this.$nextTick(() => {
          this.getCqChartData();
        });
      } else {
        this.getCqJc();
      }
    },
    getCqChartData() {
      let _this = this;
      let params = {
        ym: this.thisTime,
        orgId: this.orgId,
        yyyy: this.cqYear,
      };
      this.$http.https(
        this.$api.cqHis,
        "get",
        params,
        (res) => {
          if (res.meta && res.meta.success) {
            this.isCqLine = true;
            let datas = res.data || {};
            let xAxis = datas.xAxis || [];
            let series = datas.series || [];
            this.$nextTick(() => {
              this.getCqEcharts(xAxis, series);
            });
          } else {
            this.isCqLine = false;
          }
        },
        false,
        true,
        true,
        () => {
          this.isCqLine = false;
        }
      );
    },
    // 虫情折线图
    getCqEcharts(xAxis, seriesAll) {
      let myChart = this.$echarts.init(this.$refs.cqecharts);
      let options = this.makeCqOptions(
        xAxis,
        seriesAll,
        "只",
        "虫只数量",
        false
      );
      myChart.clear();
      myChart.setOption(options);
      window.addEventListener("resize", function () {
        myChart.resize();
      });

      this.cqEchartTime && this.cqEchartTime.clearLoop();
      this.cqEchartTime = 0;
      this.cqEchartTime = loopShowTooltip(myChart, options, {
        interval: 3000, // 轮播间隔时间
        loopSeries: true, // 是否开启轮播循环
        dataLength: xAxis.length,
      });
    },
    // 设置折线图的echarts
    makeCqOptions(xData, series, unit, name, isFixed) {
      let isFix = isFixed ? isFixed : false;
      let zeroData = [];
      for (let k = 0; k < 31; k++) {
        zeroData.push(String(0));
      }
      console.log(zeroData);
      console.log(zeroData.slice(0, xData.length));
      let _this = this;
      // let xData = []
      let seriesAll = [];
      let colors = {
        本月: "#09FFE6",
        同期: "#45A6FF",
      };
      series.forEach((item) => {
        seriesAll.push({
          name: item.name,
          type: "line",
          // smooth: true, //是否平滑
          showAllSymbol: true,
          symbol: "circle",
          symbolSize: 6,
          lineStyle: {
            normal: {
              color: colors[item.name],
            },
          },
          itemStyle: {
            color: "#031236",
            borderColor: colors[item.name],
            borderWidth: 2,
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: _this.convertHexToRGB(colors[item.name], 0.5),
                  },
                  {
                    offset: 1,
                    color: _this.convertHexToRGB(colors[item.name], 0.01),
                  },
                ],
                false
              ),
            },
          },
          data: zeroData.slice(0, xData.length),
        });
      });
      // for (let i = 1; i < 7; i++) {
      // 	xData.push(i + '号')
      // }
      let options = {
        // backgroundColor: '#080b30',
        title: {
          show: false,
          textStyle: {
            align: "center",
            color: "#fff",
            fontSize: 14,
          },
        },
        legend: {
          align: "left",
          top: "5%",
          right: "5%",
          textStyle: {
            color: "#7ec7ff",
            fontSize: 12,
          },
        },

        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(113, 200, 255, 0.28)",
          borderWidth: 0,
          textStyle: {
            color: "#FFFFFF",
            fontSize: 15,
          },
          formatter(params) {
            let result = "";
            if (params.length > 0) {
              result += `${name}`;
              params.forEach((item) => {
                let vals = item.value;
                if (isFix) {
                  if (item.value.indexOf(".") > -1) {
                    vals = Number(item.value).toFixed(1);
                  } else {
                    vals = item.value;
                  }
                } else {
                  vals = item.value;
                }
                result += `<div style="font-size:15px;margin-top:5px;">${item.seriesName}${item.axisValue}：${vals}${unit}</div>`;
              });
            }
            return result;
          },
        },
        grid: {
          top: "35%",
          left: "10%",
          right: "5%",
          bottom: "16%",
          // containLabel: true
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(0, 255, 229, 0.5)",
              },
            },
            splitArea: {
              show: false,
            },
            axisLabel: {
              color: "#CFEDFF",
              fontSize: 12,
              margin: 10,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            boundaryGap: false,
            data: xData,
          },
        ],

        yAxis: [
          {
            type: "value",
            // max: 140,
            name: "(单位:" + unit + ")",
            nameTextStyle: {
              color: "#CFEDFF",
              fontSize: 12,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(0, 134, 196, 0.5)",
                type: "dashed",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#CFEDFF",
                fontSize: 12,
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: seriesAll,
      };
      return options;
    },
    // 设置折线图的echarts
    makeOptions(xData, series, unit, name, isFixed) {
      let isFix = isFixed ? isFixed : false;
      let _this = this;
      // let xData = []
      let seriesAll = [];
      let colors = {
        本月: "#09FFE6",
        同期: "#45A6FF",
      };
      series.forEach((item) => {
        seriesAll.push({
          name: item.name,
          type: "line",
          // smooth: true, //是否平滑
          showAllSymbol: true,
          symbol: "circle",
          symbolSize: 6,
          lineStyle: {
            normal: {
              color: colors[item.name],
            },
          },
          itemStyle: {
            color: "#031236",
            borderColor: colors[item.name],
            borderWidth: 2,
          },
          areaStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: _this.convertHexToRGB(colors[item.name], 0.5),
                  },
                  {
                    offset: 1,
                    color: _this.convertHexToRGB(colors[item.name], 0.01),
                  },
                ],
                false
              ),
            },
          },
          data: item.data,
        });
      });
      // for (let i = 1; i < 7; i++) {
      // 	xData.push(i + '号')
      // }
      let options = {
        // backgroundColor: '#080b30',
        title: {
          show: false,
          textStyle: {
            align: "center",
            color: "#fff",
            fontSize: 14,
          },
        },
        legend: {
          align: "left",
          top: "5%",
          right: "5%",
          textStyle: {
            color: "#7ec7ff",
            fontSize: 12,
          },
        },

        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(113, 200, 255, 0.28)",
          borderWidth: 0,
          textStyle: {
            color: "#FFFFFF",
            fontSize: 15,
          },
          formatter(params) {
            let result = "";
            if (params.length > 0) {
              result += `${name}`;
              params.forEach((item) => {
                let vals = item.value;
                if (isFix) {
                  if (item.value.indexOf(".") > -1) {
                    vals = Number(item.value).toFixed(1);
                  } else {
                    vals = item.value;
                  }
                } else {
                  vals = item.value;
                }
                result += `<div style="font-size:15px;margin-top:5px;">${item.seriesName}${item.axisValue}：${vals}${unit}</div>`;
              });
            }
            return result;
          },
        },
        grid: {
          top: "35%",
          left: "10%",
          right: "5%",
          bottom: "16%",
          // containLabel: true
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(0, 255, 229, 0.5)",
              },
            },
            splitArea: {
              show: false,
            },
            axisLabel: {
              color: "#CFEDFF",
              fontSize: 12,
              margin: 10,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            boundaryGap: false,
            data: xData,
          },
        ],

        yAxis: [
          {
            type: "value",
            // max: 140,
            name: "(单位:" + unit + ")",
            nameTextStyle: {
              color: "#CFEDFF",
              fontSize: 12,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(0, 134, 196, 0.5)",
                type: "dashed",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#CFEDFF",
                fontSize: 12,
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: seriesAll,
      };
      return options;
    },
  },
};
</script>
<style lang="less" scoped>
@bgs: 100% 100%;
.zncenter {
  margin-left: 50px;
  .emptydata {
    height: 120px;
    display: block;
    margin: auto;
  }
  .lefts {
    margin-right: -80px;
    margin-top: -40px;
    width: 783px;
    position: relative;
    .yuans {
      width: 186px;
      height: 186px;
      background: url("../assets/img/brains/yuan_bg.png") no-repeat;
      background-size: @bgs;
      position: relative;
      &:nth-child(1) {
        margin-left: 386px;
      }
      &:nth-child(2) {
        margin-top: -25px;
      }
      &:nth-child(3) {
        margin-top: 92px;
      }
      &:nth-child(4) {
        margin-left: 386px;
      }
      .yuan-zhuan {
        position: absolute;
        width: 434px;
        height: 252px;
        // background: url('../assets/img/brains/yuan_zhuan.png') no-repeat;
        // background-size: @bgs;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        // .kkk {
        // 	width: 47px;
        // 	height: 47px;
        // 	background: url('../assets/img/brains/yuans_bg.png') no-repeat;
        // 	background-size: @bgs;
        // 	position: absolute;

        // 	// animation: mymove 6s infinite;
        // }
        // 虫情定位
        .yuan-cq {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          .yuan-cqspan {
            width: 88px;
            text-align: center;
            position: absolute;
            font-size: 13px;
          }
        }
        .yuan-title {
          width: 122px;
          height: 38px;
          background: url("../assets/img/brains/yuant_bg.png") no-repeat;
          background-size: @bgs;
          line-height: 38px;
          text-align: center;
          font-size: 16px;
        }
        .yuan-nums {
          font-size: 12px;
        }
      }
      .yuan-zhuan1 {
        background: url("../assets/img/brains/1.png") no-repeat;
        background-size: @bgs;
      }
      .yuan-zhuan2 {
        background: url("../assets/img/brains/22.png") no-repeat;
        background-size: auto 100%;
        width: 310px;
        height: 233px;
        .yuan-cqspan1 {
          top: -20px;
          left: 17%;
        }
        .yuan-cqspan2 {
          top: 33%;
          left: 0%;
        }
        .yuan-cqspan3 {
          bottom: 20%;
          left: 15%;
        }
        .yuan-cqspan4 {
          bottom: 16px;
          right: 13%;
        }
        .yuan-cqspan5 {
          top: 81px;
          right: 0;
        }
      }
      .yuan-zhuan3 {
        background: url("../assets/img/brains/3.png") no-repeat;
        background-size: @bgs;
      }
      .yuan-zhuan4 {
        background: url("../assets/img/brains/4.png") no-repeat;
        background-size: @bgs;
      }
    }
    .yuan-jian {
      position: absolute;
      width: 700px;
      height: 700px;
      z-index: 3;
    }
    .yuano-jo {
      // width: 329px;
      // height: 226px;
      width: 800px;
      height: 800px;
      top: -42px;
      left: 210px;
    }
    .yuano-jt {
      width: 780px;
      height: 780px;
      top: -26px;
      left: 166px;
    }
    .yuano-je {
      width: 750px;
      height: 750px;
      top: 135px;
      left: 201px;
    }
    .yuano-jf {
      top: 221px;
      left: 325px;
    }
    .left-jian {
      width: 78px;
      margin-left: 213px;
    }
    .xuxian {
      position: absolute;
      width: 477px;
      height: 428px;
      background: url("../assets/img/brains/xuxian.png") no-repeat;
      background-size: @bgs;
      left: 256px;
      top: 215px;
      .icon_jian1 {
        transform: rotate(50deg);
        animation: jianoX 4s linear -2s infinite,
          jianoY 4s cubic-bezier(0.43, 0.6, 0.69, 0.87) 2s infinite;
      }
      .icon_jian2 {
        transform: rotate(4deg);
        animation: jianwX 4s linear -2s infinite,
          jianwY 4s cubic-bezier(0.34, 0.395, 0.65, 0.725) 2s infinite;
      }
      .icon_jian3 {
        transform: rotate(-20deg);
        animation: jianeX 4s linear -2s infinite,
          jianeY 4s cubic-bezier(0.3, 0, 1, 0.75) 2s infinite;
      }
      .icon_jian4 {
        // bottom: 14px;
        // left: 52%;
        transform: rotate(-53deg);
        animation: jianuX 4s linear -2s infinite,
          jianuY 4s cubic-bezier(0.25, 0.25, 0.8, 0.74) 2s infinite;
      }
    }

    .icon_jian {
      position: absolute;
    }
  }
  .centers {
    .titles {
      width: 765px;
      height: 72px;
      background: url("../assets/img/brains/title_bg.png") no-repeat;
      background-size: @bgs;
    }
    .title-span {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;

      background: linear-gradient(0deg, #59c8ff 0%, #fafeff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-right: 25px;
    }
    .num-span {
      width: 30px;
      height: 36px;
      background: url("../assets/img/brains/num_bg.png") no-repeat;
      background-size: @bgs;
      font-family: 054-SSShaoNian Ti;
      font-size: 32px;
      font-weight: bold;
      color: #ffffff;
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
    .c-imgs {
      width: 765px;
      height: 645px;
      position: relative;
      .left-jian-one {
        position: absolute;
        top: 297px;
        left: 31px;
      }
      .left-jian-teo {
        position: absolute;
        top: 297px;
        right: 13px;
      }
    }
    .times {
      height: 56px;
      position: relative;
      z-index: 5;
      .times-year {
        width: 129px;
        height: 56px;
        .censelect {
          .el-input {
            width: 100%;
            height: 100%;
            background: url("../assets/img/brains/select_year.png") no-repeat;
            background-size: @bgs;
          }
        }
      }
      .times-month {
        width: 538px;
        height: 44px;
        background: url("../assets/img/brains/select_day.png") no-repeat;
        background-size: @bgs;
        padding: 0 10px;
        .month-span {
          width: 8.33%;
          text-align: center;
          height: 100%;
          // line-height: 40px;
          cursor: pointer;
          position: relative;
        }
        .t_yuan {
          width: 140%;
          height: 140%;
          position: absolute;
          left: -20%;
          top: -20%;
        }
        .month-spans {
          width: 76%;
          height: 76%;
          margin: 12% auto;
          line-height: 36px;
          text-align: center;
        }
        .month-span-active {
          // border: 1px solid #f6cb96;
          border-radius: 50%;
          background-color: #001223;
          // box-shadow: 0 0 10px 2px #f6cb96;
        }
      }
    }
  }

  .nav {
    margin-left: 43px;
    color: aliceblue;
    color: aliceblue;
    .deal {
      width: 525px;
      height: 772px;
      background: url("../assets/img/HomeBrain/Kbgc.png") no-repeat;
      box-sizing: border-box;
      .zu {
        margin-left: 34px;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        font-size: 24px;
        color: #00e2fb;
        margin-top: 17px;
        margin-left: 10px;
      }
      .record-title {
        margin-top: 0;
      }

      .tap1 {
        padding: 0;
        width: 84px;
        height: 32px;
        text-align: center;
        line-height: 28px;
        border-radius: 13%;
        color: #cfedff;
        margin: 0;
        font-size: 14px;
        background: url("../assets/img/HomeBrain/tap2.png") no-repeat;
        background-size: @bgs;
        cursor: pointer;

        &:first-child {
          margin-left: 20px !important;
        }
      }
      .tap-active {
        background: url("../assets/img/HomeBrain/tab1.png") no-repeat;
        color: #00e2fb;
        background-size: 100% 100%;
      }

      .table-tab {
        position: relative;
        width: 472px;
        height: 36px;
        margin-top: 14px;
        margin-left: 26px;
        background: url("../assets/img/HomeBrain/zu2414.png") no-repeat;
        background-size: @bgs;
      }
      .table-span {
        font-size: 14px;
        color: #fff;
        text-align: center;
        line-height: 36px;
      }
      .table-types {
        width: 30%;
      }
      .table-nums {
        width: 40%;
      }
      .table-times {
        width: 30%;
      }
      .max {
        width: 100%;
        margin-top: 10px;
        .seamtable {
          width: 100%;
          // height: 650px;
          overflow: hidden;
        }
        ul {
          margin-left: 26px;
          overflow: hidden;
          // animation-name: move;
          // animation-duration: 10s;
          // animation-timing-function: linear;
          // animation-iteration-count: infinite;
        }
        @keyframes move {
          from {
            transform: translate(0px, 0px);
          }
          to {
            transform: translate(0px, -36 * 10px);
          }
        }
        li {
          position: relative;
          width: 472px;
          height: 36px;
          background: url("../assets/img/HomeBrain/zu2414.png") no-repeat;
          margin-top: 14px;
          .table-nums {
            color: #00e2fb;
          }
        }
        ul:hover {
          animation-play-state: paused;
        }
      }
    }
    .right-jian {
      width: 65px;
      margin: 0 22px 0 42px;
    }
    .tablece-list {
      position: relative;
      width: 472px;
      height: 36px;
      background: url("../assets/img/brains/table_h.png") no-repeat;
      margin-top: 14px;
      margin-left: 26px;
      cursor: pointer;
      .table-nums {
        color: #00e2fb;
      }
      .table_n {
        width: 100%;
        font-size: 14px;
        color: #00e2fb;
        font-family: "Source Han Sans CN";
        line-height: 36px;
        text-align: center;
        letter-spacing: 0.5px;
      }
    }

    .advice {
      width: 425px;
      height: 920px;
      background: url("../assets/img/HomeBrain/bigkbgc.png") no-repeat;
      .zu {
        margin-top: -6px;
        margin-left: 25px;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        font-size: 20px;
        color: #00e2fb;
        margin-top: 17px;
        margin-left: 10px;
      }

      .tap {
        margin-left: 93px;
        padding: 0;
        width: 84px;
        height: 32px;
        font-size: 14px;
        text-align: center;
        color: #cfedff;
        line-height: 32px;
        cursor: pointer;
        background: url("../assets/img/HomeBrain/tap2.png") no-repeat;
      }
      .tap1 {
        margin: 0;
        padding: 0;
        width: 84px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        border-radius: 13%;
        color: #cfedff;
        font-size: 14px;
        cursor: pointer;
        background: url("../assets/img/HomeBrain/tap2.png") no-repeat;
      }
      .tap0 {
        color: #00e2fb;
        background: url("../assets/img/HomeBrain/tab1.png") no-repeat;
      }
      .selects {
        position: absolute;
        display: flex;
        z-index: 13;
      }
      .kuang1 {
        width: 98px;
        height: 32px;
        margin-top: 5px;
        margin-left: 22px;
      }
      .kuang2 {
        width: 114px;
        height: 32px;
        margin-top: 5px;

        margin-left: 5px;
      }
      .echarts-wrap {
        width: 100%;
        height: 203px;
        position: relative;
        z-index: 12;
      }
      .echarts {
        // margin-top:30px ;
        width: 100%;
        height: 203px;
        // background-color: #fff;
      }
      .shang {
        width: 100%;
        .zu {
          margin-top: -6x;
          margin-left: 25px;
          vertical-align: middle;
        }
        span {
          display: inline-block;
          font-size: 24px;
          color: #00e2fb;
          margin-top: 0px;
          margin-left: 10px;
        }
        .tap {
          cursor: pointer;
          margin-left: 93px;
          padding: 0;
          // width: 84px;
          // height: 32px;

          text-align: center;
          line-height: 26px;
          color: #cfedff;
          font-size: 14px;
          border-radius: 5px;

          width: 73px;
          height: 26px;
          border: 1px solid rgba(1, 118, 131, 0.6);
          background: linear-gradient(
            0deg,
            rgba(7, 39, 64, 0.5) 0%,
            rgba(1, 118, 131, 0.5) 100%
          );
          box-shadow: 0px 3px 6px 0px rgba(4, 0, 0, 0.45);
        }
        .tap1 {
          cursor: pointer;
          margin-left: 8px;
          padding: 0;
          // width: 84px;
          // height: 32px;

          text-align: center;
          line-height: 26px;
          color: #cfedff;
          font-size: 14px;

          width: 73px;
          height: 26px;
          border: 1px solid rgba(1, 118, 131, 0.6);
          background: linear-gradient(
            0deg,
            rgba(7, 39, 64, 0.5) 0%,
            rgba(1, 118, 131, 0.5) 100%
          );
          box-shadow: 0px 3px 6px 0px rgba(4, 0, 0, 0.45);
        }
        .tap0 {
          width: 73px;
          height: 26px;
          border: 1px solid rgba(1, 118, 131, 1);
          border-radius: 5px;

          background: linear-gradient(
            0deg,
            rgba(7, 39, 64, 0.9) 0%,
            rgba(1, 118, 131, 0.9) 100%
          );
          box-shadow: 0px 3px 6px 0px rgba(4, 0, 0, 0.45);
          color: #00e2fb;
        }
      }
    }
    .cqimgs-all {
      width: 425px;
      height: 920px;
    }
    .cqimgs {
      width: 425px;
      height: auto;
      background: url("../assets/img/HomeBrain/bigkbgc.png") no-repeat;
      background-size: @bgs;
      padding: 30px 20px;
      .cq-titles {
        margin-top: -7px;
      }
      .titles {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #00e2fb;
        margin-left: 15px;
      }
      .btn_back {
        width: 67px;
        height: 37px;
        line-height: 28px;
        background: url("../assets/img/brains/btn_back.png") no-repeat;
        background-size: @bgs;
        font-size: 16px;
        color: #a9c4d5;
      }
      .cqimg-wrap {
        max-height: 804px;
        overflow-y: scroll;
      }
      .cqimg-li {
        margin-top: 16px;
        width: 100%;
        height: 252px;
        background: url("../assets/img/brains/cq_bg.png") no-repeat;
        background-size: @bgs;
        position: relative;
        .cq-img {
          width: 376px;
          height: 242px;
          position: relative;
          z-index: 3;
          left: 5px;
          top: 6px;
        }
        .cqli-name {
          position: absolute;
          z-index: 4;
          left: 5px;
          bottom: 6px;
          width: 376px;
          padding: 9px 21px;
          background-color: rgba(0, 0, 0, 0.2);
          font-size: 14px;
          color: #fff;
        }
      }
      // 虫情占位图
      .cqimg-empty {
        width: 80%;
        display: block;
        margin: 16px auto 0;
      }
    }
  }

  @keyframes jianoX {
    0% {
      left: 29%;
      transform: rotate(50deg);
    }
    100% {
      left: 96%;
      transform: rotate(12deg);
    }
  }
  @keyframes jianoY {
    0% {
      top: 0px;
    }
    100% {
      top: 200px;
    }
  }
  @keyframes jianwX {
    0% {
      left: 0;
      // transform: rotate(20deg);
    }
    100% {
      left: 93%;
      // transform: rotate(9deg);
    }
  }
  @keyframes jianwY {
    0% {
      top: 103px;
    }
    100% {
      top: 201px;
    }
  }
  @keyframes jianeX {
    0% {
      left: 3%;
      transform: rotate(-20deg);
    }
    100% {
      left: 93%;
      transform: rotate(-42deg);
    }
  }
  @keyframes jianeY {
    0% {
      top: 284px;
    }
    100% {
      top: 213px;
    }
  }
  @keyframes jianuX {
    0% {
      left: 52%;
      transform: rotate(-53deg);
    }
    100% {
      left: 94%;
      transform: rotate(-53deg);
    }
  }
  @keyframes jianuY {
    0% {
      bottom: 14px;
    }
    100% {
      bottom: 181px;
    }
  }
}
</style>
<style lang="less">
.times-year {
  width: 129px;
  height: 56px;
  .censelect {
    width: 129px;
    height: 56px;
    .el-input__inner {
      width: 129px;
      height: 56px;
      background: url("../assets/img/brains/select_year.png") no-repeat;
      background-size: 100% 100%;
      background-color: transparent;
      border-width: 0;
      padding-left: 30px;
      font-size: 14px;
      color: #fff;
      padding-right: 50px;
    }
    .el-input__suffix {
      right: 20px;
    }
    .el-icon-arrow-up:before {
      content: "\e78f" !important;
      font-size: 22px;
      line-height: 1;
    }
    .el-input__icon:after {
      height: 114%;
    }
  }
}
.kuang1 {
  .el-input__inner {
    width: 98px;
    height: 32px;
    line-height: 32px;
    background-color: transparent;
    background: url("../assets/img/HomeBrain/kiang1.png") no-repeat !important;
    background-size: 100% 100%;
    border-width: 0;
    font-size: 14px;
    color: #fff;
  }
  .el-input__icon {
    line-height: 32px;
  }
}
.kuang2 {
  .el-input__inner {
    width: 114px;
    height: 32px;
    line-height: 32px;
    background: url("../assets/img/HomeBrain/kuang2.png") no-repeat !important;
    background-size: 100% 100%;
    border-width: 0;
    font-size: 14px;
    color: #fff;
  }
  .el-input__icon {
    line-height: 32px;
  }
}
.yearselect {
  .el-select-dropdown__item.hover {
    background-color: rgba(57, 204, 217, 0.2) !important;
  }
  .el-select-dropdown__item.selected {
    color: #fff !important;
    background-color: #15acd7 !important;
  }
}
</style>
