<template>
	<div id="smartstab">
		<div class="flex flex_a_c flex_ju_sb top">
			<span class="font_14 c_white">大豆生长模型1号</span>
			<span class="font_14 c_white">开始时间：2021/10/29 13:38:00</span>
		</div>
		<!-- 下面的介绍 -->
		<div class="flex flex_a_c wrap">
			<!-- 左边的图片 -->
			<div class="left">
				<img class="img_modal" src="@/assets/img/water/modal.gif" alt="" />
			</div>
			<!-- 右边的介绍 -->
			<div class="right">
				<!-- 三个图标 -->
				<div class="flex tabicons">
					<div v-for="(item, index) in tabIconList" :key="index" class="tabdiv t_c" @click="tabOpenDia(item)">
						<!-- 上面的图片 -->
						<div class="set-tab">
							<!-- 第二层间断线性背景 -->
							<img class="line-icon" :src="lineIcon(item.status)" alt="" />
							<!-- 第三层纯色背景 -->
							<div class="set-smallbg" :class="{ 'set-smallbg-y': item.status }"></div>
							<!-- 第四层不同的图标 -->
							<img class="icons" :src="item.status ? item.icony : item.iconn" alt="" />
						</div>
						<span class="c_white font_14">{{ item.name }}</span>
						<!-- 时间选择器 -->
						<div v-if="index == 1" @click="timeClick" class="starttime-change">
							<el-date-picker
								v-model="startTimeVal"
								type="datetime"
								:readonly="readonly"
								placeholder="选择日期时间"
								@change="timeChange"
								value-format="yyyy-MM-dd HH:mm:ss"
								popper-class="watertime"
							>
							</el-date-picker>
							<!-- <input id="pickers" type="text" style="display: none" /> -->
						</div>
					</div>
				</div>
				<!-- 介绍和防护 -->
				<div class="intro-div">
					<div class="intro">
						<div>
							<span class="font_14 intro-name">播种期：</span>
							<span class="c_white font_14">5天。播种前精选种子，做好发芽试 验，药剂伴种或种子包衣</span>
						</div>
						<div>
							<span class="font_14 intro-name">专家建议：</span>
							<span class="c_white font_14">大豆期注意大豆管水，防止高温干旱造成的空秕粒增多</span>
						</div>
						<div>
							<span class="font_14 intro-name">病虫害防治:</span>
							<span class="c_white font_14">1.稻飞虱，亩用10%吡虫啉粉剂 50克，或25%吡蚜酮粉剂30克。2.稻纵卷叶螟</span>
						</div>
					</div>
				</div>

				<!-- <img v-else src="@/assets/img/water/none.png" class="empty" alt="" /> -->
			</div>
		</div>
		<!-- 模型选择弹出层 -->
		<div class="smartdia" v-show="isShowDia">
			<!-- 关闭按钮 -->
			<img class="close" src="@/assets/img/water/dia_close.png" alt="" @click="closeDia" />
			<!-- 上面的模型选择 -->
			<div class="flex flex_a_c flex_ju_c selects">
				<!-- 选择 -->
				<div class="select-div">
					<el-select v-model="modalVal" @change="changeModal" popper-class="waterlog">
						<el-option
							v-for="(item, index) in modalList"
							:key="index"
							:label="item.kindName"
							:value="item.id"
						></el-option>
					</el-select>
				</div>
				<!-- 按钮 -->
				<div class="c_white t_c font_16 btn" @click="toUse">应用</div>
			</div>
			<!-- 下面的阶段详情，可滚动 -->
			<div class="stages-div">
				<div class="stages">
					<div class="stage-wrap">
						<div class="lines"></div>
						<div class="flex flex_ju_sb c_white stage-div" v-for="(item, index) in stageDetail" :key="index">
							<!-- 阶段 -->
							<div class="t_c font_18 stage-name">{{ item.stageName }}</div>
							<!-- 圆球 -->
							<img class="stage-yuan" src="@/assets/img/water/dia_yuan.png" alt="" />
							<!-- 内容 -->
							<div class="font_14 stage-detail">{{ item.stageDay }}天。{{ item.stageContent }}</div>
						</div>
					</div>

					<!-- <div class="time-line"></div> -->
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// 智能模式切换模块
export default {
	data() {
		return {
			readonly: true,
			tabIconList: [
				{
					iconn: require('@/assets/img/water/smart_iconmx.png'),
					icony: require('@/assets/img/water/smart_iconmx.png'),
					name: '模型选择',
					status: 1,
					isDia: true,
					type: 'dia',
				},
				{
					iconn: require('@/assets/img/water/smart_iconplay.png'),
					icony: require('@/assets/img/water/smart_iconplay.png'),
					name: '开始时间',
					status: 1,
					type: 'time',
				},
				{
					iconn: require('@/assets/img/water/icon_setn2.png'),
					icony: require('@/assets/img/water/icon_sety2.png'),
					name: '枪尾联动',
					status: 0,
					type: 'set',
				},
			],
			// 目前阶段的介绍
			introWord: {},
			isShowDia: false, // 弹出层是否显示
			// 可选择模型列表
			modalList: [],
			// 选中的模型id
			modalVal: '',
			kindName: '', // 选中模型的name
			startTime: '',
			startTimeVal: '',
			// 阶段详情
			stageDetail: [],
			isStop: '0', // 尾枪是否启动
			changeId: '', // 修改时传递的id值
			isEmpty: true, // 是否是空数据
			isHadTime: false, // 是否存在开始时间，开始时间为空的情况下显示暂无数据
		}
	},
	props: ['equipId'],
	watch: {
		equipId: {
			handler(newVal) {
				// 获取列表
				this.getList()
			},
		},
	},
	mounted() {
		// 获取列表
		this.getList()
	},
	methods: {
		async getList() {
			const { data: res } = await this.$http.get('/apis' + this.$api.list)
			if (res.code == 0) {
				let datas = res.data || []
				// 赋值模型列表
				this.modalList = datas
				// 判断是否有已经存在的选中模型
				this.getHad()
			}
		},
		async getHad() {
			// 判断是否有已经存在的选中模型
			const { data: ress } = await this.$http.get('/sf' + this.$api.info + '/' + this.equipId)
			console.log(ress)
			if (ress.code == 0) {
				let hadDatas = ress.data
				// 有数据，用返回的id请求详情
				if (hadDatas && hadDatas.kindId) {
					this.readonly = false
					this.isStop = hadDatas.enableTailGun
					this.tabIconList[2]['status'] = hadDatas.enableTailGun == '1' ? 1 : 0
					this.isEmpty = false
					this.modalVal = hadDatas.kindId
					this.changeId = hadDatas.id
					// 在列表中找选中的是哪一个，主要用于赋值名称
					let modalIndex = this.modalList.findIndex((item) => {
						return item.id == hadDatas.kindId
					})
					// 名称
					this.kindName = this.modalList[modalIndex].kindName
					// 判断时间是否存在
					if (hadDatas.startTime) {
						this.isHadTime = true
						// 时间
						this.startTime = hadDatas.startTime

						let params = {
							kindId: hadDatas.kindId,
							startTime: hadDatas.startTime,
						}
						// 获取当前阶段数据
						const { data: res } = await this.$http.post('/apis' + this.$api.kindStageInfo, params)
						if (res.code == 0) {
							// 赋值阶段数据
							this.introWord = res.data || {}
						}
					} else {
						this.isHadTime = false
						this.startTime = ''
					}
				} else {
					// 没有数据，直接显示暂无
					console.log('0')
					this.readonly = true
					this.isEmpty = true
					this.isStop = '0'
					this.tabIconList[2]['status'] = 0
					this.kindName = ''
					this.modalVal = ''
					this.changeId = ''
				}
			} else {
				// 显示暂无数据
				this.readonly = true
				this.modalVal = ''
				this.isEmpty = true
				this.isStop = '0'
				this.tabIconList[2]['status'] = 0
				this.changeId = ''
			}
		},
		// 获取全部阶段详情
		async getDetail() {
			const params = {
				kindId: this.modalVal,
			}
			const { data: res } = await this.$http.post('/apis' + this.$api.stageDetail, params)
			if (res.code == 0) {
				let datas = res.data || []
				// 赋值阶段详情
				this.stageDetail = datas
				this.readonly = false
			}
		},
		// 线型图片的判断
		lineIcon(status) {
			if (status == 1) {
				return require('@/assets/img/water/icon_liney.png')
			} else {
				return require('@/assets/img/water/icon_linen.png')
			}
		},
		// 开始时间点击
		async timeChange(val) {
			console.log(val)
			if (!this.changeId || !this.changeId.length || this.changeId == '') {
				return this.$message('请先选择模型并应用', 'warning')
			}
			let params = {
				id: this.changeId,
				startTime: val,
				enableTailGun: this.isStop,
			}
			console.log(params)
			const { data: res } = await this.$http.post('/sf' + this.$api.update, params)
			if (res.code == 200) {
				this.getHad()
			} else {
				this.$message(res.msg, 'warning')
			}
		},
		timeFocus() {
			$('#pickers').focus()
		},
		timeClick() {
			if (this.readonly) return this.$message('请先选择模型并应用', 'warning')
			// 设置默认时间
			this.startTimeVal = this.getNowFormatDate()
		},
		// 模型选择点击
		async tabOpenDia({ type }) {
			// 模型选择
			if (type == 'dia') {
				console.log('弹出层')
				if (!this.isShowDia) {
					this.isShowDia = true
					this.getDetail()
				}
			} else if (type == 'set') {
				if (!this.modalVal) return this.$message('请先选择模型并应用', 'warning')
				if (!this.changeId || !this.changeId.length || this.changeId == '') {
					return
				}
				let makeStop = '0'
				// 枪尾联动
				if (this.isStop == '0') {
					// 停止状态，需要开启
					makeStop = '1'
				} else {
					makeStop = '0'
				}
				let params = {
					id: this.changeId,
					startTime: this.startTime,
					enableTailGun: makeStop,
				}
				const { data: res } = await this.$http.post('/sf' + this.$api.update, params)
				if (res.code == 200) {
					this.getHad()
				} else {
					this.$message(res.msg, 'warning')
				}
			} else if (type == 'time') {
			}
		},
		// 模型切换，重新请求阶段详情
		changeModal() {
			// 请求详情
			this.getDetail()
		},
		// 点击应用
		toUse() {
			if (!this.modalVal && this.modalVal == '') {
				return this.$message('请先选择模型', 'warning')
			}
			const _this = this
			this.$confirm(`是否确认应用该模型?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				customClass: 'waterMsg',
			})
				.then(() => {
					// 请求新建接口
					_this.makeSave()
				})
				.catch(() => {})
		},
		async makeSave() {
			const params = {
				equipId: this.equipId,
				kindId: this.modalVal,
			}
			// 请求新建接口
			const { data: res } = await this.$http.post('/sf' + this.$api.save, params)
			if (res.code == 0) {
				this.changeId = res.data
				this.startTime = ''
				this.getHad()
				this.closeDia()
			} else {
				this.$message(res.msg, 'warning')
			}
		},
		// 弹窗关闭事件
		closeDia() {
			this.isShowDia = false
		},
	},
}
</script>
<style lang="scss" scoped>
#smartstab {
	position: relative;
	margin: 5px auto 7px;
	.top {
		padding-top: 3px;
		padding-right: 6px;
		padding-left: 5px;
	}
	.empty {
		display: block;
		width: 70%;
		margin: auto;
	}
	.wrap {
		margin-top: 10px;
		.img_modal {
			width: 104px;
			height: 161px;
		}
		.right {
			flex: 1;
			margin-left: 32px;
		}
		.tabdiv {
			width: 33.33%;
			position: relative;
			cursor: pointer;
		}
		.starttime-change {
			position: absolute;
			top: 0;
			left: -10px;
			z-index: 10;
			width: 80px;
			height: 82px;
			overflow: hidden;
			opacity: 0;
			cursor: pointer;
			.el-date-editor.el-input {
				width: 80px !important;
				height: 82px !important;
				cursor: pointer !important;
				padding: 0 !important;
				.el-input__inner {
					width: 80px !important;
					height: 82px !important;
					cursor: pointer !important;
					padding: 0 !important;
				}
			}
			.el-input__suffix {
				display: none;
			}
			.el-input__prefix {
				display: none;
			}
		}
		.set-tab {
			width: 42px;
			height: 42px;
			border: 2px solid rgba(141, 141, 141, 0.5);
			border-radius: 50%;
			margin: 0 auto 6px;
			position: relative;
		}
		// 线
		.line-icon {
			position: absolute;
			z-index: 1;
			width: 34px;
			height: 34px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		// 纯色背景
		.set-smallbg {
			width: 24px;
			height: 24px;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
			background-color: rgba(153, 153, 153, 0.23);
		}
		.set-smallbg-y {
			background-color: rgba(0, 186, 255, 0.23);
		}
		// 第四层不同的图标
		.icons {
			width: 18px;
			height: 18px;
			position: absolute;
			z-index: 4;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		// 阶段介绍
		.intro-div {
			width: 281px;
			height: 138px;
			overflow-x: hidden;
			margin-top: 10px;
			border-radius: 4px;
			box-sizing: border-box;
			border: 1px dashed rgba(15, 230, 255, 0.6);
		}
		.intro {
			width: 281px;
			height: 128px;
			margin-top: 5px;

			overflow-y: scroll;
			padding: 0 10px;
			& > div {
				line-height: 1.4;
			}
			&::-webkit-scrollbar {
				width: 4px;
				height: 0px;
				background-color: transparent;
			}
			&::-webkit-scrollbar-track {
				border-radius: 10px;
				// background-color: #f5f5f5;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background-color: rgba($color: #04638b, $alpha: 0.6);
			}
			.intro-name {
				color: #0dfffc;
			}
		}
	}
	// 弹出层
	.smartdia {
		width: 494px;
		height: 636px;
		background: url('../../assets/img/water/dia_bg.png') no-repeat;
		background-size: 100% 100%;
		position: absolute;
		top: -18px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 10;
		padding-top: 38px;
		// 关闭
		.close {
			position: absolute;
			width: 34px;
			height: 34px;
			top: 26px;
			right: 13px;
			cursor: pointer;
		}
		// 选择
		.selects {
			.btn {
				width: 80px;
				height: 34px;
				line-height: 34px;
				background-color: rgba(13, 174, 164, 1);
				border-radius: 7px;
				margin-left: 3px;
				cursor: pointer;
			}
		}
		// 详情
		.stages-div {
			width: 470px;
			height: auto;
			overflow-x: hidden;
			position: relative;
		}
		.stages {
			width: 494px;
			padding-right: 26px;
			padding-left: 20px;
			height: 500px;
			padding-top: 5px;
			overflow-y: scroll;
			position: relative;
			left: 0;
			&::-webkit-scrollbar {
				width: 0px;
				height: 0px;
				background-color: transparent;
			}
			&::-webkit-scrollbar-track {
				border-radius: 10px;
				// background-color: #f5f5f5;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 10px;
				background-color: rgba($color: #04638b, $alpha: 0.6);
			}
			.stage-wrap {
				position: relative;
				.lines {
					position: absolute;
					width: 4px;
					height: 98%;
					background-color: rgba(0, 82, 112, 0.26);
					top: -8px;
					left: 117px;
					z-index: 11;
				}
				// &::before {
				// 	content: '';
				// 	position: absolute;
				// 	width: 4px;
				// 	height: 98%;
				// 	background-color: rgba(0, 82, 112, 0.26);
				// 	top: -8px;
				// 	right: 320px;
				// 	z-index: 11;
				// }
			}
			// .time-line {
			// }
			.stage-div {
				position: relative;
				z-index: 12;
				margin-top: 7px;
				&:first-child {
					margin-top: 0;
				}
			}
			.stage-name {
				width: 100px;
				padding-top: 12px;
			}
			.stage-yuan {
				width: 43px;
				height: 43px;
			}
			.stage-detail {
				width: 295px;
				padding: 10px 12px 19px 9px;
				background: url('../../assets/img/water/dia_intro.png') no-repeat;
				background-size: 100% auto;
				line-height: 1.5;
				letter-spacing: 1px;
				margin-top: 6px;
			}
		}
	}
}
</style>
<style lang="scss">
#smartstab {
	.selects {
		.el-select {
			width: 172px;
			height: auto;
			background: none;
		}
		.el-input__inner {
			width: 100%;
			background-color: transparent;
			border-width: 0;
			height: 45px;
			line-height: 45px;
			padding: 0 21px;
			background-image: url('../../assets/img/water/dia_select.png');
			background-size: 100% 100%;
			color: #2bf7ff;
			font-size: 16px;
		}
		.el-input__suffix {
			right: 17px;
		}
		.el-select .el-input .el-select__caret {
			color: #2bf7ff;
		}
		.el-icon-arrow-up:before {
			content: '\e78f';
		}
	}
}
.el-select-dropdown.waterlog {
	width: 172px !important;
	min-width: 0 !important;
	background-color: #004058 !important;
	border: none !important;
	box-shadow: 0px 0px 10px 5px rgba(0, 217, 244, 1) inset !important;
}
.waterlog .el-select-dropdown__item {
	color: #fff !important;
}
.waterlog .el-select-dropdown__item.hover,
.waterlog .el-select-dropdown__item:hover {
	background-color: rgba(52, 200, 255, 0.3) !important;
	color: #fff !important;
}
.waterlog .el-select-dropdown__item.selected {
	color: #2bf7ff !important;
}
.el-popper[x-placement^='bottom'] .popper__arrow::after {
	border-bottom-color: #2bf7ff !important;
}
.el-popper[x-placement^='bottom'] .popper__arrow {
	border-bottom-color: #2bf7ff !important;
}
// 开始时间的选择
.el-input--small .el-input__inner {
	background-color: transparent;
}
.el-picker-panel__footer {
	background-color: transparent !important;
}
.el-date-picker__header-label {
	color: #fff !important;
}
.el-button {
	background-color: transparent !important;
	color: rgba(13, 174, 164, 1) !important;
	border-width: 0 !important;
}
.el-button--text {
	color: #d3d3d3 !important;
}
.starttime-change {
	position: absolute;
	top: 0;
	left: -10px;
	z-index: 10;
	width: 80px;
	height: 82px;
	overflow: hidden;
	opacity: 0;
	cursor: pointer;
	.el-date-editor.el-input {
		width: 80px !important;
		height: 82px !important;
		cursor: pointer !important;
		padding: 0 !important;
		.el-input__inner {
			width: 80px !important;
			height: 82px !important;
			cursor: pointer !important;
			padding: 0 !important;
		}
	}
	.el-input__suffix {
		display: none;
	}
	.el-input__prefix {
		display: none;
	}
}
.watertime {
	.el-input__inner {
		color: rgba(13, 174, 164, 1);
	}
	.el-time-panel {
		background-color: #024242 !important;
		box-shadow: 0px 0px 10px 5px rgba(13, 174, 164, 1) inset !important;
		.el-time-spinner__item {
			color: #fff;
		}
		.el-time-spinner__item:hover:not(.disabled):not(.active) {
			background-color: transparent;
			color: rgba(13, 174, 164, 1);
		}
		.el-time-spinner__item.active:not(.disabled) {
			color: rgba(13, 174, 164, 1);
		}
		.el-time-panel__btn {
			color: #d3d3d3 !important;
		}
		.el-time-panel__btn.confirm {
			color: rgba(13, 174, 164, 1) !important;
		}
	}
}
</style>
