<template>
	<div>
		<!-- 饼状图1-->
		<div ref="pie1Ref" class="pie1"></div>
	</div>
</template>

<script>
	export default {
        props: {
            data: {
                type: Array,
                required:true
            },
        },
		data() {
			return {

			}
		},
		mounted() {
			this.pie1();
		},
        watch:{
			data:{
				handler(){
					this.pie1();
			 	},
				deep:true
			}
		},
		methods: {
			pie1() {
                let _this = this;
                if(_this.data.length < 2 ){
                    return
                }
                let data = JSON.parse(JSON.stringify(_this.data))
                let pieData = data.map(item=>{
                    return {
                        name:item.name,
                        value: +(item.count),
                        max: +(item.countAll)
                    }
                })
				let myChart = this.$echarts.init(this.$refs.pie1Ref)

      var seriesArr = [];

      pieData.forEach(function (item, index) {
          seriesArr.push(
            {
              type: 'pie',
              clockWise: false,
              radius: ['65%', '80%'],
              itemStyle: {
                  normal: {
                      color: new _this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                              offset: 0,
                              color: '#22ECB0',
                          },
                          {
                              offset: 1,
                              color: '#0BBEE8',
                          },
                      ]),
                      label: {
                          show: false,
                      },
                      labelLine: {
                          show: false,
                      },
                  },
              },
              hoverAnimation: false,
              center: [index * 50 + 25 + '%', '45%'],
              data: [
                  {
                      value: item.max-item.value,
                      name: 'invisible',
                      itemStyle: {
                          normal: {
                              color: 'rgba(255,255,255,.3)',
                          },
                          emphasis: {
                              color: 'rgba(255,255,255,.3)',
                          },
                      },
                  },
                  {
                      name:item.name,
                      value: item.value,
                      label: {
                          normal: {
                              formatter: function (params) {
                                  return `{a|${params.value}个}\n{b|${params.name}}`;
                              },
                              rich:{
                                a:{
                                  fontSize: '20',
                                  fontWeight:800,
                                  color: '#fff',
                                },
                                b:{
                                  fontSize: 14,
                                  fontWeight:800,
                                  color: '#c9c9c9',
                                }
                              },
                              position: 'center',
                              show: true,
                              textStyle: {
                                  fontSize: '20',
                                  // fontWeight: 'bold',
                                  color: '#fff',
                              },
                          },
                      },
                  }
              ],
          },

              )
      });
      const option = {
          grid: {
              left: '5%',
              right: '2%',
              bottom: '0%',
              top: '0%',
              containLabel: true,
          },
          series: seriesArr,
      };
				myChart.setOption(option)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.pie1 {
		width: 310px;
		height: 160px;
	}
</style>
