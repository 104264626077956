<template>
	<div>
		<div class="line2" ref="lineRef"></div>
	</div>
</template>

<script>
import { loopShowTooltip } from '../../util/loop-tooltip'
export default {
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			chartInstane: null,
			tooltipTimer: null,
		}
	},
	mounted() {
		this.echartLine()
	},
	watch: {
		data: {
			handler() {
				this.echartLine()
			},
			deep: true,
		},
	},
	destroyed() {
		this.tooltipTimer && this.tooltipTimer.clearLoop()
	},
	methods: {
		// 中间市场价格折线图
		echartLine() {
			const _this = this
			if (!this.data.xAxis || !this.data.series || this.data.series.length < 2) {
				return
			}
			let myChart = this.$echarts.init(this.$refs.lineRef)
			let data = JSON.parse(JSON.stringify(this.data))
			let serData = data.series
			let xLabel = data.xAxis
			let unitArr = [
				{ name: '用水', unit: '吨' },
				{ name: '用电', unit: 'kw·h' },
				{ name: '用暖', unit: 'Nm³' },
			]
			let SelectedData = {},
				LengData = [],
				colors = [],
				rgbs = [],
				YaData = [],
				SeriesData = [],
				DataInfo = [],
				option = {}
			colors = ['#2194FF', '#29DDDC', '#f03738']
			rgbs = ['33,148,252', '41,221,220', '240, 55, 56']
			serData.forEach((item, index) => {
				SelectedData[item.name] = true
				LengData.push(item.name)
				DataInfo.push({
					type: 'line',
					name: item.name,
					yAxisIndex: index,
					data: item.data,
					lineStyle: {
						color: 'rgba(' + rgbs[index] + ',.8)', // 线条颜色
						type: 'solid',
						width: 2,
					},
					zlevel: index + 2,
					areaStyle: {
						normal: {
							color: new this.$echarts.graphic.LinearGradient(
								0,
								0,
								0,
								1,
								[
									{
										offset: 0,
										color: 'rgba(' + rgbs[index] + ', 0.4)',
									},
									{
										offset: 0.5,
										color: 'rgba(' + rgbs[index] + ', 0.3)',
									},
									{
										offset: 1,
										color: 'rgba(' + rgbs[index] + ', 0.1)',
									},
								],
								false
							),
						},
					},
					symbol: 'none',
					smooth: true,
				})
			})
			function Init(sel, dataInfo) {
				SelectedData = sel || {}
				;(YaData = []), (SeriesData = [])
				let Datas = JSON.parse(JSON.stringify(dataInfo))
				for (let n = 0, l = LengData.length; n < l; n++) {
					const ydata = Datas[n].data
					let ymax = Math.ceil(Math.max(...ydata) / 100) * 100
					if (ymax === 0) {
						ymax = 100
					}
					let name = LengData[n].slice(0, 2)
					let [unitObj] = unitArr.filter((unit) => unit.name == name)
					// 如果该图例状态为false时，则跳过 不push
					if (sel[LengData[n]]) {
						YaData.push({
							type: 'value',
							name: LengData[n],
							min: 0,
							max: ymax,
							minInterval: 1,
							splitNumber: 5,
							interval: ymax / 5,
							position: YaData.length % 2 == 0 ? 'left' : 'right',
							offset: YaData.length + 1 <= 2 ? 0 : (Math.ceil((YaData.length + 1) / 2) - 1) * 80,
							name: `(${unitObj.unit})`,
							nameTextStyle: {
								color: '#fff',
								fontSize: 16,
								padding: YaData.length % 2 == 0 ? [0, 50, 0, 0] : [0, 0, 0, 50],
							},
							splitLine: {
								show: n == 0 ? true : false,
								lineStyle: {
									color: 'rgba(23,157,160,.2)',
									width: 3,
								},
							},
							axisLabel: {
								show: true,
								color: '#fff',
								fontSize: 16,
							},
							axisTick: {
								show: false,
							},
						})
					} else {
						Datas[n].data = []
					}
					Datas[n].yAxisIndex = YaData.length - 1 < 0 ? 0 : YaData.length - 1
					SeriesData.push(Datas[n])
				}
				if (YaData.length == 0) {
					YaData = [{ type: 'value' }]
				}
				option = {
					color: colors,
					tooltip: {
						trigger: 'axis',
						backgroundColor: 'rgb(53, 53, 53, 0.51)',
						borderWidth: 0,
						textStyle: {
							fontSize: 15,
							color: '#fff',
						},
						axisPointer: {
							type: 'line',
							lineStyle: {
								color: '#3371FC',
							},
						},
						position: (pos, param, dom, rect, size) => {
							var obj = { top: 60 }
							let halfContent = size.viewSize[0] / 2
							if (pos[0] <= halfContent) {
								obj['left'] = pos[0]
							} else if (pos[0] > halfContent) {
								obj['right'] = size.viewSize[0] - pos[0]
							}
							return obj
						},
						formatter(params) {
							let result = ''
							if (params.length > 0) {
								result += `<div style="line-height:20px">${params[0].axisValue}:</div>`
								params.forEach((item) => {
									let name = item.seriesName.slice(0, 2)
									let [unitObj] = unitArr.filter((unit) => unit.name == name)
									result += `<div style="line-height:20px">${unitObj.name}：${item.data}${unitObj.unit}</div>`
								})
							}
							return result
						},
					},
					grid: {
						top: '18%',
						left: '6%',
						right: '6%',
						bottom: '16%',
					},
					legend: {
						data: LengData,
						selected: SelectedData,
						top: '4%',
						// selectedMode:false,
						orient: 'horizontal',
						itemGap: 50, //图例每项之间的间隔。横向布局时为水平间隔，纵向布局时为纵向间隔
						itemHeight: 16,
						textStyle: {
							color: '#ffffff',
							fontSize: 15,
							height: 16,
							lineHeight: 16,
							fontWeight: 900,
						},
						icon: 'rect',
					},
					xAxis: [
						{
							type: 'category',
							boundaryGap: false, //一定要给x轴设置,与前面紧挨着(紧挨边缘)
							axisLine: {
								//坐标轴轴线相关设置。数学上的x轴
								show: false,
							},
							axisLabel: {
								//坐标轴刻度标签的相关设置
								textStyle: {
									fontSize: 14,
									color: '#fff',
								},
							},
							splitLine: {
								show: false,
							},
							axisTick: {
								show: false,
								inside: true,
								lineStyle: {
									color: '#192a44',
								},
							},
							data: xLabel,
						},
					],
					yAxis: YaData,
					series: SeriesData,
				}
			}
			Init(SelectedData, DataInfo)
			if (option && typeof option === 'object') {
				myChart.setOption(option, true)
				this.tooltipTimer && this.tooltipTimer.clearLoop()
				this.tooltipTimer = 0
				this.tooltipTimer = loopShowTooltip(myChart, option, {
					interval: 3000, // 轮播间隔时间
					loopSeries: true, // 是否开启轮播循环
					dataLength: xLabel.length,
				})
			}
			/* 选中图例 */
			myChart.on('legendselectchanged', function (params) {
				// 得到当前的图例显示隐藏状态分别有哪些
				SelectedData = params.selected
				let serIndex = 0
				Object.values(SelectedData).forEach((item, index) => {
					if (item) {
						serIndex = index
					}
				})
				Init(SelectedData, DataInfo)
				if (option && typeof option === 'object') {
					myChart.setOption(option, true)
					_this.tooltipTimer && _this.tooltipTimer.clearLoop()
					_this.tooltipTimer = 0
					_this.tooltipTimer = loopShowTooltip(myChart, option, {
						interval: 3000, // 轮播间隔时间
						loopSeries: true, // 是否开启轮播循环
						dataLength: xLabel.length,
						seriesIndex: serIndex,
						dataIndex: -1,
					})
				}
			})
		},
	},
}
</script>
<style lang="less" scoped>
.line2 {
	height: 220px;
	width: 800px;
}
</style>
