<template>
  <div>
    <div id="pieRef" class="pie1"></div>
  </div>
</template>
<script>
import HighCharts from 'highcharts'
export default{
  props: {
    data: {
      type: Array,
      require:true 
    },
  },
  data() {
    return {
      myChart: null
    }
  },
  mounted () {
    this.pie1();
  },
  watch:{
			data:{
				handler(){
          this.myChart = null;
					this.pie1();
			 	},
				deep:true
			}
		},
  methods: {
     pie1() {
       if(this.data.length==0){
         return;
       }
          let _this = this;
        let data = JSON.parse(JSON.stringify(this.data));
					let opData = [];
        data.forEach((item,index)=>{
					let newObj = {
						name:item.name,
						y:+(item.value)
					}
					if(item.name == '物联网设备'){
						newObj.selected=true;
						newObj.sliced=true;
					}
          opData.push(newObj)
        })
        let option = {
				 credits: {
					enabled: false //不显示LOGO
					},
				 chart: {
					type: 'pie',
					backgroundColor: "rgba(0, 0, 0, 0)",
					options3d: {
						enabled: true,
						alpha: 60,
						viewDistance:80,
						beta: 0, 
					}
				},
				title: {
					text: ''
				},
        tooltip:{
					enabled:true,
          backgroundColor:"rgba(255,255,255,0.3)",
          borderWidth:0,
          shadow:false,
          style:{
            color:"#fff",
            fontSize:16
          },
          formatter:function(){
            // let percent = this.point.percentage.toFixed(0)
            return `${this.point.name}：${this.point.y}（万元）`;
          },
				},
				labels:{
					color:"#fff"
				},
				plotOptions: {
					pie: {
            size:"120%",
						center:['40%','60%'],
						allowPointSelect: true,
						innerSize: '0%',
						cursor: 'pointer',
						depth: 25,
						slicedOffset:20,
						colors:['#45c2ff','#00d7e9'],
						// showInLegend:true,
						states:{
							inactive:{
								opacity:1
							}
						},
						dataLabels: {
							enabled: true,
							distance:5,
							padding:0,
							connectorPadding:0,
							y:-8,
							formatter:function(index){
								return `<div style="font-size:16px"><span style="font-size:22px;font-weight:bold;color:#41C1FF">${this.point.y}</span>（万元）<br>${this.point.name}</div>`;
							},
							style: { //样式配置
									textOutline: "none", //去掉文字白边
									color: "#fff",
									fontSize: 14,
							},
						}
					}
				},
				series: [{
					data: opData,
				}]
			 }
			 HighCharts.chart('pieRef', option)
				
			},

  },
}
</script>
<style lang='scss' scoped>
	.pie1 {
		width: 420px;
		height: 236px;
	}
</style>