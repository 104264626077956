<template>
  <div class="piePage">
    <!-- 饼状图1-->
    <div ref="pie2Ref" class="pie1" @click.prevent="stopModel"></div>
    <div class="vshow" ref="qrcode" v-if="showModel" :style="modelStyle">
      <!-- <img :src="curData.url" alt="" srcset=""> -->
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  props: {
    data: {
        type: Array,
        required:true
    },
  },
  data() {
    return {
      curData:{},
      showModel:false,
      modelStyle:{
        left:'',
        top:''
      }
    };
  },
  mounted() {
    this.pie1();
    document.addEventListener('click',(e)=>{
      this.showModel = false
    })
  },
  watch:{
			data:{
				handler(){
					this.pie1();
			 	},
				deep:true
			}
		},
    destroyed(){
      document.removeEventListener('click',(e)=>{
      this.showModel = false
    })
    },
  methods: {
    stopModel(){
      this.showModel = false
    },
    pie1() {
      let _this = this;
      if(this.data.length == 0){
        return
      }
      let myChart = this.$echarts.init(this.$refs.pie2Ref);
      let xiaoshoue=[];
      let xiaoshouliang = [];
      let xingm = [];
      let data = JSON.parse(JSON.stringify(this.data))
      data.forEach(item=>{
        xiaoshoue.push(item.saleAcount)
        xiaoshouliang.push(item.saleWeight)
        xingm.push(item.varietyName)
      })
      let max1 = xiaoshoue.reduce((a,b)=>b>a ? b : a)
      let max2 = xiaoshouliang.reduce((a,b)=>b>a ? b : a)
      const option = {
        // color: ["#61A8FF","#0cd2a5"], //进度条颜色
        legend: {
          show: true,
          top:"5%",
          itemGap:20,
          data:[
            {
              name:"销售量",
              textStyle:{
                color:"#fff",
                fontSize:14
              },
              itemStyle:{
                color: new this.$echarts.graphic.LinearGradient(
                  0,1,0,0, [
                    {
                      offset: 0,
                      color: "#1A81FD",
                    },
                    {
                      offset: 0.6,
                      color: "#1A81FD",
                    },
                    {
                      offset: 1,
                      color: "#FFFFFF",
                    },
                  ],
                )
              }
            },
            {
              name:"销售额",
              textStyle:{
                color:"#fff",
                fontSize:14
              },
              itemStyle:{
                color: new this.$echarts.graphic.LinearGradient(
                  0,1,0,0, [
                    {
                      offset: 0,
                      color: "#03D0A2",
                    },
                    {
                      offset: 0.6,
                      color: "#03D0A2",
                    },
                    {
                      offset: 1,
                      color: "#FFFFFF",
                    },
                  ],
                )
              }
            }
          ]
        },
        grid:{
          left:"28%",
          right:"15%",
          top:"10%",
          bottom:"7%"
        },
        xAxis: [
          {
            show: false,
            max:max1*1.2
          },
          {
            show:false,
            max:max2*1.2
          }
        ],
        yAxis: {
          type: "category",
          offset:0,
          triggerEvent:true,
          axisLabel: {
            clickable:true,
            show: true, //让Y轴数据不显示
            color: "#fff",
            fontSize:16,
            formatter:(value,index)=>{
              if(value.length > 4){
                value = value.substring(0,4)+'...'
              }
              return '{a|'+value+'}{b|}'
             
            },
            rich:{
              a:{
                width:70,
                height:20,
                color: "#fff",
                fontSize:16,
                align:'left',
              },
              b:{
                height:20,
                width:20,
                align:'right',
                backgroundColor:{ image: require('@/assets/img/homeProduction/rbr-code.png') }
              }
            },
          },
          itemStyle: {},
          axisTick: {
            show: false, //隐藏Y轴刻度
          },
          axisLine: {
            show: true, //隐藏Y轴线段
            lineStyle:{
              color:'rgba(255,255,255,.25)',
              width:3
            }
          },
          data: xingm,
        },
        series: [
          //蓝条--------------------我是分割线君------------------------------//
          {
            show: true,
            type: "bar",
            name: "销售量",
            barGap: '25%',
            xAxisIndex:0,
            barWidth: "16", //统计条宽度
            itemStyle: {
              normal: {
                shadowBlur:15,
                shadowColor:"#1A81FD",
                color: function (params) {
                  return {
                    type: "linear",
                    x: 0,
                    y: 1,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#1A81FD",
                      },
                      {
                        offset: 0.6,
                        color: "#1A81FD",
                      },
                      {
                        offset: 1,
                        color: "#FFFFFF",
                      },
                    ],
                  };
                },
              },
            },
            label: {
              show: true,
              position: "right",
              fontSize:14,
              color: "#fff",
              verticalAlign:"middle",
              formatter:'{c}吨'
            },
            labelLine: {
              show: false,
            },
            z: 2,
            data: xiaoshouliang,
          },
          {
            show: true,
            type: "bar",
            name: "销售额",
             xAxisIndex:1,
            barWidth: "16", //统计条宽度
            itemStyle: {
              normal: {
                shadowBlur:15,
                shadowColor:"#03D0A2",
                color: function (params) {
                  return {
                    type: "linear",
                    x: 0,
                    y: 1,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#03D0A2",
                      },
                      {
                        offset: 0.6,
                        color: "#03D0A2",
                      },
                      {
                        offset: 1,
                        color: "#fff",
                      },
                    ],
                  };
                },
              },
            },
            labelLine: {
              show: false,
            },
            label: {
              show: true,
              position: "right",
              fontSize:14,
              color: "#fff",
              verticalAlign:"middle",
              formatter:'{c}万元'
            },
            z: 2,
            data: xiaoshoue,
          },
        ],
      };
      myChart.setOption(option);
      this.extension(myChart);
      myChart.on('click','yAxis.category',param=>{
        if(!param.event.target.__image){
          return;
        }
        param.event.event.stopPropagation()
        _this.showModel =true;
        _this.curData = data.filter(item=>item.varietyName == param.value)
        _this.$nextTick(()=>{
          _this.$refs['qrcode'].innerHTML = ''
          new QRCode(_this.$refs['qrcode'], {
            width: 100, 
            render: 'canvas', 
            colorDark: '#000000',
            colorLight: '#ffffff',
            height: 100,
            correctLevel : QRCode.CorrectLevel.H,
            text: _this.curData[0].url, // 二维码地址
          })
        })
        _this.modelStyle.left = 100 + 'px';
        _this.modelStyle.top = param.event.offsetY - 20 + 'px';
        if(param.event.offsetY + 81 > 460){
           _this.modelStyle.top = 365 + 'px';
        }
      })
    },
    extension(myChart) {
      // 注意这里，是以X轴显示内容过长为例，如果是y轴的话，需要把params.componentType == 'xAxis'改为yAxis
      // 判断是否创建过div框,如果创建过就不再创建了
      // 该div用来盛放文本显示内容的，方便对其悬浮位置进行处理
      var elementDiv = document.getElementById('extension')
      if (!elementDiv) {
        var div = document.createElement('span')
        div.setAttribute('id', 'extension')
        div.style.display = 'block'
        document.querySelector('html').appendChild(div)
      }
      myChart.on('mouseover', function (params) {
        if (params.componentType == 'yAxis') {
          if(params.event.target.__image){
            return;
          }
          var elementDiv = document.querySelector('#extension')
          //设置悬浮文本的位置以及样式
          var elementStyle =
            'position: absolute;z-index: 99999;color: #fff;font-size: 12px;padding: 5px;display: inline;border-radius: 4px;background-color: #303133;box-shadow: rgba(0, 0, 0, 0.3) 2px 2px 8px;white-space:nowrap;'
          elementDiv.style.cssText = elementStyle
          elementDiv.innerHTML = params.value
          document.querySelector('html').onmousemove = function (event) {
            // console.log(event);
            var elementDiv = document.querySelector('#extension')
            var xx = event.pageX - 10
            var yy = event.pageY + 15
            // console.log(xx,yy);
            elementDiv.style.top = yy + 'px'
            elementDiv.style.left = xx + 'px'
          }
        }
      })
      myChart.on('mouseout', function (params) {
        //注意这里，我是以X轴显示内容过长为例，如果是y轴的话，需要改为yAxis
        if (params.componentType == 'yAxis') {
          var elementDiv = document.querySelector('#extension')
          elementDiv.style.display = 'none'
        }
      })
    }
  },
};
</script>

<style lang="scss">
.piePage{
  width:100%;
  height:100%;
  position: relative;
  .pie1 {
    width: 400px;
    height: 460px;
  }
  .vshow{
    position: absolute;
    // top:54px;
    // left:100px;
    width: 140px;
    height: 130px;
    padding: 15px 26px;
    background-image: url("../../assets/img/homeProduction/ewm.png");
    background-size: 100% 100%;
  }
}

</style>
