<template>
  <div>
    <div class="line2" ref="lineRef"></div>
  </div>
</template>

<script>
import { loopShowTooltip } from "../../util/loop-tooltip";
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartInstane: null,
      tooltipTimer: null,
    };
  },
  mounted() {
    this.echartLine();
  },
  watch: {
    data: {
      handler() {
        this.echartLine();
      },
      deep: true,
    },
  },
  destroyed() {
    this.tooltipTimer && this.tooltipTimer.clearLoop();
  },
  methods: {
    // 中间市场价格折线图
    echartLine() {
      const _this = this;
      if (!this.data || this.data.length == 0) {
        return;
      }
      let myChart = this.$echarts.init(this.$refs.lineRef);
      let xLabel = [];
      let yData = [];
      let data = JSON.parse(JSON.stringify(this.data));
      data.forEach((item) => {
        item.value = +item.value;
        xLabel.push(item.name + "年");
        yData.push(item.value.toFixed(2));
      });
      var option = {
        color: ["#00FCFF", "#7888FF"],
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgb(113,200,255, 0.28)",
          borderWidth: 0,
          textStyle: {
            fontSize: 15,
            color: "#fff",
          },
          position: (pos, param, dom, rect, size) => {
            var obj = { top: 60 };
            let halfContent = size.viewSize[0] / 2;
            if (pos[0] <= halfContent) {
              obj["left"] = pos[0];
            } else if (pos[0] > halfContent) {
              obj["right"] = size.viewSize[0] - pos[0];
            }
            return obj;
          },
          confine: true,
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#3371FC",
            },
          },
          formatter(params) {
            return `<div style="font-size:18px;">${params[0].axisValue}</div>
											<div style="font-size:16px;margin-top:9px;">产量：${params[0].value}公斤/亩</div>`;
          },
        },
        legend: {
          show: false,
        },
        grid: {
          top: "18%",
          left: "13%",
          right: "7%",
          bottom: "20%",
        },
        xAxis: {
          type: "category",
          boundaryGap: false, //一定要给x轴设置,与前面紧挨着(紧挨边缘)
          axisLine: {
            //坐标轴轴线相关设置。数学上的x轴
            show: false, //是否显示坐标轴轴线。
          },
          axisLabel: {
            //坐标轴刻度标签的相关设置
            textStyle: {
              fontSize: 14,
              color: "#fff",
            },
          },
          //是否显示分隔线。默认数值轴显示，类目轴不显示
          splitLine: {
            show: false,
          },
          //是否显示坐标轴刻度。
          axisTick: {
            show: false,
            inside: true, //坐标轴刻度是否朝内，默认朝外。
            lineStyle: {
              color: "#192a44",
            },
          },
          data: xLabel,
        },
        yAxis: {
          name: "产量（公斤/亩)",
          nameTextStyle: {
            color: "#fff",
            fontSize: 16,
            fontWeight: 200,
            padding: [0, 0, 0, 20],
          },
          min: 0,
          minInterval: 1,
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(0,90,198,.2)",
              width: 3,
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#192a44",
            },
          },
          axisLabel: {
            show: true,
            color: "#fff",
            fontSize: 16,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            type: "line",
            symbol: "none", // 默认是空心圆（中间是白色的）.设置为none不显示
            // showAllSymbol: true,
            //标记的大小
            symbolSize: 10,
            smooth: true,
            label: {
              show: false, //是否显示标签。
              position: "top", //标签的位置。
              textStyle: {
                color: "#00AEFF",
              },
            },
            //折线拐点标志的样式。
            itemStyle: {
              color: "#00AEFF",
              borderColor: "#fff",
              borderWidth: 2,
            },
            //线条
            lineStyle: {
              color: "#00AEFF", // 线条颜色
              type: "solid",
            },
            tooltip: {
              show: true,
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,

                  [
                    {
                      offset: 0,
                      color: "rgba(0, 174, 255, 1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0, 174, 255, 0.02)",
                    },
                  ],
                  false
                ),
              },
            },
            data: yData,
          },
        ],
      };
      myChart.setOption(option);
      this.tooltipTimer && this.tooltipTimer.clearLoop();
      this.tooltipTimer = 0;
      this.tooltipTimer = loopShowTooltip(myChart, option, {
        interval: 3000, // 轮播间隔时间
        loopSeries: true, // 是否开启轮播循环
        dataLength: xLabel.length,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.line2 {
  width: 840px;
  height: 220px;
}
</style>
