<template>
	<div class="month-wrap">
		<!-- 业务监测 -->
		<div v-if="datas.length">
			<vue-seamless-scroll
				:data="datas"
				class="seamless-warp"
				:class-option="classOption"
			>
				<ul class="flex">
					<li class="DataList_top" v-for="(item, index) in datas" :key="index">
						<div class="left2">
							<p class="font_14 cors" v-if="Number(tabindex) < 2">
								本月{{ item.name }}平均值
							</p>
							<p class="font_14 cors" v-else>
								{{ tabindex == '2' ? '本月' : '' }}{{ item.name || '--' }}
							</p>
							<p class="maxs">
								<span class="max-nums">{{ showNums(item.value) }}</span
								>{{ item.unit || '次' }}
							</p>
							<div class="font_14 cors ellipsis">
								与上月相比
								<span v-if="duibi(item)" class="sheng-jian">⬆ </span>
								<span v-else class="reda-jian">⬇ </span>
								<el-tooltip :content="makeNums(item)">
									<span
										:class="duibi(item) ? 'sheng-word' : 'reda-word'"
										>{{ makeNums(item) }}</span
									>
								</el-tooltip>
							</div>
						</div>
					</li>
				</ul>
			</vue-seamless-scroll>
		</div>

		<img v-else class="emptydata" src="../assets/img/none.png" alt="" />

		<!-- 下 -->
		<div class="left3">
			<div class="intros">监测建议：{{ jianyi }}。</div>
		</div>
	</div>
</template>

<script>
/**
 * @description 本月情况的列表组件
 * @param datas [Array] 数据
 * @param tabindex [String] tab代表值
 * @param jianyi  [String]  监测建议
 */
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
	components: {
		vueSeamlessScroll,
	},
	props: {
		datas: {
			type: Array,
			default: () => {
				return []
			},
		},
		tabindex: {
			type: String,
			default: '0',
		},
		jianyi: {
			type: String,
			default:
				'根据相关指标情况，对农业生产会造成一定影响，建议多观察监测数据，做好防范措施',
		},
	},
	computed: {
		firstData() {
			return this.datas[0] || {}
		},
		otherData() {
			return this.datas.slice(1, this.datas.length - 1) || []
		},
	},
	data() {
		return {
			classOption: {
				step: 0.5, // 数值越大速度滚动越快
				limitMoveNum: 2, // 开始无缝滚动的数据量 this.datas.length
				direction: 2, // 0向下 1向上 2向左 3向右
			},
		}
	},
	methods: {
		mouseEnter() {
			this.$refs.mySwipers.$swiper.autoplay.stop()
		},
		mouseLeave() {
			this.$refs.mySwipers.$swiper.autoplay.start()
		},
		showNums(value) {
			if (Number(value) > 0) {
				if (Number(this.tabindex) < 2) {
					return Number(value).toFixed(1)
				} else {
					return value
				}
			} else {
				return 0
			}
		},
		makeNums({ value, last, unit }) {
			let units = unit ? unit : '次'
			let abss = Math.abs(Number(value) - Number(last))
			if (abss > 0) {
				if (Number(this.tabindex) < 2) {
					return abss.toFixed(1) + units
				} else {
					return abss + units
				}
			} else {
				return 0 + units
			}
		},
		duibi({ value, last }) {
			if (Number(value) - Number(last) >= 0) {
				return true
			} else {
				return false
			}
		},
	},
}
</script>

<style lang="less" scoped>
.month-wrap {
	width: 400px;
	margin: auto;
	padding-bottom: 25px;
	.seamless-warp {
		width: 100%;
		height: 96px;
		overflow: hidden;
		margin-top: 4px;
	}
	.emptydata {
		height: 96px;
		display: block;
		margin: auto;
	}
	.cols {
		color: #cfedff;
	}
	.left1 {
		width: 196px;
		height: 96px;
		font-size: 14px;
		padding: 14px 0 0 16px;
		margin-top: 4px !important;
		background: url('../assets/img/HomeBrain/chang0.png') no-repeat;
		background-size: 100% 100%;
		.maxs {
			margin: 8px 0;
			text-align: center;
			color: #00e2fb;
			.max-nums {
				font-size: 24px;
			}
		}
	}
	.left2 {
		width: 196px;
		height: 96px;
		font-size: 14px;
		padding: 14px 10px 0 16px;
		background: url('../assets/img/HomeBrain/chang0.png') no-repeat;
		background-size: 100% 100%;
		.maxs {
			margin: 8px 0;
			text-align: center;
			color: #00e2fb;
			.max-nums {
				font-size: 24px;
			}
		}
	}
	.sheng-jian {
		font-size: 14px;
		color: #41ff5c;
		margin-left: 10px;
	}
	.sheng-word {
		font-size: 14px;
		color: #41ff5c;
	}
	.reda-jian {
		font-size: 14px;
		color: #ff2222;
		margin-left: 18px;
	}
	.reda-word {
		font-size: 14px;
		color: #ff2222;
	}
	.left3 {
		width: 384px;
		height: 70px;
		margin: 12px auto 0;
		padding-top: 15px;
		background: url('../assets/img/HomeBrain/chang1.png') no-repeat;
		background-size: 100% 100%;
		.intros {
			width: 351px;
			height: 40px;
			margin: 0 auto;
			overflow-y: scroll;
			line-height: 1.5;
			font-size: 14px;
			color: #cfedff;
		}
	}
}
</style>
