<template>
  <div>
    <div id="pieRef" class="pie1"></div>
  </div>
</template>
<script>
import HighCharts from 'highcharts'
export default{
  data(){
    return{}
  },
  mounted () {
    this.pie1();
  },
  methods: {
     pie1() {
      let _this = this;
       let color = ['#45c2ff','#00d7e9'];
			let data=[
					{
						value: 70,
						y:70,
						name: '嘉祥大豆种子',
						
					},
					{
						value: 30,
						y:30,
						selected:true,
						sliced: true,
						name: '国内其他大豆种子',
					}
			];
      let option = {
				 credits: {
					enabled: false //不显示LOGO
					},
				 chart: {
					type: 'pie',
					backgroundColor: "rgba(0, 0, 0, 0)",
					options3d: {
						enabled: true,
						alpha: 60,
						viewDistance:80,
						beta: 0, 
					}
				},
				title: {
					text: ''
				},
        tooltip:{
					enabled:true,
          backgroundColor:"rgba(255,255,255,0.3)",
          borderWidth:0,
          shadow:false,
          style:{
            color:"#fff",
            fontSize:16
          },
          formatter:function(){
            let percent = this.point.percentage.toFixed(0)
            return `${this.point.name}：${this.point.y}%`;
          },
				},
				labels:{
					color:"#fff"
				},
				plotOptions: {
					pie: {
            size:"100%",
            center:['42%','88%'],
						allowPointSelect: true,
						innerSize: '0%',
						cursor: 'pointer',
						depth: 25,
						slicedOffset:20,
						colors:['#45c2ff','#00d7e9'],
						states:{
							inactive:{
								opacity:1
							}
						},
						dataLabels: {
							enabled: true,
							distance:20,
							padding:0,
							y:-20,
							formatter:function(index){
								let result = ''
								if(this.point.name == '嘉祥大豆种子'){
									// result += `<div style="font-size:14px">
									// 	<div>占黄淮海流域</div><br>
									// 	<p>大豆种子交易量</p>
									// 	<div style="font-size:28px;font-weight:bold;color:#41C1FF;">70%</div>
									// </div><br>
									// <div>${this.point.name}年交易量1.5亿元</div>`
									result += `<div style="font-size:14px;"><p>${this.point.name}</p><br>
									<p style="font-size:28px;font-weight:bold;color:#41C1FF;">${this.point.y}%</p>  <p>年交易量1.5亿元</p>
									</div>`;
								} else {
									result += `<div style="font-size:14px;"><p>${this.point.name}</p><br><p style="font-size:28px;font-weight:bold;color:#41C1FF;">${this.point.y}%</p></div>`;
								}
								return result;
							},
							style: { //样式配置
									textOutline: "none", //去掉文字白边
									color: "#fff",
									lineHeight:26,
									fontSize: 14,
							},
						}
					}
				},
				series: [{
					data: data,
				}]
			 }
			 HighCharts.chart('pieRef', option)
		},

  },
}
</script>
<style lang='scss' scoped>
	.pie1 {
		width: 510px;
		height: 210px;
	}
</style>