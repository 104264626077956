<template>
	<div class="flex c_white cultures">
		<div
			class="culture-div"
			v-for="(item, index) in dataList"
			:key="index"
			@click="toOpenDialog(item)"
			:class="{ 'culture-div-active': chooseId == item.id }"
		>
			<div class="icon-div" v-if="index % 2 == 0">
				<img class="culture-icon" :src="item.icons" />
			</div>

			<div class="font_18 culture-word">{{ item.cultureName }}</div>
			<div class="icon-div" v-if="index % 2 != 0">
				<img class="culture-icon" :src="item.icons" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @description 文化长廊
 */
export default {
	data() {
		return {
			defaultLists: [
				{
					icons: require('../../assets/img/homeMon/icon_lingdao.png'),
					name: '领导关怀篇',
				},
				{
					icons: require('../../assets/img/homeMon/icon_open.png'),
					name: '大豆文化篇',
				},
				{
					icons: require('../../assets/img/homeMon/icon_earth.png'),
					name: '科技创新篇',
				},
				{
					icons: require('../../assets/img/homeMon/icon_cy.png'),
					name: '产业园篇',
				},
				{
					icons: require('../../assets/img/homeMon/icon_zhengce.png'),
					name: '政策引领篇',
				},
				{
					icons: require('../../assets/img/homeMon/icon_qiye.png'),
					name: '种业企业篇',
				},
				{
					icons: require('../../assets/img/homeMon/icon_dd.png'),
					name: '嘉祥种业篇',
				},
				{
					icons: require('../../assets/img/homeMon/icon_zhinan.png'),
					name: '技术指导篇',
				},
			],
			// // 实际呈现的数据
			// dataList: []
			chooseId: '',
		}
	},
	props: {
		lists: {
			type: Array,
			default: () => {
				return []
			},
		},
	},
	methods: {
		// 点击打开文化长廊弹窗
		toOpenDialog({ cultureName, id }) {
			let emitVal = {
				cultureName,
				id,
			}
			this.chooseId = id
			this.$emit('openDialog', emitVal)
		},
	},
	computed: {
		// 实际呈现的数据
		dataList() {
			let arrs = JSON.parse(JSON.stringify(this.lists))
			arrs.forEach((item, index) => {
				this.defaultLists.forEach((items) => {
					if (item.cultureName == items.name) {
						arrs[index].icons = items.icons
					}
				})
			})
			return arrs
		},
	},
}
</script>

<style lang="less" scoped>
.cultures {
	padding-top: 12px;
	padding-bottom: 17px;
	.culture-div {
		width: 92px;
		height: 202px;
		background-size: 100% 100%;
		cursor: pointer;
		&:hover {
			color: #04bfff;
		}
		&-active {
			color: #04bfff;
		}
		&:nth-child(2n) {
			background: url('../../assets/img/homeMon/whbg_b.png') no-repeat;
			background-size: 100% 100%;
			// .culture-word {
			// 	padding-top: 10px;
			// }
		}
		&:nth-child(2n + 1) {
			background: url('../../assets/img/homeMon/whbg_t.png') no-repeat;
			background-size: 100% 100%;
		}
		&:not(:first-child) {
			margin-left: 9px;
		}
	}
	.icon-div {
		width: 92px;
		height: 85px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.culture-icon {
		width: 35px;
		height: auto;
	}
	.culture-word {
		width: 30px;
		height: 112px;
		padding-top: 13px;
		margin: auto;
		text-align: center;
		font-family: 'Source Han Sans CN';
		font-weight: bold;
	}
}
</style>
